import React, { ReactNode } from "react";
import { ARTIST_ID_ROUTE_PARAM_TOKEN, ARTIST_ROUTE, EVENT_ID_ROUTE_PARAM_TOKEN, EVENT_ROUTE, VENUE_ID_ROUTE_PARAM_TOKEN, VENUE_ROUTE } from "src/constants";
import ArtistDetails from "src/Pages/Artist/InternalComponents/ArtistDetails/ArtistDetails";
import { ArtistAction, EventAction, VenueAction } from "src/Types";
import EventDetails from "../../../Pages/Event/InternalComponents/EventDetails/EventDetails";
import VenueDetails from "../../../Pages/Venue/InternalComponents/VenueDetails/VenueDetails";

export interface IHistoricalActionModalProps {
  action: EventAction | VenueAction | ArtistAction;
  onCloseModal(): any;
}

export interface IHistoricalActionModalState {
  width: number;
}

export const computeActionBeforeEntityDetailComponent = (
  action: EventAction | VenueAction | ArtistAction,
  routerHistory: any,
  onCloseModal: any
): ReactNode => {
  let returnComponent = (<div></div>);
  const { actionTargetType, actionType } = action;

  const onEventClick = (eventID: string) => {
    const computedURL = EVENT_ROUTE.replace(EVENT_ID_ROUTE_PARAM_TOKEN, eventID);
    onCloseModal();
    routerHistory.push(computedURL);
  };
  
  const onArtistClick = (artistID: string) => {
    const computedURL = ARTIST_ROUTE.replace(ARTIST_ID_ROUTE_PARAM_TOKEN, artistID);
    onCloseModal();
    routerHistory.push(computedURL);
  };
  
  const onVenueClick = (venueID: string) => {
    const computedURL = VENUE_ROUTE.replace(VENUE_ID_ROUTE_PARAM_TOKEN, venueID);
    onCloseModal();
    routerHistory.push(computedURL);
  };

  if (actionTargetType === "ARTIST") {
  // let castedAction: ArtistAction = action;
  if (actionType === "EDIT" || actionType === "DELETE") {
    // console.log(`Action data -> ${JSON.stringify(action, null, 4)}`);
    // console.log(`Artist data -> ${JSON.stringify((action as ArtistAction).previousInfoState!, null, 4)}`);
    returnComponent = (
        <ArtistDetails
          artist={(action as ArtistAction).previousInfoState!}
          // onEventClick={() => {}}
          actionHistoryCardMode
        />
      );
    }
  }
  
  else if (actionTargetType === "EVENT") {
    // let castedAction: EventAction = action;
    if (actionType === "EDIT" || actionType === "DELETE") {
      returnComponent = (
          <EventDetails
            event={(action as EventAction).previousInfoState!}
            onArtistClick={onArtistClick}
            onVenueClick={onVenueClick}
            actionHistoryCardMode
          />
        );
      }
    }
    
  else if (actionTargetType === "VENUE") {
    if (actionType === "EDIT" || actionType === "DELETE") {
      if ((action as VenueAction).previousInfoState) {
        returnComponent = (
          <VenueDetails
            venue={(action as VenueAction).previousInfoState!}
            onEventClick={onEventClick}
            actionHistoryCardMode
          />
        );
      }
    }
  }
    
  return returnComponent;
};
      
export const computeActionAfterEntityDetailComponent = (
  action: EventAction | VenueAction | ArtistAction,
  routerHistory: any,
  onCloseModal: any
  ): ReactNode => {
    let returnComponent = (<div></div>);
    const { actionTargetType, actionType } = action;
    
    const onEventClick = (eventID: string) => {
      const computedURL = EVENT_ROUTE.replace(EVENT_ID_ROUTE_PARAM_TOKEN, eventID);
      onCloseModal();
      routerHistory.push(computedURL);
    };

    const onArtistClick = (artistID: string) => {
      const computedURL = ARTIST_ROUTE.replace(ARTIST_ID_ROUTE_PARAM_TOKEN, artistID);
      onCloseModal();
      routerHistory.push(computedURL);
    };

    const onVenueClick = (venueID: string) => {
      const computedURL = VENUE_ROUTE.replace(VENUE_ID_ROUTE_PARAM_TOKEN, venueID);
      onCloseModal();
      routerHistory.push(computedURL);
    };
    
    if (actionTargetType === "ARTIST") {
      // let castedAction: ArtistAction = action;
      if (actionType === "EDIT" || actionType === "CREATE") {
        // console.log(`Action data -> ${JSON.stringify(action, null, 4)}`);
        // console.log(`Artist data -> ${JSON.stringify((action as ArtistAction).newInfoState!, null, 4)}`);
        returnComponent = (
          <ArtistDetails
            artist={(action as ArtistAction).newInfoState!}
            // onEventClick={() => {}}
            actionHistoryCardMode
          />
        );
      }
    }
      
    else if (actionTargetType === "EVENT") {
      // let castedAction: EventAction = action;
      if (actionType === "EDIT" || actionType === "CREATE") {
        returnComponent = (
          <EventDetails
            event={(action as EventAction).newInfoState!}
            onVenueClick={onVenueClick}
            onArtistClick={onArtistClick}
            actionHistoryCardMode
          />
        );
      }
    }

    else if (actionTargetType === "VENUE") {
      if (actionType === "EDIT" || actionType === "CREATE") {
        if ((action as VenueAction).newInfoState) {
          returnComponent = (
            <VenueDetails
              venue={(action as VenueAction).newInfoState!}
              onEventClick={onEventClick}
              actionHistoryCardMode
            />
          );
        }
      }
    }

    return returnComponent;
  };
