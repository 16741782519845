import cloneDeep from "lodash/cloneDeep";
import { IReducerAction, Event } from "../../Types";
import {
  EVENTS_ROUTE, DATA_ITEMS_PER_PAGE, FILTER_CONDITION__NONE, GRID,
  PAGE_NUM_PARAM_KEY
} from "src/constants";
import {
  SORT_CRITERIA__NONE, SORT_DIRECTION__ASCENDING, sortEvents,
  FILTER_CONDITION_TYPE__RANGE, FILTER_CONDITION_TYPE__LIST,
  filterEvents, searchEvents, selectPaginatedEvents, FILTER_CRITERIA__NONE,
} from "./helpers";

export interface IEventsState {
  // -> General
  events: Event[];
  displayedEvents: Event[];
  numberOfDisplayableEvents: number;
  displayMode: "Grid" | "List";
  pageContentInStatus: boolean;
  loadingStatus: boolean;
  loaderInStatus: boolean;
  sortingActive: boolean;
  sortActivityIndicatorInStatus: boolean;
  filteringActive: boolean;
  filterActivityIndicatorInStatus: boolean;
  searchActive: boolean;
  searchActivityIndicatorInStatus: boolean;
  // -> Sorting
  currentSortCriteria: string;
  currentSortDirection: string;
  // -> Filtering
  currentFilterCriteria: string;
  currentFilterCondition: string;
  currentFilterConditionType: string;
  currentFilterArgument: string[];
  filterArgumentFadeInStatuses: boolean[];
  filtArgMicroAnimationTimeoutIDs: any[];
  // -> Searching
  currentSearchQuery: string;
  // -> Pagination
  itemsPerPage: number;
  currentPageNumber: number;
}

export const initialEventsState: IEventsState = {
  // -> General
  events: [],
  displayedEvents: [],
  numberOfDisplayableEvents: 0,
  displayMode: GRID,
  pageContentInStatus: true,
  loadingStatus: true,
  loaderInStatus: true,
  sortingActive: false,
  filteringActive: false,
  searchActive: false,
  sortActivityIndicatorInStatus: true,
  filterActivityIndicatorInStatus: true,
  searchActivityIndicatorInStatus: true,
  filtArgMicroAnimationTimeoutIDs: [],
  filterArgumentFadeInStatuses: [],
  // -> Sorting
  currentSortCriteria: SORT_CRITERIA__NONE,
  currentSortDirection: SORT_DIRECTION__ASCENDING,
  // -> Filtering
  currentFilterCriteria: FILTER_CRITERIA__NONE,
  currentFilterCondition: FILTER_CONDITION__NONE,
  currentFilterConditionType: FILTER_CONDITION_TYPE__LIST,
  currentFilterArgument: [],
  // -> Searching
  currentSearchQuery: "",
  // -> Pagination
  itemsPerPage: DATA_ITEMS_PER_PAGE,
  currentPageNumber: 1,
};

// - Action strings - //
// -> General
const SET_EVENTS_ACTION = "SET_EVENTS_ACTION";
const SET_DISPLAYED_EVENTS_ACTION = "SET_DISPLAYED_EVENTS_ACTION";
const SET_DISPLAY_MODE_ACTION = "SWITCH_DISPLAY_MODE_ACTION";
const SET_ITEMS_PER_PAGE_ACTION = "SET_ITEMS_PER_PAGE_ACTION";
const SET_PAGE_CONTENT_IN_STATUS_ACTION = "SET_PAGE_CONTENT_IN_STATUS_ACTION";
const SET_LOADING_STATUS_ACTION = "SET_LOADING_STATUS_ACTION";
const SET_LOADER_IN_STATUS_ACTION = "SET_LOADER_IN_STATUS_ACTION";
const ADD_MICROANIMATION_TIMEOUT_ID_ACTION = "ADD_MICROANIMATION_TIMEOUT_ID_ACTION";
const ADD_FILTER_ARG_FADE_IN_STATUS_ACTION = "ADD_FILTER_ARG_FADE_IN_STATUS_ACTION";
const REMOVE_FILTER_ARG_FADE_IN_STATUS_ACTION = "REMOVE_FILTER_ARG_FADE_IN_STATUS_ACTION";
const SET_FILTER_ARG_FADE_IN_STATUSES_ACTION = "SET_FILTER_ARG_FADE_IN_STATUSES_ACTION";
const SET_SORT_ACTIVITY_INDICATOR_IN_STATUS_ACTION = "SET_SORT_ACTIVITY_INDICATOR_IN_STATUS_ACTION";
const SET_FILTER_ACTIVITY_INDICATOR_IN_STATUS_ACTION = "SET_FILTER_ACTIVITY_INDICATOR_IN_STATUS_ACTION";
const SET_SEARCH_ACTIVITY_INDICATOR_IN_STATUS_ACTION = "SET_SEARCH_ACTIVITY_INDICATOR_IN_STATUS_ACTION";
// -> Sorting
const SET_SORT_CRITERIA_ACTION = "SET_SORT_CRITERIA_ACTION";
const SET_SORT_DIRECTION_ACTION = "SET_SORT_DIRECTION_ACTION";
const APPLY_SORT_CONFIG_ACTION = "APPLY_SORT_CONFIG_ACTION";
const CLEAR_SORT_CONFIG_ACTION = "CLEAR_SORT_CONFIG_ACTION";
// -> Filtering
const SET_FILTER_CRITERIA_ACTION = "SET_FILTER_CRITERIA_ACTION";
const SET_FILTER_CONDITION_ACTION = "SET_FILTER_CONDITION_ACTION";
const SET_FILTER_CONDITION_TYPE_ACTION = "SET_FILTER_CONDITION_TYPE_ACTION";
const SET_FILTER_ARGUMENT_ACTION = "SET_FILTER_ARGUMENT_ACTION";
const APPLY_FILTER_CONFIG_ACTION = "APPLY_FILITER_CONFIG_ACTION";
const CLEAR_FILTER_CONFIG_ACTION = "CLEAR_FILTER_CONFIG_ACTION";
// -> Searching
const SEARCH_EVENTS_ACTION = "SEARCH_EVENTS_ACTION";
const CLEAR_EVENT_SEARCH_ACTION = "CLEAR_EVENT_SEARCH_ACTION";
// -> Pagination
const SET_PAGINATED_EVENTS_ACTION = "SET_PAGINATED_EVENTS_ACTION";
const SET_CURRENT_PAGE_ACTION = "SET_CURRENT_PAGE_ACTION";

// - Action creators - //
// -> General
export const setEvents = (events: Event[]): IReducerAction => ({
  type: SET_EVENTS_ACTION,
  payload: events,
});

export const setDisplayedEvents = (events: Event[]): IReducerAction => ({
  type: SET_DISPLAYED_EVENTS_ACTION,
  payload: events,
});

export const setDisplayMode = (displayMode: string): IReducerAction => ({
  type: SET_DISPLAY_MODE_ACTION,
  payload: displayMode,
});

export const setItemsPerPage = (numberOfItems: number): IReducerAction => ({
  type: SET_ITEMS_PER_PAGE_ACTION,
  payload: numberOfItems,
});

export const setPageContentInStatus = (inStatus: boolean): IReducerAction => ({
  type: SET_PAGE_CONTENT_IN_STATUS_ACTION,
  payload: inStatus
});

export const setLoadingStatus = (loadingStatus: boolean): IReducerAction => ({
  type: SET_LOADING_STATUS_ACTION,
  payload: loadingStatus,
});

export const setLoaderInStatus = (loaderInStatus: boolean): IReducerAction => ({
  type: SET_LOADER_IN_STATUS_ACTION,
  payload: loaderInStatus,
});

export const addMicroanimationTimeoutID = (timeout: any): IReducerAction => ({
  type: ADD_MICROANIMATION_TIMEOUT_ID_ACTION,
  payload: timeout,
});

export const addFilterArgumentFadeInStatus = (): IReducerAction => ({ type: ADD_FILTER_ARG_FADE_IN_STATUS_ACTION });

export const removeFilterArgumentFadeInStatus = (index: number): IReducerAction => ({
  type: REMOVE_FILTER_ARG_FADE_IN_STATUS_ACTION,
  payload: index,
});

export const setFilterArgumentFadeInStatuses = (fadeInStatuses: boolean[]): IReducerAction => ({
  type: SET_FILTER_ARG_FADE_IN_STATUSES_ACTION,
  payload: fadeInStatuses,
});

export const setSortActivityIndicatorInStatus = (inStatus: boolean): IReducerAction => ({
  type: SET_SORT_ACTIVITY_INDICATOR_IN_STATUS_ACTION,
  payload: inStatus,
});

export const setFilterActivityIndicatorInStatus = (inStatus: boolean): IReducerAction => ({
  type: SET_FILTER_ACTIVITY_INDICATOR_IN_STATUS_ACTION,
  payload: inStatus,
});

export const setSearchActivityIndicatorInStatus = (inStatus: boolean): IReducerAction => ({
  type: SET_SEARCH_ACTIVITY_INDICATOR_IN_STATUS_ACTION,
  payload: inStatus,
});

// -> Sorting
export const setSortCriteria = (sortCriteria: string): IReducerAction => ({
  type: SET_SORT_CRITERIA_ACTION,
  payload: sortCriteria,
});

export const setSortDirection = (sortDirection: string): IReducerAction => ({
  type: SET_SORT_DIRECTION_ACTION,
  payload: sortDirection,
});

export const applySortConfig = (): IReducerAction => ({ type: APPLY_SORT_CONFIG_ACTION });
export const clearSortConfig = (): IReducerAction => ({ type: CLEAR_SORT_CONFIG_ACTION });

// -> Filtering
export const setFilterCriteria = (filterCriteria: string): IReducerAction => ({
  type: SET_FILTER_CRITERIA_ACTION,
  payload: filterCriteria,
});

export const setFilterCondition = (filterCondition: string): IReducerAction => ({
  type: SET_FILTER_CONDITION_ACTION,
  payload: filterCondition,
});

export const setFilterConditionType = (filterConditionType: string): IReducerAction => ({
  type: SET_FILTER_CONDITION_TYPE_ACTION,
  payload: filterConditionType,
});

export const setFilterArgument = (filterArgument: string[]): IReducerAction => ({
  type: SET_FILTER_ARGUMENT_ACTION,
  payload: filterArgument,
});

export const applyFilterConfig = (): IReducerAction => ({ type: APPLY_FILTER_CONFIG_ACTION });
export const clearFilterConfig = (): IReducerAction => ({ type: CLEAR_FILTER_CONFIG_ACTION });

// -> Searching
export const searchEventsAction = (searchQuery: string): IReducerAction => ({
  type: SEARCH_EVENTS_ACTION,
  payload: searchQuery,
});
export const clearEventSearch = (): IReducerAction => ({ type: CLEAR_EVENT_SEARCH_ACTION });

// -> Pagination
export const setPaginatedEvents = (): IReducerAction => ({ type: SET_PAGINATED_EVENTS_ACTION });

export const setCurrentPage = (pageNumber: number): IReducerAction => ({
  type: SET_CURRENT_PAGE_ACTION,
  payload: pageNumber
});

// - State reducer - //
export const reducer = (state: IEventsState, action: IReducerAction): IEventsState => {
  const stateCopy = cloneDeep(state);
  const {
    sortingActive, filteringActive, searchActive, currentSearchQuery, events,
  } = stateCopy;

  switch(action.type) {

    // -> General

    case SET_EVENTS_ACTION:
      return {
        ...stateCopy,
        events: action.payload,
        numberOfDisplayableEvents: action.payload.length,
      };
    case SET_DISPLAYED_EVENTS_ACTION:
      return { ...stateCopy, displayedEvents: action.payload }
    case SET_DISPLAY_MODE_ACTION:
      return { ...stateCopy, displayMode: action.payload };
    case SET_ITEMS_PER_PAGE_ACTION:
      return { ...stateCopy, itemsPerPage: action.payload };
    case SET_PAGE_CONTENT_IN_STATUS_ACTION:
      return { ...stateCopy, pageContentInStatus: action.payload };
    case SET_LOADING_STATUS_ACTION:
      return { ...stateCopy, loadingStatus: action.payload };
    case SET_LOADER_IN_STATUS_ACTION:
      return { ...stateCopy, loaderInStatus: action.payload };
    case ADD_MICROANIMATION_TIMEOUT_ID_ACTION: {
      stateCopy.filtArgMicroAnimationTimeoutIDs.push(action.payload);
      return stateCopy;
    }
    case ADD_FILTER_ARG_FADE_IN_STATUS_ACTION: {
      stateCopy.filterArgumentFadeInStatuses.push(true);
      return stateCopy;
    }
    case REMOVE_FILTER_ARG_FADE_IN_STATUS_ACTION: {
      const filteredInStatuses = stateCopy.filterArgumentFadeInStatuses.filter((_, index) => index !== action.payload);
      return { ...stateCopy, filterArgumentFadeInStatuses: filteredInStatuses};
    }
    case SET_FILTER_ARG_FADE_IN_STATUSES_ACTION: {
      return { ...stateCopy, filterArgumentFadeInStatuses: action.payload };
    }
    case SET_SORT_ACTIVITY_INDICATOR_IN_STATUS_ACTION: {
      return { ...stateCopy, sortActivityIndicatorInStatus: action.payload };
    }
    case SET_FILTER_ACTIVITY_INDICATOR_IN_STATUS_ACTION: {
      return { ...stateCopy, filterActivityIndicatorInStatus: action.payload };
    }
    case SET_SEARCH_ACTIVITY_INDICATOR_IN_STATUS_ACTION: {
      return { ...stateCopy, searchActivityIndicatorInStatus: action.payload };
    }

    // -> Sorting

    case SET_SORT_CRITERIA_ACTION: {
      return { ...stateCopy, currentSortCriteria: action.payload };
    }
    case SET_SORT_DIRECTION_ACTION: {
      return { ...stateCopy, currentSortDirection: action.payload };
    }
    case APPLY_SORT_CONFIG_ACTION: {
      let processedEventData: Event[] = stateCopy.events;
      if (filteringActive) processedEventData = filterEvents(stateCopy, { eventsToFilterParam: processedEventData });
      if (searchActive) processedEventData = searchEvents(currentSearchQuery, stateCopy, { eventsToSearchParam: processedEventData });
      processedEventData = sortEvents(stateCopy, { eventsToSortParam: processedEventData });

      const numDisplayableEvents = processedEventData.length;

      processedEventData = selectPaginatedEvents(stateCopy, {
        pageNumber: 1,
        eventsToPaginateParam: processedEventData,
      });

      return {
        ...stateCopy,
        sortingActive: true,
        sortActivityIndicatorInStatus: true,
        displayedEvents: processedEventData,
        numberOfDisplayableEvents: numDisplayableEvents,
        currentPageNumber: 1,
      };
    }
    case CLEAR_SORT_CONFIG_ACTION: {
      let processedEventData: Event[] = stateCopy.events;
      if (filteringActive) processedEventData = filterEvents(stateCopy, { eventsToFilterParam: processedEventData});
      if (searchActive) processedEventData = searchEvents(currentSearchQuery, stateCopy, { eventsToSearchParam: processedEventData });

      const numDisplayableEvents = processedEventData.length;

      processedEventData = selectPaginatedEvents(stateCopy, {
        pageNumber: 1,
        eventsToPaginateParam: processedEventData,
      });

      return {
        ...stateCopy,
        sortingActive: false,
        sortActivityIndicatorInStatus: false,
        currentSortCriteria: SORT_CRITERIA__NONE,
        currentSortDirection: SORT_DIRECTION__ASCENDING,
        displayedEvents: processedEventData,
        numberOfDisplayableEvents: numDisplayableEvents,
        currentPageNumber: 1,
      };
    }

    // -> Filtering

    case SET_FILTER_CRITERIA_ACTION:
      return { ...stateCopy, currentFilterCriteria: action.payload };
    case SET_FILTER_CONDITION_ACTION: {
      const { payload: filterCondition } = action;

      if ((filterCondition !== FILTER_CONDITION__NONE) &&
          (stateCopy.currentFilterArgument.length === 0)) {
        stateCopy.filterArgumentFadeInStatuses = [true];
        stateCopy.currentFilterArgument.push("");
      }

      if (filterCondition === FILTER_CONDITION__NONE) {
        stateCopy.filterArgumentFadeInStatuses = [];
        stateCopy.currentFilterArgument = [];
      }

      return {
        ...stateCopy, currentFilterCondition: action.payload
      };
    }
    case SET_FILTER_CONDITION_TYPE_ACTION: {
      const { payload: filterConditionType } = action;

      // -> Override filter argument content to one blank entry
      if (filterConditionType === FILTER_CONDITION_TYPE__LIST) {
        stateCopy.filterArgumentFadeInStatuses = [true];
        stateCopy.currentFilterArgument = [""];
      }

      // -> Override filter argument content to two blank entries
      if (filterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        stateCopy.filterArgumentFadeInStatuses = [true, true];
        stateCopy.currentFilterArgument = ["", ""];
      }
      return { ...stateCopy, currentFilterConditionType: filterConditionType };
    }
    case SET_FILTER_ARGUMENT_ACTION: 
      return { ...stateCopy, currentFilterArgument: action.payload };
    case APPLY_FILTER_CONFIG_ACTION: {
      let processedEventData: Event[] = events;
      if (sortingActive) processedEventData = sortEvents(stateCopy, { eventsToSortParam: processedEventData });
      if (searchActive) processedEventData = searchEvents(currentSearchQuery, stateCopy, { eventsToSearchParam: processedEventData });
      processedEventData = filterEvents(stateCopy, { eventsToFilterParam: processedEventData });

      const numDisplayableEvents = processedEventData.length;

      processedEventData = selectPaginatedEvents(stateCopy, {
        pageNumber: 1,
        eventsToPaginateParam: processedEventData,
      });

      return {
        ...stateCopy,
        filteringActive: true,
        filterActivityIndicatorInStatus: true,
        displayedEvents: processedEventData,
        numberOfDisplayableEvents: numDisplayableEvents,
        currentPageNumber: 1,
      };
    }
    case CLEAR_FILTER_CONFIG_ACTION: {
      let processedEventData: Event[] = events;
      if (sortingActive) processedEventData = sortEvents(stateCopy);
      if (searchActive) processedEventData = searchEvents(currentSearchQuery, stateCopy, { eventsToSearchParam: processedEventData });

      const numDisplayableEvents = processedEventData.length;

      processedEventData = selectPaginatedEvents(stateCopy, {
        pageNumber: 1,
        eventsToPaginateParam: processedEventData,
      });

      return {
        ...stateCopy,
        filteringActive: false,
        filterActivityIndicatorInStatus: false,
        currentFilterCriteria: FILTER_CRITERIA__NONE,
        currentFilterCondition: FILTER_CONDITION__NONE,
        currentFilterArgument: [],
        displayedEvents: processedEventData,
        numberOfDisplayableEvents: numDisplayableEvents,
        currentPageNumber: 1,
      };
    }

    // -> Searching

    case SEARCH_EVENTS_ACTION: {
      let processedEventData: Event[] = events;
      if (sortingActive) processedEventData = sortEvents(stateCopy, { eventsToSortParam: processedEventData });
      if (filteringActive) processedEventData = filterEvents(stateCopy, { eventsToFilterParam: processedEventData });
      processedEventData = searchEvents(action.payload, stateCopy, { eventsToSearchParam: processedEventData });

      const numDisplayableEvents = processedEventData.length;

      processedEventData = selectPaginatedEvents(stateCopy, {
        pageNumber: 1,
        eventsToPaginateParam: processedEventData,
      });

      return {
        ...stateCopy,
        searchActive: true,
        searchActivityIndicatorInStatus: true,
        currentSearchQuery: action.payload,
        displayedEvents: processedEventData,
        numberOfDisplayableEvents: numDisplayableEvents,
        currentPageNumber: 1,
      }
    }

    case CLEAR_EVENT_SEARCH_ACTION: {
      let processedEventData: Event[] = events;
      if (sortingActive) processedEventData = sortEvents(stateCopy, { eventsToSortParam: processedEventData });
      if (filteringActive) processedEventData = filterEvents(stateCopy, { eventsToFilterParam: processedEventData });

      const numDisplayableEvents = processedEventData.length;

      processedEventData = selectPaginatedEvents(stateCopy, {
        pageNumber: 1,
        eventsToPaginateParam: processedEventData,
      });

      return {
        ...stateCopy,
        searchActive: false,
        searchActivityIndicatorInStatus: false,
        currentSearchQuery: "",
        displayedEvents: processedEventData,
        numberOfDisplayableEvents: numDisplayableEvents,
        currentPageNumber: 1,
      };
    }

    // -> Pagination
    case SET_PAGINATED_EVENTS_ACTION:
      let processedEventData: Event[] = selectPaginatedEvents(stateCopy);
      return { ...stateCopy, displayedEvents: processedEventData };
    case SET_CURRENT_PAGE_ACTION:
      let eventData: Event[] = selectPaginatedEvents(stateCopy, { pageNumber: action.payload });
      window.history.pushState(null, "", `${EVENTS_ROUTE}?${PAGE_NUM_PARAM_KEY}=${action.payload}`);

      return {
        ...stateCopy,
        currentPageNumber: action.payload,
        displayedEvents: eventData,
      };
    default: return stateCopy;
  } // -> End of switch statement
};
