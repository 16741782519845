import { StyleSheet } from "aphrodite";
import { NAV_BAR_HEIGHT } from "../../constants";
import { ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { DEFAULT_CARD_WIDTH, DEFAULT_CARD_HEIGHT } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, screenHeight: number, screenWidth: number) => {
  const { distance } = themeInfo;

  return StyleSheet.create({
    pageCradle: {
      position: "relative",
      display: 'flex',
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      padding: distance.three,
      backgroundColor: themeInfo.palette.white,
    },
    mainContentCradle: {
      height: "auto",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    addPhoneNumberCradle: {
      position: "absolute",
      top: ((screenHeight / 2) - (DEFAULT_CARD_HEIGHT) - (NAV_BAR_HEIGHT)),
      left: ((screenWidth / 2) - (DEFAULT_CARD_WIDTH / 2)),
      zIndex: themeInfo.stackingIndexes.modal,
    }
  });
}
