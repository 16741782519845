import { ITypeAheadSearchItem } from "src/Components/TypeAheadSearch/stateManagement";
import { BANDCAMP, LANGUAGE__ENGLISH, LANGUAGE__FRENCH } from "src/constants";
import { mapLanguageCodeToLanguage } from "src/helpers";
import {
  VenueLinks, IPotentialExternalLinkEntry, Venue,
  Event, VenueStatus, ICoordinatePair
} from "../../../Types";

export interface IAddOrEditVenueFormProps {
  formMode: 'ADD' | 'EDIT';
  venueDetails?: Venue;
  onSubmit: any;
  isInCardMode?: boolean;
  customFormCradleStyles?: any;
  customFormTitleTextStyles?: any;
  rtl?: boolean;
}

export interface IAddOrEditVenueFormState {
  links?: VenueLinks
  potentialExternalLinks?: IPotentialExternalLinkEntry[];
  manualFormFieldsValid: boolean;
  microAnimationTimeoutIDs: any[];
  venueLinksPlaceholderSurfaceInStatus: boolean;
  imageUploadPreviewURL?: string;
  imageToUpload?: any; // - TODO: -> What kind of data structure will the file be?
  eventSearchActive: boolean;
  venueEvents: Event[];
  eventSearchTerm: string;
  availableSearchedEvents: ITypeAheadSearchItem[];
  venueStatus: VenueStatus;
  address: string;
  city: string;
  country: string;
  coordinates: ICoordinatePair;
  // -> Address typeahead
  addressTypeAheadIsOpen: boolean;
  addressTypeAheadDropCradleInStatus: boolean;
  addressTypeAheadLoadingInStatus: boolean;
  addressTypeAheadResultsInStatus: boolean;
  availableSearchedAddresses: ITypeAheadSearchItem[];
}

export const hydrateExternalLinkEntries = (links: VenueLinks): IPotentialExternalLinkEntry[] => {
  const hydratedLinkEntries: IPotentialExternalLinkEntry[] = [];

  Object.keys(links).map((linkKey: string) => {
    let newLinkEntry: IPotentialExternalLinkEntry = {
      linkName: BANDCAMP,
      URL: "",
      URLFieldTouched: true,
      URLFieldDirty: false,
      errorStatus: false,
      errorMessage: "Please enter a valid URL",
      microAnimationInStatus: true,
    };

    newLinkEntry.linkName = linkKey;
    newLinkEntry.URL = links[linkKey];

    hydratedLinkEntries.push(newLinkEntry);
    return "";
  })

  return hydratedLinkEntries;
}

export const determineSubmissionButtonStatus = (
  props: IAddOrEditVenueFormProps,
  { isValid, dirty }: { isValid: boolean, dirty: boolean }
) => {
  const { formMode } = props;
  let buttonDisabled = false;

  if (formMode === "ADD") {
    if (!isValid || !dirty) buttonDisabled = true;
  }

  return buttonDisabled;
}

// -> VENUE STATUS
export const STATUS__OPERATIONAL = "Operational";
export const STATUS__ON_HIATUS = "On Hiatus";
export const STATUS__CLOSED = "Permanently Closed";
export const availableVenueStatuses = [
  STATUS__OPERATIONAL, STATUS__ON_HIATUS, STATUS__CLOSED,
];
export const STATUS__OPERATIONAL_FR = "Opérationnel";
export const STATUS__ON_HIATUS_FR = "En pause";
export const STATUS__CLOSED_FR = "Définitivement Fermé";
export const availableVenueStatuses_FR = [
  STATUS__OPERATIONAL_FR, STATUS__ON_HIATUS_FR, STATUS__CLOSED_FR,
];

export const mapVenueStatusToEnglishVenueStatus =
  (venueStatus: string, currentLanguageCode: string): VenueStatus => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedVenueStatus: VenueStatus = STATUS__OPERATIONAL;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedVenueStatus = (venueStatus as VenueStatus);
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (venueStatus) {
      case STATUS__OPERATIONAL_FR:
        translatedVenueStatus = STATUS__OPERATIONAL;
        break;
      case STATUS__ON_HIATUS_FR:
        translatedVenueStatus = STATUS__ON_HIATUS;
        break;
      case STATUS__CLOSED_FR:
        translatedVenueStatus = STATUS__CLOSED;
        break;
      default:
        translatedVenueStatus = STATUS__OPERATIONAL;
        break;
    }
  }

  return translatedVenueStatus;
};

export const mapEnglishVenueStatusToTargetLanguage = (venueStatus: VenueStatus, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedVenueStatus: string = STATUS__OPERATIONAL;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedVenueStatus = venueStatus;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (venueStatus) {
      case STATUS__OPERATIONAL:
        translatedVenueStatus = STATUS__OPERATIONAL_FR;
        break;
      case STATUS__ON_HIATUS:
        translatedVenueStatus = STATUS__ON_HIATUS_FR;
        break;
      case STATUS__CLOSED:
        translatedVenueStatus = STATUS__CLOSED_FR;
        break;
      default:
        translatedVenueStatus = STATUS__OPERATIONAL_FR;
        break;
    }
  }

  return translatedVenueStatus;
};

export const determineAvailableVenueStatusesByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableVenueStatuses;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableVenueStatuses = availableVenueStatuses;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableVenueStatuses = availableVenueStatuses_FR;
      break;
    default:
      computedAvailableVenueStatuses = availableVenueStatuses;
      break;
  }

  return computedAvailableVenueStatuses;
};