import { StyleSheet } from  "aphrodite-jss";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { IAddOrEditArtistFormProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IAddOrEditArtistFormProps) => {
  const { palette, distance, fonts, shadows, borderRadii, typography } = themeInfo;
  const { customFormCradleStyles, customFormTitleTextStyles, isInCardMode, rtl } = props;

  return StyleSheet.create({
    formCradle: {
      height: "auto", // - TODO: -> Potentially fix the height at 100% and have any overflow content scroll
      width: "70%",
      maxWidth: 800,
      padding: (isInCardMode) ? distance.three : 0,
      boxShadow: (isInCardMode) ? shadows.one : "none",
      borderRadius: borderRadii.one,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      background: palette.white,
      overflow: "visible",

      "@media (max-width: 750px)": {
        width: "100%",
      },

      "@media (min-width: 1150px)": {
        width: 1000,
      }
    },
    topBarCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    titleText: {
      fontFamily: fonts.primary,
      fontSize: "3rem",
      fontWeight: "bold",
      color: palette.black,
      lineHeight: 0.8,
    },
    labelCradle: {
      display: 'flex',
      alignItmes: 'center',
      justifyContent: (rtl) ? 'flex-end' : 'flex-start',
    },
    labelText: {
      ...themeInfo.typography.default,
      margin: 0,
    },
    addImagePromptSurface: {
      height: "auto",
      minHeight: 150,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: borderRadii.two,
      background: palette.grey1,

      "&:hover": {
        cursor: "pointer",
      },
    },
    circularIconCradle: {
      height: 85,
      width: 85,
      display: "grid",
      placeItems: "center",
      background: "transparent",
      borderRadius: "50%",
      border: `3px solid ${palette.grey6}`
    },
    addInfoPromptText: {
      fontFamily: fonts.primary,
      fontStyle: "italic",
      fontSize: "1.6rem",
      color: palette.grey6,
    },
    nameCradle: {
      height: "auto",
      width: "100%",
      display: "grid",
      alignContent: "center",
      gridTemplateColumns: "1fr",
      gridAutoFlow: "row",
      gap: `${distance.three}px`,

      "@media (max-width: 550px)": {
        gridTemplateColumns: "1fr",
      }
    },
    descriptionCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
    },
    artistEventsTitleText: { ...typography.h2 },
    artistEventsCradle: {
      height: "min-content",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    artistEventPreviewsCradle: {
      height: "auto",
      width: "100%",
      display: "grid",
      // maxHeight: 750,
      // overflowY: "scroll",
      // padding: distance.one,
      alignContent: "center",
      gridTemplateColumns: "1fr 1fr",
      gridAutoFlow: "row",
      gap: `${distance.three}px`,

      "@media (max-width: 550px)": {
        gridTemplateColumns: "1fr",
      }
    },
    artistLinksCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      // overflowY: "scroll",
    },
    artistLinksPlaceholderSurface: {
      height: "100%",
      width: "100%",
      paddingTop: distance.three,
      paddingBottom: distance.three,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: borderRadii.two,
      background: palette.grey1,

      "&:hover": {
        cursor: "pointer",
      },
    },
    artistLinksTopRow: {
      height: "auto",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    artistLinksTitleText: {
      ...typography.h2,
    },
    bottomFormRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    itemSeparatorCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    customFormCradleStyles: { ...customFormCradleStyles },
    customFormTitleTextStyles: { ...customFormTitleTextStyles },
  });
}
