import { StyleSheet } from 'aphrodite-jss';
import { ThemeInfo } from '../../../../Components/UI_InfoProvider/UI_InfoProvider';
import { IRegistrationCardProps, DEFAULT_CARD_WIDTH } from './helpers';

export const styleGen = (props: IRegistrationCardProps, themeInfo: ThemeInfo) => {
  const { customCardCradleStyles, isInCardMode } = props;
  const { distance, shadows, borderRadii, palette, typography } = themeInfo;
  
  return StyleSheet.create({
    cardCradle: {
      height: 'auto',
      width: 650,
      padding: (isInCardMode) ? distance.three: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      boxShadow: (isInCardMode) ? shadows.one : "none",
      borderRadius: borderRadii.two,
      backgroundColor: palette.white,

      "@media (max-width: 680px)": {
        width: DEFAULT_CARD_WIDTH,
      },

      "@media (max-width: 320px)": {
        width: 290,
      }
    },
    topBarCradle: {
      minHeight: 100,
      height: "auto",
      paddingLeft: distance.three,
      paddingRight: distance.three,
      paddingTop: distance.three,
      display: 'flex',
      flexDirection: "column",
      alignItems: 'center',
      backgroundColor: palette.white,
      borderTopLeftRadius: borderRadii.one,
      borderTopRightRadius: borderRadii.one,
    },
    promptText: {
      ...typography.default,
      fontSize: "1.5rem",
      fontStyle: "italic",
      color: palette.grey3,
      textAlign: "center",
      margin: 0,
    },
    cardBottomCradle: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flex: 1,
    },
    customCardCradleStyles: { ...customCardCradleStyles },
  })
}