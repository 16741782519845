import { StyleSheet } from "aphrodite";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DEFAULT_CARD_WIDTH } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo) => (StyleSheet.create({
  cardCradle: {
    height: 'auto',
    width: DEFAULT_CARD_WIDTH,
    paddingLeft: themeInfo.distance.three,
    paddingRight: themeInfo.distance.three,
    paddingTop: themeInfo.distance.three,
    paddingBottom: themeInfo.distance.six,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: themeInfo.shadows.one,
    borderRadius: themeInfo.borderRadii.one,
    backgroundColor: themeInfo.palette.white,

    "@media (max-width: 320px)": {
      width: 290,
    }
  },
  headerText: {
    ...themeInfo.typography.h3,
    color: themeInfo.palette.black,
    textAlign: "center",
    lineHeight: 1.3,
    margin: 0,
  },
  messageText: {
    fontFamily: themeInfo.fonts.primary,
    fontSize: "1.4rem",
    color: themeInfo.palette.black,
    textAlign: "center",
    margin: 0,
  }
}));
