import cloneDeep from 'lodash.clonedeep';

export interface IReducerAction {
  type: string;
  payload?: any;
}

export interface ITypeAheadSearchState {
  items: ITypeAheadSearchItem[];
  isOpen: boolean;
  searchTerm: string;
  dropCradleInStatus: boolean;
  loadingStatus: boolean;
  loadingInStatus: boolean;
  resultsInStatus: boolean;
}

export type TypeAheadSearchItem = string;

export interface ITypeAheadSearchItem {
  id: string;
  name: string;
  imageURL?: string;
}

// -> Actions
const OPEN_TYPE_AHEAD_SEARCH_ACTION = "OPEN_TYPE_AHEAD_SEARCH_ACTION";
const CLOSE_TYPE_AHEAD_SEARCH_ACTION = "CLOSE_TYPE_AHEAD_SEARCH_ACTION";
const TOGGLE_TYPE_AHEAD_SEARCH_ACTION = "TOGGLE_TYPE_AHEAD_SEARCH_ACTION";
const SET_SEARCH_TERM_ACTION = "SET_SEARCH_TERM_ACTION";
const SET_ITEMS_ACTION = "SET_ITEMS_ACTION";
const SET_DROP_CRADLE_IN_STATUS = "SET_DROP_CRADLE_IN_STATUS";
const SET_LOADING_STATUS_ACTION = "SET_LOADING_STATUS_ACTION";
const SET_LOADING_IN_STATUS_ACTION = "SET_LOADING_IN_STATUS_ACTION";
const SET_RESULTS_IN_STATUS_ACTION = "SET_RESULTS_IN_STATUS_ACTION";

// -> Action Creators
export const openTypeAheadSearch = (): IReducerAction => ({ type: OPEN_TYPE_AHEAD_SEARCH_ACTION });
export const closeTypeAheadSearch = (): IReducerAction => ({ type: CLOSE_TYPE_AHEAD_SEARCH_ACTION });
export const toggleTypeAheadSearch = (): IReducerAction => ({ type: TOGGLE_TYPE_AHEAD_SEARCH_ACTION });
export const setDropCradleInStatus = (status: boolean): IReducerAction => ({ type: SET_DROP_CRADLE_IN_STATUS, payload: status })
export const setSearchTerm = (searchTerm: string): IReducerAction => ({ type: SET_SEARCH_TERM_ACTION, payload: searchTerm });
export const setLoadingStatus = (status: boolean): IReducerAction => ({ type: SET_LOADING_STATUS_ACTION, payload: status });
export const setLoadingInStatus = (status: boolean): IReducerAction => ({ type: SET_LOADING_IN_STATUS_ACTION, payload: status });
export const setResultsInStatus = (status: boolean): IReducerAction => ({ type: SET_RESULTS_IN_STATUS_ACTION, payload: status });

export const setItems = ((items: ITypeAheadSearchItem[]): IReducerAction => ({
  type: SET_ITEMS_ACTION,
  payload: items,
}));

// -> Reducer
export const reducer = (
  state: ITypeAheadSearchState,
  action: IReducerAction
): ITypeAheadSearchState => {
  const stateCopy = cloneDeep(state);
  let {
    isOpen, dropCradleInStatus
  } = stateCopy;

  switch(action.type) {
    case OPEN_TYPE_AHEAD_SEARCH_ACTION:
      // console.log('[TypeAheadSearch Reducer]: Open TypeAheadSearch Action Fired');
      isOpen = true;
      dropCradleInStatus = true;
      return { ...stateCopy, isOpen, dropCradleInStatus };
    case CLOSE_TYPE_AHEAD_SEARCH_ACTION:
      // console.log('[TypeAheadSearch Reducer]: Close TypeAheadSearch Action Fired');
      isOpen = false;
      dropCradleInStatus = false;
      return { ...stateCopy, isOpen, dropCradleInStatus };
    case TOGGLE_TYPE_AHEAD_SEARCH_ACTION:
      // console.log('[TypeAheadSearch Reducer]: Toggle TypeAheadSearch Action Fired');
      isOpen = !isOpen;
      return { ...stateCopy, isOpen };
    case SET_ITEMS_ACTION:
      // console.log('[Dropdown Reducer]: Set Items Action Fired');
      return { ...stateCopy, items: action.payload };
    case SET_DROP_CRADLE_IN_STATUS:
      return { ...stateCopy, dropCradleInStatus: action.payload };
    case SET_SEARCH_TERM_ACTION:
      return { ...stateCopy, searchTerm: action.payload };
    case SET_LOADING_STATUS_ACTION:
      return { ...stateCopy, loadingStatus: action.payload };
    case SET_LOADING_IN_STATUS_ACTION:
      return { ...stateCopy, loadingInStatus: action.payload };
    case SET_RESULTS_IN_STATUS_ACTION:
      return { ...stateCopy, resultsInStatus: action.payload };
    default: break;
  }

  return { ...stateCopy };
}
