import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { IArtistDetailsProps, DEFAULT_IMAGE_SIZE, IArtistDetailsState } from "./helpers";

export const styleGen = (
  themeInfo: ThemeInfo,
  props: IArtistDetailsProps,
  state: IArtistDetailsState
) => {
  const { eventsScrollActive, hasMultipleExternalLinks } = state;
  const { customComponentCradleStyles, customArtistNameTextStyles, actionHistoryCardMode } = props;
  const {
    palette, distance, fonts, typography, borderRadii, gradients,
  } = themeInfo;

  return StyleSheet.create({
    componentCradle: {
      height: "100%",
      width: "60%",
      paddingTop: distance.three,
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      backgroundColor: palette.white,

      "@media (max-width: 750px)": {
        width: "100%",
      },

      "@media (min-width: 1150px)": {
        width: 1000,
      }
    },
    topSectionCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "flex-start",

      "@media (max-width: 550px)": {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }
    },
    fallbackIconCradle: {
      height: DEFAULT_IMAGE_SIZE,
      width: DEFAULT_IMAGE_SIZE,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: "50%",
    },
    artistDetailsCradle: {
      height: "auto",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      flex: 1,

      "@media (max-width: 550px)": {
        width: "100%",
        flex: "0 1 auto",
      }
    },
    artistNameRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    artistNameText: {
      fontFamily: fonts.primary,
      fontSize: "3.4rem",
      fontWeight: "bold",
      color: palette.black,
    },
    artistLinksCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: (hasMultipleExternalLinks) ? "center" : "flex-start",
      alignItems: "center",
    },
    noArtistLinksFallbackCradle: {
      height: "auto",
      width: "100%",
      paddingTop: distance.three,
      paddingBottom: distance.three,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    h2Text: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: actionHistoryCardMode ? "1.6rem" : "2rem",
      color: palette.black,
    },
    h2Cradle: {
      padding: distance.one,
      borderRadius: borderRadii.one,
      background: palette.grey1,
      width: "min-content",
      "whiteSpace": "nowrap",
    },
    noContentFallbackCradle: {
      height: "auto",
      width: "100%",
      paddingTop: distance.three,
      paddingBottom: distance.three,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    bodyText: {
      fontFamily: fonts.primary,
      fontSize: actionHistoryCardMode ? "1.2rem" : "1.4rem",
      color: palette.black,
    },
    detailsLabelCradle: {
      padding: distance.one,
      borderRadius: borderRadii.one,
      background: palette.grey1,
      width: "min-content",
      "whiteSpace": "nowrap",
    },
    artistSongsCradle: {
      height: "auto",
      maxHeight: 600,
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    artistEventsTitleBarCradle: {
      height: 'min-content',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    artistEventsTitleBarText: {
      fontFamily: fonts.primary,
      fontWeight: 'bold',
      fontSize: '2rem',
    },
    artistSongsTitleCradle: {
      padding: distance.one,
      borderRadius: borderRadii.one,
      background: palette.grey1,
      width: "min-content",
      "whiteSpace": "nowrap",
    },
    artistSongsListCradle: {
      height: '100%',
      width: '100%',
      position: "relative",
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      overflowY: "scroll",
      // backgroundImage: gradients.activeScrollContainerTopGradient,
    },
    scrollOverlay: {
      height: "100%",
      width: "100%",
      position: "absolute",
      transition: "all 0.25s ease-in-out",
      backgroundImage: (eventsScrollActive) ? gradients.activeScrollContainerTopGradient : "none",
    },
    artistNoSongsCradle: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    itemSeparatorCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    noContentFallbackText: { ...typography.noContentFallbackText },
    customComponentCradleStyles: { ...customComponentCradleStyles },
    customArtistNameTextStyles: { ...customArtistNameTextStyles }
  });
}
