import { StyleSheet } from "aphrodite-jss";
import { NAV_BAR_HEIGHT } from "../../../../constants";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider"
import { TOP_BAR_DEFAULT_HEIGHT } from "../TopBar/helpers";
import { VenueListProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: VenueListProps) => {
  const { customVenueListCradleStyles } = props;
  const { palette, distance, typography } = themeInfo;

  return StyleSheet.create({
    venueListCradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT + TOP_BAR_DEFAULT_HEIGHT}px)`,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      backgroundColor: palette.white,
    },
    venueContentCradle: {
      height: "100%",
      width: "100%",
      padding: distance.three,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      backgroundColor: palette.white,
    },
    noVenuesCradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT + TOP_BAR_DEFAULT_HEIGHT}px)`,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: palette.white,
    },
    noContentFallbackText: {
      ...typography.noContentFallbackText,
    },
    itemSeparatorCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    customVenueListCradleStyles: { ...customVenueListCradleStyles },
  });
}
