// -> Beyond codebase
import React, { useContext, useEffect, useState } from "react";
import { css } from "aphrodite-jss";
import { debounce } from "lodash";
// -> Within codebase
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import { PersonIcon } from "../../VisualUtilities/IconPresets";
import { UIContext, ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { determineScreenDimensions } from "../../../helpers";
import { ROUND, SCREEN_WIDTH_450, VERTICAL } from "../../../constants";
// -> Within component
import { styleGen } from "./ArtistCardStyles";
import { IArtistCardProps, IMAGE_SIZE_LG, IMAGE_SIZE_SM } from "./helpers";

const ArtistCard: React.FC<IArtistCardProps> = (props) => {
  const [computedImageSize, setComputedImageSize] = useState(IMAGE_SIZE_LG);
  const { name, imageURL, imageSize, onClick } = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const {
    cardCradle, imgSection, nameRow, nameText,
    fallbackIconCradle, customCardCradleStyles, customNameTextStyles
  } = styleGen(themeInfo, props, computedImageSize);

  const computedOnClick = (onClick) ? onClick : () => {};

  useEffect(() => {
    const { width: screenWidth } = determineScreenDimensions();

    if (screenWidth <= SCREEN_WIDTH_450) {
      setComputedImageSize(IMAGE_SIZE_SM);
    }

    const handleResize = debounce(() => {
      const { width: screenWidth } = determineScreenDimensions();
      if (screenWidth <= SCREEN_WIDTH_450) {
        setComputedImageSize(IMAGE_SIZE_SM);
      }
      else setComputedImageSize(imageSize ? imageSize : IMAGE_SIZE_LG);
    }, 10);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [setComputedImageSize, imageSize]);

  return (
    <div className={css(cardCradle, customCardCradleStyles)} onClick={computedOnClick}>
      <div className={css(imgSection)}>
        {
          (imageURL) ? (
            <ImageWrapper
              alt={`Artist: ${name}`} src={imageURL}
              borderGeometry={ROUND}
              height={computedImageSize} width={computedImageSize}
            />
          ) : (
            <div className={css(fallbackIconCradle)}>
              <PersonIcon color={themeInfo.palette.grey2} size={computedImageSize - 65} />
            </div>
          )
        }
      </div>
      <Spacer direction={VERTICAL} amount={distance.three} />
      <div className={css(nameRow)}>
        <p className={css(nameText, customNameTextStyles)}>{name}</p>
      </div>
    </div>
  );
}

export default ArtistCard;
