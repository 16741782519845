// -> Beyond Codebase
import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import { css } from 'aphrodite-jss';
import classNames from "classnames";
import KUTE from "kute.js";
// -> Within Codebase
import Button from '../../Components/Button/Button';
import FillUnderNavBarCradle from '../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle';
import Spacer from '../../Components/LayoutUtilities/Spacer/Spacer';
import { UIContext } from '../../Components/UI_InfoProvider/UI_InfoProvider';
// import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import { ROUNDED, SPACE_CHAR_UNICODE, VENUES_ROUTE, VERTICAL } from '../../constants';
// import { underConstructionAlert } from '../../helpers';
// -> Within Component
import styles from "./homeStyles.module.css";
import { styleGen } from './HomeStyles';
import LinkWrapper from 'src/Components/VisualUtilities/LinkWrapper/LinkWrapper';

const VENUE_ADDITION_FORM_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSe6WxEt29MZvlnCrrqDsPzVEqXb1-tHM-su-pCtMtANE5E4gQ/viewform?usp=sf_link";

const Home: React.FC = () => {
  const history = useHistory();
  const UIInfo = useContext(UIContext);
  const { themeInfo, hideNavBar } = UIInfo;
  const { distance, palette, fonts, transitions, shadows } = themeInfo;
  const {
    cradle, titleText, cardCradle, blobGroup1Cradle, blobGroup2Cradle,
    blobGroup3Cradle, blobGroup4Cradle, bodyText, topOfCard, addVenueRow,
    addVenuePromptText, titleCradle, blobGroup5Cradle, blobGroup6Cradle,
    blobGroup7Cradle, blobGroup8Cradle,
  } = styleGen(themeInfo);
  let tween1 = useRef<any>(null);
  let tween2 = useRef<any>(null);
  let tween3 = useRef<any>(null);
  let tween4 = useRef<any>(null);
  let tween5 = useRef<any>(null);
  let tween6 = useRef<any>(null);
  let tween7 = useRef<any>(null);
  let tween8 = useRef<any>(null);

  useEffect(() => {
    hideNavBar();

    tween1.current = KUTE.fromTo(
      "#blob1",
      { path: "#blob1" },
      { path: "#blob2" },
      { repeat: 999, duration: 7000, yoyo: true }
    );
    
    tween2.current = KUTE.fromTo(
      "#blob3",
      { path: "#blob3" },
      { path: "#blob4" },
      { repeat: 999, duration: 7000, yoyo: true }
    );
    
    tween3.current = KUTE.fromTo(
      "#blob5",
      { path: "#blob5" },
      { path: "#blob6" },
      { repeat: 999, duration: 7000, yoyo: true }
    );
    
    tween4.current = KUTE.fromTo(
      "#blob7",
      { path: "#blob7" },
      { path: "#blob8" },
      { repeat: 999, duration: 7000, yoyo: true }
    );
    
    tween5.current = KUTE.fromTo(
      "#blob9",
      { path: "#blob9" },
      { path: "#blob10" },
      { repeat: 999, duration: 7000, yoyo: true }
    );
    
    tween6.current = KUTE.fromTo(
      "#blob11",
      { path: "#blob11" },
      { path: "#blob12" },
      { repeat: 999, duration: 7000, yoyo: true }
    );

    tween7.current = KUTE.fromTo(
      "#blob13",
      { path: "#blob13" },
      { path: "#blob14" },
      { repeat: 999, duration: 7000, yoyo: true }
    );

    tween8.current = KUTE.fromTo(
      "#blob15",
      { path: "#blob15" },
      { path: "#blob16" },
      { repeat: 999, duration: 7000, yoyo: true }
    );
    
    tween1.current.start();
    tween2.current.start();
    tween3.current.start();
    tween4.current.start();
    tween5.current.start();
    tween6.current.start();
    tween7.current.start();
    tween8.current.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNavigateToVenues = () => history.push(VENUES_ROUTE);

  return (
    <FillUnderNavBarCradle>
      <div className={css(cradle)}>
        <div className={classNames(styles.SVGSpacer, styles.spacerLayer1)}></div>

        <div className={css(cardCradle)}>
          <div className={css(topOfCard)}>
            <div className={css(titleCradle)}>
              <p className={css(titleText)}>WaveMap</p>
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />
            <p className={css(bodyText)}>A directory of wave-friendly venues around the world.</p>
            <Spacer direction={VERTICAL} amount={distance.three} />
            <Button
              buttonText="GO TO VENUES"
              buttonGeometry={ROUNDED}
              onClick={() => onNavigateToVenues()}
              customTextStyles={{ fontWeight: "bold", fontSize: "1.2rem" }}
              customCradleStyles={{ transition: transitions.boxShadowTransition }}
              customCradleHoverStyles={{ boxShadow: shadows.two }}
            />
          </div>
          <Spacer direction={VERTICAL} amount={distance.three} />
          <div className={css(addVenueRow)}>
            <p className={css(addVenuePromptText)}>
              {`Know of a venue that's not on the map? Use the ${SPACE_CHAR_UNICODE}`}
              <LinkWrapper
                link={VENUE_ADDITION_FORM_LINK}
                customStyles={{
                  fontFamily: fonts.primary,
                  fontSize: "1.4rem",
                  color: palette.primary,
                  "&:hover": { textDecoration: "underline" }
                }}
              >
                {"Venue Addition Request Form."}
              </LinkWrapper>
            </p>
          </div>
        </div>

        <div className={css(blobGroup1Cradle)}>
          <svg id="visual" viewBox="0 0 900 300" width="900" height="300" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(430.4972741255753 104.77512950718352)">
              <path id="blob1" d="M38.6 -37.5C51.8 -25.5 65.4 -12.7 65.7 0.4C66.1 13.4 53.2 26.9 40 35.2C26.9 43.5 13.4 46.8 -5.1 51.8C-23.6 56.9 -47.1 63.8 -63 55.5C-78.8 47.1 -86.9 23.6 -86.7 0.2C-86.4 -23.1 -77.9 -46.2 -62 -58.2C-46.2 -70.2 -23.1 -71.1 -5.2 -65.9C12.7 -60.7 25.5 -49.5 38.6 -37.5" fill={palette.primary}></path>
            </g>

            <g transform="translate(417.6019911389177 96.6371453393497)" style={{ visibility: "hidden" }}>
              <path id="blob2" d="M57.9 -60C69.5 -46.2 69.8 -23.1 70 0.2C70.2 23.6 70.5 47.1 58.8 63.1C47.1 79.1 23.6 87.6 5.9 81.7C-11.8 75.8 -23.6 55.6 -35.7 39.6C-47.9 23.6 -60.5 11.8 -64.1 -3.7C-67.8 -19.1 -62.5 -38.2 -50.4 -52C-38.2 -65.9 -19.1 -74.4 2 -76.4C23.1 -78.4 46.2 -73.9 57.9 -60" fill={palette.primary}></path>
            </g>
          </svg>
        </div>

        <div className={css(blobGroup2Cradle)}>
          <svg id="visual" viewBox="0 0 900 300" width="900" height="300" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(655.48154800982473 143.579782919089)">
              <path id="blob3" d="M62 -69.1C76.8 -47.1 82.9 -23.6 87.6 4.7C92.3 33 95.7 66 80.8 91C66 116 33 133 4.8 128.2C-23.3 123.3 -46.7 96.7 -59.2 71.7C-71.7 46.7 -73.3 23.3 -77.3 -4C-81.4 -31.3 -87.7 -62.7 -75.2 -84.7C-62.7 -106.7 -31.3 -119.3 -3.9 -115.5C23.6 -111.6 47.1 -91.1 62 -69.1" fill={palette.primary}></path>
            </g>

            <g transform="translate(669.1828581927117 149.73535625627454)" style={{ visibility: "hidden" }}>
              <path id="blob4" d="M65.8 -63.3C85 -46.7 100 -23.3 101.9 1.9C103.8 27.1 92.5 54.2 73.4 69.9C54.2 85.5 27.1 89.8 2.1 87.7C-22.9 85.5 -45.7 77.1 -70.7 61.4C-95.7 45.7 -122.9 22.9 -120.3 2.6C-117.7 -17.7 -85.4 -35.4 -60.4 -52C-35.4 -68.7 -17.7 -84.3 2.8 -87.2C23.3 -90 46.7 -80 65.8 -63.3" fill={palette.primary}></path>
            </g>
          </svg>
        </div>

        <div className={css(blobGroup3Cradle)}>
          <svg id="visual" viewBox="0 0 900 400" width="900" height="400" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(141.60282128769205 222.74973895699654)">
              <path id="blob5" d="M59.9 -58.5C84.4 -35.4 115.7 -17.7 124.5 8.8C133.4 35.4 119.7 70.7 95.2 90.7C70.7 110.7 35.4 115.4 7.1 108.3C-21.2 101.2 -42.4 82.4 -58.4 62.4C-74.4 42.4 -85.2 21.2 -89 -3.8C-92.8 -28.8 -89.5 -57.5 -73.5 -80.7C-57.5 -103.8 -28.8 -121.4 -5.5 -115.9C17.7 -110.3 35.4 -81.7 59.9 -58.5" fill={palette.primary}></path>
            </g>

            <g transform="translate(164.39712119246417 192.28287784162862)" style={{ visibility: "hidden" }}>
              <path id="blob6" d="M85.5 -86.7C100.4 -70.7 94.7 -35.4 85.9 -8.8C77 17.7 65 35.4 50.2 48.7C35.4 62 17.7 71 -2 73C-21.7 75 -43.4 70 -64.2 56.7C-85 43.4 -105 21.7 -103.4 1.6C-101.7 -18.4 -78.4 -36.8 -57.6 -52.8C-36.8 -68.8 -18.4 -82.4 8.5 -90.9C35.4 -99.4 70.7 -102.7 85.5 -86.7" fill={palette.primary}></path>
            </g>
          </svg>
        </div>

        <div className={css(blobGroup4Cradle)}>
          <svg id="visual" viewBox="0 0 900 375" width="900" height="375" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(428.72153239417514 238.72494255914415)">
              <path id="blob7" d="M87.6 -89.8C108.3 -66.9 116.1 -33.5 110.7 -5.4C105.3 22.6 86.6 45.3 65.9 57.8C45.3 70.3 22.6 72.6 -6.1 78.8C-34.9 84.9 -69.8 94.8 -93.4 82.3C-117.1 69.8 -129.6 34.9 -129.8 -0.2C-130 -35.4 -118 -70.7 -94.4 -93.5C-70.7 -116.4 -35.4 -126.7 -0.9 -125.7C33.5 -124.8 66.9 -112.6 87.6 -89.8" fill={palette.primary}></path>
            </g>

            <g transform="translate(412.9894050294901 202.85213826348004)" style={{ visibility: "hidden" }}>
              <path id="blob8" d="M73.4 -64.1C98.2 -48.6 123.6 -24.3 126.8 3.2C130 30.6 110.9 61.3 86.1 82.4C61.3 103.6 30.6 115.3 -2.4 117.7C-35.4 120 -70.7 113 -91.4 91.9C-112 70.7 -118 35.4 -109.2 8.8C-100.3 -17.7 -76.7 -35.4 -56 -50.9C-35.4 -66.4 -17.7 -79.7 3.3 -83C24.3 -86.3 48.6 -79.6 73.4 -64.1" fill={palette.primary}></path>
            </g>
          </svg>
        </div>

        <div className={css(blobGroup5Cradle)}>
          <svg id="visual" viewBox="0 0 900 300" width="900" height="300" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(256.0485343113682 152.32677220812053)">
              <path id="blob9" d="M34 -32.8C43 -25 48.5 -12.5 48.8 0.4C49.2 13.2 44.4 26.4 35.4 32.7C26.4 38.9 13.2 38.2 0.5 37.7C-12.3 37.3 -24.5 37 -37 30.8C-49.5 24.5 -62.3 12.3 -60.8 1.4C-59.4 -9.4 -43.9 -18.9 -31.4 -26.7C-18.9 -34.5 -9.4 -40.8 1.5 -42.3C12.5 -43.8 25 -40.7 34 -32.8" fill={palette.primary}></path>
            </g>

            <g transform="translate(251.13976993532253 142.99569746505986)" style={{ visibility: "hidden" }}>
              <path id="blob10" d="M31.7 -34.5C38 -25.5 37.7 -12.7 37.1 -0.6C36.6 11.5 35.6 23.1 29.4 35.6C23.1 48.1 11.5 61.5 -0.4 61.9C-12.3 62.3 -24.5 49.5 -31.5 37C-38.5 24.5 -40.3 12.3 -39.5 0.7C-38.8 -10.8 -35.7 -21.7 -28.7 -30.7C-21.7 -39.7 -10.8 -46.8 0.9 -47.8C12.7 -48.7 25.5 -43.5 31.7 -34.5" fill={palette.primary}></path>
            </g>
          </svg>
        </div>

        <div className={css(blobGroup6Cradle)}>
          <svg id="visual" viewBox="0 0 900 300" width="900" height="300" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(738.323559495701 146.43967206480943)">
              <path id="blob11" d="M39.2 -33.9C51.5 -26.9 62.8 -13.4 64.9 2.1C67 17.7 60 35.4 47.7 43C35.4 50.7 17.7 48.3 4.4 43.9C-8.8 39.5 -17.7 33 -25.5 25.3C-33.3 17.7 -40.2 8.8 -41.6 -1.5C-43.1 -11.8 -39.2 -23.6 -31.4 -30.6C-23.6 -37.6 -11.8 -39.8 0.8 -40.6C13.4 -41.4 26.9 -40.9 39.2 -33.9" fill={palette.primary}></path>
            </g>

            <g transform="translate(761.04137693413077 147.5596663358425)" style={{ visibility: "hidden" }}>
              <path id="blob12" d="M35.9 -39.2C42.6 -29.2 41.3 -14.6 39.6 -1.6C38 11.3 36 22.6 29.3 33.5C22.6 44.3 11.3 54.6 -2.2 56.9C-15.8 59.1 -31.6 53.3 -44.1 42.4C-56.6 31.6 -65.8 15.8 -62.3 3.5C-58.8 -8.8 -42.7 -17.7 -30.2 -27.7C-17.7 -37.7 -8.8 -48.8 2.9 -51.7C14.6 -54.6 29.2 -49.2 35.9 -39.2" fill={palette.primary}></path>
            </g>
          </svg>
        </div>

        <div className={css(blobGroup7Cradle)}>
          <svg id="visual" viewBox="0 0 900 300" width="900" height="300" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(68.5060371946087 102.2769146262778)">
              <path id="blob13" d="M35.1 -32.7C43.7 -26.4 47.9 -13.2 47.7 -0.1C47.6 13 43.3 25.9 34.6 35.3C25.9 44.6 13 50.3 -2.1 52.4C-17.2 54.5 -34.4 53.1 -46.9 43.7C-59.4 34.4 -67.2 17.2 -64 3.2C-60.8 -10.8 -46.7 -21.7 -34.2 -27.9C-21.7 -34.2 -10.8 -35.8 1.2 -37C13.2 -38.2 26.4 -38.9 35.1 -32.7" fill="#9611d8"></path>
            </g>

            <g transform="translate(50.9274151666713 104.348809758765)" style={{ visibility: "hidden" }}>
              <path id="blob14" d="M31.3 -29.8C42.3 -20.3 54.1 -10.1 57.9 3.8C61.7 17.7 57.4 35.4 46.4 46.2C35.4 57 17.7 61 1.1 60C-15.6 58.9 -31.1 52.8 -37.4 41.9C-43.6 31.1 -40.6 15.6 -38.6 2C-36.6 -11.5 -35.6 -23.1 -29.4 -32.6C-23.1 -42.1 -11.5 -49.5 -0.7 -48.8C10.1 -48.1 20.3 -39.3 31.3 -29.8" fill="#9611d8"></path>
            </g>
          </svg>
        </div>

        <div className={css(blobGroup8Cradle)}>
          <svg id="visual" viewBox="0 0 900 300" width="900" height="300" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(840.30502133406617 187.74400757678137)">
              <path id="blob15" d="M29.2 -29.5C40.7 -17.7 54.8 -8.8 59.3 4.4C63.7 17.7 58.4 35.4 46.9 47.5C35.4 59.7 17.7 66.3 1.9 64.5C-13.9 62.6 -27.8 52.1 -34.1 40C-40.5 27.8 -39.2 13.9 -40.1 -0.8C-40.9 -15.6 -43.8 -31.1 -37.4 -42.9C-31.1 -54.8 -15.6 -62.9 -3.4 -59.5C8.8 -56.2 17.7 -41.3 29.2 -29.5" fill="#9611d8"></path>
            </g>

            <g transform="translate(852.9355202421557 196.64115773770067)" style={{ visibility: "hidden" }}>
              <path id="blob16" d="M43.3 -43.1C54.9 -31.6 62.5 -15.8 59 -3.5C55.5 8.8 41 17.7 29.3 26C17.7 34.3 8.8 42.2 -3.7 45.9C-16.3 49.6 -32.5 49.2 -45 40.9C-57.5 32.5 -66.3 16.3 -65.7 0.6C-65.1 -15.1 -55.2 -30.2 -42.7 -41.7C-30.2 -53.2 -15.1 -61.1 0.4 -61.4C15.8 -61.8 31.6 -54.6 43.3 -43.1" fill="#9611d8"></path>
            </g>
          </svg>
        </div>

        <div className={classNames(styles.SVGSpacer, styles.spacerLayer2)}></div>
      </div>
    </FillUnderNavBarCradle>
  );
}

export default Home;
