import { StyleSheet } from 'aphrodite-jss';
import { ThemeInfo } from '../../../UI_InfoProvider/UI_InfoProvider';
import {
  ITypeAheadSearchItemProps, determineInnerCradleBackgroundColor, DEFAULT_IMAGE_SIZE
} from './helpers';

export const styleGen = (
  props: ITypeAheadSearchItemProps,
  themeInfo: ThemeInfo,
) => {
  const {
    height, customTypeAheadSearchItemCradleStyles, customTypeAheadSearchItemTextStyles
  } = props;
  const { palette, distance, borderRadii, fonts } = themeInfo;


  return StyleSheet.create({
    searchResultItemCradle: {
      width: 'auto',
      height,
    },
    innerCradle: {
      height: '100%',
      width: 'auto',
      paddingLeft: distance.one,
      paddingRight: distance.one,
      paddingTop: distance.one,
      paddingBottom: distance.one,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      transition: themeInfo.transitions.bgColorTransition,
      backgroundColor:
        determineInnerCradleBackgroundColor(props, themeInfo, { isInHoverState: false }),
      '&:hover': {
        cursor: "pointer",
        backgroundColor:
          determineInnerCradleBackgroundColor(props, themeInfo, { isInHoverState: true }),
      },
    },
    innerCradleRounded: { borderRadius: borderRadii.one },
    innerCradleRound: { borderRadius: 10000 },
    searchResultImageCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    fallbackIconCradle: {
      height: DEFAULT_IMAGE_SIZE,
      width: DEFAULT_IMAGE_SIZE,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: borderRadii.one,
    },
    rightRowContentCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    searchItemTextStyles: {
      fontFamily: fonts.primary,
      // fontWeight: "bold",
      fontSize: "1.4rem",
      color: palette.black,
      "@media (max-width: 450px)": { fontSize: "1.2rem" },
    },
    customTypeAheadSearchItemCradleStyles: { ...customTypeAheadSearchItemCradleStyles },
    customTypeAheadSearchItemTextStyles: { ...customTypeAheadSearchItemTextStyles },
  });
}
