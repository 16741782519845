import { MFAType, MFAScenario } from "../../../../Types";
import { obscureUserInfo } from "../../../../helpers";

export const DEFAULT_CARD_WIDTH = 350;
export const DIGIT_ONE = "digit1";
export const DIGIT_TWO = "digit2";
export const DIGIT_THREE = "digit3";
export const DIGIT_FOUR = "digit4";
export const DIGIT_FIVE = "digit5";
export const DIGIT_SIX = "digit6";

export interface IAuthenticateMFACardProps {
  onSubmitCode(MFAType: MFAType, code: string): any;
  resendCode(): any;
  MFAType: MFAType;
  email: string;
  phoneNumber: string;
  MFAScenario: MFAScenario;
}

export interface IAuthenticateMFAFormState {
  userCode: string;
}

// - TODO: -> Think about the options around obscuring parts of these emails or telephone numbers.
//           -> Obscuring is relatively easy, but there's a lot of edge cases for phone number formats.
export const computeAuthenticationMessage = (
  t: any,
  args: IAuthMessageComputationArgs
): string => {
  // console.log("computeAuthenticationMessage(): args -> ", args);
  const { MFAType, email, phoneNumber } = args;
  let computedMessage = "";

  if (MFAType === "Email") {
    computedMessage = t("authenticationPrompt", { contactPoint: obscureUserInfo(email) });
  } else if (MFAType === "SMS") {
    computedMessage = t("authenticationPrompt", { contactPoint: obscureUserInfo(phoneNumber) });
  }

  return computedMessage;
};

interface IAuthMessageComputationArgs {
  MFAType?: MFAType;
  email: string;
  phoneNumber: string;
  MFAScenario: MFAScenario;
}
