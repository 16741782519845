import { Venue } from "../../../../Types";

export const BASE_CARD_WIDTH = 225;

export interface VenueGalleryProps {
  venues: Venue[];
  onVenueClick(venueID: string): any;
  customVenueGalleryCradleStyles?: any;
}

export const SMALL_CARD_WIDTH = BASE_CARD_WIDTH - 25;
export const LARGE_CARD_WIDTH = BASE_CARD_WIDTH;
export const GALLERY_WIDTH_BREAKPOINT = 525;