import { Event, EventStatus, EventType } from "../../../Types";

export const IMAGE_SIZE_LG = 135;
export const IMAGE_SIZE_SM = 100;
export const BASE_CARD_WIDTH = 425;
export const BASE_CARD_HEIGHT = 190;
export const CARD_ASPECT_RATIO = (150/425);
export const SMALL_CARD_HEIGHT = BASE_CARD_HEIGHT - 50;

export interface IEventCardProps {
  // id: string;
  // title: string;
  // imageURL?: string;
  imageSize?: number;
  onClick?: any;
  // city: string;
  // country: string;
  // eventStatus: EventStatus;
  // eventType: EventType;
  event: Event;
  // onArtistClick(artistID: string): any;
  customCardCradleStyles?: any;
  customEventTitleTextStyles?: any;
  // customSongMetadataValueTextStyles?: any;
  // customSongMetadataLabelTextStyles?: any;
}
