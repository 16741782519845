// -> Beyond Codebase
import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Switch, Route, Redirect } from 'react-router-dom';
// -> Within Codebase
import LoginProtectedRoute from '../LoginProtectedRoute/LoginProtectedRoute';
import TestGround from '../../Pages/TestGround/TestGround';
import Venues from "../../Pages/Venues/Venues";
import VenuePage from "../../Pages/Venue/Venue";
import AddVenue from "src/Pages/AddVenue/AddVenue";
import EditVenue from "src/Pages/EditVenue/EditVenue";
import EventPage from "../../Pages/Event/Event";
import Events from "../../Pages/Events/Events";
import AddEvent from "src/Pages/AddEvent/AddEvent";
import EditEvent from "src/Pages/EditEvent/EditEvent";
import Artists from 'src/Pages/Artists/Artists';
import ArtistPage from "../../Pages/Artist/Artist";
import AddArtist from "src/Pages/AddArtist/AddArtist";
import EditArtist from "src/Pages/EditArtist/EditArtist";
import Home from '../../Pages/Home/Home';
import AdminDashboard from "../../Pages/AdminDashboard/AdminDashboard";
import Login from "../../Pages/Login/Login";
import Registration from "../../Pages/Registration/Registration";
import RequestReset from "../../Pages/RequestReset/RequestReset";
import ResetPassword from "../../Pages/ResetPassword/ResetPassword";
import RouteNotFoundFallback from '../Fallbacks/RouteNotFoundFallback/RouteNotFoundFallback';
import EnableMFA from 'src/Pages/EnableMFA/EnableMFA';
import AuthenticateMFA from 'src/Pages/AuthenticateMFA/AuthenticateMFA';
import {
  HOME_ROUTE, VENUE_ROUTE, VENUES_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE,
  REQUEST_RESET_ROUTE, RESET_PASSWORD_ROUTE, ENABLE_MFA_ROUTE, AUTHENTICATE_MFA_ROUTE,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PROFILE_ROUTE, EVENT_ROUTE, ADMIN_DASHBOARD_ROUTE, ARTIST_ROUTE, ARTISTS_ROUTE,
  TEST_GROUND_ROUTE, EVENTS_ROUTE, ADD_VENUE_ROUTE, EDIT_VENUE_ROUTE,
  ADD_EVENT_ROUTE, EDIT_EVENT_ROUTE, ADD_ARTIST_ROUTE, EDIT_ARTIST_ROUTE,
} from '../../constants';

const RoutingModule: React.FC = () => {
  return (
    <Switch>
      <Route exact path={HOME_ROUTE} component={Home} />
      <Route exact path={VENUE_ROUTE} component={VenuePage} />
      <Route exact path={VENUES_ROUTE} component={Venues} />
      <Route exact path={ADD_VENUE_ROUTE} component={AddVenue} />
      <Route exact path={EDIT_VENUE_ROUTE} component={EditVenue} />
      <Route exact path={EVENT_ROUTE} component={EventPage} />
      <Route exact path={EVENTS_ROUTE} component={Events} />
      <Route exact path={ADD_EVENT_ROUTE} component={AddEvent} />
      <Route exact path={EDIT_EVENT_ROUTE} component={EditEvent} />
      <Route exact path={ARTIST_ROUTE} component={ArtistPage} />
      <Route exact path={ARTISTS_ROUTE} component={Artists} />
      <Route exact path={ADD_ARTIST_ROUTE} component={AddArtist} />
      <Route exact path={EDIT_ARTIST_ROUTE} component={EditArtist} />
      <Route exact path={LOGIN_ROUTE} component={Login} />
      <Route exact path={ADMIN_DASHBOARD_ROUTE} component={AdminDashboard} />
      <Route exact path={REGISTRATION_ROUTE} component={Registration} />
      <Route exact path={REQUEST_RESET_ROUTE} component={RequestReset} />
      <Route exact path={RESET_PASSWORD_ROUTE} component={ResetPassword} />
      <Route exact path={TEST_GROUND_ROUTE} component={TestGround} />
      <LoginProtectedRoute exact path={ENABLE_MFA_ROUTE} component={EnableMFA} />
      <Route exact path={AUTHENTICATE_MFA_ROUTE} component={AuthenticateMFA} />
      {/* <Redirect exact from={HOME_ROUTE} to={PROFILE_ROUTE} /> */}
      <Route path="*" component={RouteNotFoundFallback} />
    </Switch>
  );
}

export default RoutingModule;
