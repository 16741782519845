import React, { useContext, useEffect, useState } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// -> Within codebase
import ImageWrapper from "../../../../Components/VisualUtilities/ImageWrapper/ImageWrapper";
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import IconButton from "../../../../Components/Button/Variants/IconButton/IconButton";
import Fade from "../../../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import { PersonIcon } from "../../../../Components/VisualUtilities/IconPresets";
import EditIcon from "../../../../Components/VisualUtilities/IconPresets/EditIcon";
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../../../Components/DataContextProvider/DataContextProvider";
import { AuthContext } from "../../../../Components/AuthContextProvider/AuthContextProvider";
import {
  DEFAULT_TRANSITION_MICROANIMATION_TIME, HORIZONTAL, ROUND, VERTICAL
} from "../../../../constants";
import { underConstructionAlert, customAlertGen } from "../../../../helpers";
import DashboardRoutingLinks from "../DashboardRoutingLinks/DashboardRoutingLinks";
// -> Within component
import RecentActionsFeed from "../RecentActionsFeed/RecentActionsFeed";
import { styleGen } from "./AdminDashboardDetailsStyles";
import {
  IAdminDashboardDetailsState, IAdminDashboardDetailsProps,
} from "./helpers";
import { Artist, Event, Venue } from "src/Types";

export const AdminDashboardDetails: React.FC<IAdminDashboardDetailsProps> = (props) => {
  const [state, setState] = useState<IAdminDashboardDetailsState>({
    mainContentInStatus: true,
  });

  const { onOpenModal, actions } = props;
  const { t } = useTranslation("page_adminDashboard");
  const history = useHistory();
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const {
    state: { artists, events, venues }
  } = useContext(DataContext);
  const { distance, palette, styles } = themeInfo;
  const {
    componentCradle, topSectionCradle, fallbackIconCradle,
    dashboardTopDetailsCradle, dashboardTitleRow, dashboardTitleText,
    navLinkRow, metricsGridCradle, metricCradle, metricValueText,
    metricContextText,
  } = styleGen(themeInfo, props);

  let mainContentMicroanimationTimeout: any = -1;
  let baselineEvents: Event[] = [];
  let baselineVenues: Venue[] = [];
  let baselineArtists: Artist[] = [];

  if (venues && events && artists) {
    baselineEvents = events;
    baselineVenues = venues;
    baselineArtists = artists;
  }

  // -----

  useEffect(() => {
    return () => clearTimeout(mainContentMicroanimationTimeout)
  }, [mainContentMicroanimationTimeout]);


  const onSelectArtistAction = (actionID: string) => onOpenModal(actionID);
  const onSelectEventAction = (actionID: string) => onOpenModal(actionID);
  const onSelectVenueAction = (actionID: string) => onOpenModal(actionID);

  // ------

  const { mainContentInStatus } = state;

  return (
    <Fade inStatus={mainContentInStatus}>
      <div className={css(componentCradle)}>
        <div className={css(topSectionCradle)}>
          {/* TITLE AND ROUTING LINKS */}
          <div className={css(dashboardTopDetailsCradle)}>
            <div className={css(dashboardTitleRow)}>
              <p className={css(dashboardTitleText)}>{t("pageTitleMsg")}</p>
            </div>

            <LineSegment
              direction={HORIZONTAL}
              width="100%" size={2}
              color={palette.black}
              customStyles={{ marginTop: distance.two, marginBottom: distance.two }}
            />
            
            <div className={css(navLinkRow)}>
              <DashboardRoutingLinks />
            </div>
          </div>  
        </div>

        <Spacer direction={VERTICAL} amount={distance.three} />

        <div className={css(metricsGridCradle)}>
          <div className={css(metricCradle)}>
            <p className={css(metricValueText)}>41</p>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <p className={css(metricContextText)}>Upcoming Events</p>
          </div>
          <div className={css(metricCradle)}>
            <p className={css(metricValueText)}>175</p>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <p className={css(metricContextText)}>Operational Venues</p>
          </div>
          <div className={css(metricCradle)}>
            <p className={css(metricValueText)}>263</p>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <p className={css(metricContextText)}>Total Events Tracked</p>
          </div>
          <div className={css(metricCradle)}>
            <p className={css(metricValueText)}>185</p>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <p className={css(metricContextText)}>Total Venues Tracked</p>
          </div>
          <div className={css(metricCradle)}>
            <p className={css(metricValueText)}>179</p>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <p className={css(metricContextText)}>Total Artists Tracked</p>
          </div>
        </div>

        <Spacer direction={VERTICAL} amount={distance.three} />

        <RecentActionsFeed
          feedActions={actions.sort((action1, action2) => action1.dateTime - action2.dateTime)}
          onSelectArtistAction={onSelectArtistAction}
          onSelectVenueAction={onSelectVenueAction}
          onSelectEventAction={onSelectEventAction}
        />
      </div>
    </Fade>
  );
};

export default AdminDashboardDetails;
