// -> Beyond codebase
import React, { useContext, useEffect, useMemo, useReducer, useRef } from "react";
import { css } from "aphrodite-jss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import Pagination from "../../Components/Pagination/Pagination";
import Spacer from "../../Components/LayoutUtilities/Spacer/Spacer";
import MapComponent from "../../Components/Map/Map";
import IconButton from "src/Components/Button/Variants/IconButton/IconButton";
import PlusIcon from "src/Components/VisualUtilities/IconPresets/PlusIcon";
import { UIContext } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { useQueryParams } from "../../helpers";
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import {
  GRID, DEFAULT_TRANSITION_MICROANIMATION_TIME, VENUE_ROUTE, VERTICAL,
  PAGE_NUM_PARAM_KEY, VENUES_ROUTE, VENUE_ID_ROUTE_PARAM_TOKEN, LIST, MAP,
  NAV_BAR_HEIGHT, DATA_ITEMS_PER_PAGE, ROUND, ADD_VENUE_ROUTE
} from "../../constants";
import { venues as venues } from "../../staticData";
// -> Within component
import TopBar from "./InternalComponents/TopBar/TopBar";
import { TOP_BAR_DEFAULT_HEIGHT } from "./InternalComponents/TopBar/helpers";
import VenueGallery from "./InternalComponents/VenueGallery/VenueGallery";
import VenueList from "./InternalComponents/VenueList/VenueList";
import { styleGen } from "./VenuesStyles";
import { VenuesProps } from "./helpers";
import {
  reducer, setDisplayMode, setLoadingStatus, setLoaderInStatus,
  setSortCriteria, setSortDirection, applySortConfig, clearSortConfig,
  setFilterCriteria, setFilterCondition, setFilterArgument, applyFilterConfig,
  clearFilterConfig, setVenues, setPaginatedVenues, setFilterConditionType,
  addMicroanimationTimeoutID, addFilterArgumentFadeInStatus, setDisplayableVenues,
  removeFilterArgumentFadeInStatus, setFilterArgumentFadeInStatuses,
  setSortActivityIndicatorInStatus, setFilterActivityIndicatorInStatus,
  setSearchActivityIndicatorInStatus, searchVenuesAction, clearVenueSearch,
  setPageContentInStatus, setCurrentPage, initialVenuesState, setItemsPerPage,
} from "./stateManagement";

const Venues: React.FC<VenuesProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialVenuesState);
  const routerHistory = useHistory();
  const queryParams = useQueryParams();
  const { t } = useTranslation("component_Pagination");
  const UIInfo =  useContext(UIContext);
  const { themeInfo, showNavBar, navBarVisible } = UIInfo
  const { getUser } = useContext(AuthContext);
  const { palette, distance, shadows } = themeInfo;
  const {
    componentCradle, venuesContentCradle, customPageCradleStyles, loaderCradle,
    addEventBtnCradle
  } = styleGen({themeInfo, props, state});

  let pageContentMicroanimationTimeout = useRef<any>(-1);

  const pageNumParam = useMemo(() => parseInt(queryParams.get(PAGE_NUM_PARAM_KEY) || "1"), [queryParams]);

  // -----

  const onVenueClick = (venueID: string) => {
    const computedURL = VENUE_ROUTE.replace(VENUE_ID_ROUTE_PARAM_TOKEN, venueID);

    dispatch(setPageContentInStatus(false));
    routerHistory.push(computedURL);
    pageContentMicroanimationTimeout.current = setTimeout(() => {
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  useEffect(() => {
    const user = getUser();
    console.log(`[Venues]: user -> ${JSON.stringify(user, null, 3)}`);

    if (!navBarVisible) showNavBar();

    dispatch(setItemsPerPage(DATA_ITEMS_PER_PAGE));
    dispatch(setVenues(venues));
    dispatch(setDisplayableVenues(venues));
    dispatch(setPaginatedVenues());

    const numInitialPages = Math.ceil(venues.length / DATA_ITEMS_PER_PAGE);
    if (pageNumParam < 1) {
      dispatch(setCurrentPage(1));
    }
    else if (pageNumParam >= 1 && pageNumParam <= numInitialPages) {
      dispatch(setCurrentPage(pageNumParam));
    }
    else {
      dispatch(setCurrentPage(numInitialPages));
      window.history.pushState(null, "", `${VENUES_ROUTE}?${PAGE_NUM_PARAM_KEY}=${numInitialPages}`);
    }

    setTimeout(() => {
      dispatch(setLoaderInStatus(false));
      setTimeout(() => {
        dispatch(setLoadingStatus(false));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }, 1000);
  }, [getUser, navBarVisible, pageNumParam, showNavBar]);

  // -----

  useEffect(() => {
    return () => {
      state.filtArgMicroanimationTimeouts.map((timeoutID) => clearTimeout(timeoutID));
      clearTimeout(pageContentMicroanimationTimeout.current);
    };
  }, [pageContentMicroanimationTimeout, state.filtArgMicroanimationTimeouts]);

  // -----

  const onSetCurrentPage = (pageNumber: number) => {
    dispatch(setCurrentPage(pageNumber));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  // -----

  const onNavigateToAddEvent = () => {
    dispatch(setPageContentInStatus(false));
    // - TODO: -> Why isn't the set timeout callback invoking?
    routerHistory.push(ADD_VENUE_ROUTE);
    pageContentMicroanimationTimeout.current = setTimeout(() => {
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  const {
    displayMode, loadingStatus, loaderInStatus, displayedVenues, pageContentInStatus,
    currentPageNumber, itemsPerPage, numberOfDisplayableVenues, displayableVenues
  } = state;

  return (loadingStatus) ? (
    <div className={css(loaderCradle)}>
      <Fade inStatus={loaderInStatus}>
        <CircleLoader spinnerColor={palette.primary} />
      </Fade>
    </div>
  ) : (
    <Fade inStatus={pageContentInStatus}>
      <div className={css(componentCradle, customPageCradleStyles)}>
        <TopBar
          // -> General
          displayMode={displayMode}
          state={state}
          dispatch={dispatch}
          setDisplayMode={setDisplayMode}
          addMicroanimationTimeoutID={addMicroanimationTimeoutID}
          // -> Sorting
          setSortCriteria={setSortCriteria}
          setSortDirection={setSortDirection}
          applySortConfig={applySortConfig}
          clearSortConfig={clearSortConfig}
          setSortActivityIndicatorInStatus={setSortActivityIndicatorInStatus}
          // -> Filtering
          setFilterCriteria={setFilterCriteria}
          setFilterCondition={setFilterCondition}
          setFilterConditionType={setFilterConditionType}
          setFilterArgument={setFilterArgument}
          applyFilterConfig={applyFilterConfig}
          clearFilterConfig={clearFilterConfig}
          addFilterArgumentFadeInStatus={addFilterArgumentFadeInStatus}
          removeFilterArgumentFadeInStatus={removeFilterArgumentFadeInStatus}
          setFilterArgumentFadeInStatuses={setFilterArgumentFadeInStatuses}
          setFilterActivityIndicatorInStatus={setFilterActivityIndicatorInStatus}
          // -> Searching
          searchVenuesAction={searchVenuesAction}
          clearVenueSearch={clearVenueSearch}
          setSearchActivityIndicatorInStatus={setSearchActivityIndicatorInStatus}
        />
        <div className={css(venuesContentCradle)}>
          {
            (displayMode === GRID || displayMode === LIST) && (
              <>
                {
                  (displayMode === GRID) && (
                    <VenueGallery
                      venues={displayedVenues}
                      onVenueClick={onVenueClick}
                    />
                  )
                }

                {
                  (displayMode === LIST) && (
                    <VenueList
                      venues={displayedVenues}
                      currentPageNumber={currentPageNumber}
                      itemsPerPage={itemsPerPage}
                      onVenueClick={onVenueClick}
                    />
                  )
                }

                <Spacer direction={VERTICAL} amount={distance.six} />

                <Pagination
                  currentPage={currentPageNumber}
                  setCurrentPage={onSetCurrentPage}
                  itemsPerPage={itemsPerPage}
                  numberOfItems={numberOfDisplayableVenues}
                  showDirectionButtonsWithOnePage={false}
                  prevPageLabel={t("prevBtnLabel")}
                  nextPageLabel={t("nextBtnLabel")}
                  // rtl
                />
              </>
            )
          }
          {
            (displayMode === MAP) && (
              <MapComponent
                venues={displayableVenues}
                containerStyles={{
                  height: "auto",
                  width: "100%",
                  minHeight: `calc(100vh - ${NAV_BAR_HEIGHT + TOP_BAR_DEFAULT_HEIGHT}px)`,
                }}
              />
            )
          }
        </div>
        <div className={css(addEventBtnCradle)}>
          <IconButton
            icon={<PlusIcon color={palette.white} size={30} />}
            buttonGeometry={ROUND}
            onClick={onNavigateToAddEvent}
            customCradleStyles={{
              boxShadow: shadows.two,
              padding: distance.two,
              backgroundColor: palette.primary,
            }}
          />
        </div>
      </div>
    </Fade>
  );
}

export default Venues;
