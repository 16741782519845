import Fuse, { FuseOptions } from "fuse.js";
import cloneDeep from "lodash.clonedeep";
import { Event } from "../../Types";
import { determineEventStatus, mapLanguageCodeToLanguage } from "../../helpers";
import { IEventsState } from "./stateManagement";
import {
  FILTER_CONDITION__EQUAL_TO, FILTER_CONDITION__NOT_EQUAL_TO, FILTER_CONDITION__GREATER_THAN,
  FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO, FILTER_CONDITION__LESS_THAN,
  FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO, FILTER_CONDITION__INCLUDED_IN, FILTER_CONDITION__NOT_INCLUDED_IN,
  FILTER_CONDITION__NONE, LANGUAGE__ENGLISH, LANGUAGE__FRENCH,
} from "../../constants";


export interface IEventsProps {
  customPageCradleStyles?: any;
}

// -----

// -> SORT CRITERIA
export const SORT_CRITERIA__NONE = "None";
export const SORT_CRITERIA__NAME = "Name";
export const SORT_CRITERIA__EVENT_TYPE = "Event Type";
export const SORT_CRITERIA__EVENT_STATUS = "Event Status";
export const SORT_CRITERIA__CITY = "City";
export const SORT_CRITERIA__COUNTRY = "Country";
export const availableSortCriteria = [
  SORT_CRITERIA__NONE, SORT_CRITERIA__NAME, SORT_CRITERIA__EVENT_TYPE,
  SORT_CRITERIA__EVENT_STATUS, SORT_CRITERIA__CITY, SORT_CRITERIA__COUNTRY
];
export const SORT_CRITERIA__NONE_FR = "Aucun";
export const SORT_CRITERIA__NAME_FR = "Nom";
export const SORT_CRITERIA__EVENT_TYPE_FR = "Type d'événement";
export const SORT_CRITERIA__EVENT_STATUS_FR = "État de l'événement";
export const SORT_CRITERIA__CITY_FR = "Ville";
export const SORT_CRITERIA__COUNTRY_FR = "Pays";
export const availableSortCriteria_FR = [
  SORT_CRITERIA__NONE_FR, SORT_CRITERIA__NAME_FR, SORT_CRITERIA__EVENT_TYPE_FR,
  SORT_CRITERIA__EVENT_STATUS_FR, SORT_CRITERIA__CITY_FR, SORT_CRITERIA__COUNTRY_FR
];

// -> SORT DIRECTIONS
export const SORT_DIRECTION__ASCENDING = "Ascending";
export const SORT_DIRECTION__DESCENDING = "Descending";
export const availableSortDirections = [SORT_DIRECTION__ASCENDING, SORT_DIRECTION__DESCENDING];
export const SORT_DIRECTION__ASCENDING_FR = "Croissant";
export const SORT_DIRECTION__DESCENDING_FR = "Décroissant";
export const availableSortDirections_FR = [SORT_DIRECTION__ASCENDING_FR, SORT_DIRECTION__DESCENDING_FR];

// -> FILTER CRITERIA
export const FILTER_CRITERIA__NONE = "None";
export const FILTER_CRITERIA__NAME = "Name";
export const FILTER_CRITERIA__CITY = "City";
export const FILTER_CRITERIA__COUNTRY = "Country";
export const FILTER_CRITERIA__EVENT_TYPE = "Event Type";
export const FILTER_CRITERIA__EVENT_STATUS = "Event Status";
export const availableFilterCriteria = [
  FILTER_CRITERIA__NONE, FILTER_CRITERIA__NAME, FILTER_CRITERIA__EVENT_TYPE,
  FILTER_CRITERIA__EVENT_STATUS, FILTER_CRITERIA__CITY, FILTER_CRITERIA__COUNTRY
];
export const FILTER_CRITERIA__NONE_FR = "Aucun";
export const FILTER_CRITERIA__NAME_FR = "Nom";
export const FILTER_CRITERIA__EVENT_TYPE_FR = "Event Type";
export const FILTER_CRITERIA__EVENT_STATUS_FR = "Event Status";
export const FILTER_CRITERIA__CITY_FR = "Ville";
export const FILTER_CRITERIA__COUNTRY_FR = "Pays";
export const availableFilterCriteria_FR = [
  FILTER_CRITERIA__NONE_FR, FILTER_CRITERIA__NAME_FR, FILTER_CRITERIA__EVENT_TYPE_FR,
  FILTER_CRITERIA__EVENT_STATUS_FR, FILTER_CRITERIA__CITY_FR, FILTER_CRITERIA__COUNTRY_FR
];

// -> FILTER CONDITIONS
export const availableFilterConditions = [
  FILTER_CONDITION__NONE, FILTER_CONDITION__EQUAL_TO, FILTER_CONDITION__NOT_EQUAL_TO, FILTER_CONDITION__GREATER_THAN,
  FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO, FILTER_CONDITION__LESS_THAN,
  FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO, FILTER_CONDITION__INCLUDED_IN, FILTER_CONDITION__NOT_INCLUDED_IN
];

// -> FILTER CONDITION TYPES
export const FILTER_CONDITION_TYPE__LIST = "List";
export const FILTER_CONDITION_TYPE__RANGE = "Range";
export const availableFilterConditionTypes = [FILTER_CONDITION_TYPE__LIST, FILTER_CONDITION_TYPE__RANGE];
export const FILTER_CONDITION_TYPE__LIST_FR = "Liste";
export const FILTER_CONDITION_TYPE__RANGE_FR = "Intervalle";
export const availableFilterConditionTypes_FR = [FILTER_CONDITION_TYPE__LIST_FR, FILTER_CONDITION_TYPE__RANGE_FR];

// ----- ARTIST SORTING ----- //

export const sortEvents = (
  state: IEventsState,
  options?: { eventsToSortParam?: Event[] }
): Event[] => {
  const stateCopy = cloneDeep(state);
  const { currentSortCriteria, currentSortDirection, events } = stateCopy;
  const eventsToSort = (!options?.eventsToSortParam) ? events : options.eventsToSortParam;
  let sortedEvents: Event[] = [];

  if (currentSortCriteria === SORT_CRITERIA__NAME) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedEvents = eventsToSort.sort(compareEventNamesAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedEvents = eventsToSort.sort(compareEventNamesDesc);
    }
  } else if (currentSortCriteria === SORT_CRITERIA__EVENT_TYPE) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedEvents = eventsToSort.sort(compareEventTypesAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedEvents = eventsToSort.sort(compareEventTypesDesc);
    }
  } else if (currentSortCriteria === SORT_CRITERIA__EVENT_STATUS) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedEvents = eventsToSort.sort(compareEventStatusesAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedEvents = eventsToSort.sort(compareEventStatusesDesc);
    }
  } else if (currentSortCriteria === SORT_CRITERIA__CITY) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedEvents = eventsToSort.sort(compareEventCitiesAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedEvents = eventsToSort.sort(compareEventCitiesDesc);
    }
  } else if (currentSortCriteria === SORT_CRITERIA__COUNTRY) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedEvents = eventsToSort.sort(compareEventCountriesAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedEvents = eventsToSort.sort(compareEventCountriesDesc);
    }
  }

  return sortedEvents;
};

const compareEventNamesAsc = (event1: Event, event2: Event): number => {
  return event1.name.toLowerCase().localeCompare(event2.name.toLowerCase());
};

const compareEventNamesDesc = (event1: Event, event2: Event): number => {
  return event2.name.toLowerCase().localeCompare(event1.name.toLowerCase());
};

const compareEventStatusesAsc = (event1: Event, event2: Event): number => {
  const event1Status = determineEventStatus(event1);
  const event2Status = determineEventStatus(event2);
  return event1Status.toLowerCase().localeCompare(event2Status.toLowerCase());
};

const compareEventStatusesDesc = (event1: Event, event2: Event): number => {
  const event1Status = determineEventStatus(event1);
  const event2Status = determineEventStatus(event2);
  return event2Status.toLowerCase().localeCompare(event1Status.toLowerCase());
};

const compareEventTypesAsc = (event1: Event, event2: Event): number => {
  return event1.type.toLowerCase().localeCompare(event2.type.toLowerCase());
};

const compareEventTypesDesc = (event1: Event, event2: Event): number => {
  return event2.type.toLowerCase().localeCompare(event1.type.toLowerCase());
};

const compareEventCitiesAsc = (event1: Event, event2: Event): number => {
  if ((!event1.city) || (!event2.city)) return 0;
  return event1.city.toLowerCase().localeCompare(event2.city.toLowerCase());
};

const compareEventCitiesDesc = (event1: Event, event2: Event): number => {
  if ((!event1.city) || (!event2.city)) return 0;
  return event2.city.toLowerCase().localeCompare(event1.city.toLowerCase());
};

const compareEventCountriesAsc = (event1: Event, event2: Event): number => {
  if ((!event1.country) || (!event2.country)) return 0;
  return event1.country.toLowerCase().localeCompare(event2.country.toLowerCase());
};

const compareEventCountriesDesc = (event1: Event, event2: Event): number => {
  if ((!event1.country) || (!event2.country)) return 0;
  return event2.country.toLowerCase().localeCompare(event1.country.toLowerCase());
};

// ----- EVENT FILTERING ----- //

export const filterEvents = (
  state: IEventsState,
  options?: { eventsToFilterParam?: Event[] }
): Event[] => {
  const stateCopy = cloneDeep(state);
  const {
    currentFilterCriteria, currentFilterCondition, currentFilterConditionType,
    currentFilterArgument, events
  } = stateCopy;
  const eventsToFilter = (!options?.eventsToFilterParam) ? events : options.eventsToFilterParam;
  let filteredEvents: Event[] = [];

  // -> Filtering by name
  if (currentFilterCriteria === FILTER_CRITERIA__NAME) {
    if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.name.toLowerCase().trim()) === 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.name.toLowerCase().trim()) !== 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.name.toLowerCase().trim()) === -1);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.name.toLowerCase().trim()) <= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.name.toLowerCase().trim()) > 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.name.toLowerCase().trim()) >= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return processedFilterArgument.includes(event.name.toLowerCase().trim());
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return (
            (minElement.localeCompare(event.name.toLowerCase().trim()) <= 0) &&
            (maxElement.localeCompare(event.name.toLowerCase().trim()) >= 0)
          );
        });
      }
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return (processedFilterArgument.includes(event.name.toLowerCase().trim()) === false);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return (
            (minElement.localeCompare(event.name.toLowerCase().trim()) > 0) ||
            (maxElement.localeCompare(event.name.toLowerCase().trim()) < 0)
          );
        });
      }
    }
  }

// -> Filtering by event city
else if (currentFilterCriteria === FILTER_CRITERIA__CITY) {
  if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
    const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
    filteredEvents = eventsToFilter.filter((event: Event) => {
      if (!event.city) return -1;
      return (processedFilterArgument.localeCompare(event.city.toLowerCase().trim()) === 0);
    });
  }
  
  else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
    const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
    filteredEvents = eventsToFilter.filter((event: Event) => {
      if (!event.city) return 0;
      return (processedFilterArgument.localeCompare(event.city.toLowerCase().trim()) !== 0);
    });
  }
  
  else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
    const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
    filteredEvents = eventsToFilter.filter((event: Event) => {
      if (!event.city) return 0;
      return (processedFilterArgument.localeCompare(event.city.toLowerCase().trim()) === -1);
    });
  }
  
  else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
    const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
    filteredEvents = eventsToFilter.filter((event: Event) => {
      if (!event.city) return 1;
      return (processedFilterArgument.localeCompare(event.city.toLowerCase().trim()) <= 0);
    });
  }
  
  else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
    const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
    filteredEvents = eventsToFilter.filter((event: Event) => {
      if (!event.city) return -1;
      return (processedFilterArgument.localeCompare(event.city.toLowerCase().trim()) > 0);
    });
  }
  
  else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
    const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
    filteredEvents = eventsToFilter.filter((event: Event) => {
      if (!event.city) return -1;
      return (processedFilterArgument.localeCompare(event.city.toLowerCase().trim()) >= 0);
    });
  }
  
  else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
    if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
      const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());
      
      filteredEvents = eventsToFilter.filter((event: Event) => {
        if (!event.city) return false;
        return processedFilterArgument.includes(event.city.toLowerCase().trim());
      });
    } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
      const minElement = currentFilterArgument[0].toLowerCase().trim();
      const maxElement = currentFilterArgument[1].toLowerCase().trim();
      
      filteredEvents = eventsToFilter.filter((event: Event) => {
        if (!event.city) return false;
        return (
          (minElement.localeCompare(event.city.toLowerCase().trim()) <= 0) &&
          (maxElement.localeCompare(event.city.toLowerCase().trim()) >= 0)
          );
        });
      }
    }
  
    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
    if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
      const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

      filteredEvents = eventsToFilter.filter((event: Event) => {
        if (!event.city) return false;
        return (processedFilterArgument.includes(event.city.toLowerCase().trim()) === false);
      });
    } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();
      
        filteredEvents = eventsToFilter.filter((event: Event) => {
          if (!event.city) return false;
          return (
            (minElement.localeCompare(event.city.toLowerCase().trim()) > 0) ||
            (maxElement.localeCompare(event.city.toLowerCase().trim()) < 0)
          );
        });
      }
    }
  }

  // -> Filtering by venue country
  else if (currentFilterCriteria === FILTER_CRITERIA__COUNTRY) {
    if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        if (!event.country) return -1;
        return (processedFilterArgument.localeCompare(event.country.toLowerCase().trim()) === 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        if (!event.country) return 0;
        return (processedFilterArgument.localeCompare(event.country.toLowerCase().trim()) !== 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        if (!event.country) return 0;
        return (processedFilterArgument.localeCompare(event.country.toLowerCase().trim()) === -1);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        if (!event.country) return 1;
        return (processedFilterArgument.localeCompare(event.country.toLowerCase().trim()) <= 0);
      });
    }
    
    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        if (!event.country) return -1;
        return (processedFilterArgument.localeCompare(event.country.toLowerCase().trim()) > 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        if (!event.country) return -1;
        return (processedFilterArgument.localeCompare(event.country.toLowerCase().trim()) >= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());
        
        filteredEvents = eventsToFilter.filter((event: Event) => {
          if (!event.country) return false;
          return processedFilterArgument.includes(event.country.toLowerCase().trim());
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredEvents = eventsToFilter.filter((event: Event) => {
          if (!event.country) return false;
          return (
            (minElement.localeCompare(event.country.toLowerCase().trim()) <= 0) &&
            (maxElement.localeCompare(event.country.toLowerCase().trim()) >= 0)
            );
          });
        }
      }
      
    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());
        
        filteredEvents = eventsToFilter.filter((event: Event) => {
          if (!event.country) return false;
          return (processedFilterArgument.includes(event.country.toLowerCase().trim()) === false);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();
        
        filteredEvents = eventsToFilter.filter((event: Event) => {
          if (!event.country) return false;
          return (
            (minElement.localeCompare(event.country.toLowerCase().trim()) > 0) ||
            (maxElement.localeCompare(event.country.toLowerCase().trim()) < 0)
          );
        });
      }
    }
  }

  // -> Filtering by event status
  if (currentFilterCriteria === FILTER_CRITERIA__EVENT_STATUS) {
    if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(determineEventStatus(event).toLowerCase().trim()) === 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(determineEventStatus(event).toLowerCase().trim()) !== 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(determineEventStatus(event).toLowerCase().trim()) === -1);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(determineEventStatus(event).toLowerCase().trim()) <= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(determineEventStatus(event).toLowerCase().trim()) > 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(determineEventStatus(event).toLowerCase().trim()) >= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return processedFilterArgument.includes(determineEventStatus(event).toLowerCase().trim());
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return (
            (minElement.localeCompare(determineEventStatus(event).toLowerCase().trim()) <= 0) &&
            (maxElement.localeCompare(determineEventStatus(event).toLowerCase().trim()) >= 0)
          );
        });
      }
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return (processedFilterArgument.includes(determineEventStatus(event).toLowerCase().trim()) === false);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return (
            (minElement.localeCompare(determineEventStatus(event).toLowerCase().trim()) > 0) ||
            (maxElement.localeCompare(determineEventStatus(event).toLowerCase().trim()) < 0)
          );
        });
      }
    }
  }

  // -> Filtering by event type
  else if (currentFilterCriteria === FILTER_CRITERIA__EVENT_TYPE) {
    if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.type.toLowerCase().trim()) === 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.type.toLowerCase().trim()) !== 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.type.toLowerCase().trim()) === -1);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.type.toLowerCase().trim()) <= 0);
      });
    }
    
    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.type.toLowerCase().trim()) > 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredEvents = eventsToFilter.filter((event: Event) => {
        return (processedFilterArgument.localeCompare(event.type.toLowerCase().trim()) >= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());
        
        filteredEvents = eventsToFilter.filter((event: Event) => {
          return processedFilterArgument.includes(event.type.toLowerCase().trim());
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return (
            (minElement.localeCompare(event.type.toLowerCase().trim()) <= 0) &&
            (maxElement.localeCompare(event.type.toLowerCase().trim()) >= 0)
          );
        });
      }
    }
    
    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return (processedFilterArgument.includes(event.type.toLowerCase().trim()) === false);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredEvents = eventsToFilter.filter((event: Event) => {
          return (
            (minElement.localeCompare(event.type.toLowerCase().trim()) > 0) ||
            (maxElement.localeCompare(event.type.toLowerCase().trim()) < 0)
          );
        });
      }
    }
  }

  return filteredEvents;
};

// ----- EVENT SEARCH ----- //

export const searchEvents = (
  searchQuery: string,
  state: IEventsState,
  options?: { eventsToSearchParam?: Event[] }
): Event[] => {
  const { events, sortingActive } = state;
  const eventsToSearch = (!options?.eventsToSearchParam) ? events : options.eventsToSearchParam;
  let eventSearchResults: any[] = [];

  if (searchQuery === "") return eventsToSearch;

  const searchEngineOptions: FuseOptions<Event> = {
    keys: ["name"],
    shouldSort: sortingActive ? false : true, // -> Results sorted by relevance score unless manual sorting is enabled
    findAllMatches: true,
    threshold: 0.4, // -> This is the default value, it can be tweaked from 0 to 1
  };

  const fuse = new Fuse(eventsToSearch, searchEngineOptions);

  eventSearchResults = fuse.search(searchQuery);

  return eventSearchResults;
};

// ----- EVENT PAGINATION ----- //

export const selectPaginatedEvents = (
  state: IEventsState,
  options?: {
    eventsToPaginateParam?: Event[],
    pageNumber?: number
  }
): Event[] => {
  const { itemsPerPage, currentPageNumber, events } = state;
  const pageNumberToUse = (!options?.pageNumber) ? currentPageNumber : options.pageNumber;
  const eventsToPaginate = (!options?.eventsToPaginateParam) ? events : options.eventsToPaginateParam;
  let displayedArtists: Event[] = [];

  const indexOfLastArtist = pageNumberToUse * itemsPerPage;
  const indexOfFirstArtist = indexOfLastArtist - itemsPerPage;
  displayedArtists = eventsToPaginate.slice(indexOfFirstArtist, indexOfLastArtist);

  return displayedArtists;
};

// ----- LANGUAGE PROCESSING ----- //

// ----- Sort Criteria ----- //

export const mapSortCriteriaToEnglishSortCriteria =
  (sortCriteria: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedSortCriteria: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedSortCriteria = sortCriteria;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (sortCriteria) {
      case SORT_CRITERIA__NONE_FR:
        translatedSortCriteria = SORT_CRITERIA__NONE;
        break;
      case SORT_CRITERIA__EVENT_TYPE_FR:
        translatedSortCriteria = SORT_CRITERIA__EVENT_TYPE;
        break;
      case SORT_CRITERIA__EVENT_STATUS_FR:
        translatedSortCriteria = SORT_CRITERIA__EVENT_STATUS;
        break;
      case SORT_CRITERIA__CITY_FR:
        translatedSortCriteria = SORT_CRITERIA__CITY;
        break;
      case SORT_CRITERIA__COUNTRY_FR:
        translatedSortCriteria = SORT_CRITERIA__COUNTRY;
        break;
      default:
        translatedSortCriteria = SORT_CRITERIA__NONE;
        break;
    }
  }

  return translatedSortCriteria;
};

// -----

export const mapEnglishSortCriteriaToTargetLanguage = (sortCriteria: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedSortCriteria: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedSortCriteria = sortCriteria;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (sortCriteria) {
      case SORT_CRITERIA__NONE:
        translatedSortCriteria = SORT_CRITERIA__NONE_FR;
        break;
      case SORT_CRITERIA__EVENT_TYPE:
        translatedSortCriteria = SORT_CRITERIA__EVENT_TYPE_FR;
        break;
      case SORT_CRITERIA__EVENT_STATUS:
        translatedSortCriteria = SORT_CRITERIA__EVENT_STATUS_FR;
        break;
      case SORT_CRITERIA__CITY:
        translatedSortCriteria = SORT_CRITERIA__CITY_FR;
        break;
      case SORT_CRITERIA__COUNTRY:
        translatedSortCriteria = SORT_CRITERIA__COUNTRY_FR;
        break;
      default:
        translatedSortCriteria = SORT_CRITERIA__NONE;
        break;
    }
  }

  return translatedSortCriteria;
};

// ----- Sort Direction ----- //

export const mapSortDirectionToEnglishSortDirection =
  (sortDirection: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedSortDirection: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedSortDirection = sortDirection;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (sortDirection) {
      case SORT_DIRECTION__ASCENDING_FR:
        translatedSortDirection = SORT_DIRECTION__ASCENDING;
        break;
      case SORT_DIRECTION__DESCENDING_FR:
        translatedSortDirection = SORT_DIRECTION__DESCENDING;
        break;
      default:
        translatedSortDirection = SORT_DIRECTION__ASCENDING;
        break;
    }
  }

  return translatedSortDirection;
};

// -----

export const mapEnglishSortDirectionToTargetLanguage = (sortDirection: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedSortDirection: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedSortDirection = sortDirection;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (sortDirection) {
      case SORT_DIRECTION__ASCENDING:
        translatedSortDirection = SORT_DIRECTION__ASCENDING_FR;
        break;
      case SORT_DIRECTION__DESCENDING:
        translatedSortDirection = SORT_DIRECTION__DESCENDING_FR;
        break;
      default:
        translatedSortDirection = SORT_DIRECTION__ASCENDING;
        break;
    }
  }

  return translatedSortDirection;
};

// ----- Filter Criteria ----- //

export const mapFilterCriteriaToEnglishFilterCriteria =
  (filterCriteria: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedFilterCriteria: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedFilterCriteria = filterCriteria;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (filterCriteria) {
      case FILTER_CRITERIA__NONE_FR:
        translatedFilterCriteria = FILTER_CRITERIA__NONE;
        break;
      case FILTER_CRITERIA__NAME_FR:
        translatedFilterCriteria = FILTER_CRITERIA__NAME;
        break;
      case FILTER_CRITERIA__EVENT_TYPE_FR:
        translatedFilterCriteria = FILTER_CRITERIA__EVENT_TYPE;
        break;
      case FILTER_CRITERIA__EVENT_STATUS_FR:
        translatedFilterCriteria = FILTER_CRITERIA__EVENT_STATUS;
        break;
      case FILTER_CRITERIA__CITY_FR:
        translatedFilterCriteria = FILTER_CRITERIA__CITY;
        break;
      case FILTER_CRITERIA__COUNTRY_FR:
        translatedFilterCriteria = FILTER_CRITERIA__COUNTRY;
        break;
      default:
        translatedFilterCriteria = FILTER_CRITERIA__NONE;
        break;
    }
  }

  return translatedFilterCriteria;
};

// -----

export const mapEnglishFilterCriteriaToTargetLanguage = (filterCriteria: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedFilterCriteria: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedFilterCriteria = filterCriteria;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (filterCriteria) {
      case FILTER_CRITERIA__NONE:
        translatedFilterCriteria = FILTER_CRITERIA__NONE_FR;
        break;
      case FILTER_CRITERIA__NAME:
        translatedFilterCriteria = FILTER_CRITERIA__NAME_FR;
        break;
      case FILTER_CRITERIA__EVENT_TYPE:
        translatedFilterCriteria = FILTER_CRITERIA__EVENT_TYPE_FR;
        break;
      case FILTER_CRITERIA__EVENT_STATUS:
        translatedFilterCriteria = FILTER_CRITERIA__EVENT_STATUS_FR;
        break;
      case FILTER_CRITERIA__CITY:
        translatedFilterCriteria = FILTER_CRITERIA__CITY_FR;
        break;
      case FILTER_CRITERIA__COUNTRY:
        translatedFilterCriteria = FILTER_CRITERIA__COUNTRY_FR;
        break;
      default:
        translatedFilterCriteria = FILTER_CRITERIA__NONE;
        break;
    }
  }

  return translatedFilterCriteria;
};

// ----- Filter Condition Type ----- //

export const mapFilterConditionTypeToEnglishFilterConditionType = (filterConditionType: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedFilterConditionType: string = FILTER_CONDITION_TYPE__LIST;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedFilterConditionType = filterConditionType;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (filterConditionType) {
      case FILTER_CONDITION_TYPE__LIST:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST_FR;
        break;
      case FILTER_CONDITION_TYPE__RANGE:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__RANGE_FR;
        break;
      default:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST_FR;
        break;
    }
  }

  return translatedFilterConditionType;
};

// -----

export const mapEnglishFilterConditionTypeToTargetLanguage = (filterConditionType: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedFilterConditionType: string = FILTER_CONDITION_TYPE__LIST;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedFilterConditionType = filterConditionType;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (filterConditionType) {
      case FILTER_CONDITION_TYPE__LIST_FR:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST;
        break;
      case FILTER_CONDITION_TYPE__RANGE_FR:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__RANGE;
        break;
      default:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST;
        break;
    }
  }

  return translatedFilterConditionType;
};

// ----- Available Filter Criteria ----- //

export const determineAvailableFilterCriteriaByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableFilterCriteria;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableFilterCriteria = availableFilterCriteria;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableFilterCriteria = availableFilterCriteria_FR;
      break;
    default:
      computedAvailableFilterCriteria = availableFilterCriteria;
      break;
  }

  return computedAvailableFilterCriteria;
};

// ----- Available Filter Condition Types ----- //

export const determineAvailableFilterConditionTypesByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableFilterConditionType;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableFilterConditionType = availableFilterConditionTypes;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableFilterConditionType = availableFilterConditionTypes_FR;
      break;
    default:
      computedAvailableFilterConditionType = availableFilterConditionTypes;
      break;
  }

  return computedAvailableFilterConditionType;
};

// ----- Available Sort Criteria ----- //

export const determineAvailableSortCriteriaByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableSortCriteria;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableSortCriteria = availableSortCriteria;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableSortCriteria = availableSortCriteria_FR;
      break;
    default:
      computedAvailableSortCriteria = availableSortCriteria;
      break;
  }

  return computedAvailableSortCriteria;
};

// ----- Available Sort Directions ----- //

export const determineAvailableSortDirectionsByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableSortDirections;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableSortDirections = availableSortDirections;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableSortDirections = availableSortDirections_FR;
      break;
    default:
      computedAvailableSortDirections = availableSortDirections;
      break;
  }

  return computedAvailableSortDirections;
};
