import * as React from "react";
import { IIconProps } from "../../../Types";

const SongIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...Object.assign({}, {...props}, {...interpretedProps})}
    >
      <title>SongPair</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect style={{ fill: "none" }} width="25" height="25"/>
          <path d="M18.83,10.64V19l-.08.3a3.39,3.39,0,0,1-2.56,2.42,4.22,4.22,0,0,1-1.28,0,3.36,3.36,0,0,1-2.56-2.27,3.2,3.2,0,0,1-.13-1.42,2.77,2.77,0,0,1,.13-.6,3.55,3.55,0,0,1,.44-.87,3.28,3.28,0,0,1,3.77-1.27,2.94,2.94,0,0,1,1,.51l.19.15v-4.3c0-.7,0-1.16,0-1.16s-2.47.61-4.92,1.24l-1.11.29-3.85,1s0,.09,0,.25c0,.61,0,2.23,0,4.12v4.35l-.09.28a4.45,4.45,0,0,1-.19.51,3.39,3.39,0,0,1-2.27,1.86,4,4,0,0,1-1.58,0,3.31,3.31,0,0,1-2.38-2.34,2.38,2.38,0,0,1-.11-.86,2.79,2.79,0,0,1,.08-.86A3.38,3.38,0,0,1,3.48,18a3.47,3.47,0,0,1,2,0,3.56,3.56,0,0,1,1,.49l.23.17V13.63c0-2.78,0-5.06,0-5.06l5-1.27L12.74,7l6.07-1.54s0,.38,0,1C18.83,7.46,18.84,8.92,18.83,10.64Z"/>
          <path d="M6.68,18V14.4l1.14-1.08c0,.61,0,2.23,0,4.12v1.62Z"/>
          <path d="M12.34,17.49a3.55,3.55,0,0,1,.44-.87v.17l-.09.28a4.45,4.45,0,0,1-.19.51,3.2,3.2,0,0,1-.29.51A2.77,2.77,0,0,1,12.34,17.49Z"/>
          <path d="M23.83,7.26V14l-.08.3a3.39,3.39,0,0,1-2.56,2.42,4.22,4.22,0,0,1-1.28,0,3.14,3.14,0,0,1-1.08-.41,3.31,3.31,0,0,1-1.1-1.07V11.71a3.31,3.31,0,0,1,3.83-1.36,2.94,2.94,0,0,1,1,.51l.19.15V8.28c0-1.5,0-2.73,0-2.73s-1.83.45-3.89,1l-6,1.54s0,1.63,0,3.72l-1.11.29V7.3c0-2.14,0-3.73,0-3.73l6-1.54L23.81.49S23.84,3.54,23.83,7.26Z"/>
        </g>
      </g>
    </svg>
  );
}

export default SongIcon;

SongIcon.defaultProps = {
  color: "black"
}
