// -> Beyond codebase
import React, { useContext } from "react";
import { css, StyleSheet } from "aphrodite-jss"
// -> Within codebase
import WavemapIcon from "../IconPresets/WavemapIcon";
import { ThemeInfo, UIContext } from "../../UI_InfoProvider/UI_InfoProvider";

const styleGen = (themeInfo: ThemeInfo) => {
  const { palette } = themeInfo;

  return StyleSheet.create({
    iconCradle: {
      height: 100,
      width: 100,
      display: "grid",
      placeItems: "center",
      borderRadius: "50%",
      border: `3px solid ${palette.primary}`,
      background: "transparent",
      boxShadow: "0 10px 20px rgba(150,17,216,0.19), 0 6px 6px rgba(150,17,216,0.23)",
    },
  });
}

const FloatingCircularLogo: React.FC = () => {
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { palette } = themeInfo;
  const { iconCradle } = styleGen(themeInfo);

  return (
    <div className={css(iconCradle)}>
      <WavemapIcon color={palette.primary} size={50} />
    </div>
  );
}

export default FloatingCircularLogo;
