import React from 'react';
import { BsGearFill } from 'react-icons/bs';

interface IIconPresetProps {
  size?: number;
  color?: string;
}

export const GearIcon: React.FC<IIconPresetProps> = (props) => {
  const { size, color } = props;

  return (
    <BsGearFill size={size} color={color} />
  );
}

export default GearIcon;
