// -> Beyond codebase
import React, { createContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
// -> Within codebase
import { styleGuide } from '../../styleGuide/styleGuide';
import { generateTypographyTable } from '../../styleGuide/typography';
import { customAlertGen, mapLanguageToLanguageCode } from "../../helpers";
import { AvailableLanguagesType } from '../../Types';
import { LANGUAGE__ENGLISH, THEME__DARK, THEME__LIGHT } from '../../constants';
// -> Within component
import { IUIContext as IUIContextState, initContextValue } from "./helpers";

export const UIContext = createContext(initContextValue);

// - TODO: -> Strongly type the containing elements of the themeInfo
export type ThemeInfo = {
  palette: any;
  fonts: any;
  typography: any;
  distance: any;
  stackingIndexes: any;
  borderRadii: any;
  shadows: any;
  gradients: any;
  transitions: any;
  styles: any;
}


const UI_InfoProvider: React.FC = (props) => {
  const { children } = props;
  const [state, setState] = useState<IUIContextState>({
    navBarVisible: true,
    backdropVisible: false,
    drawerOpen: false,
    modalOpen: false,
    currentLanguage: LANGUAGE__ENGLISH,
    currentTheme: THEME__LIGHT,
    adminLoginButtonVisible: false,
    toggleTheme: () => {},
    setTheme: (theme: string): void => {},
    toggleBackdrop: () => {},
    openBackdrop: () => {},
    closeBackdrop: () => {},
    toggleDrawer: () => {},
    openDrawer: () => {},
    closeDrawer: () => {},
    toggleModal: () => {},
    openModal: () => {},
    closeModal: () => {},
    showNavBar: () => {},
    hideNavBar: () => {},
    setLanguage: (language: string) => {},
    themeInfo: {
      palette: { ...styleGuide.palette },
      fonts: { ...styleGuide.fonts },
      typography: generateTypographyTable(styleGuide.fonts, styleGuide.palette),
      distance: { ...styleGuide.distance },
      stackingIndexes: { ...styleGuide.stackingIndexes },
      borderRadii: { ...styleGuide.borderRadii },
      shadows: { ...styleGuide.shadows },
      gradients: { ...styleGuide.gradients },
      transitions: { ...styleGuide.transitions },
      styles: { ...styleGuide.styles }
    }
  });
  const { i18n, t } = useTranslation("component_UIInfoProvider");

  let offlineAtLeastOnce = false;
  let onlineEventHandler = useRef<any>();
  let offlineEventHandler = useRef<any>();

  useEffect(() => {
    onlineEventHandler.current = window.addEventListener("online", handleOnlineTransition);
    offlineEventHandler.current = window.addEventListener("offline", handleOfflineTransition);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----- Theme ----- //
  const toggleTheme = (): void => {
    const { currentTheme } = state;
    if (currentTheme === THEME__LIGHT)  setState((prevState) => ({ ...prevState, theme: THEME__DARK }));
    else setState((prevState) => ({ ...prevState, theme: THEME__LIGHT }));
  };

  // ----- Nav Bar ----- //
  const showNavBar = (): void => {
    setState((prevState) => ({ ...prevState, navBarVisible: true }));
  };

  const hideNavBar = (): void => {
    setState((prevState) => ({ ...prevState, navBarVisible: false }));
  };

  // ----- Backdrop ----- //
  const toggleBackdrop = (): void => {
    // console.log('UI_InfoProvider: toggleBackdrop() invoked');
    if (state.drawerOpen) closeDrawer();
    setState((prevState) => ({ ...prevState, backdropVisible: !prevState.backdropVisible }));
  };
  
  const closeBackdrop = (): void => setState((prevState) => ({ ...prevState, backdropVisible: false }));
  const openBackdrop = (): void => setState((prevState) => ({ ...prevState, backdropVisible: true }));

  // ----- Modal ----- //
  const toggleModal = (): void => {
    setState((prevState) => ({ ...prevState, modalOpen: !prevState.modalOpen }));
  };

  const closeModal = (): void => setState((prevState) => ({ ...prevState, modalOpen: false }));
  const openModal = (): void => setState((prevState) => ({ ...prevState, modalOpen: true }));

  // ----- Drawer ----- //
  const toggleDrawer = (): void => {
    // console.log(`[UI_InfoProvider]: toggleDrawer() invoked`);
    setState((prevState) => ({ ...prevState, drawerOpen: !prevState.drawerOpen }));
  };

  const closeDrawer = (): void => setState((prevState) => ({ ...prevState, drawerOpen: false }));
  const openDrawer = (): void => setState((prevState) => ({ ...prevState, drawerOpen: true }));

  // ----- Network Connectivity ----- //
  const handleOnlineTransition = () => {
    if ((!offlineAtLeastOnce)) return;
    customAlertGen(t("networkUpMsg"), state.themeInfo);
  };

  const handleOfflineTransition = () => {
    if ((!offlineAtLeastOnce)) offlineAtLeastOnce = true;
    customAlertGen(t("networkDownMsg"), state.themeInfo);
  };

  // ----- Internationalization ----- //
  const setLanguage = (language: AvailableLanguagesType) => {
    setState((prevState) => ({ ...prevState, currentLanguage: language}));
    i18n.changeLanguage(mapLanguageToLanguageCode(language));
  };

  return (
    <UIContext.Provider value={{
      ...state, toggleTheme, toggleBackdrop, openBackdrop,
      closeBackdrop, toggleDrawer, closeDrawer, openDrawer,
      setLanguage, toggleModal, closeModal, openModal,
      showNavBar, hideNavBar,
    }}>
      {children}
    </UIContext.Provider>
  );
}

export default UI_InfoProvider;