import * as React from "react";
import { IIconProps } from "../../../Types"

const WavemapIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...Object.assign({}, {...props}, {...interpretedProps})}
    >
      <g id="Icons">
        <rect width="20" height="20" fill="transparent"/>
        <path d="M13.55,3.42a6.21,6.21,0,0,0-3.13,1.27c-.18.13-.63.5-1,.81A6,6,0,0,1,7.16,6.89a4.66,4.66,0,0,1-1.69.19A4.81,4.81,0,0,1,2.16,5.73c-.5-.42-.6-.47-.84-.47A.73.73,0,0,0,.58,6c0,.2.12.4.41.65A6.79,6.79,0,0,0,4.46,8.48a6.47,6.47,0,0,0,1.2.07,6.35,6.35,0,0,0,1.19-.07,6,6,0,0,0,2-.74,11.73,11.73,0,0,0,1.86-1.41,5.29,5.29,0,0,1,2.63-1.39,7.37,7.37,0,0,1,2,0,5.22,5.22,0,0,1,2.49,1.28c.49.42.51.42.71.47a.73.73,0,0,0,.87-.79c0-.26-.11-.37-.47-.67a6.79,6.79,0,0,0-3.42-1.76A10.77,10.77,0,0,0,13.55,3.42Z"/>
        <path d="M13.81,7.43a6.43,6.43,0,0,0-4,1.78,14.72,14.72,0,0,1-1.38,1.1,4.53,4.53,0,0,1-2.76.8A4.84,4.84,0,0,1,2.13,9.73c-.55-.46-.7-.52-1-.42a.75.75,0,0,0-.49.78c0,.27.14.38.62.78a6.27,6.27,0,0,0,4,1.7,6.35,6.35,0,0,0,3.39-.64,11,11,0,0,0,2.19-1.59,4.84,4.84,0,0,1,3.59-1.45,4.85,4.85,0,0,1,3.53,1.39c.55.46.7.52,1.05.41a.74.74,0,0,0,.49-.78c0-.25-.14-.38-.53-.71a6.34,6.34,0,0,0-4.07-1.77c-.29,0-.54,0-.56,0Z"/>
        <path d="M13.64,11.46A6.47,6.47,0,0,0,10,13.08c-1,.84-1.26,1.05-1.53,1.24a4.9,4.9,0,0,1-1.8.75,7.37,7.37,0,0,1-2,0,5.32,5.32,0,0,1-2.49-1.28c-.5-.42-.51-.43-.73-.47a.72.72,0,0,0-.85.79c0,.25.11.36.47.67A6.84,6.84,0,0,0,4.5,16.54a10,10,0,0,0,2.32,0,6.13,6.13,0,0,0,2-.72,12.69,12.69,0,0,0,1.76-1.32,5.89,5.89,0,0,1,2.26-1.38,3.88,3.88,0,0,1,1.5-.19,3.85,3.85,0,0,1,1.5.19,5.4,5.4,0,0,1,2,1.16c.5.41.6.47.84.47a.73.73,0,0,0,.74-.75c0-.2-.13-.4-.41-.65a6.75,6.75,0,0,0-3.47-1.82A13.52,13.52,0,0,0,13.64,11.46Z"/>
      </g>
    </svg>
  );
}

export default WavemapIcon;

WavemapIcon.defaultProps = {
  color: "black"
}
