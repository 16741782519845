// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite";
import * as yup from "yup";
import { Formik, Form, FormikValues } from "formik";
import onClickOutside from "react-onclickoutside";
// import "yup-phone";
// -> Within codebase
import Input from "../../FormUtilities/Input/Input";
import Button from "../../Button/Button";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import { ThemeInfo, UIContext } from "../../UI_InfoProvider/UI_InfoProvider";
import { VERTICAL, ROUNDED } from "../../../constants";
// -> Within component
import { styleGen } from "./AddPhoneNumberCardStyles";
import { IAddPhoneNumberCardProps, IAddPhoneNumberCardState } from "./helpers";

const initialValues: IAddPhoneNumberCardState = { phoneNumber: "" };
const validationSchema = yup.object({
  phoneNumber: yup.string(), //.phone("", true, "Invalid phone number format"),
});

// -> Written as a non-arrow function to avoid a bug caused by "react-onclickoutside"
// - TODO: -> Union type is kind of ugly, consider refactor somehow
const AddPhoneNumberCard: React.FC<IAddPhoneNumberCardProps> & {
  handleClickOutside?: () => any;
} = function (props) {
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { onAddPhoneNumberSubmission, closeModal } = props;
  const { cardCradle, topBarCradle, cardBottomCradle, promptText, formCradle } =
    styleGen(themeInfo);

  const onFormSubmission = (values: FormikValues) => {
    const { phoneNumber } = values;
    onAddPhoneNumberSubmission(phoneNumber);
  };

  // -> When the screen is clicked from anywhere off the modal, it correspondingly closes.
  AddPhoneNumberCard.handleClickOutside = () => closeModal();

  return (
    <div className={css(cardCradle)}>
      {/* TOP BAR */}
      <div className={css(topBarCradle)}>
        <p className={css(promptText)}>
          Looks like you've tried to set up multi-factor authentication with
          SMS, but you haven't added a phone number yet. Please add one to your
          profile below, and we can proceed.
        </p>
      </div>
      {/* MAIN CARD CONTENT*/}
      <div className={css(cardBottomCradle)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFormSubmission}
        >
          {(formik) => (
            <Form className={css(formCradle)}>

              <Spacer direction={VERTICAL} amount={distance.two} />
              <Input
                required
                name="phoneNumber"
                label="Phone Number"
                width="100%"
                borderGeometry={ROUNDED}
                mode="border"
                customInputCradleStyles={{ height: 35 }}
              />

              <Spacer direction={VERTICAL} amount={distance.two} />
              <Button
                buttonText="Add Phone Number"
                onClick={() => {}}
                type="submit"
                disabled={
                  !formik.isValid || !formik.dirty || formik.isSubmitting
                }
                customTextStyles={{ fontSize: "1.6rem", fontWeight: "bold" }}
                customCradleStyles={{
                  paddingTop: themeInfo.distance.one,
                  paddingBottom: themeInfo.distance.one,
                  paddingRight: themeInfo.distance.two,
                  paddingLeft: themeInfo.distance.two,
                  width: "100%",
                  height: 35,
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => AddPhoneNumberCard.handleClickOutside,
};

export default onClickOutside(AddPhoneNumberCard, clickOutsideConfig);
