import React from 'react';
import { BsFillPeopleFill } from 'react-icons/bs';

interface IIconPresetProps {
  size?: number;
  color?: string;
}

export const PeopleIcon: React.FC<IIconPresetProps> = (props) => {
  const { size, color } = props;

  return (
    <BsFillPeopleFill size={size} color={color} />
  );
}

export default PeopleIcon;
