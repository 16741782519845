import { REFRESH_TOKEN_KEY, MFA_LOGOUT_EVENT_KEY } from "../../constants";
// import { processBackEndExpiryDateTime } from "./helpers";

const inMemoryJWTManager = () => {
  let inMemoryJWT: string | null = null;
  let expiryDateTime: number | null = null;

  window.addEventListener("storage", (event) => {
    if (event.key === MFA_LOGOUT_EVENT_KEY) eraseToken();
  })

  const getToken = () => inMemoryJWT;

  const setToken = (token: string) => inMemoryJWT = token;

  const eraseToken = () => {
    inMemoryJWT = null;
    localStorage.setItem(MFA_LOGOUT_EVENT_KEY, Date.now().toString());
  }
  
  const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

  const setRefreshToken = (refreshToken: string) => localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

  const eraseRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_KEY);

  const getExpiryDateTime = () => expiryDateTime;

  const setExpiryDateTime = (dateTime: number) => expiryDateTime = dateTime * 1000; // -> expiry date time comes from server in seconds

  const eraseExpiryDateTime = () => expiryDateTime = null;

  return {
    getToken, setToken, eraseToken,
    getRefreshToken, setRefreshToken, eraseRefreshToken,
    getExpiryDateTime, setExpiryDateTime, eraseExpiryDateTime,
  };
}

export default inMemoryJWTManager();
