// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import ArtistCard from "../../../../Components/Cards/ArtistCard/ArtistCard";
import { ThemeInfo, UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { determineScreenDimensions } from "../../../../helpers";
import { Artist } from "../../../../Types";
// -> Within component
import { styleGen } from "./ArtistGalleryStyles";
import {
  IArtistGalleryProps, SMALL_CARD_WIDTH, LARGE_CARD_WIDTH, GALLERY_WIDTH_BREAKPOINT
} from "./helpers"

const { width: screenW } = determineScreenDimensions();

const ArtistGallery: React.FC<IArtistGalleryProps> = (props) => {
  const { artists, onArtistClick } = props;
  const { t } = useTranslation("page_artists");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  // const { distance } = themeInfo;
  const computedCardWidth = (screenW <= GALLERY_WIDTH_BREAKPOINT) ? SMALL_CARD_WIDTH : LARGE_CARD_WIDTH;
  // const computedCardWidth = (screenW <= GALLERY_WIDTH_BREAKPOINT) ? (screenW / 2) - distance.nine : LARGE_CARD_WIDTH;
  const {
    galleryCradle, galleryNoArtistsCradle, noContentFallbackText, customArtistGalleryCradleStyles,
    galleryContentCradle
  } = styleGen(themeInfo, props, computedCardWidth);

  return (
    <div className={css(galleryCradle, customArtistGalleryCradleStyles)}>
      {
        (artists.length > 0) ? (
          <div className={css(galleryContentCradle)}>
            {
              artists.map((artist: Artist) => {
                const { id, name, imageURL } = artist;
    
                return (
                  <ArtistCard
                    key={id}
                    id={id}
                    name={name}
                    imageURL={imageURL}
                    onClick={() => onArtistClick(id)}
                  />
                );
              })
            }
          </div>
        ) : (
          <div className={css(galleryNoArtistsCradle)}>
            <p className={css(noContentFallbackText)}>{t("noArtistsToDisplayMsg")}</p>
          </div>
        )
      }
    </div>
  );
}

export default ArtistGallery;
