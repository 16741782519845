import React from 'react';
import { BiPlus } from 'react-icons/bi';

interface IIconPresetProps {
  size?: number;
  color?: string;
}

const PlusIcon: React.FC<IIconPresetProps> = (props) => {
  const { size, color } = props;

  return (
    <BiPlus size={size} color={color} />
  );
}

export default PlusIcon;
