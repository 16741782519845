import * as React from "react";
import { IIconProps } from "../../../Types";

const SoundcloudIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...Object.assign({}, {...props}, {...interpretedProps})}>
      <path d="M432 240a79.66 79.66 0 00-16.672 1.76C408.128 169.024 346.592 112 272 112c-8.832 0-16 7.168-16 16v256c0 8.832 7.168 16 16 16h160c44.128 0 80-35.872 80-80 0-44.096-35.872-80-80-80zM208 144c-8.832 0-16 7.168-16 16v224c0 8.832 7.168 16 16 16s16-7.168 16-16V160c0-8.832-7.168-16-16-16zM144 208c-8.832 0-16 7.168-16 16v160c0 8.832 7.168 16 16 16s16-7.168 16-16V224c0-8.832-7.168-16-16-16zM80 208c-8.832 0-16 7.168-16 16v160c0 8.832 7.168 16 16 16s16-7.168 16-16V224c0-8.832-7.168-16-16-16zM16 256c-8.832 0-16 7.168-16 16v96c0 8.832 7.168 16 16 16s16-7.168 16-16v-96c0-8.832-7.168-16-16-16z" />
    </svg>
  );
}

export default SoundcloudIcon;
