export interface VenueListItemProps {
  itemNumber?: number;
  name: string;
  imageURL?: string;
  city: string;
  country: string;
  onClick(...params: any[]): any;
  customVenueListItemCradleStyles?: any;
  customVenueNameTextStyles?: any;
}

export const DEFAULT_VENUE_LIST_ITEM_HEIGHT = 75;
export const DEFAULT_IMAGE_SIZE = 60;
