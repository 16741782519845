// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite-jss";
// -> Within codebase
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import { CheckmarkIcon } from "../IconPresets";
import { UIContext, ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { VERTICAL } from "../../../constants";
// -> Within component
import { IStatusMessageProps, calibrateComponent } from "./helpers";
import { styleGen } from "./StatusMessageStyles";

const StatusMessage: React.FC<IStatusMessageProps> = (props) => {
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { palette, distance } = themeInfo;
  const { headerText, messageText, icon, iconSize, statusCircle, color } = props;
  const { iconWrapper } = styleGen(themeInfo, props);
  const styles = styleGen(themeInfo, props);
  const {
    headerTextStylechain, messageTextStylechain, cradleStylechain
  } = calibrateComponent(themeInfo, props, styles);

  return (
    <div className={cradleStylechain}>
      {
        (statusCircle) ? (
          <div className={css(iconWrapper)}>
            {(icon) ? icon : <CheckmarkIcon size={(iconSize) ? iconSize : 40} color={(color) ? color : palette.primary} />}
          </div>
        ) : (
          (icon) ? icon : <CheckmarkIcon size={(iconSize) ? iconSize : 40} color={(color) ? color : palette.primary} />
        )
      }
      <Spacer direction={VERTICAL} amount={distance.two} />
      {
        (headerText) && <p className={headerTextStylechain}>{headerText}</p>
      }
      <Spacer direction={VERTICAL} amount={distance.two} />
      {
        (messageText) && <p className={messageTextStylechain}>{messageText}</p>
      }
    </div>    
  );
}

export default StatusMessage;

StatusMessage.defaultProps = {
  statusCircle: true,
}
