import { IDropdownItem } from "../../Dropdown/stateManagement";
import {
  BANDCAMP, SOUNDCLOUD, SPOTIFY, APPLE_MUSIC, TIDAL, BEATPORT, VK, DEEZER,
  YOUTUBE, WEBSITE, INSTAGRAM, FACEBOOK, TWITTER, EVENTBRITE, DICE,
} from "../../../constants";
import { IPotentialExternalLinkEntry } from "../../../Types";
import { mapExternalLinkKeyToName } from "src/helpers";

export interface IExternalLinkEntryProps {
  state: any;
  setState: any;
  index: number;
  linkName: string;
  URL: string;
  removeExternalLinkEntry: any;
  validateExternalLinkEntryURL: any;
  errorStatus: boolean;
  errorMessage: string;
  URLFieldTouched: boolean;
  URLFieldDirty: boolean;
  type: "ARTIST" | "EVENT" | "VENUE" | "EVENT_TICKETS";
}

export const externalArtistLinkDropdownOptions: IDropdownItem[] = [
  { id: "b2355b7e-ea9e-4295-b305-f5d90b17ad73", label: BANDCAMP },
  { id: "e0acb406-ee7d-43d3-bdd1-bbd8932d06fa", label: SOUNDCLOUD },
  { id: "b8481e0a-05e5-4eb1-9bb5-dee7757974d1", label: SPOTIFY },
  { id: "eaef8e4c-7ad0-403a-a17e-c8dd1916a6af", label: APPLE_MUSIC },
  { id: "1e50ef88-21c3-49ad-8603-3d2865a09503", label: TIDAL },
  { id: "f1b46c5c-5b74-43d8-8667-1f332b5f0343", label: BEATPORT },
  { id: "f54d6f4b-de3f-4c66-9af7-ea4897f78c27", label: VK },
  { id: "6aaf7d05-fdac-4a8a-b982-7a56c0c90525", label: DEEZER },
  { id: "342921ca-28e3-479f-a9bc-9b9f587f0727", label: YOUTUBE },
  { id: "f423c5c3-fde5-4566-8a49-dd4a405c2dd6", label: WEBSITE },
  { id: "0fad2cb7-948c-4146-b82f-e38df56dabfe", label: INSTAGRAM },
  { id: "c0f93777-2956-44ff-abd2-c9d995087558", label: FACEBOOK },
  { id: "2ef8fe84-fe74-4b2a-a470-eb3fb7573b3d", label: TWITTER },
];

export const externalEventTicketsLinkDropdownOptions: IDropdownItem[] = [
  { id: "2f844594-efc0-485e-94ca-4255f25a6cec", label: EVENTBRITE },
  { id: "72bc1f8f-a64a-43b6-aa60-a0e7713cd10f", label: DICE },
  { id: "321970a8-93fd-49d0-8616-7858dbc3b569", label: WEBSITE },
];

export const externalEventLinkDropdownOptions: IDropdownItem[] = [
  { id: "2f844594-efc0-485e-94ca-4255f25a6cec", label: EVENTBRITE },
  { id: "72bc1f8f-a64a-43b6-aa60-a0e7713cd10f", label: DICE },
  { id: "c0f93777-2956-44ff-abd2-c9d995087558", label: FACEBOOK },
  { id: "321970a8-93fd-49d0-8616-7858dbc3b569", label: WEBSITE },
];

export const externalVenueLinkDropdownOptions: IDropdownItem[] = [
  { id: "b2355b7e-ea9e-4295-b305-f5d90b17ad73", label: BANDCAMP },
  { id: "e0acb406-ee7d-43d3-bdd1-bbd8932d06fa", label: SOUNDCLOUD },
  { id: "b8481e0a-05e5-4eb1-9bb5-dee7757974d1", label: SPOTIFY },
  { id: "eaef8e4c-7ad0-403a-a17e-c8dd1916a6af", label: APPLE_MUSIC },
  { id: "1e50ef88-21c3-49ad-8603-3d2865a09503", label: TIDAL },
  { id: "f1b46c5c-5b74-43d8-8667-1f332b5f0343", label: BEATPORT },
  { id: "f54d6f4b-de3f-4c66-9af7-ea4897f78c27", label: VK },
  { id: "6aaf7d05-fdac-4a8a-b982-7a56c0c90525", label: DEEZER },
  { id: "342921ca-28e3-479f-a9bc-9b9f587f0727", label: YOUTUBE },
  { id: "f423c5c3-fde5-4566-8a49-dd4a405c2dd6", label: WEBSITE },
  { id: "0fad2cb7-948c-4146-b82f-e38df56dabfe", label: INSTAGRAM },
  { id: "c0f93777-2956-44ff-abd2-c9d995087558", label: FACEBOOK },
  { id: "2ef8fe84-fe74-4b2a-a470-eb3fb7573b3d", label: TWITTER },
];

export const determineDropdownOptions = (currentEntityType: string): IDropdownItem[] => {
  let dropdownOptions: IDropdownItem[] = [];
  
  switch (currentEntityType) {
    case "ARTIST":
      dropdownOptions = externalArtistLinkDropdownOptions;
      break;
    case "EVENT":
      dropdownOptions = externalEventLinkDropdownOptions;
      break;
    case "EVENT_TICKETS":
      dropdownOptions = externalEventTicketsLinkDropdownOptions;
      break;
    case "VENUE":
      dropdownOptions = externalVenueLinkDropdownOptions;
      break;
    default: break;
  }

  return dropdownOptions;
};

export const computeAvailableExternalLinkOptions = (
  state: any,
  externalLinkDropdownOptions: IDropdownItem[]
): IDropdownItem[] => {
  let availableExternalLinkOptions: IDropdownItem[] = [];

  if (!state.potentialExternalLinks) return externalLinkDropdownOptions;

  const currentlyChosenExternalLinkNames: string[]
    = state.potentialExternalLinks.map((potentialExternalLink: IPotentialExternalLinkEntry) => {
    
    return mapExternalLinkKeyToName(potentialExternalLink.linkName);
  });

  availableExternalLinkOptions = externalLinkDropdownOptions.filter((option: IDropdownItem) => {
    return !currentlyChosenExternalLinkNames.includes(option.label);
  });

  return availableExternalLinkOptions;
};
