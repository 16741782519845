// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import { PersonIcon } from "../../VisualUtilities/IconPresets";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import { ThemeInfo, UIContext } from "../../UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL, ROUNDED } from "../../../constants";
import { computeItemNumberString } from "../../../helpers";
// -> Within component
import { styleGen } from "./ArtistListItemStyles";
import { IArtistListItemProps, DEFAULT_IMAGE_SIZE } from "./helpers";

const ArtistListItem: React.FC<IArtistListItemProps> = (props) => {
  const { name, imageURL, numEvents, imageSize, onClick, itemNumber } = props;
  const { t } = useTranslation("component_ArtistListItem");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const {
    listItemCradle, artistImageCradle, artistNameCradle, 
    artistNameText, customListItemCradleStyles, rightRowContentCradle,
    customArtistNameTextStyles, customSongNumTextStyles, fallbackIconCradle,
    artistMetadataItemColumn, artistMetadataItemValueText, artistMetadataItemLabelText,
    customArtistMetadataItemLabelTextStyles, customArtistMetadataItemValueTextStyles,
    itemNumberTextCradle, itemNumberText, customItemNumberTextStyles
  } = styleGen(themeInfo, props);

  const computedOnClick = (onClick) ? onClick : () => {};
  const computedImageSize = imageSize ? imageSize : DEFAULT_IMAGE_SIZE;

  return (
    <div className={css(listItemCradle, customListItemCradleStyles)} onClick={computedOnClick}>
      {
        (itemNumber) ? (
          <>
            <div className={css(itemNumberTextCradle)}>
              <p className={css(itemNumberText, customItemNumberTextStyles)}>{computeItemNumberString(itemNumber)}</p>
            </div>
            <Spacer direction={HORIZONTAL} amount={distance.three} />
          </>
        ) : undefined
      }
      <div className={css(artistImageCradle)}>
        {
          (imageURL) ? (
            <ImageWrapper
              alt={`Artist: ${name}`} src={imageURL}
              borderGeometry={ROUNDED}
              height={computedImageSize} width={computedImageSize}
              customImgStyles={{ borderRadius: themeInfo.borderRadii.two }}
              customImgCradleStyles={{ borderRadius: themeInfo.borderRadii.two }}
            />
          ) : (
            <div className={css(fallbackIconCradle)}>
              <PersonIcon color={themeInfo.palette.grey2} size={DEFAULT_IMAGE_SIZE - 20} />
            </div>
          )
        }
      </div>
      <Spacer direction={HORIZONTAL} amount={distance.three} />
      <div className={css(rightRowContentCradle)}>
        <div className={css(artistNameCradle)}>
          <p className={css(artistNameText, customArtistNameTextStyles)}>{name}</p>
        </div>
        <Spacer direction={HORIZONTAL} amount={distance.three} />
        <div className={css(artistMetadataItemColumn)}>
          <p className={css(artistMetadataItemValueText, customArtistMetadataItemValueTextStyles, customSongNumTextStyles)}>{numEvents}</p>
          <p className={css(artistMetadataItemLabelText, customArtistMetadataItemLabelTextStyles)}>{t("numEventsLabel", { count: numEvents })}</p>
        </div>
      </div>
    </div>
  );
}

export default ArtistListItem;
