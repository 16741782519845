// -> Beyond codebase
import React, { useContext, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "aphrodite-jss";
import { format } from "date-fns";
// -> Within codebase
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import IconButton from "../../Button/Variants/IconButton/IconButton";
import Tag from "src/Components/Tag/Tag";
import LineSegment from "../../VisualUtilities/LineSegment/LineSegment"
import ImageWrapper from "src/Components/VisualUtilities/ImageWrapper/ImageWrapper";
import { CheckmarkIcon, PersonIcon, XIcon } from "../../VisualUtilities/IconPresets";
import { ThemeInfo, UIContext } from "../../UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL, ROUND, ROUNDED, SCREEN_WIDTH_550, VERTICAL } from "src/constants";
import { computeActionDescriptionLabel, computeActionDescriptionText, computeSemanticActionType, determineScreenDimensions } from "../../../helpers";
import { ArtistAction, EventAction, VenueAction } from "src/Types";
// -> Wthin component
import { styleGen } from "./HistoricalActionModalStyles";
import {
  IHistoricalActionModalProps, computeActionBeforeEntityDetailComponent,
  computeActionAfterEntityDetailComponent, IHistoricalActionModalState,
} from "./helpers";

const HistoricalActionModal: React.FC<IHistoricalActionModalProps> = (props) => {
  const [state, setState] = useState<IHistoricalActionModalState>({ width: 1 })
  const { onCloseModal, action, action: {
    actionType, actionTargetType, dateTime, userInfo: { avatarURL, username }
  }} = props;
  const { themeInfo } = useContext(UIContext);
  const routerHistory = useHistory();
  const { t } = useTranslation("page_adminDashboard");
  const { palette, distance, fonts } = themeInfo;
  const {
    modalCradle, topPortion, titleText, metadataItemRow, metadataItemLabelText, metadataArea,
    bottomPortion, beforePane, afterPane, actionDescriptionText, metadataColumn, usernameText,
    fallbackIconCradle, paneTitleRow, paneBottomContent, noContentPane, noContentFallbackText,
    entitySnapshotLabelText
  } = styleGen(themeInfo);

  const computedActionBeforeEntityDetailComponent = computeActionBeforeEntityDetailComponent(action, routerHistory, onCloseModal);
  const computedActionAfterEntityDetailComponent = computeActionAfterEntityDetailComponent(action, routerHistory, onCloseModal);

  useLayoutEffect(() => {
    const { width } = determineScreenDimensions();
    setState({ width });

    const handleResize = () => {
      const { width } = determineScreenDimensions();
      setState({ width });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [])

  const { width } = state;

  return (
    <div className={css(modalCradle)}>
      <div className={css(topPortion)}>
        <p className={css(titleText)}>{t("actionModalTitleLabel")}</p>
        <Spacer direction={VERTICAL} amount={distance.three} />
        <div className={css(metadataItemRow)}>
        <p className={css(metadataItemLabelText)}>{computeActionDescriptionLabel({ action }, t)}</p>
          <Spacer direction={HORIZONTAL} amount={distance.one} />
          <p className={css(actionDescriptionText)}>{computeActionDescriptionText({ action }, t)}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.three} />
        <div className={css(metadataArea)}>
          <div className={css(metadataColumn)}>
            <div className={css(metadataItemRow)}>
              <p className={css(metadataItemLabelText)}>{t("actionTypeLabel")}</p>
              <Spacer direction={HORIZONTAL} amount={distance.one} />
              <Tag
                labelText={computeSemanticActionType(actionType)}
                tagGeometry={ROUNDED}
                activeColor={palette.white}
                activeTextColor={palette.primary_p1}
                activeBorder={`1px solid ${palette.primary_p1}`}
                customCradleStyles={{
                  paddingTop: distance.one, paddingBottom: distance.one,
                  paddingLeft: distance.one, paddingRight: distance.one,
                }}
                customTextStyles={{ fontSize: "1rem", fontWeight: "bold" }}
              />
            </div>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <div className={css(metadataItemRow)}>
              <p className={css(metadataItemLabelText)}>{t("actionTargetTypeLabel")}</p>
              <Spacer direction={HORIZONTAL} amount={distance.one} />
              <Tag
                labelText={actionTargetType}
                tagGeometry={ROUNDED}
                activeColor={palette.white}
                activeTextColor={palette.primary_p1}
                activeBorder={`1px solid ${palette.primary_p1}`}
                customCradleStyles={{
                  paddingTop: distance.one, paddingBottom: distance.one,
                  paddingLeft: distance.one, paddingRight: distance.one,
                }}
                customTextStyles={{ fontSize: "1rem", fontWeight: "bold" }}
              />
            </div>
          </div>
          {/* {
            (width > SCREEN_WIDTH_550) ? (
              <Spacer direction={HORIZONTAL} amount={distance.two} />
              ) : (
              <Spacer direction={VERTICAL} amount={distance.two} />
            )
          } */}
          <div className={css(metadataColumn)}>
            <div className={css(metadataItemRow)}>
              {
                (avatarURL) ? (
                  <ImageWrapper
                  alt={`Artist: ${username}`} src={avatarURL}
                  borderGeometry={ROUND}
                  height={15} width={15}
                  />
                  ) : (
                    <div className={css(fallbackIconCradle)}>
                    <PersonIcon color={themeInfo.palette.grey2} size={15 - 5} />
                  </div>
                )
              }
              <Spacer direction={HORIZONTAL} amount={distance.one} />
              <p className={css(usernameText)}>{username}</p>
            </div>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <div className={css(metadataColumn)}>
              <div>{format(dateTime, "yyyy-MM-dd ")}</div>
              <div>{format(dateTime, "kk:mm:ss")}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <Spacer direction={VERTICAL} amount={distance.three} /> */}
      <LineSegment
        direction={HORIZONTAL}
        width={"100%"} size={2}
        color={palette.black}
        customStyles={{
          marginTop: distance.three, marginBottom: distance.three,
        }}
      />
      <div className={css(bottomPortion)}>
        <div className={css(beforePane)}>
          <div className={css(paneTitleRow)}>
            <Tag
                labelText={t("actionModalBeforeLabel")}
                tagGeometry={ROUNDED}
                activeColor={palette.white}
                activeTextColor={palette.black}
                activeBorder={`1px solid ${palette.black}`}
                customCradleStyles={{
                  paddingTop: distance.one, paddingBottom: distance.one,
                  paddingLeft: distance.one, paddingRight: distance.one,
                }}
                customTextStyles={{
                  fontFamily: fonts.primary,
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: palette.black,
                }}
              />
            {/* <p className={css(entitySnapshotLabelText)}>{t("actionModalBeforeLabel")}</p> */}
          </div>
          {
            (action.previousInfoState) ? (
              <div className={css(paneBottomContent)}>
                {computedActionBeforeEntityDetailComponent}
              </div>
              ) : (
              <>
                <Spacer direction={VERTICAL} amount={distance.five} />
                <div className={css(paneBottomContent, noContentPane)}>
                  <p className={css(noContentFallbackText)}>{t("actionModalNoBeforeInfoLabel")}</p>
                </div>
              </>
            )
          }
        </div>
        {
          (width > 700) ? (
            <Spacer direction={HORIZONTAL} amount={distance.three} />
          ) : (
            <Spacer direction={VERTICAL} amount={distance.three} />
          )
        }
        {/* <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <LineSegment
            direction={VERTICAL} size={2}
            height={50}
            color={palette.black}
            customStyles={{ marginLeft: distance.three, marginRight: distance.three }}
          />
        </div> */}
        <div className={css(afterPane)}>
          <div className={css(paneTitleRow)}>
            <Tag
              labelText={t("actionModalAfterLabel")}
              tagGeometry={ROUNDED}
              activeColor={palette.white}
              activeTextColor={palette.black}
              activeBorder={`1px solid ${palette.black}`}
              customCradleStyles={{
                paddingTop: distance.one, paddingBottom: distance.one,
                paddingLeft: distance.one, paddingRight: distance.one,
              }}
              customTextStyles={{
                fontFamily: fonts.primary,
                fontSize: "1.4rem",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: palette.black,
              }}
            />
            {/* <p className={css(entitySnapshotLabelText)}>{t("actionModalAfterLabel")}</p> */}
          </div>
          {
            (action.newInfoState) ? (
              <div className={css(paneBottomContent)}>
                {computedActionAfterEntityDetailComponent}
              </div>
              ) : (
              <>
                <Spacer direction={VERTICAL} amount={distance.five} />
                <div className={css(paneBottomContent, noContentPane)}>
                  <p className={css(noContentFallbackText)}>{t("actionModalNoAfterInfoLabel")}</p>
                </div>
              </>
            )
          }

        </div>
      </div>
    </div>
  );
}

export default HistoricalActionModal;