import { MFAType } from "../../../../Types";

export const DEFAULT_CARD_WIDTH = 350;

export interface IChooseMFAMethodCardProps {
  onSelectMFAMethod(MFAType: MFAType): any;
  verificationOption: MFAType;
}

export interface IChooseMFAMethodCardState {
  verificationOption: MFAType;
}
