// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite-jss";
// -> Within codebase
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import CalendarIcon from "../../VisualUtilities/IconPresets/CalendarIcon";
import { XIcon } from "src/Components/VisualUtilities/IconPresets";
import Button from "src/Components/Button/Button";
import { UIContext } from "../../UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL, ROUND, ROUNDED } from "../../../constants";
// -> Within component
import { styleGen } from "./TypeAheadChosenResultStyles";
import { ITypeAheadChosenResultProps, DEFAULT_IMAGE_SIZE } from "./helpers";

const EventListItem: React.FC<ITypeAheadChosenResultProps> = (props) => {
  const { name, imageURL, id, onRemoveItem } = props;
  const { themeInfo } = useContext(UIContext);
  const {
    distance, palette, borderRadii, shadows,
    styles: { standardIconSize }, transitions,
  } = themeInfo;
  const {
    listItemCradle, eventImageCradle, fallbackIconCradle,
    eventNameText, customEventNameTextStyles, customCradleStyles,
  } = styleGen(props, themeInfo);

  return (
    <div className={css(listItemCradle, customCradleStyles)}>
      <div className={css(eventImageCradle)}>
        {
          (imageURL) ? (
            <ImageWrapper
              alt={`Event: ${name}`} src={imageURL}
              borderGeometry={ROUNDED}
              height={DEFAULT_IMAGE_SIZE} width={DEFAULT_IMAGE_SIZE}
              customImgStyles={{ borderRadius: borderRadii.two }}
              customImgCradleStyles={{ borderRadius: borderRadii.two }}
            />
          ) : (
            <div className={css(fallbackIconCradle)}>
              <CalendarIcon color={palette.grey2} size={DEFAULT_IMAGE_SIZE - 20} />
            </div>
          )
        }
      </div>

      <Spacer direction={HORIZONTAL} amount={distance.three} />

      <p className={css(eventNameText, customEventNameTextStyles)}>{name}</p>

      <Button
        icon={<XIcon size={standardIconSize - 5} color={palette.black} />}
        buttonGeometry={ROUND}
        iconTextSpacing={distance.one}
        color={palette.white}
        textColor={palette.black}
        customCradleStyles={{
          transition: `${transitions.boxShadowTransition}, ${transitions.bgColorTransition}`,
          backgroundColor: 'transparent',
          paddingLeft: 2.5, paddingRight: 2.5,
          paddingTop: 2.5, paddingBottom: 2.5,
          position: "absolute",
          top: distance.one, right: distance.one,
        }}
        customCradleHoverStyles={{
          boxShadow: shadows.one,
          backgroundColor: palette.grey1,
        }}
        onClick={() => onRemoveItem(id)}
      />
    </div>
  );
}

export default EventListItem;
