import * as React from "react";
import { IIconProps } from "../../../Types";

const BeatportIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...Object.assign({}, {...props}, {...interpretedProps})}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M9.18.14V3.51c0,3.47-.06,4.37-.44,5.17a29.18,29.18,0,0,1-3.26,3.49l-3,3,1.29,1.29,1.3,1.3,2.18-2.19,2.19-2.18-.52,1a8.59,8.59,0,0,0-.7,1.9A7.69,7.69,0,0,0,9,21.38a8.82,8.82,0,0,0,2.32,2.47,7.4,7.4,0,0,0,8.38-.31,7,7,0,0,0,2.83-5.75,6.32,6.32,0,0,0-.69-3.14,7,7,0,0,0-6.51-4.08,7.32,7.32,0,0,0-4,1.15c-.35.23-.33.21.24-.66,1.14-1.7,1.14-1.73,1.2-6.73l0-4.33H11C9.63,0,9.2,0,9.18.14ZM17.05,14.3a3.88,3.88,0,1,1-4.38,6.3,3.8,3.8,0,0,1-.6-5,3.74,3.74,0,0,1,1.83-1.44A4.11,4.11,0,0,1,17.05,14.3Z"/>
          <rect style={{ fill: "none "}} width="25" height="25"/>
        </g>
      </g>
    </svg>
  );
}

export default BeatportIcon;

BeatportIcon.defaultProps = {
  color: "black"
}
