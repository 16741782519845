// -> Beyond codebase
import React, { useContext, useEffect } from "react";
import { css } from "aphrodite";
import * as yup from "yup";
import { Formik, Form, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Button from "../../../../Components/Button/Button";
import Input from "../../../../Components/FormUtilities/Input/Input";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import FloatingCircularLogo from "../../../../Components/VisualUtilities/FloatingCircularLogo/FloatingCircularLogo";
import { ThemeInfo, UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { VERTICAL } from "../../../../constants";
// -> Within component
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IAuthenticateMFACardProps, IAuthenticateMFAFormState, computeAuthenticationMessage } from "./helpers";
import { styleGen } from "./AuthenticateMFACardStyles";

const initialValues: IAuthenticateMFAFormState = {
  userCode: "",
};

const AuthenticateMFACard: React.FC<IAuthenticateMFACardProps> = (props) => {
  // const inputRefs: any = useRef({}); // - TODO: -> Improve typescript here
  const { t } = useTranslation("page_authenticateMFA");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { cardCradle, promptText, formCradle, digitsCradle } =
    styleGen(themeInfo);
  const { onSubmitCode, MFAType, email, phoneNumber, resendCode, MFAScenario } = props;

  // console.log(`[AuthenticateMFACard]: email from props -> ${email}`);
  // console.log(`[AuthenticateMFACard]: phone number from props -> ${phoneNumber}`);

  const validationSchema = yup.object({
    userCode: yup.string()
      .required(t("userCodeRequiredErrorMsg")) 
      .matches(/[\d]{6}/, t("userCodeFormatErrorMsg")),
  });

  const onFormSubmission = (value: FormikValues) => {
    const { userCode } = value;
    // -> Combine individual digits into one string
    const code = `${userCode}`;
    onSubmitCode(MFAType, code);
  };

  // - TODO: -> Fix issues with use of forwardRef() in <Input />
  //           -> It seems like all instructions for the use of this feature were properly followed.
  //           -> Despite this, the ref is interpreted as a prop on the component which it does not
  //              recognize. With the use of forwardRef(), this ref should be passed down as a second
  //              parameter to the component rather than as part of the props object.
  //             -> According to the Typescript parser, this is not the case...
  useEffect(() => {
    // inputRefs.current[DIGIT_ONE].focus();
  }, []);

  const computedMessage = computeAuthenticationMessage(t, {
    MFAScenario, MFAType, email, phoneNumber,
  });
  return (
    <div className={css(cardCradle)}>
      <FloatingCircularLogo />
      <Spacer direction={VERTICAL} amount={distance.four} />
      <p className={css(promptText)}>{computedMessage}</p>
      <Spacer direction={VERTICAL} amount={distance.two} />

      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmission}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form className={css(formCradle)}>
            <div className={css(digitsCradle)}>
              <Input
                numberOnly={
                  MFAScenario === "Login Code Entry" ||
                  MFAScenario === "Confirmation"
                }
                name="userCode"
                mode="underline"
                maxLength={6}
                customInputCradleStyles={{
                  height: 35, width: "100%",
                  textAlign: "center",
                }}
                customControlCradleStyles={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                customInputTextStyles={{ fontSize: "1.4rem" }}
                width="80%"
                // ref={(element: any) => inputRefs.current[DIGIT_ONE] = element}
              />
            </div>
            <Spacer direction={VERTICAL} amount={distance.four} />

            <Button
              onClick={null}
              buttonText={t("codeSubmissionBtnLabel")}
              type="submit"
              disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
              customTextStyles={{
                fontSize: "1.6rem",
                fontWeight: "bold",
              }}
              customCradleStyles={{
                paddingTop: themeInfo.distance.one,
                paddingBottom: themeInfo.distance.one,
                paddingRight: themeInfo.distance.two,
                paddingLeft: themeInfo.distance.two,
                height: 35,
              }}
            />
            <Spacer direction={VERTICAL} amount={distance.two} />
            <Button
              buttonText={t("sendAnotherCodeBtnLabel")}
              transparent
              onClick={(e: any) => {
                e.stopPropagation(); // - TODO: -> This is not working, form still submits...
                resendCode();
              }}
              customTextStyles={{
                fontSize: "1.2rem",
                color: themeInfo.palette.primary,
              }}
              customCradleStyles={{
                paddingTop: themeInfo.distance.one,
                paddingBottom: themeInfo.distance.one,
                paddingRight: themeInfo.distance.two,
                paddingLeft: themeInfo.distance.two,
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuthenticateMFACard;
