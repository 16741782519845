// -> Beyond Codebase
import React, { useContext, MouseEvent, ChangeEvent, useEffect } from 'react';
import { css } from 'aphrodite-jss';
import cloneDeep from 'lodash.clonedeep';
import { useTranslation } from "react-i18next";
// -> Within Codebase
import Dropdown from '../../../../Components/Dropdown/Dropdown';
import Button from "../../../../Components/Button/Button";
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import Input from "../../../../Components/FormUtilities/Input/Input";
import Spacer from '../../../../Components/LayoutUtilities/Spacer/Spacer';
import IconButton from 'src/Components/Button/Variants/IconButton/IconButton';
import PlusIcon from 'src/Components/VisualUtilities/IconPresets/PlusIcon';
import MinusIcon from 'src/Components/VisualUtilities/IconPresets/MinusIcon';
import Fade from "../../../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import { ThemeInfo, UIContext } from '../../../../Components/UI_InfoProvider/UI_InfoProvider';
import {
  determineAvailableFilterConditionsByLanguage, generateDropdownItems,
  mapFilterConditionToEnglishFilterCondition, mapEnglishFilterConditionToTargetLanguage,
} from "../../../../helpers"; // -> Codebase-level helpers
import { IDropdownItem } from '../../../../Components/Dropdown/stateManagement';
import {
  FILTER_CRITERIA__NONE, FILTER_CONDITION_TYPE__LIST,
  FILTER_CONDITION_TYPE__RANGE, mapFilterCriteriaToEnglishFilterCriteria,
  mapEnglishFilterCriteriaToTargetLanguage, mapFilterConditionTypeToEnglishFilterConditionType,
  mapEnglishFilterConditionTypeToTargetLanguage, determineAvailableFilterCriteriaByLanguage,
  determineAvailableFilterConditionTypesByLanguage
} from '../../helpers'; // -> <Artists/> helpers
import {
  GREYSCALE, LEFT, FILTER_CONDITION__NONE, FILTER_CONDITION__INCLUDED_IN,
  FILTER_CONDITION__NOT_INCLUDED_IN, ROUND, DEFAULT_TRANSITION_MICROANIMATION_TIME,
  VERTICAL, HORIZONTAL,
} from "../../../../constants";
import { IArtistsState } from '../../stateManagement'; // -> Artists state management
// -> Within Component
import { styleGen } from './FilterPanelStyles';
import { IFilterPanelProps } from './helpers';

const FilterPanel: React.FC<IFilterPanelProps> = (props) => {
  const {
    dispatch, setFilterCriteria, setFilterCondition, applyFilterConfig,
    clearFilterConfig, setFilterArgument, state, addMicroanimationTimeoutID,
    addFilterArgumentFadeInStatus, removeFilterArgumentFadeInStatus,
    setFilterArgumentFadeInStatuses, setFilterConditionType,
    setFilterActivityIndicatorInStatus,
  } = props;
  let filterActivityIndicatorMicroanimationTimeoutID: any = -1;
  const {
    filterArgumentFadeInStatuses, currentFilterCriteria, currentFilterCondition,
    currentFilterConditionType, currentFilterArgument
  } = state;
  const { t, i18n } = useTranslation("page_artists");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { fonts, palette, distance, styles, transitions, shadows } = themeInfo;
  const {
    panelCradle, filterPanelTitleCradle, filterPanelTitleText, selectionDropdownLabel,
    bottomRow, bottomRightBtns, multipleFiltArgsHeaderRow, rangeInputRow, filterArgEntryRow
  } = styleGen(themeInfo);

  useEffect(() => {
    return () => clearTimeout(filterActivityIndicatorMicroanimationTimeoutID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----

  const onSetFilterCriteria = (filterCriteria: string) => {
    dispatch(setFilterCriteria(mapFilterCriteriaToEnglishFilterCriteria(filterCriteria, i18n.language)));
  };

  const onSetFilterCondition = (filterCondition: string) => {
    dispatch(setFilterCondition(mapFilterConditionToEnglishFilterCondition(filterCondition, i18n.language)));
  };

  const onSetFilterConditionType = (filterConditionType: string) => {
    dispatch(setFilterConditionType(mapFilterConditionTypeToEnglishFilterConditionType(filterConditionType, i18n.language)));
  };

  // -----

  const addFilterArgumentEntry = () => {
    const stateCopy = cloneDeep(state);
    stateCopy.currentFilterArgument.push("");
    
    dispatch(addFilterArgumentFadeInStatus());
    dispatch(setFilterArgument(stateCopy.currentFilterArgument));
  };

  // -----

  // - DEV NOTE -> For whatever reason, the state I clone on the first line of the function already has
  //               fade in-status missing from the array. As a stop gap, I pass in a copy of the
  //               former state in order to manipulate the boolean which will trigger the fade-out microanimation
  //               in the list item.
  const removeFilterArgumentEntry = (indexToDelete: number, prevState: IArtistsState) => {
    const stateCopy = cloneDeep(state);

    if ((stateCopy.currentFilterArgument.length > 0) &&
        (prevState.currentFilterArgument.length > 0)) {
      prevState.filterArgumentFadeInStatuses[indexToDelete] = false;
      dispatch(setFilterArgumentFadeInStatuses(prevState.filterArgumentFadeInStatuses));
      const timeout = setTimeout(() => {
        dispatch(removeFilterArgumentFadeInStatus(indexToDelete));
        stateCopy.currentFilterArgument =
          stateCopy.currentFilterArgument.filter((_, index: number) => index !== indexToDelete);
        dispatch(setFilterArgument(stateCopy.currentFilterArgument));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
      dispatch(addMicroanimationTimeoutID(timeout));
    }
  };

  // -----

  const onChangeFilterArgumentValue = (evt: ChangeEvent<HTMLInputElement>, index: number) => {
    const stateCopy = cloneDeep(state);

    stateCopy.currentFilterArgument[index] = evt.target.value;
    dispatch(setFilterArgument(stateCopy.currentFilterArgument));
  };

  // -----

  const filterCriteriaDropdownItems: IDropdownItem[] =
    generateDropdownItems(determineAvailableFilterCriteriaByLanguage(i18n.language));
  const filterConditionDropdownItems: IDropdownItem[] =
    generateDropdownItems(determineAvailableFilterConditionsByLanguage(i18n.language));
  const filterConditionTypeDropdownItems: IDropdownItem[] =
    generateDropdownItems(determineAvailableFilterConditionTypesByLanguage(i18n.language));

  return (
    <div className={css(panelCradle)}>
      <div className={css(filterPanelTitleCradle)}>
        <p className={css(filterPanelTitleText)}>{t("filterPanel_panelTitle")}</p>
      </div>

      <Spacer direction={VERTICAL} amount={distance.two} />

      {/* FILTER CRITERIA */}
      <p className={css(selectionDropdownLabel)}>{t("filterPanel_filterCriteriaLabel")}</p>
      <Dropdown
        selectedItemValue={mapEnglishFilterCriteriaToTargetLanguage(currentFilterCriteria, i18n.language)}
        onSelectItem={onSetFilterCriteria}
        items={filterCriteriaDropdownItems}
        colorMode={GREYSCALE}
        dropCradleAlignment={LEFT}
        customDropdownCradleStyles={{ width: "100%" }}
      />

      <Spacer direction={VERTICAL} amount={distance.two} />

      <LineSegment color={palette.grey1} width={`calc(100% - ${distance.four})`} size={1} />

      <Spacer direction={VERTICAL} amount={distance.two} />

      {/* FILTER CONDITION */}
      <p className={css(selectionDropdownLabel)}>{t("filterPanel_filterConditionLabel")}</p>
      <Dropdown
        selectedItemValue={mapEnglishFilterConditionToTargetLanguage(currentFilterCondition, i18n.language)}
        onSelectItem={onSetFilterCondition}
        items={filterConditionDropdownItems}
        colorMode={GREYSCALE}
        dropCradleAlignment={LEFT}
        customDropdownCradleStyles={{ width: "100%" }}
      />

      <Spacer direction={VERTICAL} amount={distance.two} />


      {/* FILTER ARGUMENT(S) */}
      {
        (currentFilterCondition !== FILTER_CONDITION__NONE) ? (
          <>
            {
              ((currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) || (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN)) ? (
                <>
                  <p className={css(selectionDropdownLabel)}>{t("filterPanel_filterConditionTypeLabel")}</p>
                  <Dropdown
                    selectedItemValue={mapEnglishFilterConditionTypeToTargetLanguage(currentFilterConditionType, i18n.language)}
                    onSelectItem={onSetFilterConditionType}
                    items={filterConditionTypeDropdownItems}
                    colorMode={GREYSCALE}
                    dropCradleAlignment={LEFT}
                    customDropdownCradleStyles={{ width: "100%" }}
                  />

                  <Spacer direction={VERTICAL} amount={distance.two} />

                  {
                    (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) ? (
                      (currentFilterArgument.length > 0) ? (
                        <>
                          <div className={css(multipleFiltArgsHeaderRow)}>
                            <p className={css(selectionDropdownLabel)}>{t("filterPanel_filterArgListLabel")}</p>
                            <Spacer direction={HORIZONTAL} amount={distance.two} />
                            <IconButton
                              icon={<PlusIcon color={palette.white} size={styles.standardIconSize - 8} />}
                              onClick={addFilterArgumentEntry}
                              buttonGeometry={ROUND}
                              customCradleStyles={{
                                padding: 3,
                                backgroundColor: palette.primary,
                                transition: transitions.boxShadowTransition
                              }}
                              customCradleHoverStyles={{ boxShadow: shadows.one }}
                            />
                          </div>
                          {
                            currentFilterArgument.map((filterArg: string, index: number) => (
                              <div key={index}>
                                <Fade inStatus={filterArgumentFadeInStatuses[index]}>
                                  <div className={css(filterArgEntryRow)}>
                                    <Input
                                      name={`filterArg-${index}`}
                                      mode="underline"
                                      useManualInputField={true}
                                      value={filterArg}
                                      onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeFilterArgumentValue(evt, index)}
                                      customInputTextStyles={{ fontSize: "1.4rem" }}
                                      customControlCradleStyles={{ width: "100%" }}
                                    />
                                    {
                                      (index > 0) ? (
                                        <>
                                          <Spacer direction={HORIZONTAL} amount={distance.two} />
                                          <IconButton
                                            icon={<MinusIcon color={palette.white} size={styles.standardIconSize - 8} />}
                                            onClick={() => removeFilterArgumentEntry(index, state)}
                                            buttonGeometry={ROUND}
                                            customCradleStyles={{
                                              padding: 3,
                                              backgroundColor: palette.primary,
                                              transition: transitions.boxShadowTransition
                                            }}
                                            customCradleHoverStyles={{ boxShadow: shadows.one }}
                                          />
                                        </>
                                      ) : null
                                    }
                                  </div>
                                </Fade>
                              </div>
                            ))
                          }
                        </>
                      ) : null
                    ) : null
                  }
                  {
                    (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) ? (
                      <div className={css(rangeInputRow)}>
                        <Fade inStatus={filterArgumentFadeInStatuses[0]}>
                          <Input
                            name={`filterArg-0`}
                            mode="underline"
                            label={t("filterPanel_rangeMinInputLabel")}
                            useManualInputField={true}
                            value={currentFilterArgument[0]}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeFilterArgumentValue(evt, 0)}
                            customInputTextStyles={{ fontSize: "1.4rem" }}
                            customControlCradleStyles={{ width: "100%" }}
                          />
                        </Fade>
                        <Spacer direction={HORIZONTAL} amount={distance.two} />
                        <Fade inStatus={filterArgumentFadeInStatuses[1]}>
                          <Input
                            name={`filterArg-1`}
                            mode="underline"
                            label={t("filterPanel_rangeMaxInputLabel")}
                            useManualInputField={true}
                            value={currentFilterArgument[1]}
                            onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeFilterArgumentValue(evt, 1)}
                            customInputTextStyles={{ fontSize: "1.4rem" }}
                            customControlCradleStyles={{ width: "100%" }}
                          />
                        </Fade>
                      </div>
                    ) : null
                  }
                </>
              ) : (
                <Fade inStatus={filterArgumentFadeInStatuses[0]}>
                  <Input
                    name="filterArg"
                    label={t("filterPanel_filterArgLabel")}
                    mode="underline"
                    useManualInputField={true}
                    value={currentFilterArgument[0]}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeFilterArgumentValue(evt, 0)}
                    customInputTextStyles={{ fontSize: "1.4rem" }}
                    customControlCradleStyles={{ width: "100%" }}
                  />
                </Fade>
              )
            }
            <Spacer direction={VERTICAL} amount={distance.two} />
          </>
        ) : null
      }

      <LineSegment color={palette.grey1} width={`calc(100% - ${distance.four})`} size={1} />
      <Spacer direction={VERTICAL} amount={distance.two} />

      {/* BOTTOM BUTTON ROW */}
      <div className={css(bottomRow)}>
        <Button
          buttonText={t("filterPanel_clearBtnLabel")}
          onClick={(evt: MouseEvent<HTMLButtonElement>) => {
            evt.stopPropagation();
            dispatch(setFilterActivityIndicatorInStatus(false));
            filterActivityIndicatorMicroanimationTimeoutID = setTimeout(() => {
              dispatch(clearFilterConfig());
            }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
          }}
          transparent
          customTextStyles={{
            fontFamily: fonts.primary,
            fontSize: "1.4rem",
            fontWeight: "bold",
            color: palette.grey4,
          }}
          customTextHoverStyles={{ textDecoration: "underline" }}
          customCradleStyles={{ padding: 0, width: "min-content", whiteSpace: "nowrap" }}
        />

        <div className={css(bottomRightBtns)}>
          {/* <Button
            buttonText="Cancel"
            onClick={(evt: MouseEvent<HTMLButtonElement>) => {
              evt.stopPropagation();
              // - TODO: -> Figure out how to close <Dropdown /> from outside of it.
              underConstructionAlert();
            }}
            transparent
            customTextStyles={{
              fontFamily: fonts.primary,
              fontSize: "1.4rem",
              fontWeight: "bold",
              color: palette.grey4,
            }}
            customTextHoverStyles={{ textDecoration: "underline" }}
            customCradleStyles={{ padding: 0, width: "min-content", whiteSpace: "nowrap" }}
          />
          <Spacer direction={HORIZONTAL} amount={distance.two} /> */}
          <Button
            buttonText={t("filterPanel_applyBtnLabel")}
            disabled={
              (currentFilterCriteria === FILTER_CRITERIA__NONE) ||
              (currentFilterCondition === FILTER_CONDITION__NONE)
            }
            onClick={(evt: MouseEvent<HTMLButtonElement>) => {
              evt.stopPropagation();
              dispatch(applyFilterConfig());
            }}
            customCradleStyles={{
              paddingTop: distance.one,
              paddingBottom: distance.one,
            }}
            customTextStyles={{
              fontFamily: fonts.primary,
              fontSize: "1.4rem",
              fontWeight: "bold",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FilterPanel;
