// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL } from "../../../../constants";
import { Venue } from "../../../../Types";
// -> Within component
import VenueListItem from "../../../../Components/ListItems/VenueListItem/VenueListItem";
import { styleGen } from "./VenueListStyles";
import { VenueListProps } from "./helpers";

const VenueList: React.FC<VenueListProps> = (props) => {
  const { venues, onVenueClick, currentPageNumber, itemsPerPage } = props;
  const { t } = useTranslation("page_venues");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, palette } = themeInfo;
  const {
    venueListCradle, venueContentCradle, noVenuesCradle,
    noContentFallbackText, customVenueListCradleStyles, itemSeparatorCradle
  } = styleGen(themeInfo, props);

  const venueCount = (venues) ? venues.length: 0;
  
  return (
    <div className={css(venueListCradle, customVenueListCradleStyles)}>
      {
        (venueCount > 0) ? (
          <div className={css(venueContentCradle)}>
            <>
              {
                venues.map((venue: Venue, index: number) => {
                  const { id, name, imageURL, city, country  } = venue;

                  return (
                    <div key={id}>
                      <VenueListItem
                        itemNumber={(index + 1) + ((currentPageNumber - 1) * itemsPerPage)}
                        name={name}
                        imageURL={imageURL}
                        city={city}
                        country={country}
                        onClick={() => onVenueClick(id)}
                      />
                      {
                        (index < (venueCount - 1)) ? (
                          <div className={css(itemSeparatorCradle)}>
                            <LineSegment
                              direction={HORIZONTAL}
                              width={`calc(100% - ${distance.six}px)`}
                              size={1}
                              color={palette.grey1}
                            />
                          </div>
                        ) : null
                      }
                    </div>
                  );
                })
              }
            </>
          </div>
        ) : (
          <div className={css(noVenuesCradle)}>
            <p className={css(noContentFallbackText)}>{t("noVenuesToDisplayMsg")}</p>
          </div>
        )
      }
    </div>
  );
}

export default VenueList;
