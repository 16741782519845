import { ReactNode } from "react";

export interface IPaginationProps {
  // -> General
  currentPage: number;
  setCurrentPage(pageNumber: number): any;
  itemsPerPage: number;
  numberOfItems: number;
  numPagesUntilBreak1?: number;
  numPagesUntilBreak2?: number;
  numPagesBeforeBreak1?: number;
  numPagesBetweenBreak1AndBreak2?: number;
  numPagesAfterBreak2?: number;
  buttonGeometry?: "round" | "rounded" | "orthogonal";
  rtl?: boolean;
  prevPageLabel?: string;
  leftwardsIcon?: ReactNode;
  nextPageLabel?: string;
  rightwardsIcon?: ReactNode;
  primaryColor?: string;
  secondaryColor?: string;
  itemGap?: string | number;
  // -> Conditional element visibility
  showHoverStylesOnCurrPageBtn?: boolean;
  showPrevButton?: boolean;
  showPrevButtonIcon?: boolean;
  showPrevButtonLabel?: boolean;
  showPrevButtonOnFirstPage?: boolean;
  showNextButton?: boolean;
  showNextButtonOnLastPage?: boolean;
  showNextButtonIcon?: boolean;
  showNextButtonLabel?: boolean;
  showDirectionButtonsWithOnePage?: boolean;
  showWithOnePage?: boolean;
  // -> Custom styles
  customPrevBtnTextStyles?: any;
  customPrevBtnHoverStyles?: any;
  customPrevBtnTextHoverStyles?: any;
  customPrevBtnStyles?: any;
  customNextBtnTextStyles?: any;
  customNextBtnHoverStyles?: any;
  customNextBtnTextHoverStyles?: any;
  customNextBtnStyles?: any;
  customBreak1BtnTextStyles?: any;
  customBreak1BtnHoverStyles?: any;
  customBreak1BtnTextHoverStyles?: any;
  customBreak1BtnStyles?: any;
  customBreak2BtnTextStyles?: any;
  customBreak2BtnHoverStyles?: any;
  customBreak2BtnTextHoverStyles?: any;
  customBreak2BtnStyles?: any;
  customPageNumBtnTextStyles?: any;
  customPageNumBtnHoverStyles?: any;
  customPageNumBtnTextHoverStyles?: any
  customPageNumBtnStyles?: any;
  customPrevBtnCradleStyles?: any;
  customPageNumBtnsCradleStyles?: any;
  customNextBtnCradleStyles?: any;
  customComponentCradleStyles?: any;
}

export const calibrateComponent = (props: IPaginationProps) => {
  const {
    numberOfItems, itemsPerPage, showPrevButton, showPrevButtonOnFirstPage,
    currentPage, showNextButton, showNextButtonOnLastPage, showPrevButtonLabel,
    prevPageLabel, showPrevButtonIcon, leftwardsIcon, showNextButtonLabel,
    nextPageLabel, showNextButtonIcon, rightwardsIcon, rtl, showDirectionButtonsWithOnePage
  } = props;

  const numberOfPages = Math.ceil(numberOfItems / itemsPerPage);
  let pageNumbers: number[] = [];
  for (let i = 1; i <= numberOfPages; i++) pageNumbers.push(i);

  const prevButtonVisible = (
    showPrevButton &&
    (((showPrevButtonOnFirstPage === false) && (currentPage === 1)) === false) &&
    (((showDirectionButtonsWithOnePage === false) && (numberOfPages === 1)) === false)
  );
  const nextButtonVisible = (
    showNextButton &&
    (((showNextButtonOnLastPage === false) && (currentPage === pageNumbers.length)) === false) &&
    (((showDirectionButtonsWithOnePage === false) && (numberOfPages === 1)) === false)
  );
  const computedPrevButtonText = showPrevButtonLabel ? prevPageLabel : undefined;
  const computedNextButtonText = showNextButtonLabel ? nextPageLabel : undefined;
  const computedPrevButtonIcon = showPrevButtonIcon ? (rtl ? rightwardsIcon : leftwardsIcon) : undefined;
  const computedNextButtonIcon = showNextButtonIcon ? (rtl ? leftwardsIcon : rightwardsIcon) : undefined;

  return {
    numberOfPages, pageNumbers, prevButtonVisible, nextButtonVisible,
    computedPrevButtonText, computedPrevButtonIcon, computedNextButtonText,
    computedNextButtonIcon
  };
};
