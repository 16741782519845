// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL } from "../../../../constants";
import { Event } from "../../../../Types";
// -> Within component
import EventListItem from "../../../../Components/ListItems/EventListItem/EventListItem";
import { styleGen } from "./EventListStyles";
import { IEventListProps } from "./helpers";

const EventList: React.FC<IEventListProps> = (props) => {
  const { events, onEventClick, currentPageNumber, itemsPerPage } = props;
  const { t } = useTranslation("page_events");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, palette } = themeInfo;
  const {
    eventListCradle, eventContentCradle, noEventsCradle,
    noContentFallbackText, customEventListCradleStyles, itemSeparatorCradle
  } = styleGen(themeInfo, props);

  const eventCount = (events) ? events.length : 0;

  return (
    <div className={css(eventListCradle, customEventListCradleStyles)}>
      {
        (eventCount > 0) ? (
          <div className={css(eventContentCradle)}>
            <>
              {
                events.map((event: Event, index: number) => {
                  const { id, name, startDateTime, imageURL } = event;
                  // const numEvents = (events) ? events.length : 0;

                  return (
                    <div key={id}>
                      <EventListItem
                        itemNumber={(index + 1) + ((currentPageNumber - 1) * itemsPerPage)}
                        name={name}
                        date={startDateTime}
                        imageURL={imageURL}
                        onClick={() => onEventClick(id)}
                      />
                      {
                        (index < (eventCount - 1)) ? (
                          <div className={css(itemSeparatorCradle)}>
                            <LineSegment
                              direction={HORIZONTAL}
                              width={`calc(100% - ${distance.six}px)`}
                              size={1}
                              color={palette.grey1}
                            />
                          </div>
                        ) : null
                      }
                    </div>
                  );
                })
              }
            </>
          </div>
        ) : (
          <div className={css(noEventsCradle)}>
            <p className={css(noContentFallbackText)}>{t("noEventsToDisplayMsg")}</p>
          </div>
        )
      }
    </div>
  );
}

export default EventList;
