// -> Beyond Codebase
import React, { useState, useEffect, useContext } from "react";
import { css } from "aphrodite";
import { useTranslation } from "react-i18next";
// -> Within Codebase
import VerticalFade from "../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade";
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import Spacer from "../../Components/LayoutUtilities/Spacer/Spacer";
import FillUnderNavBarCradle from "../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import Button from "../../Components/Button/Button";
import StatusMessage from "../../Components/VisualUtilities/StatusMessage/StatusMessage";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import { XIcon } from "../../Components/VisualUtilities/IconPresets";
import { UIContext, ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import { IRequestResetArgs } from "../../Components/AuthContextProvider/helpers";
import { mapHttpErrorCodeToMessage, customAlertGen } from "../../helpers";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, VERTICAL } from "../../constants";
// -> Within Component
import RequestResetCard from "./InternalComponents/RequestResetCard/RequestResetCard";
import {
  IRequestResetState, PAGE_STATE_NEW, PAGE_STATE_SUBMITTED,
} from "./helpers";
import { styleGen } from "./RequestResetStyles";

const RequestReset: React.FC = (props) => {
  const [state, setState] = useState<IRequestResetState>({
    loadingStatus: false,
    errorStatus: false,
    cardInStatus: true,
    loaderInStatus: true,
    successMsgInStatus: true,
    pageState: PAGE_STATE_NEW,
  });
  const { t } = useTranslation("page_requestReset");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { requestPasswordReset } = useContext(AuthContext);
  const { pageCradle, successContextCradle, mainContentCradle } = styleGen(themeInfo);

  // -----

  let cardTimeout: ReturnType<typeof setTimeout>;
  let loaderTimeout: ReturnType<typeof setTimeout>;
  let successMsgTimeout: ReturnType<typeof setTimeout>;
  let demoTimeout: ReturnType<typeof setTimeout>;

  // -> Clean up resources to avoid memory leaks.
  useEffect(() => {
    return () => {
      clearTimeout(cardTimeout);
      clearTimeout(loaderTimeout);
      clearTimeout(successMsgTimeout);
      clearTimeout(demoTimeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----

  const onResetEmailSubmission = async (args: IRequestResetArgs) => {
    setState((prevState) => ({ ...prevState, cardInStatus: false }));
    cardTimeout = setTimeout(() => {
      setState((prevState) => ({ ...prevState, loadingStatus: true }));
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    
    let error;
    setTimeout(async() => {
      let { error: err }= await requestPasswordReset(args);
      error = err;
    }, 1500)

    if (error) {
      const errorMessage = mapHttpErrorCodeToMessage(error);
      demoTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loaderInStatus: false, errorMessage }));
        loaderTimeout = setTimeout(() => {
          setState((prevState) => ({ ...prevState, loadingStatus: false, cardInStatus: true }));
          customAlertGen(errorMessage, themeInfo);
        }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
      }, 2000);
    }
    
    else {
      demoTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loaderInStatus: false }));
        loaderTimeout = setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            successMsgInStatus: true,
            pageState: PAGE_STATE_SUBMITTED,
            loadingStatus: false,
          }));
        }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
      }, 2000);
    }
  };

  // -----

  const enterAnotherEmail = () => {
    setState((prevProps) => ({ ...prevProps, successMsgInStatus: false }));
    successMsgTimeout = setTimeout(() => {
      setState((prevState) => ({ ...prevState, cardInStatus: true, pageState: PAGE_STATE_NEW }))
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  const {
    loadingStatus, errorStatus, cardInStatus, loaderInStatus,
    successMsgInStatus, pageState, errorMessage
  } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {
          (!loadingStatus) ? (
            (pageState === PAGE_STATE_NEW) ? (
              <VerticalFade inStatus={cardInStatus}>
                <div className={css(mainContentCradle)}>
                  {
                    (errorStatus) && (
                      <>
                        <Fade>
                          <StatusMessage
                            color={themeInfo.palette.errorStatus}
                            headerText={t("requestResetErrorMsg")}
                            messageText={errorMessage}
                            icon={<XIcon color={themeInfo.palette.errorStatus} size={40} />}
                          />
                        </Fade>
                        <Spacer direction={VERTICAL} amount={distance.three} />
                      </>
                    )
                  }
                  <RequestResetCard onResetEmailSubmission={onResetEmailSubmission} />
                </div>
              </VerticalFade>
            ) : (
              <Fade inStatus={successMsgInStatus}>
                <div className={css(successContextCradle)}>
                  <Fade>
                    <StatusMessage
                      headerText={t("requestResetSuccessTitle")}
                      messageText={t("requestResetSuccessMsg")}
                    />
                  </Fade>
                  
                  <Spacer direction={VERTICAL} amount={distance.three} />

                  <Button
                    buttonText={t("additionalRecoveryEmailBtnLabel")}
                    onClick={enterAnotherEmail}
                    transparent
                    customTextStyles={{ fontSize: '1.4rem', color: themeInfo.palette.primary, fontStyle: "italic" }}
                    customCradleStyles={{
                      paddingTop: themeInfo.distance.one,
                      paddingBottom: themeInfo.distance.one,
                      paddingRight: themeInfo.distance.two,
                      paddingLeft: themeInfo.distance.two,
                      width: "100%",
                      height: 35,
                    }}
                  />
                </div>
              </Fade>
            )
          ) : (
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={themeInfo.palette.primary} />
            </Fade>
          )
        }
      </div>
    </FillUnderNavBarCradle>
  );
}

export default RequestReset;

