import { StyleSheet } from "aphrodite";
import { ThemeInfo } from "../../../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME } from "../../../../../../constants";
import { IMFAVerificationOptionState, SELECTION_INDICATOR_SIZE } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, state: IMFAVerificationOptionState) => {
  const { selected } = state;
  return StyleSheet.create({
    componentCradle: {
      height: "auto",
      width: "100%",
      padding: themeInfo.distance.two,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: themeInfo.palette.white,
      border: `2px solid ${themeInfo.palette.primary_p3}`,
      borderRadius: themeInfo.borderRadii.one,
      transition: '0.2s ease-in-out',
      ":hover": { cursor: "pointer" },
    },
    componentCradleSelected: {
      backgroundColor: themeInfo.palette.primary_p4,
      border: `2px solid ${themeInfo.palette.primary}`,
    },
    radioButtonCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    restCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    selectionIndicatorCradle: {
      height: SELECTION_INDICATOR_SIZE,
      width: SELECTION_INDICATOR_SIZE,
      display: "grid",
      placeItems: "center",
      transition: `${DEFAULT_TRANSITION_MICROANIMATION_TIME}ms ease-in-out`,
      border: `2px solid ${(selected) ? themeInfo.palette.primary : themeInfo.palette.primary_p3 }`,
      borderRadius: 50000,
    },
    typeText: { ...themeInfo.typography.h5 },
    messageText: { ...themeInfo.typography.default, lineHeight: 1.3 },
  });
}