import { css } from 'aphrodite-jss';
import { ThemeInfo } from "../../../UI_InfoProvider/UI_InfoProvider";
import { ORTHOGONAL, ROUNDED, ROUND, COLOR } from '../../../../constants';

// - TODO: -> Weakness here, prefer string constants but had to use string literals.
export interface ITypeAheadSearchItemProps {
  disabled?: boolean;
  id: string;
  imageURL?: string;
  noImageModeEnabled?: boolean;
  label: string;
  item: any;
  onSelectItem: any;
  height?: number;
  backgroundColor?: string;
  colorMode?: 'Color' | 'Greyscale';
  geometry?: 'orthogonal' | 'rounded' | 'round';
  hasSelectionIndicators?: boolean;
  showAllSelectionIndicators?: boolean;
  showInnerCradleUnderlay?: boolean;
  customTypeAheadSearchItemCradleStyles?: any;
  customTypeAheadSearchItemInnerCradleStyles?: any;
  customTypeAheadSearchItemTextStyles?: any;
}

export const DEFAULT_SEARCH_LIST_ITEM_HEIGHT = 55;
export const DEFAULT_IMAGE_SIZE = 45;

const determineInnerCradleGeometry = (props: ITypeAheadSearchItemProps, styles: any) => {
  const { geometry } = props;
  let geometryStyle = null;

  switch(geometry) {
    case ORTHOGONAL: break;
    case ROUNDED: {
      geometryStyle = styles.innerCradleRounded;
      break;
    }
    case ROUND:
      geometryStyle = styles.innerCradleRound;
      break;
    default: break;
  }

  return geometryStyle;
}

export const calibrateComponent = (
  props: ITypeAheadSearchItemProps,
  themeInfo: ThemeInfo,
  styles: any
) => {
  const {
    searchResultItemCradle, innerCradle, searchItemTextStyles, customTypeAheadSearchItemCradleStyles,
    customTypeAheadSearchItemInnerCradleStyles, customTypeAheadSearchItemTextStyles
  } = styles;

  const innerCradleGeometryStyle = determineInnerCradleGeometry(props, styles);

  const typeAheadSearchItemCradleStylechain = css(searchResultItemCradle, customTypeAheadSearchItemCradleStyles);
  const innerCradleStylechain = css(innerCradle, innerCradleGeometryStyle, customTypeAheadSearchItemInnerCradleStyles);
  const searchItemTextStylechain = css(searchItemTextStyles, customTypeAheadSearchItemTextStyles);

  return { typeAheadSearchItemCradleStylechain, innerCradleStylechain, searchItemTextStylechain };
}

export const determineInnerCradleBackgroundColor = (
  props: ITypeAheadSearchItemProps,
  themeInfo: ThemeInfo,
  options: any
): string => {
  const { colorMode, showInnerCradleUnderlay } = props;
  const { palette } = themeInfo;
  const { isInHoverState } = options;

  let computedColor = 'transparent';

  if (isInHoverState && showInnerCradleUnderlay) {
    (colorMode === COLOR) ?
      computedColor = palette.primary_p2 : computedColor = palette.grey1;
  }

  return computedColor;
}
