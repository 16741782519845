// -> Beyond codebase
import React, { memo, useContext, useEffect, useState } from "react";
import {
  GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow, useJsApiLoader
}from "@react-google-maps/api";
import { css } from "aphrodite-jss";
import { useHistory } from "react-router-dom";
// -> Within codebase
import CircleLoader from "../Loaders/CircleLoader/CircleLoader";
import Fade from "../AnimationUtilities/TransitionWrappers/Fade/Fade";
import VenueInfoWindowContent from "../../Pages/VenueMap/InternalComponents/VenueInfoWindowContent/VenueInfoWindowContent";
import { ThemeInfo, UIContext } from "../UI_InfoProvider/UI_InfoProvider";
import { venues as venuesFromStaticData } from "../../staticData";
import { Venue } from "../../Types";
import {
  DEFAULT_TRANSITION_MICROANIMATION_TIME, VENUE_ID_ROUTE_PARAM_TOKEN, VENUE_ROUTE
} from "../../constants";
// -> Within component
import { styleGen } from "./MapStyles";
import { IMapProps, IMapState } from "./helpers";

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

const MapContainer: React.FC<IMapProps> = (props) => {
  const [state, setState] = useState<IMapState>({
    selectedVenue: null,
    infoWindowInStatus: true,
    mapInStatus: true,
  });
  const history = useHistory();
  const { containerStyles, center, zoom, venues } = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { palette } = themeInfo;
  const { loadingElementCradle } = styleGen(themeInfo);

  let infoWindowMicroanimationTimeout: any = -1;

  const loadingElement = (
    <div className={css(loadingElementCradle)}>
      <CircleLoader spinnerColor={palette.primary} size={35} />
    </div>
  );

  const computedVenuesToSearch = (venues) ? venues : venuesFromStaticData;

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY! // ,
    // ...otherOptions
  });

  // -----

  useEffect(() => {
    return () => clearTimeout(infoWindowMicroanimationTimeout);
  }, [infoWindowMicroanimationTimeout]);

  // -----

  const onClickMarker = (venue: Venue) => {
    setState((prevState) => ({ ...prevState, selectedVenue: venue }));
  }

  // -----

  const onCloseInfoWindow = () => {
    setState((prevState) => ({ ...prevState, selectedVenue: null }));
  }

  // -----

  const onInfoWindowClick = (venueID: string) => {
    const computedURL = VENUE_ROUTE.replace(VENUE_ID_ROUTE_PARAM_TOKEN, venueID);
    
    setState((prevState) => ({ ...prevState, mapInStatus: false }));
    infoWindowMicroanimationTimeout = setTimeout(() => {
      history.push(computedURL);
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  const { selectedVenue, mapInStatus, infoWindowInStatus } = state;

  // - TODO: -> Microanimation not working on the map or info windows
  return (
    <>
      {
        (isLoaded) ? (
          <Fade inStatus={mapInStatus}>
            <GoogleMap
              zoom={zoom}
              center={center}
              mapContainerStyle={containerStyles}
            >
              <MarkerClusterer
                averageCenter={true}
                // ignoreHidden={true} // - DEV NOTE -> Could be useful if hiding markers programatically
                gridSize={25} // - DEV NOTE -> Default is set to 60, tune if needed
              >
                {
                  (clusterer) => (
                    computedVenuesToSearch!.map((venue: Venue) => {
                      const { id, coordinates } = venue;
          
                      return (
                        <div key={id}>
                          <Marker
                            position={coordinates}
                            clusterer={clusterer}
                            onClick={() => onClickMarker(venue)}
                          />
                          {/* {
                            (selectedVenue) ? (
                              <Fade inStatus={infoWindowInStatus}>
                                <InfoWindow
                                  position={{
                                    lat: selectedVenue.coordinates.lat,
                                    lng: selectedVenue.coordinates.lng
                                  }}
                                  onCloseClick={onCloseInfoWindow}
                                >
                                  <VenueInfoWindowContent
                                    id={selectedVenue.id}
                                    name={selectedVenue.name}
                                    imageURL={selectedVenue.imageURL}
                                    onClick={onInfoWindowClick}
                                  />
                                </InfoWindow>
                              </Fade>
                            ) : null
                          } */}
                        </div>
                      );
                    })
                  )
                }
              </MarkerClusterer>
            </GoogleMap>
          </Fade>
        ) : (
          <CircleLoader spinnerColor={palette.primary} />
        )
      }
    </>
  );
}

export default memo(MapContainer);

MapContainer.defaultProps = {
  zoom: 3,
  center: { lat: 45, lng: -30 },
  // center: { lat: 47.606209, lng: -122.332069 }, // -> Seattle
  // center: { lat: 45, lng: -75 }, // -> Ottawa
};
