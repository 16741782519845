import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { NAV_BAR_HEIGHT } from "../../constants";

export const styleGen = (themeInfo: ThemeInfo) => {
  // const { customPageCradleStyles } = props;
  const { distance, palette } = themeInfo;

  return StyleSheet.create({
    componentCradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      width: '100%',
      backgroundColor: palette.white,
      paddingTop: distance.two,
      paddingBottom: distance.three,
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    artistsContentCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    loaderCradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    addArtistBtnCradle: {
      height: "auto",
      width: "auto",
      position: "fixed",
      bottom: distance.three,
      right: distance.three,
    },
    // customPageCradleStyles: { ...customPageCradleStyles },
  });
}
