// -> Beyond codebase
import React, { useEffect, useState, useContext } from "react";
import { css } from "aphrodite";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// -> Within codebase
import VerticalFade from "../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade";
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import StatusMessage from "../../Components/VisualUtilities/StatusMessage/StatusMessage";
import FillUnderNavBarCradle from "../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import Button from "../../Components/Button/Button";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import Spacer from "../../Components/LayoutUtilities/Spacer/Spacer";
import { XIcon } from "../../Components/VisualUtilities/IconPresets";
import { UIContext, ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import { IResetPasswordArgs } from "../../Components/AuthContextProvider/helpers";
import { mapHttpErrorCodeToMessage, customAlertGen, useQueryParams } from "../../helpers";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, LOGIN_ROUTE, RESET_PASSWORD_TOKEN_KEY, VERTICAL } from "../../constants";
// -> Within component
import ResetPasswordCard from "./InternalComponents/ResetPasswordCard/ResetPasswordCard";
import {
  IResetPasswordState, PAGE_STATE_NEW, PAGE_STATE_SUBMITTED, IResetPasswordFormArgs
} from "./helpers";
import { styleGen } from "./ResetPasswordStyles";

const ResetPassword: React.FC = (props) => {
  const [state, setState] = useState<IResetPasswordState>({
    loadingStatus: false,
    errorStatus: false,
    cardInStatus: true,
    loaderInStatus: true,
    successMsgInStatus: true,
    pageState: PAGE_STATE_NEW,
  });
  const history = useHistory();
  const queryParams = useQueryParams();
  const { t } = useTranslation("page_resetPassword");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { resetPassword, getUser } = useContext(AuthContext);
  const user = getUser();
  const userId = user?.id!;
  const { pageCradle, successContextCradle, mainContentCradle } = styleGen(themeInfo);

  let cardTimeout: ReturnType<typeof setTimeout>;
  let loaderTimeout: ReturnType<typeof setTimeout>;
  let successMsgTimeout: ReturnType<typeof setTimeout>;
  let demoTimeout: ReturnType<typeof setTimeout>;
  let passwordResetToken: string | null;
  let errorMsg: string;

  // -> When the user clicks on the reset link sent to their email, it will contain
  //    a specialized token attached to the URL. This will be sent back along with the new
  //    password to the server to validate the process.
  passwordResetToken = queryParams.get(RESET_PASSWORD_TOKEN_KEY);

  // -----

  // -> Clean up resources to avoid memory leaks.
  useEffect(() => {
    return () => {
      clearTimeout(cardTimeout);
      clearTimeout(loaderTimeout);
      clearTimeout(successMsgTimeout);
      clearTimeout(demoTimeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----
  
  const onNavigateToLogin = () => {
    setState((prevState) => ({ ...prevState, successMsgInStatus: false }));
    successMsgTimeout = setTimeout(() => {
      history.push(LOGIN_ROUTE);
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  const onPasswordResetSubmission = async (args: IResetPasswordFormArgs) => {
    setState((prevState) => ({ ...prevState, cardInStatus: false }));
    cardTimeout = setTimeout(() => {
      setState((prevState) => ({ ...prevState, loadingStatus: true }));
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);

    if (!passwordResetToken) {
      errorMsg = t("noResetTokenErrorMsg");
      setState((prevState) => ({ ...prevState, loaderInStatus: true, errorMessage: errorMsg }));
      loaderTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loadingStatus: false, cardInStatus: true }));
        customAlertGen(errorMsg, themeInfo, { autoClose: 5000 });
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);

      return;
    }

    const submissionArgs: IResetPasswordArgs = {
      new_password1: args.password,
      new_password2: args.passwordConfirmation,
      uid: userId,
      token: passwordResetToken!, // - DEV NOTE -> Asserting that this is non-null since I have a check for it above.
    }

    let error;
    setTimeout(async() => {
      let { error: err }= await resetPassword(submissionArgs);
      error = err;
    }, 1500)

    if (error) {
      errorMsg = mapHttpErrorCodeToMessage(error);
      demoTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loaderInStatus: false, errorMessage: errorMsg }));
        loaderTimeout = setTimeout(() => {
          setState((prevState) => ({ ...prevState, loadingStatus: false, cardInStatus: true }));
          customAlertGen(errorMsg, themeInfo);
        }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
      }, 2000);
    }

    else {
      demoTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loaderInStatus: false }));
        loaderTimeout = setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            successMsgInStatus: true,
            pageState: PAGE_STATE_SUBMITTED,
            loadingStatus: false,
          }))
        }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
      }, 2000);
    }
  };

  // -----

  const {
    loadingStatus, errorStatus, cardInStatus, loaderInStatus,
    successMsgInStatus, pageState, errorMessage
  } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {
          (!loadingStatus) ? (
            (pageState === PAGE_STATE_NEW) ? (
              <VerticalFade inStatus={cardInStatus}>
                <div className={css(mainContentCradle)}>
                  {
                    (errorStatus) && (
                      <>
                        <Fade>
                          <StatusMessage
                            color={themeInfo.palette.errorStatus}
                            headerText={t("passwordResetErrorTitleMsg")}
                            messageText={errorMessage}
                            icon={<XIcon color={themeInfo.palette.errorStatus} size={40} />}
                          />
                        </Fade>
                        <Spacer direction={VERTICAL} amount={distance.three} />
                      </>
                    )
                  }
                  <ResetPasswordCard onPasswordResetSubmission={onPasswordResetSubmission} />
                </div>
              </VerticalFade>
            ) : (
              <Fade inStatus={successMsgInStatus}>
                <div className={css(successContextCradle)}>
                  <Fade>
                    <StatusMessage
                      headerText={t("passwordResetSuccessTitleMsg")}
                      messageText={t("passwordResetSuccessBodyMsg")}
                    />
                  </Fade>

                  <Spacer direction={VERTICAL} amount={distance.three} />

                  <Button
                    buttonText={t("loginBtnLabel")}
                    onClick={onNavigateToLogin}
                    customTextStyles={{ fontSize: '1.6rem', color: themeInfo.palette.white, fontWeight: "bold" }}
                    customCradleStyles={{
                      paddingTop: themeInfo.distance.one,
                      paddingBottom: themeInfo.distance.one,
                      paddingRight: themeInfo.distance.two,
                      paddingLeft: themeInfo.distance.two,
                      width: "auto",
                      minWidth: 100,
                      height: 35,
                    }}
                  />
                </div>
              </Fade>
            )
          ) : (
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={themeInfo.palette.primary} />
            </Fade>
          )
        }

      </div>
    </FillUnderNavBarCradle>
  );
}

export default ResetPassword;
