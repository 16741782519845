import React, { ReactNode } from "react";
import { styleGuide } from "../styleGuide/styleGuide";
import {
  FacebookIcon, GlobeIcon, GoogleDriveIcon, InstagramIcon,
  NoResourceTypeIcon, SpotifyIcon, TwitterIcon, VKIcon, YoutubeIcon
} from "../Components/VisualUtilities/IconPresets";
import BandcampIcon from "src/Components/VisualUtilities/IconPresets/BandcampIcon";
import SoundcloudIcon from "src/Components/VisualUtilities/IconPresets/SoundcloudIcon";
import AppleMusicIcon from "src/Components/VisualUtilities/IconPresets/AppleMusicIcon";
import TidalIcon from "src/Components/VisualUtilities/IconPresets/TidalIcon";
import BeatportIcon from "src/Components/VisualUtilities/IconPresets/BeatportIcon";
import DeezerIcon from "src/Components/VisualUtilities/IconPresets/DeezerIcon";
import DiceEventsIcon from "src/Components/VisualUtilities/IconPresets/DiceEventsIcon";
import EventbriteIcon from "src/Components/VisualUtilities/IconPresets/EventbriteIcon";
import {
  BANDCAMP_KEY, SOUNDCLOUD_KEY, SPOTIFY_KEY, APPLE_MUSIC_KEY, TIDAL_KEY, BEATPORT_KEY,
  VK_KEY, DEEZER_KEY, YOUTUBE_KEY, GOOGLE_DRIVE_KEY, WEBSITE_KEY, INSTAGRAM_KEY,
  FACEBOOK_KEY, TWITTER_KEY, DICE_KEY, BANDCAMP_HOME_URL, SOUNDCLOUD_HOME_URL,
  SPOTIFY_HOME_URL, APPLE_MUSIC_HOME_URL, TIDAL_HOME_URL, BEATPORT_HOME_URL, VK_HOME_URL,
  DEEZER_HOME_URL, YOUTUBE_HOME_URL, WEBSITE_HOME_URL, INSTAGRAM_HOME_URL, TWITTER_HOME_URL,
  FACEBOOK_HOME_URL, TWITTER, FACEBOOK, INSTAGRAM, WEBSITE, YOUTUBE, DEEZER, VK, BEATPORT,
  TIDAL, APPLE_MUSIC, SPOTIFY, SOUNDCLOUD, BANDCAMP, EVENTBRITE, EVENTBRITE_KEY, DICE, EVENTBRITE_HOME_URL, DICE_HOME_URL,
} from "../constants";

export const mapExternalLinkToIcon = (
  linkName: string,
  size: number = styleGuide.styles.standardIconSize,
  color: string = styleGuide.palette.black
) => {
  let icon: ReactNode;
  
  switch (linkName) {
    case BANDCAMP_KEY:
      icon = <BandcampIcon size={size} color={color} />;
      break;
    case SOUNDCLOUD_KEY:
      icon = <SoundcloudIcon size={size} color={color} />;
      break;
    case SPOTIFY_KEY:
      icon = <SpotifyIcon size={size - 5} color={color} />;
      break;
    case APPLE_MUSIC_KEY:
      icon = <AppleMusicIcon size={size - 5} color={color} />;
      break;
    case TIDAL_KEY:
      icon = <TidalIcon size={size} color={color} />;
      break;
    case BEATPORT_KEY:
      icon = <BeatportIcon size={size - 5} color={color} />;
      break;
    case VK_KEY:
      icon = <VKIcon size={size} color={color} />;
      break;
    case DEEZER_KEY:
      icon = <DeezerIcon size={size - 5} color={color} />
      break;
    case YOUTUBE_KEY:
      icon = <YoutubeIcon size={size} color={color} />;
      break;
    case GOOGLE_DRIVE_KEY:
      icon = <GoogleDriveIcon size={size - 5} color={color} />;
      break;
    case WEBSITE_KEY:
      icon = <GlobeIcon size={size} color={color} />;
      break;
    case INSTAGRAM_KEY:
      icon = <InstagramIcon size={size - 5} color={color} />;
      break;
    case FACEBOOK_KEY:
      icon = <FacebookIcon size={size - 2.5} color={color} />;
      break;
    case TWITTER_KEY:
      icon = <TwitterIcon size={size} color={color} />;
      break;
    case DICE_KEY:
      icon = <DiceEventsIcon size={size} color={color} />
      break;
    case EVENTBRITE_KEY:
      icon = <EventbriteIcon size={size} color={color} />
      break;
    default:
      icon = <NoResourceTypeIcon size={size} color={color} />;
      break;
  }

  return icon;
}

export const mapExternalLinkNameToKey = (linkName: string): string => {
  let linkKey = "";

  switch (linkName) {
    case BANDCAMP:
      linkKey = BANDCAMP_KEY;
      break;
    case SOUNDCLOUD:
      linkKey = SOUNDCLOUD_KEY
      break;
    case SPOTIFY:
      linkKey = SPOTIFY_KEY;
      break;
    case APPLE_MUSIC:
      linkKey = APPLE_MUSIC_KEY;
      break;
    case TIDAL:
      linkKey = TIDAL_KEY;
      break;
    case BEATPORT:
      linkKey = BEATPORT_KEY;
      break;
    case VK:
      linkKey = VK_KEY;
      break;
    case DEEZER:
      linkKey = DEEZER_KEY;
      break;
    case YOUTUBE:
      linkKey = YOUTUBE_KEY;
      break;
    case WEBSITE:
      linkKey = WEBSITE_KEY;
      break;
    case INSTAGRAM:
      linkKey = INSTAGRAM_KEY;
      break;
    case FACEBOOK:
      linkKey = FACEBOOK_KEY;
      break;
    case TWITTER:
      linkKey = TWITTER_KEY;
      break;
    case EVENTBRITE:
      linkKey = EVENTBRITE_KEY;
      break;
    case DICE:
      linkKey = DICE_KEY;
      break;
    default: break;
  }
  
  return linkKey;
}

export const mapExternalLinkKeyToName = (linkKey: string): string => {
  let linkName = "";

  switch (linkKey) {
    case BANDCAMP_KEY:
      linkName = BANDCAMP;
      break;
    case SOUNDCLOUD_KEY:
      linkName = SOUNDCLOUD;
      break;
    case SPOTIFY_KEY:
      linkName = SPOTIFY;
      break;
    case APPLE_MUSIC_KEY:
      linkName = APPLE_MUSIC;
      break;
    case TIDAL_KEY:
      linkName = TIDAL;
      break;
    case BEATPORT_KEY:
      linkName = BEATPORT;
      break;
    case VK_KEY:
      linkName = VK;
      break;
    case DEEZER_KEY:
      linkName = DEEZER;
      break;
    case YOUTUBE_KEY:
      linkName = YOUTUBE;
      break;
    case WEBSITE_KEY:
      linkName = WEBSITE;
      break;
    case INSTAGRAM_KEY:
      linkName = INSTAGRAM;
      break;
    case FACEBOOK_KEY:
      linkName = FACEBOOK;
      break;
    case TWITTER_KEY:
      linkName = TWITTER;
      break;
    case EVENTBRITE_KEY:
      linkName = EVENTBRITE;
      break;
    case DICE_KEY:
      linkName = DICE;
      break;
    default: break;
  }
  
  return linkName;
}

export const navigateToExternalLinkHomePage = (linkName: string) => {
  let link;

  switch(linkName) {
    case BANDCAMP_KEY:
      link = BANDCAMP_HOME_URL;
      break;
    case SOUNDCLOUD_KEY:
      link = SOUNDCLOUD_HOME_URL;
      break;
    case SPOTIFY_KEY:
      link = SPOTIFY_HOME_URL;
      break;
    case APPLE_MUSIC_KEY:
      link = APPLE_MUSIC_HOME_URL;
      break;
    case TIDAL_KEY:
      link = TIDAL_HOME_URL;
      break;
    case BEATPORT_KEY:
      link = BEATPORT_HOME_URL;
      break;
    case VK_KEY:
      link = VK_HOME_URL;
      break;
    case DEEZER_KEY:
      link = DEEZER_HOME_URL;
      break;
    case YOUTUBE_KEY:
      link = YOUTUBE_HOME_URL;
      break;
    case WEBSITE_KEY:
      link = WEBSITE_HOME_URL;
      break;
    case INSTAGRAM_KEY:
      link = INSTAGRAM_HOME_URL;
      break;
    case FACEBOOK_KEY:
      link = FACEBOOK_HOME_URL;
      break;
    case TWITTER_KEY:
      link = TWITTER_HOME_URL;
      break;
    case EVENTBRITE_KEY:
      link = EVENTBRITE_HOME_URL;
      break;
    case DICE:
      link = DICE_HOME_URL;
      break;
    default: break;
  }

  window.open(link, "_blank");
}
