import { ITypeAheadSearchItem } from "src/Components/TypeAheadSearch/stateManagement";
import { BANDCAMP, LANGUAGE__ENGLISH, LANGUAGE__FRENCH } from "src/constants";
import { mapLanguageCodeToLanguage } from "src/helpers";
import {
  Event, EventLinks, IPotentialExternalLinkEntry, Venue, Artist, EventType, EventTicketLinks,
} from "../../../Types";

export interface IAddOrEditEventFormProps {
  formMode: 'ADD' | 'EDIT';
  eventDetails?: Event;
  onSubmit: any;
  isInCardMode?: boolean;
  customFormCradleStyles?: any;
  customFormTitleTextStyles?: any;
  rtl?: boolean;
}

export interface IAddOrEditEventFormState {
  links?: EventLinks
  potentialExternalLinks?: IPotentialExternalLinkEntry[];
  potentialTicketsLink?: IPotentialExternalLinkEntry;
  manualFormFieldsValid: boolean;
  microAnimationTimeoutIDs: any[];
  eventLinksPlaceholderSurfaceInStatus: boolean;
  eventTicketsLinkPlaceholderSurfaceInStatus: boolean;
  ticketLinkEntryInStatus: boolean;
  imageUploadPreviewURL?: string;
  imageToUpload?: any; // - TODO: -> What kind of data structure will the file be?
  eventStartDate: number;
  eventStartTime: number;
  eventEndDate: number;
  eventEndTime: number;
  eventType: EventType,
  artistSearchActive: boolean;
  eventArtists: Artist[];
  artistSearchTerm: string;
  availableSearchedArtists: ITypeAheadSearchItem[];
  venueSearchActive: boolean;
  eventVenues: Venue[];
  venueSearchTerm: string;
  availableSearchedVenues: ITypeAheadSearchItem[];
}

export const hydrateExternalLinkEntries = (links: EventLinks | EventTicketLinks): IPotentialExternalLinkEntry[] => {
  const hydratedLinkEntries: IPotentialExternalLinkEntry[] = [];

  Object.keys(links).map((linkKey: string) => {
    let newLinkEntry: IPotentialExternalLinkEntry = {
      linkName: BANDCAMP,
      URL: "",
      URLFieldTouched: true,
      URLFieldDirty: false,
      errorStatus: false,
      errorMessage: "Please enter a valid URL",
      microAnimationInStatus: true,
    };

    newLinkEntry.linkName = linkKey;
    newLinkEntry.URL = links[linkKey];

    hydratedLinkEntries.push(newLinkEntry);
    return "";
  });

  return hydratedLinkEntries;
}

export const determineSubmissionButtonStatus = (
  props: IAddOrEditEventFormProps,
  { isValid, dirty }: { isValid: boolean, dirty: boolean }
) => {
  const { formMode } = props;
  let buttonDisabled = false;

  if (formMode === "ADD") {
    if (!isValid || !dirty) buttonDisabled = true;
  }

  return buttonDisabled;
}

// export type EventType = "Online" | "In Person" | "Hybrid";

// -> EVENT TYPE
export const STATUS__ONLINE = "Online";
export const STATUS__IN_PERSON = "In Person";
export const STATUS__HYBRID = "Hybrid";
export const availableEventTypes = [
  STATUS__ONLINE, STATUS__IN_PERSON, STATUS__HYBRID,
];
export const STATUS__ONLINE_FR = "En ligne";
export const STATUS__IN_PERSON_FR = "En personne";
export const STATUS__HYBRID_FR = "Hybride";
export const availableEventTypes_FR = [
  STATUS__ONLINE_FR, STATUS__IN_PERSON_FR, STATUS__HYBRID_FR,
];

export const mapEventTypeToEnglishEventType =
  (eventType: string, currentLanguageCode: string): EventType => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedEventType: EventType = STATUS__IN_PERSON;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedEventType = (eventType as EventType);
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (eventType) {
      case STATUS__ONLINE_FR:
        translatedEventType = STATUS__ONLINE;
        break;
      case STATUS__IN_PERSON_FR:
        translatedEventType = STATUS__IN_PERSON;
        break;
      case STATUS__HYBRID_FR:
        translatedEventType = STATUS__HYBRID;
        break;
      default:
        translatedEventType = STATUS__ONLINE;
        break;
    }
  }

  return translatedEventType;
};

export const mapEnglishEventTypeToTargetLanguage = (eventType: EventType, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedEventType: string = STATUS__IN_PERSON;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedEventType = eventType;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (eventType) {
      case STATUS__ONLINE:
        translatedEventType = STATUS__ONLINE_FR;
        break;
      case STATUS__IN_PERSON:
        translatedEventType = STATUS__IN_PERSON_FR;
        break;
      case STATUS__HYBRID:
        translatedEventType = STATUS__HYBRID_FR;
        break;
      default:
        translatedEventType = STATUS__IN_PERSON_FR;
        break;
    }
  }

  return translatedEventType;
};

export const determineAvailableEventTypesByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableEventTypes;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableEventTypes = availableEventTypes;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableEventTypes = availableEventTypes_FR;
      break;
    default:
      computedAvailableEventTypes = availableEventTypes;
      break;
  }

  return computedAvailableEventTypes;
};
