// -> Beyond codebase
import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { css } from "aphrodite-jss";
// -> Within codebase
import FillUnderNavBarCradle from "../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import { UIContext, ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../Components/DataContextProvider/DataContextProvider";
import { IDataContextState } from "../../Components/DataContextProvider/stateManagement";
import { Artist as ArtistType } from "../../Types";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME } from "../../constants";
// -> Within component
import ArtistDetails from "./InternalComponents/ArtistDetails/ArtistDetails";
import { styleGen } from "./ArtistStyles";
import { IArtistState } from "./helpers";

const Artist: React.FC = () => {
  const [state, setState] = useState<IArtistState>({
    loadingStatus: true,
    loaderInStatus: true,
    fallbackContentInStatus: true,
  });

  const { artistID }: { artistID: string } = useParams();
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { state: { artists }}: { state: IDataContextState } = useContext(DataContext);
  const { palette } = themeInfo;
  const {
    pageCradle, noContentFallbackText, noContentFallbackTextCradle
  } = styleGen(themeInfo);

  const artistData: ArtistType | undefined = artists.find((artist: ArtistType) => artist.id === artistID);

  useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, loaderInStatus: false }));
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, loadingStatus: false }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME)
    }, 1000);
  }, []);

  const { loadingStatus, loaderInStatus, fallbackContentInStatus } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {
          (loadingStatus) ? (
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={palette.primary} />
            </Fade>
          ) : (
            <>
              {
                (artistData) ? (
                  <ArtistDetails artist={artistData} />
                ) : (
                  <Fade inStatus={fallbackContentInStatus}>
                    <div className={css(noContentFallbackTextCradle)}>
                      <p className={css(noContentFallbackText)}>
                        Something went wrong while trying to retrieve the artist's information.
                      </p>
                    </div>
                  </Fade>
                )
              }
            </>
          )
        }
      </div>
    </FillUnderNavBarCradle>
  );
}

export default Artist;
