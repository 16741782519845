export const usePromise = async (promise: Promise<any>, errorEnhancements?: any) => {
  // - TODO: -> Function feels weak. When there's problems, there's no error thrown, so nothing is caught.
  try {
    const data = await promise;
    if (!data.ok) return [undefined, data.status];
    return [data, null];
  } catch (err) {
    // if (errorEnhancements)
    //   Object.assign(err, errorEnhancements);
    return [undefined, err];
  }
}

export const usePromisev2 = async (promise: Promise<any>, errorEnhancements?: any) => {
  // - TODO: -> Function feels weak. When there's problems, there's no error thrown, so nothing is caught.
  try {
    const data = await promise;
    if (!data.ok) return [undefined, data];
    return [data, null];
  } catch (err) {
    // if (errorEnhancements)
    //   Object.assign(err, errorEnhancements);
    return [undefined, err];
  }
}
