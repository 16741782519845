// -> Beyond Codebase
import React, { useContext, useReducer, useEffect, MouseEvent, useRef, useCallback } from 'react';
// import onClickOutside from "react-onclickoutside";
// -> Within Codebase
import Fade from '../AnimationUtilities/TransitionWrappers/Fade/Fade';
import Spacer from '../LayoutUtilities/Spacer/Spacer';
import Input from '../FormUtilities/Input/Input';
import { ChevronUpIcon, ChevronDownIcon } from '../VisualUtilities/IconPresets'
import { UIContext, ThemeInfo } from '../UI_InfoProvider/UI_InfoProvider';
import {
  ROUNDED, COLOR, RIGHT, DEFAULT_TRANSITION_MICROANIMATION_TIME,
  HORIZONTAL, DEFAULT_INPUT_HEIGHT
} from '../../constants';
import { useClickOutside } from "../../helpers";
// -> Within Component
import TypeAheadSearchItem from './InternalComponents/TypeAheadSearchItem/TypeAheadSearchItem';
import { styleGen } from './TypeAheadSearchStyles';
import {
  calibrateComponent, ITypeAheadSearchProps, determineTypeAheadSearchItemGap, 
} from './helpers';
import {
  ITypeAheadSearchState, reducer, openTypeAheadSearch, closeTypeAheadSearch,
  setSearchTerm, setDropCradleInStatus, ITypeAheadSearchItem, setLoadingStatus,
  setLoadingInStatus, setResultsInStatus, setItems
} from './stateManagement'
import CircleLoader from '../Loaders/CircleLoader/CircleLoader';

// -> Written as a non-arrow function to avoid a bug caused by "react-onclickoutside"
// - TODO: -> Union type is kind of ugly, consider refactor somehow
const TypeAheadSearch: React.FC<ITypeAheadSearchProps> & { handleClickOutside?: () => any } = function(props) {
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, fonts, palette } = themeInfo;

  const {
    isOpen: isOpenFromProps, searchTerm: searchTermFromProps,
    showBaseCradleIcon, backgroundColor, geometry, colorMode, onSelectItem, searchInputPlaceholder,
    separator, customTypeAheadSearchItemCradleStyles, customTypeAheadSearchItemTextStyles,
    openIcon, closedIcon, disabled, dropdownItemHeight, showInnerCradleUnderlay,
    customTypeAheadSearchItemInnerCradleStyles, noSearchResultsAvailableMsg, noImageModeEnabled,
    handleSearchInputChange, loadingStatus: loadingStatusFromProps, items: itemsFromProps,
  } = props;

  // - DEV NOTE -> This custom hook returns a reference to assign to the DOM node within which
  //               one would like constrain click events from closing the dropdown.
  const boundaryNodeRef = useClickOutside((evt: React.MouseEvent<HTMLElement>) => onCloseTypeAheadSearch(evt));

  let loaderTimeout = useRef<any>(null);
  // let resultsTimeout = useRef<any>(null);
  let dropCradleTimeout = useRef<any>(null);

  const computedIsOpen = isOpenFromProps ? isOpenFromProps : false;
  const computedOpenIcon = openIcon ? openIcon : (
    <ChevronUpIcon
      color={themeInfo.palette.black}
      size={themeInfo.styles.standardIconSize}
    />
  );
  const computedClosedIcon = closedIcon ? closedIcon : (
    <ChevronDownIcon
      color={themeInfo.palette.black}
      size={themeInfo.styles.standardIconSize}
    />
  );
  const computedTypeAheadSearchItemGap = determineTypeAheadSearchItemGap(props, themeInfo);
  const computedOnTypeAheadSearchClick = disabled ? () => {} : (evt: React.MouseEvent<HTMLElement>) => onClickTypeAheadSearch(evt);

  // -----

  const initialTypeAheadSearchState: ITypeAheadSearchState = {
    items: [],
    isOpen: computedIsOpen,
    dropCradleInStatus: true,
    searchTerm: "",
    loadingStatus: false,
    loadingInStatus: true,
    resultsInStatus: false,
  };

  const [ state, dispatch ] = useReducer(reducer, initialTypeAheadSearchState);
  
  useEffect(() => {
    return () => {
      clearTimeout(loaderTimeout.current);
      // clearTimeout(resultsTimeout.current);
      clearTimeout(dropCradleTimeout.current);
    }
  })

  // -----

  const wasEscapePushed = useCallback((evt: any) => {
    const { isOpen } = state;
    if (evt.key === "Escape" && (isOpen)) {
      dropCradleTimeout.current = dispatch(setDropCradleInStatus(false));
      setTimeout(() => {
        dispatch(closeTypeAheadSearch());
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  }, [state]);
  
  useEffect(() => {
    document.addEventListener("keydown", (evt: any) => wasEscapePushed(evt));
    
    return () => document.removeEventListener("keydown", wasEscapePushed);
  }, [wasEscapePushed]);

  // -----

  useEffect(() => {
    (isOpenFromProps) ? dispatch(openTypeAheadSearch()) : dispatch(closeTypeAheadSearch());
  }, [isOpenFromProps]);

  // -> State of the search input being set from the parent component
  useEffect(() => {
    dispatch(setSearchTerm(searchTermFromProps));
    if (searchTermFromProps === "") {
      
      dropCradleTimeout.current = dispatch(setDropCradleInStatus(false));
      setTimeout(() => {
        dispatch(closeTypeAheadSearch());
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  }, [searchTermFromProps]);

  useEffect(() => {
    if (loadingStatusFromProps === true) {
      dispatch(setLoadingInStatus(true));
      dispatch(setResultsInStatus(false))
      loaderTimeout.current = setTimeout(() => {
        dispatch(setLoadingStatus(true));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
    else {
      dispatch(setLoadingInStatus(false));
      dispatch(setResultsInStatus(true))
      loaderTimeout.current = setTimeout(() => {
        dispatch(setLoadingStatus(false));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  }, [loadingStatusFromProps]);

  useEffect(() => {
    dispatch(setItems(itemsFromProps));
    // if (loadingStatusFromProps === true) {
    //   dispatch(setLoadingInStatus(false));
    //   dispatch(setResultsInStatus(true))
    //   loaderTimeout.current = setTimeout(() => {
    //     dispatch(setLoadingStatus(false));
    //   }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    // }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [itemsFromProps, loadingStatusFromProps]);

  // -----
  
  const onTextEntry = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOpen) dispatch(openTypeAheadSearch());
    handleSearchInputChange(evt);
  };
  
  // -----
  
  // -> When the screen is clicked from anywhere off the dropdown menu, it correspondingly closes.
  // - TODO: -> This seems to trigger for any dropdowns set up, regardless of if any of them are currently open. What gives?
  TypeAheadSearch.handleClickOutside = () => {
    // console.log('bleep bloop, onclickoutside invoked in <TypeAheadSearch />');
    dispatch(closeTypeAheadSearch());
  };


  const styles = styleGen(themeInfo, props);
  // const { noSearchResultsAvailableCradle, noSearchResultsAvailableText } = styles;
  const {
    typeAheadCradleStylechain, baseCradleStylechain, dropCradleStylechain
  } = calibrateComponent(props, themeInfo, styles);

  // -----

  // -> The base cradle is clicked, opening or closing the dropdown based on its current state.
  const onClickTypeAheadSearch = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    // console.log("evt -> ", JSON.stringify(evt, null, 1));
    const { isOpen } = state;
    const { items } = props;
    
    if (isOpen) return
    else {
      if (items.length === 0) return;
      else dispatch(openTypeAheadSearch());
    }

    // if (isOpen) {
    //   dispatch(setDropCradleInStatus(false));
    //   setTimeout(() => {
    //     dispatch(closeTypeAheadSearch());
    //   }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    // } else {
    //   dispatch(openTypeAheadSearch());
    // }
  };

  // -----

  const onCloseTypeAheadSearch = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();

    dispatch(setDropCradleInStatus(false));
    setTimeout(() => {
      dispatch(closeTypeAheadSearch());
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  // const onSingleSelectTypeAheadSearchItemClick = (isSelected: boolean, item: ITypeAheadSearchItem) => {
  //   const { closeDropCradleOnSelect, onSelectItem } = props;
  //   const { selectedItems } = state;
  //   const selectedItemsCopy = cloneDeep(selectedItems);

  //   if (onSelectItem) onSelectItem(item.label);

  //   if (isSelected) selectedItemsCopy.length = 0;
  //   if (!isSelected) {
  //     if (selectedItemsCopy.length === 0) {
  //       selectedItemsCopy.push(item);
  //     }
  //     else {
  //       selectedItemsCopy.length = 0;
  //       selectedItemsCopy.push(item);
  //     }
  //   }

  //   dispatch(setSelectedItems(selectedItemsCopy));
  //   if (closeDropCradleOnSelect) dispatch(toggleTypeAheadSearch());
  // }

  // -----

  const {
    items, searchTerm, loadingStatus, loadingInStatus,
    resultsInStatus, dropCradleInStatus, isOpen
  } = state;

  // console.log(`search items -> ${JSON.stringify(items, null, 4)}`);

  return (
    // - BASE CRADLE - //
    <div className={typeAheadCradleStylechain} ref={boundaryNodeRef}>
      <div className={baseCradleStylechain} onClick={computedOnTypeAheadSearchClick}>
        <Input
          name="eventSearchInput"
          useManualInputField
          value={searchTerm}
          onChange={onTextEntry}
          showErrorMessage={false}
          placeholder={searchInputPlaceholder ? searchInputPlaceholder : "Search by starting to type"}
          customInputTextStyles={{ fontSize: "1.4rem" }}
          customControlCradleStyles={{ width: "100%" }}
          customInputCradleStyles={{
            border: "none", paddingLeft: 0, paddingTop: 0,
            paddingRight: 0, paddingBottom: 0,
            height: DEFAULT_INPUT_HEIGHT
          }}
        />
        <Spacer direction={HORIZONTAL} amount={distance.one} />
        {
          (showBaseCradleIcon) && (
              (isOpen) ? computedOpenIcon : computedClosedIcon
          )
        }
      </div>
      {/* DROP CRADLE */}
      {
        (isOpen) && (
          // - TODO: -> Troubleshoot getting leave animation to trigger correctly.
          <Fade inStatus={dropCradleInStatus}>  
            <div className={dropCradleStylechain}>
                {
                  (!loadingStatus) ? (
                    <Fade inStatus={resultsInStatus}>
                      <>
                        {
                          (items && (items.length > 0)) ? (
                            items.map((item: ITypeAheadSearchItem, index: number) => {
                              const { id, name, imageURL } = item;
                              const computedMarginTop = (index === 0) ? 0 : (computedTypeAheadSearchItemGap / 2);
                              const computedMarginBottom = (index === (items?.length - 1)) ? 0 : (computedTypeAheadSearchItemGap / 2);
                              return (
                                <div key={id} style={{
                                  marginTop: computedMarginTop,
                                  marginBottom: computedMarginBottom,
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                  <TypeAheadSearchItem
                                    disabled={disabled}
                                    id={(id) ? id : `${index}`} // -> Impose a unique id if none is provided externally.
                                    label={name}
                                    item={item}
                                    imageURL={imageURL}
                                    onSelectItem={onSelectItem}
                                    height={dropdownItemHeight}
                                    noImageModeEnabled={noImageModeEnabled}
                                    backgroundColor={backgroundColor}
                                    colorMode={colorMode}
                                    geometry={geometry}
                                    showInnerCradleUnderlay={showInnerCradleUnderlay}
                                    customTypeAheadSearchItemCradleStyles={customTypeAheadSearchItemCradleStyles}
                                    customTypeAheadSearchItemInnerCradleStyles={customTypeAheadSearchItemInnerCradleStyles}
                                    customTypeAheadSearchItemTextStyles={customTypeAheadSearchItemTextStyles}
                                  />
                                  {
                                    (separator && (index < (items.length - 1))) && separator
                                  }
                                </div>
                              );
                            })
                          ) : (
                            // <div className={css(noSearchResultsAvailableCradle)}>
                            <div style={{
                              height: 50,
                              width: "100%",
                              display: "grid",
                              placeItems: "center",
                            }}>
                              {/* <p className={css(noSearchResultsAvailableText)}>{noSearchResultsAvailableMsg ? noSearchResultsAvailableMsg : "No results available from search query"}</p> */}
                              <p style={{
                                fontFamily: fonts.primary,
                                fontSize: '1.4rem',
                                fontStyle: "italic",
                                color: palette.grey3,
                                textAlign: 'center',
                              }}>
                                {noSearchResultsAvailableMsg ? noSearchResultsAvailableMsg : "No results available from search query"}
                              </p>
                            </div>
                            // <TypeAheadSearchItem
                            //   id={EMPTY_LIST_ITEM.id}
                            //   label={EMPTY_LIST_ITEM.label}
                            //   item={EMPTY_LIST_ITEM}
                            //   disabled={disabled}
                            //   customTypeAheadSearchItemTextStyles={customTypeAheadSearchItemTextStyles}
                            // />
                          )
                        }
                      </>
                    </Fade>
                  ) : (
                    <div style={{
                      height: 50,
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}>
                      <Fade inStatus={loadingInStatus}>
                        <CircleLoader spinnerColor={palette.primary} size={25} spinnerTrackWidth={1} />
                      </Fade>
                    </div>
                  )
                }
            </div>
          </Fade>
        )
      }
    </div>
  );
}

TypeAheadSearch.defaultProps = {
  isOpen: false,
  disabled: false,
  closeDropCradleOnSelect: true,
  showBaseCradleIcon: true,
  raised: false,
  geometry: ROUNDED,
  colorMode: COLOR,
  baseCradleHeight: 30,
  baseCradleWidth: 120,
  dropCradleGap: 0,
  dropCradleAlignment: RIGHT,
  showInnerCradleUnderlay: true,
  maxDropCradleHeight: 300,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const clickOutsideConfig = {
  handleClickOutside: () => TypeAheadSearch.handleClickOutside
};

export default TypeAheadSearch;
// export default onClickOutside(TypeAheadSearch, clickOutsideConfig);
