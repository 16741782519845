import { StyleSheet } from "aphrodite";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { DEFAULT_CARD_WIDTH, DEFAULT_CARD_HEIGHT } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo) => (StyleSheet.create({
  cardCradle: {
    height: DEFAULT_CARD_HEIGHT,
    width: DEFAULT_CARD_WIDTH,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: themeInfo.shadows.one,
    borderRadius: themeInfo.borderRadii.one,
    backgroundColor: themeInfo.palette.white,
  },
  topBarCradle: {
    height: "auto",
    width: "100%",
    paddingLeft: themeInfo.distance.three,
    paddingRight: themeInfo.distance.three,
    paddingTop: themeInfo.distance.three,
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    backgroundColor: themeInfo.palette.white,
    borderTopLeftRadius: themeInfo.borderRadii.one,
    borderTopRightRadius: themeInfo.borderRadii.one,
    textAlign: "center",
  },
  promptText: {
    fontFamily: themeInfo.fonts.primary,
    fontSize: "1.5rem",
    fontStyle: "italic",
    color: themeInfo.palette.grey3,
    marginBottom: 0,
  },
  cardBottomCradle: {
    height: '100%',
    width: '100%',
    padding: themeInfo.distance.three,
    display: 'flex',
    flex: 1,
  },
  formCradle: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));