import Fuse, { FuseOptions } from "fuse.js";
import cloneDeep from "lodash.clonedeep";
import { Venue } from "../../Types";
import { mapLanguageCodeToLanguage } from "../../helpers";
import { VenuesState } from "./stateManagement";
import {
  FILTER_CONDITION__EQUAL_TO, FILTER_CONDITION__NOT_EQUAL_TO, FILTER_CONDITION__GREATER_THAN,
  FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO, FILTER_CONDITION__LESS_THAN,
  FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO, FILTER_CONDITION__INCLUDED_IN,
  FILTER_CONDITION__NOT_INCLUDED_IN, FILTER_CONDITION__NONE, LANGUAGE__ENGLISH, LANGUAGE__FRENCH,
} from "../../constants";

export interface VenuesProps {
  customPageCradleStyles?: any;
}

// -----

// -> SORT CRITERIA
export const SORT_CRITERIA__NONE = "None";
export const SORT_CRITERIA__NAME = "Name";
export const SORT_CRITERIA__CITY = "City";
export const SORT_CRITERIA__COUNTRY = "Country";
export const availableSortCriteria = [
  SORT_CRITERIA__NONE, SORT_CRITERIA__NAME, SORT_CRITERIA__CITY,
  SORT_CRITERIA__COUNTRY
];
export const SORT_CRITERIA__NONE_FR = "Aucun";
export const SORT_CRITERIA__NAME_FR = "Nom";
export const SORT_CRITERIA__CITY_FR = "Ville";
export const SORT_CRITERIA__COUNTRY_FR = "Pays";
export const availableSortCriteria_FR = [
  SORT_CRITERIA__NONE_FR, SORT_CRITERIA__NAME_FR, SORT_CRITERIA__CITY_FR,
  SORT_CRITERIA__COUNTRY_FR
];

// -> SORT DIRECTIONS
export const SORT_DIRECTION__ASCENDING = "Ascending";
export const SORT_DIRECTION__DESCENDING = "Descending";
export const availableSortDirections = [SORT_DIRECTION__ASCENDING, SORT_DIRECTION__DESCENDING];
export const SORT_DIRECTION__ASCENDING_FR = "Croissant";
export const SORT_DIRECTION__DESCENDING_FR = "Décroissant";
export const availableSortDirections_FR = [SORT_DIRECTION__ASCENDING_FR, SORT_DIRECTION__DESCENDING_FR];

// -> FILTER CRITERIA
export const FILTER_CRITERIA__NONE = "None";
export const FILTER_CRITERIA__NAME = "Name";
export const FILTER_CRITERIA__CITY = "City";
export const FILTER_CRITERIA__COUNTRY = "Country";
export const availableFilterCriteria = [
  FILTER_CRITERIA__NONE, FILTER_CRITERIA__NAME, FILTER_CRITERIA__CITY,
  FILTER_CRITERIA__COUNTRY
];
export const FILTER_CRITERIA__NONE_FR = "Aucun";
export const FILTER_CRITERIA__NAME_FR = "Nom";
export const FILTER_CRITERIA__CITY_FR = "Ville";
export const FILTER_CRITERIA__COUNTRY_FR = "Pays";
export const availableFilterCriteria_FR = [
  FILTER_CRITERIA__NONE_FR, FILTER_CRITERIA__NAME_FR, FILTER_CRITERIA__CITY_FR,
  FILTER_CRITERIA__COUNTRY_FR
];

// -> FILTER CONDITIONS
export const availableFilterConditions = [
  FILTER_CONDITION__NONE, FILTER_CONDITION__EQUAL_TO, FILTER_CONDITION__NOT_EQUAL_TO, FILTER_CONDITION__GREATER_THAN,
  FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO, FILTER_CONDITION__LESS_THAN,
  FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO, FILTER_CONDITION__INCLUDED_IN, FILTER_CONDITION__NOT_INCLUDED_IN
];

// -> FILTER CONDITION TYPES
export const FILTER_CONDITION_TYPE__LIST = "List";
export const FILTER_CONDITION_TYPE__RANGE = "Range";
export const availableFilterConditionTypes = [FILTER_CONDITION_TYPE__LIST, FILTER_CONDITION_TYPE__RANGE];
export const FILTER_CONDITION_TYPE__LIST_FR = "Liste";
export const FILTER_CONDITION_TYPE__RANGE_FR = "Intervalle";
export const availableFilterConditionTypes_FR = [FILTER_CONDITION_TYPE__LIST_FR, FILTER_CONDITION_TYPE__RANGE_FR];

// ----- VENUE SORTING ----- //

export const sortVenues = (
  state: VenuesState,
  options?: { venuesToSortParam?: Venue[] }
): Venue[] => {
  const stateCopy = cloneDeep(state);
  const { currentSortCriteria, currentSortDirection, venues } = stateCopy;
  const venuesToSort = (!options?.venuesToSortParam) ? venues : options.venuesToSortParam;
  let sortedVenues: Venue[] = [];

  if (currentSortCriteria === SORT_CRITERIA__NAME) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedVenues = venuesToSort.sort(compareVenueNamesAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedVenues = venuesToSort.sort(compareVenueNamesDesc);
    }
  } else if (currentSortCriteria === SORT_CRITERIA__CITY) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedVenues = venuesToSort.sort(compareVenueCitiesAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedVenues = venuesToSort.sort(compareVenueCitiesDesc);
    }
  } else if (currentSortCriteria === SORT_CRITERIA__COUNTRY) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedVenues = venuesToSort.sort(compareVenueCountriesAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedVenues = venuesToSort.sort(compareVenueCountriesDesc);
    }
  }

  return sortedVenues;
};

const compareVenueNamesAsc = (venue1: Venue, venue2: Venue): number => {
  return venue1.name.toLowerCase().localeCompare(venue2.name.toLowerCase());
};

const compareVenueNamesDesc = (venue1: Venue, venue2: Venue): number => {
  return venue2.name.toLowerCase().localeCompare(venue1.name.toLowerCase());
};

const compareVenueCitiesAsc = (venue1: Venue, venue2: Venue): number => {
  return venue1.city.toLowerCase().localeCompare(venue2.city.toLowerCase());
};

const compareVenueCitiesDesc = (venue1: Venue, venue2: Venue): number => {
  return venue2.city.toLowerCase().localeCompare(venue1.city.toLowerCase());
};

const compareVenueCountriesAsc = (venue1: Venue, venue2: Venue): number => {
  return venue1.country.toLowerCase().localeCompare(venue2.country.toLowerCase());
};

const compareVenueCountriesDesc = (venue1: Venue, venue2: Venue): number => {
  return venue2.country.toLowerCase().localeCompare(venue1.country.toLowerCase());
};

// ----- VENUE FILTERING ----- //

export const filterVenues = (
  state: VenuesState,
  options?: { venuesToFilterParam?: Venue[] }
): Venue[] => {
  const stateCopy = cloneDeep(state);
  const {
    currentFilterCriteria, currentFilterCondition, currentFilterConditionType,
    currentFilterArgument, venues,
  } = stateCopy;
  const venuesToFilter = (!options?.venuesToFilterParam) ? venues : options.venuesToFilterParam;
  let filteredVenues: Venue[] = [];

  // -> Filtering by venue name
  if (currentFilterCriteria === FILTER_CRITERIA__NAME) {
    if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.name.toLowerCase().trim()) === 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.name.toLowerCase().trim()) !== 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.name.toLowerCase().trim()) === -1);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.name.toLowerCase().trim()) <= 0);
      });
    }
    
    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.name.toLowerCase().trim()) > 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.name.toLowerCase().trim()) >= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return processedFilterArgument.includes(venue.name.toLowerCase().trim());
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return (
            (minElement.localeCompare(venue.name.toLowerCase().trim()) <= 0) &&
            (maxElement.localeCompare(venue.name.toLowerCase().trim()) >= 0)
          );
        });
      }
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return (processedFilterArgument.includes(venue.name.toLowerCase().trim()) === false);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return (
            (minElement.localeCompare(venue.name.toLowerCase().trim()) > 0) ||
            (maxElement.localeCompare(venue.name.toLowerCase().trim()) < 0)
          );
        });
      }
    }
  }

  // -> Filtering by venue city
  else if (currentFilterCriteria === FILTER_CRITERIA__CITY) {
    if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.city.toLowerCase().trim()) === 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.city.toLowerCase().trim()) !== 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.city.toLowerCase().trim()) === -1);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.city.toLowerCase().trim()) <= 0);
      });
    }
    
    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.city.toLowerCase().trim()) > 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.city.toLowerCase().trim()) >= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return processedFilterArgument.includes(venue.city.toLowerCase().trim());
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return (
            (minElement.localeCompare(venue.city.toLowerCase().trim()) <= 0) &&
            (maxElement.localeCompare(venue.city.toLowerCase().trim()) >= 0)
          );
        });
      }
    }
    
    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return (processedFilterArgument.includes(venue.city.toLowerCase().trim()) === false);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return (
            (minElement.localeCompare(venue.city.toLowerCase().trim()) > 0) ||
            (maxElement.localeCompare(venue.city.toLowerCase().trim()) < 0)
          );
        });
      }
    }
  }

  // -> Filtering by venue country
  else if (currentFilterCriteria === FILTER_CRITERIA__COUNTRY) {
    if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.country.toLowerCase().trim()) === 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.country.toLowerCase().trim()) !== 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.country.toLowerCase().trim()) === -1);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.country.toLowerCase().trim()) <= 0);
      });
    }
    
    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.country.toLowerCase().trim()) > 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredVenues = venuesToFilter.filter((venue: Venue) => {
        return (processedFilterArgument.localeCompare(venue.country.toLowerCase().trim()) >= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());
        
        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return processedFilterArgument.includes(venue.country.toLowerCase().trim());
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return (
            (minElement.localeCompare(venue.country.toLowerCase().trim()) <= 0) &&
            (maxElement.localeCompare(venue.country.toLowerCase().trim()) >= 0)
          );
        });
      }
    }
    
    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return (processedFilterArgument.includes(venue.country.toLowerCase().trim()) === false);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredVenues = venuesToFilter.filter((venue: Venue) => {
          return (
            (minElement.localeCompare(venue.country.toLowerCase().trim()) > 0) ||
            (maxElement.localeCompare(venue.country.toLowerCase().trim()) < 0)
          );
        });
      }
    }
  }

  return filteredVenues;
};

// ----- VENUE SEARCH ----- //

export const searchVenues = (
  searchQuery: string,
  state: VenuesState,
  options?: { venuesToSearchParam?: Venue[] }
): Venue[] => {
  const { venues, sortingActive } = state;
  const venuesToSearch = (!options?.venuesToSearchParam) ? venues : options.venuesToSearchParam;
  let venueSearchResults: any[] = [];

  if (searchQuery === "") return venues;

  const searchEngineOptions: FuseOptions<Venue> = {
    keys: ["name"],
    shouldSort: sortingActive ? false : true, // -> Results sorted by relevance score unless manual sorting is enabled
    findAllMatches: true,
    threshold: 0.4, // -> This is the default value, it can be tweaked from 0 to 1
  };

  const fuse = new Fuse(venuesToSearch, searchEngineOptions);

  venueSearchResults = fuse.search(searchQuery);

  return venueSearchResults;
};

// ----- VENUE PAGINATION ----- //

export const selectPaginatedVenues = (
  state: VenuesState,
  options?: {
    venuesToPaginateParam?: Venue[],
    pageNumber?: number
  }
): Venue[] => {
  const { itemsPerPage, currentPageNumber, venues } = state;
  const pageNumberToUse = (!options?.pageNumber) ? currentPageNumber : options.pageNumber;
  const artistsToPaginate = (!options?.venuesToPaginateParam) ? venues : options.venuesToPaginateParam;
  let displayedVenues: Venue[] = [];

  const indexOfLastVenue = pageNumberToUse * itemsPerPage;
  const indexOfFirstVenue = indexOfLastVenue - itemsPerPage;
  displayedVenues = artistsToPaginate.slice(indexOfFirstVenue, indexOfLastVenue);

  return displayedVenues;
};

// ----- LANGUAGE PROCESSING ----- //

// ----- Sort Criteria ----- //

export const mapSortCriteriaToEnglishSortCriteria =
  (sortCriteria: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedSortCriteria: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedSortCriteria = sortCriteria;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (sortCriteria) {
      case SORT_CRITERIA__NONE_FR:
        translatedSortCriteria = SORT_CRITERIA__NONE;
        break;
      case SORT_CRITERIA__NAME_FR:
        translatedSortCriteria = SORT_CRITERIA__NAME;
        break;
      case SORT_CRITERIA__CITY_FR:
        translatedSortCriteria = SORT_CRITERIA__CITY;
        break;
      case SORT_CRITERIA__COUNTRY_FR:
        translatedSortCriteria = SORT_CRITERIA__COUNTRY;
        break;
      default:
        translatedSortCriteria = SORT_CRITERIA__NONE;
        break;
    }
  }

  return translatedSortCriteria;
};

// -----

export const mapEnglishSortCriteriaToTargetLanguage = (sortCriteria: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedSortCriteria: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedSortCriteria = sortCriteria;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (sortCriteria) {
      case SORT_CRITERIA__NONE:
        translatedSortCriteria = SORT_CRITERIA__NONE_FR;
        break;
      case SORT_CRITERIA__NAME:
        translatedSortCriteria = SORT_CRITERIA__NAME_FR;
        break;
      case SORT_CRITERIA__CITY:
        translatedSortCriteria = SORT_CRITERIA__CITY_FR;
        break;
      case SORT_CRITERIA__COUNTRY:
        translatedSortCriteria = SORT_CRITERIA__COUNTRY_FR;
        break;
      default:
        translatedSortCriteria = SORT_CRITERIA__NONE;
        break;
    }
  }

  return translatedSortCriteria;
};

// ----- Sort Direction ----- //

export const mapSortDirectionToEnglishSortDirection =
  (sortDirection: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedSortDirection: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedSortDirection = sortDirection;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (sortDirection) {
      case SORT_DIRECTION__ASCENDING_FR:
        translatedSortDirection = SORT_DIRECTION__ASCENDING;
        break;
      case SORT_DIRECTION__DESCENDING_FR:
        translatedSortDirection = SORT_DIRECTION__DESCENDING;
        break;
      default:
        translatedSortDirection = SORT_DIRECTION__ASCENDING;
        break;
    }
  }

  return translatedSortDirection;
};

// -----

export const mapEnglishSortDirectionToTargetLanguage = (sortDirection: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedSortDirection: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedSortDirection = sortDirection;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (sortDirection) {
      case SORT_DIRECTION__ASCENDING:
        translatedSortDirection = SORT_DIRECTION__ASCENDING_FR;
        break;
      case SORT_DIRECTION__DESCENDING:
        translatedSortDirection = SORT_DIRECTION__DESCENDING_FR;
        break;
      default:
        translatedSortDirection = SORT_DIRECTION__ASCENDING;
        break;
    }
  }

  return translatedSortDirection;
};

// ----- Filter Criteria ----- //

export const mapFilterCriteriaToEnglishFilterCriteria =
  (filterCriteria: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedFilterCriteria: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedFilterCriteria = filterCriteria;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (filterCriteria) {
      case FILTER_CRITERIA__NONE_FR:
        translatedFilterCriteria = FILTER_CRITERIA__NONE;
        break;
      case FILTER_CRITERIA__NAME_FR:
        translatedFilterCriteria = FILTER_CRITERIA__NAME;
        break;
      case FILTER_CRITERIA__CITY_FR:
        translatedFilterCriteria = FILTER_CRITERIA__CITY;
        break;
      case FILTER_CRITERIA__COUNTRY_FR:
        translatedFilterCriteria = FILTER_CRITERIA__COUNTRY;
        break;
      default:
        translatedFilterCriteria = FILTER_CRITERIA__NONE;
        break;
    }
  }

  return translatedFilterCriteria;
};

// -----

export const mapEnglishFilterCriteriaToTargetLanguage = (filterCriteria: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedFilterCriteria: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedFilterCriteria = filterCriteria;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (filterCriteria) {
      case FILTER_CRITERIA__NONE:
        translatedFilterCriteria = FILTER_CRITERIA__NONE_FR;
        break;
      case FILTER_CRITERIA__NAME:
        translatedFilterCriteria = FILTER_CRITERIA__NAME_FR;
        break;
      case FILTER_CRITERIA__CITY:
        translatedFilterCriteria = FILTER_CRITERIA__CITY_FR;
        break;
      case FILTER_CRITERIA__COUNTRY:
        translatedFilterCriteria = FILTER_CRITERIA__COUNTRY_FR;
        break;
      default:
        translatedFilterCriteria = FILTER_CRITERIA__NONE;
        break;
    }
  }

  return translatedFilterCriteria;
};

// ----- Filter Condition Type ----- //

export const mapFilterConditionTypeToEnglishFilterConditionType = (filterConditionType: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedFilterConditionType: string = FILTER_CONDITION_TYPE__LIST;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedFilterConditionType = filterConditionType;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (filterConditionType) {
      case FILTER_CONDITION_TYPE__LIST:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST_FR;
        break;
      case FILTER_CONDITION_TYPE__RANGE:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__RANGE_FR;
        break;
      default:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST_FR;
        break;
    }
  }

  return translatedFilterConditionType;
};

// -----

export const mapEnglishFilterConditionTypeToTargetLanguage = (filterConditionType: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedFilterConditionType: string = FILTER_CONDITION_TYPE__LIST;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedFilterConditionType = filterConditionType;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (filterConditionType) {
      case FILTER_CONDITION_TYPE__LIST_FR:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST;
        break;
      case FILTER_CONDITION_TYPE__RANGE_FR:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__RANGE;
        break;
      default:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST;
        break;
    }
  }

  return translatedFilterConditionType;
};

// ----- Available Filter Criteria ----- //

export const determineAvailableFilterCriteriaByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableFilterCriteria;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableFilterCriteria = availableFilterCriteria;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableFilterCriteria = availableFilterCriteria_FR;
      break;
    default:
      computedAvailableFilterCriteria = availableFilterCriteria;
      break;
  }

  return computedAvailableFilterCriteria;
};

// ----- Available Filter Condition Types ----- //

export const determineAvailableFilterConditionTypesByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableFilterConditionType;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableFilterConditionType = availableFilterConditionTypes;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableFilterConditionType = availableFilterConditionTypes_FR;
      break;
    default:
      computedAvailableFilterConditionType = availableFilterConditionTypes;
      break;
  }

  return computedAvailableFilterConditionType;
};

// ----- Available Sort Criteria ----- //

export const determineAvailableSortCriteriaByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableSortCriteria;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableSortCriteria = availableSortCriteria;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableSortCriteria = availableSortCriteria_FR;
      break;
    default:
      computedAvailableSortCriteria = availableSortCriteria;
      break;
  }

  return computedAvailableSortCriteria;
};

// ----- Available Sort Directions ----- //

export const determineAvailableSortDirectionsByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableSortDirections;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableSortDirections = availableSortDirections;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableSortDirections = availableSortDirections_FR;
      break;
    default:
      computedAvailableSortDirections = availableSortDirections;
      break;
  }

  return computedAvailableSortDirections;
};
