import cloneDeep from "lodash.clonedeep";
import { Venue, IReducerAction, Event, Artist } from "../../Types";
import { venues, events, artists } from "../../staticData";

export interface IDataContextState {
  venues: Venue[];
  events: Event[];
  artists: Artist[];
}

export const initDataContextState: IDataContextState = {
  venues: venues, events, artists,
};

// - Action Strings - //
// -> General
const SET_VENUES_ACTION = "SET_VENUES_ACTION";
const SET_EVENTS_ACTION = "SET_EVENTS_ACTION";
const SET_ARTISTS_ACTION = "SET_ARTISTS_ACTION";

export const setVenues = (venues: Venue[]) => ({
  type: SET_VENUES_ACTION,
  payload: venues,
});

export const setEvents = (events: Event[]) => ({
  type: SET_EVENTS_ACTION,
  payload: events
});

export const setArtists = (artists: Artist[]) => ({
  type: SET_ARTISTS_ACTION,
  payload: artists,
});

// - State Reducer - //
export const reducer = (state: IDataContextState, action: IReducerAction): IDataContextState => {
  const stateCopy = cloneDeep(state);

  switch (action.type) {
    case SET_VENUES_ACTION:
      return { ...stateCopy, venues: action.payload };
    case SET_EVENTS_ACTION:
      return { ...stateCopy, events: action.payload };
    case SET_ARTISTS_ACTION:
      return { ...stateCopy, events: action.payload };
    default: return stateCopy;
  }
};
