import { StyleSheet } from 'aphrodite-jss';
import { ThemeInfo } from '../../../../Components/UI_InfoProvider/UI_InfoProvider';

export const styleGen = (themeInfo: ThemeInfo) => {
  const { fonts, palette, distance, borderRadii } = themeInfo;
  
  return StyleSheet.create({
    panelCradle: {
      height: "auto",
      maxHeight: 550,
      width: 225,
      display: 'flex',
      flexDirection: 'column',
      overflow: "hidden scroll",
    },
    filterPanelTitleCradle: {
      padding: distance.one,
      borderRadius: borderRadii.one,
      background: palette.grey1,
      width: "min-content",
      "whiteSpace": "nowrap",
    },
    filterPanelTitleText: {
      fontFamily: fonts.primary,
      fontSize: "1.8rem",
      color: palette.black,
      fontWeight: "bold",
    },
    selectionDropdownLabel: {
      fontFamily: fonts.primary,
      fontSize: "1.2rem",
      color: palette.black,
    },
    multipleFiltArgsHeaderRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      alignItems: "center"
    },
    filterArgEntryRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    rangeInputRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    bottomRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    bottomRightBtns: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    }
  })
}