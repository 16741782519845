// -> Beyond codebase
import React, { useContext, useMemo } from "react";
import { css } from "aphrodite-jss";
import format from 'date-fns/format'
// -> Within codebase
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import DiscoBallIcon from "../../VisualUtilities/IconPresets/DiscoBallIcon";
import { UIContext } from "../../UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL, ROUNDED, VERTICAL } from "../../../constants";
import { computeItemNumberString } from "../../../helpers";
// -> Within component
import { styleGen } from "./EventListItemStyles";
import { EventListItemProps, DEFAULT_IMAGE_SIZE } from "./helpers";

const EventListItem: React.FC<EventListItemProps> = (props) => {
  const { name, imageURL, date, onClick, itemNumber } = props;
  const { themeInfo } = useContext(UIContext);
  const { distance, palette, borderRadii } = themeInfo;
  const {
    listItemCradle, itemNumberTextCradle, itemNumberText, eventImageCradle,
    fallbackIconCradle, rightRowContentCradle, eventNameText, customEventNameTextStyles,
    customCradleStyles, eventDateText
  } = styleGen(props, themeInfo);

  const humanReadableDate = useMemo(() => format(date, "EEEE, LLLL do, yyyy").toUpperCase(), [date])

  return (
    <div className={css(listItemCradle, customCradleStyles)} onClick={onClick}>
      {
        (itemNumber) ? (
          <>
            <div className={css(itemNumberTextCradle)}>
              <p className={css(itemNumberText)}>{computeItemNumberString(itemNumber)}</p>
            </div>
            <Spacer direction={HORIZONTAL} amount={distance.three} />
          </>
        ) : null
      }

      <div className={css(eventImageCradle)}>
        {
          (imageURL) ? (
            <ImageWrapper
              alt={`Event: ${name}`} src={imageURL}
              borderGeometry={ROUNDED}
              height={DEFAULT_IMAGE_SIZE} width={DEFAULT_IMAGE_SIZE}
              customImgStyles={{ borderRadius: borderRadii.two }}
              customImgCradleStyles={{ borderRadius: borderRadii.two }}
            />
          ) : (
            <div className={css(fallbackIconCradle)}>
              <DiscoBallIcon color={palette.grey2} size={DEFAULT_IMAGE_SIZE - 20} />
            </div>
          )
        }
      </div>

      <Spacer direction={HORIZONTAL} amount={distance.three} />

      <div className={css(rightRowContentCradle)}>
        <p className={css(eventNameText, customEventNameTextStyles)}>{name}</p>
        <Spacer direction={VERTICAL} amount={distance.one} />
        <p className={css(eventDateText)}>{humanReadableDate}</p>
      </div>
    </div>
  );
}

export default EventListItem;
