import * as React from "react";
import { IIconProps } from "../../../Types";

const SongIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...Object.assign({}, {...props}, {...interpretedProps})}
    >
      <title>Song</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect style={{ fill: "none" }} width="25" height="25"/>
          <path d="M15.61,2.73,8.2,4.62s0,2.79,0,6.2V17l-.28-.21a4.72,4.72,0,0,0-1.16-.6,4.27,4.27,0,0,0-2.48,0,4.12,4.12,0,0,0-2.74,2.87,3,3,0,0,0-.1,1.05,2.85,2.85,0,0,0,.12,1.07,4.11,4.11,0,0,0,2.93,2.86,4.65,4.65,0,0,0,1.93,0,4.18,4.18,0,0,0,2.79-2.28,5.16,5.16,0,0,0,.23-.63l.11-.33V15.49c0-2.93,0-5.34.05-5.36S21.64,7,21.71,7s0,1.51,0,3.35v3.35l-.23-.18a4,4,0,0,0-1.21-.64,4.07,4.07,0,0,0-5.16,2.63,4.19,4.19,0,0,0,0,2.48,4.11,4.11,0,0,0,3.14,2.78,5.16,5.16,0,0,0,1.57,0,4.16,4.16,0,0,0,3.14-3l.1-.36V9.14c0-4.57,0-8.3,0-8.3Z"/>
        </g>
      </g>
    </svg>
  );
}

export default SongIcon;

SongIcon.defaultProps = {
  color: "black"
}