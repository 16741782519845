import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../UI_InfoProvider/UI_InfoProvider";
import { IModalProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IModalProps) => {
  const { customCradleStyles } = props;
  const { stackingIndexes } = themeInfo;

  return StyleSheet.create({
    modalCradle: {
      position: "fixed",
      top: "50%", left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      zIndex: stackingIndexes.modal,
    },
    customCradleStyles: { ...customCradleStyles },
  });
};
