import { Artist, Event } from "../../../../Types";

export const DEFAULT_IMAGE_SIZE = 185;
export const HORIZONTAL_BREAKPOINT_750 = 750;
export const HORIZONTAL_BREAKPOINT_550 = 550;
export interface IArtistDetailsProps {
  artist: Artist;
  customComponentCradleStyles?: any;
  customArtistNameTextStyles?: any;
  actionHistoryCardMode?: boolean;
}

export interface IArtistDetailsState {
  artistEvents?: Event[];
  artistEventsLoadingStatus: boolean;
  artistEventsLoaderInStatus: boolean;
  hasMultipleExternalLinks: boolean;
  mainContentInStatus: boolean;
  eventsScrollBegan: boolean;
  eventsScrollActive: boolean;
}
