export interface VenueCardProps {
  name: string;
  imageURL?: string;
  city: string;
  country: string;
  onClick(...params: any[]): any;
  customCardCradleStyles?: any;
}

export const BASE_IMAGE_SIZE = "90%";
export const BASE_CARD_HEIGHT = 290;
export const BASE_CARD_WIDTH = 225;
export const CARD_ASPECT_RATIO = (BASE_CARD_WIDTH / BASE_CARD_HEIGHT);
export const CARD_ASPECT_RATIO_INVERSE = (BASE_CARD_HEIGHT / BASE_CARD_WIDTH);