// -> Beyond codebase
import React, { useContext, useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Spacer from "src/Components/LayoutUtilities/Spacer/Spacer";
import IconButton from "src/Components/Button/Variants/IconButton/IconButton";
import { AvatarImage } from "src/Components/AvatarImage/AvatarImage";
import { GearIcon, ClipboardIcon } from "src/Components/VisualUtilities/IconPresets";
import CalendarIcon from "src/Components/VisualUtilities/IconPresets/CalendarIcon";
import DiscoBallIcon from "src/Components/VisualUtilities/IconPresets/DiscoBallIcon";
import PersonIcon from "src/Components/VisualUtilities/IconPresets/PersonIcon";
import MicrophoneIcon from "src/Components/VisualUtilities/IconPresets/MicrophoneIcon";
import ExitIcon from "src/Components/VisualUtilities/IconPresets/ExitIcon";
import { ThemeInfo, UIContext } from "../../../UI_InfoProvider/UI_InfoProvider";
import { AuthContext } from "src/Components/AuthContextProvider/AuthContextProvider";
import { determineScreenDimensions, underConstructionAlert } from "src/helpers";
import {
  ADMIN_DASHBOARD_ROUTE, ARTISTS_ROUTE, EVENTS_ROUTE, HORIZONTAL, LOGIN_ROUTE,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PROFILE_ROUTE, ROUND, SETTINGS_ROUTE, TEMP_AVATAR_URL, VENUES_ROUTE, VERTICAL
} from "src/constants";
// -> Within component
import { styleGen } from "./DrawerContentStyles";
import { IDrawerContentProps, IDrawerContentState, calibrateComponent } from "./helpers";

const DrawerContent: React.FC<IDrawerContentProps> = (props) => {
  const [state, setState] = useState<IDrawerContentState>({
    screenWidth: 0,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { closeDrawer, closeBackdrop, backdropVisible } = props;
  const history = useHistory();
  const { logout } = useContext(AuthContext);
  const { t } = useTranslation("component_DrawerMenuContent");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const styles = styleGen(themeInfo);
  const { distance, palette } = themeInfo;
  const {
    contentCradle, menuHeader, settingsButtonCradle, mainContentCradle, userNameText,
    itemRow, itemRowText,
  } = styleGen(themeInfo);

  // -----
  
  useLayoutEffect(() => {
    const { width } = determineScreenDimensions();
    setState((prevState) => ({ ...prevState, screenWidth: width }));

    const handleResize = () => {
      const { width } = determineScreenDimensions();
      setState((prevState) => ({ ...prevState, screenWidth: width }));
    }
    
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  // -----

  
  const onNavigateToProfile = () => {
    // history.push(PROFILE_ROUTE)
    closeDrawer();
    closeBackdrop();
    underConstructionAlert();
  };
  
  const onNavigateToSettings = () => {
    // history.push(SETTINGS_ROUTE);
    closeDrawer();
    closeBackdrop();
    underConstructionAlert();
  }

  const onNavigateToVenues = () => {
    history.push(VENUES_ROUTE);
    closeDrawer();
    closeBackdrop();
  };

  const onNavigateToEvents = () => {
    history.push(EVENTS_ROUTE);
    closeDrawer();
    closeBackdrop();
  };

  const onNavigateToArtists = () => {
    history.push(ARTISTS_ROUTE);
    closeDrawer();
    closeBackdrop();
  };

  const onNavigateToAdminDashboard = () => {
    history.push(ADMIN_DASHBOARD_ROUTE);
    closeDrawer();
    closeBackdrop();
  };

  const onLogout = () => {
    logout();
    history.push(LOGIN_ROUTE);
    closeDrawer();
    closeBackdrop();
  };

  // -----
  
  const {
    computedAvatarImageSize, artistsOptionStyleChain, artistsOptionTextStyleChain, artistsOptionIconColor,
    eventsOptionStyleChain, eventsOptionTextStyleChain, eventsOptionIconColor, venuesOptionStyleChain,
    profileOptionStyleChain, profileOptionTextStyleChain, profileOptionIconColor, venuesOptionTextStyleChain,
    venuesOptionIconColor, adminDashboardOptionStyleChain, adminDashboardOptionTextStyleChain, adminDashboardOptionIconColor
  } = calibrateComponent(state, styles, themeInfo);


  return (
    <div className={css(contentCradle)}>
      <div className={css(menuHeader)}>
        <div className={css(settingsButtonCradle)}>
          <IconButton
            onClick={onNavigateToSettings}
            icon={<GearIcon color={palette.white} size={styles.standardIconSize} />}
          />
        </div>
        <div style={{ width: computedAvatarImageSize, height: computedAvatarImageSize }}>
          <AvatarImage
            avatarURL={TEMP_AVATAR_URL}
            avatarGeometry={ROUND}
            size={computedAvatarImageSize}
          />
        </div>
        <Spacer direction={HORIZONTAL} amount={distance.three} />
        <p className={css(userNameText)}>Placeholder Username</p>
      </div>

      <Spacer direction={VERTICAL} amount={distance.three} />

      <div className={css(mainContentCradle)}>
        <div className={profileOptionStyleChain} onClick={onNavigateToProfile}>
          <PersonIcon color={profileOptionIconColor} size={25} />
          <Spacer direction={HORIZONTAL} amount={distance.three} />
          <p className={profileOptionTextStyleChain}>{t("option_profile")}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.three} />

        <div className={eventsOptionStyleChain} onClick={onNavigateToEvents}>
          <CalendarIcon color={eventsOptionIconColor} size={25} />
          <Spacer direction={HORIZONTAL} amount={distance.three} />
          <p className={eventsOptionTextStyleChain}>{t("option_events")}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.three} />

        <div className={venuesOptionStyleChain} onClick={onNavigateToVenues}>
          <DiscoBallIcon color={venuesOptionIconColor} size={25} />
          <Spacer direction={HORIZONTAL} amount={distance.three} />
          <p className={venuesOptionTextStyleChain}>{t("option_venues")}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.three} />

        <div className={artistsOptionStyleChain} onClick={onNavigateToArtists}>
          <MicrophoneIcon color={artistsOptionIconColor} size={25} />
          <Spacer direction={HORIZONTAL} amount={distance.three} />
          <p className={artistsOptionTextStyleChain}>{t("option_artists")}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.three} />

        <div className={adminDashboardOptionStyleChain} onClick={onNavigateToAdminDashboard}>
          <ClipboardIcon color={adminDashboardOptionIconColor} size={25} />
          <Spacer direction={HORIZONTAL} amount={distance.three} />
          <p className={adminDashboardOptionTextStyleChain}>{t("option_adminDashboard")}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.three} />

        <div className={css(itemRow)} onClick={onLogout}>
          <ExitIcon color={palette.primary} size={25} /> 
          <Spacer direction={HORIZONTAL} amount={distance.three} />
          <p className={css(itemRowText)}>{t("option_logOut")}</p>
        </div>
      </div>
    </div>
  );
}

export default DrawerContent;
