// -> Beyond codebase
import React, { useReducer, createContext } from "react";
// -> Within codebase
import { Event, Venue, Artist } from "../../Types";
// -> Within component
import { initDataContextValue } from "./helpers";
import {
  initDataContextState, reducer, setVenues as setVenuesActionCreator,
  setEvents as setEventsActionCreator, setArtists as setArtistsActionCreator,
} from "./stateManagement";

export const DataContext = createContext(initDataContextValue);

const DataContextProvider: React.FC = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initDataContextState);

  const setVenues = (venues: Venue[]) => dispatch(setVenuesActionCreator(venues));

  const getVenueFromID = (venueID: string): Venue | undefined => {
    const { venues } = state;
    return venues.find((venue: Venue) => venue.id === venueID);
  };

  const setEvents = (events: Event[]) => dispatch(setEventsActionCreator(events));

  const getEventFromID = (eventID: string): Event | undefined => {
    const { events } = state;
    return events.find((event: Event) => event.id === eventID);
  }

  const setArtists = (artists: Artist[]) => dispatch(setArtistsActionCreator(artists));

  const getArtistFromID = (artistID: string): Artist | undefined => {
    const { artists } = state;
    return artists.find((artist: Artist) => artist.id === artistID);
  }

  return (
    <DataContext.Provider value={{
      state, setVenues, getVenueFromID, setEvents, getEventFromID,
      setArtists, getArtistFromID,
    }}>
      { children }
    </DataContext.Provider>
  );
}

export default DataContextProvider;
