// -> Beyond codebase
import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import { DataContext } from "../../Components/DataContextProvider/DataContextProvider";
import { ThemeInfo, UIContext } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { Event } from "../../Types";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, VENUE_ROUTE, VENUE_ID_ROUTE_PARAM_TOKEN, ARTIST_ROUTE, ARTIST_ID_ROUTE_PARAM_TOKEN } from "../../constants";
// -> Within component
import EventDetails from "./InternalComponents/EventDetails/EventDetails";
import { styleGen } from "./EventStyles";
import { EventState } from "./helpers";

const EventPage: React.FC = () => {
  const [state, setState] = useState<EventState>({
    event: undefined,
    loadingStatus: true,
    errorStatus: false,
    loaderInStatus: true,
    fallbackContentInStatus: true,
  });
  const { eventID }: { eventID: string } = useParams();
  const routerHistory = useHistory();
  const { t } = useTranslation("page_event");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { getEventFromID } = useContext(DataContext);
  const { palette } = themeInfo;
  const { pageCradle, noContentFallbackText, noContentFallbackTextCradle } = styleGen(themeInfo);

  let mainContentMicroanimationTimeout = useRef<any>(-1);
  let fallbackContentMicroanimationTimeout = useRef<any>(-1);

  useEffect(() => {
    const eventToSet: Event | undefined = getEventFromID(eventID);
    setState((prevState) => ({ ...prevState, event: eventToSet }));
    console.log(`[Event] event to display -> ${JSON.stringify(eventToSet, null, 1)}`);

    mainContentMicroanimationTimeout.current = setTimeout(() => {
      setState((prevState) => ({ ...prevState, loaderInStatus: false }));
      fallbackContentMicroanimationTimeout.current = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loadingStatus: false }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }, 1000);
  }, [eventID, getEventFromID]);

  useEffect(() => {
    return () => {
      clearTimeout(mainContentMicroanimationTimeout.current);
      clearTimeout(fallbackContentMicroanimationTimeout.current);
    }
  }, [mainContentMicroanimationTimeout, fallbackContentMicroanimationTimeout]);

  // -----

  const onVenueClick = (venueID: string) => {
    const computedURL = VENUE_ROUTE.replace(VENUE_ID_ROUTE_PARAM_TOKEN, venueID);

    setState((prevState) => ({ ...prevState, mainContentInStatus: false }));
    mainContentMicroanimationTimeout.current = setTimeout(() => {
      routerHistory.push(computedURL);
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  const onArtistClick = (artistID: string) => {
    const computedURL = ARTIST_ROUTE.replace(ARTIST_ID_ROUTE_PARAM_TOKEN, artistID);

    setState((prevState) => ({ ...prevState, mainContentInStatus: false }));
    mainContentMicroanimationTimeout.current = setTimeout(() => {
      routerHistory.push(computedURL);
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME)

  }

  // -----

  const {
    event, loadingStatus, loaderInStatus, errorStatus, fallbackContentInStatus
  } = state;

  return (
    <div className={css(pageCradle)}>
      {
        (loadingStatus) ? (
          <Fade inStatus={loaderInStatus}>
            <CircleLoader spinnerColor={palette.primary} />
          </Fade>
        ) : (
          <>
            {
              (event) ? (
                <EventDetails
                  event={event}
                  onVenueClick={onVenueClick}
                  onArtistClick={onArtistClick}
                />
              ) : (
                <Fade inStatus={fallbackContentInStatus}>
                  <div className={css(noContentFallbackTextCradle)}>
                    <p className={css(noContentFallbackText)}>  
                      {t("loadErrorMsg")}
                    </p>
                  </div>
                </Fade>
              )
            }
          </>
        )
      }
    </div>
  );
}

export default EventPage;
