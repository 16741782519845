import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
// import './App.less';
import './index.css';
import WrappedApp from './Components/WrappedApp/WrappedApp';
import "./i18n/i18n";
// import * as serviceWorkerUtil from './serviceWorkerUtil';

ReactDOM.render(
    <WrappedApp />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorkerUtil.register();
// 