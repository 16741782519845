import { ISong } from "../Types";
import { artists } from "./artists";

export const overall_collection_starstuff: ISong[] = [
  {
    id: "1b9c1250-47e7-4032-aaa2-fa8c83b3615b",
    title: "Guidance",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0233ac95d38c932415ee1325b9",
    // -> Artist: Planet V
    creators: artists.filter((artist) => artist.id === "37d2529d-14b9-4a90-9caf-c024052ffe28"),
    length: 199,
    keySignature: "6B",
    tempo: 111,
    links: {
      spotify: "https://open.spotify.com/track/4kxSrxFWbODczoG11jUiO0?si=yLgLi8qwQba-W9vDkQGIcg"
    }
  },
  {
    id: "76c854b2-3e72-4b42-aad6-055d2c15db4b",
    title: "Monster",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027b48a01785b82a2493a97ca0",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 288,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/6qCUEJHDnquJFvOrE2Npm0?si=bCJd53UiSlyNKgRT11MNIA",
    }
  },
  {
    id: "fd5f16bb-0640-4998-a751-d6b63339eb90",
    title: "Hope",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0282c901847066eb7fbfa71eaa",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 230,
    keySignature: "11B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/2C1PcLzaQVDK5i1PzlTPK4?si=GA915d3bTOOC0F2N_M4-lA"
    }
  },
  {
    id: "be65172a-77db-43b4-8221-06b1e8faaa63",
    // -> Artist: Øfdream
    title: "First Woe",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027113f7b388fb145de6cfcc09",
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 229,
    keySignature: "6B",
    tempo: 90,
    links: {
      spotify: "https://open.spotify.com/track/5r9jssGySumsa9ITKL6uga?si=dpMtCwPvTtuaOV1SjTuJMw"
    }
  },
  {
    id: "6da01f4a-dc26-4988-9e01-d7ee87d8e5f1",
    title: "Gurl",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029f40cb50a921c3c73d694885",
    // -> Artist: NVRLUV
    creators: artists.filter((artist) => artist.id === "fe8f6f47-18fb-451b-a5d7-9914aa029bca"),
    length: 193,
    keySignature: "10B",
    tempo: 124,
    links: {
      spotify: "https://open.spotify.com/track/18fH9dLbXUU3YrrPHqosCJ?si=KFVcvf2fTY-cZcOAo2VLzQ"
    }
  },
  {
    id: "4ac5e277-e30d-44c1-98ae-a5b1f1879868",
    title: "Andromeda",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c66c5f3ff334b024f94a03bf",
    // -> Artist: Rednog
    creators: artists.filter((artist) => artist.id === "10543a27-c99f-45b5-8372-26bc9c04ff31"),
    length: 264,
    keySignature: "5B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/4vIvFKHAzS4e5PzpgmtTKs?si=pXsWek3nTKSGxYNN40peKQ"
    }
  },
  {
    id: "00f9bb76-a7c9-4f03-9ba9-46f889cf2520",
    title: "Life Support",
    imageURL: "https://f4.bcbits.com/img/a2763404040_16.jpg",
    // -> Artist: FRCLN
    creators: artists.filter((artist) => artist.id === "4028e24d-6e41-4cac-822f-37057a001d77"),
    length: 171,
    keySignature: "8A", // -> Another key-finder reported 5A
    tempo: 138,
    links: {
      bandCamp: "https://lunarcollective.bandcamp.com/track/frcln-life-support"
    }
  },
  {
    id: "c64cf739-960e-4288-b9c4-e1d89a1f7942",
    title: "AAA Flip",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cfd5b6cf21ebfe1459b95e95",
    // -> Artists: Visene, Beau
    creators: artists.filter(
      (artist) => ["86ef7707-fef6-4a8a-a311-8d1f1653f87d", "1dfe9974-ebfa-4772-9ab8-a1205148874e"].includes(artist.id)
    ),
    length: 156,
    keySignature: "11B",
    tempo: 120,
    links: {
      soundCloud: "https://soundcloud.com/visene/aaa-flip",
      spotify: "https://open.spotify.com/track/0bRpxXlG9kz3LGg86cV5OK?si=n3i684r6QreplNw9S3oGjw",
      appleMusic: "https://music.apple.com/us/album/aaa-flip-feat-beau/1436907965?i=1436907966",
    }
  },
  {
    id: "70e81892-ed09-440f-b37a-f514e6eb48be",
    title: "Runnin'",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0204e52be3368f7569068cffad",
    // -> Artist: Øbeson
    creators: artists.filter((artist) => artist.id === "4d5d304b-4e10-4c04-a30a-462a0761a1fc"),
    length: 240,
    keySignature: "1B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/7KTZFY9dCzLUapGGr09nbE?si=0dSzupV0SoaAnGuFoDMzjg"
    }
  },
  {
    id: "bdfe4eb6-9c46-47f5-8a94-8b8c33b428a8",
    title: "Holding On",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a4c5bd2fcf0f815cbd1df271",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 206,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/1K8mFMTpiqMdigKnSHPLMx?si=AVpVtRJgTLa6YYNBEg92YA"
    }
  },

// - 10 - //

  {
    id: "c680377f-e463-4c5f-9432-dd0d5f51c7a4",
    title: "moonlight",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a4c5bd2fcf0f815cbd1df271",
    // -> Artists: arael, Rayless
    creators: artists.filter(
      (artist) => ["d0e601e3-1869-4634-a6c8-4436df00baaa", "580d4a2b-4583-4ab1-a55a-98be5f193814"].includes(artist.id)
    ),
    length: 165,
    keySignature: "11B",
    tempo: 128,
    links: {
      spotify: "https://open.spotify.com/track/0WV2pdD2TfUvSiujvLoANF?si=u__lSdu6STqrxVWyDfnMrg",
    },
  },
  {
    id: "065a950b-83b0-427b-98b8-304860fbd61b",
    title: "Dipsomania",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2737b389c8884441d2104f4e3e3",
    // -> Artist: Flechewave
    creators: artists.filter((artist) => artist.id === "3c783977-2e40-42ba-97eb-7c912a60fbbf"),
    length: 300,
    keySignature: "6B",
    tempo: 103,
    links: {
      spotify: "https://open.spotify.com/track/1ADYbnAbYhxAePxfqgdzAw?si=POm7Tn92RK-QDB5nyhlXMg"
    }
  },
  {
    id: "c0320eb5-8d84-433c-8308-81186eb59a55",
    title: "Solivagant",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02eb0ea952db34dd3adf7cc600",
    // -> Artist: Monomi
    creators: artists.filter((artist) => artist.id === "7edfd409-f07a-45a9-8e23-149c08bcf4e5"),
    length: 288,
    keySignature: "4B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/0WvLOm8v0PBBmTVbP87jkY?si=2NQxlUiMTJWm6X9hBXA1Mw"
    }
  },
  {
    id: "ade6d16f-698e-403b-a31d-9258d54943ca",
    title: "Bi Mask",
    // -> Artist: gl00my
    creators: artists.filter((artist) => artist.id === "483a9c78-cbfa-4b7c-8494-48e2e8631846"),
    length: 137,
    keySignature: "7A",
    tempo: 1,
    links: {
      bandCamp: "https://gloomy.bandcamp.com/track/bi-mask"
    }
  },
  {
    id: "7424320d-0041-425b-bc3c-f4d833f8d4cf",
    title: "T Shirt",
    imageURL: "https://f4.bcbits.com/img/a2815918561_16.jpg",
    // -> Artist: Pholo
    creators: artists.filter((artist) => artist.id === "59ad7bf8-4667-45b6-96d4-87f70573b936"),
    length: 160,
    keySignature: "3A",
    tempo: 139,
    links: {
      bandCamp: "https://pholouk.bandcamp.com/track/t-shirt"
    }
  },
  // - TODO: -> Figure out keysig and tempo of this track.
  {
    id: "6d6b2416-750f-4dd3-b2c7-182b81edca1a",
    title: "Slide (KILLSIREN and HYPEER Remix)",
    imageURL: "https://i1.sndcdn.com/artworks-zBRMy7bDWLvFmftB-v7Tv4g-t500x500.jpg",
    // -> Artists: H.E.R, KILLSIREN, HYPEER
    creators: artists.filter(
      (artist) => ["72f83a96-fffd-4850-9d43-61b93a9b57f4",
        "90d7b4dc-84bc-46a9-a84f-ea3b0c7b6b46", "ef9bc1e8-7e34-4fdd-a54e-b4de1477b831"].includes(artist.id)
    ),
    length: 176,
    keySignature: "1B", // -> UNDETERMINED
    tempo: 1, // -> UNDETERMINED
    links: {
      soundCloud: "https://soundcloud.com/killsiren/her-slide-rmx-ft-hypeer"
    }
  },
  {
    id: "078ca2bb-e865-4096-83b3-a862b8da49f1",
    title: "nobody knows",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02bafc8cbbafefe40ce4cb7117",
    // -> Artist: almogfx
    creators: artists.filter((artist) => artist.id === "5a29ca1f-c903-4b43-b6bf-0eba13d43ddc"),
    length: 155,
    keySignature: "12B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/00TKUyC9BfJKh19sLF1nx4?si=oNRhdxlKSN6fn6EoaUEYIw"
    }
  },
  {
    id: "d704ddc4-42d5-47ec-a36a-5904db51a1dd",
    title: "Saudade",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a9b9b1b3160aefea649438d1",
    // -> Artist: almogfx
    creators: artists.filter((artist) => artist.id === "5a29ca1f-c903-4b43-b6bf-0eba13d43ddc"),
    length: 229,
    keySignature: "10B",
    tempo: 123,
    links: {
      spotify: "https://open.spotify.com/track/1OuohtClQPrBBkMsUiVpxi?si=06XHGXmkR0aAKPwut1XGew"
    }
  },
  {
    id: "95899556-d4a1-4ad9-84e2-2fed23db39bb",
    title: "angst",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029536d6b30d1ac62a419b1a38",
    // -> Artist: almogfx
    creators: artists.filter((artist) => artist.id === "5a29ca1f-c903-4b43-b6bf-0eba13d43ddc"),
    length: 179,
    keySignature: "4B",
    tempo: 123,
    links: {
      spotify: "https://open.spotify.com/track/0zOiNx9r7w7p3DsTl9P84s?si=EEHIVgiJT469lD8dY2rJd"
    }
  },
  {
    id: "f37e0fa4-0731-4e78-852b-d55fc0685081",
    title: "Astral Plane",
    imageURL: "https://i1.sndcdn.com/artworks-000322846044-efdzz0-t500x500.jpg",
    // -> Artist: KXIRU
    creators: artists.filter((artist) => artist.id === "cb464011-0833-44aa-b567-d07094a6c191"),
    length: 160,
    keySignature: "11A",
    tempo: 119,
    links: {
      soundCloud: "https://soundcloud.com/yumecollective/kairu-astral-plane-yume-premiere-1",
    },
  },

  // - 20 - //

  {
    id: "2e28aae5-5cd1-49d0-a390-5a5254cca2a9",
    title: "Rockstar (barnacle boi flip)",
    imageURL: "https://i1.sndcdn.com/artworks-000280439129-ttqlxc-t500x500.jpg",
    // -> Artists: Post Malone, barnacle boi
    creators: artists.filter(
      (artist) => ["774c9e44-6ade-4698-9466-5219c669035f", "839a4f4a-4b49-4ef9-b7d3-a3550b9ca1c6"].includes(artist.id)
    ),
    length: 226,
    keySignature: "7B",
    tempo: 140,
    links: {
      soundCloud: "https://soundcloud.com/barnacleboimusic/post-malone-rockstar-barnacle-boi-flip"
    },
  },
  {
    id: "d369e688-0045-47af-8473-1a6761aa8273",
    title: "Forever.",
    imageURL: "https://i.scdn.co/image/ab67616d00001e025577ff5f262ac4d06a1e18ed",
    // -> Artist: barnacle boi, throwmance
    creators: artists.filter(
      (artist) => ["774c9e44-6ade-4698-9466-5219c669035f", "7f053e5d-ef16-4b89-b79e-7cedfb2a7273"].includes(artist.id)
    ),
    length: 187,
    keySignature: "12B",
    tempo: 128,
    links: {
      spotify: "https://open.spotify.com/track/55v2WpOO1zZsTxctLTJ2vL?si=aCSyj2IDSVGYME4OcytJTw"
    }
  },
  {
    id: "0d2b2477-9e32-447d-8c86-89488b1a1ab9",
    title: "Conscience",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d988f7bcd56007c1f3e0ba48",
    // -> Artist: Weaver Beats
    creators: artists.filter((artist) => artist.id === "49950c18-900e-4def-a4b8-bc15d0dafaff"),
    length: 200,
    keySignature: "1B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5KFRHLVP4C1CR4OMhAbQff?si=1h3QK4GzSDW3S0E8AqasTw",
    },
  },
  {
    id: "0b83ecd4-17cb-4cec-bedf-085294c491c7",
    title: "So High (Sorsari Remix)",
    imageURL: "https://i1.sndcdn.com/artworks-000284832293-tlm1k8-t500x500.jpg",
    // -> Artists: Jake Robertz, Sorsari
    creators: artists.filter(
      (artist) => ["ef839db3-ae41-4af9-9078-a8d211089962", "f2e4ba6b-9fbc-4dcb-bad6-341e21f1e32c"].includes(artist.id)
    ),
    length: 221,
    keySignature: "8A",
    tempo: 72,
    links: {
      soundCloud: "https://soundcloud.com/sorsari/jake-robertz-so-high-sorsari-remix"
    },
  },
  {
    id: "038c9344-b3c8-45a8-bfe9-a1174280d3dc",
    title: "GUCCI",
    imageURL: "https://i1.sndcdn.com/artworks-000206096441-y1606d-t500x500.jpg",
    // -> Artist: juku
    creators: artists.filter((artist) => artist.id === "6b3f7af5-f773-4255-8ea8-3254235e11fc"),
    length: 164,
    keySignature: "6B",
    tempo: 117,
    links: {
      soundCloud: "https://soundcloud.com/juku2000/x-1"
    }
  },
  {
    id: "10ca7f65-d403-4736-b7dc-8e5fd7b433dd",
    title: "Catch Your Breath (Øfdream Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0232b6d3e91d4ec362928e5223",
    // -> Artists: Verasect, Øfdream
    creators: artists.filter(
      (artist) => ["4d469c16-57df-42ea-bc13-202b204411ba", "e1c0dc8e-ef6f-4be8-b544-f59c78595481"].includes(artist.id)
    ),
    length: 258,
    keySignature: "12B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/0XSy0griub1vc7iTwJISe4?si=90nAhHOiQ6ucqY_bZPgoJA"
    }
  },
  {
    id: "b9763f3f-68ce-4cb5-8c2d-da8e15d9248d",
    title: "4get",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273bc457208d83aa168ddad4e3c",
    // -> Artist: Visene
    creators: artists.filter((artist) => artist.id === "86ef7707-fef6-4a8a-a311-8d1f1653f87d"),
    length: 155,
    keySignature: "11B",
    tempo: 144,
    links: {
      spotify: "https://open.spotify.com/track/54BnRrAiLRq2iGgj9giBJE?si=sBXlDF6jQu-La3uTXw9fnA"
    }
  },
  {
    id: "6b9bda1a-63ee-49cf-baa8-703c54b4dfc1",
    title: "Labyrinth",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273489eaf58d751cf575c23f8a5",
    // -> Artist: Mannequin
    creators: artists.filter((artist) => artist.id === "5c63804d-531c-43d7-866a-58de5b67f5e4"),
    length: 314,
    keySignature: "11B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/3ioHEkY18dP4sommZJIbow?si=rQEYX0iWSO6XwJPiB5FVwQ"
    }
  },
  {
    id: "f79cd40a-30e4-4259-ba38-048b73233c86",
    title: "Gateway",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2736152bf81223b1c9bbb000a42",
    // -> Artists: Henryisdead, Freud Jung
    creators: artists.filter(
      (artist) => ["26a2f0e6-842f-41a8-8cc2-46125532c821", "b091c39f-9dfa-481e-a30b-ce707c27c801"].includes(artist.id)
    ),
    length: 257,
    keySignature: "8B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/5UqWOpRcPvEdKKTD2Gu8sU?si=3mjCM9rySEikLJfJ8Y57vQ"
    }
  },
  {
    id: "fa2fc5cb-d462-4dab-a6fb-78c8f017c8f5",
    title: "Still Lost (Five Suns Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2737245e3a5a8ee898447734bed",
    // -> Artists: Freud Jung, Kareful, Five Suns
    creators: artists.filter(
      (artist) => ["26a2f0e6-842f-41a8-8cc2-46125532c821",
        "cf86ac9a-861e-4ca5-930e-8b2ca003ea38", "ca5623c9-4268-47f0-8569-8a562e4e9bbe"].includes(artist.id)
    ),
    length: 206,
    keySignature: "4B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/5SWRSpVW9fkx7l7XX990PH?si=fwfSrWw4TAOIhVcT3VgnaA"
    }
  },

  // - 30 - //

  {
    id: "47bade83-e955-45f9-b8ad-d0e4a5674070",
    title: "white iverson",
    imageURL: "https://f4.bcbits.com/img/a2877658461_16.jpg",
    // -> Artists: Post Malone, astral planes
    creators: artists.filter(
      (artist) => ["839a4f4a-4b49-4ef9-b7d3-a3550b9ca1c6", "28d8c46e-8702-459f-a849-319e2d940f39"].includes(artist.id)
    ),
    length: 288,
    keySignature: "7A",
    tempo: 120,
    links: {
      bandCamp: "https://spectralcollective.bandcamp.com/track/astral-planes-white-inverson"
    }
  },
  {
    id: "dc652b7a-f01d-4dae-937d-d49519027925",
    title: "Tempted",
    imageURL: "https://f4.bcbits.com/img/a2877658461_16.jpg",
    // -> Artists: Jazz Cartier, Hyperforms
    creators: artists.filter(
      (artist) => ["b5c8ad3b-9e5b-43cb-af67-771a940fbb21", "aa1ea277-75b1-45e0-aef8-3fda501c0334"].includes(artist.id)
    ),
    length: 181,
    keySignature: "8A",
    tempo: 95,
    links: {
      bandCamp: "https://spectralcollective.bandcamp.com/track/hyperforms-tempted"
    }
  },
  {
    id: "f8a1659e-19d6-4b14-b150-2eff452694c4",
    title: "Worthless",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273437d4d166e067d91a62da7c8",
    // -> Artist: KAVERN
    creators: artists.filter((artist) => artist.id === "905a1bc8-add0-4ff9-a63a-92c6db6e3a23"),
    length: 214,
    keySignature: "1B",
    tempo: 68,
    links: {
      spotify: "https://open.spotify.com/track/1NlLAKiLuz15by78liSBsz?si=JtA-PrLXSnCg9VNHYXIn-A"
    }
  },
  {
    id: "c0338f80-6881-46b0-8e0e-ace39922443d",
    title: "Howls",
    imageURL: "https://i1.sndcdn.com/artworks-000176804436-72mcpw-t500x500.jpg",
    // -> Artist: Trash Lord
    creators: artists.filter((artist) => artist.id === "c0338f80-6881-46b0-8e0e-ace39922443d"),
    length: 293,
    keySignature: "3B",
    tempo: 140,
    links: {
      soundCloud: "https://soundcloud.com/trshlrd/howls"
    }
  },
  {
    id: "64d283de-38b0-4c68-93fa-b4fdadfede7b",
    title: "Architect",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27380d3dcc9e028037bea4c8d30",
    // -> Artists: Sorsari, gl00my
    creators: artists.filter(
      (artist) => ["ef839db3-ae41-4af9-9078-a8d211089962", "483a9c78-cbfa-4b7c-8494-48e2e8631846"].includes(artist.id)
    ),
    length: 288,
    keySignature: "4B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2dtSEr7TRWkYhGZPmaWRal?si=J51if0kvQFSxDzk5jAXE_Q"
    }
  },
  {
    id: "ccc8a49e-379e-460c-81a4-19cc29bd4397",
    title: "Palace",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0282b211685c80166f8eddef4c",
    // -> Artists: Skit, Kareful
    creators: artists.filter(
      (artist) => ["ca5623c9-4268-47f0-8569-8a562e4e9bbe", "f828cc93-b86e-4fe6-abde-40dfcc37686a"].includes(artist.id)
    ),
    length: 221,
    keySignature: "1B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/7u1asB0CHXg3vJjpZh6Mxa?si=LxabQpcJTiq-q9aCPDWkUQ"
    }
  },
  {
    id: "6cb3a9b3-c829-4e11-8415-197532e8d37b",
    title: "Juneau",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273f75ec56e7854ae346d84ea63",
    // -> Artist: Skit
    creators: artists.filter((artist) => artist.id === "f828cc93-b86e-4fe6-abde-40dfcc37686a"),
    length: 205,
    keySignature: "12B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/4oDzRMvDeIrwuwupp0iRQ3?si=MmcjtXLeQEyEno3wwqJzww"
    }
  },
  {
    id: "02349909-9076-4afe-a131-ebee97c8057e",
    title: "Give U",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273dd9405c124ba20291bf8a8c6",
    // -> Artist: gl00my
    creators: artists.filter((artist) => artist.id === "483a9c78-cbfa-4b7c-8494-48e2e8631846"),
    length: 199,
    keySignature: "1B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/1qZClR8TSrZIaXw6gfU9eq?si=ShvDnxXNRaKbtm0qy8VV-g"
    }
  },
  {
    id: "1e87f02e-b748-425f-99f5-915e5a22dd31",
    title: "Nemy",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273a579f2284e4eb84e08e9b1c7",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 293,
    keySignature: "8B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/05DhvYv3ywnMlLqecAYIkR?si=sCQTHS0BTlCsqyJB6am-3w"
    }
  },
  {
    id: "f07a7229-4ede-4e35-b2b3-2e3851e080b9",
    title: "Pink Echoes",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27329125329b606b0ee27d19bef",
    // -> Artists: gl00my, Mannequin
    creators: artists.filter(
      (artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "5c63804d-531c-43d7-866a-58de5b67f5e4"].includes(artist.id)
    ),
    length: 179,
    keySignature: "2B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/68x1nLcxbVNtfSeqkl7nN5"
    }
  },

  // - 40 - //

  {
    id: "14aece4b-ae5a-4dfd-96c0-f7948cca1b0a",
    title: "Try Me",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273e7ceea95328caf4c54f91143",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 308,
    keySignature: "8B",
    tempo: 151,
    links: {
      spotify: "https://open.spotify.com/track/72XJZqZHYjnUVGOXZJX8dA?si=JaQIwbZpTlmZN6MNdy3Hxg"
    }
  },
  {
    id: "7b70746a-f4a5-4540-8fa2-3135beb6f927",
    title: "Outcast",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273950b5bf0e78be4ea7555df9a",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 168,
    keySignature: "10B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4huXXmwOoRIGGx5ITYxFRB"
    }
  },
  {
    id: "56d83716-57f8-419d-9cf2-2098ce22488b",
    title: "TEARDROP",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273d95e779f88dff29f786daa21",
    // -> Artists: Brothel, Enjoii, luna.moon
    creators: artists.filter(
      (artist) => [
        "0ad1f780-0e60-4fde-a1c3-c3808671292f", "d2bc4672-f7cb-4ebd-a25e-9b78048a8b0f",
        "4e597e7d-a7e8-4d3e-bfa2-fddc5cc11b5f"
      ].includes(artist.id)
    ),
    length: 240,
    keySignature: "8B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/533OSQzYI1H0u0Az4W878G"
    }
  },
  {
    id: "d5514e71-3ea7-40a4-a115-3031eb361ecc",
    title: "Endless Sky",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2736b779fb1998321cd78b77849",
    // -> Artist: Noah B
    creators: artists.filter((artist) => artist.id === "5613bb7a-d148-4164-a329-812a72eb74e5"),
    length: 243,
    keySignature: "4B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/2V3lxcAlbBk0rl94lSoDFz"
    }
  },
  {
    id: "634d0eb8-6cf0-4a7e-897a-055ac5f3b1fd",
    title: "Dreamcatcher",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b1854fb5dcc269b0bb4714a5",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 297,
    keySignature: "3B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/6QZrCg92L7L32tdyJ7P0AN"
    }
  },
  {
    id: "3e27d27b-be59-4a65-ba54-9062e04f8eb3",
    title: "Arcanum",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273bb60a2853a79cf58f6f81561",
    // -> Artists: Sublab, Azaleh
    creators: artists.filter(
      (artist) => ["37b5df35-6b7b-4ff8-b392-8d093a6793ef", "c50b5744-6800-49c4-b706-e79d7382a45f"].includes(artist.id)
    ),
    length: 220,
    keySignature: "9B",
    tempo: 123,
    links: {
      spotify: "https://open.spotify.com/track/5ZT0HMZOROXyzxb15kL9JO"
    }
  },
  {
    id: "70896022-82f3-4454-bebf-be6d93c2537e",
    title: "Thelema",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2737113f7b388fb145de6cfcc09",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 210,
    keySignature: "3B",
    tempo: 142,
    links: {
      spotify: "https://open.spotify.com/track/098PU9y0wA153H6UI36uMy"
    }
  },
  {
    id: "81fff4a3-e62e-4ab5-ab41-5adf35a2ce33",
    title: "Perception",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273bf4e0575322a30cc3cd14923",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 274,
    keySignature: "5B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3LLThN8kxWS8rvstTacMdZ"
    }
  },
  {
    id: "9881a087-da93-422d-90a2-8b2310491d60",
    title: "Roses",
    imageURL: "https://i1.sndcdn.com/artworks-000490172478-gjsbrq-t500x500.jpg",
    // -> Artist: BACKWHEN
    creators: artists.filter((artist) => artist.id === "2855ad6e-e78a-425a-9e3c-9df73fc4b746"),
    length: 261,
    keySignature: "7B",
    tempo: 110,
    links: {
      soundCloud: "https://soundcloud.com/thefugginvhs/roses-backwhen"
    }
  },
  {
    id: "a8d81758-0ba6-4f56-97c1-5655731ff3e0",
    title: "We See It",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027b389c8884441d2104f4e3e3",
    // -> Artist: Flechewave
    creators: artists.filter((artist) => artist.id === "3c783977-2e40-42ba-97eb-7c912a60fbbf"),
    length: 193,
    keySignature: "6B",
    tempo: 101,
    links: {
      spotify: "https://open.spotify.com/track/3LwW97G8fHZAgL0k2GMC7e?si=3FHVuOr_QY2rcbFI8Lcsjw"
    }
  },

  // - 50 - //

  {
    id: "5335848b-ac30-4a06-b1bd-b186603530d0",
    title: "Fallen Angel",
    imageURL: "https://i1.sndcdn.com/artworks-000550239411-4wgpyw-t500x500.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 237,
    keySignature: "2B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/6iKJAUeHx0saIoZ8z69Wam"
    }
  },
  {
    id: "d6d0c828-337b-406d-b192-7b5b7279ceb1",
    title: "Ectoplasm",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b4e3006b1e48ba76be9a0f19",
    // -> Artist: Celestial
    creators: artists.filter((artist) => artist.id === "35888719-18d4-42d3-b623-19266fc6f247"),
    length: 267,
    keySignature: "1B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5t5O95UMwCaVR6BL2W363f"
    }
  },
  {
    id: "29d39f17-5fe4-4814-baba-4a9956ce767c",
    title: "Fallen",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273da30a7633ffc13bddb5adcd3",
    // -> Artists: EONE, Black Chanel, Xaniel, Kareful
    creators: artists.filter(
      (artist) => [
        "3f11f74d-2600-4ed8-9df9-ac2c629461b0", "7592477d-1352-44cf-ab1c-c0dc88ee5d0c",
        "9a836389-75a4-4c66-9e76-60249f03ea25", "ca5623c9-4268-47f0-8569-8a562e4e9bbe"
      ].includes(artist.id)
    ),
    length: 201,
    keySignature: "4B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3a2Uc0gBBcfNC7Ulzhw0pm"
    }
  },
  {
    id: "6581b2f1-d810-4db1-b50a-80bc7c206b0d",
    title: "Darkest Hour",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b4e3006b1e48ba76be9a0f19",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 245,
    keySignature: "5B",
    tempo: 1,
    links: {
      spotify: "https://open.spotify.com/track/2LwLZxz6IVehkchQXOLSy7"
    }
  },
  {
    id: "bee093df-6639-4309-a820-ad4d6817dd07",
    title: "S14",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b4e3006b1e48ba76be9a0f19",
    // -> Artist: Kyo
    creators: artists.filter((artist) => artist.id === "3bd76438-3603-412b-9e44-432bb6655308"),
    length: 226,
    keySignature: "1B",
    tempo: 122,
    links: {
      spotify: "https://open.spotify.com/track/4tKBYKImlydpCHB8Qw5Qts"
    }
  },
  {
    id: "edaa4021-d6c6-4629-8f3b-3d9312b7f0d3",
    title: "Respire",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b4e3006b1e48ba76be9a0f19",
    // -> Artist: AyyJay
    creators: artists.filter((artist) => artist.id === "7ee42c8a-2982-4760-a12f-b1b752967a4a"),
    length: 293,
    keySignature: "1B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/7pDIyFS4ZcfyIHhhMdCvo2"
    }
  },
  {
    id: "e55139cb-ec91-4381-a02e-89726f16ad89",
    title: "Ajna",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b4e3006b1e48ba76be9a0f19",
    // -> Artist: Skit
    creators: artists.filter((artist) => artist.id === "f828cc93-b86e-4fe6-abde-40dfcc37686a"),
    length: 210,
    keySignature: "4B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/4Y9WBW1QolPfr0kVXRYxr3"
    }
  },
  {
    id: "07f2fbed-b446-4cac-9046-c7f7c4963405",
    title: "The Corridor (Sorsari Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273e587ba35c6a8716df8287cf4",
    // -> Artist: Om Unit, Sorsari
    creators: artists.filter(
      (artist) => ["3aa7f9ac-7cc0-42ca-aab9-42bce1245610", "ef839db3-ae41-4af9-9078-a8d211089962"].includes(artist.id)
    ),
    length: 233,
    keySignature: "7B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/79cx8DyuVXe6MalkScC4Lc"
    }
  },
  {
    id: "12486fc7-6b89-4cde-95fa-af3d94444a5f",
    title: "Know Your Worth",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b1854fb5dcc269b0bb4714a5",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 388,
    keySignature: "2B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5fHtiIKhRSPF5AmQxs3Srh?si=dxb3REANTTeFexf2DVO6LA"
    }
  },
  {
    id: "ba3d96a6-36cb-4b60-adf7-8be2a8d0e2d2",
    title: "Anything",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739fc941ee5ca30ea27e1bfa76",
    // -> Artists: Sorsari, MRKRYL
    creators: artists.filter(
      (artist) => ["ef839db3-ae41-4af9-9078-a8d211089962", "f0073e5b-c23a-4add-a29b-c5000e4c96d6"].includes(artist.id)
    ),
    length: 362,
    keySignature: "3B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/2nA8SRd9ay62KQ8GxUwEZI"
    }
  },

  // - 60 - //

  {
    id: "ff6bb694-2521-4062-858b-0f0109105aaf",
    title: "fatigue",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273da44a205ed5ac33165a76cb9",
    // -> Artist: MVEJIMV
    creators: artists.filter((artist) => artist.id === "9d235bf1-8e03-44f4-b0a3-e7af8e0b92b4"),
    length: 311,
    keySignature: "9B",
    tempo: 112,
    links: {
      spotify: "https://open.spotify.com/track/27GALBsVfptARM8A77Cq3t"
    }
  },
  {
    id: "ba6e5647-7961-49a1-8e20-b2aced748db5",
    title: "Rot",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2733412e89338d4cea8976ab097",
    // -> Artist: WTCHCRFT
    creators: artists.filter((artist) => artist.id === "cb9586c1-45fc-4ce8-b108-48abfeb0e52c"),
    length: 247,
    keySignature: "8B",
    tempo: 91, // -> Autodetected number seems questionable
    links: {
      spotify: "https://open.spotify.com/track/1pQnwYzLm6ZP1waUCPSeGm"
    }
  },
  {
    id: "b326080d-2101-4188-996e-90c6c8f14ec9",
    title: "Less Education",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273baada8fbe52f5ff1ea516e0e",
    // -> Artist: WTCHCRFT
    creators: artists.filter((artist) => artist.id === "cb9586c1-45fc-4ce8-b108-48abfeb0e52c"),
    length: 223,
    keySignature: "8B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/0QBaH1j51Jyw4fMuZbQy1z"
    }
  },
  {
    id: "d6c6757d-762f-432f-aedd-f802b1f57144",
    title: "124th",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2737b66cf8e36bae0d2ad552f05",
    // -> Artist: Mssingno
    creators: artists.filter((artist) => artist.id === "a209cc6d-cc0d-4415-833b-d4b9352b6d4b"),
    length: 247,
    keySignature: "11B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4AT5ORddYpQ4HWIzlhDSn6"
    }
  },
  {
    id: "8d697a01-401c-4b2b-83cd-e55a88e1fcae",
    title: "Fones",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2734c51824c35a3a282be2421db",
    // -> Artist: Mssingno
    creators: artists.filter((artist) => artist.id === "a209cc6d-cc0d-4415-833b-d4b9352b6d4b"),
    length: 236,
    keySignature: "6B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/5fxnAXXER92BZgnJNV8lQN"
    }
  },
  {
    id: "f10a2ff3-b5a1-4d72-8bff-8b4f058d7c60",
    title: "Scope",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2734c51824c35a3a282be2421db",
    // -> Artist: Mssingno
    creators: artists.filter((artist) => artist.id === "a209cc6d-cc0d-4415-833b-d4b9352b6d4b"),
    length: 210,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2K4hEhEtvWQBMXvLWFHV0q"
    }
  },
  {
    id: "3d3281d0-b218-4ce6-83b1-dd04fc556770",
    title: "Inta",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2734c51824c35a3a282be2421db",
    // -> Artist: Mssingno
    creators: artists.filter((artist) => artist.id === "a209cc6d-cc0d-4415-833b-d4b9352b6d4b"),
    length: 214,
    keySignature: "2B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/4uhigmJ8Quq8VDFKEg52Sk"
    }
  },
  {
    id: "5186cbe5-409a-40d9-af7f-fc1640e5e77e",
    title: "Silver Dream",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273a9c424d9b874f3079c13dbd5",
    // -> Artist: CHVRN
    creators: artists.filter((artist) => artist.id === "8a9aac1e-19bf-4566-998e-05cd023b0bec"),
    length: 232,
    keySignature: "5B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5vUUUMVHHYZWKCGBhOmjL0"
    }
  },
  {
    id: "58f4a930-fde6-4083-9c9e-0c30858c30a2",
    title: "Control",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273e278024bcbf14126ab0c84d7",
    // -> Artist: CHVRN
    creators: artists.filter((artist) => artist.id === "8a9aac1e-19bf-4566-998e-05cd023b0bec"),
    length: 260,
    keySignature: "11B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/6PVbwxWGPZnBFveBfe8o3H"
    }
  },
  {
    id: "8b79905e-d705-4d8c-ab1d-8f031998686b",
    title: "Sidewalks",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273afbe810b9f0678764bf20136",
    // -> Artists: CHVRN, Landechoe
    creators: artists.filter(
      (artist) => ["8a9aac1e-19bf-4566-998e-05cd023b0bec", "f56349f7-c4fc-4ce9-afc8-fe7ae8da90bf"].includes(artist.id)
    ),
    length: 234,
    keySignature: "1B",
    tempo: 117,
    links: {
      spotify: "https://open.spotify.com/track/7J1tb7lzCdly5bbh4uehMD"
    }
  },

  // - 70 - //

  {
    id: "79ce80c0-d8ab-4d09-a0bd-138bf687ce84",
    title: "No Exit",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b03ac08272ae0347bccc420e",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 194,
    keySignature: "11B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/4iyHuqLLVofIqkeWGxOVAZ"
    }
  },
  {
    id: "174a6dba-c0d5-4dad-a244-d9a0e06c7ae3",
    title: "For No One",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b9ea372c3f38797f1748eaae",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 213,
    keySignature: "11B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/6a8k1xs5olQTV2ap719yzB"
    }
  },
  {
    id: "6e295c39-49ca-4895-be0c-73f39f341d03",
    title: "Let Off",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273c99ebd1602af9a328f138302",
    // -> Artist: Vacant
    creators: artists.filter((artist) => artist.id === "125105d9-775a-429c-ab9e-04f12f440cdc"),
    length: 200,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/600uPEYmDBWCzFf6fKz63H"
    }
  },
  {
    id: "4ef06fab-501f-4a4b-9397-1bcbf9e43b16",
    title: "Possession",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e9cfd3d32be6921d309acdd",
    // -> Artists: Dyzphoria, Kyddiekafka
    creators: artists.filter(
      (artist) => ["169286b0-b3d4-4829-b080-8ba4d8c471ca", "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"].includes(artist.id)
    ),
    length: 251,
    keySignature: "10B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/09C3hEn839px4MKv1jkexU"
    }
  },
  {
    id: "43b046a0-fc9b-4f32-bedb-e50fa6108b7a",
    title: "Blue Eyes",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b6415324dfa59ff240dadaf4",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 216,
    keySignature: "11B",
    tempo: 111,
    links: {
      spotify: "https://open.spotify.com/track/6EXOyMeqZrLVnuLmcDHyWV"
    }
  },
  {
    id: "995d9345-8b6d-4d00-8f90-a6895ca94776",
    title: "Everlasting",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b156c8258634a038a89b33bf",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 217,
    keySignature: "10B",
    tempo: 126,
    links: {
      spotify: "https://open.spotify.com/track/2qjv68yW7uQsydWQrTxPdC"
    }
  },
  {
    id: "f8661639-96fb-4c7e-8019-bcec7d902741",
    title: "Regrets",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273f27e8dd6496f03f4eb60c4de",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 208,
    keySignature: "4B",
    tempo: 98,
    links: {
      spotify: "https://open.spotify.com/track/4kHPSkNb2qjuJI1JsiDBXr"
    }
  },
  {
    id: "ebf46227-2c73-4f67-9e16-fd9510ef0b2e",
    title: "On My Own",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b9ea372c3f38797f1748eaae",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 244,
    keySignature: "8B",
    tempo: 129,
    links: {
      spotify: "https://open.spotify.com/track/7yiVsNHRxbv4Yy9TfnBxcd"
    }
  },
  {
    id: "3a59df9f-98be-4598-9451-6920c9cd62fe",
    title: "Obsessions",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b156c8258634a038a89b33bf",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 219,
    keySignature: "12B",
    tempo: 117,
    links: {
      spotify: "https://open.spotify.com/track/6nGOlIdDjOcjZhirApJDet"
    }
  },
  {
    id: "93246a0c-70b4-40c7-a569-b61689410493",
    title: "Bloody Storm",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b9ea372c3f38797f1748eaae",
    // -> Artists: Kyddiekafka, palence
    creators: artists.filter(
      (artist) => ["3e0ae7fe-aecf-4b50-bab8-0c4875265daf", "b8a2c25d-b88e-4347-8c2a-945c92c16257"].includes(artist.id)
    ),
    length: 199,
    keySignature: "3B",
    tempo: 112,
    links: {
      spotify: "https://open.spotify.com/track/55SG6eEBHyKh4VeBdLBzsZ?si=t8G7vVlcQoKzu-sICH9v1w"
    }
  },

  // - 80 - //

  {
    id: "51f8188b-474a-4fcf-873f-47df83e196fa",
    title: "Rose Petals",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273e6545bada50bb901de6c33e8",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 178,
    keySignature: "1B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/52yTpTfOqRHXyn0lyQgwr3"
    }
  },
  {
    id: "74ce6a15-f196-4fe8-a043-daab7710af78",
    title: "On My Own",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273d75523282bf3ba41ea85234f",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 172,
    keySignature: "12B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/0F4FejjWi3bf5vGOkuQwPS"
    }
  },
  {
    id: "17d35ac3-ec5f-4145-ae82-48e5f8c7041c",
    title: "Stunt",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273ab58c544609a5f6d178b8b1e",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 155,
    keySignature: "9B",
    tempo: 76,
    links: {
      spotify: "https://open.spotify.com/track/75TaaL8GNqoY7mxgz9TFB6"
    }
  },
  {
    id: "65c2ccf3-210e-4855-ab75-7907e5ea96f0",
    title: "Take It Back",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273cac7a5726adb721d54d4c8ca",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 193,
    keySignature: "4B",
    tempo: 160,
    links: {
      spotify: "https://open.spotify.com/track/3LeKlj9GCxzPB0fAvwtLFU"
    }
  },
  {
    id: "7333b774-3647-4b30-ac14-002815b6741c",
    title: "Last Call",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273bada6e5baee2910941cd9290",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 173,
    keySignature: "4B",
    tempo: 119,
    links: {
      spotify: "https://open.spotify.com/track/0jkiOZCb3yToykDRAtLHdT"
    }
  },
  {
    id: "98f83084-709e-4cfa-8edc-c9e7c9066925",
    title: "Come Around",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2734af40f60c15e486c487d588c",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 204,
    keySignature: "9B",
    tempo: 132,
    links: {
      spotify: "https://open.spotify.com/track/0zw0dzP2N15Hc1FlI9dXFN"
    }
  },
  {
    id: "2e8d59f3-5403-4c56-b68c-fc9ed8abe150",
    title: "Won't",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273339b110d85b9d1296ea30cce",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 187,
    keySignature: "8B",
    tempo: 108,
    links: {
      spotify: "https://open.spotify.com/track/7ivvYukBBkyHCr4YQMVot5"
    }
  },
  {
    id: "b683d1fd-a8ad-4e22-a31a-63da9f6e4832",
    title: "These Nights",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2733f4c9c36453c48b79610d9c9",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 247,
    keySignature: "11B",
    tempo: 133,
    links: {
      spotify: "https://open.spotify.com/track/6Y2Pm57dwAMpYn7QRQ5Azt"
    }
  },
  {
    id: "98d3c785-a08e-4a7d-92bd-60e8782cf7ec",
    title: "Blackout",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273cac7a5726adb721d54d4c8ca",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 125,
    keySignature: "5B",
    tempo: 77,
    links: {
      spotify: "https://open.spotify.com/track/6vTJnMrj7G1aUQZF5PdJRC"
    }
  },
  {
    id: "162e6186-f700-46f2-bba9-fcbb29a70509",
    title: "Toss Up",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273d89ff2211d91a5784d467cfe",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 131,
    keySignature: "1B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/4MIZbPiPX2iSfo2cHAFjOn"
    }
  },

  // - 90 - //

  {
    id: "bcbf6302-8200-44c0-b282-73b655c76e2c",
    title: "Some Say",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273896bad92f32359992a3f5051",
    // -> Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 226,
    keySignature: "6B",
    tempo: 112,
    links: {
      spotify: "https://open.spotify.com/track/5zx1WrC5AuCaKjpKZDzNy5"
    }
  },
  {
    id: "087a31e9-139c-4a9b-8a01-b2601c75debf",
    title: "Drive Way",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2732b995bd89d3405e84c408bda",
    // -> Artists: GASHI, Azul Wynter
    creators: artists.filter(
      (artist) => ["14b042c5-2391-4efc-a582-57b5808ad3f4", "0247e7ca-bb4a-4e17-8569-bdbfbd331368"].includes(artist.id)
    ),
    length: 194,
    keySignature: "11B",
    tempo: 158,
    links: {
      spotify: "https://open.spotify.com/track/0nkwj667p0oMEURBST8Nyx"
    }
  },
  {
    id: "f0bde5ac-85b1-410c-832d-a03478f69c19",
    title: "Foreign Blocks",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e0f82cad03d8711ffe36a8c",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 182,
    keySignature: "12B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4GUOGUGSkSpzVbmZddEoPk"
    }
  },
  {
    id: "216f1ccd-58e0-4ab6-9a15-cd177f4da7be",
    title: "Playing Games",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e0f82cad03d8711ffe36a8c",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 207,
    keySignature: "3B",
    tempo: 1,
    links: {
      spotify: "https://open.spotify.com/track/78P2VuCfTtKinFVDaxh2yF"
    }
  },
  {
    id: "211f677b-1989-4dc1-b8c8-500d7ba0fda7",
    title: "Somebody",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273aec6ef399bb2efb225cbc745",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 279,
    keySignature: "11B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/5b5meXqcdbUGA1QZjKAqM3"
    }
  },
  {
    id: "7f55c573-7e71-4d0d-92d8-2d1d85dac28c",
    title: "Vacant",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2730ae87790ea11eb558645c87f",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 365,
    keySignature: "2B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/609in4htVEhmHLTlLjb25f"
    }
  },
  {
    id: "d889d585-654f-49be-9fc4-1e7f01182e25",
    title: "Night Racer",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273c5e2556621a0155dcc02089a",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 289,
    keySignature: "2B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/4kR8Mz23J5PoKNe9lKy3k9"
    }
  },
  {
    id: "5e726ab9-3048-4e38-913d-c0c0fcfae3f0",
    title: "Lay Low",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b3f3ad710567453c9b46d996",
    // -> Artists: Benjamin, Dyzphoria
    creators: artists.filter(
      (artist) => ["169286b0-b3d4-4829-b080-8ba4d8c471ca", "8a920388-2c50-4330-9f5b-29cd86d85c01"].includes(artist.id)
    ),
    length: 168,
    keySignature: "6B",
    tempo: 160,
    links: {
      spotify: "https://open.spotify.com/track/1Zty9Y9IQPfwjiRvSn0iyt"
    }
  },
  {
    id: "1a47fc9f-943c-47b0-9635-baa07feb1234",
    title: "Raver",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2736acda94d1a5af95883be1578",
    // -> Artist: KAVERN
    creators: artists.filter((artist) => artist.id === "905a1bc8-add0-4ff9-a63a-92c6db6e3a23"),
    length: 197,
    keySignature: "11B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/1wd6OiAJ7KTjvEIYSmuRbx"
    }
  },
  {
    id: "6ada1d6b-da9f-4eac-8c95-fb81c9f0b883",
    title: "Marks of Despair",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273328221a91e22e06b7bf79138",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 227,
    keySignature: "2B",
    tempo: 127,
    links: {
      spotify: "https://open.spotify.com/track/0ZZyMCjd9oBlkKqj4V4YQV"
    }
  },
  
  // - 100 - //
  
  {
    id: "850ce5b8-94de-4ba7-b854-849f641140d1",
    title: "Ascend (Silk Road Assassins Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2732775ec767d4207af21a5a545",
    // -> Artists: Last Japan, AJ Tracey, Silk Road Assassins
    creators: artists.filter(
      (artist) => [
        "9d66c812-5915-49a0-9dd4-7ba3742fba97", "505b7ead-1656-489a-b251-91ee60e2f6da",
        "e43371e3-20c0-4f81-ab86-172e01d1cda9"
      ].includes(artist.id)
    ),
    length: 232,
    keySignature: "5B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3omU9taE18yMpoeLMLOBsz"
    }
  },
  {
    id: "87d60470-4dfe-463e-aca4-6b95b2f48994",
    title: "Soft Spot",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028c4743c90d24f9a7cfb903ad",
    // -> Artists: Pholo, Jato
    creators: artists.filter(
      (artist) => ["59ad7bf8-4667-45b6-96d4-87f70573b936", "b14341ab-3d48-4170-841b-55255e13f3ea"].includes(artist.id)
    ),
    length: 239,
    keySignature: "1B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/55epsKogNbdbgjCC7ny2ZO"
    }
  },
  {
    id: "dc14b2ca-486c-4496-b16d-3b53c4517f27",
    title: "Delusion",
    imageURL: "https://i1.sndcdn.com/artworks-eKhU9Nz6doTnnKOF-yhGQzA-t500x500.jpg",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 210,
    keySignature: "8A",
    tempo: 138.5,
    links: {
      soundCloud: "https://soundcloud.com/kyddiekafka/delusion"
    }
  },
  {
    id: "fc88bfc8-26c5-45d4-a386-5c8773e26b1b",
    title: "Oleander",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02162de6851a2cadabb23be5d3",
    // -> Artist: Resonata
    creators: artists.filter((artist) => artist.id === "c5a025b1-8b72-4c2e-af87-4099a15fae70"),
    length: 227,
    keySignature: "3B",
    tempo: 125,
    links: {
      spotify: "https://open.spotify.com/track/0tGBonRazPYgsSeNMJVDeX"
    }
  },
  {
    id: "59d0f4d6-d840-4c5e-9505-51a5609aec0a",
    title: "FADE",
    imageURL: "https://i.scdn.co/image/ab67616d00001e021c4af85ac938e93018a6ada2",
    // -> Artist: STAHL
    creators: artists.filter((artist) => artist.id === "50b5d998-682b-42af-91ec-c34122977bcf"),
    length: 151,
    keySignature: "1B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/1LrVEYnPA35LB0ZvMayIb9"
    }
  },
  {
    id: "354bf6d2-a102-4bc1-8115-2d189d02b5a8",
    title: "Sentient",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d054b04c6704a63edb066989",
    // -> Artist: iSorin
    creators: artists.filter((artist) => artist.id === "0adf3528-a9fd-4331-afdc-b46da832d82b"),
    length: 209,
    keySignature: "5B",
    tempo: 116,
    links: {
      spotify: "https://open.spotify.com/track/5FyECBwwMSODumc3tAEjqs"
    }
  },
  {
    id: "e32c984f-21c3-465f-aa52-79d6d7a5396d",
    title: "neolaws",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02049bcd345661685cb812407a",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 214,
    keySignature: "12B",
    tempo: 126,
    links: {
      spotify: "https://open.spotify.com/track/2WZv8ZzWqyMStW1rGMvx09"
    }
  },
  {
    id: "3ef3e69b-bd39-448d-922d-2f195f1bd78b",
    title: "Super GT",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2731cc4d16606bdc2bdc3497f50",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 228,
    keySignature: "8B",
    tempo: 118,
    links: {
      spotify: "https://open.spotify.com/track/4BrOjd6eC8AgJB9uBSoqXg"
    }
  },
  {
    id: "bac74603-1b0e-4518-8411-848854288cb8",
    title: "Coves",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273ef65e22585378cebbed6e977",
    // -> Artist: Harukasuka
    creators: artists.filter((artist) => artist.id === "9dcd8cba-6112-432f-93b2-47b2b1fcf203"),
    length: 213,
    keySignature: "7B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/67LKQzCUIdGSIPzJZk7iYi"
    }
  },
  {
    id: "3df6e445-0ac2-4a70-b600-13b999e38b47",
    title: "Tel Aviv",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27362dc6924237145ff14f70e3d",
    // -> Artist: Skeler
    creators: artists.filter((artist) => artist.id === "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22"),
    length: 217,
    keySignature: "2B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/45KsTd0ZqKlgw0fNc8oyC5"
    }
  },

  // - 110 - //

  {
    id: "fee8c11c-e878-456e-8221-9cb710bf78c7",
    title: "WhatWasThat",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2735818dc5e188bafbf89a66349",
    // -> Artist: Bones
    creators: artists.filter((artist) => artist.id === "ad323d56-10f6-4fdb-a2d2-8a594f39015f"),
    length: 144,
    keySignature: "8B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/5RVUXuZ0YzLggdkbblcxhw"
    }
  },
  {
    id: "542f7842-cf0d-44b5-869e-f04aef1b1893",
    title: "midnight.",
    imageURL: "https://i1.sndcdn.com/artworks-000342375831-i6li8o-t500x500.jpg",
    // -> Artist: Hyperforms
    creators: artists.filter((artist) => artist.id === "aa1ea277-75b1-45e0-aef8-3fda501c0334"),
    length: 185,
    keySignature: "12B",
    tempo: 136,
    links: {
      soundCloud: "https://soundcloud.com/lstcs/midnight"
    }
  },
  {
    id: "75d08432-29f4-4592-ad6f-2d8f45a171e2",
    title: "Embrace",
    imageURL: "https://i1.sndcdn.com/artworks-000382810866-xec7io-t500x500.jpg",
    // -> Artist: iSorin
    creators: artists.filter((artist) => artist.id === "0adf3528-a9fd-4331-afdc-b46da832d82b"),
    length: 240,
    keySignature: "12A",
    tempo: 143.5,
    links: {
      soundCloud: "https://soundcloud.com/restrictedcollective/isorin-embrace-restricted-collective"
    }
  },
  {
    id: "90d50db6-3161-4982-a88d-3919d2c1f120",
    title: "NIBIRU",
    imageURL: "https://i1.sndcdn.com/artworks-000376291563-3bpq6h-t500x500.jpg",
    // -> Artists: Ludicy, NVTE
    creators: artists.filter(
      (artist) => ["624f7975-c678-48eb-9f85-34dc11a58082", "9f569ccb-d5b3-45c6-ab4b-0f672f136beb"].includes(artist.id)
    ),
    length: 175,
    keySignature: "10A",
    tempo: 118,
    links: {
      soundCloud: "https://soundcloud.com/ludicysounds/nibiru-wnvte"
    }
  },
  {
    id: "d7d3df5c-967f-4305-9d4e-4ec183c31955",
    title: "SCAN (WARDUB)",
    // -> Artist: Anxxiety
    creators: artists.filter((artist) => artist.id === "19d67143-aba7-4f76-887a-0e9c5b8cd6f3"),
    length: 156,
    keySignature: "8A",
    tempo: 150,
    links: {
      soundCloud: "https://soundcloud.com/anxxiety-crypt/scan-wardub-s2-n-hiraethwillixmemphis"
    }
  },
  {
    id: "712d242b-30ef-49bd-a29c-a5a790a0c3c3",
    title: "Iridescence",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0255fbdefe0a7db9010162c610",
    // -> Artists: Kareful, Deadcrow
    creators: artists.filter(
      (artist) => ["ca5623c9-4268-47f0-8569-8a562e4e9bbe", "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"].includes(artist.id)
    ),
    length: 197,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2Yx12YosywOA4VDGVwYFUh"
    }
  },
  {
    id: "aab8e095-4651-4650-866b-56f2661d6c68",
    title: "Canon",
    imageURL: "https://i1.sndcdn.com/artworks-000119119146-8xomn5-t500x500.jpg",
    // -> Artist: MVEJIMV
    creators: artists.filter((artist) => artist.id === "9d235bf1-8e03-44f4-b0a3-e7af8e0b92b4"),
    length: 295,
    keySignature: "2B",
    tempo: 109,
    links: {
      soundCloud: "https://soundcloud.com/mvejimv/canon"
    }
  },
  {
    id: "c651a451-8c25-4700-ab67-e700d8be2ac1",
    title: "forever again",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273d409fd702aeae4ead205c1d0",
    // -> Artists: Enjoii, Juche
    creators: artists.filter(
      (artist) => ["d2bc4672-f7cb-4ebd-a25e-9b78048a8b0f", "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"].includes(artist.id)
    ),
    length: 226,
    keySignature: "7B",
    tempo: 129,
    links: {
      spotify: "https://open.spotify.com/track/0alu2SecGsnQF6gFcp02qQ"
    }
  },
  {
    id: "abdf1b1c-e83c-4dff-ae2a-453e30f29343",
    title: "Fall Apart",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c751ec994777d93ea6d84749",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 284,
    keySignature: "11B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1VNYE6dZEBMiMreTtpcbVa"
    }
  },
  {
    id: "cef1ad5f-f4b4-4d58-ab39-a9a55d3b5e2b",
    title: "Alone",
    imageURL: "https://i1.sndcdn.com/artworks-000436270770-l4oom0-t500x500.jpg",
    // -> Artists: w/Out, nights2050
    creators: artists.filter(
      (artist) => ["75c02207-5bcc-4377-bf1b-f25e0b9d1579", "1dcc693e-9b9a-4d35-94a3-51b877fb2617"].includes(artist.id)
    ),
    length: 191,
    keySignature: "10A",
    tempo: 151,
    links: {
      soundCloud: "https://soundcloud.com/waveform991/wout-x-nights-alone"
    }
  },

  // - 120 - //

  {
    id: "2108ea5c-6ff2-4d65-af9e-3e0a39f46d6d",
    title: "...It Goes On",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2730c6feee97e0597dc9ba30c55",
    // -> Artist: Dyzphoria
    creators: artists.filter((artist) => artist.id === "169286b0-b3d4-4829-b080-8ba4d8c471ca"),
    length: 274,
    keySignature: "4B",
    tempo: 152,
    links: {
      spotify: "https://open.spotify.com/track/7b7nwSf1awiHmZtRP23oqh"
    }
  },
  {
    id: "33289b3f-4342-497b-bcfc-135701a9425a",
    title: "Deep South",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273a3875c03a6d9d85f1def4cc6",
    // -> Artist: Vacant
    creators: artists.filter((artist) => artist.id === "125105d9-775a-429c-ab9e-04f12f440cdc"),
    length: 247,
    keySignature: "4B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/7oMFpfiP2K4frK1rhjMC2H"
    }
  },
  {
    id: "a7cf527a-9e89-4bec-899a-18dbd1d1cd4a",
    title: "Over You",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273a3875c03a6d9d85f1def4cc6",
    // -> Artist: Vacant
    creators: artists.filter((artist) => artist.id === "125105d9-775a-429c-ab9e-04f12f440cdc"),
    length: 324,
    keySignature: "4B",
    tempo: 138,
    links: {
      spotify: "https://open.spotify.com/track/1mMVFewaGBo5wPaamAoZU6"
    }
  },
  {
    id: "0480cf33-24ab-48a8-a89e-8b8b276e7347",
    title: "The Threads",
    imageURL: "https://i1.sndcdn.com/artworks-000463392135-yqok5s-t500x500.jpg",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 260,
    keySignature: "12A",
    tempo: 90.5,
    links: {
      soundCloud: "https://soundcloud.com/yumecollective/sorsari-the-threads-yume"
    }
  },
  {
    id: "53c2a401-53ee-4f9a-beeb-40380fadf40f",
    title: "It Is What It Is",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2738458f01ead08e958faa24284",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 312,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5kwltTpGoYh6dUme4vQ9ZG"
    }
  },
  // {
  //   id: "0ee073fb-efcc-45fb-897f-404272a37d74",
  //   title: "Kiss It Better (gl00my Remix)",
  //   // -> Artists: Sombra, gl00my
  //   creators: artists.filter(
  //     (artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "92e72cec-603f-4622-a8f7-147b99bba410"].includes(artist.id)
  //   ),
  //   length: 185,
  //   keySignature: "6B",
  //   tempo: 124,
  //   links: {
  //     soundCloud: "https://soundcloud.com/gloomysounds/sombra-kiss-it-better-gl00my-remix"
  //   }
  // },
  {
    id: "a6cdf851-b47d-49b9-8998-80dcdf02cd76",
    title: "The Secrets We Keep",
    imageURL: "https://i1.sndcdn.com/artworks-000606571993-tt9wr6-t500x500.jpg",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 280,
    keySignature: "10A",
    tempo: 97.8,
    links: {
      soundCloud: "https://soundcloud.com/mrkryl/thesecretswekeep"
    }
  },
  {
    id: "ecfc34ba-e130-4079-85c3-9534359f974b",
    title: "In Your Arms",
    imageURL: "https://i1.sndcdn.com/artworks-000548604144-fdm6j2-t500x500.jpg",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 288,
    keySignature: "8B",
    tempo: 140,
    links: {
      soundCloud: "https://soundcloud.com/mrkryl/inyourarms"
    },
  },
  {
    id: "f1389b52-b743-46b7-adff-94eeeb20f9c4",
    title: "Rewinding",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027ba0d75632184eac4f645561",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 351,
    keySignature: "7B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/6wGsPpvZm3TWfBbTeGof6l"
    }
  },
  {
    id: "2f061fb3-86b6-4230-95d4-93ab9d823380",
    title: "I Won't",
    imageURL: "https://i1.sndcdn.com/artworks-000515779944-r7f7qm-t500x500.jpg",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 209,
    keySignature: "5B",
    tempo: 110,
    links: {
      soundCloud: "https://soundcloud.com/mrkryl/iwont",
    },
  },

  // - 130 - //

  {
    id: "1d6cd77c-f04e-4416-80dd-62f18a4ae970",
    title: "Through the Night",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02990e55746f9d2ff22aea61cb",
    // -> Artists: MRKRYL, Sorsari
    creators: artists.filter(
      (artist) => ["f0073e5b-c23a-4add-a29b-c5000e4c96d6", "ef839db3-ae41-4af9-9078-a8d211089962"].includes(artist.id)
    ),
    length: 256,
    keySignature: "8B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1msfHXYmHnFXWvLl0gAiNG",
    },
  },
  {
    id: "7f541429-33c9-41a0-86cf-ff0ae3de139a",
    title: "Say It Again But Sound Sure",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b6e0da836950a68a9a21e417",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 274,
    keySignature: "4B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/43cTfy5CKUrk4lXyj4vHLs",
    },
  },
  {
    id: "0849e0b0-1874-4899-91f0-9c1ba227b03b",
    title: "Chain Reaction",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a8bd0d64ae8fd5fbd3f746b7",
    // -> Artist: iSorin
    creators: artists.filter((artist) => artist.id === "0adf3528-a9fd-4331-afdc-b46da832d82b"),
    length: 212,
    keySignature: "1B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/2vDnhB7LBPJXuBRbMRQSns",
    },
  },
  {
    id: "b90f9849-efc8-4fd4-9f9e-2be652687621",
    title: "Laminar Flow",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0234ef1ebd4f5d6cada16b38a7",
    creators: artists.filter((artist) => artist.id === "0adf3528-a9fd-4331-afdc-b46da832d82b"),
    length: 219,
    keySignature: "1B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5MK9JOR1mUYawP3315nwff",
    },
  },
  {
    id:  "0d420245-2fde-4bfd-a068-ae1ed2b5d986",
    title: "Pit Viper (MRKRYL Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026fd3ba3b4d54d7f61fcd7cfc",
    // -> Artists: Pictureplane, MRKRYL
    creators: artists.filter(
      (artist) => ["fef5be9d-6223-43cb-a3db-e6048a070fec", "0d420245-2fde-4bfd-a068-ae1ed2b5d986"].includes(artist.id)
    ),
    length: 320,
    keySignature: "12B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/3TNmb3tu9EwPL73AQbecBt"
    }
  },
  {
    id: "fcee0572-525c-40b5-9950-40c0f1c0407b",
    title: "Again",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a4c5bd2fcf0f815cbd1df271",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 279,
    keySignature: "9B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/4nGrNKff7nwjaGjd02uawE",
    },
  },
  {
    id: "962c7646-5739-43f9-9a31-ec2f24ffa0a9",
    title: "Tell Me",
    imageURL: "https://i.scdn.co/image/ab67616d00001e025d9c3eeccdb408ba7732d6d1",
    // -> Artist: KAVERN
    creators: artists.filter((artist) => artist.id === "905a1bc8-add0-4ff9-a63a-92c6db6e3a23"),
    length: 180,
    keySignature: "3B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/4wFEpYXapSluZPYjgha5S9",
    },
  },
  {
    id: "216b3218-5826-4083-9738-759ccd2d1eae",
    title: "Flashing Before My Eyes",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cfdf39efd4dfb4053173d119",
    // -> Artist: Fyoomz
    creators: artists.filter((artist) => artist.id === "195d1986-f79a-44d1-a36c-42634a2c1df0"),
    length: 226,
    keySignature: "6B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/6ckNtrRLPK3J4XTOoAKUYa",
    },
  },
  {
    id: "a2e9fe01-9b1a-4111-afa4-fbe08ce62714",
    title: "The Freedom Area",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0270615f3d359c985de0273ed6",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 236,
    keySignature: "9B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/2CIQ6X83rBDBU4vUERNaKd"
    }
  },
  {
    id: "94eaea02-cc3e-4de1-8479-ac98954f74b6",
    title: "Cartel",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d8906b0e1fc8daae7d1af11d",
    // -> Artist: Boy Racers
    creators: artists.filter((artist) => artist.id === "271c33ac-1a6e-41df-832e-d92408099fb1"),
    length: 249,
    keySignature: "3B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/0mbRZr0IGbdOagC7chHhvo"
    }
  },

  // - 140 - //

  {
    id: "9cfa8fb0-6d1c-42ff-8e75-a158b9cf02da",
    title: "In My Mind",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273a01feb05b1c3743443f888a0",
    // -> Artists: Skeler, Dynoro, Gigi D'agostino
    creators: artists.filter(
      (artist) => [
        "79a99efb-56ca-44a0-aaaf-b46cc5ecd7ee", "6e539d91-1a05-4d53-bd14-256097e6093b",
        "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22"
      ].includes(artist.id)
    ),
    length: 186,
    keySignature: "3B",
    tempo: 126,
    links: {
      spotify: "https://open.spotify.com/track/7bgm7BtKzJTW2ViBkX4abh"
    }
  },
  {
    id: "4ddb3054-3525-4993-8f3e-72fe0a1a1f29",
    title: "Diamond Pier",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026a281037a4da8750d8fccd54",
    // -> Artists: TIME94, ROT10
    creators: artists.filter(
      (artist) => ["0cfb1578-49fb-486b-b72c-dbfb1568e351", "684388b2-a83e-4159-84fd-f1f169829073"].includes(artist.id)
    ),
    length: 211,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2stnreHoQMEfczriraO5t8"
    }
  },
  {
    id: "c52b45d0-0944-4d47-ab1e-a5db94a36252",
    title: "Neonboy (Spaceouters Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0246c6c7e7804f2d1d2ec3cd77",
    // -> Artists: Sibewest, Spaceouters
    creators: artists.filter(
      (artist) => ["96187786-5425-4a35-b8b0-e9a5f98702ad", "ded31fda-f7f9-41a2-ac63-3c1506c61efe"].includes(artist.id)
    ),
    length: 323,
    keySignature: "3B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/75XtRkPK1j3z99wgIPNi5x"
    }
  },
  {
    id: "2552fd59-2850-43d3-90bf-a94262264e77",
    title: "New Lands",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0283ae9197b437c5b963b0e9e3",
    // -> Artist: Tim Schaufert
    creators: artists.filter((artist) => artist.id === "4d5d7dc9-4e9d-4aa9-b9cd-947abece3157"),
    length: 207,
    keySignature: "6B",
    tempo: 122,
    links: {
      spotify: "https://open.spotify.com/track/6edlobhItCQuPJkWWZdmnG"
    }
  },
  {
    id: "b103a480-579e-4218-ab89-020873aed0cd",
    title: "idontwannabeyouanymore (Nayd Flip)",
    imageURL: "https://i1.sndcdn.com/artworks-000519954600-r0i3e8-t500x500.jpg",
    // -> Artists: Billie Eilish, Nayd
    creators: artists.filter(
      (artist) => ["9f7de473-cf96-4e5a-af12-e2975480466f", "7a561dec-9a42-4002-a125-10d51195f3e6"].includes(artist.id)
    ),
    length: 130,
    keySignature: "10A",
    tempo: 167.5,
    links: {
      soundCloud: "https://soundcloud.com/naydmusic/idontwannabeyouanymore"
    }
  },
  {
    id: "45545237-c564-4ec8-ba88-c7ba78e73293",
    title: "Mantle",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273f8374d25594fc2c12d3d4190",
    // -> Artist: Giiant Ibis
    creators: artists.filter((artist) => artist.id === "2777e990-49e8-4a79-8440-89f213ecc411"),
    length: 260,
    keySignature: "2B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/60w9keoABmlsXly49t2MNj"
    }
  },
  {
    id: "190c5dc5-6608-464b-9303-c8891a312217",
    title: "Breaking Boundaries",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27313419eb829a8ff04af1693b9",
    // -> Artist: F.O.O.L
    creators: artists.filter((artist) => artist.id === "f28b66a9-200d-412c-ac72-3a84c458898e"),
    length: 181,
    keySignature: "3B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/7wFUytz6bf2VhISuWBJE8Y"
    }
  },
  {
    id: "42a03ce3-0eaa-458b-86d9-dae7da335f93",
    title: "ICED",
    imageURL: "https://i1.sndcdn.com/artworks-000297918183-u0mqi6-t500x500.jpg",
    // -> Artist: STAHL
    creators: artists.filter((artist) => artist.id === "50b5d998-682b-42af-91ec-c34122977bcf"),
    length: 152,
    keySignature: "10A",
    tempo: 120.2,
    links: {
      soundCloud: "https://soundcloud.com/stahlsound/iced"
    }
  },
  {
    id: "a6401dc6-90ac-4fac-a965-fa75bd42cfea",
    title: "Somebody Else's (Sorsari VIP)",
    imageURL: "https://i1.sndcdn.com/artworks-000552960456-dup7q0-t500x500.jpg",
    // -> Artists: MRKRYL, Sorsari
    creators: artists.filter(
      (artist) => ["f0073e5b-c23a-4add-a29b-c5000e4c96d6", "ef839db3-ae41-4af9-9078-a8d211089962"].includes(artist.id)
    ),
    length: 261,
    keySignature: "6A",
    tempo: 155,
    links: {
      spotify: "https://soundcloud.com/sorsari/mrkryl-sorsari-somebody-elses-sorsari-vip"
    }
  },
  {
    id: "7beaad01-3118-4470-a969-8d3c2b2b145d",
    title: "Swift",
    imageURL: "https://i1.sndcdn.com/artworks-000555790908-vai1xx-t500x500.jpg",
    // -> Artist: AL_NE
    creators: artists.filter((artist) => artist.id === "f58a5991-c161-49a1-9464-6b705ed2ef74"),
    length: 188,
    keySignature: "8A",
    tempo: 146.7,
    links: {
      soundCloud: "https://soundcloud.com/al_ne/swift"
    }
  },

  // - 150 - //

  {
    id: "78cc0319-fd19-4423-a93a-57032a1fc2f7",
    title: "Alone",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02544610fe9f7dabd5e1423fbf",
    // -> Artist: AL_NE
    creators: artists.filter((artist) => artist.id === "f58a5991-c161-49a1-9464-6b705ed2ef74"),
    length: 192,
    keySignature: "5A",
    tempo: 97.5,
    links: {
      spotify: "https://open.spotify.com/track/6Xf2s5hqZqVBZvZgoO0tB3?si=b417244eec4549d8"
    }
  },
  {
    id: "4057bc61-f0f4-474e-bc6e-aa30284194ca",
    title: "Wanna B With U",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ea45000b3e64500aeceea752",
    // -> Artist: Vexxy
    creators: artists.filter((artist) => artist.id === "aa8507e3-e295-442d-af3c-7036e9ef44aa"),
    length: 201,
    keySignature: "9B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/4mzlYUQ3yZmkC0wo55LdFy?si=4d2ef71990da49b7"
    }
  },
  {
    id: "79c3a775-dbfc-4ec1-97f6-e947ef1efb4e",
    title: "Can't Come Down",
    imageURL: "https://i1.sndcdn.com/artworks-000413394420-ode3m0-t500x500.jpg",
    // -> Artist: Jato
    creators: artists.filter((artist) => artist.id === "b14341ab-3d48-4170-841b-55255e13f3ea"),
    length: 181,
    keySignature: "5A",
    tempo: 131.5,
    links: {
      soundCloud: "https://soundcloud.com/jatobeats/cant-come-dwn"
    }
  },
  {
    id: "863674ab-0aaf-496d-8a7f-2f32c736abef",
    title: "ULTRAVIOLET",
    imageURL: "https://i1.sndcdn.com/artworks-000413394924-4lclec-t500x500.jpg",
    // -> Artist: Jato
    creators: artists.filter((artist) => artist.id === "b14341ab-3d48-4170-841b-55255e13f3ea"),
    length: 247,
    keySignature: "5A",
    tempo: 95,
    links: {
      soundCloud: "https://soundcloud.com/jatobeats/ultraviolet"
    }
  },
  // - TODO: -> Discrepancy between two bpm/keysig algorithms for this data, investigate further.
  {
    id: "b238364e-096c-4800-8e35-12ec336f30af",
    title: "In Lov3",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026bd7a79ffd0ded312ff5147c",
    // -> Artist: Torai
    creators: artists.filter((artist) => artist.id === "0c64d208-8400-4679-b98b-a905cbedb9b6"),
    length: 188,
    keySignature: "2A",
    tempo: 96,
    links: {
      spotify: "https://open.spotify.com/track/4hsHaPR3h46h7IuOpxdgEZ"
    }
  },
  {
    id: "6481f3aa-d46d-4c3d-9971-f4b8e435936d",
    title: "Liff",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026bd7a79ffd0ded312ff5147c",
    // -> Artist: Toka
    creators: artists.filter((artist) => artist.id === "86987cf5-db81-455a-8310-11143edef563"),
    length: 297,
    keySignature: "1B",
    tempo: 133,
    links: {
      spotify: "https://open.spotify.com/track/2B3rukQptaD2DCqxPxlBcP"
    }
  },
  {
    id: "f4501b5d-f818-4b25-94f3-22acb92beae8",
    title: "Illicit",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026bd7a79ffd0ded312ff5147c",
    // -> Artist: Circa
    creators: artists.filter((artist) => artist.id === "68a55c22-73c0-4de1-93c6-86b9cc363f06"),
    length: 192,
    keySignature: "1B",
    tempo: 143,
    links: {
      spotify: "https://open.spotify.com/track/5B5hEqWXkOvXqqNsUgQ4CU"
    }
  },
  {
    id: "6e0c32e3-e062-4abc-9315-e5e13ba53ccb",
    title: "Momentum",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e18908930687ebeb520dfed8",
    // -> Artist: BlazinG
    creators: artists.filter((artist) => artist.id === "b8927930-9542-40b3-8a0c-e90507a53102"),
    length: 173,
    keySignature: "8B",
    tempo: 144,
    links: {
      spotify: "https://open.spotify.com/track/1Mxfhqj1wG3f0nLYfi5lSm?si=128b66b085254c67"
    }
  },
  {
    id: "c240a09d-9347-4332-a83c-f9a2bc69622b",
    title: "Duality",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02470010aa2171845381a09b1c",
    // -> Artist: REMNANT.exe
    creators: artists.filter((artist) => artist.id === "08353957-64fa-45de-b3f1-f390bd03f433"),
    length: 310,
    keySignature: "1B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/6RQJwpYEEpqkvqYzi8Ds2a"
    }
  },
  {
    id: "bc6fadb2-2df4-486c-a1e3-4db32d555d9d",
    title: "Torrent",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02df9305feddb4a6f816db6974",
    // -> Artist: REMNANT.exe
    creators: artists.filter((artist) => artist.id === "08353957-64fa-45de-b3f1-f390bd03f433"),
    length: 267,
    keySignature: "7B",
    tempo: 99,
    links: {
      spotify: "https://open.spotify.com/track/11jJEyehXdNEMLIU4LKDcp"
    }
  },

  // - 160 - //

  {
    id: "3d41824b-5ed0-4017-a55f-a8e29456b290",
    title: "Phantom Feelings",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02248215074d06c01ef819dca4",
    // -> Artists: Palm Fires, w/out
    creators: artists.filter(
      (artist) => ["6eff3e12-1cee-47a6-9335-7694bf8148fe", "75c02207-5bcc-4377-bf1b-f25e0b9d1579"].includes(artist.id)
    ),
    length: 188,
    keySignature: "5B",
    tempo: 126,
    links: {
      spotify: "https://open.spotify.com/track/1hYzPSPtCSNprvXhiwM9hp"
    }
  },
  {
    id: "b3293473-ba8d-4fa0-94a9-645cbeadce0e",
    title: "Ocean Eyes (Arael x Hyperforms Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02db34328be786e4c9d0008201",
    // -> Artists: Billie Eilish, Arael, Hyperforms
    creators: artists.filter(
      (artist) => [
        "9f7de473-cf96-4e5a-af12-e2975480466f", "d0e601e3-1869-4634-a6c8-4436df00baaa",
        "aa1ea277-75b1-45e0-aef8-3fda501c0334"
      ].includes(artist.id)
    ),
    length: 226,
    keySignature: "2B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/5vcclSdw54TVzR2QHeFipn"
    }
  },
  {
    id: "13d70f47-e3a7-476d-aacb-cfd20f896d64",
    title: "Seven Seals",
    imageURL: "https://i1.sndcdn.com/artworks-000135823483-qv3vhj-t500x500.jpg",
    // -> Artists: cvrl, Øfdream
    creators: artists.filter(
      (artist) => ["0a967ce4-e926-4e17-a987-4dd08a0a0ce8", "e1c0dc8e-ef6f-4be8-b544-f59c78595481"].includes(artist.id)
    ),
    length: 212,
    keySignature: "4A",
    tempo: 109.8,
    links: {
      soundCloud: "https://soundcloud.com/carlmmmusic/sevenseals"
    }
  },
  {
    id: "bff1350f-2cd9-41dc-ae8b-8217f2201ef6",
    title: "Aeternalove",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0246ff42368b9a5f5b46159569",
    // -> Artists: Imer6ia, Ofdream
    creators: artists.filter(
      (artist) => ["db18fc83-643c-4f03-aea9-83456cbc045c", "e1c0dc8e-ef6f-4be8-b544-f59c78595481"].includes(artist.id)
    ),
    length: 240,
    keySignature: "1B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/21DAYo08qg2CXp06ahHKbC"
    }
  },
  {
    id: "7fa046ed-9432-4574-8585-6d7e60d8d70c",
    title: "Drift",
    imageURL: "https://i1.sndcdn.com/artworks-000576872213-pf981e-t500x500.jpg",
    // -> Artist: Tigereyes
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 211,
    keySignature: "10A",
    tempo: 96.2,
    links: {
      soundCloud: "https://soundcloud.com/sekai-collective/tigereyes-drift"
    }
  },
  {
    id: "c7ed2ebe-e168-422b-a886-2ef33564f436",
    title: "lalala",
    imageURL: "https://i1.sndcdn.com/artworks-000577129250-y2x0iq-t500x500.jpg",
    // -> Artist: Calicry
    creators: artists.filter((artist) => artist.id === "5bb2ec42-87be-4fd1-8c9f-3fb94f58991f"),
    length: 251,
    keySignature: "8A",
    tempo: 93.5,
    links: {
      soundCloud: "https://soundcloud.com/sekai-collective/c-a-l-i-c-r-y-lalala"
    }
  },
  {
    id: "bc9bbfc1-6448-49dc-91b2-fe840a53c9f4",
    title: "Out of Time (feat. Goldy)",
    imageURL: "https://i1.sndcdn.com/artworks-m6VhnDHIGlGVLDTW-yWeGEg-t500x500.jpg",
    // -> Artists: METAHESH, Goldy
    creators: artists.filter(
      (artist) => ["f587554a-2e53-4997-93a7-c1480c56d88f", "03aea4de-1def-48f3-b9cc-6bdb0855b879"].includes(artist.id)
    ),
    length: 186,
    keySignature: "8A",
    tempo: 92.25,
    links: {
      soundCloud: "https://soundcloud.com/metahesh/out-of-time-w-goldy"
    }
  },
  {
    id: "fce19dda-9d45-42f5-9647-7a26955f8d59",
    title: "Drone",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02df6b15bcb5f990310083ea42",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 288,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4u7Opvrv0wWdqMwoQ1O3pC"
    }
  },
  {
    id: "7bf776f5-52db-4f50-956b-dd9acbfd74f6",
    title: "Promise",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0234955220e59db5b95de2d9ef",
    // -> Artist: Dyzphoria
    creators: artists.filter((artist) => artist.id === "169286b0-b3d4-4829-b080-8ba4d8c471ca"),
    length: 177,
    keySignature: "10B",
    tempo: 151,
    links: {
      spotify: "https://open.spotify.com/track/5xb8DcYjeCJ5YuOSGfokNU"
    }
  },
  {
    id: "8a613264-2fed-4a35-9ac7-e68f3f644504",
    title: "Lost",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0220277fe2fb41bc805353c822",
    // -> Artist: REMNANT.exe
    creators: artists.filter((artist) => artist.id === "08353957-64fa-45de-b3f1-f390bd03f433"),
    length: 310,
    keySignature: "9B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/6HpHyynAbz3EK8Ln2hn8SZ"
    }
  },

  // - 170 - //

  {
    id: "53a74ca1-9125-4db4-b635-5fc0d7329443",
    title: "No Options",
    imageURL: "https://i1.sndcdn.com/artworks-000474550449-okklid-t500x500.jpg",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 209,
    keySignature: "8A",
    tempo: 123.7,
    links: {
      soundCloud: "https://soundcloud.com/kyddiekafka/no-options"
    }
  },
  {
    id: "a16e43ba-9c8a-4894-bfa2-4528b95115de",
    title: "If You Only",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0280d3dcc9e028037bea4c8d30",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 206,
    keySignature: "2B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/1EytPqsNuzeseja54PmlVK"
    }
  },
  {
    id: "7cea13ba-b23d-4b53-908c-1e58936db559",
    title: "Grime",
    imageURL: "https://i1.sndcdn.com/artworks-000612295801-sqzhx2-t500x500.jpg",
    // -> Artists: Flowdan, Fyoomz
    creators: artists.filter(
      (artist) => ["87ebd021-a13d-44bc-a350-bf36f0e6eeb6", "195d1986-f79a-44d1-a36c-42634a2c1df0"].includes(artist.id)
    ),
    length: 144,
    keySignature: "2A",
    tempo: 98.5,
    links: {
      soundCloud: "https://soundcloud.com/fyoomz/flowdan-grime-fyoomz-remix"
    }
  },
  {
    id: "ea5a3f5a-a60d-47a4-a7bb-0bd0050840ae",
    title: "Obsessed",
    imageURL: "https://i1.sndcdn.com/artworks-000526687182-b7xzer-t500x500.jpg",
    // -> Artist: Vexxy
    creators: artists.filter((artist) => artist.id === "aa8507e3-e295-442d-af3c-7036e9ef44aa"),
    length: 222,
    keySignature: "3A",
    tempo: 148,
    links: {
      soundCloud: "https://soundcloud.com/vexxymusic/obsessed-free-download"
    }
  },
  {
    id: "455935d7-44ea-46da-bab1-783a780e1665",
    title: "Oh (feat. Ludacris) (Fyoomz Remix)",
    imageURL: "https://i1.sndcdn.com/artworks-000620785483-pz4wnm-t500x500.jpg",
    // -> Artists: Ciara, Ludacris, Fyoomz
    creators: artists.filter(
      (artist) => [
        "2d93f4f9-3162-48c5-8ba2-ec1124d4f2b2", "b52354b0-861c-445b-8588-a6def0f640c1",
        "195d1986-f79a-44d1-a36c-42634a2c1df0"
      ].includes(artist.id)
    ),
    length: 188,
    keySignature: "5A",
    tempo: 98,
    links: {
      soundCloud: "https://soundcloud.com/fyoomz/ciara-oh-feat-ludacris-fyoomz-remix"
    }
  },
  {
    id: "706e707e-5a99-445b-b6b8-31b0a5d76299",
    title: "Coven",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0267e139235cbe04eb50714d65",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 193,
    keySignature: "6B",
    tempo: 144,
    links: {
      spotify: "https://open.spotify.com/track/1xYTPjMYLvLAOxjlnCtEKk"
    }
  },
  {
    id: "82ac3fbb-86bb-4d6b-9765-2fa3c7a72f6c",
    title: "Mental Loop",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2736c502121cd65561e91efe6ff",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 228,
    keySignature: "7B",
    tempo: 118,
    links: {
      spotify: "https://open.spotify.com/track/7EovzLbZJ3mHcJ3239ytln"
    }
  },
  {
    id: "e89a0fce-a4e3-4651-8989-c07e9b126411",
    title: "Blueprint",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273d35367f34afa76f6cafbec80",
    // -> Artists: Resonata, w/out
    creators: artists.filter(
      (artist) => ["c5a025b1-8b72-4c2e-af87-4099a15fae70", "75c02207-5bcc-4377-bf1b-f25e0b9d1579"].includes(artist.id)
    ),
    length: 243,
    keySignature: "2B",
    tempo: 95,
    links: {
      spotify: "https://open.spotify.com/track/7ibXaRhrdvr0lktkK1p9V0"
    }
  },
  {
    id: "0cfbc6bb-a80d-4236-a9d0-10572d4dd65f",
    title: "Apart",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cbed46a6fa0dde9b94f0463e",
    // -> Artist: Vacant
    creators: artists.filter((artist) => artist.id === "125105d9-775a-429c-ab9e-04f12f440cdc"),
    length: 228,
    keySignature: "1B",
    tempo: 133,
    links: {
      spotify: "https://open.spotify.com/track/4mJjs2cYHqKo7L54qfTLO1"
    }
  },
  {
    id: "fa04578f-3c2a-475a-8ca7-c58cafc59c26",
    title: "Even More",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02407f2df24bd122b42cd7a7c0",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 222,
    keySignature: "6B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/1IFpT9fY2UCZHW392zgCEs"
    }
  },

  // - 180 - //
  {
    id: "3a7c521d-6383-419a-aadd-b77542677e3e",
    title: "Human (Gladkill Remix)",
    imageURL: "https://i1.sndcdn.com/artworks-000488969568-9cyyll-t500x500.jpg",
    // -> Artists: Sevdaliza, Gladkill
    creators: artists.filter(
      (artist) => ["916d713c-6280-4621-8d8d-b67c9374093f", "6953b6f9-d944-4383-95c2-a9e6228a6da9"].includes(artist.id)
    ),
    length: 274,
    keySignature: "7A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 100,
    links: {
      soundCloud: "https://soundcloud.com/gladkill/human"
    }
  },
  {
    id: "1a4108a0-3285-47a2-99f7-9cf5d7d65aed",
    title: "LUVIN ME",
    imageURL: "https://i1.sndcdn.com/artworks-000631560328-wfmn0q-t500x500.jpg",
    // -> Artist: Pholo
    creators: artists.filter((artist) => artist.id === "59ad7bf8-4667-45b6-96d4-87f70573b936"),
    length: 253,
    keySignature: "3A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 96.2,
    links: {
      soundCloud: "https://soundcloud.com/pholouk/luvinme"
    },
  },
  {
    id: "450619df-210b-49d7-90b6-5f7a19f0eba5",
    title: "Neon Glow",
    imageURL: "https://i1.sndcdn.com/artworks-000672279676-8fphjz-t500x500.jpg",
    // -> Artist: Calicry
    creators: artists.filter((artist) => artist.id === "5bb2ec42-87be-4fd1-8c9f-3fb94f58991f"),
    length: 223,
    keySignature: "7A",
    tempo: 110,
    links: {
      soundCloud: "https://soundcloud.com/calicry/neon-glow"
    },
  },
  {
    id: "d2f350d8-4ede-451c-bedc-0f0b1ab965eb",
    title: "Unreality",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0284ae4f3c96e4d3e6b6403080",
    // -> Artist: MYSTXRIVL
    creators: artists.filter((artist) => artist.id === "c7f75b69-5a29-4f60-a486-ace3354d3df6"),
    length: 216,
    keySignature: "8B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/0Q50jjSRPjZz0mQNbiPJmc"
    }
  },
  {
    id: "a96a0ea1-0d33-4a97-a749-d031e234c0a6",
    title: "never say goodbye",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ff5330766f5b755b3ee7346b",
    // -> Artist: Enjoii
    creators: artists.filter((artist) => artist.id === "d2bc4672-f7cb-4ebd-a25e-9b78048a8b0f"),
    length: 181,
    keySignature: "5B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/7qss1vfkxeWVMDJ1LyLvHK"
    }
  },
  {
    id: "52c2a813-99c6-4c3c-9230-8b59204cb3ee",
    title: "M0lly",
    imageURL: "",
    // -> Artist: Ytho
    creators: artists.filter((artist) => artist.id === "a5fa3d99-781f-4468-bc2e-5ecd371992a6"),
    length: 199,
    keySignature: "3B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/2Q91BvogTapSGfnWmVlg6M"
    }
  },
  {
    id: "fde504ce-979f-4ae5-8d7c-cc55e2225bd6",
    title: "Burning",
    imageURL: "https://i.scdn.co/image/ab67616d00001e025c2a2d21e40450a35cbc7270",
    // -> Artist: Tokyo Tears
    creators: artists.filter((artist) => artist.id === "cdb87c9e-1acd-4665-8cc1-7104bf876362"),
    length: 222,
    keySignature: "9B",
    tempo: 124,
    links: {
      spotify: "https://open.spotify.com/track/0OxWxvVdMdRB81rnnxEmqK"
    }
  },
  {
    id: "858486c3-8961-404e-b742-8f0bc31afffe",
    title: "All the Things She Said",
    imageURL: "https://i1.sndcdn.com/artworks-5xKNhwR5qpOJ3NO1-BoNzDw-t500x500.jpg",
    // -> Artists: Pexøt, BVXSEEK
    creators: artists.filter(
      (artist) => ["b1ad3961-37db-4c22-9a2d-12b1960b118e", "f5dd2a16-aea4-4f53-9140-a7c4fdc9c255"].includes(artist.id)
    ),
    length: 243,
    keySignature: "11A",
    tempo: 150,
    links: {
      soundCloud: "https://soundcloud.com/p-vg-ot/pexot-x-bvxseek-all-the-things-she-said"
    }
  },
  {
    id: "5b610807-f613-40cd-a686-f9a9bdf7180c",
    title: "Reload",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0244114a42183e58ac04201416",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 285,
    keySignature: "1B",
    tempo: 138,
    links: {
      spotify: "https://open.spotify.com/track/0QPF7HFemmBJMrIOcWwoMi"
    }
  },
  {
    id: "df6efdd1-1015-47e3-a18c-885b3b0df96d",
    title: "Pale Light",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028aa40981ec8f7f1abe1aaaff",
    // -> Artist: Skeler
    creators: artists.filter((artist) => artist.id === "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22"),
    length: 225,
    keySignature: "10B",
    tempo: 124,
    links: {
      spotify: "https://open.spotify.com/track/64F0tid5vwapfuC4ERAHyA"
    }
  },

  // - 190 - //

  {
    id: "6a7d564a-7787-4375-9669-a4ab3f23386b",
    title: "Desolate",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e3928eeeed5e267325e7405",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 318,
    keySignature: "1B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/33bAtsOdZvHZxDdn3WIYUV"
    }
  },
  {
    id: "ef6e6ccd-b971-4133-b8cf-e833e14810a2",
    title: "Dive",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e3928eeeed5e267325e7405",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 291,
    keySignature: "10B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/3DlguMx1Ya2nPrzpnDkGj2",
    }
  },
  {
    id: "bed0e946-9cb0-4963-a0c0-7ac4b035937c",
    title: "Dreams",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e3928eeeed5e267325e7405",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 288,
    keySignature: "4B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1G08Ywi9tuZm5ieiZZpDN2"
    }
  },
  {
    id: "0ee0c292-3abb-4787-b277-00d83a41d4b3",
    title: "The Faceless",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e3928eeeed5e267325e7405",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 245,
    keySignature: "4B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/3iMjUBDE7N8ggSEAShghgS"
    }
  },
  {
    id: "701ae535-d512-480c-9dc2-935b0e76601d",
    title: "City of Tears (Pholo Edit)",
    imageURL: "https://i1.sndcdn.com/artworks-pLLS3eEB5uUSyfnM-znSO3A-t500x500.jpg",
    // -> Artist: Dark0, Pholo
    creators: artists.filter(
      (artist) => ["6b78d40f-35c5-49f0-899e-7d448c3178d5", "59ad7bf8-4667-45b6-96d4-87f70573b936"].includes(artist.id)
    ),
    length: 207,
    keySignature: "4A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 96,
    links: {
      soundCloud: "https://soundcloud.com/pholouk/cityoftears"
    }
  },
  {
    id: "1146e9ad-0033-4685-9a3c-3af12b2b292f",
    title: "Resolution",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0296389068c71315e18b57bcae",
    // -> Artist: Module
    creators: artists.filter((artist) => artist.id === "c89f7b13-1dd1-4847-9dfd-cb8486e7dabc"),
    length: 204,
    keySignature: "8B",
    tempo: 122,
    links: {
      spotify: "https://open.spotify.com/track/08efQYn15ZHZkLpMtgqXbA"
    }
  },
  {
    id: "18048160-260c-4be8-a432-89bbcc1e58f6",
    title: "I Met Her On the Internet",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0234f364f19c6a3a411f49440c",
    // -> Artist: Enjoii
    creators: artists.filter((artist) => artist.id === "d2bc4672-f7cb-4ebd-a25e-9b78048a8b0f"),
    length: 168,
    keySignature: "8B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2k362CweJfZUZr69CMjVUP"
    }
  },
  {
    id: "4c85d213-2952-4a5d-89fa-8b37ef61a185",
    title: "All I Need",
    imageURL: "https://i1.sndcdn.com/artworks-3Y6c1oxVRZa9MJHn-Big8nA-t500x500.jpg",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 390,
    keySignature: "12B",
    tempo: 94.5,
    links: {
      soundCloud: "https://soundcloud.com/slowaverecs/sorsari-all-i-need"
    }
  },
  {
    id: "b61eb270-9843-425a-9e9f-bc172fe7fd41",
    title: "Afterburner",
    imageURL: "https://i1.sndcdn.com/artworks-41jE0PDZBio6kbxC-NAztTg-t500x500.jpg",
    // -> Artist: KAVERN
    creators: artists.filter((artist) => artist.id === "905a1bc8-add0-4ff9-a63a-92c6db6e3a23"),
    length: 113,
    keySignature: "3A",
    tempo: 159,
    links: {
      soundCloud: "https://soundcloud.com/kavern-beats/afterburner"
    }
  },
  {
    id: "386371b6-8379-4389-9286-5af3ec3281fe",
    title: "Twisted",
    imageURL: "https://i1.sndcdn.com/artworks-2Ac2pw9zjKvkmmvB-0J0uMg-t500x500.jpg",
    // -> Artists: Pholo, Vexxy
    creators: artists.filter(
      (artist) => ["59ad7bf8-4667-45b6-96d4-87f70573b936", "aa8507e3-e295-442d-af3c-7036e9ef44aa"].includes(artist.id)
    ),
    length: 243,
    keySignature: "6A",
    tempo: 97.3,
    links: {
      soundCloud: "https://soundcloud.com/esclabel/pholo-vexxy-twisted-free-download"
    }
  },

  // - 200 - //

  {
    id: "7a76613a-a40e-4c6c-b3da-fef5c1fcac6b",
    title: "BABYYY",
    imageURL: "https://i1.sndcdn.com/artworks-qBaYMUJ1ra4kkhnl-OTJIaA-t500x500.jpg",
    // -> Artist: NVRLUV
    creators: artists.filter((artist) => artist.id === "fe8f6f47-18fb-451b-a5d7-9914aa029bca"),
    length: 138,
    keySignature: "12A",
    tempo: 94,
    links: {
      spotify: "https://soundcloud.com/nvrluv/baby"
    }
  },
  {
    id: "323b61de-28a6-4a27-97bf-e81f41390769",
    title: "She Kept My Heart",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029f67ac958f98be54fc4368fd",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 226,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3ibskhaN6PdPjqYGq6kMtq"
    }
  },
  {
    id: "1fab2610-71ab-49f2-9e97-de43bbd045dd",
    title: "Flight",
    imageURL: "https://i1.sndcdn.com/artworks-17SyoupVWOfaQGWG-DRkRcg-t500x500.jpg",
    // -> Artist: KAVERN
    creators: artists.filter((artist) => artist.id === "905a1bc8-add0-4ff9-a63a-92c6db6e3a23"),
    length: 244,
    keySignature: "4A",
    tempo: 94.25,
    links: {
      soundCloud: "https://soundcloud.com/kavern-beats/flight"
    }
  },
  {
    id: "a8ef0fb7-18c7-4306-b1f8-1d86d757a18d",
    title: "FULL CONTROL",
    imageURL: "https://i1.sndcdn.com/artworks-pYYXN8qPlEl4fked-KuMTlA-t500x500.jpg",
    // -> Artist: Skeler
    creators: artists.filter((artist) => artist.id === "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22"),
    length: 155,
    keySignature: "4A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 90.5,
    links: {
      soundCloud: "https://soundcloud.com/skelermusic/full-control-1"
    }
  },
  {
    id: "e81aa2b8-8ca6-419b-8008-7ecc12fd28bb",
    title: "Time Lapse",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026ffe019f956ebdaefb322b07",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 191,
    keySignature: "6B",
    tempo: 151,
    links: {
      spotify: "https://open.spotify.com/track/2CO6zld3nOnNQmY5um0wbS"
    }
  },
  {
    id: "a7005041-e5a8-4ba6-bfbd-07663c8f0116",
    title: "Spyware",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02497f3fc556b4d011b4b8e7d8",
    // -> Artist: gl00my
    creators: artists.filter((artist) => artist.id === "483a9c78-cbfa-4b7c-8494-48e2e8631846"),
    length: 178,
    keySignature: "7B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/52BaatA2Hji0FSf0StUJtn"
    }
  },
  {
    id: "55b3e997-1cdb-4c16-babb-2beddaa3a77b",
    title: "Kitsune No Kami",
    imageURL: "https://i1.sndcdn.com/artworks-000086951329-adicwh-t500x500.jpg",
    // -> Artist: CRÆTION
    creators: artists.filter((artist) => artist.id === "71b698f5-4840-4657-9599-7c627d2ef374"),
    length: 160,
    keySignature: "4B",
    tempo: 93.5,
    links: {
      soundCloud: "https://soundcloud.com/somicomusic/craetion-kitsune-no-kami"
    }
  },
  {
    id: "bff0de5d-d072-486f-b8ad-a9c53e49dd21",
    title: "swallow",
    imageURL: "https://i1.sndcdn.com/artworks-000078653770-1druw6-t500x500.jpg",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 196,
    keySignature: "10A",
    tempo: 73.5,
    links: {
      soundCloud: "https://soundcloud.com/fox-wedding/s-w-a-l-l-o-w"
    }
  },
  {
    id: "87872b22-e3e7-4bb6-94d2-75e718b8b643",
    title: "DEADCELL",
    imageURL: "https://i1.sndcdn.com/artworks-000190159198-90dmnl-t500x500.jpg",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 197,
    keySignature: "2A",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 171,
    links: {
      soundCloud: "https://soundcloud.com/silkroadassassins/d-e-a-d-c-e-l-l"
    }
  },
  {
    id: "6b1aae3f-e289-41c7-b7e1-2d98a6bd566c",
    title: "Mea Culpa",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02489eaf58d751cf575c23f8a5",
    // -> Artist: Resonata
    creators: artists.filter((artist) => artist.id === "c5a025b1-8b72-4c2e-af87-4099a15fae70"),
    length: 236,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/0ENcecxmFQOHo7aLPl6riI"
    }
  },

  // - 210 - //

  {
    id: "d94fd7c1-262c-4651-99d8-3401ac559472",
    title: "How I Killed My Love",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0224b8cf34be269e24f11041bc",
    // -> Artist: The Øbsidian
    creators: artists.filter((artist) => artist.id === "bd6727fc-1ca8-4146-8430-7c2b72f1843b"),
    length: 213,
    keySignature: "12B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 180,
    links: {
      spotify: "https://open.spotify.com/track/7fkH49OaqdQBGwBM6sKju1"
    }
  },
  {
    id: "25d50fa0-d270-4d3c-a5f1-4a0cb1a6cb7b",
    title: "Love Algorithm",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273c8b1aaeebf6118a5a442dc97",
    // -> Artist: The Øbsidian
    creators: artists.filter((artist) => artist.id === "bd6727fc-1ca8-4146-8430-7c2b72f1843b"),
    length: 265,
    keySignature: "1B",
    tempo: 155,
    links: {
      spotify: "https://open.spotify.com/track/7tYGd8hmDZZkjgFSshdXJH"
    }
  },
  {
    id: "706b2316-53ea-47a7-9f2f-80b7559cb0d2",
    title: "Caprize",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273c8b1aaeebf6118a5a442dc97",
    // -> Artist: The Obsidian
    creators: artists.filter((artist) => artist.id === "bd6727fc-1ca8-4146-8430-7c2b72f1843b"),
    length: 255,
    keySignature: "12B",
    tempo: 113,
    links: {
      spotify: "https://open.spotify.com/track/75cFpVLSdRgcAM7bZHcYFL"
    }
  },
  {
    id: "042f111f-5e3f-4b57-951c-a6e43dd2ec0e",
    title: "Sonic Anxiety (Yama Rebirth)",
    imageURL: "https://i1.sndcdn.com/artworks-HUzt5nmB6DhZQzD3-3jq74w-t500x500.jpg",
    // -> Artists: Plastician, Yama
    creators: artists.filter((artist) => ["0e3a1efe-123f-4b11-9d80-aa4946991c80", "f7fafa9f-1ebe-4798-a8fc-cc42f7480b23"].includes(artist.id)),
    length: 274,
    keySignature: "9A",
    tempo: 91.65,
    links: {
      soundCloud: "https://soundcloud.com/yamadubs/sonic-anxiety-plastician-yama-rebirth-v3"
    }
  },
  {
    id: "53a154d0-56f8-4ac9-a095-fa3fba64dd1b",
    title: "Eclipse",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02db302b79894a9b472ba4bc8f",
    // -> Artists: Synge, dotethereal
    creators: artists.filter((artist) => ["8e80a356-6ee8-4be9-ba05-0f26e0f495ed", "7a6a7839-3335-4af4-bccf-b27b5a919ca1"].includes(artist.id)),
    length: 182,
    keySignature: "8B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/3en79n74UpMFUdL4wZH6z7"
    }
  },
  {
    id: "4e6dfd05-c74a-426c-9018-e2eb305e7516",
    title: "419",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02dc4a5bba9698d158e09edbfc",
    // -> Artist: Mannequin
    creators: artists.filter((artist) => artist.id === "5c63804d-531c-43d7-866a-58de5b67f5e4"),
    length: 307,
    keySignature: "7B",
    tempo: 104,
    links: {
      spotify: "https://open.spotify.com/track/2zaBgbQvSlzvE9rbko3A8c"
    }
  },
  {
    id: "aacbc155-3b9c-4d8e-938b-c1cba5455f34",
    title: "Cynthia",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0224b6b783506ec2d2ffe2e936",
    // -> Artists: NVRLUV, Mannequin
    creators: artists.filter((artist) => ["fe8f6f47-18fb-451b-a5d7-9914aa029bca", "5c63804d-531c-43d7-866a-58de5b67f5e4"].includes(artist.id)),
    length: 192,
    keySignature: "9B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/5uklv8Eew0sXSywU8KNdnN"
    }
  },
  {
    id: "f6887982-a34c-4c06-bb4b-52ab0387047e",
    title: "dodo",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0210092710ec94ebc06596cc6c",
    // -> Artist: Mannequin
    creators: artists.filter((artist) => artist.id === "5c63804d-531c-43d7-866a-58de5b67f5e4"),
    length: 231,
    keySignature: "8B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/48Ix9jFvEkgsRmsy08p1Za"
    }
  },
  {
    id: "7aef61a7-7a19-4b2a-bc51-1187e429fb55",
    title: "Unwary",
    imageURL: "https://i.scdn.co/image/ab67616d00001e021d362f361f47c98e4ddc310c",
    // -> Artist: Jook
    creators: artists.filter((artist) => artist.id === "c0fec759-afa3-414b-93e4-251014470a03"),
    length: 281,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/20E5nW9sOopDQl8QtVA19C"
    }
  },
  {
    id: "3d1b0932-f929-4f60-aceb-03ab078bca48",
    title: "Say Anything",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273489eaf58d751cf575c23f8a5",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 216,
    keySignature: "3B",
    tempo: 160,
    links: {
      spotify: "https://open.spotify.com/track/2UrCrHzm5sLP9pHPnorWgO"
    }
  },

  // - 220 - //

  {
    id: "0e509944-b5af-4338-a06d-a2e5677b5348",
    title: "STARGAZING",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022e0fe6b43f06d8ffc4874c72",
    // -> Artist: Travis Scott, Artonoise
    creators: artists.filter((artist) => ["8bdba741-43a0-4b28-a94d-bd34f45e9846", "598dfd8b-d9ed-429e-87a9-31e1c207293b"].includes(artist.id)),
    length: 214,
    keySignature: "6B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/6lar3kIiwgsODM245rXMm9"
    }
  },
  {
    id: "a82245c9-ccf5-47b8-8301-d4d2c895778b",
    title: "Keep My Cool",
    imageURL: "https://i.scdn.co/image/b26e6916ec7f3ff3c0bdadacbc55ecc54d74260b",
    // -> Artists: gl00my, Vexxy
    creators: artists.filter((artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "aa8507e3-e295-442d-af3c-7036e9ef44aa"].includes(artist.id)),
    length: 219,
    keySignature: "12B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/47XIg9zK8jMyZE4XmgXiUn",
    }
  },
  {
    id: "e8b90475-431d-4b63-861a-50b60192b01b",
    title: "Now U See Me",
    imageURL: "https://i1.sndcdn.com/artworks-000600681897-djxm5p-t500x500.jpg",
    // -> Artist: gl00my
    creators: artists.filter((artist) => artist.id === "483a9c78-cbfa-4b7c-8494-48e2e8631846"),
    length: 198,
    keySignature: "11A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 92.5,
    links: {
      soundCloud: "https://soundcloud.com/gloomysounds/now-u-see-me"
    }
  },
  {
    id: "6734ff6b-707c-4fcd-8e1f-9e2fb14f5cc5",
    title: "Eastside",
    imageURL: "https://i1.sndcdn.com/artworks-iYJs3uMSIbQ2HUlc-pRy5vw-t500x500.jpg",
    // -> Artists: Holly Stell, Essex
    creators: artists.filter((artist) => ["e0806ec3-f19f-4d72-8c54-c22e4bdb26a1", "aa9daf5a-7c46-4db8-9845-ecd9d823d82d"].includes(artist.id)),
    length: 263,
    keySignature: "9A",
    tempo: 94.7,
    links: {
      soundCloud: "https://soundcloud.com/holly_stell/eastside-prod-by-essex"
    }
  },
  {
    id: "5086edea-60c5-478b-9699-bcdbfd390819",
    title: "CYBERWARE",
    imageURL: "https://i1.sndcdn.com/artworks-Y3HHQ05Yg0hRAmyU-6NYENw-t500x500.jpg",
    // -> Artist: Error
    creators: artists.filter((artist) => artist.id === "05542823-e9a2-445d-9836-314477493c9c"),
    length: 220,
    keySignature: "6A",
    tempo: 176,
    links: {
      soundCloud: "https://soundcloud.com/errxrmusic/cyberware"
    }
  },
  {
    id: "8539d04c-07e5-49b3-b203-4b3d44ab15cf",
    title: "With You",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026a19825222b717ff55d4c314",
    // -> Artist: Yedgar
    creators: artists.filter((artist) => artist.id === "42d3afdd-cb05-4d6d-ac80-f83a94a326e3"),
    length: 200,
    keySignature: "3B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/33nGXmfwJqBA6Ethd3mtLc"
    }
  },
  {
    id: "32fb8794-3b6e-4ae6-8f53-59a91bebfb2a",
    title: "Indistinct",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ad2b7110bfa68699edafbc44",
    // -> Artist: MVEJIMV
    creators: artists.filter((artist) => artist.id === "9d235bf1-8e03-44f4-b0a3-e7af8e0b92b4"),
    length: 208,
    keySignature: "2B",
    tempo: 94,
    links: {
      spotify: "https://open.spotify.com/track/1A7rE2KQhA4S7cRykamxqV"
    }
  },
  {
    id: "0532d706-3a79-437f-b7b4-e9017e1d07f6",
    title: "Horrors of Loneliness",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02fa6becdab97f7dce81ecff0a",
    // -> Artist: kim
    creators: artists.filter((artist) => artist.id === "9c535188-b27d-4a20-8ea4-0586e1a7c1e9"),
    length: 162,
    keySignature: "10B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/1o7AVrju2izRFKooBJVyOc"
    }
  },
  {
    id: "11750416-34f3-48e6-abf0-618ac3dde3ea",
    title: "Zippo",
    imageURL: "https://i1.sndcdn.com/artworks-WqYLJgzpLzt16Js1-kxdjkg-t500x500.jpg",
    // -> Artist: Mannequin
    creators: artists.filter((artist) => artist.id === "5c63804d-531c-43d7-866a-58de5b67f5e4"),
    length: 144,
    keySignature: "11A",
    tempo: 99.25,
    links: {
      soundCloud: "https://soundcloud.com/roadwrx/mannequin-zippo"
    }
  },
  {
    id: "476c10d6-7a39-45e7-8f73-6ea04a8a1d2e",
    title: "First Encounter",
    imageURL: "https://i1.sndcdn.com/artworks-WqYLJgzpLzt16Js1-kxdjkg-t500x500.jpg",
    // -> Artist: Fyoomz
    creators: artists.filter((artist) => artist.id === "195d1986-f79a-44d1-a36c-42634a2c1df0"),
    length: 154,
    keySignature: "9A",
    tempo: 95,
    links: {
      soundCloud: "https://soundcloud.com/roadwrx/fyoomz-first-encounter"
    }
  },

  // - 230 - //

  {
    id: "5e19f5fb-3e1e-48a0-9ab5-86f73efa23bb",
    title: "IN MY MIND",
    imageURL: "https://i1.sndcdn.com/artworks-000380976912-91g468-t500x500.jpg",
    // -> Artist: Error
    creators: artists.filter((artist) => artist.id === "05542823-e9a2-445d-9836-314477493c9c"),
    length: 238,
    keySignature: "8A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 74.2,
    links: {
      soundCloud: "https://soundcloud.com/errxrmusic/in-my-mind"
    }
  },
  {
    id: "9c950f39-ff88-4c72-9bf0-d88afdbcdd21",
    title: "Pour It Down the Drain",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a5dde1ad3446055b073a32e0",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 237,
    keySignature: "7B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/51WIbePwKQKVqioarUj3On"
    }
  },
  {
    id: "09999476-5370-4e0c-8448-f5baa4b10ece",
    title: "Rare Type",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02697e245f0e170d932d5c979f",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 152,
    keySignature: "6B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/37xrO5FQ4xARZe4tX4S2XB"
    }
  },
  {
    id: "6c751af0-9b86-4ac9-ae5a-40fa1172f831",
    title: "Money Can't Stop Karma",
    imageURL: "https://i1.sndcdn.com/artworks-000611922094-cxntmz-t500x500.jpg",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 108,
    keySignature: "9A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 96,
    links: {
      soundCloud: "https://soundcloud.com/jmingo/mngo-money-cant-stop-karma"
    }
  },
  {
    id: "21ded010-38da-422c-815b-06b9a7b437fe",
    title: "BLADE",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02bc25a5b86b5a8f4c3103108d",
    // -> Artist: Jato
    creators: artists.filter((artist) => artist.id === "b14341ab-3d48-4170-841b-55255e13f3ea"),
    length: 215,
    keySignature: "12B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/4R4oLGViHMMgL9gyDIWIRR"
    }
  },
  {
    id: "23f8a0ba-8575-42b2-9966-b1442e9d8970",
    title: "LOVE TWO",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02328dd362fd35eddaa08c0d11",
    // -> Artist: Jato
    creators: artists.filter((artist) => artist.id === "b14341ab-3d48-4170-841b-55255e13f3ea"),
    length: 250,
    keySignature: "2B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/6uSJLqe9gQPIY06azOc9mH"
    }
  },
  {
    id: "8353dfd1-adc4-484f-84e6-450697f3388e",
    title: "AFTERPARTY",
    imageURL: "https://i1.sndcdn.com/artworks-000413395767-vvp8ob-t500x500.jpg",
    // -> Artists: Ariana Grande, Jato
    creators: artists.filter((artist) => ["044d21b8-024e-40cb-8f8a-32111dacf4cc", "b14341ab-3d48-4170-841b-55255e13f3ea"].includes(artist.id)),
    length: 193,
    keySignature: "9B",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 93.2,
    links: {
      soundCloud: "https://soundcloud.com/jatobeats/afterparty-2"
    }
  },
  {
    id: "52d3ea43-aec3-498b-9027-6cf33b13d12f",
    title: "EVERYTIME",
    imageURL: "https://i1.sndcdn.com/artworks-000423066288-z8yap7-t500x500.jpg",
    // -> Artists: Ariana Grande, Jato
    creators: artists.filter((artist) => ["044d21b8-024e-40cb-8f8a-32111dacf4cc", "b14341ab-3d48-4170-841b-55255e13f3ea"].includes(artist.id)),
    length: 184,
    keySignature: "10A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 92.2,
    links: {
      soundCloud: "https://soundcloud.com/jatobeats/ariana-grande-everytime-jato-remix"
    }
  },
  {
    id: "61bf6cc5-8232-4c38-96a4-f49b1f07b37f",
    title: "Faxing Berlin (Fyoomz Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ed364b6828739dd13243feaa",
    // -> Artists: Deadmau5, Fyoomz
    creators: artists.filter((artist) => ["7ca7e8b4-910a-42bd-8693-82192114ec88", "195d1986-f79a-44d1-a36c-42634a2c1df0"].includes(artist.id)),
    length: 255,
    keySignature: "9B",
    tempo: 132,
    links: {
      spotify: "https://open.spotify.com/track/1x8Binnp7g1U41qxk4eb2S"
    }
  },
  {
    id: "ae66b477-6e64-4162-99cb-caa62e3ee006",
    title: "Us and Them (Barnacle Boi x Brothel Remix)",
    imageURL: "https://i1.sndcdn.com/artworks-2zESGyqgFMECZ2TJ-aVTKvA-t500x500.jpg",
    // -> Artists: Derek Pope, barnacle boi, Brothel
    creators: artists.filter(
      (artist) => [
        "24c29208-60ef-4dde-943d-dc3c22fe9169", "774c9e44-6ade-4698-9466-5219c669035f",
        "0ad1f780-0e60-4fde-a1c3-c3808671292f"
      ].includes(artist.id)
    ),
    length: 167,
    keySignature: "7A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 157,
    links: {
      soundCloud: "https://soundcloud.com/barnacleboi777/derek-pope-us-and-them-barnacle-boi-x-brothel-flip"
    }
  },

  // - 240 - //

  {
    id: "18c8098d-65a4-44db-9985-b7ead4254253",
    title: "Splinter In Your Mind",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ed364b6828739dd13243feaa",
    // -> Artist: Fyoomz
    creators: artists.filter((artist) => artist.id === "195d1986-f79a-44d1-a36c-42634a2c1df0"),
    length: 302,
    keySignature: "12B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5LvXLJnoqmC9bgHokQGIMK"
    }
  },
  {
    id: "35d88f6d-fa2c-4853-bc2c-8d121f0afd16",
    title: "Impossible",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0270faff80d362ec006d5f1939",
    // -> Artists: Dxxdly, Lovewithme
    creators: artists.filter((artist) => ["545ec973-0780-4529-ba30-83ababd46840", "d0cb8c08-e74b-430c-8054-52c1c99dd6c8"].includes(artist.id)),
    length: 261,
    keySignature: "4B",
    tempo: 125,
    links: {
      spotify: "https://open.spotify.com/track/1Vg6hedPjb5YSsLHAbOhBS"
    }
  },
  {
    id: "11fa4a88-ec2f-4785-963a-6ac28adde06d",
    title: "Heartless (Where Has Our Love Gone)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b01b1c94991b2006df2e2606",
    // -> Artist: Nick Neutronz
    creators: artists.filter((artist) => artist.id === "f913a669-71bf-48f7-94cc-4d9278d4f7de"),
    length: 176,
    keySignature: "10B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/6qtvSkM18XopWjn6tSaSDV"
    }
  },
  {
    id: "7fe6c2b7-d5d3-4f38-9a38-d987c09c50b2",
    title: "Introspective",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023a42d4f5d6a6eb3578e7357b",
    // -> Artist: Five Suns
    creators: artists.filter((artist) => artist.id === "cf86ac9a-861e-4ca5-930e-8b2ca003ea38"),
    length: 228,
    keySignature: "2B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5kHkn1L2wwYPMiCBPsWu30"
    }
  },
  {
    id: "cb7b2b07-4224-4430-bf81-a45a44ebc57e",
    title: "Tied to You",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0206095168507265b2cbfa9278",
    // -> Artist: NVRLUV
    creators: artists.filter((artist) => artist.id === "fe8f6f47-18fb-451b-a5d7-9914aa029bca"),
    length: 140,
    keySignature: "11B",
    tempo: 144,
    links: {
      spotify: "https://open.spotify.com/track/4ZzMROoJLAxhb2yslIQ7mF"
    }
  },
  {
    id: "5d5eeb31-e0d0-4c7f-b8c9-820d76e45f10",
    title: "Differences",
    imageURL: "https://i1.sndcdn.com/artworks-000266168177-wnecx7-t500x500.jpg",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 173,
    keySignature: "1A",
    tempo: 94.7,
    links: {
      soundCloud: "https://soundcloud.com/sorsari/differences-free-download"
    }
  },
  {
    id: "3924283f-81c5-4f04-a816-1144338198c4",
    title: "In Txuch",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0206095168507265b2cbfa9278",
    // -> Artist: NVRLUV
    creators: artists.filter((artist) => artist.id === "fe8f6f47-18fb-451b-a5d7-9914aa029bca"),
    length: 208,
    keySignature: "4B",
    tempo: 119,
    links: {
      spotify: "https://open.spotify.com/track/55YMxd6swUSTnRyxnEBjw8"
    }
  },
  {
    id: "08049f7a-b061-4c51-8097-17597ca888cf",
    title: "With(out) You",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0206095168507265b2cbfa9278",
    // -> Artist: NVRLUV
    creators: artists.filter((artist) => artist.id === "fe8f6f47-18fb-451b-a5d7-9914aa029bca"),
    length: 244,
    keySignature: "1B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/5sOLJJLhTi7pXCJlQ5lZqj"
    }
  },
  {
    id: "29c3bf35-e1bf-4405-b86f-d4869185bba4",
    title: "Guiding Light",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ed364b6828739dd13243feaa",
    // -> Artists: Fyoomz, Djedi
    creators: artists.filter((artist) => ["195d1986-f79a-44d1-a36c-42634a2c1df0", "03dc31b6-213f-4b29-ae1c-0813c5e7b695"].includes(artist.id)),
    length: 199,
    keySignature: "6B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2tMBcLMv3XyJE91ktLjPK5"
    }
  },
  {
    id: "c477f7d5-0bbe-4383-91dd-a41548464e7e",
    title: "VPN (feat. Palmistry) (Silk Road Assassins Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0251881d65ac9f6ffa847a473c",
    // -> Artists: Mr. Mitch, Palmistry, Silk Road Assassins
    creators: artists.filter(
      (artist) => [
        "d8419db5-abb6-41ed-9019-0fc80d0a46c1", "6d1deeb2-29eb-405a-8d64-7ecbc02d47b6",
        "e43371e3-20c0-4f81-ab86-172e01d1cda9"
      ].includes(artist.id)
    ),
    length: 288,
    keySignature: "4B",
    tempo: 90,
    links: {
      spotify: "https://open.spotify.com/track/6nx6nrk8VfzGYtawk58SrY"
    }
  },

  // - 250 - //

  {
    id: "39734a94-ff3e-47bf-abe7-f9e45e1ce305",
    title: "Hymn",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b29ca323f6ddadb2d880f46e",
    // -> Artists: Forever, Chemist
    creators: artists.filter((artist) => ["05e91f75-c077-43e5-bfde-dfa79043929b", "0881c3ee-96b0-4e2c-8b62-eb50aacbc854"].includes(artist.id)),
    length: 177,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/6FYgKkLH1p0wVeqtvHR8IS"
    }
  },
  {
    id: "2cf6c9b9-dc73-4171-91a0-c2e82d254609",
    title: "Fugitive",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cc22b1d9baaf47ea674a73cb",
    // -> Artist: Tom E. Vercetti
    creators: artists.filter((artist) => artist.id === "fb096d5d-0e2f-46c5-a3f8-273806a8f7e9"),
    length: 214,
    keySignature: "6B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/2Wh7cT1e48Z3TuyK7mQpr2"
    }
  },
  {
    id: "5cc14f23-5480-43c5-9b80-f2552ac7c035",
    title: "Young (Calicry Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273a4c498e7fa06d1d39678cdd3",
    // -> Artists: Tako Tomago, Forerunner, Calicry
    creators: artists.filter(
      (artist) => [
        "ed153518-8c3d-44b7-a436-64b4186f08fa", "d74e0c82-dd78-44e2-b72c-7d254058e4bb",
        "5bb2ec42-87be-4fd1-8c9f-3fb94f58991f",
      ].includes(artist.id)
    ),
    length: 224,
    keySignature: "4B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/76uoEKA3XHTHP1hmorrj5y"
    }
  },
  {
    id: "4eb52b70-5f5d-4c01-989e-ff6f27f463f1",
    title: "I'll Fly With U",
    imageURL: "https://i1.sndcdn.com/artworks-7drrsxIAZrRutVeL-uwRk1g-t500x500.jpg",
    // -> Artist: Yedgar
    creators: artists.filter((artist) => artist.id === "42d3afdd-cb05-4d6d-ac80-f83a94a326e3"),
    length: 224,
    keySignature: "5A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 98,
    links: {
      soundCloud: "https://soundcloud.com/yedgar/lamour-toujours"
    }
  },
  {
    id: "0ca64253-cca9-42ea-b129-814bf9abc5f4",
    title: "Magnetic Hills",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022bb2c3610033e98da3c3ed0f",
    // -> Artist: Desi Jae
    creators: artists.filter((artist) => artist.id === "189feb0c-f061-4584-84f9-fef576a94972"),
    length: 229,
    keySignature: "10B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/6957bPBenXcdGjDlGOTcjx"
    }
  },
  {
    id: "966150d9-092e-4007-a6cf-bf09a9924e60",
    title: "Miami to Tampa Bay (Casual Death Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a4c498e7fa06d1d39678cdd3",
    // -> Artists: Tako Tomago, Casual Death
    creators: artists.filter((artist) => ["ed153518-8c3d-44b7-a436-64b4186f08fa", "1bfeeb3b-a05a-459b-98aa-aa7a8493373e"].includes(artist.id)),
    length: 240,
    keySignature: "1B",
    tempo: 125,
    links: {
      spotify: "https://open.spotify.com/track/6aoLt8FTQiggXniCrigvex"
    }
  },
  {
    id: "496d0df2-f1c1-42a0-93d1-691bf4753e7d",
    title: "Lunar",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c65bebd6528db77f21f16fc9",
    // -> Artists: Jake Robertz, Sorsari
    creators: artists.filter((artist) => ["f2e4ba6b-9fbc-4dcb-bad6-341e21f1e32c", "ef839db3-ae41-4af9-9078-a8d211089962"].includes(artist.id)),
    length: 264,
    keySignature: "3B",
    tempo: 174,
    links: {
      spotify: "https://open.spotify.com/track/49LgyQFqL0btazSJPnOwO6"
    }
  },
  {
    id: "82886d1e-6197-4e53-b43c-afc6a3890fbf",
    title: "LUST",
    imageURL: "https://i1.sndcdn.com/artworks-000660919318-wfakqu-t500x500.jpg",
    // -> Artist: Pholo
    creators: artists.filter((artist) => artist.id === "59ad7bf8-4667-45b6-96d4-87f70573b936"),
    length: 229,
    keySignature: "1B",
    tempo: 141.5,
    links: {
      soundCloud: "https://soundcloud.com/pholouk/lust"
    }
  },
  {
    id: "3fe32e51-0b11-45cd-9850-9722ea01e784",
    title: "KEYCHAIN (feat. Jato)",
    imageURL: "https://i1.sndcdn.com/artworks-QRNkQVmyFYYLLUg1-iVDuGw-t500x500.jpg",
    // -> Artists: gl00my, Jato
    creators: artists.filter((artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "b14341ab-3d48-4170-841b-55255e13f3ea"].includes(artist.id)),
    length: 198,
    keySignature: "2A",
    tempo: 177,
    links: {
      soundCloud: "https://soundcloud.com/yumecollective/gl00my-keychain-w-jato-yume-premiere"
    }
  },
  {
    id: "8d2a5e40-9363-4824-a8a7-a4ea43e5748c",
    title: "NEO-CLUB (feat. Jato)",
    imageURL: "https://i1.sndcdn.com/artworks-QRNkQVmyFYYLLUg1-iVDuGw-t500x500.jpg",
    // -> Artist: gl00my, Jato
    creators: artists.filter((artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "b14341ab-3d48-4170-841b-55255e13f3ea"].includes(artist.id)),
    length: 192,
    keySignature: "6A",
    tempo: 93.5,
    links: {
      soundCloud: "https://soundcloud.com/gloomysounds/neo-club-w-jato"
    }
  },

  // - 260 - //

  {
    id: "5a369570-6c7f-49c1-959a-2c2947b17dda",
    title: "Harca (Tom E. Vercetti)",
    imageURL: "https://i1.sndcdn.com/artworks-000105072069-cwlvra-t500x500.jpg",
    // -> Artists: Last Japan, Tom E. Vercetti
    creators: artists.filter((artist) => ["9d66c812-5915-49a0-9dd4-7ba3742fba97", "fb096d5d-0e2f-46c5-a3f8-273806a8f7e9"].includes(artist.id)),
    length: 314,
    keySignature: "8A",
    tempo: 137.5,
    links: {
      soundCloud: "https://soundcloud.com/factmag/last-japan-harca-tom-e-vercetti-remix"
    }
  },
  {
    id: "6d040aaa-f834-4a52-9f60-bfc8402e22f5",
    title: "omg",
    imageURL: "https://i1.sndcdn.com/artworks-000545092545-cl59nn-t500x500.jpg",
    // -> Artist: Calicry
    creators: artists.filter((artist) => artist.id === "5bb2ec42-87be-4fd1-8c9f-3fb94f58991f"),
    length: 223,
    keySignature: "4A",
    tempo: 145.5,
    links: {
      soundCloud: "https://soundcloud.com/calicry/omg",
    }
  },
  {
    id: "1f83ebe4-b3b9-4e5e-9b05-2023a7f9e6f4",
    title: "Andromeda",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ea7555dc5475296b0675eac8",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 309,
    keySignature: "10B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/6EjlkmSS7ErXuNivilh8C0",
    }
  },
  {
    id: "53f21f7d-88ec-48ae-928a-a4059f3fd0e0",
    title: "FIRST PERSON",
    imageURL: "https://i.scdn.co/image/ab67616d00001e024505f811ee9fb3cd93d47ece",
    // -> Artist: TIME94
    creators: artists.filter((artist) => artist.id === "684388b2-a83e-4159-84fd-f1f169829073"),
    length: 200,
    keySignature: "12B",
    tempo: 70,
    links: {
      spotify: "https://open.spotify.com/track/6JuswQXM3cAC5quSfvFDco"
    }
  },
  {
    id: "ce8cff70-88f8-47f5-9416-67e3d1b3ceb5",
    title: "T (Peace Edit)",
    imageURL: "https://i1.sndcdn.com/artworks-000108615202-5o72pd-t500x500.jpg",
    // -> Artists: Silk Road Assassins, Peace
    creators: artists.filter((artist) => ["e43371e3-20c0-4f81-ab86-172e01d1cda9"].includes(artist.id)),
    length: 279,
    keySignature: "2A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 96.20,
    links: {
      soundCloud: "https://soundcloud.com/gobstopper-records/silk-road-assassins-t"
    }
  },
  {
    id: "ef4e069c-1ef3-4702-a5d1-797e25ef82e7",
    title: "3M Kunai",
    imageURL: "https://i1.sndcdn.com/artworks-000183314535-l51t6d-t500x500.jpg",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 310,
    keySignature: "3A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 97,
    links: {
      soundCloud: "https://soundcloud.com/coyoterecordsuk/silk-road-assassins-3m-kunai"
    }
  },
  {
    id: "cb214c64-7612-4d29-889b-53db48843005",
    title: "BRB",
    imageURL: "https://i1.sndcdn.com/artworks-000556131798-x73hne-t500x500.jpg",
    // -> Artists: K9, Silk Road Assassins
    creators: artists.filter((artist) => ["2a07bbe1-3f47-405b-b94d-833dcfe2998c", "e43371e3-20c0-4f81-ab86-172e01d1cda9"].includes(artist.id)),
    length: 195,
    keySignature: "10A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 93.5,
    links: {
      soundCloud: "https://soundcloud.com/kruddyninez/k9-brb-prod-sra"
    }
  },
  {
    id: "b0a7b92f-554f-4688-818f-edbb49c2458a",
    title: "Moon Shard",
    imageURL: "https://i1.sndcdn.com/artworks-000090417841-k6yamb-t500x500.jpg",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 328,
    keySignature: "2A",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 173.3,
    links: {
      soundCloud: "https://soundcloud.com/silkroadassassins/m-o-o-n-s-h-a-r-d"
    }
  },
  {
    id: "656a2d3b-9148-4f8c-8722-941a11eb0592",
    title: "Black Mantis",
    imageURL: "https://i1.sndcdn.com/artworks-000129030424-b2lk00-t500x500.jpg",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 162,
    keySignature: "7A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 163,
    links: {
      soundCloud: "https://soundcloud.com/silkroadassassins/b-l-c-k-m-n-t-i-s",
    }
  },
  {
    id: "f4625fc8-c359-4414-a36c-e55dd4517051",
    title: "Infinite Plane",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cc22b1d9baaf47ea674a73cb",
    // -> Artist: Tom E. Vercetti
    creators: artists.filter((artist) => artist.id === "fb096d5d-0e2f-46c5-a3f8-273806a8f7e9"),
    length: 282,
    keySignature: "8B",
    tempo: 116,
    links: {
      spotify: "https://open.spotify.com/track/2CKUEUj769E0YsrWMQyuYq"
    }
  },

  // - 270 - //

  {
    id: "ea155c9c-275a-446a-8873-894a6feabead",
    title: "Data Trail",
    imageURL: "https://i1.sndcdn.com/artworks-000170246916-ypod8r-t500x500.jpg",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 130,
    keySignature: "2A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 93.8,
    links: {
      soundCloud: "https://soundcloud.com/silkroadassassins/data-trail"
    }
  },
  {
    id: "be5840de-efd4-4595-b1bc-525c4070c192",
    title: "Caya (M1 Personal Trainer)",
    imageURL: "https://i1.sndcdn.com/artworks-000512758398-i8424u-t500x500.jpg",
    // -> Artist: Mssingno
    creators: artists.filter((artist) => artist.id === "a209cc6d-cc0d-4415-833b-d4b9352b6d4b"),
    length: 195,
    keySignature: "6A",
    tempo: 98,
    links: {
      soundCloud: "https://soundcloud.com/jacklycke/mssingno-caya-m1-personal-trainer"
    }
  },
  {
    id: "92189dbd-24a3-479e-b0ba-71440570ce45",
    title: "Dub Plate File #18 (Ravver Bootleg Extension)",
    imageURL: "https://i1.sndcdn.com/artworks-000205458999-92ma7a-t500x500.jpg",
    // -> Artists: PLATA, Ravver
    creators: artists.filter((artist) => ["25468154-fee6-4149-9c3f-9ebf0ef773db", "b86e14e8-7300-415a-88e8-1e71706910f2"].includes(artist.id)),
    length: 160,
    keySignature: "8A",
    tempo: 149,
    links: {
      soundCloud: "https://soundcloud.com/ravver/plata-dub-plate-file-19-ravver-bootleg-extension"
    }
  },
  {
    id: "20dad197-3afb-4c9e-9aa4-ef9b3ab50384",
    title: "Foreign Import (No MC Edit)",
    imageURL: "https://i1.sndcdn.com/artworks-000468801495-c6osnz-t500x500.jpg",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 134,
    keySignature: "10A",
    tempo: 92.8,
    links: {
      soundCloud: "https://soundcloud.com/unreleasedbeats/silk-road-assassins-foreign-import-no-mc-edit",
    }
  },
  {
    id: "37783b53-6655-4d33-a13a-05555dbc3e93",
    title: "Cardi",
    imageURL: "https://i1.sndcdn.com/artworks-000583391819-eg5pc2-t500x500.jpg",
    // -> Artist: R@
    creators: artists.filter((artist) => artist.id === "14bb42da-2c19-4a29-9fb0-6cf344845866"),
    length: 370,
    keySignature: "2A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 74.5,
    links: {
      soundCloud: "https://soundcloud.com/plasmaabuse/r-cardi",
    }
  },
  {
    id: "fcbff6ef-0ede-45f3-95cc-15d8bc6b33f0",
    title: "Zgijm",
    imageURL: "https://i1.sndcdn.com/artworks-000159089485-i9kjaf-t500x500.jpg",
    // -> Artists: JOCKJOMZ, Pope
    creators: artists.filter((artist) => ["1eeb13eb-a838-4d40-a954-cc71de1ef86a", "33035dfb-332e-4758-aec0-a250c2a6cfa6"].includes(artist.id)),
    length: 185,
    keySignature: "10A",
    tempo: 154,
    links: {
      soundCloud: "https://soundcloud.com/jockjomzworldwide/bae-zgijm-pope-remix"
    }
  },
  {
    id: "55b0812f-5d77-454d-abcb-8533b1305d21",
    title: "No Limits",
    imageURL: "https://i1.sndcdn.com/artworks-LptSIzOfsc8UsaSQ-8hOO5Q-t500x500.jpg",
    // -> Artists: DaniLeigh, Skeler
    creators: artists.filter((artist) => ["283e2031-753f-4ce3-abe9-ba67495211d5", "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22"].includes(artist.id)),
    length: 142,
    keySignature: "12B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 174,
    links: {
      soundCloud: "https://soundcloud.com/skelermusic/danileigh-no-limits-skeler-remix"
    }
  },
  {
    id: "5a2ee3e4-6695-4aaa-b684-53cee212235a",
    title: "Equinox",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27395683aa76db65fa6d3a19761",
    // -> Artists: Planet V, Mannequin
    creators: artists.filter((artist) => ["37d2529d-14b9-4a90-9caf-c024052ffe28", "5c63804d-531c-43d7-866a-58de5b67f5e4"].includes(artist.id)),
    length: 212,
    keySignature: "10B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/69NsaIHZMbp5UGG6rE83ib"
    }
  },
  {
    id: "711e819f-de67-4c2c-a308-7af0889b6d01",
    title: "Step 20 (Lost Tourist Remix)",
    imageURL: "https://i1.sndcdn.com/artworks-000154614171-rfyixn-t500x500.jpg",
    // -> Artists: Wiley, Lost Tourist
    creators: artists.filter((artist) => ["519bcd83-e248-4b20-b03f-757e073dc71f", "a5942e5c-fddb-46e2-b1cd-ebf848d08859"].includes(artist.id)),
    length: 216,
    keySignature: "5A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 98,
    links: {
      soundCloud: "https://soundcloud.com/lostourist/wiley-step-20-lost-tourist-remixx",
    }
  },
  {
    id: "32c397ad-4a1d-4e89-ab89-1c4f975d8f5d",
    title: "VEDMA (feat. Enjoii)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02948b65f302782e19d344894d",
    // -> Artists: Brothel, Enjoii
    creators: artists.filter((artist) => ["0ad1f780-0e60-4fde-a1c3-c3808671292f", "d2bc4672-f7cb-4ebd-a25e-9b78048a8b0f"].includes(artist.id)),
    length: 232,
    keySignature: "2B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further. Could be half time at 88 bpm
    tempo: 176,
    links: {
      spotify: "https://open.spotify.com/track/4R3iKrPDBwjQZSek9bc7HV",
    }
  },

  // - 280 - //

  {
    id: "015d33b4-ec1f-4ae3-bcc9-14a889415c9d",
    title: "Inter Alios",
    imageURL: "https://i1.sndcdn.com/artworks-sLzclIKOOrdlL3XG-Vcbplw-t500x500.jpg",
    // -> Artist: Jaga
    creators: artists.filter((artist) => artist.id === "d3773310-fb18-4a8c-850d-0de9d2cd0499"),
    length: 414,
    keySignature: "11A",
    tempo: 146,
    links: {
      soundCloud: "https://soundcloud.com/echotrainrecs/ech001-inter-alios-louder-ten",
    }
  },
  {
    id: "cb3c0a4a-461f-4ed2-bc1f-65209932f682",
    title: "Buster",
    imageURL: "https://i1.sndcdn.com/artworks-icNDDGaiNLmtRhsm-tA3KiA-t500x500.jpg",
    // -> Artist: Pholo
    creators: artists.filter((artist) => artist.id === "59ad7bf8-4667-45b6-96d4-87f70573b936"),
    length: 186,
    keySignature: "9A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 98.5,
    links: {
      soundCloud: "https://soundcloud.com/pholouk/buster",
    }
  },
  {
    id: "d76f3912-2940-44b3-8db4-10a075822a37",
    title: "Blurred Dream (feat. almogfx)",
    imageURL: "https://i1.sndcdn.com/artworks-dKkMD9ni4Bak8Rqo-YoqCJQ-t500x500.jpg",
    // -> Artists: aarise, almogfx
    creators: artists.filter((artist) => ["b46be49d-590c-4606-b8f2-e2596b4ffe17", "5a29ca1f-c903-4b43-b6bf-0eba13d43ddc"].includes(artist.id)),
    length: 206,
    keySignature: "11A",
    tempo: 147,
    links: {
      soundCloud: "https://soundcloud.com/deadhere/blurred-dream-w-almogfx"
    }
  },
  {
    id: "84e31dda-7479-45ff-9711-8ef9180c5064",
    title: "Talkin' (Blade Runner Blues Flip)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e024d82e66ee889a273747881c1",
    // -> Artist: Weaver Beats
    creators: artists.filter((artist) => artist.id === "49950c18-900e-4def-a4b8-bc15d0dafaff"),
    length: 249,
    keySignature: "7B",
    tempo: 141,
    links: {
      spotify: "https://open.spotify.com/track/17o5xNABOJvDcvEcoYtqv7",
    }
  },
  {
    id: "da6ffeb1-aac6-43cf-bd19-ca3cb67c8906",
    title: "ZERO ISOLATION (feat. Jess Audrey)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02eff8849bc83f87b7f3d4e730",
    // -> Artists: Svperior Dwells, Jess Audrey
    creators: artists.filter((artist) => ["39ca2802-5084-48b5-9d6e-6f7ee5e58e47", "673af6d6-3f2f-465b-9f00-4d3adde9aa13"].includes(artist.id)),
    length: 163,
    keySignature: "11B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/0GAFLVKGrzPFaGGond56Uo"
    }
  },
  {
    id: "c667612b-c47d-4b30-aed1-f36e627fd115",
    title: "CONSUME (WARDUB)",
    imageURL: "https://i1.sndcdn.com/artworks-tGb0alJ9AdZTQiNT-q3iiNQ-t500x500.jpg",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 123,
    keySignature: "2A",
    tempo: 98.5,
    links: {
      soundCloud: "https://soundcloud.com/sorsari/consume-wardub",
    }
  },
  {
    id: "65b00b19-adab-4dbd-a1eb-3e878135e783",
    title: "Neb Riddim",
    imageURL: "https://i1.sndcdn.com/artworks-PSivGNzfXkyhSxMu-bT9O1A-t500x500.jpg",
    // -> Artist: Fyoomz
    creators: artists.filter((artist) => artist.id === "195d1986-f79a-44d1-a36c-42634a2c1df0"),
    length: 118,
    keySignature: "4A",
    tempo: 138.5,
    links: {
      soundCloud: "https://soundcloud.com/fyoomz/neb-riddim-war-dub",
    }
  },
  {
    id: "a0535b11-b5fc-486e-9380-ff262bfb9282",
    title: "wardub s2 phonk",
    imageURL: "https://i1.sndcdn.com/artworks-gD0XZVpCgPTUZuRV-HlQVjQ-t500x500.jpg",
    // -> Artist: BVXSEEK
    creators: artists.filter((artist) => artist.id === "f5dd2a16-aea4-4f53-9140-a7c4fdc9c255"),
    length: 102,
    keySignature: "6A",
    tempo: 97.5,
    links: {
      soundCloud: "https://soundcloud.com/baeseek/wardbus-2-phonk",
    }
  },
  {
    id: "4afa1447-0be3-4212-be24-8b80cc4d2dc2",
    title: "My Baby",
    imageURL: "https://i1.sndcdn.com/artworks-TyJeRCJ68bDWQqOR-YkE1AQ-t500x500.jpg",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 193,
    keySignature: "1A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 91.5,
    links: {
      soundCloud: "https://soundcloud.com/gastah/my-baby",
    }
  },
  {
    id: "3a5d30d4-bb17-4728-8307-4b8f677fa956",
    title: "Fly Away",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0284fc0b0a69e2328259b5ce88",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 260,
    keySignature: "9B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/6ijZZxyGBzbNq2FgR0USYR",
    }
  },

  // - 290 - //

  {
    id: "ac379139-7cc6-4a30-9137-4a806fd3dd10",
    title: "Blood Moon",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02bc25a5b86b5a8f4c3103108d",
    // -> Artist: Jato
    creators: artists.filter((artist) => artist.id === "b14341ab-3d48-4170-841b-55255e13f3ea"),
    length: 287,
    keySignature: "6B",
    tempo: 127,
    links: {
      spotify: "https://open.spotify.com/track/4t4PdcGxfI5kIPabRa25WG",
    }
  },
  {
    id: "cb053330-b4bb-4f7e-86e5-39387b0b3ed1",
    title: "Paradise City",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a6c49dc2a557f2177d87ce66",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 213,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3ZVuDcMtcjMuKBRdUtVbSh",
    }
  },
  {
    id: "a71f6e4a-25b2-42cc-ae64-3dd2be8f2f0e",
    title: "Null Response",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a6c49dc2a557f2177d87ce66",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 233,
    keySignature: "5B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5Y5FfRolPzCquiP7FPIEFf",
    }
  },
  {
    id: "e2b5603c-e4d4-45f0-b3b2-0340e5e2f67e",
    title: "Rain",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e4d8e22efdb6dc539c6673b5",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 213,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5kmTAysq1Wqa7ymrnIsiSM",
    }
  },
  {
    id: "25aa9196-2ac8-4910-8881-4d881efd745d",
    title: "Who You Think You Frontin On",
    imageURL: "https://i1.sndcdn.com/artworks-eMvUgAS8uQAO6mHx-Acwa0w-t500x500.jpg",
    // -> Artist: DJ Breathing Exercise
    creators: artists.filter((artist) => artist.id === "e34537ff-dddb-4679-8931-d9b77e56982d"),
    length: 243,
    keySignature: "1A",
    tempo: 92.25,
    links: {
      soundCloud: "https://soundcloud.com/djbreathingexercise/who-you-think-you-frontin-on",
    }
  },
  {
    id: "12a3ac3c-da70-4959-8bfc-43fca0f75725",
    title: "Way I Like It",
    imageURL: "https://i1.sndcdn.com/artworks-iv5lNnE6F8mEvlh7-sW7ypw-t500x500.jpg",
    // -> Artist: Jato
    creators: artists.filter((artist) => artist.id === "b14341ab-3d48-4170-841b-55255e13f3ea"),
    length: 213,
    keySignature: "11B",
    tempo: 144,
    links: {
      soundCloud: "https://soundcloud.com/jatobeats/way-i-like-it",
    }
  },
  {
    id: "275c51bc-69a0-4dff-929a-ab782fec928e",
    title: "Chronomyst",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029f3de5c6fe1fb46e7b2f0c1a",
    // -> Artists: Noah B, iSorin
    creators: artists.filter((artist) => ["5613bb7a-d148-4164-a329-812a72eb74e5", "0adf3528-a9fd-4331-afdc-b46da832d82b"].includes(artist.id)),
    length: 243,
    keySignature: "11B",
    tempo: 142,
    links: {
      spotify: "https://open.spotify.com/track/3wN6ICzpmHuePWdc2yce2h"
    }
  },
  {
    id: "2f290e58-85c0-4707-81a4-0fff8c4890bb",
    title: "So Lost",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a6edce087a7664597b243dfc",
    // -> Artist: Yedgar
    creators: artists.filter((artist) => artist.id === "42d3afdd-cb05-4d6d-ac80-f83a94a326e3"),
    length: 222,
    keySignature: "6B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2xt0tbemEwIhyoMNLyoj1E",
    }
  },
  {
    id: "597f8652-2b11-4529-ad70-37b3560724cf",
    title: "Set You Free",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a6edce087a7664597b243dfc",
    // -> Artist: Yedgar
    creators: artists.filter((artist) => artist.id === "42d3afdd-cb05-4d6d-ac80-f83a94a326e3"),
    length: 280,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3tUT0hlfGdex94bkyByBpJ",
    }
  },
  {
    id: "236a10d1-3416-4587-8084-01d7995b327c",
    title: "Zenith",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0215cfa46fc1320c36edfd3dc6",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 237,
    keySignature: "7B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/3lvUDWNOER1ahfKDTWjNJ0",
    }
  },

  // - 300 - //

  {
    id: "2e03d65f-1622-4b22-80d7-9610c5eea8b5",
    title: "All Around the World (Skeler x Juche Remix)",
    imageURL: "https://i1.sndcdn.com/artworks-spbgE6lhT2nzkXnB-Pp7Ewg-t500x500.jpg",
    // -> Artists: Skeler, Juche
    creators: artists.filter((artist) => ["4f1bb9a6-fb96-499d-adbc-b9043ee1fa22", "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"].includes(artist.id)),
    length: 141,
    keySignature: "10A",
    tempo: 154.5,
    links: {
      soundCloud: "https://soundcloud.com/se1fmotivation/atc-all-around-the-world-skeler-juche-remix"
    }
  },
  {
    id: "82b024a3-01c5-4b4a-83f5-1a3a1f9520a3",
    title: "Falling",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023c34c4b106071a94b550dbaa",
    // -> Artists: w/out, iSorin
    creators: artists.filter((artist) => ["75c02207-5bcc-4377-bf1b-f25e0b9d1579", "0adf3528-a9fd-4331-afdc-b46da832d82b"].includes(artist.id)),
    length: 298,
    keySignature: "10B",
    tempo: 79,
    links: {
      spotify: "https://open.spotify.com/track/6yvFmJMrmdhItpwLGtnCn3"
    }
  },
  {
    id: "890b7b50-9a09-4e1c-9967-e6ee0fe15c4f",
    title: "lost",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026985a33761732dff68944a40",
    // -> Artist: lovewithme
    creators: artists.filter((artist) => artist.id === "d0cb8c08-e74b-430c-8054-52c1c99dd6c8"),
    length: 206,
    keySignature: "4B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/1pHK9YDtH2T1I19djXXOJ2",
    }
  },
  {
    id: "ff9a1409-f195-4a2b-b997-10169e8762ce",
    title: "Illusion",
    imageURL: "https://i1.sndcdn.com/artworks-000209162207-ai3tx2-t500x500.jpg",
    // -> Artist: SOKOS
    creators: artists.filter((artist) => artist.id === "810e350b-0161-48e5-8c0e-e91c3f20abd6"),
    length: 161,
    keySignature: "7A",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 178.5,
    links: {
      soundCloud: "https://soundcloud.com/sokosmusic/illusion",
    }
  },
  {
    id: "298c25e1-2f17-4e46-b29c-309e19d29f83",
    title: "SCORCH",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0232e0d30a09aed07513ff227c",
    // -> Artist: kim
    creators: artists.filter((artist) => artist.id === "9c535188-b27d-4a20-8ea4-0586e1a7c1e9"),
    length: 220,
    keySignature: "3B",
    tempo: 111,
    links: {
      spotify: "https://open.spotify.com/track/0VADUQQTb3rfmQN3QFTurV",
    }
  },
  {
    id: "50e8bed9-5b5f-426c-b52c-0b442ba6d70c",
    title: "Night Ride",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0221d9f4e8b00ebd489752947f",
    // -> Artist: Grace
    creators: artists.filter((artist) => artist.id === "e0fba31d-6f15-4d5e-a4f4-242581e88da9"),
    length: 204,
    keySignature: "1B",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 98,
    links: {
      spotify: "https://open.spotify.com/track/4MZYTxfYFVkI6O4VZoqwni",
    }
  },
  {
    id: "9bff4e0a-f285-4a53-8ad1-d120cd599f21",
    title: "She Wanted",
    imageURL: "https://i1.sndcdn.com/artworks-OavO6QK88fayl6Wx-4nAKzg-t500x500.jpg",
    // -> Artist: Grace
    creators: artists.filter((artist) => artist.id === "e0fba31d-6f15-4d5e-a4f4-242581e88da9"),
    length: 229,
    keySignature: "8A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 94.7,
    links: {
      soundCloud: "https://soundcloud.com/grace-uk/grace-she-wanted"
    }
  },
  {
    id: "d7adf5f4-e0ea-4595-b48a-ae6ad5ef1818",
    title: "Borderline",
    imageURL: "https://i1.sndcdn.com/artworks-Ns3Wey49nNpdBw1v-9numEQ-t500x500.jpg",
    // -> Artist: mykz
    creators: artists.filter((artist) => artist.id === "6dcf2cff-4c7d-4a0b-a0b6-c09bf9083a69"),
    length: 281,
    keySignature: "2A",
    tempo: 95.5,
    links: {
      soundCloud: "https://soundcloud.com/yungmykz/untitled-future-garage-1"
    }
  },
  {
    id: "5179a5cc-3829-4377-b5f5-b6d67229ca14",
    title: "Memories (Fyoomz Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027e76ab0aa55b356114013528",
    // -> Artists: Melleefresh, Fyoomz
    creators: artists.filter((artist) => ["d7467a36-e32a-4826-a4cd-b412fbe937f9", "195d1986-f79a-44d1-a36c-42634a2c1df0"].includes(artist.id)),
    length: 217,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5RtXBN9DiVtKmxDiJeyPFG"
    }
  },
  {
    id: "6876156e-dbc7-40e7-b2f5-fc2c1dad2a2e",
    title: "nobody",
    imageURL: "https://i1.sndcdn.com/artworks-NYzYIKtvCqmfD1ww-IkPj3g-t500x500.jpg",
    // -> Artist: kim
    creators: artists.filter((artist) => artist.id === "9c535188-b27d-4a20-8ea4-0586e1a7c1e9"),
    length: 156,
    keySignature: "11A",
    tempo: 95.75,
    links: {
      soundCloud: "https://soundcloud.com/beatskim/nobody"
    }
  },

  // - 310 - //

  {
    id: "679a8cc3-4908-4ad0-a4fa-0a8b6faadc7f",
    title: "Shoumyou",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ffd9ecc8d483f0eb5fdd108a",
    // -> Artist: Five Suns
    creators: artists.filter((artist) => artist.id === "cf86ac9a-861e-4ca5-930e-8b2ca003ea38"),
    length: 285,
    keySignature: "8B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/6NsP1DGG3pnpz6GEbnQEvC"
    }
  },
  {
    id: "f1354751-afd2-4f4e-b484-ba8ee3edb3ce",
    title: "Stargazing (TIGEREYES Flip)",
    imageURL: "https://i1.sndcdn.com/artworks-Uyi9ABTnQkyvnHoS-xbfOBQ-t500x500.jpg",
    // -> Artists: Tinashe, TIGERYES
    creators: artists.filter((artist) => ["153a21ba-7249-4ee8-9877-cbce6fa55d70", "ab918827-aa3e-4462-9a4d-dacf3759e37e"].includes(artist.id)),
    length: 233,
    keySignature: "3A",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 171.3,
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/tinashe-stargazing-tigereyes-flip"
    }
  },
  {
    id: "0e00d304-832e-46ca-ad56-c07bb2f4e08f",
    title: "GONE COLD",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273bc25a5b86b5a8f4c3103108d",
    // -> Artist: Jato
    creators: artists.filter((artist) => artist.id === "b14341ab-3d48-4170-841b-55255e13f3ea"),
    length: 281,
    keySignature: "8B",
    tempo: 133,
    links: {
      spotify: "https://open.spotify.com/track/45smQ4witVq7oau7CDmzFO"
    }
  },
  {
    id: "32d53869-b0c4-4c2f-a932-824fb06d9e3d",
    title: "aral",
    imageURL: "https://i1.sndcdn.com/artworks-000232522030-l7xq1t-t500x500.jpg",
    // -> Artist: R@
    creators: artists.filter((artist) => artist.id === "14bb42da-2c19-4a29-9fb0-6cf344845866"),
    length: 293,
    keySignature: "12A",
    tempo: 127.5,
    links: {
      soundCloud: "https://soundcloud.com/r-at/aral"
    }
  },
  {
    id: "a9bf0a2a-47c6-4179-8c9e-b5e9318a11a8",
    title: "Subterfuge",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02284c4570360345ce59911d2c",
    // -> Artist: Cvrl
    creators: artists.filter((artist) => artist.id === "0a967ce4-e926-4e17-a987-4dd08a0a0ce8"),
    length: 240,
    keySignature: "10B",
    tempo: 116,
    links: {
      spotify: "https://open.spotify.com/track/3V3EweEH3Tpq29nyzRey36"
    }
  },
  {
    id: "54448eed-f9ea-44f5-b942-64cf40ed1d56",
    title: "Eternally Yours",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0231cf84b55b54efea6d18153d",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 263,
    keySignature: "1B",
    tempo: 127,
    links: {
      spotify: "https://open.spotify.com/track/3ju6S1v9i1s9hzOm7hMRQr"
    }
  },
  {
    id: "01da99d1-83e7-4313-8dcc-e304e31c06ad",
    title: "Forever 2001",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273e7bc57fafbc09d05827a4e35",
    // -> Artist: Gud
    creators: artists.filter((artist) => artist.id === "81651801-9b64-4710-b0bf-be5aaabb9233"),
    length: 262,
    keySignature: "3B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/3EFksYRRQBS0KgOYvTDZXI"
    }
  },
  {
    id: "8e94651f-7506-407d-96bb-a5310349d6f2",
    title: "Viper",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02fe7984282df8fcd0e468b5b4",
    // -> Artist: Deadcrow 
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 235,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/1kkzyqyDD7XghZAxf514Xw"
    }
  },
  {
    id: "2aa3aaf9-c862-47a4-845a-73393c70b277",
    title: "Every Day",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023ee5109a6ab5eae93dc79b45",
    // -> Artist: Kamandi
    creators: artists.filter((artist) => artist.id === "6c482670-8f76-4c2c-b15f-19af10a20ab8"),
    length: 184,
    keySignature: "8B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/3n82TUGqdW0M1gI22IVQdY"
    }
  },
  {
    id: "eadff2f7-f77f-4038-86cb-43145b430f6c",
    title: "Defect",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b35dfa387c3c8ae3e3cb541a",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 317,
    keySignature: "4B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/66nftD161Q2MfRFTAn631o"
    }
  },

  // - 320 - //

  {
    id: "2c8dec76-9615-4bbb-b381-caa60948e25d",
    title: "Pixel",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f23454f848627b595e3dd37a",
    // -> Artists: MYSTXRIVL, SOKOS
    creators: artists.filter((artist) => ["c7f75b69-5a29-4f60-a486-ace3354d3df6", "810e350b-0161-48e5-8c0e-e91c3f20abd6"].includes(artist.id)),
    length: 165,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/0CDQpqpDFkbpHLHReZ3uuR"
    }
  },
  {
    id: "04259b86-7e01-4c26-ad8e-b1127477f870",
    title: "Darkest Hour",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b4e3006b1e48ba76be9a0f19",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 245,
    keySignature: "5B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/2LwLZxz6IVehkchQXOLSy7"
    }
  },
  {
    id: "7110c2c6-200c-499a-b032-14998da04a50",
    title: "Guardian",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f75ec56e7854ae346d84ea63",
    // -> Artist: Noah B
    creators: artists.filter((artist) => artist.id === "5613bb7a-d148-4164-a329-812a72eb74e5"),
    length: 267,
    keySignature: "2B",
    tempo: 126,
    links: {
      spotify: "https://open.spotify.com/track/2vSPdjRb4TLcxPYHQl9FZx"
    }
  },
  {
    id: "6a52ee13-79bb-40b8-af2c-a1bffc3de04f",
    title: "Don't Dwell",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028fd0528604e8ee5422d6d96f",
    // -> Artist: barnacle boi
    creators: artists.filter((artist) => artist.id === "774c9e44-6ade-4698-9466-5219c669035f"),
    length: 214,
    keySignature: "2B",
    tempo: 117,
    links: {
      spotify: "https://open.spotify.com/track/1yCeiQKpXQgz1c2fGokdm3"
    }
  },
  {
    id: "aa80edda-5ba7-43bc-a5d4-3fe4515cdff3",
    title: "Up From Here",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0279b1f18a981e9e691b40066c",
    // -> Artist: barnacle boi
    creators: artists.filter((artist) => artist.id === "774c9e44-6ade-4698-9466-5219c669035f"),
    length: 174,
    keySignature: "2B",
    tempo: 80,
    links: {
      spotify: "https://open.spotify.com/track/6p6gVrx0jxPhV1Tmy2Guwu"
    }
  },
  {
    id: "d39b62c1-6638-4eab-9ee7-b0673fb7382a",
    title: "This is Also a Lost Project",
    imageURL: "https://i.scdn.co/image/ab67616d00001e021ce82829e233dcb902f61a2d",
    // -> Artist: barnacle boi
    creators: artists.filter((artist) => artist.id === "774c9e44-6ade-4698-9466-5219c669035f"),
    length: 199,
    keySignature: "1B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/4DYUukRn8OG9nFYibpXkzf"
    }
  },
  {
    id: "c7cbecfe-b9bd-4445-9ac6-8542ee6409d6",
    title: "Svyslf",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02fed31009457806b40aa90b6b",
    // -> Artist: Whispa
    creators: artists.filter((artist) => artist.id === "52d05eae-fb77-4406-b059-d9045bdc943b"),
    length: 194,
    keySignature: "11B",
    tempo: 124,
    links: {
      spotify: "https://open.spotify.com/track/2puLTdRFH9KosIhblFtYgw"
    }
  },
  {
    id: "b8782bd8-bb04-4ee8-89df-df6d56e2e406",
    title: "Despite It All",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27382bfd4a7ebc3bd724b44851c",
    // -> Artist: Om Unit
    creators: artists.filter((artist) => artist.id === "3aa7f9ac-7cc0-42ca-aab9-42bce1245610"),
    length: 257,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3d1BdVcWJXuMFWx5MBAkOP",
    }
  },
  {
    id: "cdce33fc-5f8f-4853-a3bd-1fe82e9a9f76",
    title: "Last Kiss",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0218aef38dd66a2e3eeb5da227",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 292,
    keySignature: "11B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/52qF3PGgpgnZJnAoy5NYTR",
    }
  },
  {
    id: "915ec4ad-43d8-41d4-b443-075bab0d4876",
    title: "Azure",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027383faa031c3f64a1ce6cafc",
    // -> Artist: Noah B
    creators: artists.filter((artist) => artist.id === "5613bb7a-d148-4164-a329-812a72eb74e5"),
    length: 188,
    keySignature: "1B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/2IlzqlTambpyjThTOoP8LI",
    }
  },

  // - 330 - //

  {
    id: "33ffb03a-d4fe-4e2a-b6b0-13c8c6a71d2b",
    title: "Cryo Enemy",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b35dfa387c3c8ae3e3cb541a",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 184,
    keySignature: "3B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/5mhHijU4zeIYMk2ak6neCh"
    }
  },
  {
    id: "1de9bdc9-41fc-4414-b5ae-af877159bb66",
    title: "Vectors",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b35dfa387c3c8ae3e3cb541a",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 246,
    keySignature: "5B",
    tempo: 114,
    links: {
      spotify: "https://open.spotify.com/track/71gYcA9ttHYCwgLdwbrZxf"
    }
  },
  {
    id: "faef9e70-0320-458a-8c30-bdf8e6c9fb35",
    title: "Hypersquare",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023294bafa9d6598329b43afe8",
    // -> Artists: MYSTXRIVL, Deadcrow
    creators: artists.filter((artist) => ["c7f75b69-5a29-4f60-a486-ace3354d3df6", "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"].includes(artist.id)),
    length: 248,
    keySignature: "11B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5pDFJgkTXczsJIIDspl8hF"
    }
  },
  {
    id: "17e29146-3c3c-4dfc-8b9b-588a2581c169",
    title: "Tears",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e80f5f30cbe10b6629965c5e",
    // -> Artist: KOSIKK
    creators: artists.filter((artist) => artist.id === "a7e933e2-5311-428e-83ae-fd10f0a764ee"),
    length: 211,
    keySignature: "12B",
    tempo: 90,
    links: {
      spotify: "https://open.spotify.com/track/5A3oZ6kzbgWQx5y6LMQNUJ"
    }
  },
  {
    id: "42b523ae-791b-4e85-9d7c-9fbfcefa9cd1",
    title: "Two Days",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028245ddb0cc806275e6b1d5fb",
    // -> Artist: Grey Killer
    creators: artists.filter((artist) => artist.id === "a969094d-4883-4140-9fb3-2cea6b032584"),
    length: 267,
    keySignature: "3B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/4cIemtxB4N3qOh2obXrqaG"
    }
  },
  {
    id: "9cfd9d03-e397-47bb-9665-c615a6c1e8ec",
    title: "Ninth Gate",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027113f7b388fb145de6cfcc09",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 221,
    keySignature: "6B",
    tempo: 160,
    links: {
      spotify: "https://open.spotify.com/track/79j1ss92fsiBYFkceAerXV"
    }
  },
  {
    id: "44eb2870-1b7d-4978-b9bd-d580243fc24e",
    title: "The Keeper (Alex Banks Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e020c41713a9d75d13961ffb82b",
    // -> Artists: Bonobo, Andreya Triana, Alex Banks
    creators: artists.filter(
      (artist) => [
        "c2f374ce-5596-4c90-8751-3587a278d38c", "78f581e0-a490-4af9-b9bb-2f439ce9e8a2",
        "a06964b3-be25-4ef0-9e47-042cb00b78ec"
      ].includes(artist.id)
    ),
    length: 371,
    keySignature: "4B",
    tempo: 98,
    links: {
      spotify: "https://open.spotify.com/track/37WssUIwxFxg785SVYSc6H"
    }
  },
  {
    id: "4caed5b3-af45-4346-a215-a5cf51ab98b5",
    title: "Mithril",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029a1be59daa9b58e509bb27d8",
    // -> Artist: KAVERN
    creators: artists.filter((artist) => artist.id === "905a1bc8-add0-4ff9-a63a-92c6db6e3a23"),
    length: 381,
    keySignature: "2B",
    tempo: 116,
    links: {
      spotify: "https://open.spotify.com/track/3KDwWQfQR2iWL8A41BbEps"
    }
  },
  {
    id: "fce445a9-70f9-48cf-bc7e-ae2cb40e6540",
    title: "Yeah",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02acf3ee1a248f003ee3c4fbf0",
    // -> Artist: DECAP
    creators: artists.filter((artist) => artist.id === "6b83cbf3-90e1-44ef-9008-7fc2d42a93fa"),
    length: 192,
    keySignature: "3B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/4y8EK77j9dgfA4Ewig3Ad1"
    }
  },
  {
    id: "7f3d6347-5df6-4ba1-865c-23a762df1dc4",
    title: "Witness",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ee54bc6892a8a08b7dee7e54",
    // -> Artists: Lusine, Benoît Pioulard
    creators: artists.filter((artist) => ["8ce0bd12-8f14-48a5-b1fe-5deb3fd8c187", "87eb9a82-3b46-4cd1-8f0c-305bacf52748"].includes(artist.id)),
    length: 264,
    keySignature: "6B",
    tempo: 132,
    links: {
      spotify: "https://open.spotify.com/track/2EYw1867TIkTOHLWXYh7r4"
    }
  },

  // - 340 - //

  {
    id: "fe67372a-66b0-41bb-a967-7f93199ac56c",
    title: "Fatamorgana",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a77745163e83e4e1f6a56a0e",
    // -> Artist: Gedz
    creators: artists.filter((artist) => artist.id === "4fd67a32-d0e8-4308-b62f-b3d7d6948d03"),
    length: 233,
    keySignature: "8B",
    tempo: 83,
    links: {
      spotify: "https://open.spotify.com/track/7HtXpnPITiHlFGxBfJtDu4"
    }
  },
  {
    id: "547111e1-66fe-4fc4-a2df-66be0af03179",
    title: "Icebreaker",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0296e16f4cf75132385e76ecd7",
    // -> Artist: Obesøn
    creators: artists.filter((artist) => artist.id === "20dfdc76-254a-413e-81aa-69b246936f3c"),
    length: 289,
    keySignature: "3B",
    tempo: 128,
    links: {
      spotify: "https://open.spotify.com/track/2T6MGgKXbiT7JvKqx9y9D3"
    }
  },
  {
    id: "cde9ed5d-c44b-44ee-b25c-97bd3cb4117f",
    title: "Shards",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e6463ad8a18f73d2436c15fc",
    // -> Artists: George Fitzgerald, Lawrence Hart
    creators: artists.filter((artist) => ["f7da001a-b8ba-4d0a-9642-38bfb7f8813e", "331732c2-a22b-46a8-9d4c-10bb100d3570"].includes(artist.id)),
    length: 187,
    keySignature: "1B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/1sqvCvSYXyaQCfWj0rPp9W"
    }
  },
  {
    id: "5ebc3901-f5c1-4bc8-8c8c-0413dee34fe4",
    title: "Worth",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02aae3b839d3d24d30d8576e16",
    // -> Artist: Dyzphoria 
    creators: artists.filter((artist) => artist.id === "169286b0-b3d4-4829-b080-8ba4d8c471ca"),
    length: 249,
    keySignature: "5B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/6pzfjQyNPdYILP91GvZz7o"
    }
  },
  {
    id: "80ddea12-f6a0-470e-ab62-e6fefb2a9f24",
    title: "SORCERER",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0279e1b5f9279284f9a8612ae2",
    // -> Artist: WHITE KATANA
    creators: artists.filter((artist) => artist.id === "2a934ed2-8181-46eb-b926-50dd953055ba"),
    length: 192,
    keySignature: "2B",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 97,
    links: {
      spotify: "https://open.spotify.com/track/3vLx9eP8H0yEfgfrRLdMnr"
    }
  },
  {
    id: "7608707d-2c78-4fba-8ff5-2a6c4c8590f2",
    title: "Missed Call",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026a4221a0b0b42fd3d52f3377",
    // -> Artists: M!NGO, pinknokia
    creators: artists.filter((artist) => ["a77f263a-3296-4f8c-84ff-2208134ddcfe", "5dec993e-2648-474c-aecc-5b99ae6e9221"].includes(artist.id)),
    length: 219,
    keySignature: "10B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/64SrUvSXvi2DCqwnScNQ87"
    }
  },  
  {
    id: "b5279267-4de6-4514-9a29-b571ff65dc22",
    title: "Lifestream",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f60e15eb66e708234b443e3f",
    // -> Artist: Skit
    creators: artists.filter((artist) => artist.id === "f828cc93-b86e-4fe6-abde-40dfcc37686a"),
    length: 273,
    keySignature: "3B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/4Ow784ahnFJQt86XNQiKbI"
    }
  },
  {
    id: "f2b800a1-2135-4f2e-9a83-2f7b964be1e8",
    title: "Icefire",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a732641ab051110c73103166",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 214,
    keySignature: "2B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/2TWwg4g97uxJDWh2vc7iks"
    }
  },
  {
    id: "7402b1e7-159c-42b1-a5e8-e4a9f015aed2",
    title: "Gold Coffin",
    imageURL: "https://i.scdn.co/image/a1bb263ad607c2d60fad03012f32ad4caacc5add",
    // -> Artist: 2Ravens
    creators: artists.filter((artist) => artist.id === "211a5e75-c0d9-4d67-933b-8dec688b042b"),
    length: 184,
    keySignature: "3B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/6CZjHsqBNqhDByNI4XnEUE"
    }
  },

  // - 350 - //

  {
    id: "328e8999-9d89-48ee-a598-a1e99738ad9f",
    title: "Emerald Rush",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029d9126ec04bb005c687291a5",
    // -> Artist: Jon Hopkins
    creators: artists.filter((artist) => artist.id === "afcec413-2dc5-4380-b37b-f7071c6a9e71"),
    length: 337,
    keySignature: "3B",
    tempo: 107,
    links: {
      spotify: "https://open.spotify.com/track/5S3F5GL8LASIjPg2PMiU1Q"
    }
  },
  {
    id: "ba3713e7-4e95-486a-9a98-eac1d273ad2b",
    title: "Our City (feat. Jaga)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b4f1b32da9943a03d69b57a5",
    // -> Artists: Kamandi, Jaga
    creators: artists.filter((artist) => ["6c482670-8f76-4c2c-b15f-19af10a20ab8", "d3773310-fb18-4a8c-850d-0de9d2cd0499"].includes(artist.id)),
    length: 186,
    keySignature: "7B",
    tempo: 137,
    links: {
      spotify: "https://open.spotify.com/track/7KkK9B2jDiyenUrpthM1NC"
    }
  },  
  {
    id: "3bbc51c4-16fd-4937-a807-3a55bcbe68b1",
    title: "Speedway 2001",
    imageURL: "https://i.scdn.co/image/ab67616d00001e024ee225c23804efbb4469ddd8",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 228,
    keySignature: "1B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2HVQH9wXlVfNe7l9METbjW"
    }
  },
  {
    id: "82286e3a-1935-4146-bfaf-f0712fda1ce4",
    title: "Akwraith",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b35dfa387c3c8ae3e3cb541a",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 216,
    keySignature: "2B",
    tempo: 160,
    links: {
      spotify: "https://open.spotify.com/track/1vRp872N7kX0OSKnYzcZtZ"
    }
  },
  {
    id: "36e38d9a-9b49-4645-b02f-af00d7c26624",
    title: "Awake",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02164ceed681edd60045dc728c",
    // -> Artist: Forxst
    creators: artists.filter((artist) => artist.id === "3a997d7a-2282-46db-8357-331508091e83"),
    length: 193,
    keySignature: "3B",
    tempo: 122,
    links: {
      spotify: "https://open.spotify.com/track/6bpJGOXT9hjCD4qGNBwn9I"
    }
  },
  {
    id: "4c38c595-b183-4c02-ba1b-8b4e22b0ced1",
    title: "Tesseract",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739245364234cf34ceab4e8f53",
    // -> Artist: Throwing Snow
    creators: artists.filter((artist) => artist.id === "95a4115c-1cf2-4351-801c-0a2f877fa473"),
    length: 309,
    keySignature: "2B",
    tempo: 93,
    links: {
      spotify: "https://open.spotify.com/track/7um9KBNv2gaZNGqNn094cV"
    }
  },
  {
    id: "c9e4b371-2c6f-4b0e-a859-686678080aec",
    title: "CIGARETTES",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273c0cbb42913ff851a6bbe5af8",
    // -> Artist: Amir Obe
    creators: artists.filter((artist) => artist.id === "6f1f107a-66cc-4f5d-a388-9d6c859edb78"),
    length: 190,
    keySignature: "2B",
    tempo: 144,
    links: {
      spotify: "https://open.spotify.com/track/3TfTZ3fGLYtR1azsY8iEp4"
    }
  },
  {
    id: "1ba5ee01-bb6d-4e89-b0c9-c86c4ede5164",
    title: "You Don't Know M3",
    imageURL: "https://i1.sndcdn.com/artworks-000190262334-y9o7x9-t500x500.jpg",
    // -> Artist: 
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 204,
    keySignature: "11A",
    tempo: 100.25,
    links: {
      soundCloud: "https://soundcloud.com/jmingo/you-dont-know-m3"
    }
  },
  {
    id: "07c650a8-819a-4b1b-8c0f-3ec7fd529e54",
    title: "i18n",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d380c0d379abd69a6cbbdec5",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 253,
    keySignature: "5B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/5l2IwjWINgKNX7cYSuqYir"
    }
  },
  {
    id: "8d9ddc85-5153-41a9-b1dc-976169e57eb7",
    title: "Medi",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d380c0d379abd69a6cbbdec5",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 194,
    keySignature: "8B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5TAosLWrQHZNs42r3xwFxq"
    }
  },

  // - 360 - //

  {
    id: "dc5f535e-935f-4c7d-b5ad-9ec93d9f0224",
    title: "Elliptic (Throwing Snow Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e020e5d8b7902b4e95915666199",
    // -> Artists: Vessels, Throwing Snow
    creators: artists.filter(
      (artist) => ["267f20ef-058a-4614-bd62-51617c565a30", "95a4115c-1cf2-4351-801c-0a2f877fa473"].includes(artist.id)),
    length: 421,
    keySignature: "6B",
    tempo: 123,
    links: {
      spotify: "https://open.spotify.com/track/1qzxbgGnG2FZzBrH8dBvdd"
    }
  },
  {
    id: "34a67d41-31dd-48f6-9f4d-cd81bb104fe3",
    title: "Dreams",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c0af9444a0e5054e5d0ec694",
    // -> Artists: Sidewalks and Skeletons, Brothel
    creators: artists.filter((artist) => ["b57db24c-40e9-45f6-9409-9f6d4b82322e", "ad1f780-0e60-4fde-a1c3-c3808671292f"].includes(artist.id)),
    length: 255,
    keySignature: "2B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/2E06vbFYcrwcHlz4ORolxr"
    }
  },
  {
    id: "68991706-4f38-4a62-85fc-d4099cfdd713",
    title: "The Greats",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023111910eddd8e8b0ee413e9c",
    // -> Artist: Derek Pope
    creators: artists.filter((artist) => artist.id === "24c29208-60ef-4dde-943d-dc3c22fe9169"),
    length: 211,
    keySignature: "8B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/3EoNYOYkRg27uLQFa3lwm5"
    }
  },
  {
    id: "995a958d-3f04-4006-8a93-d367405bdd3e",
    title: "Vagabond",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d3548e894525f2b592de58a1",
    // -> Artists: Pholo, Peter Xan
    creators: artists.filter((artist) => ["59ad7bf8-4667-45b6-96d4-87f70573b936", "62b61c4b-696c-49df-9856-295ab9868f44"].includes(artist.id)),
    length: 172,
    keySignature: "5B",
    tempo: 67,
    links: {
      spotify: "https://open.spotify.com/track/4JUAswK1GNb0WmE7Cj9LnR"
    }
  },
  {
    id: "2f4a2d79-5f4c-4fdf-bab5-a1b59af7c9d2",
    title: "Old Friend",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02659d9269fc8b0b81beaa1abd",
    // -> Artists: Elderbrook
    creators: artists.filter((artist) => artist.id === "4b98a187-1459-4e6a-b07c-34a25f755d32"), 
    length: 200,
    keySignature: "5B",
    tempo: 122,
    links: {
      spotify: "https://open.spotify.com/track/7l1NMHXBISVLarwGyfgmFP"
    }
  },
  {
    id: "569a9564-2025-428e-b976-c955e403f9e6",
    title: "Sum Clouds",
    imageURL: "https://i.scdn.co/image/ab67616d00001e024faafd0df125a7e6cf49b7a4",
    // -> Artist: Sum Wave
    creators: artists.filter((artist) => artist.id === "75d2c13a-257f-44f4-aed8-af3f45d25bc9"), 
    length: 187,
    keySignature: "2B",
    tempo: 148,
    links: {
      spotify: "https://open.spotify.com/track/4gn0PQSYvXInswNyw43UWk"
    }
  },
  {
    id: "2880d9c0-3fe3-406a-ad26-2727d12ba57a",
    title: "Temple (feat. M.I.A, G-DRAGON)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028928f6b06c7b3d68258c3970",
    // -> Artists: Baauer, M.I.A, G-DRAGON
    creators: artists.filter(
      (artist) => [
        "730268d8-b2d0-488b-9396-1f5686d1f370", "b333382b-8da2-4fb7-8511-c75b74351ded",
        "737e4fd0-0aee-4e1b-856b-0494dd4be594"
      ].includes(artist.id)
    ),
    length: 185,
    keySignature: "8B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/7mY2gmBPnmt4SJqkhPTLnf"
    }
  },
  {
    id: "adb978fc-c45c-4521-bd0c-b34700938433",
    title: "EDEN",
    imageURL: "https://i.scdn.co/image/ab67616d00001e025b37d1930a47a9fd302a5c49",
    // -> Artist: Henryisdead 
    creators: artists.filter((artist) => artist.id === "b091c39f-9dfa-481e-a30b-ce707c27c801"), 
    length: 199,
    keySignature: "11B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/2CBod7czT5zr08B0Ue3Exs"
    }
  },
  {
    id: "7609e2b9-253a-41af-a73d-086e1b23cef0",
    title: "Abduction (feat. Goo Munday)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d840d0ca482d3d8a78d2e624",
    // -> Artists: Sidewalks and Skeletons, Goo Munday
    creators: artists.filter((artist) => ["b57db24c-40e9-45f6-9409-9f6d4b82322e", "380367e6-0173-4d92-ad9d-9d1ffd00c5c8"].includes(artist.id)),
    length: 202,
    keySignature: "2B",
    tempo: 85,
    links: {
      spotify: "https://open.spotify.com/track/1hMg3pUbXn2E1l0s9pCDHm"
    }
  },
  {
    id: "162612a5-83e6-42f5-be85-8c9bf1fa5a89",
    title: "One Day",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028c8c93e2ab245f08b42a1384",
    // -> Artist: Skeler
    creators: artists.filter((artist) => artist.id === "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22"), 
    length: 240,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/7obCERKA5H8m7zpEVxndmM"
    }
  },

  // - 370 - //

  {
    id: "408b4588-9d28-4308-9af5-9e9d0a741a1e",
    title: "Shinobi Flow",
    imageURL: "https://i.scdn.co/image/ab67616d00001e020f4704a4efc14de52f380153",
    // -> Artist: Whispa
    creators: artists.filter((artist) => artist.id === "52d05eae-fb77-4406-b059-d9045bdc943b"), 
    length: 188,
    keySignature: "10B",
    tempo: 127,
    links: {
      spotify: "https://open.spotify.com/track/4BPMeYQn01PdrTT4oxjW3L"
    }
  },
  {
    id: "c32ab8af-fa41-4fe7-acc8-3581d2374bfc",
    title: "Shawty",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273e3cd62e1bec5f37f4b7433af",
    // -> Artists: $kyhook, Hermei
    creators: artists.filter((artist) => ["6d25fc91-0404-4cd9-a4a6-0ac94e530c05", "a550a7ef-13d0-4ee8-ad3d-7eade79cbebb"].includes(artist.id)),
    length: 179,
    keySignature: "1B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/7mf16gxAzI8lS6QFOBk9em"
    }
  },
  {
    id: "19614570-2ee1-4dbf-841e-0fce5fbbdae8",
    title: "Take Me Away",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ade177107fb43591350c8dde",
    // -> Artists: Five Suns, Cardio
    creators: artists.filter((artist) => ["", "04c5f330-059b-4240-80ff-c471248dd417"].includes(artist.id)),
    length: 271,
    keySignature: "9B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/4TEJ3l6RcK0ODIbw33wydb"
    }
  },  
  {
    id: "264bb5df-88e9-4e76-9232-afdcdfb37cae",
    title: "G0tti",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c4dc5603f267206f5e8e17f2",
    // -> Artist: Ytho 
    creators: artists.filter((artist) => artist.id === "a5fa3d99-781f-4468-bc2e-5ecd371992a6"), 
    length: 162,
    keySignature: "2B",
    tempo: 81,
    links: {
      spotify: "https://open.spotify.com/track/0pCFrz7WEtNsNMHKExe0gl"
    }
  },
  {
    id: "44a9bc9c-12bf-4380-8d22-aa658aaf287f",
    title: "Weekend",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029a695704d06016a608ea5bd6",
    // -> Artists: Flume, Moses Sumney
    creators: artists.filter((artist) => ["22ee6f55-4930-451a-9c54-aa781a269be3", "4863d296-41f1-4db6-87b6-186bf552a35b"].includes(artist.id)),
    length: 259,
    keySignature: "3B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/797cARXs5UuXT7anWuILTP"
    }
  },
  {
    id: "204eb49e-07d5-4ac2-9964-088f4c44524a",
    title: "Wild Mother",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02547e96d83764fbd350888623",
    // -> Artists: Ouri, Mind Bath
    creators: artists.filter((artist) => ["990402bb-3340-471e-a4ac-dc38ec2b5324", "e7c9fa06-43e2-41fe-bfe6-ec5a9638f4ed"].includes(artist.id)),
    length: 212,
    keySignature: "8B",
    tempo: 95,
    links: {
      spotify: "https://open.spotify.com/track/7A3TfYuTgmNSVsFwmYuNnM"
    }
  },
  {
    id: "77019ee6-5381-4318-8967-ad039e3c7c13",
    title: "TRUST",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026e8488510f92e3a340b0caf4",
    // -> Artists: Flume, Isabella Manfredi
    creators: artists.filter((artist) => ["22ee6f55-4930-451a-9c54-aa781a269be3", "0189b2d5-8780-48d9-b022-86b82126cb29"].includes(artist.id)),
    length: 197,
    keySignature: "8B",
    tempo: 107,
    links: {
      spotify: "https://open.spotify.com/track/2Zp4W9yRmv1F6PJVg4BibH"
    }
  },
  {
    id: "7e7a6d70-88c5-463a-a99a-d31235c901ae",
    title: "HEROIST - Edit",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0231bf6bf8e8629aac909108c8",
    // -> Artist: Apparat
    creators: artists.filter((artist) => artist.id === "e5a56d16-1348-49d3-9a53-7158a3d9436f"), 
    length: 224,
    keySignature: "4B",
    tempo: 137,
    links: {
      spotify: "https://open.spotify.com/track/5mXmWTa3SqAvQvkk3AATG8"
    }
  },
  {
    id: "4ca4e7f0-7554-404a-8c3b-f81297736c96",
    title: "Future Perfect",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cc22b1d9baaf47ea674a73cb",
    // -> Artist: Tom E. Vercetti
    creators: artists.filter((artist) => artist.id === "fb096d5d-0e2f-46c5-a3f8-273806a8f7e9"), 
    length: 280,
    keySignature: "2B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/05IJvuD0d1rstf6izt91hr"
    }
  },
  {
    id: "fabdb83b-5b20-40f8-913f-91ab2d38d378",
    title: "Twilight",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0207ae0ba277082b91d6790608",
    // -> Artist: Outsidr
    creators: artists.filter((artist) => artist.id === "761c2052-f6b0-4057-a79e-796b6013bc13"), 
    length: 222,
    keySignature: "6B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/3Jet2NwfY4he5JsuQePwov"
    }
  },

  // - 380 - //

  {
    id: "c264f25d-1789-4216-ae19-e3f152189679",
    title: "Lml",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d60e6f2e42c2f60573f6e0cd",
    // -> Artist: Eli Sostre
    creators: artists.filter((artist) => artist.id === "8210c481-0f19-4875-a3a7-03191a3623c6"), 
    length: 220,
    keySignature: "3B",
    tempo: 158,
    links: {
      spotify: "https://open.spotify.com/track/6i878i1nBDf0ONHJE7w71m"
    }
  },
  {
    id: "b5a84189-d35e-4cfe-9818-deae2bb69227",
    title: "DÉJALO",
    imageURL: "https://i.scdn.co/image/ab67616d00001e021af79b520559bec7fa431d12",
    // -> Artist: A.CHAL
    creators: artists.filter((artist) => artist.id === "242463c2-3c15-40b1-920a-d197274109d7"), 
    length: 199,
    keySignature: "1B",
    tempo: 131,
    links: {
      spotify: "https://open.spotify.com/track/7KP8ydBNwzR5OIV9KWoLQc"
    }
  },
  {
    id: "a9801816-cb75-4a7e-aafe-bf36ccce0b3d",
    title: "Reincarnation",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023c3831d0c686abcd71c0c15f",
    // -> Artist: iSorin
    creators: artists.filter((artist) => artist.id === "0adf3528-a9fd-4331-afdc-b46da832d82b"),
    length: 234,
    keySignature: "3B",
    tempo: 129,
    links: {
      spotify: "https://open.spotify.com/track/4tzk8obVuwlVFSrXZt0gbO"
    }
  },
  {
    id: "c7bb1fe6-5e74-460a-8c30-75bd471efe42",
    title: "Luna",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f60e15eb66e708234b443e3f",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"), 
    length: 200,
    keySignature: "4B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5bZRKkPYoXGoE4iVwamv21"
    }
  },
  {
    id: "3fcab479-9541-411f-93ee-4b04b46a2a69",
    title: "Nylon",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026838b11a303be3135018af19",
    // -> Artists: Jato, Vexxy
    creators: artists.filter((artist) => ["b14341ab-3d48-4170-841b-55255e13f3ea", "aa8507e3-e295-442d-af3c-7036e9ef44aa"].includes(artist.id)),
    length: 199,
    keySignature: "1B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/6quEGPb7LoA1f2j7j8w0YD"
    }
  },
  {
    id: "351f4e91-f42a-4538-815d-b52571f25190",
    title: "Adrift",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b221d3f1e8362db6daf20fd0",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 339,
    keySignature: "6B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2bwRAG1WJOJNVQec8sqJP9"
    }
  },
  {
    id: "4c889493-b49e-4380-a355-e1d78db74697",
    title: "Glass",
    imageURL: "https://i1.sndcdn.com/artworks-000648508165-ciuxkp-t500x500.jpg",
    // -> Artist: Imer6ia
    creators: artists.filter((artist) => artist.id === "db18fc83-643c-4f03-aea9-83456cbc045c"),
    length: 275,
    keySignature: "2B",
    tempo: 142,
    links: {
      spotify: "https://open.spotify.com/track/2ksjU40Az8qy2wE4a60Ltx"
    }
  },
  {
    id: "8ad8a1d9-6a06-4331-896b-a9a7f398dd65",
    title: "Nocturnal",
    imageURL: "https://i.scdn.co/image/ab67616d00001e025638984aba8c19ac97cf5c3f",
    // -> Artist: Jaga
    creators: artists.filter((artist) => artist.id === "d3773310-fb18-4a8c-850d-0de9d2cd0499"),
    length: 229,
    keySignature: "11B",
    tempo: 128,
    links: {
      spotify: "https://open.spotify.com/track/4Zg1NPQKmgAgfdsjwTidma?si=f99f1e7c205c453a"
    }
  },
  {
    id: "6deb98a6-2907-4416-9a07-e64f025d34c4",
    title: "Self-Destructive",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0272a537a8eda652297cbcaecf",
    // -> Artist: Blanku
    creators: artists.filter((artist) => artist.id === "8115e887-b936-4b95-a19d-ca95d31112cb"),
    length: 278,
    keySignature: "11B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/3bnsFj3xpb3RpMDN9Zubwj"
    }
  },
  {
    id: "9b533d49-9f70-4f03-b666-7ed3dcd448db",
    title: "Open Eye Signal (George Fitzgerald Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02db394ae130056b01ec247c5d",
    // -> Artists: Jon Hopkins
    creators: artists.filter((artist) => ["afcec413-2dc5-4380-b37b-f7071c6a9e71", "f7da001a-b8ba-4d0a-9642-38bfb7f8813e"].includes(artist.id)),
    length: 328,
    keySignature: "6B",
    tempo: 123,
    links: {
      spotify: "https://open.spotify.com/track/6q9pf6G4NDArvpJTBSLUAP"
    }
  },

  // - 390 - //

  {
    id: "3f189993-6b04-4764-a7fe-1b5b5a1f4105",
    title: "Taste of Metal (Instrumental)",
    imageURL: "https://i1.sndcdn.com/artworks-000485408214-q1ov0p-t500x500.jpg",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 204,
    keySignature: "3B",
    tempo: 156,
    links: {
      spotify: "https://open.spotify.com/track/4Nv5cOy4oPgCOJXaMSjXsS"
    }
  },
  {
    id: "1231b50f-1aef-4834-aef4-fde7db13cc96",
    title: "Till We Fall",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0262e366662440369a979bb3f9",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 220,
    keySignature: "11B",
    tempo: 144,
    links: {
      spotify: "https://open.spotify.com/track/1seG2EJSgXLHCTPrCAdNXV"
    }
  },
  {
    id: "75f36551-19e2-46e8-8f76-0f02e995f4a3",
    title: "Flow Away",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2733d853638240926afe65e62b5",
    // -> Artist: Skeler
    creators: artists.filter((artist) => artist.id === "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22"),
    length: 155,
    keySignature: "4B",
    tempo: 124,
    links: {
      spotify: "https://open.spotify.com/track/04FXtfbY8KFILgf7P3mEul"
    }
  },
  {
    id: "3f02b30d-a1bb-4391-a2d7-2f3c0ea8adde",
    title: "Universe in a Kiss",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02fa92ae9c59eab536ae0bee6a",
    // -> Artist: Imer6ia
    creators: artists.filter((artist) => artist.id === "db18fc83-643c-4f03-aea9-83456cbc045c"),
    length: 326,
    keySignature: "11B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/0cntA5P1CztszsDTK71Fd2"
    }
  },
  {
    id: "85f1cb2a-2e63-4334-8187-a8af76d10a8c",
    title: "Reflex",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02148e1f0715c7e9c1034d722c",
    // -> Artist: SAINt JHN
    creators: artists.filter((artist) => artist.id === "85f1cb2a-2e63-4334-8187-a8af76d10a8c"),
    length: 242,
    keySignature: "3B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/2HhJ0b1AwX5Vgcnf3zzx1p"
    }
  },
  {
    id: "df588cb3-472d-40f0-b5be-11eeff693f89",
    title: "Regular",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022b31b8eb58c152d9e824d6b0",
    // -> Artist: The Range
    creators: artists.filter((artist) => artist.id === "87db04ed-4771-4c87-875f-05d82eeda5ec"),
    length: 201,
    keySignature: "9B",
    tempo: 112,
    links: {
      spotify: "https://open.spotify.com/track/4AzJyi4gvSbsXfd4HUEGiR"
    }
  },
  {
    id: "499aad06-0def-4244-ae6f-e14396d5c654",
    title: "Dakhma",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c9b2f5f9f853ec2f29a67694",
    // -> Artists: Øfdream, Imer6ia
    creators: artists.filter((artist) => ["e1c0dc8e-ef6f-4be8-b544-f59c78595481", "db18fc83-643c-4f03-aea9-83456cbc045c"].includes(artist.id)),
    length: 348,
    keySignature: "8B",
    tempo: 160,
    links: {
      spotify: "https://open.spotify.com/track/3WTq2JwxgyG0d1twSAHkpz"
    }
  },
  {
    id: "86cbe2b6-89d4-47d8-a786-8a7158e6a557",
    title: "Sad Alien",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d50d24d2e6b0c458c36df4ca",
    // -> Artist: Pensees
    creators: artists.filter((artist) => artist.id === "86c84cee-be8c-4d1f-a781-2b11ac5db480"),
    length: 234,
    keySignature: "7B",
    tempo: 119,
    links: {
      spotify: "https://open.spotify.com/track/0WRzhonYVhdhhph9jII7GX"
    }
  },
  {
    id: "0dbdd39d-a2bf-4ccb-9904-97e12134df96",
    title: "You Mean",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0299ef126a972968f598fcf796",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 216,
    keySignature: "1B",
    tempo: 138,
    links: {
      spotify: "https://open.spotify.com/track/6AzyTi3vfBHrOcEKiYLVCh"
    }
  },
  {
    id: "e4eebc19-8f01-4ba1-a3aa-8e8feae9ee1d",
    title: "Runaway",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028afeb5a757c9724442e902f1",
    // -> Artist: Tomkillsjerry
    creators: artists.filter((artist) => artist.id === "bf11c966-4935-4a96-a20a-7cc6e8a27a19"),
    length: 245,
    keySignature: "1B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1zrYeIg2pP3vcI0l1rUxBF"
    }
  },

  // - 400 - //

  {
    id: "c9c04990-9a16-4600-ab57-99c00911f294",
    title: "Evoke (Dyzphoria Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e020aadc24f0887594974a688b5",
    // -> Artists: TIGEREYES, Dyzphoria
    creators: artists.filter((artist) => ["ab918827-aa3e-4462-9a4d-dacf3759e37e", "169286b0-b3d4-4829-b080-8ba4d8c471ca"].includes(artist.id)),
    length: 291,
    keySignature: "2B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/1x5C3xtniMCLy9tO8Ook7E"
    }
  },
  {
    id: "c10ee633-3e8f-45f7-9c6e-5e19521e7f56",
    title: "Future Kids of America",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f78938c624f4228cbf1fdd8d",
    // -> Artist: Derek Pope
    creators: artists.filter((artist) => artist.id === "24c29208-60ef-4dde-943d-dc3c22fe9169"),
    length: 205,
    keySignature: "1B",
    tempo: 78,
    links: {
      spotify: "https://open.spotify.com/track/5afKpM4kEDPpxhhIwB2PPA"
    }
  },
  {
    id: "952b0d96-4281-46c4-9e4a-a42485506e75",
    title: "Dreamers",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027b48a01785b82a2493a97ca0",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 226,
    keySignature: "2B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/6HutR0IgWZfcFwIS9GLW8w"
    }
  },
  {
    id: "c0b12216-2be5-4e5d-a4d4-8820fa117cc6",
    title: "Patterns",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027b48a01785b82a2493a97ca0",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 175,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/7s1UUNgeeA9LjfYvJis20u"
    }
  },
  {
    id: "ca6fa96e-1cff-491a-8eca-0b3471fec68d",
    title: "Cosmica",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02327d4dad13cbac5647bdce42",
    // -> Artists: Sublab, Azaleh
    creators: artists.filter((artist) => ["37b5df35-6b7b-4ff8-b392-8d093a6793ef", "c50b5744-6800-49c4-b706-e79d7382a45f"].includes(artist.id)),
    length: 324,
    keySignature: "3B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/0a7NHZNby1UoaLCGGUXHMv"
    }
  },
  {
    id: "8ba4d7b7-cc98-4c90-969a-2758d425a944",
    title: "So Numb",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ea63d842b8863f56907c6e0e",
    // -> Artists: Blvck Ceiling, Linkin Park
    creators: artists.filter((artist) => ["a9093cff-72aa-4298-86e9-2630955ed61c", "72c3f167-7c79-4610-862d-3ec145993355"].includes(artist.id)),
    length: 223,
    keySignature: "2B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/7HUlN9cFHYRTBUnn6pt53t"
    }
  },
  {
    id: "b591618c-b7b2-4268-8d14-f482c5f8487d",
    title: "Blood Moon (MRKRYL Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0230aa04e522e15e9140a964e1",
    // -> Artists: Henryisdead, MRKRYL
    creators: artists.filter((artist) => ["b091c39f-9dfa-481e-a30b-ce707c27c801", "f0073e5b-c23a-4add-a29b-c5000e4c96d6"].includes(artist.id)),
    length: 322,
    keySignature: "11B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/25iNqzly4tvGJiRx8NC18C"
    }
  },
  {
    id: "0c735fdf-0626-4597-829f-3096f52352c7",
    title: "Chrome Tears",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a3e7c7ed8dae3d61b7eac8df",
    // -> Artist: Pixelord
    creators: artists.filter((artist) => artist.id === "45664f7f-b1d0-4962-8f79-25c4c86d86d9"),
    length: 192,
    keySignature: "8B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/2Gz01EIXEpmxKMhH9Go2yr"
    }
  },
  {
    id: "e7d99850-64e2-4435-9c12-515b5296ddc9",
    title: "Flare",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e754ef4aeadc5d37fe5f0ace",
    // -> Artist: Lostageless
    creators: artists.filter((artist) => artist.id === "d578cf18-8f1f-4a2b-8a40-d121f172442f"),
    length: 199,
    keySignature: "1B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/7jUew7pOOFd06T19A2CHKR"
    }
  },
  {
    id: "c46254f2-e7a5-4596-b8ca-60811d52f8ba",
    title: "Delivered By Fate",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02644ee6d2529c881c77369325",
    // -> Artist: Artonoise
    creators: artists.filter((artist) => artist.id === "598dfd8b-d9ed-429e-87a9-31e1c207293b"),
    length: 779,
    keySignature: "4B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 170, // -> Half time would be 85 bpm
    links: {
      spotify: "https://open.spotify.com/track/2BhCjynvW14wkZDogIi5Qi"
    }
  },

  // - 410 - //

  {
    id: "279df3e4-8d3d-4d88-af19-cc1501576898",
    title: "Night Shift",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022e8bb8b7ac6a5526babf34f2",
    // -> Artist: REMNANT.exe
    creators: artists.filter((artist) => artist.id === "08353957-64fa-45de-b3f1-f390bd03f433"),
    length: 226,
    keySignature: "11B",
    tempo: 132,
    links: {
      spotify: "https://open.spotify.com/track/6sDGwV6q4ijDl2emukNgtT"
    }
  },
  {
    id: "df05e96c-a713-4842-9a9e-95809895cacf",
    title: "Reasons",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e1b71919149ca17fa8d4367a",
    // -> Artist: Ravver
    creators: artists.filter((artist) => artist.id === "b86e14e8-7300-415a-88e8-1e71706910f2"),
    length: 239,
    keySignature: "3B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/4LR7aCbIkVx2CVUWJ8tuN0"
    }
  },
  {
    id: "cbd824d9-4cea-4097-8900-5e2206c51734",
    title: "Broken Cult",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027c423fd80eed0c30c3cf10ab",
    // -> Artist: Cotchi
    creators: artists.filter((artist) => artist.id === "c899764d-0bee-448e-8261-061b70e67890"),
    length: 267,
    keySignature: "4B",
    tempo: 97,
    links: {
      spotify: "https://open.spotify.com/track/7ltgZ2oFlT4toVppgVESKf"
    }
  },
  {
    id: "f202ef0b-abbd-4264-bbf4-5bff7e329ada",
    title: "Adore",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0213776bea2d35f40da65cff7e",
    // -> Artists: AK, Sublab
    creators: artists.filter((artist) => ["e3572967-0b7b-417a-8faa-740229402dcf", "37b5df35-6b7b-4ff8-b392-8d093a6793ef"].includes(artist.id)),
    length: 200,
    keySignature: "7B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/7xsYz1piIebb9WFfcYA1L2"
    }
  },
  {
    id: "4dec22e7-1f14-43c9-ab36-dd6d87dbea35",
    title: "Aero",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ca1f7e0cbc52b64ae4251e6d",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 199,
    keySignature: "10B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/31uvu1q6bNkMddXY60opor"
    }
  },
  {
    id: "d3f70bd5-4e2b-4e57-9df9-73e3314424da",
    title: "Turning Point",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023980bf5f9630594b70e3bced",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 193,
    keySignature: "11B",
    tempo: 144,
    links: {
      spotify: "https://open.spotify.com/track/6mhf7cyWPOdvh6YiIpGXIy"
    }
  },
  {
    id: "28244d1a-1061-4cff-a035-e52765640349",
    title: "Admire",
    imageURL: "https://i.scdn.co/image/ab67616d00001e025de30a2b016327c0fb8e34f1",
    // -> Artists: Pholo, gl00my
    creators: artists.filter((artist) => ["59ad7bf8-4667-45b6-96d4-87f70573b936", "483a9c78-cbfa-4b7c-8494-48e2e8631846"].includes(artist.id)),
    length: 151,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/6kTpVYekCxIc5GQEyTvCqt"
    }
  },
  {
    id: "e0025fe6-a17b-4b6b-9f1b-f3bf262a2216",
    title: "Do You",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a5579704efb7909f7c9790e9",
    // -> Artist: SOKOS
    creators: artists.filter((artist) => artist.id === "810e350b-0161-48e5-8c0e-e91c3f20abd6"),
    length: 255,
    keySignature: "10B",
    tempo: 125,
    links: {
      spotify: "https://open.spotify.com/track/5Unvggpgur3jFDyZgbjNwL"
    }
  },
  {
    id: "a6f3eaae-003b-42f8-8dd8-78d6c3aa7a15",
    title: "Tell Me",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022038d8d456fda8ef364b1799",
    // -> Artist: REMNANT.exe
    creators: artists.filter((artist) => artist.id === "08353957-64fa-45de-b3f1-f390bd03f433"),
    length: 212,
    keySignature: "3B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/5L8s9RYdijv7ACKDAuqLnv"
    }
  },
  {
    id: "f7a3c950-b5ad-4169-ba67-3c2b66d9bd0f",
    title: "Dark Tower",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0203af215f56490bab32558f0a",
    // -> Artist: Noah B
    creators: artists.filter((artist) => artist.id === "5613bb7a-d148-4164-a329-812a72eb74e5"),
    length: 244,
    keySignature: "3B",
    tempo: 118,
    links: {
      spotify: "https://open.spotify.com/track/2q8MMLlcnPyg6JrAWmj1Ez"
    }
  },

  // - 420 - //

  {
    id: "d5395e58-9e64-4fec-9622-a478fa7f567c",
    title: "Bowman",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2737ca0a5beed6b21ad2ff08dad",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 218,
    keySignature: "2B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 167,
    links: {
      spotify: "https://open.spotify.com/track/4CSCra3pJ8LfqUSaRgD7jr"
    }
  },
  {
    id: "4e1943e3-a464-4ad2-bd64-1232d18f37b2",
    title: "HEROIST",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27385ec2816775eae420551183f",
    // -> Artist: Apparat
    creators: artists.filter((artist) => artist.id === "e5a56d16-1348-49d3-9a53-7158a3d9436f"),
    length: 345,
    keySignature: "4B",
    tempo: 138,
    links: {
      spotify: "https://open.spotify.com/track/0roMCaFn5xz5yBH2o8mK76"
    }
  },
  {
    id: "a71ff0e9-afd2-4926-a510-bc1178b72ce9",
    title: "Hardwire",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0290b155a5b6e4ee035e9ce553",
    // -> Artist: Dark0
    creators: artists.filter((artist) => artist.id === "6b78d40f-35c5-49f0-899e-7d448c3178d5"),
    length: 194,
    keySignature: "4B",
    tempo: 138,
    links: {
      spotify: "https://open.spotify.com/track/0roMCaFn5xz5yBH2o8mK76"
    }
  },
  {
    id: "97ca2c80-c862-4f51-a03a-1d58de944919",
    title: "Drown",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028b123cd6721bff6d402e8f6c",
    // -> Artist: Gastah 
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 219,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4orMM8SIVPnrnSAbvp0Jg7"
    }
  },
  {
    id: "f729b09c-8e1e-4661-bd6f-b286152b30d8",
    title: "Last Memory Fades",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e409470b09def9406989ea91",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 244,
    keySignature: "10B",
    tempo: 132,
    links: {
      spotify: "https://open.spotify.com/track/5wgRASnk6AAgj64uozOBYP"
    }
  },
  {
    id: "cc46870f-5fb3-45be-89d4-1872d3f773d9",
    title: "Move On",
    imageURL: "https://i.scdn.co/image/ab67616d00001e020ae87790ea11eb558645c87f",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 349,
    keySignature: "10A",
    tempo: 80.5,
    links: {
      spotify: "https://open.spotify.com/track/4355BrOXuSxNoAt2wSxtlI?si=1d1c1653bfb24f06"
    }
  },
  {
    id: "6ac54d64-aa05-4db0-a16a-4a32bfd3fba7",
    title: "Stay",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02415463c8ee65ba48519c0289",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 240,
    keySignature: "2B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/230JSWdJAsuRiqercV05uO"
    }
  },
  {
    id: "4d4a231b-9aef-45f0-b396-2ced903c308e",
    title: "Resting In",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022405e082d32acdb371226cdc",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 178,
    keySignature: "6B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/1ngI2hCwA4CFmFtUCBMGR6"
    }
  },
  {
    id: "0c2bb17e-64c2-4c9b-8245-066239963a7c",
    title: "Unraveled",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ffd9ecc8d483f0eb5fdd108a",
    // -> Artist: Five Suns
    creators: artists.filter((artist) => artist.id === "cf86ac9a-861e-4ca5-930e-8b2ca003ea38"),
    length: 289,
    keySignature: "6B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/2vmZZs8brJbZzBipsfIOiQ"
    }
  },
  {
    id: "b662b999-b6b0-492d-b55d-65e2ba0b69b5",
    title: "Control",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2733a3d4a642f4bd182e12d945e",
    // -> Artist: Rat & Co
    creators: artists.filter((artist) => artist.id === "b662b999-b6b0-492d-b55d-65e2ba0b69b5"),
    length: 215,
    keySignature: "3B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 182,
    links: {
      spotify: "https://open.spotify.com/track/2BexxjK9I9iOcsDfy8s594"
    }
  },

  // - 430 - //

  {
    id: "027cd8e1-bea7-46fb-b5a8-bf392230d750",
    title: "Falling",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02bc73cd83a87959c0ac264579",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 279,
    keySignature: "10B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/4b3svQKxNOf0VnSk8mfGZq"
    }
  },
  {
    id: "66b942af-6123-49ae-9735-ba5409551126",
    title: "Adrift",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02782d8b3ed07fafed5f22454d",
    // -> Artist: iSorin
    creators: artists.filter((artist) => artist.id === "0adf3528-a9fd-4331-afdc-b46da832d82b"),
    length: 264,
    keySignature: "11B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/3gSh9nXQtxaornFrfHxAok"
    }
  },
  {
    id: "66a99d3e-45cf-4c3a-a721-78cc87de3263",
    title: "Indicate",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0215ac1100ca9bc99986e4f2ac",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 210,
    keySignature: "4B",
    tempo: 95,
    links: {
      spotify: "https://open.spotify.com/track/7D8FObvhT4xSWUDfq6tUIa"
    }
  },
  {
    id: "905df21d-d1ec-476d-b742-a1eb08b153eb",
    title: "What About You",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02eecca0ad56ba5b3cb1ff5ff8",
    // -> Artist: Kamandi
    creators: artists.filter((artist) => artist.id === "6c482670-8f76-4c2c-b15f-19af10a20ab8"),
    length: 196,
    keySignature: "11B",
    tempo: 108,
    links: {
      spotify: "https://open.spotify.com/track/2Up4Bwy4QSdFJLftDpNDXu"
    }
  },
  {
    id: "385c0fde-515a-4101-8745-0a686f098a39",
    title: "Tokyo Train",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cd39ea595a3ceac21cb5203b",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 220,
    keySignature: "4B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/18YjCNwwuuNZFLZHXQe31G"
    }
  },
  {
    id: "9085ff91-1e27-40bc-8b91-6d524ec66978",
    title: "Beneath Me",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d1495c3e607052ce182553bf",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 248,
    keySignature: "12B",
    tempo: 114,
    links: {
      spotify: "https://open.spotify.com/track/6A2RF5m6z0IvPDs4EbFIWv"
    }
  },
  {
    id: "84a0788a-23c3-4817-94c0-11978c24f456",
    title: "Stairway",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0217a42c78e843488e6ad70902",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 141,
    keySignature: "1B",
    tempo: 111,
    links: {
      spotify: "https://open.spotify.com/track/1wQIFaV1RTavwj8kqGVMrz"
    }
  },
  {
    id: "252739e0-b24c-49d1-b8de-7cb23a773730",
    title: "Almost Home",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0231cf84b55b54efea6d18153d",
    // -> Artist: nvrmore
    creators: artists.filter((artist) => artist.id === "9c7af4c3-9e9e-49c7-a162-0449f7e7c5a4"),
    length: 179,
    keySignature: "12B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/3nh7UXxtfuFK6iGPBdWH9J"
    }
  },
  {
    id: "d7938e6c-a1cd-4b7c-afd2-4fbfa944b4e4",
    title: "Lotus",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0231cf84b55b54efea6d18153d",
    // -> Artists: Skit, Kareful
    creators: artists.filter((artist) => ["f828cc93-b86e-4fe6-abde-40dfcc37686a", "ca5623c9-4268-47f0-8569-8a562e4e9bbe"].includes(artist.id)),
    length: 197,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4qEv3o9fRYpUrb9VenkC1P"
    }
  },
  {
    id: "ced437e5-038a-49d3-9645-5ca011b3a80d",
    title: "Oceans, Pt II (Gladkill Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026b45f1559fb2f5b4e0ed2c95",
    // -> Artists: Crywolf, Ianborg, Gladkill
    creators: artists.filter(
      (artist) => [
        "96ff7c46-0155-415d-8e89-803e6d0385dc", "d1577c2f-6c64-4255-b1ba-bf3c83f2cec6",
        "6953b6f9-d944-4383-95c2-a9e6228a6da9"
      ].includes(artist.id)
    ),
    length: 269,
    keySignature: "4B",
    tempo: 148,
    links: {
      spotify: "https://open.spotify.com/track/6WVQqICr3plhDfNsWUdFLV"
    }
  },

  // - 440 - //

  {
    id: "863f38bf-b095-49b6-b599-b51d3d02c51f",
    title: "Aureolin",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ec9eda611f783d8f452cb84f",
    // -> Artists: MISOGI, Voduz
    creators: artists.filter((artist) => ["808158bf-33a5-45ab-9143-851c31a01841", "c44c0d28-4fae-4a85-9cca-23e3177bd3e1"].includes(artist.id)),
    length: 212,
    keySignature: "9B",
    tempo: 118,
    links: {
      spotify: "https://open.spotify.com/track/6M8lvThFcokC6O4jbiVb57"
    }
  },
  {
    id: "13b34e5b-75e8-4bc4-a0b6-f29bcbf5b36b",
    title: "Isolated",
    imageURL: "https://i1.sndcdn.com/artworks-eKhU9Nz6doTnnKOF-yhGQzA-t500x500.jpg",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 207,
    keySignature: "8A",
    tempo: 111,
    links: {
      soundCloud: "https://soundcloud.com/kyddiekafka/isolated"
    }
  },
  {
    id: "eb13932f-1ada-4635-90ce-0a6d25dd1127",
    title: "Hexagram",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27315ac1100ca9bc99986e4f2ac",
    // -> Artist: Trashlord
    creators: artists.filter((artist) => artist.id === "c0338f80-6881-46b0-8e0e-ace39922443d"),
    length: 212,
    keySignature: "11B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/0WCCEcDQXZDlMFzseVjiX0"
    }
  },
  {
    id: "9862dad5-d28f-48c4-9fbb-e8ca77dce2e4",
    title: "I'm Gonna Live Forever",
    imageURL: "https://i.scdn.co/image/ab67616d00001e025e9cf50da1bf161a022089f7",
    // -> Artist: Da Vosk Docta
    creators: artists.filter((artist) => artist.id === "b323309d-9910-421b-a60e-161d8ac3633c"),
    length: 227,
    keySignature: "2B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/3KJJtuYAPwSdt2B3Sx8UzV"
    }
  },
  {
    id: "0a39acb3-9d29-4cab-a83a-6235e9c7e0f0",
    title: "Deep Down",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f6e963a2c1a65a1dd668d265",
    // -> Artist: Vacant
    creators: artists.filter((artist) => artist.id === "125105d9-775a-429c-ab9e-04f12f440cdc"),
    length: 293,
    keySignature: "3B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further, could be half time of this (88.5)
    tempo: 177,
    links: {
      spotify: "https://open.spotify.com/track/36ncF2SN4Och67K02kljQf"
    }
  },
  {
    id: "05424a75-0fa1-4ed1-ae47-55fdc2503244",
    title: "Whatever I Am",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02492d669bf3ffe16ad8d736cf",
    // -> Artists: Skit, foxwedding
    creators: artists.filter((artist) => ["f828cc93-b86e-4fe6-abde-40dfcc37686a", "4283d9e4-92ef-41f0-a6cf-b48897facae1"].includes(artist.id)),
    length: 196,
    keySignature: "12B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/2o8292IJhCH5NWjwDCBsma"
    }
  },  
  {
    id: "0942112b-ad02-4ef0-b87f-bf6b9e53d0ed",
    title: "Emerald Halls",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0267b435fd554b1f83d772d92f",
    // -> Artist: Kareful
    creators: artists.filter((artist) => artist.id === "ca5623c9-4268-47f0-8569-8a562e4e9bbe"),
    length: 226,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/7wokPgT5SPTGxit5mVevsB"
    }
  },
  {
    id: "374a03a3-d0a8-46f8-b76a-0b2538abc601",
    title: "Meta",
    imageURL: "https://i.scdn.co/image/ab67616d00001e020926520004e1365d702e5d27",
    // -> Artist: Chainless
    creators: artists.filter((artist) => artist.id === "3eb5e3bf-ebcc-4764-97a2-ab2f21a9a863"),
    length: 221,
    keySignature: "12B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further, could be half time of this (87)
    tempo: 174,
    links: {
      spotify: "https://open.spotify.com/track/3ixpBn8q20rqHWvQwKBkJW"
    }
  },
  {
    id: "54a82f75-f594-4faa-b5ed-885c3e5f8c74",
    title: "Searching",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0298d09c8f22e04ecd94be2032",
    // -> Artist: Dyzphoria
    creators: artists.filter((artist) => artist.id === "169286b0-b3d4-4829-b080-8ba4d8c471ca"),
    length: 204,
    keySignature: "3B",
    tempo: 127,
    links: {
      spotify: "https://open.spotify.com/track/25HLwPp2im6z5sjAOqZEnE"
    }
  },
  {
    id: "ab5f7204-8e6c-419e-8e8c-031c3eb2ae34",
    title: "Downfall",
    imageURL: "https://i1.sndcdn.com/artworks-eKhU9Nz6doTnnKOF-yhGQzA-t500x500.jpg",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 176,
    keySignature: "10A",
    tempo: 133.5,
    links: {
      soundCloud: "https://soundcloud.com/kyddiekafka/downfall"
    }
  },

  // - 450 - //

  {
    id: "e58b3242-2470-4d8b-a946-8517eccf38eb",
    title: "Dark Times",
    imageURL: "https://i1.sndcdn.com/artworks-eKhU9Nz6doTnnKOF-yhGQzA-t500x500.jpg",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 135,
    keySignature: "11A",
    tempo: 99.5,
    links: {
      soundCloud: "https://soundcloud.com/kyddiekafka/dark-times-downfall-ep"
    }
  },
  {
    id: "9bdb52c4-4882-49c0-b19c-e9ef84618709",
    title: "Fractals",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c20630771043949fe14400f6",
    // -> Artist: Kareful, LTHL
    creators: artists.filter((artist) => ["ca5623c9-4268-47f0-8569-8a562e4e9bbe", "a4b7e73e-d9c7-49bc-83cf-79cf78f78aaa"].includes(artist.id)),
    length: 233,
    keySignature: "1B",
    tempo: 132,
    links: {
      spotify: "https://open.spotify.com/track/5Wjzqz7rRpCcSV3SKqTNLd"
    }
  },
  {
    id: "7742b5b2-c41c-4718-9555-faf435403a23",
    title: "Luminoscity",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e67946acaf1aec7548814e37",
    // -> Artists: Skit, Kareful
    creators: artists.filter((artist) => ["f828cc93-b86e-4fe6-abde-40dfcc37686a", "ca5623c9-4268-47f0-8569-8a562e4e9bbe"].includes(artist.id)),
    length: 214,
    keySignature: "4B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/7DleNI8zar5Ff3434lORXG"
    }
  },
  {
    id: "f3c042ef-914d-41c1-a033-bec353bd780f",
    title: "Ghost Lite",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0231cf84b55b54efea6d18153d",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 226,
    keySignature: "3B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/55m8EdLatRDNIsR9qE1AhN"
    }
  },
  {
    id: "cb13e07a-7f59-495c-bd54-d133994d5176",
    title: "Cement",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27398dcfc644d6c13a724b3e0c9",
    // -> Artist: Klasey Jones
    creators: artists.filter((artist) => artist.id === "b09d61f7-4313-4862-9e08-3a3621275d93"),
    length: 289,
    keySignature: "1B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/57VM8YqkSLUbP3ovGQBW4g"
    }
  },
  {
    id: "ab8a4911-8c5a-404b-a1aa-b36538038ac4",
    title: "Nylon",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b4e3006b1e48ba76be9a0f19",
    // -> Artist: Noah B 
    creators: artists.filter((artist) => artist.id === "5613bb7a-d148-4164-a329-812a72eb74e5"),
    length: 289,
    keySignature: "1B",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1UsHLvLnGBCYWJflJWIFbp"
    }
  },
  {
    id: "d8016e20-5691-4b29-ada2-611d3d013194",
    title: "Assassination",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b29f45452917a92d6629aaf4",
    // -> Artists: Da Vosk Docta, MYSTXRIVL
    creators: artists.filter((artist) => ["b323309d-9910-421b-a60e-161d8ac3633c", "c7f75b69-5a29-4f60-a486-ace3354d3df6"].includes(artist.id)),
    length: 177,
    keySignature: "2B",
    tempo: 142,
    links: {
      spotify: "https://open.spotify.com/track/15KrREW0B5glUcyylbLdIw?si=52042cf99094456c"
    }
  },
  {
    id: "efd49e40-36e3-43e9-aba1-390c2c1e909d",
    title: "Iridescent",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023a54566749e16210ace2d62a",
    // -> Artist: Five Suns
    creators: artists.filter((artist) => artist.id === "cf86ac9a-861e-4ca5-930e-8b2ca003ea38"),
    length: 325,
    keySignature: "2B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/41SyUySjOYAMCBL9Me2JC7"
    }
  },
  {
    id: "8a856204-cf0a-4f11-92e6-10b3907e8622",
    title: "Crystalline",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023a54566749e16210ace2d62a",
    // -> Artist: Five Suns
    creators: artists.filter((artist) => artist.id === "cf86ac9a-861e-4ca5-930e-8b2ca003ea38"),
    length: 228,
    keySignature: "3B",
    tempo: 123,
    links: {
      spotify: "https://open.spotify.com/track/5xU0QzE2desifaWHTsb3FQ"
    }
  },
  {
    id: "d9e431ea-b18a-49f9-88f0-b2e178c8bad3",
    title: "Final Call",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273f75ec56e7854ae346d84ea63",
    // -> Artists: Skeler, Ytho
    creators: artists.filter((artist) => ["4f1bb9a6-fb96-499d-adbc-b9043ee1fa22", "a5fa3d99-781f-4468-bc2e-5ecd371992a6"].includes(artist.id)),
    length: 214,
    keySignature: "7B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/6FkRbq7Zt0RAizABKMOJuO"
    }
  },

  // - 460 - //

  {
    id: "e777e38f-7b17-4c17-8731-179c2cdbae41",
    title: "Deluge",
    imageURL: "https://i1.sndcdn.com/artworks-000144963293-wx3l5h-t500x500.jpg",
    // -> Artist: Kareful
    creators: artists.filter((artist) => artist.id === "ca5623c9-4268-47f0-8569-8a562e4e9bbe"),
    length: 192,
    keySignature: "12B",
    tempo: 138.5,
    links: {
      soundCloud: "https://soundcloud.com/trap-door-official/kareful-deluge"
    }
  },
  {
    id: "42bfd8e1-5c57-41b5-8990-a66f44746f71",
    title: "As She Cried",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27331cf84b55b54efea6d18153d",
    // -> Artist: Kareful
    creators: artists.filter((artist) => artist.id === "ca5623c9-4268-47f0-8569-8a562e4e9bbe"),
    length: 288,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5AZERLaNhVUPug8pPDYSmm"
    }
  },
  {
    id: "a8146c18-2b9e-445f-a01e-bfa9e651102e",
    title: "As She Cried (Fyoomz Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27331cf84b55b54efea6d18153d",
    // -> Artists: Kareful, Fyoomz
    creators: artists.filter((artist) => ["ca5623c9-4268-47f0-8569-8a562e4e9bbe", "195d1986-f79a-44d1-a36c-42634a2c1df0"].includes(artist.id)),
    length: 281,
    keySignature: "1B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/689yCmQlIORdqlRM3vX1HU"
    }
  },
  {
    id: "c2a5a0f1-cda4-48d6-beaa-9431289ef994",
    title: "Darkside",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27315ac1100ca9bc99986e4f2ac",
    // -> Artists: Spoze, nvrmore
    creators: artists.filter((artist) => ["2a45e62d-2bbe-4d0b-894a-0cf9c59fdb60", "9c7af4c3-9e9e-49c7-a162-0449f7e7c5a4"].includes(artist.id)),
    length: 265,
    keySignature: "3B",
    tempo: 105,
    links: {
      spotify: "https://open.spotify.com/track/3rHTf6LOX9MOVCkQY0B9hv"
    }
  },
  {
    id: "62a7e278-2e03-4994-8120-903aea778d18",
    title: "Stay!",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0274d93f881bbd26988e616f7d",
    // -> Artist: M!NGO 
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 260,
    keySignature: "1B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/26x6YgDyuxHzEKDMFu0rqU"
    }
  },
  {
    id: "5408913b-2025-4d92-8224-d399a33d2402",
    title: "Venice In Space",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273fea592d00606545962c47138",
    // -> Artist: Klasey Jones
    creators: artists.filter((artist) => artist.id === "b09d61f7-4313-4862-9e08-3a3621275d93"),
    length: 281,
    keySignature: "10B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/1nSptiJDeEVzVbsKhSfs06"
    }
  },
  {
    id: "2773dad6-b45a-4034-9554-4926110e66b1",
    title: "Battle Angel",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273fea592d00606545962c47138",
    // -> Artist: Klasey Jones
    creators: artists.filter((artist) => artist.id === "b09d61f7-4313-4862-9e08-3a3621275d93"),
    length: 302,
    keySignature: "6B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/7gxMZnQjNzTfaGOgV7i7j9"
    }
  },
  {
    id: "43c017f6-b6f7-489b-b3d3-f0572664ef9b",
    title: "ultima.",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2733c1107e398ae562f208d50fc",
    // -> Artists: hyperforms, théos
    creators: artists.filter((artist) => ["aa1ea277-75b1-45e0-aef8-3fda501c0334", "f1c1606b-5880-40e9-916b-c68faf4a39b0"].includes(artist.id)),
    length: 199,
    keySignature: "8B",
    tempo: 106,
    links: {
      spotify: "https://open.spotify.com/track/4mSkFzz5obE3fRR7JastTH"
    }
  },
  {
    id: "e30ada19-d183-4c62-811a-49e85a5e54ac",
    title: "Lost",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e131998db0fa5ddbac755a72",
    // -> Artists: Kareful
    creators: artists.filter((artist) => artist.id === "ca5623c9-4268-47f0-8569-8a562e4e9bbe"),
    length: 177,
    keySignature: "6B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/06zt2XBRUVFpnePcUPMVlS"
    }
  },
  {
    id: "8f0207ca-fc9d-4933-9b93-cdaa4de0c38a",
    title: "Hexan",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0234a15eb5aa42f4c25e072747",
    // -> Artists: MYSTXRIVL, SOKOS
    creators: artists.filter((artist) => ["c7f75b69-5a29-4f60-a486-ace3354d3df6", "810e350b-0161-48e5-8c0e-e91c3f20abd6"].includes(artist.id)),
    length: 192,
    keySignature: "8B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/0jwyntdePRC2oLIc7aRoy7"
    }
  },

  // - 470 - //

  {
    id: "56f1af02-aed4-4eac-9bf4-e6fd88dd9f7e",
    title: "Dyne",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273aa47dda37351b5d8af2a5b74",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 261,
    keySignature: "3B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/4UCxJssdyskC2vMvbcrheM"
    }
  },
  {
    id: "1c8d3a4e-5b1a-4e65-a3fb-65de2315c320",
    title: "Slip Away",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2735b3d4cecc9ce6217e95cc0c9",
    // -> Artist: Sidewalks and Skeletons
    creators: artists.filter((artist) => artist.id === "b57db24c-40e9-45f6-9409-9f6d4b82322e"),
    length: 202,
    keySignature: "2B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/7lNsROm6NcTSn2bX1YhwyZ"
    }
  },
  {
    id: "9710061b-f37a-465c-8697-51f75ff8faa6",
    title: "Give Everything",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ed9f28aff63b026eec3a8425",
    // -> Artists: MYSTXRIVL, Kareful
    creators: artists.filter((artist) => ["c7f75b69-5a29-4f60-a486-ace3354d3df6", "ca5623c9-4268-47f0-8569-8a562e4e9bbe"].includes(artist.id)),
    length: 227,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3WPIvAVJwiW2QWpWQfphoL"
    }
  },
  {
    id: "b053a345-5352-4aca-ad9d-806da7c3e7c3",
    title: "Children of Gaia",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02aa47dda37351b5d8af2a5b74",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 231,
    keySignature: "2B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1HbFovNkmIkEtAWIjzMIHT"
    }
  },
  {
    id: "a86e90a3-c831-4076-a7d8-6862274882eb",
    title: "Vanishing Point",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273342c3cffd96af85625e65ee2",
    // -> Artist: Synkro
    creators: artists.filter((artist) => artist.id === "b9c9c770-34cf-47c9-b314-dc9a2cf4315a"),
    length: 329,
    keySignature: "10B", 
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further. could be 88.5 (halftime)
    tempo: 170,
    links: {
      spotify: "https://open.spotify.com/track/5yR4yEe2yWGTFY2Qno5dKP"
    }
  },
  {
    id: "312c4e9c-8d71-4d12-9b8f-6836f7ce285a",
    title: "Why?",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a7618b5b079179b77e3bedb6",
    // -> Artist: Cardio
    creators: artists.filter((artist) => artist.id === "04c5f330-059b-4240-80ff-c471248dd417"),
    length: 228,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4OY395kmdKymBKjVLSwtmR"
    }
  },
  {
    id: "256c0bbf-b895-4d83-809d-81e56ad9700f",
    title: "WiFi",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27318aef38dd66a2e3eeb5da227",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 224,
    keySignature: "6B",
    tempo: 125,
    links: {
      spotify: "https://open.spotify.com/track/0HIZdebT7gtC6LRfrPMJ6S"
    }
  },
  {
    id: "5734be7f-3171-4c71-aeaf-c31285e18981",
    title: "Onsra",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f5e799ee661a5564ae525b75",
    // -> Artist: Skeler
    creators: artists.filter((artist) => artist.id === "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22"),
    length: 241,
    keySignature: "7B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3riXqWf7Ah7LS0oHn3DrhB"
    }
  },
  {
    id: "5f00963d-7413-499b-a720-b2bc014a1f00",
    title: "Diamonds",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e84d42c66e75f642110a1f4f",
    // -> Artist: Rayless
    creators: artists.filter((artist) => artist.id === "580d4a2b-4583-4ab1-a55a-98be5f193814"),
    length: 212,
    keySignature: "9B",
    tempo: 127,
    links: {
      spotify: "https://open.spotify.com/track/7JkURTRIk9MqdzwMRrGyeA"
    }
  },
  {
    id: "89efb1a3-41b2-44ee-aa5e-f5c6c8c27d08",
    title: "Chains",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0283622d2d26ec89806eefdb21",
    // -> Artists: Pholo, Jato 
    creators: artists.filter((artist) => ["59ad7bf8-4667-45b6-96d4-87f70573b936", "b14341ab-3d48-4170-841b-55255e13f3ea"].includes(artist.id)),
    length: 186,
    keySignature: "8B",
    tempo: 137,
    links: {
      spotify: "https://open.spotify.com/track/18gkwiim1XIk74AhEbPQrU"
    }
  },

  // - 480 - //

  {
    id: "624a8e21-623e-4725-bcbd-f003dc08c59b",
    title: "Inhuman",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a76fa99ce24be08c5358f374",
    // -> Artist: Enjoii
    creators: artists.filter((artist) => artist.id === "d2bc4672-f7cb-4ebd-a25e-9b78048a8b0f"),
    length: 203,
    keySignature: "10B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/5BfARpkatEjlsj9tiErcUA"
    }
  },
  {
    id: "d3e964a3-2bc1-43b3-9dd9-6babdee9e2bf",
    title: "Take Me Away",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ade177107fb43591350c8dde",
    // -> Artists: Five Suns, Cardio
    creators: artists.filter((artist) => ["cf86ac9a-861e-4ca5-930e-8b2ca003ea38", "04c5f330-059b-4240-80ff-c471248dd417"].includes(artist.id)),
    length: 271,
    keySignature: "9B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/4TEJ3l6RcK0ODIbw33wydb"
    }
  },
  {
    id: "a0d24a47-202f-49d9-ae4c-4131ef47e617",
    title: "GTR",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028cf76f65aaf7e44a3f665e3c",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 318,
    keySignature: "11B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/2LVEvMN14IkNamdhD2kMG7"
    }
  },
  {
    id: "e7cad9b0-54b3-4083-b44b-9dd0576d8822",
    title: "Unity",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e89f67dfc9d9099e8a346b9",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 298,
    keySignature: "12B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/4qPnWPJUomSoelIL97wkOv"
    }
  },
  {
    id: "1c51fee4-9952-4f77-8d3c-895d919d212f",
    title: "EVERYDAY ANYONE",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0236cd2e52d7970c6208958c07",
    // -> Artist: N O M I N A L
    creators: artists.filter((artist) => artist.id === "825781db-6a33-48cb-bb67-86b4a5bcb0fa"),
    length: 231,
    keySignature: "4B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/1FCvuPVt67WTBmDHR3nufc"
    }
  },
  {
    id: "c26b49b0-5708-406c-9cc4-0828645fe61f",
    title: "Apathy",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ff9408960a3ca1db6aa2791c",
    // -> Artists: Shlohmo, D33J
    creators: artists.filter((artist) => ["fcdbfea1-cbf2-4d2d-9575-3233ba310dad", "815a2782-1eaa-45ec-9a2d-2b7cd49523a9"].includes(artist.id)),
    length: 326,
    keySignature: "4B",
    tempo: 100,
    links: {
      spotify: "https://open.spotify.com/track/5IOHx7dO2JkNGSLrdkSrQx"
    }
  },
  {
    id: "828846c7-c321-46c4-995a-4e4a1dace323",
    title: "Headspace",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022f51f79751559fdfb974e983",
    // -> Artist: Kazukii
    creators: artists.filter((artist) => artist.id === "50609925-85a2-43e9-ad7a-86ed69e41b62"),
    length: 218,
    keySignature: "11B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/6sYTBT79lAAIUI9C6bLHMr"
    }
  },
  {
    id: "b2d2e064-da14-45bd-9d44-6cbe4eb2f817",
    title: "Ice",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0225921ac7df7a2b4fd81bf9ba",
    // -> Artist: chaøs
    creators: artists.filter((artist) => artist.id === "87fc1d5e-b3a8-4311-ad27-9cdb6dbd57a2"),
    length: 193,
    keySignature: "3B",
    // - TODO: -> Tempo seems slow, investigate further
    tempo: 159,
    links: {
      spotify: "https://open.spotify.com/track/7DpWAqLcK2M9vEe1p3nnzy"
    }
  },
  {
    id: "fbb27f44-3d4b-4ec9-918b-898335b6f931",
    title: "Lines",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02bc457208d83aa168ddad4e3c",
    // -> Artist: Landechoe
    creators: artists.filter((artist) => artist.id === "f56349f7-c4fc-4ce9-afc8-fe7ae8da90bf"),
    length: 233,
    keySignature: "4B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/159KGXUNcJ8a4EUXlF4ITQ"
    }
  },
  {
    id: "31229eae-a276-41a6-8455-9da2a4853b0e",
    title: "Galaxy",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02bc457208d83aa168ddad4e3c",
    // -> Artist: HYPEER
    creators: artists.filter((artist) => artist.id === "90d7b4dc-84bc-46a9-a84f-ea3b0c7b6b46"),
    length: 242,
    keySignature: "10B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/2DTWbuS3AbmsLFxSztKbWr"
    }
  },

  // - 490 - //

  {
    id: "009f3a0b-7e71-4327-8fa2-5154a813b591",
    title: "Divergent Arc",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739f3de5c6fe1fb46e7b2f0c1a",
    // -> Artists: Noah B, iSorin
    creators: artists.filter((artist) => ["5613bb7a-d148-4164-a329-812a72eb74e5", "0adf3528-a9fd-4331-afdc-b46da832d82b"].includes(artist.id)),
    length: 202,
    keySignature: "10B",
    tempo: 96,
    links: {
      spotify: "https://open.spotify.com/track/7bG0rm8JACoaSiYw1hI4II"
    }
  },
  {
    id: "464d5f51-ebbb-408d-ad3d-8666aae8017e",
    title: "Burn",
    imageURL: "https://i.scdn.co/image/ab67616d00001e024c65a586b3877f478cc582b4",
    // -> Artists: M!NGO, KAVERN
    creators: artists.filter((artist) => ["a77f263a-3296-4f8c-84ff-2208134ddcfe", "905a1bc8-add0-4ff9-a63a-92c6db6e3a23"].includes(artist.id)),
    length: 197,
    keySignature: "6B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/2b2wmulRcOyruI1QAzhuDR"
    }
  },
  {
    id: "46340442-8188-4261-8369-c7f0cd2ddd91",
    title: "River",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ffd9ecc8d483f0eb5fdd108a",
    // -> Artist: Five Suns 
    creators: artists.filter((artist) => artist.id === "cf86ac9a-861e-4ca5-930e-8b2ca003ea38"),
    length: 213,
    keySignature: "7B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/0hX3tc5FsmU2J8EnV63hlC"
    }
  },
  {
    id: "dd8a128a-a4d0-4676-a89b-77894bcb9c48",
    title: "Citadel VI",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b35dfa387c3c8ae3e3cb541a",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 234,
    keySignature: "4B",
    tempo: 94,
    links: {
      spotify: "https://open.spotify.com/track/7jOmEtV9eiRu1mOcikhTW2"
    }
  },
  {
    id: "4592367d-db18-40b1-a73e-ede25f33715f",
    title: "Respire",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b4e3006b1e48ba76be9a0f19",
    // -> Artist: AyyJay
    creators: artists.filter((artist) => artist.id === "7ee42c8a-2982-4760-a12f-b1b752967a4a"),
    length: 293,
    keySignature: "1B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/7pDIyFS4ZcfyIHhhMdCvo2"
    }
  },
  {
    id: "8094d549-d883-4c50-9912-bc08c46252a9",
    title: "Night Sky",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027e4f2362aa03da4f9f313d39",
    // -> Artist: AyyJay
    creators: artists.filter((artist) => artist.id === "7ee42c8a-2982-4760-a12f-b1b752967a4a"),
    length: 216,
    keySignature: "4B",
    tempo: 146,
    links: {
      spotify: "https://open.spotify.com/track/6jzXGuB4ln6Pd7ke8JnfYS"
    }
  },
  {
    id: "1d405b99-55d0-4fe9-af64-e101a4579b87",
    title: "Numb",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028c881ec1bc50e8189bb5b3b5",
    // -> Artist: AyyJay
    creators: artists.filter((artist) => artist.id === "7ee42c8a-2982-4760-a12f-b1b752967a4a"),
    length: 251,
    keySignature: "11B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/77aJ4OqZnJfTL9Rw3CZ6FJ"
    }
  },
  {
    id: "4950d007-37a9-4d3d-bd00-51f7c6967f61",
    title: "Drifts",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0271af0bd5d2dc899189f9ee53",
    // -> Artist: astral planes
    creators: artists.filter((artist) => artist.id === "28d8c46e-8702-459f-a849-319e2d940f39"),
    length: 188,
    keySignature: "4B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/6gL4G8pm54NDpBCSrKaMCG"
    }
  },
  {
    id: "aa55004b-7ab7-47be-82ce-91e764f376e6",
    title: "Gold Rose",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e89f67dfc9d9099e8a346b9",
    // -> Artist: Vexxy
    creators: artists.filter((artist) => artist.id === "aa8507e3-e295-442d-af3c-7036e9ef44aa"),
    length: 219,
    keySignature: "11B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/2rxq9hwfGHs8U9L4blytSs"
    }
  },
  {
    id: "258c84d4-3c64-47bb-8435-963cd40d9ce8",
    title: "Threat Level Midnight",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f2351edb0d7defb778eb8048",
    // -> Artists: XOX, Vexxy
    creators: artists.filter((artist) => ["2598c233-6f6a-4367-aa7e-be2261fe53d0", "aa8507e3-e295-442d-af3c-7036e9ef44aa"].includes(artist.id)),
    length: 202,
    keySignature: "4B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5x3y4edpZ9w3cGY9MrLHtU"
    }
  },

  // - 500 - //

  {
    id: "fddf6585-0a3a-46cc-93c3-0f3173a9c562",
    title: "Ice Castle",
    imageURL: "https://i1.sndcdn.com/artworks-000556361616-3c49wt-t500x500.jpg",
    // -> Artist: Vexxy
    creators: artists.filter((artist) => artist.id === "aa8507e3-e295-442d-af3c-7036e9ef44aa"),
    length: 198, // - TODO: -> Seems too fast, maybe half-time?
    keySignature: "7A",
    tempo: 169.5,
    links: {
      soundCloud: "https://soundcloud.com/seeksicksound/premiere-vexxy-ice-castle-out-june-27-on-italdred"
    }
  },
  {
    id: "fe6b13fe-72d2-4230-adbc-fefc12b58b16",
    title: "Science Trip",
    imageURL: "https://i1.sndcdn.com/artworks-000559309908-6431ph-t500x500.jpg",
    // -> Artists: gl00my, Mannequin
    creators: artists.filter((artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "5c63804d-531c-43d7-866a-58de5b67f5e4"].includes(artist.id)),
    length: 163,
    keySignature: "1B",
    tempo: 98.5, // - TODO: -> Seems too slow
    links: {
      soundCloud: "https://soundcloud.com/yumecollective/premiere-gl00my-mannequin-science-trip"
    }
  },
  {
    id: "af0e7e98-212e-433a-bfa8-fc3aee9b8934",
    title: "Solaris",
    imageURL: "https://i1.sndcdn.com/artworks-000634977841-bq7ipz-t500x500.jpg",
    // -> Artists: gl00my, Mannequin
    creators: artists.filter((artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "5c63804d-531c-43d7-866a-58de5b67f5e4"].includes(artist.id)),
    length: 120,
    keySignature: "3A",
    tempo: 159.5, // - TODO: -> Seems too fast, maybe half-time?
    links: {
      soundCloud: "https://soundcloud.com/gloomysounds/gl00my-mannequin-solaris-forthcoming-10112019"
    }
  },
  {
    id: "0e5fa61f-ad51-4dd3-aca0-6491b149a716",
    title: "Stay",
    imageURL: "https://i1.sndcdn.com/artworks-000338567301-ef0jfd-t500x500.jpg",
    // -> Artists: gl00my, Mannequin
    creators: artists.filter((artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "5c63804d-531c-43d7-866a-58de5b67f5e4"].includes(artist.id)),
    length: 188,
    keySignature: "5A",
    tempo: 137,
    links: {
      soundCloud: "https://soundcloud.com/gloomysounds/gl00my-mannequin-solaris-forthcoming-10112019"
    }
  },
  {
    id: "97914554-93cc-4067-a9e2-efa8431055dd",
    title: "Ausgang",
    imageURL: "https://i1.sndcdn.com/artworks-000602211988-x18stx-t500x500.jpg",
    // -> Artist: Mannequin
    creators: artists.filter((artist) => artist.id === "5c63804d-531c-43d7-866a-58de5b67f5e4"),
    length: 206,
    keySignature: "6A",
    tempo: 100.25, // - TODO: -> Seems too slow
    links: {
      soundCloud: "https://soundcloud.com/yumecollective/mannequin-ausgang-yume"
    }
  },
  {
    id: "fdc6063a-0e7c-407c-8b5e-1e2570ea5bd6",
    title: "Stwo Flip",
    imageURL: "https://i1.sndcdn.com/artworks-oaVSPwN9Ri29HurH-IOjSsQ-t500x500.jpg",
    // -> Artist: gl00my
    creators: artists.filter((artist) => artist.id === "483a9c78-cbfa-4b7c-8494-48e2e8631846"),
    length: 183,
    keySignature: "6A",
    tempo: 93.5,
    links: {
      soundCloud: "https://soundcloud.com/somethinginside/gl00my-stwo-flip"
    }
  },
  {
    id: "69f959e0-3f35-496a-8ba0-97e50af70b43",
    title: "Golden Hour",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02239f3b9573f20243606210cc",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 253,
    keySignature: "1B",
    tempo: 125,
    links: {
      spotify: "https://open.spotify.com/track/410T3i03Fhxo5OVYIF5HgX"
    }
  },
  {
    id: "48ff2e07-ed46-411d-8a9a-02010f14b9de",
    title: "Evoke",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2736ffe019f956ebdaefb322b07",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 169,
    keySignature: "2B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/1wHJs0FZ2rfnF6WPbf7J9D"
    }
  },
  {
    id: "14cc6adf-2092-4b46-8a3b-18a9fc2a9523",
    title: "Time Lapse",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026ffe019f956ebdaefb322b07",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 191,
    keySignature: "6B",
    tempo: 151,
    links: {
      spotify: "https://open.spotify.com/track/2CO6zld3nOnNQmY5um0wbS"
    }
  },
  {
    id: "8054f0d0-274d-4a5b-b666-c76616eb13b8",
    title: "Sublime",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2735f6079421dd6352f629a8a0a",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 172,
    keySignature: "3B",
    tempo: 151,
    links: {
      spotify: "https://open.spotify.com/track/7bd8iFWIBxlnMqOQaNGIZO"
    }
  },

  // - 510 - //

  {
    id: "196c8965-dd1f-4746-b7f0-040ad02e1d78",
    title: "Lucid",
    imageURL: "https://i1.sndcdn.com/artworks-ZFcAgldsDJzJhFCn-JctrLw-t500x500.jpg",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 241,
    keySignature: "3A",
    tempo: 91,
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/lucid"
    }
  },
  {
    id: "54162831-8a4d-4dd6-93f6-7c3e66d18ca9",
    title: "Velocity",
    imageURL: "https://i1.sndcdn.com/artworks-000605876554-jczc8k-t500x500.jpg",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 163,
    keySignature: "9A",
    tempo: 152,
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/velocity-wavevision-exclusive"
    }
  },
  {
    id: "16b9b97c-cf1e-4e02-997d-7df4e76389cb",
    title: "Behemoth (TIGEREYES Remix)",
    imageURL: "https://i1.sndcdn.com/artworks-000599187917-yru72v-t500x500.jpg",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 185,
    keySignature: "2A",
    tempo: 124.5,
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/bosa-behemoth-tigereyes-remix"
    }
  },
  {
    id: "5444d6af-9686-4bda-99df-391363096507",
    title: "Within You",
    imageURL: "https://i1.sndcdn.com/artworks-000470642241-cwr3dj-t500x500.jpg",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 179,
    keySignature: "7A",
    tempo: 173.5, // - TODO: -> Seems too fast
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/within-you"
    }
  },
  {
    id: "59e81b3c-3d05-467e-a79a-cc86b46a15cc",
    title: "Heaven",
    imageURL: "https://i1.sndcdn.com/artworks-000464658804-pnlfj5-t500x500.jpg",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 207,
    keySignature: "2A",
    tempo: 115.5,
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/heaven"
    }
  },
  {
    id: "818274da-37e3-4fd6-9d92-8ca5f9862023",
    title: "Moonlit",
    imageURL: "https://i1.sndcdn.com/artworks-000440080320-u70qyc-t500x500.jpg",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 219,
    keySignature: "9A",
    tempo: 129,
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/moonlit"
    }
  },
  {
    id: "a9c7a2b7-7e8d-4797-a3c6-7fe5f50f0326",
    title: "Mage",
    imageURL: "https://i1.sndcdn.com/artworks-000431853159-zkcqj4-t500x500.jpg",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 268,
    keySignature: "6A",
    tempo: 146.5,
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/mage"
    }
  },
  {
    id: "da3ecc9e-b79e-4935-bf6e-9c3743b58983",
    title: "A Good Feeling",
    imageURL: "https://i1.sndcdn.com/artworks-000430914903-zu8g5q-t500x500.jpg",
    // -> Artist: 
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 231,
    keySignature: "4A",
    tempo: 109.5, // - TODO: -> Seems too slow
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/a-good-feeling"
    }
  },
  {
    id: "e090a0e8-2656-4a70-9a83-f96b9110b4c6",
    title: "Chanel",
    imageURL: "https://i1.sndcdn.com/artworks-000214588904-4uc5zp-t500x500.jpg",
    // -> Artist: METAHESH
    creators: artists.filter((artist) => artist.id === "f587554a-2e53-4997-93a7-c1480c56d88f"),
    length: 159,
    keySignature: "6A",
    tempo: 98.5,
    links: {
      soundCloud: "https://soundcloud.com/metahesh/chanel"
    }
  },
  {
    id: "5663898f-c2d7-4b52-b4b5-6e4b14f7b01a",
    title: "Here, at the End of All Things",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0258dd326e049d097beba4eb96",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 250,
    keySignature: "4B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1q6vKnTqKtKFJRzDNu8Mb4"
    }
  },

  // - 520 - //

  {
    id: "94a9e181-d3c9-4c06-a970-79a62219aabf",
    title: "The Killing of a Giant",
    imageURL: "https://i.scdn.co/image/ab67616d00001e021e04a32481dab0e0a377c06e",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 260,
    keySignature: "4B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/6zakxAA0Mf1EA2pR8tgFQl"
    }
  },
  {
    id: "4af65630-0a78-4b21-a282-e21e3ddf31b5",
    title: "Too Little, Too Late",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27301d1dbc573121d8fb8ad0f2d",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 227,
    keySignature: "9B",
    tempo: 95,
    links: {
      spotify: "https://open.spotify.com/track/09jbhyiTluzHgHeEdCEGGb"
    }
  },
  {
    id: "2b988a9e-30b0-4733-bc57-0ab45f6a29f8",
    title: "medecine",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273f7d360fe9f4ee94724b599d2",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 207,
    keySignature: "4B",
    tempo: 102,
    links: {
      spotify: "https://open.spotify.com/track/6pr6sb0NygETFtpRgCJvCh"
    }
  },
  {
    id: "a1e25d59-4fc0-4073-8ac1-a05153e31ca7",
    title: "Unity",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e93cd69187159866028c690f",
    // -> Artists: NVRLUV, Unnholy
    creators: artists.filter((artist) => ["fe8f6f47-18fb-451b-a5d7-9914aa029bca", "33c1c9d8-6a75-4861-8664-a99b6452ab71"].includes(artist.id)),
    length: 214,
    keySignature: "4B",
    tempo: 146,
    links: {
      spotify: "https://open.spotify.com/track/23Bf7ad1zer3oampzY6dqX"
    }
  },
  {
    id: "ace2d831-a1b9-4a96-aa94-507b6ad0f06a",
    title: "Breathe",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029a72f16658823414e72b3357",
    // -> Artist: NVRLUV
    creators: artists.filter((artist) => artist.id === "fe8f6f47-18fb-451b-a5d7-9914aa029bca"),
    length: 148,
    keySignature: "11B",
    tempo: 144,
    links: {
      spotify: "https://open.spotify.com/track/5QCDTgNPukRMVDBm6hqAKf"
    }
  },
  {
    id: "1be68a3d-5b15-45d2-b561-7e9e4caf2db7",
    title: "I'll be Sitting Right Here",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022bee8c24b990590f7a9b6d8d",
    // -> Artist: NVRLUV
    creators: artists.filter((artist) => artist.id === "fe8f6f47-18fb-451b-a5d7-9914aa029bca"),
    length: 220,
    keySignature: "2B",
    tempo: 117,
    links: {
      spotify: "https://open.spotify.com/track/5sH584mX4L9sxpDiA5Ehwi"
    }
  },
  {
    id: "ff3d172a-6dd3-4426-8ce3-161d4e8ad48f",
    title: "Trouble",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0258ab93fe461d30ca98b49596",
    // -> Artist: NVRLUV
    creators: artists.filter((artist) => artist.id === "fe8f6f47-18fb-451b-a5d7-9914aa029bca"),
    length: 209,
    keySignature: "5B",
    tempo: 141,
    links: {
      spotify: "https://open.spotify.com/track/7mJPosYhFaMiVbTdBiCLU9"
    }
  },
  {
    id: "93b998d8-1425-439e-8778-0ba6a74e560d",
    title: "Take Me Away",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026e6a508b629ce8334b018ddd",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 189,
    keySignature: "4B",
    tempo: 125,
    links: {
      spotify: "https://open.spotify.com/track/2t3ruOXR6YStqGbP1QaQmf"
    }
  },
  {
    id: "845afe7a-0fc6-4f74-a6d7-14cf2a412e14",
    title: "Mystic Night",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022d50288aab99ca226e64ba68",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 274,
    keySignature: "12B",
    tempo: 128,
    links: {
      spotify: "https://open.spotify.com/track/1sJv4JfKVeaSgVbqN0uf3T"
    }
  },
  {
    id: "58d0943e-e066-4969-8081-4f74834873bc",
    title: "Vision Field",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ebc03ee90ce597d704e01304",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 181,
    keySignature: "10B",
    tempo: 133,
    links: {
      spotify: "https://open.spotify.com/track/38KsBKlhSC8YwhUxzPOjnk"
    }
  },

  // - 530 - //

  {
    id: "2e6d1c01-d87e-42e4-8e72-dedcfa5bf76f",
    title: "Ponyo",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029ac066107cb887d61afb3928",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 202,
    keySignature: "12B",
    tempo: 129,
    links: {
      spotify: "https://open.spotify.com/track/69RsOsMy8zhbViLNIRgq6h"
    }
  },
  {
    id: "6ef11711-d90d-47e2-95f2-d3e8f91cd06b",
    title: "Dreamscape '95",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d2945e30b4f63a73173d32aa",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 285,
    keySignature: "1B",
    tempo: 125,
    links: {
      spotify: "https://open.spotify.com/track/7nhBJvKNRqNtXHLNLBxcWU"
    }
  },
  {
    id: "69e02d94-ee5a-42d8-b6e3-8bc499a005b4",
    title: "Terminal (2017 Master)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cf28f77cec224ef158ba8f62",
    // -> Artists: Klimeks, Skit
    creators: artists.filter((artist) => ["9a4110ff-7417-431f-afb1-02d3d9d385fa", "f828cc93-b86e-4fe6-abde-40dfcc37686a"].includes(artist.id)),
    length: 210,
    keySignature: "1B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/1DlBm4IVcu3iBQNwtfMNiR"
    }
  },
  {
    id: "6d04a8f1-d2e0-48f1-bfd8-6060be4e04f7",
    title: "Nightglide",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d1495c3e607052ce182553bf",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 242,
    keySignature: "1B",
    tempo: 114,
    links: {
      spotify: "https://open.spotify.com/track/3P4RewkUI2M5KCEsdRwFOB"
    }
  },
  {
    id: "46d46220-a533-4da2-82ee-b1808217c581",
    title: "Beneath Me",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d1495c3e607052ce182553bf",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 248,
    keySignature: "12B",
    tempo: 114,
    links: {
      spotify: "https://open.spotify.com/track/6A2RF5m6z0IvPDs4EbFIWv"
    }
  },
  {
    id: "e035d080-74d0-4645-94a4-e16d0680e3be",
    title: "Venus",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273d2945e30b4f63a73173d32aa",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 243,
    keySignature: "9B",
    tempo: 124,
    links: {
      spotify: "https://open.spotify.com/track/1aLJ9hS4le2qCMwF7T2yM7"
    }
  },
  {
    id: "dd862c45-1062-4578-b759-ef733543efbe",
    title: "Connexion",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b027f848d7062c912bd74656",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 224,
    keySignature: "8B",
    tempo: 123,
    links: {
      spotify: "https://open.spotify.com/track/2rhPyigGB6tTom9DS1JEvu"
    }
  },
  {
    id: "24dc8917-1a5f-4628-94f1-053bbd21b082",
    title: "Orca - Waved Edition",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b027f848d7062c912bd74656",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 293,
    keySignature: "6B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/6l9AP7oUcxpn1tJUOxnJFT"
    }
  },
  {
    id: "5fcd07b4-b780-4df7-bd0d-b33cfa0d0640",
    title: "Fine Night",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b027f848d7062c912bd74656",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 223,
    keySignature: "12B",
    tempo: 126,
    links: {
      spotify: "https://open.spotify.com/track/5TXPuo3OQJR0nFoH6WaU81"
    }
  },
  {
    id: "07917db1-a077-4e87-8aa8-4c49e0d5405b",
    title: "Scuba",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d9014b05dc0a7f8ad9066c2c",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 165,
    keySignature: "1B",
    tempo: 119,
    links: {
      spotify: "https://open.spotify.com/track/6wapCRnDCaew6v7nQJkAj9"
    }
  },

  // - 540 - //

  {
    id: "49617794-a4bb-4911-b755-2b6316d03d11",
    title: "Beachaus",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d9014b05dc0a7f8ad9066c2c",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 198,
    keySignature: "1B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/4A8pfRjSc0U3xA73UtxIA0"
    }
  },
  {
    id: "c81f0d95-a906-4652-9fef-27a8809042b6",
    title: "Ayahuasca",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d9014b05dc0a7f8ad9066c2c",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 171,
    keySignature: "3B",
    tempo: 122,
    links: {
      spotify: "https://open.spotify.com/track/35KQkUqxQtIKtkSzpuJsi4"
    }
  },
  {
    id: "6316989a-29e5-4450-85e7-1ead1610ed65",
    title: "Cudinites",
    imageURL: "https://i1.sndcdn.com/artworks-rWwfgWUjVikqzOjx-qHXQFA-t500x500.jpg",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 139,
    keySignature: "1A",
    tempo: 87.3, // - TODO: -> Tempo seems slow
    links: {
      soundCloud: "https://soundcloud.com/klimeks/cudinites"
    }
  },
  {
    id: "9fff4361-8bd7-41c8-a452-9bf8b8bda5c6",
    title: "Wake You (prod. Klimeks)",
    imageURL: "https://i1.sndcdn.com/artworks-000029409711-p3ic1k-t500x500.jpg",
    // -> Artists: Iam1, Klimeks
    creators: artists.filter((artist) => ["6fc3d217-95e6-4388-a216-3bd8e5619865", "9a4110ff-7417-431f-afb1-02d3d9d385fa"].includes(artist.id)),
    length: 264,
    keySignature: "2A",
    tempo: 92.5,
    links: {
      soundCloud: "https://soundcloud.com/klimeks/iam1-wake-you-produced-by-klimeks"
    }
  },
  {
    id: "b89e9e08-f1d8-4059-8d7a-4e1078b44709",
    title: "Pure White",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023e48a3ef21c017fe880ba241",
    // -> Artists: MYSTXRIVL, Enjoii
    creators: artists.filter((artist) => ["c7f75b69-5a29-4f60-a486-ace3354d3df6", "d2bc4672-f7cb-4ebd-a25e-9b78048a8b0f"].includes(artist.id)),
    length: 238,
    keySignature: "6B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/446V9vFQzM29cTGUykLzpV"
    }
  },
  {
    id: "8bd28f89-9b59-4c47-a95d-7ee0bf4bd441",
    title: "Nefarious",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022d012b94cd4e6dd899436f26",
    // -> Artist: MYSTXRIVL
    creators: artists.filter((artist) => artist.id === "c7f75b69-5a29-4f60-a486-ace3354d3df6"),
    length: 195,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/1qjvm6k8eha3xZkZ2LlWnR"
    }
  },
  {
    id: "97088a80-5ab9-45da-8373-5a4f73bc2717",
    title: "Give Everything",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ed9f28aff63b026eec3a8425",
    // -> Artist: MYSTXRIVL
    creators: artists.filter((artist) => artist.id === "c7f75b69-5a29-4f60-a486-ace3354d3df6"),
    length: 227,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3WPIvAVJwiW2QWpWQfphoL"
    }
  },
  {
    id: "b7f2a84b-7b53-45a6-8a24-c3f671eca679",
    title: "We Once Knew",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0260923a13dd6274574aa47ce7",
    // -> Artist: Jaga
    creators: artists.filter((artist) => artist.id === "d3773310-fb18-4a8c-850d-0de9d2cd0499"),
    length: 330,
    keySignature: "10B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/0LG5JGMmV5rpnYVuW9F3Co"
    }
  },
  {
    id: "3809d3a8-9a99-455c-8b4f-355d7ab802a0",
    title: "Pilot",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022d4baec5608ffeeef9d4db34",
    // -> Artist: Jaga
    creators: artists.filter((artist) => artist.id === "d3773310-fb18-4a8c-850d-0de9d2cd0499"),
    length: 224,
    keySignature: "9B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/3bP7IiSYR2YVN4NPyJsFKa"
    }
  },
  {
    id: "d8203d5d-6945-4b2c-a786-2ebcb4722d65",
    title: "Ghost Peppers",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02aba12268ba070bf89cd45f60",
    // -> Artist: Jaga
    creators: artists.filter((artist) => artist.id === "d3773310-fb18-4a8c-850d-0de9d2cd0499"),
    length: 244,
    keySignature: "3B",
    tempo: 109,
    links: {
      spotify: "https://open.spotify.com/track/5QxjI42r6qYfkAYM9CNv4G"
    }
  },
  {
    id: "22bd86c5-6e59-4a02-aca4-596426296585",
    title: "Sometime",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0202ccb614c84f924730cdacfe",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 150,
    keySignature: "2B",
    tempo: 90,
    links: {
      spotify: "https://open.spotify.com/track/7n8KIoK18ZU4OY3NBcMv6B"
    }
  },
  {
    id: "060fdc56-d1a9-4c7c-9d25-f910174765a9",
    title: "End of Cycle",
    imageURL: "https://i1.sndcdn.com/artworks-000555769470-9pv5uw-t500x500.jpg",
    // -> Artists: Øfdream, hidden jayeem
    creators: artists.filter((artist) => ["e1c0dc8e-ef6f-4be8-b544-f59c78595481", "cf7d712d-49ad-41f8-9e6f-8df8679ccfba"].includes(artist.id)),
    length: 208,
    keySignature: "5A",
    tempo: 99,
    links: {
      soundCloud: "https://soundcloud.com/jayeem_music/ofdream-w-hidden-jayeem-end-of-cycle"
    }
  },
  {
    id: "eb85e160-ace5-4ccf-ad4b-87e428198faf",
    title: "Sick and D",
    imageURL: "https://i1.sndcdn.com/artworks-000108732719-li3vlp-t500x500.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 256,
    keySignature: "4A",
    tempo: 149, // - TODO: -> Seems potentially too fast
    links: {
      soundCloud: "https://soundcloud.com/nastya25-1/fdream-sick-and-d"
    }
  },
  {
    id: "ba1daa01-f277-4e91-b28c-395e0b06f291",
    title: "Collosus",
    imageURL: "https://i1.sndcdn.com/artworks-000478875462-28y54c-t500x500.jpg",
    // -> Artists: Imer6ia, Øfdream
    creators: artists.filter((artist) => ["", "e1c0dc8e-ef6f-4be8-b544-f59c78595481"].includes(artist.id)),
    length: 226,
    keySignature: "10A",
    tempo: 95,
    links: {
      soundCloud: "https://soundcloud.com/imer6ia/colossus"
    }
  },
  {
    id: "c992543f-3e40-4421-af01-84b6bdb032e2",
    title: "Real Answer",
    imageURL: "https://i1.sndcdn.com/artworks-DAkw1hAwV10y5E6z-3MqlxA-t500x500.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 295,
    keySignature: "3A",
    tempo: 100,
    links: {
      soundCloud: "https://soundcloud.com/smuggled/real-answer-ofdream"
    }
  },
  {
    id: "cead2fc7-cca5-496d-8934-2e9af147847c",
    title: "Walking on the Abyss",
    imageURL: "https://i1.sndcdn.com/avatars-000223270067-foo6pp-t500x500.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 193,
    keySignature: "1A",
    tempo: 97.3,
    links: {
      soundCloud: "https://soundcloud.com/queer_666/ofdream-walking-on-the-abyss"
    }
  },
  {
    id: "5ef02b73-72cb-44c2-a7f6-c95fd9f75428",
    title: "We Live in a Hologram (prod. Øfdream)",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artists: SPOZE, Øfdream 
    creators: artists.filter((artist) => ["e1c0dc8e-ef6f-4be8-b544-f59c78595481", "2a45e62d-2bbe-4d0b-894a-0cf9c59fdb60"].includes(artist.id)),
    length: 217,
    keySignature: "3A",
    tempo: 145.5,
    links: {
      googleDrive: "https://drive.google.com/file/d/1KhB4tRKJd5bmiLsDsSolgI6w-iMxpgFE/view?usp=sharing"
    }
  },
  {
    id: "bcb7e5dc-6f2a-4487-93b0-e0210dd541cf",
    title: "Eternal Valley (Nothing Here Vision)",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artists: Øfdream, Imer6ia, Nothing Here
    creators: artists.filter(
      (artist) => [
        "e1c0dc8e-ef6f-4be8-b544-f59c78595481", "db18fc83-643c-4f03-aea9-83456cbc045c",
        "d6566464-b823-466d-b458-2d266fc347da"
      ].includes(artist.id)
    ),
    length: 365,
    keySignature: "1A",
    tempo: 94.5,
    links: {
      googleDrive: "https://drive.google.com/file/d/1XvJLDspNfgTnaCGXVZcQlQ4Uhuo6YZO3/view?usp=sharing"
    }
  },
  {
    id: "7c3a69f7-898a-4737-a757-460770ef6fb9",
    title: "Shadows (Øfdream Vision)",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artists: Pryda, Øfdream
    creators: artists.filter((artist) => ["c4436288-a86a-4b42-8b7a-6120fd399a97", "e1c0dc8e-ef6f-4be8-b544-f59c78595481"].includes(artist.id)),
    length: 250,
    keySignature: "10A",
    tempo: 163.5,
    links: {
      googleDrive: "https://drive.google.com/file/d/1tZapHBC_Dja1HukgR0AB7scptlL17Tcr/view?usp=sharing"
    }
  },
  {
    id: "d18275c6-5bdc-4055-a85d-ff18df22d130",
    title: "The Golden Age",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 228,
    keySignature: "8A",
    tempo: 178.3, // - TODO: -> Seems fast, maybe halftime?
    links: {
      googleDrive: "https://drive.google.com/file/d/16XgDSBDU-35gMflCEfMqXvzZuOJABJNO/view?usp=sharing"
    }
  },
  {
    id: "564c47f3-8ee8-43cf-9636-373d02e31daa",
    title: "When You Believe (Scars Vision)",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artists: Øfdream, Scars
    creators: artists.filter((artist) => ["e1c0dc8e-ef6f-4be8-b544-f59c78595481", "e11311c9-3bed-4ea9-b231-48b054ca23ca"].includes(artist.id)),
    length: 189,
    keySignature: "2A",
    tempo: 83,
    links: {
      googleDrive: "https://drive.google.com/file/d/18QKwH9-3QSRuh1uFklmTULL6gi-vOF_3/view?usp=sharing"
    }
  },
  {
    id: "c858563a-2e31-47d9-a5bb-35958b3b2eb4",
    title: "In Another Quest (feat. NOCEL)",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artists: Øfdream, NOCEL
    creators: artists.filter((artist) => ["e1c0dc8e-ef6f-4be8-b544-f59c78595481", "1c12aeb6-2b39-4230-8f82-d5d3c393ed6c"].includes(artist.id)),
    length: 197,
    keySignature: "5A",
    tempo: 90,
    links: {
      googleDrive: "https://drive.google.com/file/d/154HRfvKBPHG99yY2PmeESvvR6CAGiyof/view?usp=sharing"
    }
  },
  {
    id: "824ca5ed-27d5-4d30-b3d5-7f203ef7d7b6",
    title: "Certain Degree",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 266,
    keySignature: "4A",
    tempo: 90.3,
    links: {
      googleDrive: "https://drive.google.com/file/d/14h7wCpeWXXiWUOyZU9jwW0qJEtgN7_zh/view?usp=sharing"
    }
  },
  {
    id: "a9f4ca91-8e8c-4a9c-9b62-d6cd96b0969c",
    title: "Our Father Enki",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 206,
    keySignature: "3A",
    tempo: 179, // - TODO: -> Seems fast, maybe half-time (89.5)
    links: {
      googleDrive: "https://drive.google.com/file/d/15cDhm7l0ge5FZynH5ljg-zAKmOfDPPnU/view?usp=sharing"
    }
  },
  {
    id: "eabf2d9c-af6e-409b-b7fc-0acf269c21fc",
    title: "Asylum",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 182,
    keySignature: "6A",
    tempo: 120,
    links: {
      googleDrive: "https://drive.google.com/file/d/14lbrhYXIzThIjkyYoTKXGF8KcGAQtE8c/view?usp=sharing"
    }
  },
  {
    id: "caed9f66-8506-4904-8dcc-eb28fb17a3d3",
    title: "Sometime",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 152,
    keySignature: "12A",
    tempo: 121.3, // - TODO: -> Seems fast
    links: {
      googleDrive: "https://drive.google.com/file/d/16BfVUv9_Ng4kmE9j2z8nLyUl75J4WGku/view?usp=sharing"
    }
  },
  {
    id: "56f45bde-f199-4bc9-a0b1-1368f33656be",
    title: "Game Is On",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 176,
    keySignature: "3A",
    tempo: 120,
    links: {
      googleDrive: "https://drive.google.com/file/d/151FHA5WnqcfQreR5bbwa_AnmuUa43UuW/view?usp=sharing"
    }
  },
  {
    id: "fdc3b0ad-e925-4723-8b89-9a6b50bab0a5",
    title: "The Great Beast",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 240,
    keySignature: "6A",
    tempo: 88.8,
    links: {
      googleDrive: "https://drive.google.com/file/d/14_5ew_DJKajx3AbiHATKGPeNI-6kDY3_/view?usp=sharing"
    }
  },
  {
    id: "bf873414-ee9a-43e7-a60d-bdd673b35e18",
    title: "Out of Trouble",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 211,
    keySignature: "11A",
    tempo: 101.3,
    links: {
      googleDrive: "https://drive.google.com/file/d/15uqijLmO4BcPujgFstKsJnN9ZB8wwMdp/view?usp=sharing"
    }
  },
  {
    id: "57026c9a-57ee-4cb9-bce7-48ed4d8e1f7c",
    title: "Any Obstruction",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 231,
    keySignature: "11A",
    tempo: 112,
    links: {
      googleDrive: "https://drive.google.com/file/d/15qkggyYKAKQAV4CtQjNYFgYXlHMiV13B/view?usp=sharing"
    }
  },
  {
    id: "a73e4517-45f7-4c4b-844a-f0cbb0f7936c",
    title: "Eye of God",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 200,
    keySignature: "5A",
    tempo: 80, // - TODO: -> Seems slow
    links: {
      googleDrive: "https://drive.google.com/file/d/14uYSXMpBH1IL8a3sZ237SWLY1A45uL2b/view?usp=sharing"
    }
  },
  {
    id: "1371e261-a023-42c1-9ebe-1c60560f1970",
    title: "Hermetic Law",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 216,
    keySignature: "11A",
    tempo: 154, // - TODO: -> Seems fast
    links: {
      googleDrive: "https://drive.google.com/file/d/151tisILrEjInYLN4OGlsYrYrQRblFrCH/view?usp=sharing"
    }
  },
  {
    id: "0b882822-fab0-4fb2-bc48-b7c085aae4b4",
    title: "Cold Reach",
    imageURL: "https://f4.bcbits.com/img/a1502052776_16.jpg",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 233,
    keySignature: "6A",
    tempo: 93.3, // - TODO: -> Seems slow
    links: {
      bandCamp: "https://gastah.bandcamp.com/track/cold-reach"
    }
  },
  {
    id: "2ab31996-cc26-45e3-b4b7-c2d4d33bc906",
    title: "Marks of Despair",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273328221a91e22e06b7bf79138",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 227,
    keySignature: "11A",
    tempo: 76,
    links: {
      googleDrive: "https://drive.google.com/file/d/15LhR6VfaHnzlk8pVqmgLYmPWPyDuiWB-/view?usp=sharing"
    }
  },
  {
    id: "fbcfa60e-a9bd-42bb-9038-06137405cda1",
    title: "Succubus",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 262,
    keySignature: "1A",
    tempo: 100, // - TODO: -> Seems slow
    links: {
      spotify: "https://drive.google.com/file/d/15z8GI0fz3cNxw0xQEVHV9xf1Ghj-w4zs/view?usp=sharing"
    }
  },
  {
    id: "bb63a053-86c3-42fe-af10-8326565d42dc",
    title: "One to Pursue",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 183,
    keySignature: "12A",
    tempo: 125.5,
    links: {
      googleDrive: "https://drive.google.com/file/d/14OY5219zZWV7xaUkkhDz67XgD-Q6ahr1/view?usp=sharing"
    }
  },
  // {
  //   id: "",
  //   title: "",
  //   imageURL: "",
  //   // -> Artist: 
  //   creators: artists.filter((artist) => artist.id === ""),
  //   length: 0,
  //   keySignature: "1B",
  //   tempo: 1,
  //   links: {
  //     spotify: ""
  //   }
  // },
  // {
  //   id: "",
  //   title: "",
  //   imageURL: "",
  //   // -> Artists: 
  //   creators: artists.filter((artist) => ["", ""].includes(artist.id)),
  //   length: 0,
  //   keySignature: "1B",
  //   tempo: 1,
  //   links: {
  //     spotify: ""
  //   }
  // },
]
