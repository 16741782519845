/* eslint-disable @typescript-eslint/no-unused-vars */
// -> Beyond Codebase
import React, { useContext, useEffect, useState } from 'react';
import { css } from 'aphrodite-jss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// -> Within Codebase
import FillUnderNavBarCradle from '../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle';
import VerticalFade from '../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade';
import CircleLoader from '../../Components/Loaders/CircleLoader/CircleLoader';
import Fade from '../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade';
import Spacer from '../../Components/LayoutUtilities/Spacer/Spacer';
import StatusMessage from '../../Components/VisualUtilities/StatusMessage/StatusMessage';
import { XIcon } from '../../Components/VisualUtilities/IconPresets';
import { ThemeInfo, UIContext } from '../../Components/UI_InfoProvider/UI_InfoProvider';
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import {
  ADMIN_DASHBOARD_ROUTE,
  AUTHENTICATE_MFA_ROUTE, DEFAULT_TRANSITION_MICROANIMATION_TIME, PROFILE_ROUTE,
  REGISTRATION_ROUTE, REQUEST_RESET_ROUTE, VERTICAL
} from "../../constants"
import { mapHttpErrorCodeToMessage, underConstructionAlert, customAlertGen } from "../../helpers/";
import { ILoginArgs } from '../../Components/AuthContextProvider/helpers';
import { MFAScenario } from "../../Types";
// -> Within Component
import LoginCard from "./InternalComponents/LoginCard/LoginCard";
import { ILoginState } from "./helpers";
import { styleGen } from './LoginStyles';

const Login: React.FC = () => {
  const [state, setState] = useState<ILoginState>({
    loadingStatus: false,
    errorStatus: false,
    loginCardInStatus: true,
    loaderInStatus: false,
  })
  const history = useHistory();
  // const { state: locationState }: RouteProps["location"] = useLocation(); // - TODO: -> Figure out Typescript blocker here.
  const { t } = useTranslation("page_login");
  const { state: locationState }: any = useLocation(); // - TODO: -> Figure out Typescript blocker here.
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { palette, distance } = themeInfo;
  const { login } = useContext(AuthContext)
  const { pageCradle, mainContentCradle } = styleGen(themeInfo);
  
  let loginCardTimeout: ReturnType<typeof setTimeout>;
  let loaderTimeout: ReturnType<typeof setTimeout>;
  let redirectURL: string | undefined = undefined;

  if (locationState && locationState.redirectURL) redirectURL = locationState.redirectURL.pathname;

  // -> Clean up resources to avoid memory leaks.
  useEffect(() => {
    return () => {
      clearTimeout(loginCardTimeout);
      clearTimeout(loaderTimeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  ////////////////////////////////////
  // - onLoginSubmission(): START - // ---------------------------------------------------------------------
  ////////////////////////////////////

  const onLoginSubmission = async (values: ILoginArgs) => {
    // -> Allow login card to transition down before the loader is shown
    setState((prevState) => ({ ...prevState, loginCardInStatus: false }));
    loginCardTimeout = setTimeout(() => {
      setState((prevState) => ({ ...prevState, loadingStatus: true }));
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    
    const { data, error } = await login(values);
    
    if (error) {
      const errorMessage = mapHttpErrorCodeToMessage(error);
      
      setState((prevState) => ({
        ...prevState,
        errorStatus: true,
        errorMessage,
        loaderInStatus: false,
      }));

      loaderTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loadingStatus: false, loginCardInStatus: true }));
        customAlertGen(errorMessage, themeInfo);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
    
    else {
      const { ephemeralToken, email, phone, mfa_method } = data;
      // - DEV NOTE -> This is the edge case where a user is logging in that has MFA enabled.
      if (ephemeralToken) {
        setState((prevState) => ({ ...prevState, loaderInStatus: false }))
        loaderTimeout = setTimeout(() => {
          const MFAScenario: MFAScenario = "Login Code Entry";
          history.push(
            AUTHENTICATE_MFA_ROUTE,
            { ephemeralToken, MFAScenario, email, phone, mfa_method }
          );
        }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
      }

      // -> Allow loader to fade out before pushing to the user profile page or redirect URL.
      setState((prevState) => ({ ...prevState, loaderInStatus: false }));
      loaderTimeout = setTimeout(() => {
        if (redirectURL) history.push(redirectURL);
        else history.push(ADMIN_DASHBOARD_ROUTE);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  };

  //////////////////////////////////
  // - onLoginSubmission(): END - // -----------------------------------------------------------------------
  //////////////////////////////////

  const onRegistrationClick = () => {
    setState((prevState) => ({ ...prevState, loginCardInStatus: false }));
    loginCardTimeout = setTimeout(() => {
      history.push(REGISTRATION_ROUTE);
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  }

  // -----

  const onResetPasswordClick = () => {
    setState((prevState) => ({ ...prevState, loginCardInStatus: false }));
    loginCardTimeout = setTimeout(() => {
      history.push(REQUEST_RESET_ROUTE);
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  }

  const {
    loadingStatus, errorStatus, loginCardInStatus,
    loaderInStatus, errorMessage
  } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {
          (!loadingStatus) ? (
            <VerticalFade inStatus={loginCardInStatus}>
              <div className={css(mainContentCradle)}>
                {
                  (errorStatus) && (
                    <>
                      <Fade>
                        <StatusMessage
                          color={themeInfo.palette.errorStatus}
                          headerText="Unsuccessful Login Attempt"
                          messageText={errorMessage}
                          icon={<XIcon color={themeInfo.palette.errorStatus} size={40} />}
                        />
                      </Fade>
                      <Spacer direction={VERTICAL} amount={distance.three} />
                    </>
                  )
                }
                <LoginCard
                  onRegistrationClick={onRegistrationClick}
                  onResetPasswordClick={onResetPasswordClick}
                  onSubmit={onLoginSubmission}
                />
              </div>
            </VerticalFade>
          ) : (
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={palette.primary} />
            </Fade>
          )
        }
      </div>
    </FillUnderNavBarCradle>
  );
}

export default Login;
