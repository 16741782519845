export interface EventListItemProps {
  itemNumber?: number;
  name: string;
  imageURL?: string;
  date: number;
  onClick: any;
  customEventNameTextStyles?: any;
  customCradleStyles?: any;
}

export const DEFAULT_EVENT_LIST_ITEM_HEIGHT = 75;
export const DEFAULT_IMAGE_SIZE = 60;