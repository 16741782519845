// -> Beyoond codebase
import React, { useContext, useEffect, useRef, useState } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom";
// -> Within codebase
import FillUnderNavBarCradle from "../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import VerticalFade from "../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import { ThemeInfo, UIContext } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, VERTICAL } from "../../constants";
import { underConstructionAlert } from "../../helpers";
// -> Within component
import AddOrEditEventForm from "../../Components/Forms/AddOrEditEventForm/AddOrEditEventForm";
import { styleGen } from "./AddEventStyles";
import { IAddEventArgs, IAddEventState } from "./helpers";
import StatusMessage from "src/Components/VisualUtilities/StatusMessage/StatusMessage";
import { XIcon } from "src/Components/VisualUtilities/IconPresets";
import Spacer from "src/Components/LayoutUtilities/Spacer/Spacer";

const AddEvent: React.FC = () => {
  const [state, setState] = useState<IAddEventState>({
    loadingStatus: true,
    errorStatus: false,
    formInStatus: true,
    loaderInStatus: true,
    pageContentInStatus: true,
  })

  // const history = useHistory();
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { t } = useTranslation("page_addEvent");
  const { palette, distance } = themeInfo; 
  const { pageCradle } = styleGen(themeInfo, state.loadingStatus);

  // - TODO: -> What is right init value for the ref if using typeof setTimeout?
  //           -> If you try to use this and set init value to null, TS complains later
  //              on when you go to use the ref.
  // let formTimeout = useRef<typeof setTimeout>(null);
  let formTimeout = useRef<any>(null);
  let loaderTimeout = useRef<any>(null);

  useEffect(() => (() => {
    clearTimeout(formTimeout.current);
    clearTimeout(loaderTimeout.current);
  }), []);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, loaderInStatus: false }));
    loaderTimeout.current = setTimeout(() => {
      setState((prevState) => ({ ...prevState, loadingStatus: false, formInStatus: true }));
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  }, []);

  const onSubmit = async (args: IAddEventArgs) => {
    underConstructionAlert();
  }

  const { loadingStatus, loaderInStatus, errorStatus, formInStatus } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {
          (loadingStatus) ? (
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={palette.primary} />
            </Fade>
          ) : (
            <VerticalFade inStatus={formInStatus}>
              <>
                {
                  (errorStatus) && (
                    <>
                      <StatusMessage
                        color={palette.errorStatus}
                        headerText={t("unsuccessfulEventAdditionMsg")}
                        icon={<XIcon color={palette.errorStatus} size={40} />}
                      />
                      <Spacer direction={VERTICAL} amount={distance.three} />
                    </>
                  )
                }
                <AddOrEditEventForm onSubmit={onSubmit} formMode="ADD" />
              </>
            </VerticalFade>
          )
        }
      </div>
    </FillUnderNavBarCradle>
  );
}

export default AddEvent;
