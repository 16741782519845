// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite";
import * as yup from "yup";
import { Formik, Form, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Input from "../../../../Components/FormUtilities/Input/Input";
import Button from "../../../../Components/Button/Button";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import FloatingCircularLogo from "../../../../Components/VisualUtilities/FloatingCircularLogo/FloatingCircularLogo";
import { ThemeInfo, UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { ROUNDED, VERTICAL } from "../../../../constants";
// -> Within component
import { styleGen } from "./RequestResetCardStyles";
import { IRequestResetCardProps, IRequestResetFormState } from "./helpers";

const initialValues: IRequestResetFormState = { email: "" };

const RequestResetCard: React.FC<IRequestResetCardProps> = (props) => {
  const { t } = useTranslation("page_requestReset");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { onResetEmailSubmission } = props;
  const {
    cardCradle, topBarCradle, cardBottomCradle, promptText, formCradle
  } = styleGen(themeInfo);
  
  const validationSchema = yup.object({
    email: yup.string().email(t("emailFormatErrorMsg")).required(t("emailRequiredErrorMsg"))
  });

  const onFormSubmission = (values: FormikValues) => onResetEmailSubmission(values);

  return (
    <div className={css(cardCradle)}>
      {/* TOP BAR */}
      <div className={css(topBarCradle)}>
        <FloatingCircularLogo />
        <Spacer direction={VERTICAL} amount={distance.four} />
        <p className={css(promptText)}>{t("requestResetPromptMsg")}</p>
      </div>
      {/* MAIN CARD CONTENT */}
      <div className={css(cardBottomCradle)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFormSubmission}
        >
          {
            (formik) => (
              <Form className={css(formCradle)}>
                <Spacer direction={VERTICAL} amount={distance.two} />
                <Input
                  required
                  name="email"
                  label={t("emailInputLabel")}
                  width="100%"
                  borderGeometry={ROUNDED}
                  mode="border"
                  customInputCradleStyles={{ height: 35 }}
                />
                <Spacer direction={VERTICAL} amount={distance.two} />
                <Button
                  buttonText={t("requestResetBtnLabel")}
                  onClick={() => {}}
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                  customTextStyles={{ fontSize: '1.6rem', fontWeight: 'bold' }}
                  customCradleStyles={{
                    paddingTop: themeInfo.distance.one,
                    paddingBottom: themeInfo.distance.one,
                    paddingRight: themeInfo.distance.two,
                    paddingLeft: themeInfo.distance.two,
                    width: "100%",
                    height: 35,
                  }}
                />
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
}

export default RequestResetCard;
