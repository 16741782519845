export enum SongGroupType {
  EP = "EP",
  LP = "LP",
  ALBUM = "Album",
  COMPILATION = "Compilation",
}

interface ISongGroupLinks {
  bandCamp?: string;
  soundCloud?: string;
  spotify?: string;
  appleMusic?: string;
  tidal?: string;
  deezer?: string;
  beatPort?: string;
  youTube?: string;
  googleDrive?: string;
  website?: string;
}

export interface ISongGroup {
  id: string;
  title: string;
  imageURL?: string;
  imageSize?: string | number;
  type: SongGroupType;
  creators?: string[];
  songs: string[];
  links?: ISongGroupLinks;
}
