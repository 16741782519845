import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";

export const styleGen = (themeInfo: ThemeInfo) => {
  const { distance, typography } = themeInfo;

  return StyleSheet.create({
    componentCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    inputElementsCradle: {
      height: "auto",
      width: "100%",
      flex: 1,
      display: "grid",
      alignItems: "center",
      gridTemplateColumns: "1fr 1fr",
      gridAutoFlow: "row",
      gap: `${distance.three}px`,
  
      "@media (max-width: 550px)": {
        gridTemplateColumns: "1fr",
      }
    },
    labelText: {
      ...typography.default,
      margin: 0,
    },
    labelAndDropdownColumn: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    dropdownRow: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }
  });
}
