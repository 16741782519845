export const PAGE_STATE_NEW = "new";
export const PAGE_STATE_SUCCESS = "success";
export const REGISTRATION_SUCCESS_HANG_TIME_MS = 3500;

export interface IRegistrationState {
  loadingStatus: boolean;
  errorStatus: boolean;
  errorMessage?: string;
  cardInStatus: boolean;
  successCardInStatus: boolean;
  loaderInStatus: boolean;
  pageState: "new" | "success"
}
