import * as React from "react";
import { IIconProps } from "../../../Types";

const AppleMusicIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...Object.assign({}, {...props}, {...interpretedProps})}>
      <path d="M407 0H105C47.103 0 0 47.103 0 105v302c0 57.897 47.103 105 105 105h302c57.897 0 105-47.103 105-105V105C512 47.103 464.897 0 407 0zm-24 316v40c0 22.056-17.944 40-40 40h-25c-22.056 0-40-17.944-40-40s17.944-40 40-40h35V168.807l-144 24.828V386c0 22.056-17.944 40-40 40h-25c-22.056 0-40-17.944-40-40s17.944-40 40-40h35V118.365l204-35.172V316z" />
    </svg>
  );
}

export default AppleMusicIcon;
