// -> Beyond codebase
import React, { useContext, useState, useRef, useEffect, useCallback } from "react";
import * as yup from "yup";
import { Formik, Form, FormikValues, FormikProps } from "formik";
import Fuse, { FuseOptions } from "fuse.js";
import { css } from "aphrodite-jss";
import { v4 as uuid } from 'uuid';
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import i18n from "src/i18n/i18n";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, Suggestion } from 'react-places-autocomplete';
// import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
// -> Within codebase
import Fade from "../../AnimationUtilities/TransitionWrappers/Fade/Fade";
import Input from "../../FormUtilities/Input/Input";
import Button from "../../Button/Button";
import LineSegment from "../../VisualUtilities/LineSegment/LineSegment";
import IconButton from "../../Button/Variants/IconButton/IconButton";
import PlusIcon from "../../VisualUtilities/IconPresets/PlusIcon";
import TextArea from "src/Components/FormUtilities/TextArea/TextArea";
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import TypeAheadChosenResult from "../../FormUtilities/TypeAheadChosenResult/TypeAheadChosenResult";
import ExternalLinkEntry from "../../FormUtilities/ExternalLinkEntry/ExternalLinkEntry";
import { externalVenueLinkDropdownOptions as externalLinkDropdownOptions } from "../../FormUtilities/ExternalLinkEntry/helpers";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import { LinkIcon } from "../../VisualUtilities/IconPresets";
import { IDropdownItem } from "src/Components/Dropdown/stateManagement";
import Dropdown from "src/Components/Dropdown/Dropdown";
import { UIContext, ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../DataContextProvider/DataContextProvider";
import { Venue, IPotentialExternalLinkEntry, Event } from "../../../Types";
import {
  DEFAULT_TRANSITION_MICROANIMATION_TIME, HORIZONTAL, ROUND, URL_PATTERN_REGEX,
  ALLOWED_IMAGE_UPLOAD_FILE_TYPES, VERTICAL, EMAIL_PATTERN_REGEX, GREYSCALE,
  LEFT, DEFAULT_INPUT_HEIGHT, ROUNDED,
} from "../../../constants";
import { customAlertGen, generateDropdownItems, useClickOutside } from "../../../helpers";
import { getEventFromID } from "src/lib";
import { ITypeAheadSearchItem } from "src/Components/TypeAheadSearch/stateManagement";
import { processTypeAheadSearchItems } from "src/Components/TypeAheadSearch/helpers";
import { IAddVenueArgs } from "../../../Pages/AddVenue/helpers";
// -> Within component
import {
  determineAvailableVenueStatusesByLanguage, determineSubmissionButtonStatus,
  hydrateExternalLinkEntries, IAddOrEditVenueFormProps, IAddOrEditVenueFormState,
  mapEnglishVenueStatusToTargetLanguage, mapVenueStatusToEnglishVenueStatus
} from "./helpers"
import { styleGen } from "./AddOrEditVenueFormStyles";
import TypeAheadSearch from "src/Components/TypeAheadSearch/TypeAheadSearch";
import DiscoBallIcon from "src/Components/VisualUtilities/IconPresets/DiscoBallIcon";
import CircleLoader from "src/Components/Loaders/CircleLoader/CircleLoader";

const initialValues: Partial<Venue> = {
  name: "",
  imageURL: "",
  description: "",
  phoneNumber: "",
  email: "",
  capacity: undefined,
};

const AddOrEditVenueForm: React.FC<IAddOrEditVenueFormProps> = (props) => {
  const [state, setState] = useState<IAddOrEditVenueFormState>({
    links: {},
    manualFormFieldsValid: true,
    microAnimationTimeoutIDs: [],
    venueLinksPlaceholderSurfaceInStatus: true,
    venueEvents: [],
    availableSearchedEvents: [],
    eventSearchTerm: "",
    eventSearchActive: false,
    venueStatus: "Operational",
    address: "",
    city: "",
    country: "",
    coordinates: { lat: 0, lng: 0 },
    addressTypeAheadIsOpen: false,
    addressTypeAheadDropCradleInStatus: false,
    addressTypeAheadLoadingInStatus: false,
    addressTypeAheadResultsInStatus: false,
    availableSearchedAddresses: [],
  });
  const inputRef = useRef<HTMLInputElement>(null);
  let venueLinksPlaceholderSurfaceTimeout = useRef<any>(null);
  let resultsTimeout = useRef<any>(null);
  let formRef = useRef<FormikProps<FormikValues>>(null);
  const { onSubmit, formMode, venueDetails: venueDetailsFromProps, rtl } = props;
  const { t } = useTranslation((formMode === "ADD") ? "page_addVenue" : "page_editVenue");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, palette, styles, transitions, shadows } = themeInfo;
  const { state: { events }} = useContext(DataContext);
  const {
    formCradle, topBarCradle, titleText, customFormCradleStyles, customFormTitleTextStyles,
    addImagePromptSurface, addInfoPromptText, circularIconCradle, oneItemCradle,
    venueLinksCradle, bottomFormRow, venueLinksTitleText, venueLinksTopRow, itemSeparatorCradle,
    venueLinksPlaceholderSurface, descriptionCradle, venueEventsCradle, labelCradle, requiredElement,
    venueEventPreviewsCradle, venueEventsTitleText, twoItemCradle, labelAndDropdownColumn, labelText,
    addressTypeAheadCradle, addressTypeAheadBaseCradle, addressTypeAheadDropCradle, noSearchResultsAvailableCradle,
    noSearchResultsAvailableText, searchResultCradle, innerSearchResultCradle, searchResultText,
  } = styleGen(themeInfo, props);

  // let addressTypeAheadLoaderTimeout = useRef<any>(null);
  let addressTypeAheadDropCradleTimeout = useRef<any>(null);

  const boundaryNodeRef = useClickOutside((evt: React.MouseEvent<HTMLElement>) => onCloseTypeAheadSearch(evt));

  // -----

  const validationSchema = yup.object({
    name: yup.string().required(t("venueNameRequiredErrorMsg")),
    description: yup.string(),
    imageURL: yup.string().matches(URL_PATTERN_REGEX, t("validURLErrorMsg")),
    phoneNumber: yup.string(),
    email: yup.string().matches(EMAIL_PATTERN_REGEX, t("validEmailErrorMsg")),
    venueStatus: yup.string(),
    capacity: yup.number().min(1),
  });

  // -> Clear microanimation timeouts when component unmounts to avoid memory leaks
  useEffect(() => {
    return () => {
      state.microAnimationTimeoutIDs.map((timeoutID) => clearTimeout(timeoutID));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(resultsTimeout.current);
      clearTimeout(venueLinksPlaceholderSurfaceTimeout.current);
      clearTimeout(addressTypeAheadDropCradleTimeout.current);
      // clearTimeout(addressTypeAheadLoaderTimeout.current);
    }
  }, [state]);

  // -----

  // -> If form is in editing mode, add pre-existing information
  useEffect(() => {
    if (formMode === "EDIT" && props.venueDetails) {
      // console.log(`[EditVenue] venue details -> ${JSON.stringify(props.venueDetails, null, 5)}`);
      const { venueDetails, venueDetails: {
        events: eventIDsFromProps, links: linksFromProps,
      }} = props;
      const {
        name, description, address, email, phoneNumber, capacity, venueStatus,
        imageURL, coordinates: { lat, lng },
      } = venueDetails;
      
      // -> Retrieve event info from IDs if there are any
      let retrievedEvents: Event[] = [];
      if (eventIDsFromProps) {
        eventIDsFromProps.map((eventID: string) => {
          const retrievedEvent = getEventFromID(eventID, events);
          if (retrievedEvent) retrievedEvents.push(retrievedEvent);
          return "";
        });
        if (retrievedEvents.length !== 0) {
          setState((prevState) => ({ ...prevState, venueEvents: retrievedEvents }));
        }
      }

      // -> Set Formik fields
      if (formRef.current) {
        formRef.current.setFieldValue('name', name);
        formRef.current.setFieldValue('description', description);
        formRef.current.setFieldValue('phoneNumber', phoneNumber);
        formRef.current.setFieldValue('email', email);
        formRef.current.setFieldValue('capacity', capacity);
        formRef.current.setFieldValue('imageURL', imageURL);
      }
      
      // -> Put together external link entries
      if (linksFromProps) {
        // console.log(`artist links -> ${JSON.stringify(linksFromProps, null, 2)}`);
        const potentialLinkEntries = hydrateExternalLinkEntries(linksFromProps);
        // console.log(`external link entries -> ${JSON.stringify(potentialLinkEntries, null, 2)}`);
        setState((prevState) => ({ ...prevState, potentialExternalLinks: potentialLinkEntries}));
      }

      // -> Other Fields
      setState((prevState) => ({
        ...prevState, venueStatus, address, imageURL, coordinates: { lat, lng },
      }));
    }
  }, [events, formMode, props]);

  // -----

  // - TODO: -> Prepare image file for upload to backend system
  const onFormSubmission = (values: FormikValues, onSubmitProps: any) => {
    const {
      imageToUpload, potentialExternalLinks, manualFormFieldsValid,
      venueEvents, address, city, country, venueStatus, coordinates,
    } = state;
    const {
      name, imageURL, description, capacity, phoneNumber, email
    } = values;
    const { resetForm, setSubmitting } = onSubmitProps;

    // -> Run validation on manually controlled form fields. If any of them turn up a
    //    validation error, then return early.
    if (potentialExternalLinks) {
      for (let idx = 0; idx < potentialExternalLinks.length - 1; ++idx) {
        validateExternalLinkEntryURL(idx, potentialExternalLinks[idx].URL); 
      }

      if (manualFormFieldsValid === false) return;
    }

    const addVenueArgs: IAddVenueArgs = {
      venue: {
        name: name.trim(),
        description: description.trim,
        imageURL: imageURL.trim(),
        capacity, venueStatus, coordinates, address,
        city, country, phoneNumber, email,
        links: {},
        events: [],
      },
      imageToUpload: null,
    };

    if (potentialExternalLinks) {
      potentialExternalLinks.map((potentialExternalLink: IPotentialExternalLinkEntry) => {
        const { linkName, URL } = potentialExternalLink;

        if ((linkName !== "") && (URL !== "") && addVenueArgs.venue.links) {
          addVenueArgs.venue.links[linkName] = URL.trim();
        }

        return true; // -> TS complains if you don't return anything here.
      });
    }

    if (venueEvents) {
      venueEvents.map((event: Event) => {
        addVenueArgs.venue.events && addVenueArgs.venue.events.push(event.id);
        return ""; // -> TS Complains if you don't return something
      });
    }

    if (imageToUpload) {
      let formData = new FormData();
      formData.append('image', imageToUpload);
      addVenueArgs.imageToUpload = formData;
    }

    console.log(`[AddOrEditVenue]: submission arguments -> ${JSON.stringify(addVenueArgs, null, 1)}`);
    
    onSubmit(addVenueArgs);
    setSubmitting(false);
    resetForm();
  };

  // -----

  const onSetVenueStatus = (venueStatus: string) => {
    setState((prevState) => ({
      ...prevState,
      venueStatus: mapVenueStatusToEnglishVenueStatus(venueStatus, i18n.language)
    }));
  };

  // -----

  const wasEscapePushed = useCallback((evt: any) => {
    const { addressTypeAheadIsOpen } = state;
    if (evt.key === "Escape" && (addressTypeAheadIsOpen)) {
      setState((prevState) => ({ ...prevState, addressTypeAheadDropCradleInStatus: false }));
      addressTypeAheadDropCradleTimeout.current = setTimeout(() => {
        setState((prevState) => ({
          ...prevState, addressTypeAheadDropCradleInStatus: false,
          addressTypeAheadIsOpen: false,
        }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  }, [state]);
  
  useEffect(() => {
    document.addEventListener("keydown", (evt: any) => wasEscapePushed(evt));
    
    return () => document.removeEventListener("keydown", wasEscapePushed);
  }, [wasEscapePushed]);
  
  // -----

  // -> The base cradle is clicked, opening or closing the dropdown based on its current state.
  const onClickTypeAheadSearch = (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    const { addressTypeAheadIsOpen, availableSearchedAddresses } = state;
    
    if (addressTypeAheadIsOpen) return
    else {
      if (availableSearchedAddresses.length === 0) return;
      else {
        setState((prevState) => ({
          ...prevState, addressTypeAheadDropCradleInStatus: true,
          addressTypeAheadIsOpen: true,
        }));
      }
    }
  };

  // -----

  const onCloseTypeAheadSearch = (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();

    setState((prevState) => ({ ...prevState, addressTypeAheadDropCradleInStatus: false }));
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, addressTypeAheadIsOpen: false }));
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  const onClickToSelectImage = () => inputRef.current?.click();

  const onSelectImage = () => {
    if (inputRef.current && inputRef.current.files && inputRef.current.files[0]) {
      const file = inputRef.current.files[0];
      const fileType = file.type;
      // console.log(`[AddOrEditVenueForm]: File name chosen -> ${inputRef.current.files[0].name}`);
      // console.log(`[AddOrEditVenueForm]: File type chosen -> ${inputRef.current.files[0].type}`);
      // console.log(`[AddOrEditVenueForm]: File size chosen -> ${inputRef.current.files[0].size}`);
      if (!ALLOWED_IMAGE_UPLOAD_FILE_TYPES.includes(fileType)) {
        customAlertGen(t("imgFormatErrorMsg"), themeInfo);
      } else {
        const fileObjURL = URL.createObjectURL(file);
        setState((prevState) => ({
          ...prevState,
          imageToUpload: file,
          imageUploadPreviewURL: fileObjURL
        }))
      }
    }
  };

  // -----

  const addExternalLinkEntry = () => {
    const stateCopy = cloneDeep(state);
    const potentialExternalLinkEntry: IPotentialExternalLinkEntry = {
      linkName: "",
      URL: "",
      URLFieldTouched: false,
      URLFieldDirty: false,
      errorStatus: false,
      errorMessage: "Please enter a valid URL",
      microAnimationInStatus: true,
    };

    if (stateCopy.potentialExternalLinks) {
      stateCopy.potentialExternalLinks.push(potentialExternalLinkEntry);
    } else {
      stateCopy.potentialExternalLinks = [];
      stateCopy.potentialExternalLinks.push(potentialExternalLinkEntry);
    }

    // - TODO: -> Fix this microanimation such that the placeholder surface has time to fade out
    //            before the first potential link entry element fades in.
    if ((!stateCopy.potentialExternalLinks) || (stateCopy.potentialExternalLinks.length === 0)) {
      setState((prevState) => ({ ...prevState, venueLinksPlaceholderSurfaceInStatus: false }));
      venueLinksPlaceholderSurfaceTimeout.current = setTimeout(() => {
        setState(stateCopy);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    } else {
      setState(stateCopy);
    }
  };

  // -----

  // - DEV NOTE -> For whatever reason, the state I clone on the first line of the function already has
  //               the potential link entry missing from the array. As a stop gap, I pass in a copy of the
  //               former state in order to manipulate the boolean which will trigger the fade-out microanimation
  //               in the list item.
  const removeExternalLinkEntry = (indexToDelete: number, prevState: IAddOrEditVenueFormState) => {
    const stateCopy = cloneDeep(state);
    
    if (stateCopy.potentialExternalLinks && prevState.potentialExternalLinks) {
      prevState.potentialExternalLinks[indexToDelete].microAnimationInStatus = false;
      setState((prevState) => ({ ...prevState, potentialExternalLinks: prevState.potentialExternalLinks }));
      const timeOut = setTimeout(() => {
        // - DEV NOTE -> Inner anonymous function seems to need a check on if this is undefined or not as well
        if (stateCopy.potentialExternalLinks) {
          stateCopy.potentialExternalLinks =
            stateCopy.potentialExternalLinks.filter((_, index: number) => {
            return index !== indexToDelete;
          });
        }
        stateCopy.microAnimationTimeoutIDs.push(timeOut);
        setState(stateCopy);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  };

  // -----

  const validateExternalLinkEntryURL = (index: number, URLEntry: string): boolean => {
    const stateCopy = cloneDeep(state);
    
    if (!stateCopy.potentialExternalLinks) return true;
    
    if (URL_PATTERN_REGEX.test(URLEntry) === false) {
      // console.log(`[AddOrEditVenueForm]: validateExternalLinkEntryURL(): URL was invalid`);
      if (stateCopy.manualFormFieldsValid) stateCopy.manualFormFieldsValid = false;
      stateCopy.potentialExternalLinks[index].errorStatus = true;
      // console.log(`[AddOrEditVenueForm]: validateExternalLinkEntryURL(): modified state copy after invalid field -> ${JSON.stringify(stateCopy, null, 1)}`);
      setState((_) => ({ ...stateCopy }));
      return false;
    }

    // - TODO: -> Review this after some time, seems sketch
    if (stateCopy.manualFormFieldsValid === false) {
      let anyFieldsHaveAnError = false;
      stateCopy.potentialExternalLinks.map((potentialExternalLink: IPotentialExternalLinkEntry) => {
        if (potentialExternalLink.errorStatus === true) anyFieldsHaveAnError = true;
        return true;
      });
  
      if (!anyFieldsHaveAnError)  stateCopy.manualFormFieldsValid = true;
    }

    setState((_) => ({ ...stateCopy }));
    return true;
  };

  // -----

  const onSelectEvent = (item: ITypeAheadSearchItem) => {
    const { id: eventID } = item;
    const stateCopy = cloneDeep(state);
    const event = getEventFromID(eventID, events);
    if (event) stateCopy.venueEvents.push(event);
    stateCopy.availableSearchedEvents = [];
    stateCopy.eventSearchTerm = "";
    setState({ ...stateCopy });
  };

  // -----

  const onRemoveEvent = (eventID: string) => {
    const stateCopy = cloneDeep(state);
    const { venueEvents } = stateCopy;
    const index = venueEvents.findIndex((event: Event) => event.id === eventID);
    stateCopy.venueEvents.splice(index, 1);
    setState(() => ({ ...stateCopy }));
  }
  
  // -----

  const handleEventSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { eventSearchActive } = state;
    const searchTerm = event.target.value;
    if (!eventSearchActive) setState((prevState) => ({ ...prevState, eventSearchActive: true }))
    setState((prevState) => ({ ...prevState, eventSearchTerm: searchTerm }));
    // throttle(() => {

    // }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    // resultsTimeout.current = setTimeout(() => {
    // - TODO: -> Eventually this will be an async server call
    // setTimeout(() => {
    // }, 1500);
    const candidateEvents = searchForEvents(searchTerm, events);
    const availableSearchedEvents = processTypeAheadSearchItems(candidateEvents);
    setState((prevState) => ({ ...prevState, availableSearchedEvents, eventSearchActive: false }));
  };

  // -----

  const searchForEvents = (searchTerm: string, events: Event[]): Event[] => {
    let eventSearchResults: any[] = [];
    if (searchTerm === "") return [];
    
    const searchEngineOptions: FuseOptions<Event> = {
      keys: ["name"],
      shouldSort: true, // -> Results sorted by relevance score
      findAllMatches: true,
      threshold: 0.4, // -> This is the default value, it can be tweaked from 0 to 1
    };
  
    const fuse = new Fuse(events, searchEngineOptions);
  
    eventSearchResults = fuse.search(searchTerm);
    const availableSearchedEvents: ITypeAheadSearchItem[] = processTypeAheadSearchItems(eventSearchResults);

    setState((prevState) => ({ ...prevState, availableSearchedEvents }));

    return eventSearchResults;
  };

  // -----

  const onSelectAddress = async (address: string) => {
  
    try {
      const results = await geocodeByAddress(address)
      const { lat, lng } = await getLatLng(results[0]);
      const cityComponent = results[0].address_components.find((component) => component.types[0] === "locality");
      const countryComponent = results[0].address_components.find((component) => component.types[0] === "country");

      setState((prevState) => ({ ...prevState, addressTypeAheadDropCradleInStatus: false }))
      addressTypeAheadDropCradleTimeout.current = setTimeout(() => {
        setState((prevState) => ({
          ...prevState, coordinates: { lat, lng }, address: results[0].formatted_address,
          city: cityComponent!.long_name, country: countryComponent!.long_name,
          addressTypeAheadIsOpen: false,
        }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME)
    } catch (err) {
      console.error(err);
    }

  }

  // const onSelectAddress = async ({ description }: { description: string }) => {
    
  // }

  // -----

  const handleAddressInputChange = (address: string) => {
    const { addressTypeAheadIsOpen } = state;
    setState((prevState) => ({ ...prevState, address }));

    if (!addressTypeAheadIsOpen) {
      setState((prevState) => ({
        ...prevState, addressTypeAheadDropCradleInStatus: true,
        addressTypeAheadIsOpen: true,
      }));
    }
  }

  // const handleAddressInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
  //   const address = evt.target.value;
  //   // setAddressSearchResult(address);
  //   setState((prevState) => ({ ...prevState, address }));
  // }

  // -----

  const venueStatusDropdownItems: IDropdownItem[] =
    generateDropdownItems(determineAvailableVenueStatusesByLanguage(i18n.language));
  
  const {
    potentialExternalLinks, imageUploadPreviewURL, eventSearchTerm,
    venueLinksPlaceholderSurfaceInStatus, availableSearchedEvents,
    venueEvents, eventSearchActive, venueStatus, address,
    addressTypeAheadIsOpen, addressTypeAheadDropCradleInStatus,
    addressTypeAheadLoadingInStatus, addressTypeAheadResultsInStatus
  } = state;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmission}
      innerRef={formRef}
    >
      {
        ({isValid, dirty, isSubmitting }) => (
          <Form className={css(formCradle, customFormCradleStyles)}>
            {/* TITLE */}
            <div className={css(topBarCradle)}>
              <p className={css(titleText, customFormTitleTextStyles)}>{t("pageTitle")}</p>
            </div>

            <Spacer direction={VERTICAL} amount={distance.three} />
            <LineSegment direction={HORIZONTAL} width={"100%"} size={2} color={palette.black} />
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* IMAGE UPLOAD */}
            <div className={`${css(addImagePromptSurface)} button`} onClick={onClickToSelectImage}>
              {
                // - DEV NOTE -> Edge case that there is no new image primed for upload and that the form
                //               is in edit mode/there's a pre-existing image to show.
                ((!imageUploadPreviewURL) && (formMode === "EDIT" && venueDetailsFromProps && venueDetailsFromProps.imageURL)) ? (
                  <ImageWrapper
                    alt={t(`Venue: ${venueDetailsFromProps.name}`)} src={venueDetailsFromProps.imageURL}
                    borderGeometry={ROUNDED}
                    height={85} width={85}
                  />
                ) : (
                  <>
                    {
                      (imageUploadPreviewURL) ? (
                        <ImageWrapper
                          alt={t("venueImageUploadPreviewAltText")} src={imageUploadPreviewURL}
                          borderGeometry={ROUNDED}
                          height={85} width={85}
                        />
                      ) : (
                        <div className={css(circularIconCradle)}>
                          <DiscoBallIcon color={palette.grey6} size={50} />
                        </div>
                      )
                    }
                  </>
                )
              }
              <Spacer direction={VERTICAL} amount={distance.three} />
              <p className={css(addInfoPromptText)}>{`+ ${t("addVenueImagePrompt")}`}</p>
              <input type="file" onChange={onSelectImage} ref={inputRef} hidden />
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* IMAGE URL AND ARTIST NAME */}
            <div className={css(oneItemCradle)}>
              {/* <Input
                name="imageURL"
                label={t("imageURLInputLabel")}
                mode="border"
                customInputTextStyles={{ fontSize: "1.4rem" }}
                customControlCradleStyles={{ width: "100%" }}
              /> */}

              <Input
                name="name"
                label={t("venueNameInputLabel")}
                mode="border"
                required
                customInputTextStyles={{ fontSize: "1.4rem" }}
                customControlCradleStyles={{  width: "100%", }}
              />
            </div>
            <Spacer direction={VERTICAL} amount={distance.two} />
            
            {/* VENUE ADDRESS */}
            <div className={css(oneItemCradle)}>
              <div style={{
                height: "100%", width: "100%",
                display: "flex", flexDirection: "column",
              }}>
                <div style={{ display: 'flex', justifyContent: (rtl) ? 'flex-end' : 'flex-start' }}>
                  <p className={css(labelText)}>{t("venueAddressInputLabel")}</p>
                  <div style={{ marginRight: 2}}></div>
                  <p className={css(requiredElement)}>*</p>
                </div>
                <Spacer direction={VERTICAL} amount={distance.one} />
                <PlacesAutocomplete
                  value={address}
                  onChange={handleAddressInputChange}
                  onSelect={onSelectAddress}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading: addressTypeAheadLoadingStatus }) => {
                    let availableSearchedAddresses: ITypeAheadSearchItem[];
                    availableSearchedAddresses = suggestions.map((suggestion: Suggestion): ITypeAheadSearchItem => ({ id: uuid(), name: suggestion.description }));

                    return (
                      // - BASE CRADLE - //
                      <div className={css(addressTypeAheadCradle)} ref={boundaryNodeRef}>
                        <div className={css(addressTypeAheadBaseCradle)} onClick={onClickTypeAheadSearch}>
                          <Input
                            // name="addressSearchInput"
                            useManualInputField
                            // value={address}
                            // onChange={onTextEntry}
                            showErrorMessage={false}
                            placeholder={"Search by starting to type"}
                            customInputTextStyles={{ fontSize: "1.4rem" }}
                            customControlCradleStyles={{ width: "100%" }}
                            customInputCradleStyles={{
                              border: "none", paddingLeft: 0, paddingTop: 0,
                              paddingRight: 0, paddingBottom: 0,
                              height: DEFAULT_INPUT_HEIGHT
                            }}
                            {...getInputProps()}
                          />
                          <Spacer direction={HORIZONTAL} amount={distance.one} />
                        </div>
                        {/* DROP CRADLE */}
                        {
                          (addressTypeAheadIsOpen) && (
                            <Fade inStatus={addressTypeAheadDropCradleInStatus}>  
                              <div className={css(addressTypeAheadDropCradle)}>
                                {
                                  (!addressTypeAheadLoadingStatus) ? (
                                    <Fade inStatus={addressTypeAheadResultsInStatus}>
                                      <>
                                        {
                                          (suggestions && (suggestions.length > 0)) ? (
                                            suggestions.map((suggestion: Suggestion, index: number) => {
                                              const computedMarginTop = (index === 0) ? 0 : (distance.one / 2);
                                              const computedMarginBottom = (index === (availableSearchedAddresses?.length - 1)) ? 0 : (distance.one / 2);

                                              return (
                                                <div key={suggestion.placeId} style={{
                                                  marginTop: computedMarginTop,
                                                  marginBottom: computedMarginBottom,
                                                  justifyContent: 'space-around',
                                                  width: '100%',
                                                }}>
                                                  <div className={css(searchResultCradle)} {...getSuggestionItemProps(suggestion)}>
                                                    <div className={css(innerSearchResultCradle)}>
                                                      <p className={css(searchResultText)}>{suggestion.description}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <div className={css(noSearchResultsAvailableCradle)}>
                                              <p className={css(noSearchResultsAvailableText)}>{t("noAddressSearchResultsAvailableMsg")}</p>
                                            </div>
                                          )
                                        }
                                      </>
                                    </Fade>
                                  ) : (
                                    <div style={{
                                      height: 50,
                                      width: "100%",
                                      display: "grid",
                                      placeItems: "center",
                                    }}>
                                      <Fade inStatus={addressTypeAheadLoadingInStatus}>
                                        <CircleLoader spinnerColor={palette.primary} size={25} spinnerTrackWidth={1} />
                                      </Fade>
                                    </div>
                                  )
                                }
                              </div>
                            </Fade>
                          )
                        }
                      </div>
                    );
                  }}
                </PlacesAutocomplete>
              </div>
            </div>
            <Spacer direction={VERTICAL} amount={distance.two} />

            {/* DESCRIPTION */}
            <div className={css(descriptionCradle)}>
              <TextArea
                name="description"
                mode="border"
                label={t("descriptionLbl")}
                // width="100%"
                maxWidth="100%"
                // height={90}
                customControlCradleStyles={{ width: "100%" }}
                customTextAreaCradleStyles={{ resize: "vertical", height: 75, fontSize: "1.4rem" }}
              />
            </div>
            <Spacer direction={VERTICAL} amount={distance.two} />

            {/* VENUE EMAIL AND PHONE NUMBER */}
            <div className={css(twoItemCradle)}>
              <Input
                name="email"
                label={t("venueEmailInputLabel")}
                mode="border"
                customInputTextStyles={{ fontSize: "1.4rem" }}
                customControlCradleStyles={{  width: "100%", }}
              />

              <Input
                name="phoneNumber"
                label={t("venuePhoneNumberInputLabel")}
                mode="border"
                customInputTextStyles={{ fontSize: "1.4rem" }}
                customControlCradleStyles={{ width: "100%" }}
              />
            </div>
            <Spacer direction={VERTICAL} amount={distance.two} />
            
            {/* VENUE CAPACITY AND STATUS */}
            <div className={css(twoItemCradle)}>
              <Input
                name="capacity"
                label={t("venueCapacityInputLabel")}
                mode="border"
                type="number"
                customInputTextStyles={{ fontSize: "1.4rem" }}
                customControlCradleStyles={{  width: "100%", }}
              />

              <div className={css(labelAndDropdownColumn)}>
                <p className={css(labelText)}>{t("venueStatusDropdownLabel")}</p>
                <Spacer direction={VERTICAL} amount={distance.one} />
                <Dropdown
                  selectedItemValue={mapEnglishVenueStatusToTargetLanguage(venueStatus, i18n.language)}
                  onSelectItem={onSetVenueStatus}
                  items={venueStatusDropdownItems}
                  colorMode={GREYSCALE}
                  dropCradleAlignment={LEFT}
                  customDropdownCradleStyles={{ height: DEFAULT_INPUT_HEIGHT, width: "100%" }}
                />
              </div>
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* VENUE EVENTS */}
            <div className={css(venueEventsCradle)}>
              <div className={css(labelCradle)}>
                <p className={css(venueEventsTitleText)}>{t("venueEventsLbl")}</p>
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />
              
              <TypeAheadSearch
                loadingStatus={eventSearchActive}
                searchTerm={eventSearchTerm}
                handleSearchInputChange={handleEventSearchInputChange}
                onSelectItem={onSelectEvent}
                items={availableSearchedEvents}
                customBaseCradleStyles={{ height: "100%", width: "100%" }}
                customTypeAheadSearchCradleStyles={{ height: "auto", width: "100%", paddingBottom: 0 }}
                noSearchResultsAvailableMsg={t("noEventSearchResultsAvailableMsg")}
                baseCradleHeight={DEFAULT_INPUT_HEIGHT}
                colorMode="Greyscale"
                showBaseCradleIcon={false}
              />
              <Spacer direction={VERTICAL} amount={distance.three} />

              <div className={css(venueEventPreviewsCradle)}>
                {
                  // eslint-disable-next-line array-callback-return
                  venueEvents.map((event: Event) => {
                    const { id, name, imageURL } = event;

                    return (
                      <TypeAheadChosenResult
                        key={id}
                        id={id}
                        name={name}
                        imageURL={imageURL}
                        onRemoveItem={() => onRemoveEvent(id)}
                      />
                    );
                  })
                }
              </div>
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* EXTERNAL LINKS */}
            <div className={css(venueLinksCradle)}>
              <div className={css(venueLinksTopRow)}>
                <p className={css(venueLinksTitleText)}>{t("externalLinksLabel")}</p>
                <Spacer direction={HORIZONTAL} amount={distance.four} />
                <IconButton
                  icon={<PlusIcon color={palette.white} size={styles.standardIconSize - 5} />}
                  onClick={addExternalLinkEntry}
                  buttonGeometry={ROUND}
                  disabled={
                    state.potentialExternalLinks &&
                    state.potentialExternalLinks.length >= externalLinkDropdownOptions.length
                  }
                  customCradleStyles={{
                    padding: 3,
                    backgroundColor: palette.primary,
                    transition: transitions.boxShadowTransition
                  }}
                  customCradleHoverStyles={{ boxShadow: shadows.one }}
                />
              </div>

              <Spacer direction={VERTICAL} amount={distance.two} />

              {
                (potentialExternalLinks && (potentialExternalLinks.length > 0)) ? (
                  potentialExternalLinks.map((potentialExternalLink: IPotentialExternalLinkEntry, index: number) => {
                    const {
                      linkName, URL, errorStatus, errorMessage, URLFieldTouched,
                      URLFieldDirty, microAnimationInStatus
                    } = potentialExternalLink;

                    return (
                      <div key={index}>
                        <Fade inStatus={microAnimationInStatus}>
                          <ExternalLinkEntry
                            state={state}
                            setState={setState}
                            index={index}
                            linkName={linkName}
                            URL={URL}
                            errorStatus={errorStatus}
                            errorMessage={errorMessage}
                            URLFieldTouched={URLFieldTouched}
                            URLFieldDirty={URLFieldDirty}
                            removeExternalLinkEntry={removeExternalLinkEntry}
                            validateExternalLinkEntryURL={validateExternalLinkEntryURL}
                            type="VENUE"
                          />
                        </Fade>
                        {
                          (index < (potentialExternalLinks.length - 1)) ? (
                            <>
                              <Spacer direction={VERTICAL} amount={distance.two} />
                              <div className={css(itemSeparatorCradle)}>
                                <LineSegment
                                  direction={HORIZONTAL}
                                  width={`calc(100% - ${distance.six}px)`}
                                  size={1}
                                  color={palette.grey1}
                                />
                              </div>
                              <Spacer direction={VERTICAL} amount={distance.two} />
                            </>
                          ) : null
                        }
                      </div>
                    );
                  })
                ) : (
                  <Fade inStatus={venueLinksPlaceholderSurfaceInStatus}>
                    <div className={css(venueLinksPlaceholderSurface)} onClick={addExternalLinkEntry}>
                      <div className={css(circularIconCradle)}>
                        <LinkIcon color={palette.grey6} size={50} />
                      </div>
                      <Spacer direction={VERTICAL} amount={distance.three} />
                      <p className={css(addInfoPromptText)}>{`+ ${t("addExternalLinkPrompt")}`}</p>
                    </div>
                  </Fade>
                )
              }
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* FORM SUBMISSION */}

            <div className={css(bottomFormRow)}>
              <Button
                onClick={() => {}}
                buttonText={(formMode === "ADD") ? t("addVenueBtnLbl") : t("editVenueBtnLbl")}
                type="submit"
                disabled={isSubmitting || determineSubmissionButtonStatus(props, { isValid, dirty })}
                customTextStyles={{ fontSize: "1.6rem", fontWeight: "bold" }}
                customCradleStyles={{
                  paddingTop: distance.one,
                  paddingBottom: distance.one,
                  paddingRight: distance.two,
                  paddingLeft: distance.two,
                  height: 35,
                }}
              />
            </div>
          </Form>
        )
      }
    </Formik>
  );
}

export default AddOrEditVenueForm;

AddOrEditVenueForm.defaultProps = {
  isInCardMode: true,
};
