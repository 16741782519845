// -> Beyond codebase
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from 'aphrodite-jss';
// -> Within codebase
import Button from '../Button/Button';
import Fade from '../AnimationUtilities/TransitionWrappers/Fade/Fade';
import Dropdown from '../Dropdown/Dropdown';
import { HomeIcon } from '../VisualUtilities/IconPresets';
import { ThemeInfo, UIContext } from '../UI_InfoProvider/UI_InfoProvider';
import { AuthContext } from '../AuthContextProvider/AuthContextProvider';
import {
  ADMIN_DASHBOARD_ROUTE, HOME_ROUTE, LOGIN_ROUTE, NAV_BAR_HEIGHT,
  PROFILE_ROUTE, ROUND, ROUNDED, TEMP_AVATAR_URL
} from '../../constants';
import { IUser } from '../../Types';
// -> Within component
import DropdownBaseComponent from "./InternalComponents/DropdownBaseComponent/DropdownBaseComponent";
import DropdownDropComponent from "./InternalComponents/DropdownDropComponent/DropdownDropComponent";
import { styleGen } from './NavBarStyles';
import WavemapIcon from '../VisualUtilities/IconPresets/WavemapIcon';

const NavBar: React.FC = () => {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const history = useHistory();
  const { logout, authContextState: { user: userFromContext } } = useContext(AuthContext);
  const uiInfo = useContext(UIContext);
  const { themeInfo, openDrawer, openBackdrop } = uiInfo;
  const { palette, styles, distance, fonts, transitions, shadows } = themeInfo;
  const { navBarCradle, homeLogoCradle } = styleGen(themeInfo);

  // const onNavigateHome = () => history.push(HOME_ROUTE);

  useEffect(() => {
    setUser(userFromContext);
  }, [userFromContext]);

  const onNavigateToUserProfile = () => history.push(PROFILE_ROUTE);
  const onNavigateToAdminDashboard = () => history.push(ADMIN_DASHBOARD_ROUTE);
  const onNavigateToLogin = () => history.push(LOGIN_ROUTE);

  const onLogout = () => {
    logout();
    history.push(LOGIN_ROUTE);
  };

  const onDrawerMenuBtnClick = () => {
    openBackdrop();
    openDrawer();
  };

  return (
    <Fade>
      <div className={css(navBarCradle)}>
        <div className={css(homeLogoCradle)}>
        <Fade>
        {/*  <Button
            height={40}
            width={40}
            transparent
            buttonCradlePadding={0}
            icon={<WavemapIcon color={palette.primary} size={styles.standardIconSize} />}
            // icon={<HomeIcon color={palette.primary} size={styles.standardIconSize} />}
            customCradleStyles={{ padding: 0, justifyContent: 'flex-start' }}
            onClick={onNavigateHome}
          /> */}
          <Button
            icon={<WavemapIcon color={palette.primary} size={styles.standardIconSize} />}
            buttonGeometry={ROUND}
            iconTextSpacing={distance.one}
            color={palette.white}
            textColor={palette.black}
            customCradleStyles={{
              transition: `${transitions.boxShadowTransition}, ${transitions.bgColorTransition}`,
              backgroundColor: 'transparent',
              paddingLeft: 10, paddingRight: 10,
              paddingTop: 10, paddingBottom: 10,
              marginTop: distance.two,
              marginRight: distance.two,
              position: "relative",
              right: 10,
              // border: `1x solid transparent`,
            }}
            customCradleHoverStyles={{
              boxShadow: shadows.one,
              backgroundColor: palette.primary_p4,
              // border: `1px solid ${palette.primary}`,
            }}
            customTextStyles={{
              ...fonts.primary,
              color: palette.black,
              fontSize: "1.8rem",
            }}
            onClick={onDrawerMenuBtnClick}
          />
        </Fade>
        </div>

        {
          (user !== undefined) ? (
            <Fade>
              <Dropdown
                onSelectItem={() => {}}
                closeDropCradleOnSelect
                geometry={ROUNDED}
                dropCradleGap={10}
                baseCradleBorder="none"
                customDropdownCradleStyles={{ padding: 0, height: NAV_BAR_HEIGHT }}
                customBaseCradleStyles={{
                  padding: 0, height: NAV_BAR_HEIGHT,
                  backgroundColor: "transparent"
                }}
                customDropCradleStyles={{ padding: 0 }}
                CustomBaseCradleComponent={
                  <DropdownBaseComponent
                    name1="Gabriel"
                    name2="Bourget"
                    avatarUrl={TEMP_AVATAR_URL}
                  />
                }
                CustomDropdownComponent={
                  <DropdownDropComponent
                    logout={onLogout}
                    navigateToUserProfile={onNavigateToUserProfile}
                    navigateToAdminDashboard={onNavigateToAdminDashboard}
                  />
                }
              />
            </Fade>
          ) : (
            <Fade>
              <Button
                buttonText="ADMIN LOGIN"
                onClick={onNavigateToLogin}
                customCradleStyles={{
                  paddingTop: distance.one,
                  paddingBottom: distance.one,
                }}
                customTextStyles={{
                  fontFamily: fonts.primary,
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                }}
              />
            </Fade>
          )
        }
      </div>
    </Fade>
  );
}

export default NavBar;
