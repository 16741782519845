import { Event, Venue, Artist } from "../../Types";
import { initDataContextState, IDataContextState } from "./stateManagement";

export interface IDataContext {
  state: IDataContextState;
  setVenues: (venues: Venue[]) => void;
  getVenueFromID: (venueID: string) => Venue | undefined;
  setEvents: (events: Event[]) => void;
  getEventFromID: (eventID: string) => Event | undefined;
  setArtists: (artists: Artist[]) => void;
  getArtistFromID: (artistID: string) => Artist | undefined;
}

export const initDataContextValue: IDataContext = {
  state: initDataContextState,
  setVenues: (venues: Venue[]) => {},
  getVenueFromID: (venueID: string) => undefined,
  setEvents: (events: Event[]) => {},
  getEventFromID: (eventID: string) => undefined,
  setArtists: (artists: Artist[]) => {},
  getArtistFromID: (artistID: string) => undefined,
}
