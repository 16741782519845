// -> Beyond Codebase
import React, { useContext } from 'react';
import { css } from 'aphrodite-jss';
import { Field, ErrorMessage } from 'formik';
// -> Within Codebase
import Spacer from '../../LayoutUtilities/Spacer/Spacer';
import { UIContext } from '../../UI_InfoProvider/UI_InfoProvider';
import { VERTICAL } from '../../../constants';
// -> Within Componen
import { styleGen } from './TextAreaStyles';
import { ITextAreaProps, calibrateComponent } from './helpers';

const TextArea: React.FC<ITextAreaProps> = (props) => {
  const { themeInfo, themeInfo: { distance }} = useContext(UIContext);
  const { formField } = styleGen(props, themeInfo);
  const {
    label, name, rtl, required, useManualTextAreaField, numberOnly, resize,
    maxLength, value, onChange, errorMessage, placeholder, maxWidth, ...rest
  } = props;
  let { showLabel, showErrorMessage } = props;

  if (!showLabel) showLabel = true;
  if (showErrorMessage === undefined) showErrorMessage = true;

  const styles = styleGen(props, themeInfo);
  const { requiredElement } = styles;
  const {
    formControlCradleStylechain, labelCradleStylechain,
    labelTextStylechain, textAreaStylechain,
    errorCradleStylechain, errorTextStylechain
  } = calibrateComponent(props, styles);

  const rtlStatus = (rtl) ? "rtl" : undefined;

  return (
    <div className={formControlCradleStylechain}>
    {
      (showLabel && label) ? (
        <>
          <div className={labelCradleStylechain}>
            <p className={labelTextStylechain}>{label}</p>
            {
              (required) && (
                <>
                  <div style={{ marginRight: 2}}></div>
                  <p className={css(requiredElement)}>*</p>
                </>
              )
            }
          </div>
          <Spacer direction={VERTICAL} amount={distance.one} />
        </>
      ) : null
    }
    {
      (useManualTextAreaField) ? (
        <textarea
          className={textAreaStylechain}
          id={name}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          dir={rtlStatus}
          value={value}
          onChange={onChange}
          {...rest}
        />
      ) : (
        <Field
          autoComplete={numberOnly && "off"}
          maxLength={maxLength}
          maxWidth={maxWidth}
          className={textAreaStylechain}
          id={name}
          name={name}
          as="textarea"
          placeholder={placeholder}
          dir={rtlStatus}
          {...rest}
        />
      )
    }
    {
      (showErrorMessage) ? (
        (useManualTextAreaField) ? (
          <>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <div className={errorCradleStylechain}>
              <p className={errorTextStylechain}>{errorMessage}</p>
            </div>
          </>
        ) : (
          <>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <ErrorMessage
              name={name}
              render={(msg: string) => (
                <div className={errorCradleStylechain}>
                  <p className={errorTextStylechain}>{msg}</p>
                </div>
              )}
            />
          </>
        )
      ) : null
    }
    </div>
    // <Field
    //   className={css(formField)}
    //   id={name}
    //   name={name}
    //   as="textarea"
    //   {...rest}
    // />
  );
}

export default TextArea;