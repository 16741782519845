import React from 'react';
import { BiLink } from 'react-icons/bi';

interface IIconPresetProps {
  size?: number;
  color?: string;
}

export const LinkIcon: React.FC<IIconPresetProps> = (props) => {
  const { size, color } = props;

  return (
    <BiLink size={size} color={color} />
  );
}

export default LinkIcon;
