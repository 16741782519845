// -> Beyond codebase
import React, { useContext, useEffect, useMemo, useReducer } from "react";
import { css } from "aphrodite-jss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// -> Within codebase
import FillUnderNavBarCradle from "../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import IconButton from "../../Components/Button/Variants/IconButton/IconButton";
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import PlusIcon from "../../Components/VisualUtilities/IconPresets/PlusIcon";
import Pagination from "../../Components/Pagination/Pagination";
import Spacer from "../../Components/LayoutUtilities/Spacer/Spacer";
import { UIContext, ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../Components/DataContextProvider/DataContextProvider";
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import { Artist } from "../../Types";
import { useQueryParams } from "../../helpers";
import {
  GRID, DEFAULT_TRANSITION_MICROANIMATION_TIME, ARTIST_ROUTE, ROUND, ADD_ARTIST_ROUTE, VERTICAL,
  PAGE_NUM_PARAM_KEY, ARTISTS_ROUTE, ARTIST_ID_ROUTE_PARAM_TOKEN
} from "../../constants";
// -> Within component
import TopBar from "./InternalComponents/TopBar/TopBar";
import ArtistGallery from "./InternalComponents/ArtistGallery/ArtistGallery";
import ArtistList from "./InternalComponents/ArtistList/ArtistList";
import { styleGen } from "./ArtistsStyles";
import { IArtistsProps } from "./helpers";
import {
  reducer, setDisplayMode, setLoadingStatus, setLoaderInStatus,
  setSortCriteria, setSortDirection, applySortConfig, clearSortConfig,
  setFilterCriteria, setFilterCondition, setFilterArgument, applyFilterConfig,
  clearFilterConfig, setArtists, setPaginatedArtists, setFilterConditionType,
  addMicroanimationTimeoutID, addFilterArgumentFadeInStatus,
  removeFilterArgumentFadeInStatus, setFilterArgumentFadeInStatuses,
  setSortActivityIndicatorInStatus, setFilterActivityIndicatorInStatus,
  setSearchActivityIndicatorInStatus, searchArtistsAction, clearArtistSearch,
  setPageContentInStatus, setCurrentPage, initialArtistsState, setItemsPerPage
} from "./stateManagement";

const Artists: React.FC<IArtistsProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialArtistsState);
  const routerHistory = useHistory();
  const queryParams = useQueryParams();
  const { state: { artists }} = useContext(DataContext);
  const { getUser } = useContext(AuthContext);
  const { t } = useTranslation(["component_Pagination", "page_artists"]);
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { palette, shadows, distance } = themeInfo;
  const {
    componentCradle, artistsContentCradle, customPageCradleStyles, loaderCradle, addArtistBtnCradle
  } = styleGen(themeInfo);

  let pageContentMicroanimationTimeoutID: any = -1;

  const pageNumParam = useMemo(() => parseInt(queryParams.get(PAGE_NUM_PARAM_KEY) || "1"), [queryParams]);

  // -----

  const onArtistClick = (artistID: string) => {
    const computedURL = ARTIST_ROUTE.replace(ARTIST_ID_ROUTE_PARAM_TOKEN, artistID);

    dispatch(setPageContentInStatus(false));
    routerHistory.push(computedURL);
    const timeoutCallback = () => routerHistory.push(computedURL);
    pageContentMicroanimationTimeoutID = setTimeout(timeoutCallback, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  const onNavigateToAddArtist = () => {
    dispatch(setPageContentInStatus(false));
    // - TODO: -> Why isn't the set timeout callback invoking?
    routerHistory.push(ADD_ARTIST_ROUTE);
    pageContentMicroanimationTimeoutID = setTimeout(() => {
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  }

  // -----
  
  useEffect(() => {
    const user = getUser();
    console.log(`[Artists]: user -> ${JSON.stringify(user, null, 3)}`);
    const {
      settings: {
        itemsPerPage: itemsPerPage_Settings,
        defaultDisplayMode: defaultDisplayMode_Settings,
      }
    } = user!;

    dispatch(setItemsPerPage(itemsPerPage_Settings));
    dispatch(setDisplayMode(defaultDisplayMode_Settings));
    dispatch(setArtists(artists));
    dispatch(setPaginatedArtists());

    const numInitialPages = Math.ceil(artists.length / itemsPerPage_Settings);
    if (pageNumParam < 1) {
      dispatch(setCurrentPage(1));
    }
    else if (pageNumParam >= 1 && pageNumParam <= numInitialPages) {
      dispatch(setCurrentPage(pageNumParam));
    }
    else {
      dispatch(setCurrentPage(numInitialPages));
      window.history.pushState(null, "", `${ARTISTS_ROUTE}?${PAGE_NUM_PARAM_KEY}=${numInitialPages}`);
    }

    setTimeout(() => {
      dispatch(setLoaderInStatus(false));
      setTimeout(() => {
        dispatch(setLoadingStatus(false));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }, 1000);
  }, [artists, getUser, pageNumParam]);

  // -----

  useEffect(() => {
    return () => {
      state.filtArgMicroAnimationTimeoutIDs.map((timeoutID) => clearTimeout(timeoutID));
      clearTimeout(pageContentMicroanimationTimeoutID);
    };
  }, [pageContentMicroanimationTimeoutID, state.filtArgMicroAnimationTimeoutIDs]);

  // -----

  const onSetCurrentPage = (pageNumber: number) => {
    dispatch(setCurrentPage(pageNumber));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // -----

  const assignArtistListItemNumberByID = (id: string): number => {
    const { artists } = state;
    const listItemNumber = artists.findIndex((artist: Artist) => artist.id === id) + 1;
    
    return listItemNumber;
  };

  // -----

  const {
    displayMode, loadingStatus, loaderInStatus, displayedArtists, pageContentInStatus,
    currentPageNumber, itemsPerPage, numberOfDisplayableArtists
  } = state;

  return (
    <FillUnderNavBarCradle>
      {
        (loadingStatus) ? (
          <div className={css(loaderCradle)}>
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={palette.primary} />
            </Fade>
          </div>
        ) : (
          <Fade inStatus={pageContentInStatus}>
            <div className={css(componentCradle, customPageCradleStyles)}>
              <TopBar
                // -> General
                displayMode={displayMode}
                state={state}
                dispatch={dispatch}
                setDisplayMode={setDisplayMode}
                addMicroanimationTimeoutID={addMicroanimationTimeoutID}
                // -> Sorting
                setSortCriteria={setSortCriteria}
                setSortDirection={setSortDirection}
                applySortConfig={applySortConfig}
                clearSortConfig={clearSortConfig}
                setSortActivityIndicatorInStatus={setSortActivityIndicatorInStatus}
                // -> Filtering
                setFilterCriteria={setFilterCriteria}
                setFilterCondition={setFilterCondition}
                setFilterConditionType={setFilterConditionType}
                setFilterArgument={setFilterArgument}
                applyFilterConfig={applyFilterConfig}
                clearFilterConfig={clearFilterConfig}
                addFilterArgumentFadeInStatus={addFilterArgumentFadeInStatus}
                removeFilterArgumentFadeInStatus={removeFilterArgumentFadeInStatus}
                setFilterArgumentFadeInStatuses={setFilterArgumentFadeInStatuses}
                setFilterActivityIndicatorInStatus={setFilterActivityIndicatorInStatus}
                // -> Searching
                searchArtistsAction={searchArtistsAction}
                clearArtistSearch={clearArtistSearch}
                setSearchActivityIndicatorInStatus={setSearchActivityIndicatorInStatus}
              />
              <div className={css(artistsContentCradle)}>
                {
                  (displayMode === GRID) ? (
                    <ArtistGallery
                      artists={displayedArtists}
                      onArtistClick={onArtistClick}
                    />
                  ) : (
                    <ArtistList
                      artists={displayedArtists}
                      currentPageNumber={currentPageNumber}
                      itemsPerPage={itemsPerPage}
                      onArtistClick={onArtistClick}
                      assignArtistListItemNumberByID={assignArtistListItemNumberByID}
                    />
                  )
                }

                <Spacer direction={VERTICAL} amount={distance.six} />

                <Pagination
                  currentPage={currentPageNumber}
                  setCurrentPage={onSetCurrentPage}
                  itemsPerPage={itemsPerPage}
                  numberOfItems={numberOfDisplayableArtists}
                  showDirectionButtonsWithOnePage={false}
                  prevPageLabel={t("prevBtnLabel")}
                  nextPageLabel={t("nextBtnLabel")}
                  // rtl
                />
              </div>
              <div className={css(addArtistBtnCradle)}>
                <IconButton
                  icon={<PlusIcon color={palette.white} size={30} />}
                  buttonGeometry={ROUND}
                  onClick={onNavigateToAddArtist}
                  customCradleStyles={{
                    boxShadow: shadows.two,
                    padding: distance.two,
                    backgroundColor: palette.primary,
                  }}
                />
              </div>
            </div>
          </Fade>
        )
      }
    </FillUnderNavBarCradle>
  );
}

export default Artists;
