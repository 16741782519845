import { IResetPasswordFormArgs } from "../../helpers";

export interface IResetPasswordCardProps {
  onPasswordResetSubmission(args: IResetPasswordFormArgs): any;
}

export interface IResetPasswordFormState {
  password: string;
  passwordConfirmation: string;
}

export const DEFAULT_CARD_WIDTH = 350;
