import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { IStatusMessageProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IStatusMessageProps) => {
  const { palette, fonts, distance, borderRadii } = themeInfo;
  const { color, iconSize } = props;

  return StyleSheet.create({
    successMessageCradle: {
      height: "auto",
      width: 400,
      padding: distance.three,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      border: `2px solid ${(color) ? color: palette.primary}`,
      borderRadius: borderRadii.two,

      "@media (max-width: 450px)": { width: "100%" },
    },
    iconWrapper: {
      height: (iconSize) ? (iconSize + 10) : 50,
      width: (iconSize) ? (iconSize + 10) : 50,
      display: "grid",
      placeItems: "center",
      backgroundColor: "transparent",
      borderRadius: 500000,
      border: `2.5px solid ${(color) ? color : palette.primary}`,
    },
    headerText: {
      fontSize: "1.6rem",
      fontFamily: fonts.primary,
      fontStyle: "italic",
      fontWeight: "bold",
      color: palette.black,
      textAlign: "center",
      margin: 0,
    },
    messageText: {
      fontFamily: fonts.primary,
      fontSize: "1.4rem",
      color: palette.black,
      textAlign: "center",
      margin: 0,
    }    
  });
}