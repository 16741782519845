import { StyleSheet } from "aphrodite";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { determineScreenDimensions } from "../../../../helpers";
import {
  STATIC_MAP_LARGE_WIDTH, STATIC_MAP_SMALL_WIDTH,
  STATIC_MAP_XS_WIDTH, SCREEN_WIDTH_550
} from "../../../../constants";
import {
  DEFAULT_IMAGE_SIZE, EventDetailsState, STATIC_MAP_ASPECT_RATIO,
  EventDetailsProps,
} from "./helpers";

const { width: screenWidth } = determineScreenDimensions();

export const styleGen = (themeInfo: ThemeInfo, state: EventDetailsState, props: EventDetailsProps) => {
  const { actionHistoryCardMode } = props;
  const { hasMultipleExternalLinks } = state;
  const {
    palette, distance, fonts, typography, borderRadii
  } = themeInfo;

  let computedMapWidth: number;
  let computedMapHeight: number;

  if (actionHistoryCardMode) {
    computedMapWidth = STATIC_MAP_XS_WIDTH;
  }
  else {
    if (screenWidth > SCREEN_WIDTH_550) computedMapWidth = STATIC_MAP_LARGE_WIDTH;
    else computedMapWidth = STATIC_MAP_SMALL_WIDTH;
  }
  
  if (actionHistoryCardMode) {
    computedMapHeight = Math.floor(STATIC_MAP_XS_WIDTH * STATIC_MAP_ASPECT_RATIO);
  }
  else {
    if (screenWidth > SCREEN_WIDTH_550) computedMapHeight = Math.floor(STATIC_MAP_LARGE_WIDTH * STATIC_MAP_ASPECT_RATIO);
    else computedMapHeight = Math.floor(STATIC_MAP_SMALL_WIDTH * STATIC_MAP_ASPECT_RATIO);
  }

  return StyleSheet.create({
    componentCradle: {
      height: "100%",
      width: "70%",
      paddingTop: distance.three,
      paddingBottom: distance.three,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      backgroundColor: palette.white,

      "@media (max-width: 750px)": {
        width: "100%"
      },

      "@media (min-width: 1150px)": {
        width: actionHistoryCardMode ? "100%" : 1000,
      },
    },
    topSectionCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexDirection: actionHistoryCardMode ? "column" : "row",
      justifyContent: "center",
      alignItems: actionHistoryCardMode ? "center" : "flex-start",

      "@media (max-width: 550px)": {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }
    },
    fallbackIconCradle: {
      height: DEFAULT_IMAGE_SIZE,
      width: DEFAULT_IMAGE_SIZE,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: "50%",
    },
    eventDetailsCradle: {
      height: "auto",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      flex: 1,

      "@media (max-width: 550px)": {
        width: "100%",
        flex: "0 1 auto",
      }
    },
    topRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }, 
    eventNameLocationStatusDateTimeCol: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    eventNameText: {
      fontFamily: fonts.primary,
      fontSize: actionHistoryCardMode ? "1.8rem" : "3.4rem",
      fontWeight: "bold",
      color: palette.black,
    },
    locationAndTypeRow: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
    },
    eventLinksCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: (hasMultipleExternalLinks) ? "center" : "flex-start",
      alignItems: "center",
    },
    eventArtistsCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    noContentFallbackCradle: {
      height: "auto",
      width: "100%",
      paddingTop: distance.three,
      paddingBottom: distance.three,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    detailsLabelText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: actionHistoryCardMode ? "1.6rem" : "2rem",
      color: palette.black,
    },
    detailsLabelCradle: {
      padding: distance.one,
      borderRadius: borderRadii.one,
      background: palette.grey1,
      width: "min-content",
      "whiteSpace": "nowrap",
    },
    subtitleLabelText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: actionHistoryCardMode ? "1.4rem" : "1.6rem",
      color: palette.black,
    },
    bodyText: {
      fontFamily: fonts.primary,
      fontSize: actionHistoryCardMode ? "1.2rem" : "1.4rem",
      color: palette.black,
    },
    staticMapRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    staticMapCradle: {
      height: computedMapHeight,
      width: computedMapWidth,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    infoRow: {
      height: "auto",
      width: "auto",
      display: "flex",
      alignContent: "center",
    },
    eventVenuesCradle: {
      height: "auto",
      maxHeight: 600,
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    eventVenuesTitleBarCradle: {
      height: 'min-content',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    eventVenuesTitleBarText: {
      fontFamily: fonts.primary,
      fontWeight: 'bold',
      fontSize: actionHistoryCardMode ? "1.8rem" : '2rem',
    },
    eventVenuesTitleCradle: {
      padding: distance.one,
      borderRadius: borderRadii.one,
      background: palette.grey1,
      width: "min-content",
      "whiteSpace": "nowrap",
    },
    eventVenuesListCradle: {
      height: '100%',
      width: '100%',
      position: "relative",
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      overflowY: "scroll",
    },
    eventNoVenuesCradle: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    itemSeparatorCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    eventDateTimeText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "1.4rem",
      color: palette.grey4,
      "@media (max-width: 450px)": { fontSize: "1.2rem" },
    },
    noContentFallbackText: { ...typography.noContentFallbackText }
  });
};
