import React, { useContext } from 'react';
import { css, StyleSheet } from "aphrodite-jss";
import { ThemeInfo, UIContext } from '../../UI_InfoProvider/UI_InfoProvider';
import { ROUND, ROUNDED } from "../../../constants";
interface IImageWrapperProps {
  alt: string;
  src: string;
  height: string | number;
  width: string | number;
  borderGeometry?: "round" | "rounded";
  onClick?: any;
  customImgCradleStyles?: any;
  customImgStyles?: any;
  customHoverStyles?: any;
}

const ImageWrapper: React.FC<IImageWrapperProps> = (props: any) => {
  const { alt, src, height, width, borderGeometry, onClick } = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const {
    imgCradle, imgCradleRounded, imgCradleRound, customImgCradleStyles,
    customImgStyles
  } = styleGen(themeInfo, props);
  let borderGeometryStyle;

  if (borderGeometry === ROUNDED) borderGeometryStyle = imgCradleRounded;
  else if (borderGeometry === ROUND) borderGeometryStyle = imgCradleRound;

  const computedOnClick = (onClick) ? onClick : () => {};

  return (
    <div
      className={css(imgCradle, borderGeometryStyle, customImgCradleStyles)}
      onClick={computedOnClick}
    >
      <img
        src={src}
        alt={alt}
        className={css(borderGeometryStyle, customImgStyles)}
        style={{
          height, width,
          objectFit: 'cover',
        }}
      />
    </div>
  );
}

export default ImageWrapper;

const styleGen = (themeInfo: ThemeInfo, props: IImageWrapperProps) => {
  const { customImgStyles, customImgCradleStyles, customHoverStyles } = props;

  return StyleSheet.create({
    imgCradle: {
      height: props.height,
      width: props.width,
      display: "grid",
      placeItems: "center",

      "&:hover": { ...customHoverStyles },
    },
    imgCradleRounded: {
      borderRadius: themeInfo.borderRadii.one,
    },
    imgCradleRound: {
      borderRadius: "50%",
    },
    customImgCradleStyles: { ...customImgCradleStyles },
    customImgStyles: { ...customImgStyles }
  })
};