import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "src/Components/UI_InfoProvider/UI_InfoProvider";
import { BASE_CARD_HEIGHT, BASE_CARD_WIDTH, IEventCardProps, SMALL_CARD_HEIGHT } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IEventCardProps, computedImageSize: number) => {
  const { customCardCradleStyles } = props;
  const { distance, fonts, shadows, borderRadii, palette, transitions } = themeInfo;

  return StyleSheet.create({
    cardCradle: {
      width: BASE_CARD_WIDTH,
      height: BASE_CARD_HEIGHT,
      padding: distance.three,
      display: "flex",
      flexdirection: "row",
      backgroundColor: palette.white,
      borderRadius: borderRadii.one,
      boxShadow: shadows.one,
      transition: transitions.bgColorTransition,
      '&:hover': {
        cursor: "pointer",
        backgroundColor: palette.grey1,
      },

      "@media (max-width: 450px)": {
        width: "100%",
        height: SMALL_CARD_HEIGHT,
      },
    },
    imgSection: {
      height: "100%",
      width: computedImageSize,
      display: "flex",
      justifyContent: "center",
    },
    eventInfoSection: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    fallbackIconCradle: {
      height: computedImageSize,
      width: computedImageSize,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: borderRadii.one,
    },
    eventNameText: {
      fontFamily: fonts.primary,
      fontSize: "1.8rem",
      fontWeight: "bold",
      color: palette.black,

      "@media (max-width: 450px)": {
        fontSize: "1.6rem",
      },
    },
    locationRow: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    eventDateTimeText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "1.2rem",
      color: palette.grey4,
      "@media (max-width: 450px)": { fontSize: "1.1rem" },
    },
    customCardCradleStyles: { ...customCardCradleStyles }
  });
}