import { css } from "aphrodite"
import { MFAType } from "../../../../../../Types";

export const SELECTION_INDICATOR_SIZE = 25;
export const INDICATOR_BG_COLOR_TRANSITION_STYLE="background-color 125ms ease-in-out";

export interface IMFAVerificationOptionProps {
  verificationOption: MFAType;
  displayedVerificationOption: string;
  selected: boolean;
  onSelectVerificationOption(option: string): void;
}

export interface IMFAVerificationOptionState {
  selected: boolean;
}

export const calibrateComponent = (styles: any, state: IMFAVerificationOptionState) => {
  const { componentCradle, componentCradleSelected } = styles;
  const { selected } = state;

  const computedActiveStyle = (selected) ? componentCradleSelected : undefined;
  const componentCradleStylechain = css(componentCradle, computedActiveStyle);

  return { componentCradleStylechain };
}
