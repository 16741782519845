import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../UI_InfoProvider/UI_InfoProvider";
import { ITypeAheadSearchProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: ITypeAheadSearchProps) => {
  const { palette, distance, borderRadii, typography } = themeInfo;
  const {
    baseCradleHeight, baseCradleWidth, baseCradleBorder, 
    dropCradleHeight, dropCradleWidth, minDropCradleHeight, maxDropCradleHeight,
    typeAheadSearchPadding, backgroundColor, dropCradleGap, shadow,
    customBaseItemTextStyles, customTypeAheadSearchItemTextStyles, maxDropCradleWidth,
    minDropCradleWidth, customBaseCradleStyles, customDropCradleStyles,
    dropCradleBorder, disabled, customTypeAheadSearchCradleStyles, customTypeAheadSearchItemCradleStyles,
    customTypeAheadSearchItemInnerCradleStyles,
  } = props;

  const computedTypeAheadPadding = typeAheadSearchPadding ? typeAheadSearchPadding : distance.one;
  const computedBackgroundColor = backgroundColor ? backgroundColor : palette.white;
  const computedBaseCradleBoxShadow = shadow ? shadow : themeInfo.shadows.one;
  const computedDropCradleBoxShadow = shadow ? shadow : themeInfo.shadows.one;
  const computedDropCradleBorder = dropCradleBorder ? dropCradleBorder : 'none';
  const computedMinDropCradleWidth = minDropCradleWidth ? minDropCradleWidth : baseCradleWidth;

  // console.log(`base cradle width and height: ${baseCradleWidth}px, ${baseCradleHeight}px`);

  return StyleSheet.create({
    componentCradle: {
      position: 'relative',
      // minHeight: baseCradleHeight,
      height: baseCradleHeight,
      width: baseCradleWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    baseCradle: {
      // position: 'absolute',
      // top: 0, right: 0, bottom: 0, left: 0,
      height: baseCradleHeight,
      width: baseCradleWidth,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: computedTypeAheadPadding,
      paddingBottom: 0,
      paddingTop: 0,
      backgroundColor: computedBackgroundColor,
      border: (baseCradleBorder) ? baseCradleBorder : `1px solid ${palette.grey2}`,
      '&:hover': {
        cursor: (disabled) ? 'default' : 'pointer',
      },
    },
    baseCradleRounded: { borderRadius: borderRadii.one },
    baseCradleRound: { borderRadius: "50%" },
    baseCradleRaised: {boxShadow: computedBaseCradleBoxShadow},
    baseCradleDisabled: {opacity: 0.3},
    // - TODO: -> This is not doing the trick, want overflowing base item text not to wrap and to have an end ellipsis.
    staticBaseItemTextStyles: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    baseItemTextStyles : {
      fontFamily: themeInfo.fonts.primary,
      fontSize: '1.4rem',
      color: palette.black,
    },
    dropCradle: {
      zIndex: themeInfo.stackingIndexes.dropdown,
      position: 'absolute',
      // left: computeLeftPropertyValue(props),
      // right: computeRightPropertyValue(props),
      left: 0, right: 0,
      // top: (baseCradleHeight && dropCradleGap) ? (baseCradleHeight + dropCradleGap) : 0, 
      top: 35,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: computedTypeAheadPadding,
      backgroundColor: computedBackgroundColor,
      border: computedDropCradleBorder,
      height: (dropCradleHeight) ? dropCradleHeight : 'auto',
      width: (dropCradleWidth) ? dropCradleWidth : 'auto',
      minHeight: minDropCradleHeight,
      minWidth: computedMinDropCradleWidth,
      maxHeight: maxDropCradleHeight,
      maxWidth: maxDropCradleWidth,
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    dropCradleRounded: { borderRadius: borderRadii.one },
    dropCradleRound: { borderRadius: "50%" },
    // dropCradleRounded: {...borderRadiusConfig(props, themeInfo, state, {segment: 'dropcradle'})},
    // dropCradleRound: {...borderRadiusConfig(props, themeInfo, state, {segment: 'dropcradle'})},
    noSearchResultsAvailableCradle: {
      height: 50,
      width: "100%",
      display: "grid",
      placeItems: "center",
    },
    noSearchResultsAvailableText: { ...typography.noContentFallbackText },
    dropCradleRaised: {boxShadow: computedDropCradleBoxShadow},
    customTypeAheadSearchCradleStyles: { ...customTypeAheadSearchCradleStyles },
    customBaseCradleStyles: {...customBaseCradleStyles},
    customBaseItemTextStyles: {...customBaseItemTextStyles},
    customDropCradleStyles: {...customDropCradleStyles},
    customTypeAheadSearchItemCradleStyles: { ...customTypeAheadSearchItemCradleStyles },
    customTypeAheadSearchItemInnerCradleStyles: { ...customTypeAheadSearchItemInnerCradleStyles },
    customTypeAheadSearchItemTextStyles: { ...customTypeAheadSearchItemTextStyles },
  });
}
