export const PAGE_STATE_NEW = "new";
export const PAGE_STATE_SUBMITTED = "submitted";

export interface IRequestResetState {
  loadingStatus: boolean;
  errorStatus: boolean;
  errorMessage?: string;
  cardInStatus: boolean;
  loaderInStatus: boolean;
  successMsgInStatus: boolean;
  pageState: "new" | "submitted";
}
