import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { CARD_ASPECT_RATIO } from "../../../../Components/Cards/EventCard/helpers";
import { IEventGalleryProps } from "./helpers";

interface IInfoFromState {
  cardWidth: number;
  cardHeight: number;
}

export const styleGen = (
  themeInfo: ThemeInfo,
  props: IEventGalleryProps,
  state: IInfoFromState,
) => {
  const { customEventGalleryCradleStyles } = props;
  const { distance, palette, typography } = themeInfo;
  const { cardWidth, cardHeight } = state;

  return StyleSheet.create({
    galleryCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      backgroundColor: palette.white,
    },
    galleryContentCradle: {
      height: "100%",
      width: "100%",
      padding: distance.three,
      display: "grid",
      gridTemplateColumns: `repeat(auto-fill, ${cardWidth}px)`,
      gridTemplateRows: `repeat(auto-fit, ${cardHeight}px)`,
      justifyContent: "center",
      justifyItems: "center",
      gridGap: `2.5rem`,
      gridAutoFlow: "dense",
      // overflowY: "scroll",
      backgroundColor: palette.white,
    },
    galleryNoEventsCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: palette.white,
    },
    noContentFallbackText: {
      ...typography.noContentFallbackText,
    },
    customEventGalleryCradleStyles: { ...customEventGalleryCradleStyles },
  });
}
