/* eslint-disable @typescript-eslint/no-unused-vars */
// -> Beyond Codebase
import React, { useCallback, useContext, useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import jwt_decode from "jwt-decode";
import fetchIntercept from "fetch-intercept";
import { useTranslation } from "react-i18next";
// -> Within Codebase
import { artists, events, venues } from '../../staticData'; 
import { DataContext } from "../DataContextProvider/DataContextProvider";
import { UIContext } from '../UI_InfoProvider/UI_InfoProvider';
import { IUser, MFAType, ISongPair, Venue } from "../../Types";
import { TEMP_AVATAR_URL, TEMP_LOGGED_IN_TOKEN } from '../../constants';
import { usePromise as usepromise } from "../../helpers";
// -> Within Component
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  IRegistrationArgs, ILoginArgs, REGISTRATION_URL, LOGIN_URL,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RENEW_ACCESS_URL, USER_URL, FEtoBERegistrationArgs, BEtoFEUserKeys, FEtoBEUserKeys,
  IAccessTokenClaims, IAuthContextState, REFRESH_TRIGGER,
  initAuthContextValue, REQUEST_RESET_URL, IRequestResetArgs,
  IResetPasswordArgs, RESET_PASSWORD_URL, ACTIVATE_EMAIL_MFA_URL,
  ACTIVATE_SMS_MFA_URL, CONFIRM_EMAIL_MFA_URL, CONFIRM_SMS_MFA_URL,
  DEACTIVATE_SMS_MFA_URL, DEACTIVATE_EMAIL_MFA_URL, PROVIDE_MFA_CODE_URL,
  POTENTIAL_SONG_PAIR_URL, staticUserDetails,
} from "./helpers";
import JWTManager from "./JWTManager";
import { setEvents } from '../DataContextProvider/stateManagement';
import { setArtists } from 'src/Pages/Artists/stateManagement';

const { REACT_APP_BACKEND_BASE_URL } = process.env;

export const AuthContext = React.createContext(initAuthContextValue);

const AuthContextProvider: React.FC = (props) => {
  const { children } = props;
  const [state, setState] = useState<IAuthContextState>({
    loginCheckComplete: false,
    potentialSongPairs: undefined,
    user: undefined,
    // user: staticUserDetails,
  });
  const { setVenues } = useContext(DataContext);
  const { setLanguage } = useContext(UIContext);
  const { i18n } = useTranslation();


  // ----------------------- //
  // - REQUEST INTERCEPTOR - //
  // ----------------------- //
  const interceptNetworkRequest = (url: string, config: any): any => {
    // console.info(`[AuthContextProvider]: Network request -> ${config}`);
    return [url, config];
  };


  // ----------------------------- //
  // - REQUEST ERROR INTERCEPTOR - //
  // ----------------------------- //
  const interceptNetworkRequestError = (err: any): any => {
    // console.error(`[AuthContextProvider]: Network request error -> ${err.message}`);
    return Promise.reject(err);
  };


  // ------------------------ //
  // - RESPONSE INTERCEPTOR - //
  // ------------------------ //
  const interceptNetworkResponse = (response: any): any => {
    // console.info(`[AuthContextProvider]: network response -> ${response}`);
    return response;
  };

  // ------------------------------- //
  // - RESPONSE ERROOR INTERCEPTOR - //
  // ------------------------------- //
  const interceptNetworkResponseError = useCallback(async (err: any): Promise<any> => {
    // console.error(`[AuthContextProvider]: Network response error -> ${err}`);

    // -> Return any error which is not due to authentication problems
    if (err.response.status !== 401) return new Promise((_, reject) => reject(err));

    // -> Log out if an error comes back from trying to refresh the access token.
    //   -> This error will get passed along to the return of the renewAccess() call,
    //      which will result in a logout.
    if (err.config.url === RENEW_ACCESS_URL) return new Promise((_, reject) => reject(err));

    // -> Try to refresh the access token again
    await renewAccess();
    // - TODO: -> Try to make the API call again that failed originally.
    //           -> Potentially could have a switch statement that checks the original request
    //              URL and then calls the corresponding method?
    //           -> Is there a way to resend the already prepared HTTP request or does it need
    //              to be prepared again? How to account for original parameters passed in (e.g.
    //              user details when editing a profile)
    // switch (err.config.url) {
    //   case USER_URL:
        
    // }

    return Promise.reject(err);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -> Runs on mount. Check to see if there's a refresh token first. If so,
  //    try to use it in order to renew the access token.
  // -> Sets up an interceptor as well which retriggers requests that require
  //    an access token (if they come back as failing since the access token
  //    expired) once it tries to refresh it.
  useEffect(() => {
    const unregisterFetchInterceptor = fetchIntercept.register({
      request: interceptNetworkRequest,
      requestError: interceptNetworkRequestError,
      response: interceptNetworkResponse,
      responseError: interceptNetworkResponseError,
    });
    // const refreshToken = JWTManager.getRefreshToken()
    // if (refreshToken) renewAccess();
    // else setState({ user: undefined, loginCheckComplete: true });

    if (localStorage.getItem(TEMP_LOGGED_IN_TOKEN)) {
      setState({ user: staticUserDetails, loginCheckComplete: true });
      // setUser(staticUserDetails);
      if (staticUserDetails.settings.setLanguageUsingBrowserSetting === false) {
        const defaultLanguage = staticUserDetails.settings.defaultLanguage;
        setLanguage(defaultLanguage);
      }
      setVenues(venues);
      setEvents(events);
      setArtists(artists);
    }
    else setState({ user: undefined, loginCheckComplete: true });

    return () => unregisterFetchInterceptor();
  // - TODO: -> Putting setSongs into the dependency array here seems to trigger an infinite loop.
  //           -> Is setSongs being updated everytime it's called, since it changes the internal state?
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interceptNetworkResponseError]);


  // ---------------------------- //
  // - GET CURRENT ACCESS TOKEN - //
  // ---------------------------- //
  const getAccessToken = () => JWTManager.getToken();

  
  // ----------------------------- //
  // - SET CURRENT USER IN STATE - //
  // ----------------------------- //
  const setUser = (user: IUser | undefined): void => {
    const { loginCheckComplete } = state;
    const stateCopy = cloneDeep(state);

    stateCopy.user = user;
    // - DEV NOTE -> Temporary since the backend doesn't support avatars at the moment
    if (stateCopy.user) {
      stateCopy.user.avatarURL = TEMP_AVATAR_URL;
    }
    setState(stateCopy);
    // -> If the system was in the process of refreshing the access token while retrieving the user
    //    information, flip the status boolean to true to update the user interface that this process
    //    has now resolved.
    if (!loginCheckComplete) setState({ loginCheckComplete: true });
  };


  // ------------------------------- //
  // - GET CURRENT USER FROM STATE - //
  // ------------------------------- //
  const getUser = (): IUser | undefined => state.user;


  // --------------------------------- //
  // - GET CURRENT USER FROM NETWORK - //
  // --------------------------------- //
  // - TODO: -> If error response comes back implying that authorization failed on the access
  //            token, set up a workflow to try and get a new one with the refresh token, and
  //            to try the API call again.
  //            -> If this subsequently fails, log the user out?
  const getCurrentUser = async (): Promise<any> => {
    const requestURL = REACT_APP_BACKEND_BASE_URL + USER_URL;
    let data;
    let error;

    const [responseData, responseError] = await usepromise(fetch(requestURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${JWTManager.getToken()}`,
      }
    }));

    if (responseError) return { data, error: responseError };

    const BEUserObj = await responseData.json();
    const user = BEtoFEUserKeys(BEUserObj);

    // - DEV NOTE -> As of March 07, 2021, information about MFA status does not come along with
    //               the return object of a user request to the server. Since it only comes along
    //               during an MFA-enabled login, I put that into state on the ephemeralUserInfo
    //               object, and then it's available to splice in when grabbing the user info here.
    if (state.ephemeralUserInfo) {
      const { ephemeralUserInfo: { MFAType }} = state;
      user.settings.MFAType = MFAType;
      if (MFAType !== "None") user.settings.MFAEnabled = true;
    }

    setUser(user);
    data = { user };

    return { data, error };
  };

  // --------------------- //
  // - EDIT CURRENT USER - //
  // --------------------- //
  const editUser = async (args: Partial<IUser>): Promise<any> => {
    const requestURL = REACT_APP_BACKEND_BASE_URL + USER_URL;
    let data;
    let error;

    const processedArgs = FEtoBEUserKeys(args);

    const [responseData, responseError] = await usepromise(fetch(requestURL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${JWTManager.getToken()}`,
      },
      body: JSON.stringify(processedArgs),
    }));

    if (responseError) return { data, error: responseError };

    const user = await responseData.json();
    const processedUser = BEtoFEUserKeys(user);
    setUser(processedUser);
    data = { user: processedUser };

    return { data, error };
  };


  // ---------------------------- //
  // - GET POTENTIAL SONG PAIRS - //
  // ---------------------------- //
  const getPotentialSongPairs = async (): Promise<any> => {
    const requestURL = REACT_APP_BACKEND_BASE_URL + POTENTIAL_SONG_PAIR_URL;
    let data;
    let error;

    console.log("[AuthContextProvider]: getPotentialSongPairs()");

    const [responseData, responseError] = await usepromise(fetch(requestURL, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }));

    if (responseError) return { data, error: responseError };

    const potentialSongPairs: ISongPair[] = await responseData.json();

    return { data: potentialSongPairs, error };
  }


  // ---------------- //
  // - REGISTRATION - //
  // ---------------- //
  const register = async (args: IRegistrationArgs): Promise <any> => {
    // const requestURL = REACT_APP_BACKEND_BASE_URL + REGISTRATION_URL;
    let data;
    let error;

    // -> Change keys to match backend key names and overall shape
    // const processedArgs = FEtoBERegistrationArgs(args);

    // console.log("[AuthContextProvider]: register(): processed args -> ", processedArgs);

    // -> As of Jan 25 2021, the backend currently just returns a standard Django
    //    session token upon registration from the endpoint. A decision was taken
    //    in that context not to pass any element of the return payload onto 
    //    React components that call this API method.
    // const [responseData, responseError] = await usepromise(fetch(requestURL, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(processedArgs),
    // }));

    // if (responseError) return { data, error: responseError };

    // const { key } = await responseData.json();

    // return { data: { key }, error };

    data = true;
    return { data, error };
  };


  // --------- //
  // - LOGIN - //
  // --------- //
  const login = async (args: ILoginArgs): Promise<any> => {
    // const requestURL = REACT_APP_BACKEND_BASE_URL + LOGIN_URL;
    let data;
    let error;
    
    // -> PART 1: Request access and refresh tokens using the login credentials.
    // const [responseData, responseError] = await usepromise(fetch(requestURL, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(args),
    // }));
    
    // if (responseError) return { data, error: responseError };
    
    // -> Access and Refresh JSON Web Tokens
    // const { access, refresh, ephemeral_token, mfa_method, email, phone  } = await responseData.json();

    // - DEV NOTE -> This handles the edge case where we are logging in with 2FA enabled.
    //               Send off the ephemeral token for the login process and the Login screen
    //               will pass it onto the code entry screen to be included along with it.
    // if ((!access) && (!refresh)) {
    //   let MFAType: MFAType = "None"
    //   if (mfa_method === "sms_twilio") MFAType = "SMS";
    //   else if (mfa_method === "email") MFAType = "Email"
    //   setState((prevState) => ({ ...prevState, ephemeralUserInfo: { MFAType }}));
      
    //   data = { ephemeralToken: ephemeral_token, email, phone, mfa_method }
    //   return { data, error };
    // }

    // console.log("access token -> ", access);

    // const { exp: expiryDateTime, user_id }: IAccessTokenClaims = jwt_decode(access);
    // JWTManager.setToken(access);
    // JWTManager.setRefreshToken(refresh);
    // JWTManager.setExpiryDateTime(expiryDateTime);
    // renewAccessWithDelay(JWTManager.getExpiryDateTime()); // -> Auto-renew at some interval before expiry

    // -> PART 2: Get the user object.
    // try {
    //   const { data: currentUserData, error: currentUserError } = await getCurrentUser();
      
    //   if (currentUserError) return { data, error: currentUserError };

    //   const { user: currentUser } = currentUserData;
    //   currentUser.id = user_id; // -> Tack this on from when it came back with the access JWT
    //   setUser(currentUser);
    //   data = { user: currentUser };
      
    //   return { data, error };

    // } catch (err) {
    //   return { data, error: err };
    // }

    localStorage.setItem(TEMP_LOGGED_IN_TOKEN, "Logged In");
    setUser(staticUserDetails);

    if (staticUserDetails.settings.setLanguageUsingBrowserSetting === false) {
      const defaultLanguage = staticUserDetails.settings.defaultLanguage;
      setLanguage(defaultLanguage);
    }

    data = staticUserDetails;
    return { data, error };
  };


  // ---------- //
  // - LOGOUT - //
  // ---------- //
  const logout = () => {
    // JWTManager.eraseToken();
    // JWTManager.eraseRefreshToken();
    // JWTManager.eraseExpiryDateTime();
    localStorage.removeItem(TEMP_LOGGED_IN_TOKEN);
    setUser(undefined);
  };


  // ---------------------- //
  // - RENEW ACCESS TOKEN - //
  // ---------------------- //
  const renewAccess = async (): Promise<any> => {
    console.log("[AuthContextProvider]: renewAccess() invoked");
    const requestURL = REACT_APP_BACKEND_BASE_URL + RENEW_ACCESS_URL;
    let data: any;
    let error: any;

    // -> PART 1: Request new access token using refresh token
    const [responseData, responseError] = await usepromise(fetch(requestURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refresh: JWTManager.getRefreshToken() })
    }));
    
    if (responseError){
      JWTManager.eraseToken();
      JWTManager.eraseRefreshToken();
      JWTManager.eraseExpiryDateTime();
      setUser(undefined);
      return { data, error: responseError };
    }

    const { access } = await responseData.json();

    const { exp: expiryDateTime, user_id }: IAccessTokenClaims = jwt_decode(access);

    JWTManager.setToken(access);
    JWTManager.setExpiryDateTime(expiryDateTime);
    renewAccessWithDelay(JWTManager.getExpiryDateTime()); // -> Auto-renew at some interval before expiry

    // -> PART 2: Get the user object.
    try {
      const { data: currentUserData, error: currentUserError } = await getCurrentUser();
      
      if (currentUserError) throw new Error(currentUserError);

      const { user: currentUser } = currentUserData;
      currentUser.id = user_id; // -> Tack this on from when it came back with the access JWT
      setUser(currentUser);
      data = { user: currentUser };
      
      return { data, error };

    } catch (err) {
      return { data, error: err };
    }
  };


  // ---------------------------- //
  // - RENEW ACCESS WITH DELAY  - //
  // ---------------------------- //
  const renewAccessWithDelay = (tokenExpiryDateTime: number | null, ) => {
    // -> Edge case where somehow a null expiry datetime is passed in.
    //   -> Expiry datetime can be null if there is no user signed in (since there's no access token at all)
    //   -> TypeScript is unhappy if this logic check is not here, given the mathematical calculation
    //      that takes place involving its value below.
    if (!tokenExpiryDateTime) return;

    setTimeout(async () => {
      await renewAccess();
    }, tokenExpiryDateTime - Date.now() - REFRESH_TRIGGER);
  };


  // -------------------------------- //
  // - REQUEST PASSWORD RESET EMAIL - //
  // -------------------------------- //
  const requestPasswordReset = async (args: IRequestResetArgs): Promise<any> => {
    // const requestURL = REACT_APP_BACKEND_BASE_URL + REQUEST_RESET_URL;
    // const { email: recoveryEmail } = args;
    let data;
    let error;

    // const [responseData, responseError] = await usepromise(fetch(requestURL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     // "Authorization": `Bearer ${JWTManager.getToken()}`
    //   },
    //   body: JSON.stringify({ email: recoveryEmail }),
    // }));

    // if (responseError) return { data, error: responseError };

    data = true;
    return { data, error };
  };


  // ------------------ //
  // - RESET PASSWORD - //
  // ------------------ //
  const resetPassword = async (args: IResetPasswordArgs) => {
    // const requestURL = REACT_APP_BACKEND_BASE_URL + RESET_PASSWORD_URL;
    let data;
    let error;

    // const [responseData, responseError] = await usepromise(fetch(requestURL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": `Bearer ${JWTManager.getToken()}`,
    //   },
    //   body: JSON.stringify(args),
    // }));
    
    // if (responseError) return { data, error: responseError };

    data = true;
    return { data, error };
  };

  // ---------------- //
  // - ACTIVATE MFA - //
  // ---------------- //
  const activateMFA = async (MFAType: MFAType) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let requestURL, data, error;
    
    // if (MFAType === "Email") requestURL = REACT_APP_BACKEND_BASE_URL + ACTIVATE_EMAIL_MFA_URL;
    // else if (MFAType === "SMS") requestURL = REACT_APP_BACKEND_BASE_URL + ACTIVATE_SMS_MFA_URL;
    // else if (MFAType === "None") return;
    // else {
    //   console.error("Invalid MFA type provided to API method 'activateMFA()'");
    //   return;
    // }

    // const [responseData, responseError] = await usepromise(fetch(requestURL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": `Bearer ${JWTManager.getToken()}`,
    //   },
    //   body: (MFAType === "Email") ? JSON.stringify({ email: "" }) : undefined,
    // }));

    // if (responseError) return { data, error: responseError };

    data = true;
    return { data, error };
  };

  // --------------- //
  // - CONFIRM MFA - //
  // --------------- //
  const confirmMFA = async (MFAType: MFAType, code: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let requestURL, data, error;
    
    // if (MFAType === "Email") requestURL = REACT_APP_BACKEND_BASE_URL + CONFIRM_EMAIL_MFA_URL;
    // else if (MFAType === "SMS") requestURL = REACT_APP_BACKEND_BASE_URL + CONFIRM_SMS_MFA_URL;
    // else if (MFAType === "None") return;
    // else {
    //   console.error("Invalid MFA type provided to API method 'confirmMFA()'");
    //   return;
    // }

    // const [responseData, responseError] = await usepromise(fetch(requestURL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": `Bearer ${JWTManager.getToken()}`,
    //   },
    //   body: JSON.stringify({ code }),
    // }));

    // if (responseError) return { data, error: responseError };
    data = true;
    return { data, error };
  };

  // ------------------ //
  // - DEACTIVATE MFA - //
  // ------------------ //
  const deactivateMFA = async (MFAType: MFAType) => {
    let requestURL, data;
    let error = false;
    
    // if (MFAType === "Email") requestURL = REACT_APP_BACKEND_BASE_URL + DEACTIVATE_EMAIL_MFA_URL;
    // else if (MFAType === "SMS") requestURL = REACT_APP_BACKEND_BASE_URL + DEACTIVATE_SMS_MFA_URL;
    // else if (MFAType === "None") return;
    // else {
    //   console.error("Invalid MFA type provided to API method 'deactivateeMFA()'");
    //   return;
    // }

    // const [responseData, responseError] = await usepromise(fetch(requestURL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": `Bearer ${JWTManager.getToken()}`,
    //   },
    // }));

    // if (responseError) return { data, error: responseError };

    // - DEV NOTE -> Success response is currently set to 204: No Content, so this data will be undefined.
    data = true;
    return { data, error };
  };

  // -------------------- //
  // - PROVIDE MFA CODE - //
  // -------------------- //
  const provideMFACode = async (code: string, ephemeralToken: string) => {
    // const requestURL = REACT_APP_BACKEND_BASE_URL + PROVIDE_MFA_CODE_URL;
    let data, error;

    // const [responseData, responseError] = await usepromise(fetch(requestURL, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ code, ephemeral_token: ephemeralToken }),
    // }));

    // if (responseError) return { data, error: responseError };

    // // -> Access and Refresh JSON Web Tokens
    // const { access, refresh } = await responseData.json();

    // const { exp: expiryDateTime, user_id }: IAccessTokenClaims = jwt_decode(access);
    // JWTManager.setToken(access);
    // JWTManager.setRefreshToken(refresh);
    // JWTManager.setExpiryDateTime(expiryDateTime);
    // renewAccessWithDelay(JWTManager.getExpiryDateTime()); // -> Auto-renew at some interval before expiry

    // // -> PART 2: Get the user object.
    // try {
    //   const { data: currentUserData, error: currentUserError } = await getCurrentUser();
      
    //   if (currentUserError) return { data, error: currentUserError };

    //   const { user: currentUser } = currentUserData;
    //   currentUser.id = user_id; // -> Tack this on from when it came back with the access JWT
    //   setUser(currentUser);
    //   data = { user: currentUser };
      
    //   return { data, error };

    // } catch (err) {
    //   return { data, error: err };
    // }
    data = true;
    return { data, error };
  };

  const authContextState = {...state};

  return (
      <AuthContext.Provider value={{
        authContextState, setUser, getUser, editUser,
        register, login, logout, requestPasswordReset,
        resetPassword, activateMFA, confirmMFA,
        deactivateMFA, provideMFACode, getAccessToken,
        getPotentialSongPairs
      }}>
        { children }
      </AuthContext.Provider>
  );
}

export default AuthContextProvider;
