// -> Beyond codebase
import React, { useContext, useEffect, useMemo, useReducer, useRef } from "react";
import { css } from "aphrodite-jss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// -> Within codebase
import FillUnderNavBarCradle from "../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import IconButton from "../../Components/Button/Variants/IconButton/IconButton";
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import PlusIcon from "../../Components/VisualUtilities/IconPresets/PlusIcon";
import Pagination from "../../Components/Pagination/Pagination";
import Spacer from "../../Components/LayoutUtilities/Spacer/Spacer";
import { UIContext, ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../Components/DataContextProvider/DataContextProvider";
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import { Event } from "../../Types";
import { useQueryParams } from "../../helpers";
import {
  GRID, DEFAULT_TRANSITION_MICROANIMATION_TIME, ROUND, ADD_EVENT_ROUTE, VERTICAL,
  PAGE_NUM_PARAM_KEY, ARTISTS_ROUTE, EVENT_ID_ROUTE_PARAM_TOKEN, EVENT_ROUTE
} from "../../constants";
// -> Within component
import TopBar from "./InternalComponents/TopBar/TopBar";
import EventGallery from "./InternalComponents/EventGallery/EventGallery";
import EventList from "./InternalComponents/EventList/EventList";
import { styleGen } from "./EventsStyles";
import { IEventsProps } from "./helpers";
import {
  reducer, setDisplayMode, setLoadingStatus, setLoaderInStatus,
  setSortCriteria, setSortDirection, applySortConfig, clearSortConfig,
  setFilterCriteria, setFilterCondition, setFilterArgument, applyFilterConfig,
  clearFilterConfig, setEvents, setPaginatedEvents, setFilterConditionType,
  addMicroanimationTimeoutID, addFilterArgumentFadeInStatus,
  removeFilterArgumentFadeInStatus, setFilterArgumentFadeInStatuses,
  setSortActivityIndicatorInStatus, setFilterActivityIndicatorInStatus,
  setSearchActivityIndicatorInStatus, searchEventsAction, clearEventSearch,
  setPageContentInStatus, setCurrentPage, initialEventsState, setItemsPerPage
} from "./stateManagement";

const Events: React.FC<IEventsProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialEventsState);
  const routerHistory = useHistory ();
  const queryParams = useQueryParams();
  const { state: { events }} = useContext(DataContext);
  const { getUser } = useContext(AuthContext);
  const { t } = useTranslation(["component_Pagination", "page_events"]);
  const { themeInfo } = useContext(UIContext);
  const { palette, shadows, distance } = themeInfo;
  const {
    componentCradle, eventsContentCradle, customPageCradleStyles, loaderCradle, addEventBtnCradle
  } = styleGen(themeInfo, props);

  let pageContentMicroanimationTimeoutID = useRef<any>(-1);
  const pageNumParam = useMemo(() => parseInt(queryParams.get(PAGE_NUM_PARAM_KEY) || "1"), [queryParams]);

  // -----

  const onEventClick = (eventID: string) => {
    const computedURL = EVENT_ROUTE.replace(EVENT_ID_ROUTE_PARAM_TOKEN, eventID);

    dispatch(setPageContentInStatus(false));
    routerHistory.push(computedURL);
    pageContentMicroanimationTimeoutID.current = setTimeout(() => {
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  }

  // -----

  const onNavigateToAddEvent = () => {
    dispatch(setPageContentInStatus(false));
    // - TODO: -> Why isn't the set timeout callback invoking?
    routerHistory.push(ADD_EVENT_ROUTE);
    pageContentMicroanimationTimeoutID.current = setTimeout(() => {
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  useEffect(() => {
    const user = getUser();
    console.log(`[Events]: user -> {${JSON.stringify(user, null, 3)}}`);
    const {
      settings: {
        itemsPerPage: itemsPerPage_Settings,
        defaultDisplayMode: defaultDisplayMode_Settings,
      }
    } = user!;
    

    dispatch(setItemsPerPage(itemsPerPage_Settings));
    dispatch(setDisplayMode(defaultDisplayMode_Settings));
    dispatch(setEvents(events));
    dispatch(setPaginatedEvents());

    const numInitialPages = Math.ceil(events.length / itemsPerPage_Settings);
    if (pageNumParam < 1) {
      dispatch(setCurrentPage(1));
    }
    else if (pageNumParam >= 1 && pageNumParam <= numInitialPages) {
      dispatch(setCurrentPage(pageNumParam));
    }
    else {
      dispatch(setCurrentPage(numInitialPages));
      window.history.pushState(null, "", `${ARTISTS_ROUTE}?${PAGE_NUM_PARAM_KEY}=${numInitialPages}`);
    }

    // -----
    
    setTimeout(() => {
      dispatch(setLoaderInStatus(false));
      setTimeout(() => {
        dispatch(setLoadingStatus(false));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }, 1000);
  }, [events, getUser, pageNumParam])
  
  // -----

  useEffect(() => {
    return () => {
      state.filtArgMicroAnimationTimeoutIDs.map((timeoutID) => clearTimeout(timeoutID));
      clearTimeout(pageContentMicroanimationTimeoutID.current);
    };
  }, [pageContentMicroanimationTimeoutID, state.filtArgMicroAnimationTimeoutIDs]);

  // -----

  const onSetCurrentPage = (pageNumber: number) => {
    dispatch(setCurrentPage(pageNumber));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // -----

  const assignEventsListItemNumberByID = (id: string): number => {
    const { events } = state;
    const listItemNumber = events.findIndex((event: Event) => event.id === id) + 1;
    
    return listItemNumber;
  };

  // -----

  const {
    displayMode, loadingStatus, loaderInStatus, displayedEvents, pageContentInStatus,
    currentPageNumber, itemsPerPage, numberOfDisplayableEvents
  } = state;

  return (
    <FillUnderNavBarCradle>
      {
        (loadingStatus) ? (
          <div className={css(loaderCradle)}>
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={palette.primary} />
            </Fade>
          </div>
        ) : (
          <Fade inStatus={pageContentInStatus}>
            <div className={css(componentCradle, customPageCradleStyles)}>
              <TopBar
                // -> General
                displayMode={displayMode}
                state={state}
                dispatch={dispatch}
                setDisplayMode={setDisplayMode}
                addMicroanimationTimeoutID={addMicroanimationTimeoutID}
                // -> Sorting
                setSortCriteria={setSortCriteria}
                setSortDirection={setSortDirection}
                applySortConfig={applySortConfig}
                clearSortConfig={clearSortConfig}
                setSortActivityIndicatorInStatus={setSortActivityIndicatorInStatus}
                // -> Filtering
                setFilterCriteria={setFilterCriteria}
                setFilterCondition={setFilterCondition}
                setFilterConditionType={setFilterConditionType}
                setFilterArgument={setFilterArgument}
                applyFilterConfig={applyFilterConfig}
                clearFilterConfig={clearFilterConfig}
                addFilterArgumentFadeInStatus={addFilterArgumentFadeInStatus}
                removeFilterArgumentFadeInStatus={removeFilterArgumentFadeInStatus}
                setFilterArgumentFadeInStatuses={setFilterArgumentFadeInStatuses}
                setFilterActivityIndicatorInStatus={setFilterActivityIndicatorInStatus}
                // -> Searching
                searchArtistsAction={searchEventsAction}
                clearArtistSearch={clearEventSearch}
                setSearchActivityIndicatorInStatus={setSearchActivityIndicatorInStatus}
              />
              <div className={css(eventsContentCradle)}>
                {
                  (displayMode === GRID) ? (
                    <EventGallery
                      events={displayedEvents}
                      onEventClick={onEventClick}
                    />
                  ) : (
                    <EventList
                      events={displayedEvents}
                      currentPageNumber={currentPageNumber}
                      itemsPerPage={itemsPerPage}
                      onEventClick={onEventClick}
                      assignEventsListItemNumberByID={assignEventsListItemNumberByID}
                    />
                  )
                }

                <Spacer direction={VERTICAL} amount={distance.six} />

                <Pagination
                  currentPage={currentPageNumber}
                  setCurrentPage={onSetCurrentPage}
                  itemsPerPage={itemsPerPage}
                  numberOfItems={numberOfDisplayableEvents}
                  showDirectionButtonsWithOnePage={false}
                  prevPageLabel={t("prevBtnLabel")}
                  nextPageLabel={t("nextBtnLabel")}
                  // rtl
                />
              </div>
              <div className={css(addEventBtnCradle)}>
                <IconButton
                  icon={<PlusIcon color={palette.white} size={30} />}
                  buttonGeometry={ROUND}
                  onClick={onNavigateToAddEvent}
                  customCradleStyles={{
                    boxShadow: shadows.two,
                    padding: distance.two,
                    backgroundColor: palette.primary,
                  }}
                />
              </div>
            </div>
          </Fade>
        ) 
      }
    </FillUnderNavBarCradle>
  );
}

export default Events;
