import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider"
import { IEventListProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IEventListProps) => {
  const { customEventsListCradleStyles } = props;
  const { palette, distance, typography } = themeInfo;

  return StyleSheet.create({
    eventListCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      backgroundColor: palette.white,
    },
    eventContentCradle: {
      height: "100%",
      width: "100%",
      padding: distance.three,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      backgroundColor: palette.white,
    },
    noEventsCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: palette.white,
    },
    noContentFallbackText: {
      ...typography.noContentFallbackText,
    },
    itemSeparatorCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    customEventsListCradleStyles: { ...customEventsListCradleStyles },
  });
}
