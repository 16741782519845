// -> Beyond codebase
import React, { useContext, MouseEvent, useEffect } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// import * as yup from "yup";
import { Formik, Form, FormikValues } from "formik";
// -> Within codebase
import Input from "../../../../Components/FormUtilities/Input/Input";
import Button from "../../../../Components/Button/Button";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import { ThemeInfo, UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, HORIZONTAL, VERTICAL } from "../../../../constants";
// -> Within component
import { styleGen } from "./SearchBarStyles";
import { ISearchBarProps } from "./helpers";

const initialValues = {
  searchQuery: "",
};

const SearchBar: React.FC<ISearchBarProps> = (props) => {
  const {
    placeholder, setSearchActivityIndicatorInStatus, searchVenuesAction,
    clearVenueSearch, dispatch
  } = props;
  const { t } = useTranslation("page_venues");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, fonts, palette } = themeInfo;
  const {
    componentCradle, customSearchBarCradleStyles, btnsColumn
  } = styleGen(themeInfo, props);

  let searchActivityIndicatorMicroanimationTimeout: any = -1;

  useEffect(() => {
    return () => clearTimeout(searchActivityIndicatorMicroanimationTimeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const computedPlaceholder = placeholder ? placeholder : t("searchPanel_defaultPlaceholderMsg");

  const onSubmitSearchQuery = (values: FormikValues) => {
    const { searchQuery } = values;

    if (searchQuery === "") return;

    dispatch(searchVenuesAction(searchQuery));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitSearchQuery}
    >
      {
        (formik) => (
          <Form className={css(componentCradle, customSearchBarCradleStyles)}>
            <Input
              name="searchQuery"
              placeholder={computedPlaceholder}
              mode="underline"
              customInputTextStyles={{ fontSize: "1.4rem" }}
              // customInputCradleStyles={{ borderBottom: "none" }}
              customControlCradleStyles={{
                width: "100%",
                height: "100%",
                flex: 1,
              }}
            />

            <Spacer direction={HORIZONTAL} amount={distance.two} />

            <div className={css(btnsColumn)}>
              <Button
                buttonText={t("searchPanel_searchBtnLabel")}
                type="submit"
                onClick={() => {}}
                customCradleStyles={{
                  paddingTop: distance.one,
                  paddingBottom: distance.one,
                }}
                customTextStyles={{
                  fontFamily: fonts.primary,
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                }}
              />

              <Spacer direction={VERTICAL} amount={distance.one} />

              <Button
                buttonText={t("searchPanel_clearBtnLabel")}
                onClick={(evt: MouseEvent<HTMLButtonElement>) => {
                  const { resetForm } = formik;
                  evt.stopPropagation();
                  resetForm();

                  dispatch(setSearchActivityIndicatorInStatus(false));
                  searchActivityIndicatorMicroanimationTimeout = setTimeout(() => {
                    dispatch(clearVenueSearch());
                  }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
                }}
                transparent
                customTextStyles={{
                  fontFamily: fonts.primary,
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  color: palette.grey4,
                }}
                customTextHoverStyles={{ textDecoration: "underline" }}
                customCradleStyles={{ padding: 0, width: "min-content", whiteSpace: "nowrap" }}
              />
            </div>
          </Form>
        )
      }
    </Formik>
  );
}

export default SearchBar;
