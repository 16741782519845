// -> Beyond codebase
import React, { useContext, useRef, useLayoutEffect, useState } from "react";
import { css } from "aphrodite-jss";
import debounce from "lodash.debounce";
// -> Within codebase
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import DiscoBallIcon from "../../VisualUtilities/IconPresets/DiscoBallIcon";
import { UIContext, ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { ROUNDED, VERTICAL } from "../../../constants";
// -> Within componoent
import { styleGen } from "./VenueCardStyles";
import { VenueCardProps } from "./helpers";

const VenueCard: React.FC<VenueCardProps> = (props) => {
  const [imgSectionHeight, setImgSectionHeight] = useState(0);
  const { name, imageURL, onClick } = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, palette } = themeInfo;
  const {
    cardCradle, imgSection, fallbackIconCradle, bottomRow,
    nameText, customCardCradleStyles
  } = styleGen(themeInfo, props, imgSectionHeight);

  const imgSectionRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setImgSectionHeight(imgSectionRef.current?.getBoundingClientRect().height!);

    const handleResize = debounce(() => {
      setImgSectionHeight(imgSectionRef.current?.getBoundingClientRect().height!);
    }, 10);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={css(cardCradle, customCardCradleStyles)} onClick={onClick}>
      <div className={css(imgSection)} ref={imgSectionRef}>
        {
          (imageURL) ? (
            <ImageWrapper
              alt={`Venue: ${name}`} src={imageURL}
              borderGeometry={ROUNDED}
              height={imgSectionHeight - 15} width={imgSectionHeight - 15}
            />
          ) : (
            <div className={css(fallbackIconCradle)}>
              <DiscoBallIcon color={palette.grey2} size={imgSectionHeight - 60} />
            </div>
          )
        }
      </div>
      <Spacer direction={VERTICAL} amount={distance.three} />
      <div className={css(bottomRow)}>
        <p className={css(nameText)}>{name}</p>
        {/* <Spacer direction={VERTICAL} amount={distance.two} />
        <Tag
          labelText={`${city}, ${country}`}
          tagGeometry={ROUNDED}
          activeColor={palette.primary_p1}
          activeTextColor={palette.white}
          customCradleStyles={{
            paddingTop: distance.one, paddingBottom: distance.one,
            paddingLeft: distance.one, paddingRight: distance.one,
          }}
          customTextStyles={{ fontSize: "1rem", fontWeight: "bold" }}
        /> */}
      </div>
    </div>
  );
}

export default VenueCard;
