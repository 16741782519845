// -> Beyond codebase
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Button from "../../../../Components/Button/Button";
import { GearIcon, MicrophoneIcon, PeopleIcon } from "../../../../Components/VisualUtilities/IconPresets";
import DiscoBallIcon from "../../../../Components/VisualUtilities/IconPresets/DiscoBallIcon";
import CalendarIcon from "src/Components/VisualUtilities/IconPresets/CalendarIcon";
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import {
  ARTISTS_ROUTE, EVENTS_ROUTE, ROUNDED, VENUES_ROUTE
} from "../../../../constants";
import { underConstructionAlert } from "src/helpers";

const UserProfileRoutingLinks: React.FC = () => {
  const history = useHistory();
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { t } = useTranslation(["page_adminDashboard"]);
  const { palette, distance, transitions, shadows, fonts, styles: { standardIconSize } } = themeInfo;

  const onNavigateToArtists = () => history.push(ARTISTS_ROUTE);
  const onNavigateToEvents = () => history.push(EVENTS_ROUTE);
  const onNavigateToVenues = () => history.push(VENUES_ROUTE);
  const onNavigateToUserManagement = () => underConstructionAlert();

  return (
    <>
      <Button
        buttonText={t("navbutton_events")}
        icon={<CalendarIcon size={standardIconSize + 3} color={palette.black} />}
        buttonGeometry={ROUNDED}
        iconTextSpacing={distance.one}
        color={palette.white}
        textColor={palette.black}
        customCradleStyles={{
          transition: `${transitions.boxShadowTransition}, ${transitions.bgColorTransition}`,
          backgroundColor: 'transparent',
          paddingLeft: 2.5, paddingRight: 2.5,
          paddingTop: 2.5, paddingBottom: 2.5,
          marginTop: distance.two,
          marginRight: distance.two,
        }}
        customCradleHoverStyles={{
          boxShadow: shadows.one,
          backgroundColor: palette.grey1,
        }}
        customTextStyles={{
          ...fonts.primary,
          color: palette.black,
          fontSize: "1.8rem",
        }}
        onClick={onNavigateToEvents}
      />

      <Button
        buttonText={t("navbutton_venues")}
        icon={<DiscoBallIcon size={standardIconSize + 3} color={palette.black} />}
        buttonGeometry={ROUNDED}
        iconTextSpacing={distance.one}
        color={palette.white}
        textColor={palette.black}
        customCradleStyles={{
          transition: `${transitions.boxShadowTransition}, ${transitions.bgColorTransition}`,
          backgroundColor: 'transparent',
          paddingLeft: 2.5, paddingRight: 2.5,
          paddingTop: 2.5, paddingBottom: 2.5,
          marginTop: distance.two,
          marginRight: distance.two,
        }}
        customCradleHoverStyles={{
          boxShadow: shadows.one,
          backgroundColor: palette.grey1,
        }}
        customTextStyles={{
          ...fonts.primary,
          color: palette.black,
          fontSize: "1.8rem",
        }}
        onClick={onNavigateToVenues}
      />

      <Button
        buttonText={t("navbutton_artists")}
        icon={<MicrophoneIcon size={standardIconSize + 3} color={palette.black} />}
        buttonGeometry={ROUNDED}
        iconTextSpacing={distance.one}
        color={palette.white}
        textColor={palette.black}
        customCradleStyles={{
          transition: `${transitions.boxShadowTransition}, ${transitions.bgColorTransition}`,
          backgroundColor: 'transparent',
          paddingLeft: 2.5, paddingRight: 2.5,
          paddingTop: 2.5, paddingBottom: 2.5,
          marginTop: distance.two,
          marginRight: distance.two,
        }}
        customCradleHoverStyles={{
          boxShadow: shadows.one,
          backgroundColor: palette.grey1,
        }}
        customTextStyles={{
          ...fonts.primary,
          color: palette.black,
          fontSize: "1.8rem",
        }}
        onClick={onNavigateToArtists}
      />

      <Button
        buttonText={t("navbutton_userManagement")}
        icon={<PeopleIcon size={standardIconSize + 3} color={palette.black} />}
        buttonGeometry={ROUNDED}
        iconTextSpacing={distance.one}
        color={palette.white}
        textColor={palette.black}
        customCradleStyles={{
          transition: `${transitions.boxShadowTransition}, ${transitions.bgColorTransition}`,
          backgroundColor: 'transparent',
          paddingLeft: 2.5, paddingRight: 2.5,
          paddingTop: 2.5, paddingBottom: 2.5,
          marginTop: distance.two,
          marginRight: distance.two,
        }}
        customCradleHoverStyles={{
          boxShadow: shadows.one,
          backgroundColor: palette.grey1,
        }}
        customTextStyles={{
          ...fonts.primary,
          color: palette.black,
          fontSize: "1.8rem",
        }}
        onClick={onNavigateToUserManagement}
      />
    </>
  );
}

export default UserProfileRoutingLinks;
