export const DEFAULT_ARTIST_LIST_ITEM_HEIGHT = 75;
export const DEFAULT_IMAGE_SIZE = 60;

export interface IArtistListItemProps {
  id: string;
  itemNumber?: number; // -> Could come up if numbering list items
  name: string;
  imageURL?: string;
  numEvents: number;
  imageSize?: number;
  onClick?: any;
  customListItemCradleStyles?: any;
  customArtistNameTextStyles?: any;
  customSongNumTextStyles?: any;
  customItemNumberTextStyles?: any;
  customArtistMetadataItemLabelTextStyles?: any;
  customArtistMetadataItemValueTextStyles?: any;
}
