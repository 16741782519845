export interface IArtistCardProps {
  id: string;
  name: string;
  imageURL?: string;
  imageSize?: number;
  onClick?(...params: any[]): any;
  customCardCradleStyles?: any;
  customNameTextStyles?: any;
}

export const IMAGE_SIZE_LG = 185;
export const IMAGE_SIZE_SM = 150;

export const BASE_CARD_HEIGHT = 290;
export const BASE_CARD_WIDTH = 225;
export const CARD_ASPECT_RATIO = (BASE_CARD_HEIGHT / BASE_CARD_WIDTH);
export const CARD_ASPECT_RATIO_INVERSE = (BASE_CARD_WIDTH / BASE_CARD_HEIGHT);
