import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { ISearchBarProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: ISearchBarProps) => {
  const { customSearchBarCradleStyles } = props;
  // const { palette, distance } = themeInfo;

  return StyleSheet.create({
    componentCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    btnsColumn: {
      height: "100%",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    customSearchBarCradleStyles: { ...customSearchBarCradleStyles },
  });
  
}