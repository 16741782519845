import { css } from "aphrodite-jss";
import { LEFT, ORTHOGONAL, RIGHT, ROUND, ROUNDED } from "../../constants";
// import { ThemeInfo } from "../UI_InfoProvider/UI_InfoProvider";

export interface IDrawerProps {
  height?: string | number;
  width?: string | number;
  horizontalGap?: number;
  position?: "left" | "right";
  drawerGeometry?: "round" | "rounded" | "orthogonal",
  backgroundColor?: string;
  drawerOpen?: boolean;
  toggleDrawer?(): void;
  closeDrawer(): void;
  backdropVisible: boolean;
  closeBackdrop(): void;
  raised?: boolean;
  customCradleStyles?: any;
}

const determineDrawerGeometryStyle = (props: IDrawerProps, styles: any) => {
  const { drawerGeometry } = props;
  const { drawerCradleRounded, drawerCradleRound } = styles;

  switch (drawerGeometry) {
    case ORTHOGONAL:
      return null;
    case ROUNDED:
      return drawerCradleRounded;
    case ROUND:
      return drawerCradleRound;
    default: return null; // -> Default to orthogonal corners, change if desired
  }
}

const determineHorizontalGapStyle = (props: IDrawerProps, styles: any) => {
  const { horizontalGap, position } = props;
  const { leftGapStyle, rightGapStyle } = styles;

  if (!horizontalGap) return null;
  if (position === LEFT) return leftGapStyle;
  else if (position === RIGHT) return rightGapStyle;
}

export const calibrateComponent = (props: IDrawerProps, styles: any) => {
  const { drawerOpen, raised } = props;
  const {
    drawerCradle, customCradleStyles, drawerOpenTranslation,
    drawerRaised,
  } = styles;
  
  const drawerGeometryStyle = determineDrawerGeometryStyle(props, styles);
  const horizontalGapStyle = determineHorizontalGapStyle(props, styles);
  const drawerOpenStyle = (drawerOpen) ? drawerOpenTranslation : null;
  const drawerRaisedStyle = (raised) ? drawerRaised : null;
  
  const drawerCradleStylechain = css(
    drawerCradle, drawerGeometryStyle, horizontalGapStyle,
    drawerOpenStyle, drawerRaisedStyle, customCradleStyles
  );

  return { drawerCradleStylechain };
}
