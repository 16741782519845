import { TEMP_AVATAR_URL } from "../../../../constants";
import { ArtistAction, EventAction, VenueAction } from "../../../../Types";

export const DEFAULT_IMAGE_SIZE = 185;

export interface IAdminDashboardDetailsProps {
  onOpenModal(actionID: string): any;
  actions: Array<ArtistAction | EventAction | VenueAction>;
}

export interface IAdminDashboardDetailsState {
  mainContentInStatus: boolean;
}

