// -------------- //
// - INTERFACES - //
// -------------- //

interface IPrimaryColor {
  primary_p4: string;
  primary_p3: string;
  primary_p2: string;
  primary_p1: string;
  primary: string;
  primary_m1: string;
  primary_m2: string;
};

interface ISecondaryColor {
  secondary_p4: string;
  secondary_p3: string;
  secondary_p2: string;
  secondary_p1: string;
  secondary: string;
  secondary_m1: string;
  secondary_m2: string;
};

interface ITertiaryColor {
  tertiary_p4: string;
  tertiary_p3: string;
  tertiary_p2: string;
  tertiary_p1: string;
  tertiary: string;
  tertiary_m1: string;
  tertiary_m2: string;
};

interface IQuaternaryColor {
  quaternary_p4: string;
  quaternary_p3: string;
  quaternary_p2: string;
  quaternary_p1: string;
  quaternary: string;
  quaternary_m1: string;
  quaternary_m2: string;
};

interface IQuintenaryColor {
  quintenary_p2: string;
  quintenary_p1: string;
  quintenary: string;
  quintenary_m1: string;
  quintenary_m2: string;
};

interface IGrayScale {
  white: string;
  grey1: string;
  grey2: string;
  grey3: string;
  grey4: string;
  grey5: string;
  grey6: string;
  grey7: string;
  grey8: string;
  black: string;
};

// interface IPalette {
//   grayScale: IGrayScale,
//   errorStatus?: string;
//   successStatus?: string;
//   primaryColor?: IPrimaryColor,
//   secondaryColor?: ISecondaryColor,
//   tertiaryColor?: ITertiaryColor,
//   quaternaryColor?: IQuaternaryColor,
//   quintenaryColor?: IQuintenaryColor,
// }

// ----------------- //
// - VARIABLE SETS - //
// ----------------- //

export const primaryColor: IPrimaryColor = {
  primary_p4: "#f6e7fd",
  primary_p3: "#e4b8f9",
  primary_p2: '#d289f6',
  primary_p1: '#ad2aee',
  primary: '#9611d8',
  primary_m1: '#730da5',
  primary_m2: '#520976'
};

export const secondaryColor: ISecondaryColor = {
  secondary_p4: '#e9fcf1',
  secondary_p3: '#91eeb8',
  secondary_p2: '#39e07f',
  secondary_p1: '#1fc665',
  secondary: '#17934b',
  secondary_m1: '#116e38',
  secondary_m2: '#0a4222'
};

export const tertiaryColor: ITertiaryColor = {
  tertiary_p4: '#eae9fc',
  tertiary_p3: '#9590ef',
  tertiary_p2: '#6a63e8',
  tertiary_p1: '#4037e2',
  tertiary: '#261dc6',
  tertiary_m1: '#1e179c',
  tertiary_m2: '#15106f'
};

export const quaternaryColor: IQuaternaryColor = {
  quaternary_p4: '#bdf4ef',
  quaternary_p3: '#65e6d9',
  quaternary_p2: '#39dfce',
  quaternary_p1: '#20c6b5',
  quaternary: '#189689',
  quaternary_m1: '#126e64',
  quaternary_m2: '#0b423c'
};

// export const quintenaryColor: IQuintenaryColor = {
//   quintenary_p2: '#FFFFFF',
//   quintenary_p1: '#FFFFFF',
//   quintenary: '#EFF6F9',
//   quintenary_m1: '#D6DDE0',
//   quintenary_m2: '#BDC4C7'
// };

export const grayScale: IGrayScale = {
  white: 'white',
  grey1: '#F2F2F2',
  grey2: '#C6C6C6',
  grey3: '#9E9E9E',
  grey4: '#878787',
  grey5: '#707070',
  grey6: '#474747',
  grey7: '#3D3D3D',
  grey8: '#232323',
  black: 'black'
};

export const otherColors: any = {
  TWITTER_BLUE: '#1DA1F2',
  FACEBOOK_BLUE: '#4267B2',
  SOUNDCLOUD_ORANGE: "#FF3C03",
  APPLE_MUSIC_RED: "#FA2E48",
  BANDCAMP_TURQUOISE: "#1AA1C2",
  SPOTIFY_GREEN: "#1CD760",
  BEATPORT_GREEN: "#A8E00F",
  MAP_MARKER_RED: "#EA4435"
}


export const palette = {
  ...grayScale,
  ...otherColors,
  errorStatus: '#B73434',
  warningStatus: "#e8af20",
  successStatus: '#319606',
  ...primaryColor,
  ...secondaryColor,
  ...tertiaryColor,
  ...quaternaryColor,
};