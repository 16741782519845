export const DEFAULT_CARD_WIDTH = 350;

export interface ILoginCardProps {
  onSubmit: any;
  onRegistrationClick: any;
  onResetPasswordClick: any;
  customCardCradleStyles?: any;
  isInCardMode?: boolean;
}

