import React, { useContext } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { UIContext, ThemeInfo } from '../UI_InfoProvider/UI_InfoProvider';
interface IBackdropProps {
  color?: string;
  opacity?: number;
  drawerOpen?: boolean;
  modalOpen?: boolean;
  closeDrawer?: any;
  closeModal?: any;
  closeBackdrop?: any;
}

const Backdrop: React.FC<IBackdropProps> = (props) => {
  const {
    closeModal, closeBackdrop, closeDrawer, drawerOpen,
    modalOpen,
  } = props;
  const uiInfo = useContext(UIContext);
  const { themeInfo }: { themeInfo: ThemeInfo } = uiInfo;
  const { backdropCradle } = styleGen(props, themeInfo);
  

  const dismissBackdrop = () => {
    if (drawerOpen) {
      if (closeDrawer) closeDrawer();
    }
    if (modalOpen) {
      if (closeModal) closeModal();
    }
    if (closeBackdrop) closeBackdrop();
  }

  return (
    <div onClick={dismissBackdrop} className={css(backdropCradle)}></div>
  );
}

const styleGen = (props: IBackdropProps, themeInfo: ThemeInfo) => {
  const { color, opacity } = props;
  const { palette } = themeInfo;

  return StyleSheet.create({
    backdropCradle: {
      position: 'fixed',
      top: 0, right: 0, bottom: 0, left: 0,
      zIndex: themeInfo.stackingIndexes.backdrop,
      backgroundColor: color ? color : palette.black,
      opacity: opacity ? opacity : themeInfo.styles.backdropOpacity,
    }
  });
}

export default Backdrop;
