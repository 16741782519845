// -> Beyond codebase
import React, { useContext, useState } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Fade from "../../../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import IconButton from "../../../../Components/Button/Variants/IconButton/IconButton";
import Dropdown from "../../../../Components/Dropdown/Dropdown";
import Indicator from "../../../../Components/VisualUtilities/Indicator/Indicator";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import FilterIcon from "../../../../Components/VisualUtilities/IconPresets/FilterIcon";
import SortIcon from "../../../../Components/VisualUtilities/IconPresets/SortIcon";
import ContextMenuHorizontalIcon from "../../../../Components/VisualUtilities/IconPresets/ContextMenuHorizontalIcon";
import { GridIcon, ListIcon, SearchIcon, XIcon } from "../../../../Components/VisualUtilities/IconPresets";
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { GRID, HORIZONTAL, LIST, ROUND, ROUNDED } from "../../../../constants";
import SortPanel from "../SortPanel/SortPanel";
import FilterPanel from "../FilterPanel/FilterPanel";
import SearchBar from "../SearchBar/SearchBar";
// -> Within component
import { styleGen } from "./TopBarStyles";
import { ITopBarProps } from "./helpers";

const TopBar: React.FC<ITopBarProps> = (props) => {
  const [additionalOptionsOpen, setAdditionalOptionsOpen] = useState(false);
  const {
    title, setDisplayMode, displayMode, dispatch, setSortCriteria, setSortDirection,
    applySortConfig, clearSortConfig,
    setFilterCriteria, setFilterCondition, setFilterArgument, applyFilterConfig,
    clearFilterConfig, setFilterConditionType, state, addMicroanimationTimeoutID,
    addFilterArgumentFadeInStatus, removeFilterArgumentFadeInStatus, setFilterArgumentFadeInStatuses,
    setSortActivityIndicatorInStatus, setFilterActivityIndicatorInStatus,
    setSearchActivityIndicatorInStatus, searchArtistsAction, clearArtistSearch,
  } = props;
  const {
    sortingActive, filteringActive, sortActivityIndicatorInStatus, filterActivityIndicatorInStatus,
    searchActivityIndicatorInStatus, searchActive,
  } = state;
  const { t } = useTranslation("page_events");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { palette, distance, styles, shadows } = themeInfo;
  const {
    topBarCradle, titleText, topBarButtonRow, customTopBarCradleStyles,
    additionalOptionsButtonRow, additionalOptionsButtonRowOpen, activityIndicatorCradle
  } = styleGen(props, themeInfo);

  const computedPageTitle = title ? title : t("pageTitle");
  const computedAdditionalOptionsButtonIcon = (additionalOptionsOpen) ? (
    <XIcon color={palette.black} size={styles.standardIconSize + 5} />
  ) : (
    <ContextMenuHorizontalIcon color={palette.black} size={styles.standardIconSize + 5} />
  );

  const additionalOptionsButtonRowActiveStyle = (additionalOptionsOpen) ? additionalOptionsButtonRowOpen : undefined;
  const additionalOptionsButtonRowStylechain = css(additionalOptionsButtonRow, additionalOptionsButtonRowActiveStyle);

  return (
    <div className={css(topBarCradle, customTopBarCradleStyles)}>
      <p className={css(titleText)}>{computedPageTitle}</p>
      <div className={css(topBarButtonRow)}>

        {/* GRID MODE */}
        <IconButton
          icon={<GridIcon color={palette.black} size={styles.standardIconSize + 4} />}
          onClick={() => {
            if (displayMode === GRID) return;
            dispatch(setDisplayMode(GRID));
          }}
        />

        <Spacer direction={HORIZONTAL} amount={distance.two} />

        {/* LIST MODE */}
        <IconButton
          icon={<ListIcon color={palette.black} size={styles.standardIconSize} />}
          onClick={() => {
            if (displayMode === LIST) return;
            dispatch(setDisplayMode(LIST));
          }}
        />

        <Spacer direction={HORIZONTAL} amount={distance.two} />

        {/* SEARCH BAR */}
        <div className={css(activityIndicatorCradle)}>
          <Dropdown
            onSelectItem={() => {}}
            closeDropCradleOnSelect
            geometry={ROUNDED}
            dropCradleGap={1}
            dropCradleAlignment="right"
            baseCradleBorder="none"
            customDropdownCradleStyles={{
              height: styles.standardIconSize,
              width: styles.standardIconSize,
            }}
            customBaseCradleStyles={{
              padding: 0,
              height: styles.standardIconSize,
              width: styles.standardIconSize,
              backgroundColor: "transparent",
            }}
            customDropCradleStyles={{
              width: 300,
              boxShadow: shadows.two,
              overflowY: "visible",
              padding: distance.one,
            }}
            CustomBaseCradleComponent={
              <IconButton
                icon={<SearchIcon color={palette.black} size={styles.standardIconSize + 3} />}
                onClick={() => {}}
              />
            }
            CustomDropdownComponent={
              <SearchBar
                placeholder={t("searchPanel_placeholderMsg")}
                state={state}
                dispatch={dispatch}
                setSearchActivityIndicatorInStatus={setSearchActivityIndicatorInStatus}
                searchArtistsAction={searchArtistsAction}
                clearArtistSearch={clearArtistSearch}
              />
            }
          />

          {
            (searchActive) ? (
              <Fade inStatus={searchActivityIndicatorInStatus}>
                <Indicator
                  geometry={ROUND}
                  color={palette.primary_p1}
                  active={true}
                  customIndicatorCradleStyles={{
                    height: 2,
                    width: 15,
                    top: 20,
                    left: 4,
                    position: "absolute",
                    alignSelf: "center", 
                    border: "none",
                  }}
                />
              </Fade>
            ) : null
          }
        </div>

        <Spacer direction={HORIZONTAL} amount={distance.one} />

        <div className={additionalOptionsButtonRowStylechain}>
          {
            (additionalOptionsOpen) ? (
              <Fade inStatus={additionalOptionsOpen}>
                <>
                  {/* SORT PANEL */}
                  <div className={css(activityIndicatorCradle)}>
                    <Dropdown
                      onSelectItem={() => {}}
                      closeDropCradleOnSelect
                      geometry={ROUNDED}
                      dropCradleGap={1}
                      dropCradleAlignment="right"
                      baseCradleBorder="none"
                      customDropdownCradleStyles={{
                        height: styles.standardIconSize,
                        width: styles.standardIconSize,
                      }}
                      customBaseCradleStyles={{
                        padding: 0,
                        height: styles.standardIconSize,
                        width: styles.standardIconSize,
                        backgroundColor: "transparent",
                      }}
                      customDropCradleStyles={{
                        boxShadow: shadows.two,
                        overflowY: "visible",
                        paddingTop: distance.two,
                        paddingBottom: distance.two,
                        paddingRight: distance.one,
                        paddingLeft: distance.one,
                      }}
                      CustomBaseCradleComponent={
                        <IconButton
                          icon={<SortIcon color={palette.black} size={styles.standardIconSize + 5} />}
                          onClick={() => {}}
                        />
                      }
                      CustomDropdownComponent={
                        <SortPanel
                          dispatch={dispatch}
                          state={state}
                          setSortCriteria={setSortCriteria}
                          setSortDirection={setSortDirection}
                          applySortConfig={applySortConfig}
                          clearSortConfig={clearSortConfig}
                          setSortActivityIndicatorInStatus={setSortActivityIndicatorInStatus}
                        />
                      }
                    />

                    {
                      (sortingActive) ? (
                        <Fade inStatus={sortActivityIndicatorInStatus}>
                          <Indicator
                            geometry={ROUND}
                            color={palette.primary_p1}
                            active={true}
                            customIndicatorCradleStyles={{
                              height: 2,
                              width: 15,
                              top: 20,
                              left: 4,
                              position: "absolute",
                              alignSelf: "center", 
                              border: "none",
                            }}
                          />
                        </Fade>
                      ) : null
                    }
                  </div>

                  <Spacer direction={HORIZONTAL} amount={distance.two} />

                  {/* FILTER PANEL */}
                  <div className={css(activityIndicatorCradle)}>
                    <Dropdown
                      onSelectItem={() => {}}
                      closeDropCradleOnSelect
                      geometry={ROUNDED}
                      dropCradleGap={1}
                      dropCradleAlignment="right"
                      baseCradleBorder="none"
                      customDropdownCradleStyles={{
                        height: styles.standardIconSize,
                        width: styles.standardIconSize,
                      }}
                      customBaseCradleStyles={{
                        padding: 0,
                        height: styles.standardIconSize,
                        width: styles.standardIconSize,
                        backgroundColor: "transparent",
                      }}
                      customDropCradleStyles={{
                        boxShadow: shadows.two,
                        overflowY: "visible"
                      }}
                      CustomBaseCradleComponent={
                        <IconButton
                          icon={<FilterIcon color={palette.black} size={styles.standardIconSize + 5} />}
                          onClick={() => {}}
                        />
                      }
                      CustomDropdownComponent={
                        <FilterPanel
                          dispatch={dispatch}
                          state={state}
                          addMicroanimationTimeoutID={addMicroanimationTimeoutID}
                          addFilterArgumentFadeInStatus={addFilterArgumentFadeInStatus}
                          removeFilterArgumentFadeInStatus={removeFilterArgumentFadeInStatus}
                          setFilterArgumentFadeInStatuses={setFilterArgumentFadeInStatuses}
                          setFilterActivityIndicatorInStatus={setFilterActivityIndicatorInStatus}
                          setFilterCriteria={setFilterCriteria}
                          setFilterCondition={setFilterCondition}
                          setFilterConditionType={setFilterConditionType}
                          setFilterArgument={setFilterArgument}
                          applyFilterConfig={applyFilterConfig}
                          clearFilterConfig={clearFilterConfig}
                        />
                      }
                    />

                    {
                      (filteringActive) ? (
                        <Fade inStatus={filterActivityIndicatorInStatus}>
                          <Indicator
                            geometry={ROUND}
                            color={palette.primary_p1}
                            active={true}
                            customIndicatorCradleStyles={{
                              height: 2,
                              width: 15,
                              top: 20,
                              left: 5,
                              position: "absolute",
                              alignSelf: "center", 
                              border: "none",
                            }}
                          />
                        </Fade>
                      ) : null
                    }
                  </div>

                  <Spacer direction={HORIZONTAL} amount={distance.two} />
                </>
              </Fade>
            ) : undefined
          }

          <IconButton
            icon={computedAdditionalOptionsButtonIcon}
            onClick={() => setAdditionalOptionsOpen(!additionalOptionsOpen)}
          />
        </div>
      </div>
    </div>
  );
}

export default TopBar;
