import { EventAction, VenueAction, ArtistAction } from "../Types";

interface IRequiredActionProperties {
  action: EventAction | VenueAction | ArtistAction;
}

export const computeActionDescriptionText = (data: IRequiredActionProperties, t: any): string => {
  const { action: {
    actionTargetType, previousInfoState, newInfoState, actionType,
  }} = data;

  let computedActionDescriptionText = "";

  if (actionTargetType === "ARTIST") {
    if (actionType === "CREATE" || actionType === "EDIT") {
      if (newInfoState) {
        const { name } = newInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextArtist")}: ${name}`;
        computedActionDescriptionText = `${name}`;
        // computedActionDescriptionText = `${t("actionDescriptionTextArtist")}`;
      }
    }
    else if (actionType === "DELETE") {
      if (previousInfoState) {
        const { name } = previousInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextArtist")}: ${name}`;
        computedActionDescriptionText = `${name}`;
        // computedActionDescriptionText = `${t("actionDescriptionTextArtist")}`;
      }
    }
  }
  else if (actionTargetType === "VENUE") {
    if (actionType === "CREATE" || actionType === "EDIT") {
      if (newInfoState) {
        const { name } = newInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextVenue")}: ${name}`;
        computedActionDescriptionText = `${name}`;
        // computedActionDescriptionText = `${t("actionDescriptionTextVenue")}`;
      }
    }
    else if (actionType === "DELETE") {
      if (previousInfoState) {
        const { name } = previousInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextVenue")}: ${name}`;
        computedActionDescriptionText = `${name}`;
        // computedActionDescriptionText = `${t("actionDescriptionTextVenue")}`;
      }
    }
  }
  else if (actionTargetType === "EVENT") {
    if (actionType === "CREATE" || actionType === "EDIT") {
      if (newInfoState) {
        const { name } = newInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextEvent")}: ${name}`;
        computedActionDescriptionText = `${name}`;
        // computedActionDescriptionText = `${t("actionDescriptionTextEvent")}`;
      }
    }
    else if (actionType === "DELETE") {
      if (previousInfoState) {
        const { name } = previousInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextEvent")}: ${name}`;
        computedActionDescriptionText = `${name}`;
        // computedActionDescriptionText = `${t("actionDescriptionTextEvent")}`;
      }
    }
  }

  return computedActionDescriptionText;
};

export const computeActionDescriptionLabel = (data: IRequiredActionProperties, t: any): string => {
  const { action: {
    actionTargetType, previousInfoState, newInfoState, actionType,
  }} = data;

  let computedActionDescriptionText = "";
  // console.log(`action targetType -> ${actionTargetType}`);
  // console.log(`newInfoState -> ${newInfoState}`);

  if (actionTargetType === "ARTIST") {
    if (actionType === "CREATE" || actionType === "EDIT") {
      if (newInfoState) {
        const { name } = newInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextArtist")}: ${name}`;
        // computedActionDescriptionText = `${name}`;
        computedActionDescriptionText = `${t("actionDescriptionTextArtist")}`;
      }
    }
    else if (actionType === "DELETE") {
      if (previousInfoState) {
        const { name } = previousInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextArtist")}: ${name}`;
        // computedActionDescriptionText = `${name}`;
        computedActionDescriptionText = `${t("actionDescriptionTextArtist")}`;
      }
    }
  }
  else if (actionTargetType === "VENUE") {
    if (actionType === "CREATE" || actionType === "EDIT") {
      if (newInfoState) {
        const { name } = newInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextVenue")}: ${name}`;
        // computedActionDescriptionText = `${name}`;
        computedActionDescriptionText = `${t("actionDescriptionTextVenue")}`;
      }
    }
    else if (actionType === "DELETE") {
      if (previousInfoState) {
        const { name } = previousInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextVenue")}: ${name}`;
        // computedActionDescriptionText = `${name}`;
        computedActionDescriptionText = `${t("actionDescriptionTextVenue")}`;
      }
    }
  }
  else if (actionTargetType === "EVENT") {
    if (actionType === "CREATE" || actionType === "EDIT") {
      if (newInfoState) {
        const { name } = newInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextEvent")}: ${name}`;
        // computedActionDescriptionText = `${name}`;
        computedActionDescriptionText = `${t("actionDescriptionTextEvent")}`;
      }
    }
    else if (actionType === "DELETE") {
      if (previousInfoState) {
        const { name } = previousInfoState;
        // computedActionDescriptionText = `${t("actionDescriptionTextEvent")}: ${name}`;
        // computedActionDescriptionText = `${name}`;
        computedActionDescriptionText = `${t("actionDescriptionTextEvent")}`;
      }
    }
  }

  // console.log(`computeActionDescriptionLabel(): ${computedActionDescriptionText}`);
  return computedActionDescriptionText;
};

export const computeSemanticActionType = (actionType: string): string => {
  let actionTypeSemantic = "CREATION";

  if (actionType === "CREATE") actionTypeSemantic = "CREATION";
  else if (actionType === "EDIT") actionTypeSemantic = "UPDATE";
  else if (actionType === "DELETE") actionTypeSemantic = "DELETION";

  return actionTypeSemantic;
}