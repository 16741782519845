import { Event, Venue } from "../../../../Types";

export const DEFAULT_IMAGE_SIZE = 185;
export const STATIC_MAP_ASPECT_RATIO = (2 / 3);
export const STATIC_MAP_SCALE_COEFFICIENT = 1;

export interface VenueDetailsProps {
  venue: Venue;
  onEventClick(eventID: string): void;
  actionHistoryCardMode?: boolean;
}

export interface VenueDetailsState {
  hasMultipleExternalLinks: boolean;
  mainContentInStatus: boolean;
  staticMapWidth: number;
  events?: Event[];
}
