import { Artist } from "../Types";

export const artists: Artist[] = [
  {
    id: "37d2529d-14b9-4a90-9caf-c024052ffe28",
    name: "Planet V",
    description: "",
    imageURL: "https://i1.sndcdn.com/artworks-uRWWIOficP5T6OTI-711YqA-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://planet-v.bandcamp.com/",
      soundCloud: "https://soundcloud.com/planet_v",
      spotify: "https://open.spotify.com/artist/50KY1fYJqwGBSfCRXMtZjg?si=b1YUyGRNSkClyEa_pqlMYw&dl_branch=1",
      appleMusic: "https://music.apple.com/us/artist/planet-v/984978834",
      deezer: "https://www.deezer.com/en/artist/525560",
      instagram: "https://www.instagram.com/plvnetv/",
      facebook: "https://www.facebook.com/PlvnetV/",
      twitter: "https://twitter.com/Planet_V_",
    },
  },
  {
    id: "e1c0dc8e-ef6f-4be8-b544-f59c78595481",
    name: "Øfdream",
    description: "",
    imageURL: "https://i1.sndcdn.com/artworks-000551685123-m8l4w8-t500x500.jpg",
    events: [],
    links: {
      spotify: "https://open.spotify.com/artist/70P3LvwslDBQicUfL2Ix02?si=1zGitwTbTViz8SFAF5Hp3g&dl_branch=1",
      appleMusic: "https://music.apple.com/us/artist/ofdream/985611180",
      deezer: "https://deezer.page.link/ttRBKPz2m5k5bmef8",
      beatPort: "https://www.beatport.com/artist/ofdream/478370",
    }
  },
  {
    id: "ef839db3-ae41-4af9-9078-a8d211089962",
    name: "Sorsari",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-vz4CsOD2xkyeiAcK-WwPm1w-t500x500.jpg",
    events: [
      "05a9a61e-4f9b-429b-9516-43e42da2b99e", "e0ba48eb-0cfa-4861-801f-9f06bef02e91",
      "6b48f005-50cd-458a-b107-f55b74d69b8e", "ecda5215-8017-4b49-acfd-03f0f20d25d6",
      "61719063-19ba-49d5-9dfa-65befd58dac1", "1ce46e93-e324-481c-94ed-05263d59f156",
      "1f212b05-031c-4c7e-8d68-cad645880f2f", "921aa6a1-4ea0-4ecb-8db2-7ab1cbaa1721",
    ],
    links: {
      spotify: "https://open.spotify.com/artist/2t01L1I0juJWbThU5jP06Y",
      soundCloud: "https://soundcloud.com/sorsari",
      bandCamp: "https://sorsarimusic.bandcamp.com/",
      instagram: "https://www.instagram.com/sorsari/",
      facebook: "https://www.facebook.com/sorsari/",
      twitter: "https://twitter.com/sorsariii"
    }
  },
  {
    id: "fe8f6f47-18fb-451b-a5d7-9914aa029bca",
    name: "NVRLUV",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-SnsMeK43ldUsVABz-QITENg-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://neverlove.bandcamp.com/",
      soundCloud: "https://soundcloud.com/nvrluv",
      spotify: "https://open.spotify.com/artist/6O8B8YvVVfCAehzlfCVRuc?si=b85edbd1624a4b58",
      appleMusic: "https://music.apple.com/us/artist/nvrluv/1457477581",
      deezer: "https://deezer.page.link/dQqypjfM6eBm9om88",
      beatPort: "https://www.instagram.com/nvrluv/",
      instagram: "https://www.instagram.com/nvrluv/",
      facebook: "https://www.facebook.com/nvrluvtv/",
      twitter: "https://twitter.com/nvr_love",
    }
  },
  {
    id: "10543a27-c99f-45b5-8372-26bc9c04ff31",
    name: "Rednog",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000362077229-3w1cfx-t500x500.jpg",
    events: [],
    links: {
      soundCloud: "https://soundcloud.com/iamrednog",
      spotify: "https://open.spotify.com/artist/5Z3kMLKLU8eguktMtsbxhe?si=f071e24952a44cd2",
      appleMusic: "https://music.apple.com/us/artist/rednog/1391139066",
      deezer: "https://deezer.page.link/sUnazFQkb8BDNpcH9",
      beatPort: "https://www.beatport.com/artist/rednog/693206",
      youTube: "https://www.youtube.com/channel/UC5t6qWLlbPcCGqFyWEL1clg",
      instagram: "https://www.instagram.com/iamrednog/",
      facebook: "https://www.facebook.com/iamrednog/",
    }
  },
  {
    id: "4028e24d-6e41-4cac-822f-37057a001d77",
    name: "RIINGS", // -> Has also gone by FRCLN  
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000501778077-xmkrr4-t500x500.jpg",
    events: [],
    links: {
      soundCloud: "https://soundcloud.com/riingss",
      spotify: "https://open.spotify.com/artist/4fVfMUugOLzKU1JusQjtco",
      appleMusic: "https://music.apple.com/us/artist/riings/1455692301",
      deezer: "https://deezer.page.link/MHGJxRgb5Zn8Cnvs7",
      youTube: "https://www.youtube.com/user/fourcolonmusic",
      instagram: "https://www.instagram.com/_riings_/",
      facebook: "https://www.facebook.com/frcln",
      twitter: "https://twitter.com/riingss",
    }
  },
  {
    id: "86ef7707-fef6-4a8a-a311-8d1f1653f87d",
    name: "Visene",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-jVE26N6on7h1jkt1-hJG7vw-t500x500.jpg",
    events: [
      "ea289abd-45ce-47d4-a057-1a15ed39d67e",
    ],
    links: {
      bandCamp: "https://viseneuk.bandcamp.com/",
      soundCloud: "https://soundcloud.com/visene",
      spotify: "https://open.spotify.com/artist/4diUT4tTZ4yDpyOCArGpNk",
      appleMusic: "https://music.apple.com/us/artist/visene/1362977852",
      deezer: "https://deezer.page.link/BV6YcYphN1NQBDQ68",
      beatPort: "https://www.beatport.com/artist/visene/678903",
      vk: "https://www.instagram.com/viseneuk/",
      instagram: "https://www.instagram.com/viseneuk/",
      facebook: "https://www.facebook.com/visene/",
      twitter: "https://twitter.com/viseneuk",
    }
  },
  {
    id: "1dfe9974-ebfa-4772-9ab8-a1205148874e",
    name: "Beau",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-msPyEXiqFqDjGlSJ-XTxS0g-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://beau4.bandcamp.com/",
      soundCloud: "https://soundcloud.com/beauuk",
      spotify: "https://open.spotify.com/artist/1jp2rVquTzaYyuofy1jVBQ",
      youTube: "https://www.youtube.com/channel/UC5g5UnRhk8IW-Mrtnf77RhA/featured",
      website: "",
      instagram: "https://www.instagram.com/beauuk7/",
      facebook: "https://www.facebook.com/beauuk7/",
      twitter: "https://twitter.com/Beauuk7",
    }
  },
  {
    id: "4d5d304b-4e10-4c04-a30a-462a0761a1fc",
    name: "Obesøn",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000284595187-59b13h-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://obeson.bandcamp.com/",
      soundCloud: "https://soundcloud.com/obeson",
      spotify: "https://open.spotify.com/artist/0eu5ptO2xQT6A8p6FBWJ6C",
      appleMusic: "https://music.apple.com/us/artist/obes%C3%B8n/905927942",
      deezer: "https://www.deezer.com/en/artist/6281168",
      youTube: "https://www.youtube.com/user/OBESONmusic",
      instagram: "https://www.instagram.com/obesonmusic/",
      facebook: "https://www.facebook.com/OBESON",
      twitter: "https://twitter.com/OBESONmusic",
    }
  },
  {
    id: "d0e601e3-1869-4634-a6c8-4436df00baaa",
    name: "arael.",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-u0YkKs03z5S8AWjB-IJkdoA-t500x500.jpg",
    events: [
      
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/7KjOBaQSexjUgj0Z0YrKzO',
      soundCloud: 'https://soundcloud.com/araelsounds',
      bandCamp: 'https://araelsounds.bandcamp.com/',
      instagram: 'https://www.instagram.com/araelsounds/',
      twitter: 'https://twitter.com/araelsounds'
    }
  },

  // - 10 - //

  {
    id: "580d4a2b-4583-4ab1-a55a-98be5f193814",
    name: "Rayless",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-wuAmRSABDtcnpxu9-agv1Aw-t500x500.jpg",
    events: [],
    links: {
      soundCloud: "https://soundcloud.com/iamrayless",
      spotify: "https://open.spotify.com/artist/2AkKY28A6T9Hy2828X3wgd?si=bj61egUaRoKbhIz6An_jmg&nd=1",
      appleMusic: "https://music.apple.com/it/artist/rayless/1203822984",
      deezer: "https://www.deezer.com/en/artist/11912249",
      beatPort: "https://www.beatport.com/artist/rayless/601947",
      youTube: "https://www.youtube.com/channel/UCr3s61593U5t3NHoc34L6Ig/featured?view_as=subscriber",
      instagram: "https://www.instagram.com/rayless.wav/",
      facebook: "https://www.facebook.com/iamrayless",
      twitter: "https://twitter.com/iamrayless",
    }
  },
  {
    id: "3c783977-2e40-42ba-97eb-7c912a60fbbf",
    name: "Flechewave",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000539923206-ch7ghe-t500x500.jpg",
    events: ["065a950b-83b0-427b-98b8-304860fbd61b", "a8d81758-0ba6-4f56-97c1-5655731ff3e0"],
    links: {
      bandCamp: "https://flechewave.bandcamp.com/",
      soundCloud: "https://soundcloud.com/flecheone",
      spotify: "https://open.spotify.com/artist/2NBId5ihWIJRuKFEm0xpZF?si=AtZmOOaxRBi9y0KdpqmdlA&dl_branch=1",
      appleMusic: "https://music.apple.com/us/artist/flechewave/1356344527",
      deezer: "https://www.deezer.com/en/artist/14299757",
      beatPort: "https://www.beatport.com/artist/flechewave/674749",
      vk: "https://vk.com/flecheone",
      twitter: "https://twitter.com/flechewave",
    }
  },
  {
    id: "7edfd409-f07a-45a9-8e23-149c08bcf4e5",
    name: "Monomi",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000548812371-lcmvhe-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://monomi.bandcamp.com/",
      soundCloud: "https://soundcloud.com/monomimusic",
      spotify: "https://open.spotify.com/artist/3SiNQO7firVxSkV4NRbmDU",
      appleMusic: "https://music.apple.com/us/artist/monomi/1342030388",
      deezer: "https://www.deezer.com/en/artist/14028533",
      beatPort: "https://www.beatport.com/artist/monomi/710953",
      instagram: "https://www.instagram.com/monomimusic/",
      twitter: "https://twitter.com/monomimusic",
    }
  },
  {
    id: "483a9c78-cbfa-4b7c-8494-48e2e8631846",
    name: "gl00my",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-6GWkc0mYPasCFgyI-eAwg7g-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://gloomy.bandcamp.com/",
      soundCloud: "https://soundcloud.com/gloomysounds",
      spotify: "https://open.spotify.com/artist/4cRE52UVmPx2fqz0MGc9nX",
      appleMusic: "https://music.apple.com/us/artist/gl00my/1464061804",
      deezer: "https://www.deezer.com/en/artist/65744542",
      beatPort: "https://www.beatport.com/artist/gl00my/772136",
      instagram: "https://www.instagram.com/gl00mysounds/",
      twitter: "https://twitter.com/gloomysounds",
    },
  },
  {
    id: "59ad7bf8-4667-45b6-96d4-87f70573b936",
    name: "Pholo",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-qGBwy1Q73hfBzOpX-txJWeQ-t500x500.jpg",
    events: [
      "ea289abd-45ce-47d4-a057-1a15ed39d67e",
    ],
    links: {
      bandCamp: "https://pholouk.bandcamp.com/",
      soundCloud: "https://soundcloud.com/pholouk",
      spotify: "https://open.spotify.com/artist/53Wk6hhoFwz3REXaZ2XW2a",
      appleMusic: "https://music.apple.com/us/artist/pholo/1126920890",
      deezer: "https://www.deezer.com/en/artist/10595059?source=omni",
      beatPort: "https://www.beatport.com/artist/pholo/631782",
      instagram: "https://www.instagram.com/pholouk/",
      facebook: "https://www.facebook.com/pholouk",
      twitter: "https://twitter.com/pholouk",
    }
  },
  {
    id: "90d7b4dc-84bc-46a9-a84f-ea3b0c7b6b46",
    name: "HYPEER",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000729101257-0mepry-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://hypeer.bandcamp.com/",
      soundCloud: "https://soundcloud.com/hypeerbeats",
      spotify: "https://open.spotify.com/artist/7KuU6ifoHwiOyu50Gc9RQt?si=oDzIUjtvQAuGgaxNAIhZsw&nd=1",
      appleMusic: "https://music.apple.com/us/artist/hypeer/1310816118",
      deezer: "https://www.deezer.com/en/artist/14249187?source=omni",
      beatPort: "https://www.beatport.com/artist/hypeer/834840",
      youTube: "https://www.youtube.com/channel/UCTrUAsf9_2iALB0rmewFAIA",
      instagram: "https://www.instagram.com/prodbyhypeer/",
      facebook: "https://www.facebook.com/prodhypeer/",
    }
  },
  {
    id: "ef9bc1e8-7e34-4fdd-a54e-b4de1477b831",
    name: "KILLSIREN",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-MPkmNdTgYZonGzAK-q69YEg-t500x500.jpg",
    events: [],
    links: {
      soundCloud: "https://soundcloud.com/killsiren",
      youTube: "https://www.youtube.com/user/vUbiez",
      instagram: "https://www.instagram.com/killsirenn/",
      twitter: "https://twitter.com/etherealthegoat",
    }
  },
  {
    id: "9c535188-b27d-4a20-8ea4-0586e1a7c1e9",
    name: "kim",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-yRWb3I9TaFn1zpZv-gnpxNg-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://kimbeats.bandcamp.com/",
      soundCloud: "https://soundcloud.com/beatskim",
      spotify: "https://open.spotify.com/artist/4q5RE9bDpugEWslh6TfiI0",
      appleMusic: "https://music.apple.com/by/artist/kim/1463399051",
      vk: "https://vk.com/beatskim",
      youTube: "https://www.youtube.com/channel/UC5dHKtvfspdn4d11FBes4dA",
      instagram: "https://www.instagram.com/kim.wav/",
      twitter: "https://twitter.com/musicxkim",
    }
  },
  {
    id: "5a29ca1f-c903-4b43-b6bf-0eba13d43ddc",
    name: "almogfx",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-pmIfsreWYidrW4U0-oPU7OA-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://almogfx.bandcamp.com/",
      soundCloud: "https://soundcloud.com/almogfx",
      spotify: "https://open.spotify.com/artist/099h113YJ299YZfyIEDncL",
      appleMusic: "https://music.apple.com/us/artist/almogfx/1439674027",
      deezer: "https://www.deezer.com/en/artist/57680372",
      beatPort: "https://www.beatport.com/artist/almogfx/856495",
      vk: "https://vk.com/almogfx",
      instagram: "https://www.instagram.com/almogfx/",
      facebook: "https://www.facebook.com/almogfx-111107187330436",
      twitter: "https://twitter.com/almogfx",
    }
  },

  // - 20 - //

  {
    id: "cb464011-0833-44aa-b567-d07094a6c191",
    name: "KXIRU",
    description: "",
    imageURL: "https://i1.sndcdn.com/artworks-000322846044-efdzz0-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://prodkxiru.bandcamp.com/",
      soundCloud: "https://soundcloud.com/prodkxiru",
      spotify: "https://open.spotify.com/artist/389MZV7tZpvM4fMZSdT5JV",
    }
  },
  {
    id: "774c9e44-6ade-4698-9466-5219c669035f",
    name: "barnacle boi",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-at7YftEBiWzuAyeQ-BRgdWg-t500x500.jpg",
    events: [
      "cdc008ea-5a7c-4cef-89c8-40fa7733446a", "a61ef4a3-a15f-4063-8bce-4f34715edde6",
      "68404883-e21d-4dd8-a464-ac20a417ec38", "d586554d-488b-4f47-99ee-1f52ad8e049b",
      "1a254908-e065-4b86-9c1c-4554f337999d", "4a711fd3-c0e4-485e-af9d-d8e62b372c88",
      "9c9d6571-bca3-4d10-97a6-83c502425843", "ae44a5ea-8ead-4430-8b29-7edb57e2e3d8",
      "c5d39570-0bae-4206-9cb8-f9d62d1ddea6", "6145554e-e373-4aaf-baaf-dc3ca53b2d8f",
      "a532a76c-b5c7-4259-b260-dd4f589536f9", "17380bf5-8520-4a77-ae38-28d893984d47",
      "b856115e-97f4-42bf-bf59-3a0ccf84cca2", "31eb47a0-a317-443f-9032-23fae54e772c",
      "e6568ce8-b96e-45ba-9c86-169e261175ff",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/6psDObCnL4BfpwqYzE0Dzu',
      soundCloud: 'https://soundcloud.com/barnacleboimusic',
      appleMusic: 'https://itunes.apple.com/us/artist/barnacle-boi/268706623',
      bandCamp: 'https://barnacleboi.bandcamp.com/',
      youTube: 'https://www.youtube.com/c/barnacleboi',
      instagram: 'https://www.instagram.com/barnacleboiofficial/',
      facebook: 'https://www.facebook.com/barnacleboimusic',
      twitter: 'https://twitter.com/barnacleboi777',
      vk: 'https://vk.com/id438791682',
      website: 'https://barnacleboi.com/'
    },
  },
  {
    id: "7f053e5d-ef16-4b89-b79e-7cedfb2a7273",
    name: "throwmance",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-Onq0UZtMY8idagKE-74lkLw-t500x500.jpg",
    events: [],
    links: {
      soundCloud: "https://soundcloud.com/throwmance",
      spotify: "https://open.spotify.com/artist/0w7Cf2ZsABpjmf1UjXpVpF",
      appleMusic: "https://music.apple.com/us/artist/throwmance/1456644544",
      deezer: "https://www.deezer.com/en/artist/61776902?source=omni",
      instagram: "https://www.instagram.com/throwmance/",
      facebook: "https://www.facebook.com/throwmance/",
      twitter: "https://twitter.com/throwmance",
    }
  },
  {
    id: "f2e4ba6b-9fbc-4dcb-bad6-341e21f1e32c",
    name: "Jake Robertz",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000082519768-acxops-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://jakerobertz.bandcamp.com/",
      soundCloud: "https://soundcloud.com/jake-robertz",
      spotify: "https://open.spotify.com/artist/3ZXVfURG8LY8OX4lY4SYhG",
      appleMusic: "https://music.apple.com/us/artist/jake-robertz/567585502",
      deezer: "https://www.deezer.com/en/artist/4036465",
      beatPort: "https://www.beatport.com/artist/jake-robertz/290184",
      youTube: "https://www.youtube.com/channel/UCfdEPKiWh_BCoICG68ApeNg",
      instagram: "https://www.instagram.com/jake.robertz/",
      facebook: "https://www.facebook.com/JakeRobertzMusic",
      twitter: "https://twitter.com/Jake_Robertz",
    }
  },
  {
    id: "5c63804d-531c-43d7-866a-58de5b67f5e4",
    name: "Mannequin",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000722684425-zczea6-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://thatmannequin.bandcamp.com/",
      soundCloud: "https://soundcloud.com/that_mannequin",
      spotify: "https://open.spotify.com/artist/3btewOJD9jMKKrn5IV7nFj?si=7tN3v_zvS2iC8u3b3XJcVw&nd=1",
      appleMusic: "https://music.apple.com/us/artist/mannequin/1482541294",
      beatPort: "https://www.beatport.com/artist/mannequin/5018",
      instagram: "https://www.instagram.com/mvnnequin/",
      twitter: "https://twitter.com/thatmannequin_",
    }
  },

  // - 30 - //

  {
    id: "b091c39f-9dfa-481e-a30b-ce707c27c801",
    name: "Henryisdead",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-AkgzszJoFyzrJ4FL-igSVAg-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://henryisdead.bandcamp.com/",
      soundCloud: "https://soundcloud.com/killedhenry",
      spotify: "https://open.spotify.com/artist/0wrC9JBMa3nGMv24vunrbl",
      appleMusic: "https://music.apple.com/us/artist/henryisdead/1177342555",
      deezer: "https://www.deezer.com/us/artist/11387978?source=omni",
      beatPort: "https://www.beatport.com/artist/henryisdead/686602",
      youTube: "https://www.youtube.com/channel/UCoRspw4YbniuYEB42N7-70w",
      facebook: "https://www.facebook.com/henrysamamusic/",
      twitter: "https://twitter.com/henryisdead",
    }
  },
  {
    id: "cf86ac9a-861e-4ca5-930e-8b2ca003ea38",
    name: "Five Suns",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-jSq6fnzktvsqMxH3-kNVYyQ-t500x500.jpg",
    events: [
      "55b8544b-060b-412e-9319-ecc29ba9764b",
    ],
    links: {
      bandCamp: "https://fivesunsonvenus.bandcamp.com/",
      soundCloud: "https://soundcloud.com/fivesunsonvenus",
      spotify: "https://open.spotify.com/artist/58bIS29PlIe7RhCSoHJnnq",
      appleMusic: "https://music.apple.com/us/artist/five-suns/1190985667",
      deezer: "https://www.deezer.com/us/artist/11629363?source=omni",
      beatPort: "https://www.beatport.com/artist/five-suns/631887",
      youTube: "https://www.youtube.com/channel/UCUDtwuSPaL35h2ZgAcM97Rg",
      website: "https://fivesunsonvenus.com/",
      instagram: "https://www.instagram.com/fivesunsonvenus/",
      facebook: "https://www.facebook.com/fivesunsonvenus",
      twitter: "https://twitter.com/fivesunsonvenus",
    }
  },
  {
    id: "ca5623c9-4268-47f0-8569-8a562e4e9bbe",
    name: "Kareful",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-KjLfXazv0DhZvsOV-PLxDtw-t500x500.jpg",
    events: [
      "8f6f7215-05d1-453a-959c-11f9bfd172d7", "9652c673-a514-435a-bee6-3d2ce7107145",
      "55b8544b-060b-412e-9319-ecc29ba9764b", "346dac14-aced-4f80-abf7-a7775fa3f60c",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/7y4breKexfBWgdyMgHMEHK',
      soundCloud: 'https://soundcloud.com/karefuluk',
      appleMusic: 'https://music.apple.com/us/artist/kareful/673916892',
      bandCamp: 'https://kareful.bandcamp.com/releases',
      instagram: 'https://www.instagram.com/neverkareful/',
      facebook: 'https://www.facebook.com/karefulofficial/?notif_t=page_fan',
      twitter: 'https://twitter.com/KarefulUK'
    }
  },
  {
    id: "28d8c46e-8702-459f-a849-319e2d940f39",
    name: "astral planes",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000489087690-jvzfjp-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://astralxplanes.bandcamp.com/",
      soundCloud: "https://soundcloud.com/astralxplanes",
      spotify: "https://open.spotify.com/artist/2XyiQolWOpczm5A4osFLkE",
      appleMusic: "https://music.apple.com/us/artist/astral-planes/319858625",
      beatPort: "https://www.beatport.com/artist/astral-planes/152728",
      facebook: "https://www.facebook.com/astralxplanes",
      twitter: "https://twitter.com/astral__planes",
    }
  },
  {
    id: "aa1ea277-75b1-45e0-aef8-3fda501c0334",
    name: "hyperforms",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-S2KbQJ8SCqr3CI3r-Ad1zpw-t500x500.jpg",
    events: [
      "cf90969b-44e1-4915-aeaa-061f6842d6e3",
    ],
    links: {
      bandCamp: "https://hyperforms.bandcamp.com/releases",
      soundCloud: "https://soundcloud.com/hyperforms",
      spotify: "https://open.spotify.com/artist/0NreEgNG54pUbCtdJ7W7ub",
      appleMusic: "https://music.apple.com/us/artist/hyperforms/1214737842",
      deezer: "https://www.deezer.com/us/artist/12115100?source=omni",
      beatPort: "https://www.beatport.com/artist/hyperforms/624017",
      vk: "https://vk.com/hyperforms",
      youTube: "https://www.youtube.com/c/hyperforms",
      instagram: "https://www.instagram.com/hyperforms/",
      facebook: "https://www.facebook.com/hyperformsofficial/",
      twitter: "https://twitter.com/_hyperforms",
    }
  },
  {
    id: "905a1bc8-add0-4ff9-a63a-92c6db6e3a23",
    name: "KAVERN",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-WzSeno2aNqWXjopx-tYFpLQ-t500x500.jpg",
    events: [
      "78d26e50-ae61-4837-aac7-0267028f6401",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/1XEc0aycEzmxYuZfpY5Q7e',
      soundCloud: 'https://soundcloud.com/kavern-beats',
      bandCamp: 'https://kavern.bandcamp.com/',
      instagram: 'https://www.instagram.com/kavernbeats/',
      facebook: 'https://www.facebook.com/Kavernbeats/',
      twitter: 'https://twitter.com/KavernBeats'
    }
  },
  {
    id: "c0338f80-6881-46b0-8e0e-ace39922443d",
    name: "Trashlord",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000343175128-f0e6jk-t500x500.jpg",
    events: [],
    links: {
      soundCloud: "https://soundcloud.com/trshlrd",
      spotify: "https://open.spotify.com/artist/4CRAZ1v62qreoYTNrbP5Yj",
      appleMusic: "https://music.apple.com/us/artist/trash-lord/1166200389",
      deezer: "https://www.deezer.com/us/artist/11215508?source=omni",
      instagram: "https://www.instagram.com/trashlord.wav/",
      twitter: "https://twitter.com/trashlordmusic",
    }
  },
  {
    id: "f828cc93-b86e-4fe6-abde-40dfcc37686a",
    name: "Skit",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000550853928-tbj587-t500x500.jpg",
    events: [
      "9addd7ae-b915-4486-9bed-e4e4c5e49d8d",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/6ejpWdV51Wxr3azIpJXwoQ?lf=',
      soundCloud: 'https://soundcloud.com/skit',
      instagram: 'https://www.instagram.com/skitofficial/',
      twitter: 'https://twitter.com/skitofficial'
    }
  },
  {
    id: "4283d9e4-92ef-41f0-a6cf-b48897facae1",
    name: "foxwedding",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-FzrfxIxBMVuydRkU-faz75w-t500x500.jpg",
    events: [
    ],
    links: {
      bandCamp: "https://fox-wedding.bandcamp.com/",
      soundCloud: "https://soundcloud.com/fox-wedding",
      spotify: "https://open.spotify.com/artist/0DHLXYQowVvc8oQxp5Lrk3",
      appleMusic: "https://music.apple.com/us/artist/foxwedding/1059399531",
      deezer: "https://www.deezer.com/us/artist/9287382?source=omni",
      beatPort: "https://www.beatport.com/artist/foxwedding/631888",
      youTube: "https://www.youtube.com/channel/UCByf_8jWdmNmujDsG9Kf-mw",
      instagram: "https://www.instagram.com/foxwedding/",
      facebook: "https://www.facebook.com/FOXWEDDING1",
      twitter: "https://twitter.com/flexwedding",
    }
  },

  // -  40 - //

  {
    id: "a77f263a-3296-4f8c-84ff-2208134ddcfe",
    name: "M!NGO",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000375893087-ny58mw-t500x500.jpg",
    events: [
      "8f6f7215-05d1-453a-959c-11f9bfd172d7", "b1ce786b-8dc5-432d-84d4-b05d98d046d9",
      "78d26e50-ae61-4837-aac7-0267028f6401", "c78d3d43-a342-4d03-9a84-296adb82ccb9",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/0atqNWuCKAyb6BMWuEByhE',
      soundCloud: 'https://soundcloud.com/jmingo',
      bandCamp: 'https://mingo.bandcamp.com/',
      instagram: 'https://www.instagram.com/manlikemingo/?hl=en',
      facebook: 'https://www.facebook.com/ManlikeMINGO/',
      twitter: 'https://twitter.com/manlikemingo'
    }
  },
  {
    id: "0ad1f780-0e60-4fde-a1c3-c3808671292f",
    name: "Brothel",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-1kwkrigTsPWz7bPP-Fz9I1A-t500x500.jpg",
    events: [
      "4a711fd3-c0e4-485e-af9d-d8e62b372c88", "9c9d6571-bca3-4d10-97a6-83c502425843",
      "17380bf5-8520-4a77-ae38-28d893984d47", "b856115e-97f4-42bf-bf59-3a0ccf84cca2",
      "c6b85c8b-d7b9-4139-bcc8-acb9fd9b0b11", "8f6f7215-05d1-453a-959c-11f9bfd172d7",
      "6434a23a-618a-4df4-97e4-8efdf3f7dc8e", "9addd7ae-b915-4486-9bed-e4e4c5e49d8d",
      "65eb69ca-f032-43f7-af46-983278535578", "c78d3d43-a342-4d03-9a84-296adb82ccb9",
      "f9618733-b190-43f8-8c9e-d2f1506e9459", "e6568ce8-b96e-45ba-9c86-169e261175ff",
      "afe53f4b-1f74-44a3-bea9-5e38cd83c32d",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/3xYvvJ6tjXyJJdEXBs8qf0',
      soundCloud: 'https://soundcloud.com/brothelmusic',
      bandCamp: 'https://brothelmusic.bandcamp.com/',
      instagram: 'https://www.instagram.com/brothel.brothel',
      facebook: 'https://www.facebook.com/brothelofficial/',
      twitter: 'https://twitter.com/brothelmusic',
      website: 'https://www.brothelmerch.com/'
    }
  },
  {
    id: "d2bc4672-f7cb-4ebd-a25e-9b78048a8b0f",
    name: "Enjoii",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-Qm6PF2y0IegsZSVp-Fy3xrQ-t500x500.jpg",
    events: [
      "17380bf5-8520-4a77-ae38-28d893984d47", "9652c673-a514-435a-bee6-3d2ce7107145",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/5aUdEtXbJeOjAuNXLn9lkX',
      soundCloud: 'https://soundcloud.com/enjoii',
      appleMusic: 'https://music.apple.com/us/artist/enjoii/1159691479',
      bandCamp: 'https://enjoii.bandcamp.com/',
      youTube: 'https://www.youtube.com/c/enjoii/',
      instagram: 'https://www.instagram.com/enjoii/',
      facebook: 'https://www.facebook.com/xenjoii/',
      twitter: 'https://twitter.com/xenjoii/',
      website: 'http://enjoii.eu/'
    }
  },
  {
    id: "5613bb7a-d148-4164-a329-812a72eb74e5",
    name: "Noah B",
    description: "",
    imageURL: "https://i.scdn.co/image/0174e789bc101cf5dfd3d2e5df65b5eb24aebfb4",
    events: [
      "9c9d6571-bca3-4d10-97a6-83c502425843", "a59e5a55-1d61-4f73-ac9b-8a5e42fe2026",
      "31eb47a0-a317-443f-9032-23fae54e772c", "65eb69ca-f032-43f7-af46-983278535578",
      "81acaced-fd79-4beb-9ee6-1b580d73c054", "921aa6a1-4ea0-4ecb-8db2-7ab1cbaa1721",
    ],
    links: {
      bandCamp: "https://thisisnoahb.bandcamp.com/",
      soundCloud: "https://soundcloud.com/thisisnoahb",
      spotify: "https://open.spotify.com/artist/4F4w1Gkfja6wPJzuMKCLmk",
      appleMusic: "https://music.apple.com/us/artist/noah-b/1071519331",
      deezer: "https://www.deezer.com/us/artist/9119540",
      beatPort: "https://www.beatport.com/artist/noah-b/516733",
      instagram: "https://www.instagram.com/_thisisnoahb/",
      facebook: "https://www.facebook.com/noah.boujikian",
      twitter: "https://twitter.com/THISISNOAHB",
    }
  },
  {
    id: "37b5df35-6b7b-4ff8-b392-8d093a6793ef",
    name: "Sublab",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-1vfVzGIdFHcn2uY8-asT3yA-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://sublab.bandcamp.com/",
      soundCloud: "https://soundcloud.com/sublabmusic",
      spotify: "https://open.spotify.com/artist/3tjRdPCZTpme7vslZJYtJx",
      appleMusic: "https://music.apple.com/us/artist/sublab/1249839518",
      deezer: "https://www.deezer.com/us/artist/150125?source=omni",
      vk: "https://vk.com/sublabmusic",
      youTube: "https://www.youtube.com/channel/UCLfnER5Qy7hnNxztgyfjxMw",
      website: "https://sublabmusic.com/",
      instagram: "https://www.instagram.com/sublabmusic/",
      facebook: "https://www.facebook.com/sublabmusic",
      twitter: "https://twitter.com/sublabmusic",
    }
  },
  {
    id: "2855ad6e-e78a-425a-9e3c-9df73fc4b746",
    name: "BACKWHEN",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-ap0wnAt28LtvpyUg-905C7g-t500x500.jpg",
    events: [
      "349d015c-1324-46ef-ad38-3dd19068c8da",
    ],
    links: {
      bandCamp: "https://bamdcamp.bandcamp.com/",
      soundCloud: "https://soundcloud.com/backwhen808",
      spotify: "https://open.spotify.com/artist/47T694aNABbLrqSh4Di7Xj?si=Nh4dG7VNS7uMc5GpzM54Xw&nd=1",
      appleMusic: "https://music.apple.com/us/artist/backwhen/1202671262",
      deezer: "https://www.deezer.com/en/artist/11887369?source=omni",
      youTube: "https://www.youtube.com/channel/UCLSWVpt7sqE-uWPKon3aJyg",
      instagram: "https://www.instagram.com/backwhennn/",
      facebook: "https://www.facebook.com/backwhen605",
      twitter: "https://twitter.com/backwhennn",
    }
  },
  {
    id: "35888719-18d4-42d3-b623-19266fc6f247",
    name: "Celestial",
    description: "",
    events: ["c6b85c8b-d7b9-4139-bcc8-acb9fd9b0b11"],
    links: {
      soundCloud: "https://soundcloud.com/Celestialbh",
    }
  },
  {
    id: "3f11f74d-2600-4ed8-9df9-ac2c629461b0",
    name: "EONE",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-zZ93G0Z79LQ9hdWY-AO982A-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://eonesoundsuk.bandcamp.com/",
      soundCloud: "https://soundcloud.com/eonesoundsuk",
      spotify: "https://open.spotify.com/artist/3AGDYwQzhmQRXkiBT5pO3S?si=JWs0wTKXR5u5XlF_DoZbwA&nd=1",
      appleMusic: "https://music.apple.com/us/artist/eone/1477267244",
      deezer: "https://www.deezer.com/en/artist/5120655",
      beatPort: "https://www.beatport.com/artist/eone/342079",
      instagram: "https://www.instagram.com/eonesoundsuk/",
      facebook: "https://www.facebook.com/EonesoundsUK/",
      twitter: "https://twitter.com/EoneSoundsUK",
    }
  },
  {
    id: "9a4110ff-7417-431f-afb1-02d3d9d385fa",
    name: "Klimeks",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-UVEXS5WEK2wzmzCr-8rkLxQ-t500x500.jpg",
    events: [
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/5JQljNQvDpk1hhKpJHpwNU?si=HP7oViEvQp2auZL9a0tPjQ',
      soundCloud: 'https://soundcloud.com/klimeks',
      bandCamp: 'https://music.klimeks.co.uk/',
      instagram: 'https://www.instagram.com/klimeksmusic/',
      facebook: 'https://www.facebook.com/klimeks',
      twitter: 'https://twitter.com/klimeks',
      website:'https://klimeks.co.uk/'
    }
  },
  {
    id: "7ee42c8a-2982-4760-a12f-b1b752967a4a",
    name: "AyyJay",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000274085614-7lghu9-t500x500.jpg",
    events: [],
    links: {
      bandCamp: "https://ayyjay.bandcamp.com/",
      soundCloud: "https://soundcloud.com/ayyjaay",
      spotify: "https://open.spotify.com/artist/60RO3qigO2WPPYHUBCIZkg",
      appleMusic: "https://music.apple.com/us/artist/ayyjay/1068193854",
      deezer: "https://www.deezer.com/en/artist/9532526",
      beatPort: "https://www.beatport.com/artist/ayyjay/635032",
      instagram: "https://www.instagram.com/ayyjay___/",
      facebook: "https://www.facebook.com/aaron.rd.3",
      twitter: "https://twitter.com/AyyJvy  ",
    }
  },
  {
    id: "f0073e5b-c23a-4add-a29b-c5000e4c96d6",
    name: "MRKRYL",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-nn9C3U0QYyyLoIQb-vM3qnA-t500x500.jpg",
    events: [
      "05a9a61e-4f9b-429b-9516-43e42da2b99e", "e0ba48eb-0cfa-4861-801f-9f06bef02e91",
      "6b48f005-50cd-458a-b107-f55b74d69b8e", "ecda5215-8017-4b49-acfd-03f0f20d25d6",
      "61719063-19ba-49d5-9dfa-65befd58dac1", "1ce46e93-e324-481c-94ed-05263d59f156",
      "1f212b05-031c-4c7e-8d68-cad645880f2f", "2581c141-bdf1-4f03-8000-e24d96b56546",
      "921aa6a1-4ea0-4ecb-8db2-7ab1cbaa1721",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/5AJeziFEwkw9lvA4HPXcNt',
      soundCloud: 'https://soundcloud.com/mrkryl ',
      bandCamp: 'https://mrkrylofficial.bandcamp.com/releases',
      instagram: 'https://www.instagram.com/mrkryl/',
      facebook: 'https://www.facebook.com/sometimessomethingiseverything/',
      twitter: 'https://twitter.com/MRKRYL',
      youTube: 'https://www.youtube.com/channel/UCt4EImIF34Vkze9L-SnEJfQ'
    }
  },
  {
    id: "cb9586c1-45fc-4ce8-b108-48abfeb0e52c",
    name: "WTCHCRFT",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000737147410-qge5ih-t500x500.jpg",
    events: [
      "9c9d6571-bca3-4d10-97a6-83c502425843", "a59e5a55-1d61-4f73-ac9b-8a5e42fe2026",
      "f6af81de-437c-48a2-8b2e-a654f51c92ae",
    ],
    links: {
      bandCamp: "https://wtchcrft.bandcamp.com/",
      soundCloud: "https://soundcloud.com/wtchcrft2",
      spotify: "https://open.spotify.com/artist/5BfKiWheLXOhRKgVka5Gkq",
      appleMusic: "https://music.apple.com/us/artist/wtchcrft/979680731",
      deezer: "https://www.deezer.com/en/artist/8425410?source=omni",
      beatPort: "https://www.beatport.com/artist/wtchcrft/471242",
      vk: "https://vk.com/artist/wtchcrft",
      youTube: "https://www.youtube.com/channel/UCA8x5VcGHFAO69C_OPco4dQ",
      instagram: "https://www.instagram.com/w_t_c_h/",
      facebook: "https://www.facebook.com/WTCHCRFT1",
      twitter: "https://twitter.com/WTCHCRFT666",
    }
  },
  {
    id: "a209cc6d-cc0d-4415-833b-d4b9352b6d4b",
    name: "Mssingno",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000077484345-wsgte2-t500x500.jpg",
    events: [
    ],
    links: {
      bandCamp: "https://mssingno.bandcamp.com/",
      soundCloud: "https://soundcloud.com/mssingno",
      spotify: "https://open.spotify.com/artist/7iNWihBrDST1H86nEeFY9W",
      appleMusic: "https://music.apple.com/us/artist/mssingno/1053728535",
      deezer: "https://www.deezer.com/en/artist/5303770?source=omni",
      beatPort: "https://www.beatport.com/artist/mssingno/378303",
      vk: "https://vk.com/artist/mssingno",
      youTube: "https://www.youtube.com/channel/UCe5lBPXw2M333WCyLv__Z2Q",
      instagram: "https://www.instagram.com/mssingno/",
      facebook: "https://www.facebook.com/mssingno",
      twitter: "https://twitter.com/mssingno",
    }
  },

  // - 60 - //

  {
    id: "8a9aac1e-19bf-4566-998e-05cd023b0bec",
    name: "CHVRN",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000648145875-4zx3zv-t500x500.jpg",
    events: [
    ]
  },
  {
    id: "f56349f7-c4fc-4ce9-afc8-fe7ae8da90bf",
    name: "Landechoe",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-yDZldJdQTxwrdEcy-Lcb1yQ-t500x500.jpg",
    events: []
  },
  {
    id: "3e0ae7fe-aecf-4b50-bab8-0c4875265daf",
    name: "Kyddiekafka",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-hwH9zsPM0sqeHhv6-bzo3fg-t500x500.jpg",
    events: [
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/2WEqx25N8Xb7dHMjRmaalG?si=hRxn2th2QaWkGRwOh609WA',
      soundCloud: 'https://soundcloud.com/kyddiekafka',
      bandCamp: 'https://kyddiekafka.bandcamp.com/',
      youTube: 'https://www.youtube.com/user/SilvestreEditions',
      instagram: 'https://www.instagram.com/kyddiekafka/',
      facebook: 'https://www.facebook.com/kyddiekafkamusic',
      twitter: 'https://twitter.com/kyddiekafka'
    }
  },
  {
    id: "125105d9-775a-429c-ab9e-04f12f440cdc",
    name: "Vacant",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-lgwb4XPoMazbm77h-UtRlyw-t500x500.jpg",
    events: [
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/30DoC8Ezpk2qU8yRMUzr1x/about',
      soundCloud: 'https://soundcloud.com/vacantmusiclondon',
      appleMusic: 'https://music.apple.com/us/artist/vacant/517456250',
      bandCamp: 'https://vacant.bandcamp.com/',
      youTube: 'https://www.youtube.com/vacantldn',
      instagram: 'https://www.instagram.com/vacantldn/',
      facebook: 'https://www.facebook.com/vacantmusiclondon',
      twitter: 'https://twitter.com/vacantldn'
    }
  },
  {
    id: "169286b0-b3d4-4829-b080-8ba4d8c471ca",
    name: "Dyzphoria",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000464317086-uxaqug-t500x500.jpg",
    events: [
      "c6b85c8b-d7b9-4139-bcc8-acb9fd9b0b11", "2581c141-bdf1-4f03-8000-e24d96b56546",
      "4a7c231d-e2c9-4b38-8eec-3df9cee3cd42",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/6SehYBqIfg08SBrc7Ydreg',
      soundCloud: 'https://soundcloud.com/dyzphoria',
      bandCamp: 'https://dyzphoria.bandcamp.com/',
      instagram: 'https://www.instagram.com/dyzphoria/',
      facebook: 'https://www.facebook.com/dyzphoria',
      twitter: 'https://twitter.com/dyzphoria',
    }
  },
  {
    id: "b8a2c25d-b88e-4347-8c2a-945c92c16257",
    name: "palence",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-zZdGdKXiy9nDXpsx-9Xq3rA-t500x500.jpg",
    events: []
  },
  {
    id: "be3cc0c8-3e65-407a-ad52-14688e098ba5",
    name: "Bucky",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-Hyc4OQ4XVeD3hfsY-TRyfFg-t500x500.jpg",
    events: [
    ]
  },
  {
    id: "e43371e3-20c0-4f81-ab86-172e01d1cda9",
    name: "Silk Road Assassins",
    description: "",
    imageURL: "https://i.scdn.co/image/9ad2667d6354c1fce16bd949db2c5fa1b1464696",
    events: [
    ],
  },
  {
    id: "9d66c812-5915-49a0-9dd4-7ba3742fba97",
    name: "Last Japan",
    description: "",
    imageURL: "https://i.scdn.co/image/3486b1207cca06629f89eea98141ca5dcefaca27",
    events: []
  },
  {
    id: "b14341ab-3d48-4170-841b-55255e13f3ea",
    name: "Jato",
    description: "",
    imageURL: "https://i.scdn.co/image/e29403e7f1b2b96243d664602457dbc048793c1a",
    events: [
      "f870c437-0ee1-404b-a2bb-1aba1215f8b1",
    ]
  },
  {
    id: "c5a025b1-8b72-4c2e-af87-4099a15fae70",
    name: "Resonata",
    description: "",
    imageURL: "https://i.scdn.co/image/6694ce76fd999d6be0f7e6778e9faa2702ba7557",
    events: [
      "9f03af57-d10c-4258-8b1f-44778e89f6f9", "ae862f3b-146f-4b00-bcde-dfb2e6d2cc94",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/0DPxk01Zpe39U3H7gTBj4H',
      soundCloud: 'https://soundcloud.com/resonata',
      instagram: 'https://www.instagram.com/resonata_/',
      facebook: 'https://www.facebook.com/ResonataOfficial/',
      twitter: 'https://twitter.com/resonata_'
    }
  },
  {
    id: "0adf3528-a9fd-4331-afdc-b46da832d82b",
    name: "iSorin",
    description: "",
    imageURL: "https://i.scdn.co/image/b7d0af06b49309950422aa6f065e8373fdbf0e72",
    events: [
    ]
  },
  {
    id: "372c112f-75fc-4dcf-a4f5-bd514bdd6a18",
    name: "Juche",
    description: "",
    imageURL: "https://i.scdn.co/image/a56dbb32198c54e6031e70e8ae42130a9e3de59c",
    events: [
      "25126771-c7e0-46df-a810-c9aacc727eef", "b856115e-97f4-42bf-bf59-3a0ccf84cca2",
      "daa3c491-feb0-429c-b958-a37e05085ffa", "e6568ce8-b96e-45ba-9c86-169e261175ff",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/3GZ8Rfap7VxAOzABiZEXCL',
      soundCloud: 'https://soundcloud.com/erejuche',
      bandCamp: 'https://fromjuche.bandcamp.com/',
      instagram: 'https://www.instagram.com/fromjuche/',
      facebook: 'https://www.facebook.com/erejuche/',
      twitter: 'https://twitter.com/erejuche',
      youTube: 'https://www.youtube.com/channel/UCd6Caf2nnRrR5w7b6DjN7Lg',
      vk: 'https://vk.com/id444671266'
    }
  },
  {
    id: "9dcd8cba-6112-432f-93b2-47b2b1fcf203",
    name: "Harukasuka",
    description: "",
    imageURL: "https://i.scdn.co/image/6356ddefe604abd0f8d179de0ebc933ab5d739fc",
    events: ["4a7c231d-e2c9-4b38-8eec-3df9cee3cd42"],
    links: {
      soundCloud: "https://soundcloud.com/harukasuka",
    }
  },
  {
    id: "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22",
    name: "Skeler",
    description: "",
    imageURL: "https://i.scdn.co/image/330ef51de97fcb285663cb05f2f146c094f8fb5e",
    events: [
      "0284e116-2025-41db-9df1-951c646c5c1e", "a59e5a55-1d61-4f73-ac9b-8a5e42fe2026",
      "159e031a-0096-49b5-be05-3b96d410c915", "e6568ce8-b96e-45ba-9c86-169e261175ff",

    ],
    links: {
      spotify: 'https://open.spotify.com/artist/7ks4LdnBvp6HUsmVJiKgsB',
      soundCloud: 'https://soundcloud.com/skelermusic',
      appleMusic: 'https://music.apple.com/us/artist/skeler/1257397468',
      bandCamp: 'https://skelermusic.bandcamp.com/',
      youTube: 'https://www.youtube.com/channel/UCpoKdKVhH-jcr3Pu0auKjmw',
      instagram: 'https://www.instagram.com/skelerofficial/',
      facebook: 'https://www.facebook.com/skelermusic/',
      twitter: 'https://twitter.com/skelermusic'
    }
  },
  {
    id: "624f7975-c678-48eb-9f85-34dc11a58082",
    name: "LUDICY",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-uoBhU8kPgEWapeCw-iokLWw-t500x500.jpg",
    events: [],
    links: {
      spotify: 'https://open.spotify.com/artist/7BdHyuZ5YS6YwecHSeHiXu',
      soundCloud: 'https://soundcloud.com/ludicysounds',
      bandCamp: 'https://ludicysounds.bandcamp.com/releases',
      instagram: 'https://www.instagram.com/sound_weaver/',
      facebook: 'https://www.facebook.com/ludicysoundsofficial',
      twitter: 'https://twitter.com/ludicysounds'
    }
  },
  {
    id: "9c4a40d6-3e11-4dbb-8038-7639bde1ff11",
    name: "Deadcrow",
    description: "",
    imageURL: "https://i.scdn.co/image/ab67616100005174e6daccefd8ee1827e2ff3b77",
    events: [
      "076fc469-b3b7-43cc-9f92-6b9f0c517952", "c2fa0e90-6f2d-4e2e-ade0-fe12079b0175",
      "2581c141-bdf1-4f03-8000-e24d96b56546", "55b8544b-060b-412e-9319-ecc29ba9764b",
      "81acaced-fd79-4beb-9ee6-1b580d73c054", "f5d25111-14c3-4fb8-b600-8425ac5b75bf",
      "cf229d67-7539-42f8-a18c-369e573c7b00", "5bb39710-7d5d-4b8f-8db6-73e420312bcd",

    ],
    links: {
      spotify: 'https://open.spotify.com/artist/4ufkY8hmhmYl4aCnzv3dLE',
      soundCloud: 'https://soundcloud.com/deadcrxw',
      bandCamp: 'https://deadcrow.bandcamp.com/',
      instagram: 'https://www.instagram.com/devdcrow/',
      facebook: 'https://www.facebook.com/deadcrxw/',
      twitter: 'https://twitter.com/devdcrow'
    }
  },
  {
    id: "75c02207-5bcc-4377-bf1b-f25e0b9d1579",
    name: "w/out",
    description: "",
    imageURL: "https://i.scdn.co/image/5ed676e3ea76e018669630161a2f0e39c55690fa",
    events: [
      "f5d25111-14c3-4fb8-b600-8425ac5b75bf", "d404cc79-81d6-428c-9936-4a394222a269",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/5gtHKg86NBBz20yUOO8f7b?si=BndP6BmgRTyWh5SEZFjAxA',
      soundCloud: 'https://soundcloud.com/thisiswithout',
      instagram: 'https://www.instagram.com/thisiswithout/',
      facebook: 'https://www.facebook.com/thisiswithout/',
      twitter: 'https://twitter.com/thisiswithout'
    }
  },
  {
    id: "92e72cec-603f-4622-a8f7-147b99bba410",
    name: "Sombra",
    description: "",
    imageURL: "",
    events: [""]
  },
  {
    id: "195d1986-f79a-44d1-a36c-42634a2c1df0",
    name: "Fyoomz",
    description: "",
    imageURL: "https://i.scdn.co/image/0bd0c450842939541b88874d94b3266e3e2f2a89",
    events: [
      "9c9d6571-bca3-4d10-97a6-83c502425843", "b856115e-97f4-42bf-bf59-3a0ccf84cca2",
      "6434a23a-618a-4df4-97e4-8efdf3f7dc8e", "a59e5a55-1d61-4f73-ac9b-8a5e42fe2026",
      "f5d25111-14c3-4fb8-b600-8425ac5b75bf", "921aa6a1-4ea0-4ecb-8db2-7ab1cbaa1721",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/08kgcpEQscDxkhVLXRkGXK?si=RTo4wyZ8Q_uds2jyJbIvCA',      
      soundCloud: 'https://soundcloud.com/fyoomz',
      bandCamp: 'https://fyoomz.bandcamp.com/',
      instagram: 'https://www.instagram.com/fyoomz/',
      facebook: 'https://www.facebook.com/fyoomz/',
      twitter: 'https://twitter.com/fyoomz',
    }
  },
  {
    id: "684388b2-a83e-4159-84fd-f1f169829073",
    name: "TIME94",
    description: "",
    imageURL: "https://i.scdn.co/image/d41795663532cbdfd7a2414726aea10e9f4b01fc",
    events: []
  },
  {
    id: "0cfb1578-49fb-486b-b72c-dbfb1568e351",
    name: "ROT10",
    description: "",
    imageURL: "https://i.scdn.co/image/fff31afdb86117ecb75554382209a94fe8e401bf",
    events: []
  },
  {
    id: "96187786-5425-4a35-b8b0-e9a5f98702ad",
    name: "Sibewest",
    description: "",
    imageURL: "https://i.scdn.co/image/ab6761610000517479c44b7aea3f9f6a0ee0e692",
    events: []
  },
  {
    id: "2777e990-49e8-4a79-8440-89f213ecc411",
    name: "Giant Ibis",
    description: "",
    imageURL: "https://i.scdn.co/image/4c37a000db84d1da7998d1a557aa9c45e0591b62",
    events: [],
    links: {
      spotify: 'https://open.spotify.com/artist/5nMsZgYVD86BSKYjhQKP5A',
      soundCloud: 'https://soundcloud.com/giantibismusic',
      bandCamp: 'https://giantibis.bandcamp.com/',
      instagram: 'https://www.instagram.com/giantibis/',
      twitter: 'https://twitter.com/Giant_ibis'
    }
  },
  {
    id: "aa8507e3-e295-442d-af3c-7036e9ef44aa",
    name: "Vexxy",
    description: "",
    imageURL: "https://i.scdn.co/image/2974a1a051ca7118012204bb9d17dc4fa2ec409f",
    events: [],
  },
  {
    id: "08353957-64fa-45de-b3f1-f390bd03f433",
    name: "REMNANT.exe",
    description: "",
    imageURL: "https://i.scdn.co/image/ab6761610000517434052cae930a3cdd6adcd9eb",
    events: [
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/46q8Ul5r65jpuyZ8LwWPN3',
      soundCloud: 'https://soundcloud.com/remnantsofyore',
      bandCamp: 'https://remnantsofyore.bandcamp.com',
      twitter: 'https://twitter.com/remnantsofyore'
    }
  },
  {
    id: "0a967ce4-e926-4e17-a987-4dd08a0a0ce8",
    name: "CVRL",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000697299091-gpycub-t500x500.jpg",
    events: [
    ]
  },
  {
    id: "db18fc83-643c-4f03-aea9-83456cbc045c",
    name: "Imer6ia",
    description: "",
    imageURL: "https://i.scdn.co/image/f304e4c4a27bc3b872d24e2ffdd11b1102619e11",
    events: [
    ]
  },
  {
    id: "ab918827-aa3e-4462-9a4d-dacf3759e37e",
    name: "TIGEREYES",
    description: "",
    imageURL: "https://i.scdn.co/image/f07122ecc248f1ca266be7874ed0954fb38c4017",
    events: [
    ],
    links: {
      soundCloud: 'https://soundcloud.com/tigerr-eyes',
      instagram: 'https://www.instagram.com/tigereyes.official/',
      facebook: 'https://www.facebook.com/iamtigereyes/'
    }
  },
  {
    id: "5bb2ec42-87be-4fd1-8c9f-3fb94f58991f",
    name: "Calicry",
    description: "",
    imageURL: "https://i.scdn.co/image/f7c2a26e117614f99329c1bc6652edeef9571064",
    events: [
    ],
    links: {
      soundCloud: 'https://soundcloud.com/calicry',
      instagram: 'https://www.instagram.com/calicry/',
      facebook: 'https://www.facebook.com/kaseyissocoolunlikeURmom'
    }
  },
  {
    id: "f587554a-2e53-4997-93a7-c1480c56d88f",
    name: "METAHESH",
    description: "",
    imageURL: "https://i.scdn.co/image/c4ce1686003bf34d79a643012b75a274d454c189",
    events: []
  },
  {
    id: "6953b6f9-d944-4383-95c2-a9e6228a6da9",
    name: "Gladkill",
    description: "",
    imageURL: "https://i.scdn.co/image/d1b62c4e9f975e557272e94a32aee6ca40810d00",
    events: []
  },
  {
    id: "c7f75b69-5a29-4f60-a486-ace3354d3df6",
    name: "MYSTXRIVL",
    description: "",
    imageURL: "https://i.scdn.co/image/969cfd808c8fe2c9ad9bc54899e99ae7bf464219",
    events: [
      "9652c673-a514-435a-bee6-3d2ce7107145", 
    ]
  },
  {
    id: "a5fa3d99-781f-4468-bc2e-5ecd371992a6",
    name: "Ytho",
    description: "",
    imageURL: "https://i.scdn.co/image/c3c89711deb187ee8da2e0669241072af548812b",
    events: [
    ]
  },
  {
    id: "0e3a1efe-123f-4b11-9d80-aa4946991c80",
    name: "Plastician",
    description: "",
    imageURL: "https://i.scdn.co/image/cae62e0a5f0ee24dd8efbccb4cd9f6a91971b0f2",
    events: [
      "b856115e-97f4-42bf-bf59-3a0ccf84cca2", "f146f2b4-e48d-44c7-8240-2c3277d1d9b8",
      "b1a72a5e-666e-4048-b24d-88a3fcfd751c", "81acaced-fd79-4beb-9ee6-1b580d73c054",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/6p41GgJajkf3W2YXAzL8xC',
      soundCloud: 'https://soundcloud.com/plastician',
      bandCamp: 'https://plastician.bandcamp.com/',
      instagram: 'https://www.instagram.com/plastician/',
      facebook: 'https://www.facebook.com/plastician',
      twitter: 'https://twitter.com/plastician'
    }
  },
  {
    id: "f7fafa9f-1ebe-4798-a8fc-cc42f7480b23",
    name: "Yama",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-VPW7YfPtEu7XqRkC-1yaW5A-t500x500.jpg",
    events: [
      "0284e116-2025-41db-9df1-951c646c5c1e", "afe53f4b-1f74-44a3-bea9-5e38cd83c32d",

    ],
    links: {
      soundCloud: 'https://soundcloud.com/yamadubs',
      instagram: 'https://www.instagram.com/yamadubs',
      facebook: 'https://www.facebook.com/yamadubs'
    }
  },
  {
    id: "42d3afdd-cb05-4d6d-ac80-f83a94a326e3",
    name: "Yedgar",
    description: "",
    imageURL: "https://i.scdn.co/image/e64813381d84f819f53cb8d9cc8ebfc7a5607b83",
    events: [
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/0YB8v04fVMgWFl9Nqdrqna',
      soundCloud: 'https://soundcloud.com/yedgar',
      bandCamp: 'https://yedgar.bandcamp.com/',
      instagram: 'https://www.instagram.com/yedgar/',
      facebook: 'https://www.facebook.com/yedgarmusic/ ',
      twitter: 'https://twitter.com/jyedgar',
      youTube: 'https://www.youtube.com/user/YedgarMusic'
    }
  },
  {
    id: "f913a669-71bf-48f7-94cc-4d9278d4f7de",
    name: "Nick Neutronz",
    description: "",
    imageURL: "https://i.scdn.co/image/ab67616100005174299f5a4e70d187887fff85af",
    events: ["c78d3d43-a342-4d03-9a84-296adb82ccb9"],
    links: {
      soundCloud: "https://soundcloud.com/nickneutronz",
    },
  },
  {
    id: "03dc31b6-213f-4b29-ae1c-0813c5e7b695",
    name: "Djedi",
    description: "",
    imageURL: "https://i.scdn.co/image/0d7c700f9b5294773151334189f1fecadb4c6dfa",
    events: [
      "9c9d6571-bca3-4d10-97a6-83c502425843", "17380bf5-8520-4a77-ae38-28d893984d47",
      "b856115e-97f4-42bf-bf59-3a0ccf84cca2", "8f6f7215-05d1-453a-959c-11f9bfd172d7",
      "076fc469-b3b7-43cc-9f92-6b9f0c517952", "2581c141-bdf1-4f03-8000-e24d96b56546",
      "0284e116-2025-41db-9df1-951c646c5c1e", "a59e5a55-1d61-4f73-ac9b-8a5e42fe2026",
      "31eb47a0-a317-443f-9032-23fae54e772c", "9addd7ae-b915-4486-9bed-e4e4c5e49d8d",
      "ea289abd-45ce-47d4-a057-1a15ed39d67e", "f6af81de-437c-48a2-8b2e-a654f51c92ae",
      "921aa6a1-4ea0-4ecb-8db2-7ab1cbaa1721", "c78d3d43-a342-4d03-9a84-296adb82ccb9",
      "5bb39710-7d5d-4b8f-8db6-73e420312bcd",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/59eedBe7vYyyWDyqHZ0IYd',
      soundCloud: 'https://soundcloud.com/djedimusic',
      bandCamp: 'https://djedi.bandcamp.com/',
      instagram: 'https://www.instagram.com/brett_djedi/',
      facebook: 'https://www.facebook.com/djedimusic',
      twitter: 'https://twitter.com/brett_djedi'
    }
  },
  {
    id: "ed153518-8c3d-44b7-a436-64b4186f08fa",
    name: "Tako Tomago",
    description: "",
    imageURL: "https://i.scdn.co/image/ab67616100005174983a5a49152be7c36c7611d6",
    events: [
      "c9e24786-ebf9-4aba-8870-56831be646a6",
    ]
  },
  {
    id: "b86e14e8-7300-415a-88e8-1e71706910f2",
    name: "Ravver",
    description: "",
    imageURL: "https://i.scdn.co/image/ab6761610000517486a45684efbd823109d76d01",
    events: []
  },
  {
    id: "14bb42da-2c19-4a29-9fb0-6cf344845866",
    name: "R@",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-a6XX1wPNlc32CVC8-3MeXMg-t500x500.jpg",
    events: []
  },
  {
    id: "56fb0655-b417-4669-9efd-857added9ca2",
    name: "Gastah",
    description: "",
    imageURL: "https://i.scdn.co/image/3efb2afc2ddcafb29651ca0a3dfa3cd0ff9a916f",
    events: [
    ]
  },
  {
    id: "2a934ed2-8181-46eb-b926-50dd953055ba",
    name: "WHITE KATANA",
    description: "",
    imageURL: "https://i.scdn.co/image/88c5838b3c25d993f7765823fc08e0e22fa98266",
    events: ["80ddea12-f6a0-470e-ab62-e6fefb2a9f24"]
  },
  {
    id: "b57db24c-40e9-45f6-9409-9f6d4b82322e",
    name: "Sidewalks and Skeletons",
    description: "",
    imageURL: "https://i.scdn.co/image/fb3fa62226dbb9b39b6b4bc853e0164d1a3d1fef",
    events: [
      "9c9d6571-bca3-4d10-97a6-83c502425843", "9addd7ae-b915-4486-9bed-e4e4c5e49d8d",
      "f9618733-b190-43f8-8c9e-d2f1506e9459", "e6568ce8-b96e-45ba-9c86-169e261175ff",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/48nHO1cuTbpx4ELhChsxX1',
      soundCloud: 'https://soundcloud.com/jakeleeismyname',
      appleMusic: 'https://music.apple.com/us/artist/sidewalks-and-skeletons/678419002',
      bandCamp: 'https://sidewalksandskeletons.bandcamp.com/',
      youTube: 'https://www.youtube.com/c/SidewalksandSkeletons',
      instagram: 'https://www.instagram.com/sidewalksandskeletons/',
      facebook: 'https://www.facebook.com/sidewalksandskeletons/',
      twitter: 'https://twitter.com/JakeLeeeee'
    }
  },
  {
    id: "04c5f330-059b-4240-80ff-c471248dd417",
    name: "Cardio",
    description: "",
    imageURL: "https://i.scdn.co/image/58dc3796650b136cee0c8d9d709a363941110354",
    events: [
    ]
  },
  {
    id: "a4b7e73e-d9c7-49bc-83cf-79cf78f78aaa",
    name: "LTHL",
    description: "",
    imageURL: "https://i.scdn.co/image/c3065857362908e958e61e8cc30681784ef3ae3e",
    events: [],
    links: {
      spotify: 'https://open.spotify.com/artist/00DUVKhfHjyNKmN9xBZHOJ',
      soundCloud: 'https://soundcloud.com/lthl',
      bandCamp: 'https://lthl.bandcamp.com/',
      instagram: 'https://www.instagram.com/lthlgrm/',
      facebook: 'https://www.facebook.com/lthlgrm',
      twitter: 'https://twitter.com/LTHLgrm'
    }
  },
  {
    id: "b09d61f7-4313-4862-9e08-3a3621275d93",
    name: "Klasey Jones",
    description: "",
    imageURL: "https://i.scdn.co/image/3ff382d792bd3a0618a4c5026d9cd816ce71325d",
    events: [
      "b856115e-97f4-42bf-bf59-3a0ccf84cca2", "b1a72a5e-666e-4048-b24d-88a3fcfd751c",
      "81acaced-fd79-4beb-9ee6-1b580d73c054",
    ]
  },
  {
    id: "1a8f4c5f-3fbd-4f91-bc99-2ea0fcf825d6",
    name: 'sweeep',
    description: "",
    imageURL: "https://i.scdn.co/image/ab67616100005174c5c5ab6154e4c6be2443c736",
    events: [
      "31eb47a0-a317-443f-9032-23fae54e772c", "65eb69ca-f032-43f7-af46-983278535578",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/44AMYUwAQZq6GzYPJO0Nn2',
      soundCloud: 'https://soundcloud.com/sweeep',
      bandCamp: 'https://sweeep.bandcamp.com/',
      instagram: 'https://www.instagram.com/sweeep_/',
      facebook: 'https://www.facebook.com/sweeepmusic ',
      twitter: 'https://twitter.com/sweeep_',
      vk: 'https://vk.com/sweeepmusic'
    }     
  },
  {
    id: "d2712624-d448-436b-be90-0479f2a52f0a",
    name: 'Just Connor',
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-000719696188-oyxvmz-t500x500.jpg",
    events: [
      "4a711fd3-c0e4-485e-af9d-d8e62b372c88", "a59e5a55-1d61-4f73-ac9b-8a5e42fe2026",
      "31eb47a0-a317-443f-9032-23fae54e772c",
    ],
    links: {
      spotify: 'https://open.spotify.com/artist/56RD5PjknZlVSB4HS6YEF1',
      soundCloud: 'https://soundcloud.com/justconnornyc',
      bandCamp: 'https://justconnor.bandcamp.com/',
      instagram: 'https://www.instagram.com/condonaldson/',
      twitter: 'https://twitter.com/justbeconnor'
    }
  },
  {
    id: "68bd3377-dfea-4965-9876-32e94a8b7f7f",
    name: "Howl",
    description: "",
    imageURL: "",
    events: ["05a9a61e-4f9b-429b-9516-43e42da2b99e"],
    links: {
      soundCloud: "https://soundcloud.com/darkvelvetbass",
    }
  },
  {
    id: "192ffd33-2e03-4e44-b99e-4b6ebbfc1956",
    name: "DARK VELVET",
    description: "",
    imageURL: "https://i1.sndcdn.com/avatars-YwHlvJjkpyTCMfCt-w86HqQ-t500x500.jpg",
    events: ["ecda5215-8017-4b49-acfd-03f0f20d25d6"],
    links: {
      soundCloud: "https://soundcloud.com/darkvelvetbass",
    }
  },
  {
    id: "356dd634-f4e2-44d2-8515-f7476e64715c",
    name: "STRGD",
    description: "",
    events: ["ecda5215-8017-4b49-acfd-03f0f20d25d6"],
    links: {
      soundCloud: "https://soundcloud.com/stargod",
    }
  },
  {
    id: "2d8788bd-92c6-4081-8363-65fa54f7b810",
    name: "Malum",
    description: "",
    events: ["25126771-c7e0-46df-a810-c9aacc727eef"],
    links: {
      soundCloud: "https://soundcloud.com/mvlvm",
    },
  },
  {
    id: "58ccf7d8-efe1-41be-9fde-ec4120d07a0e",
    name: "WEVLTH",
    description: "",
    events: [
      "6145554e-e373-4aaf-baaf-dc3ca53b2d8f", "65eb69ca-f032-43f7-af46-983278535578",
    ],
    links: {
      soundCloud: "https://soundcloud.com/wevlth",
    },
  },
  {
    id: "7639acd1-3fdc-4863-81c0-b1b1f3f50316",
    name: "WRCKTNGL",
    description: "",
    events: [
      "c6b85c8b-d7b9-4139-bcc8-acb9fd9b0b11", "349d015c-1324-46ef-ad38-3dd19068c8da",
    ],
    links: {
      soundCloud: "https://soundcloud.com/wrcktngl",
    },
  },
  {
    id: "2ea1880f-f34a-4a85-8099-a3f47ed597f6",
    name: "LEViTATE",
    description: "",
    events: ["c6b85c8b-d7b9-4139-bcc8-acb9fd9b0b11"]
  },
  {
    id: "f328b82b-6f19-4d23-baa8-d744566d096d",
    name: "MENTIRAS",
    description: "",
    events: ["c6b85c8b-d7b9-4139-bcc8-acb9fd9b0b11"],
    links: {
      soundCloud: "https://soundcloud.com/itsmentiras",
    }
  },
  {
    id: "4fd2dfef-481a-4b48-9316-113167d0f674",
    name: "2RAVENS",
    description: "",
    events: ["c6b85c8b-d7b9-4139-bcc8-acb9fd9b0b11"],
    links: {
      soundCloud: "https://soundcloud.com/2ravensmusic",
    }
  },
  {
    id: "2b000d21-5d49-44d6-a655-ab9b4f3b8c04",
    name: "Moonbaby",
    description: "",
    events: ["2581c141-bdf1-4f03-8000-e24d96b56546"],
    links: {
      soundCloud: "https://soundcloud.com/m-o-o-n-b-a-b-y",
    },
  },
  {
    id: "28001e7d-1feb-43bb-9ded-51e2e5040fcd",
    name: "DJ DIRECT DEPOSIT",
    description: "",
    events: ["2581c141-bdf1-4f03-8000-e24d96b56546"],
    links: {
      soundCloud: "https://soundcloud.com/djdirectdeposit",
    },
  },
  {
    id: "e3032b88-33e1-483d-8174-c920b504e1b6",
    name: "hnrk",
    description: "",
    events: ["9652c673-a514-435a-bee6-3d2ce7107145"],
    links: {
      soundCloud: "https://soundcloud.com/hnrk93",
    },
  },
  {
    id: "8cb8d7d8-c889-44d2-a23d-2424c5b64eb0",
    name: "Stohou",
    description: "",
    events: ["9652c673-a514-435a-bee6-3d2ce7107145"],
    links: {
      soundCloud: "https://soundcloud.com/stohou",
    },
  },
  {
    id: "4ab028dc-2240-41cc-bdb1-ea3a52294e5c",
    name: "Onhell",
    description: "",
    events: [
      "eee32d76-d39c-45e5-b79e-a06eeffc5853", "f870c437-0ee1-404b-a2bb-1aba1215f8b1",
      
    ],
    links: {
      soundCloud: "https://soundcloud.com/onhellmusic",
    }
  },
  {
    id: "f1da6515-c9b6-48db-ba9d-89dab1a54be7",
    name: "NEVAEH",
    description: "",
    events: ["b5122df2-a751-4027-ab30-6449956e585f"],
    links: {
      soundCloud: "https://soundcloud.com/nevaxh",
    }
  },
  {
    id: "11a9b015-6463-47b5-90fc-6e12bc97ac6c",
    name: "lostageless",
    description: "",
    events: ["b5122df2-a751-4027-ab30-6449956e585f"],
    links: {
      soundCloud: "https://soundcloud.com/lostageless",
    }
  },
  {
    id: "4e68a07b-c5bd-4daa-96d8-7d3f038625f7",
    name: "Kissay",
    description: "",
    events: ["57103a08-7d37-4ec3-ab71-f5095ab038c9"],
    links: {
      soundCloud: "https://soundcloud.com/kissaymusic",
    }
  },
  {
    id: "1d3ae963-14bb-42b2-80ba-de0a30fbf988",
    name: "Eevee",
    description: "",
    events: [
      "55b8544b-060b-412e-9319-ecc29ba9764b", "daa3c491-feb0-429c-b958-a37e05085ffa",
      "c78d3d43-a342-4d03-9a84-296adb82ccb9",
    ],
    links: {
      soundCloud: "https://soundcloud.com/eveline-eevee",
    }
  },
  {
    id: "62bc65f2-1b08-41b3-825b-4bfb9053be8a",
    name: "Demz One",
    description: "",
    events: ["7d1168cf-ac48-45ce-8a22-60b405a20260"],
    links: {
      soundCloud: "https://soundcloud.com/demzone",
    }
  },
  {
    id: "83557e3a-b992-405d-8d34-e53de149bc3b",
    name: "PURP",
    description: "",
    events: ["7d1168cf-ac48-45ce-8a22-60b405a20260"],
    links: {
      soundCloud: "https://soundcloud.com/purpgawd",
    }
  },
  {
    id: "54c94cfe-672e-480a-81a6-7bfd9fd8632d",
    name: "Myth",
    description: "",
    events: ["65eb69ca-f032-43f7-af46-983278535578"],
    links: {
      soundCloud: "https://soundcloud.com/mythmusicldn",
    }
  },
  {
    id: "9fb88f65-88df-4a7d-bbaf-8380a2c16a0c",
    name: "ÆSTRAL",
    description: "",
    events: ["daa3c491-feb0-429c-b958-a37e05085ffa"],
    links: {
      soundCloud: "https://soundcloud.com/aestral",
    }
  },
  {
    id: "48e4ac32-de4d-40d7-8898-3da84462d34d",
    name: "Tokyo Tears",
    description: "",
    events: ["daa3c491-feb0-429c-b958-a37e05085ffa"],
    links: {
      soundCloud: "https://soundcloud.com/tokyo_tears",
    }
  },
  {
    id: "bcbedbdd-4cea-4143-a63e-8f1e87d7919f",
    name: "SBU",
    description: "",
    events: ["78d26e50-ae61-4837-aac7-0267028f6401"],
    links: {
      soundCloud: "https://soundcloud.com/officialsbu",
    }
  },
  {
    id: "2c220e04-6025-4550-8c05-95969b9d21ae",
    name: "Spirit Bomb",
    description: "",
    events: ["78d26e50-ae61-4837-aac7-0267028f6401"],
    links: {
      soundCloud: "https://soundcloud.com/spiritxbomb",
    }
  },
  {
    id: "8a8e79c8-037a-4555-8146-373e011c21b8",
    name: "Outsidr",
    description: "",
    events: ["ea289abd-45ce-47d4-a057-1a15ed39d67e"],
    links: {
      soundCloud: "https://soundcloud.com/outsidr",
    }
  },
  {
    id: "c2953519-72dc-4472-9250-9c81b6e6e7ed",
    name: "Shxde",
    description: "",
    events: ["b1a72a5e-666e-4048-b24d-88a3fcfd751c"],
    links: {
      soundCloud: "https://soundcloud.com/shxde",
    }
  },
  {
    id: "9196d842-6c76-40b0-b824-5bd882a9c52e",
    name: "AL_NE",
    description: "",
    events: ["f5d25111-14c3-4fb8-b600-8425ac5b75bf",],
    links: {
      spotify: 'https://open.spotify.com/artist/7AHnoNiNhFM87Y3Rhzw1HU',
      soundCloud: 'https://soundcloud.com/al_ne',
      bandCamp: 'https://alonealex.bandcamp.com/',
      instagram: 'https://www.instagram.com/alexmezatis/',
      twitter: 'https://twitter.com/AL_NEmusic'
    }
  },
  {
    id: "7d1e7e1d-52fe-4698-a510-daea9f7c7d6e",
    name: "Matt Wax",
    description: "",
    events: ["9c4a40d6-3e11-4dbb-8038-7639bde1ff11"],
    links: {
      soundCloud: "https://soundcloud.com/mattwaxblr",
    }
  },
  {
    id: "77e348e4-5831-4645-b712-d86db9473752",
    name: "Glacci",
    description: "",
    events: ["0958b05c-fdb9-4372-ad23-4cea76d1233f",],
    links: {
      soundCloud: "https://soundcloud.com/glacci",
    }
  },
  {
    id: "50ad7c80-07fe-4f22-b763-9635a86d0855",
    name: "CloZee",
    description: "",
    events: ["921aa6a1-4ea0-4ecb-8db2-7ab1cbaa1721"],
    links: {
      soundCloud: "https://soundcloud.com/clozee",
    }
  },
  {
    id: "be44e4b2-07b7-422b-a6da-69898a497445",
    name: "YVVRN",
    description: "",
    events: ["159e031a-0096-49b5-be05-3b96d410c915"],
    links: {
      soundCloud: "https://soundcloud.com/yvvrn_music",
    }
  },
  {
    id: "97cb8e94-5431-4c82-a3de-8a5080b0ae9a",
    name: "Azaleh",
    description: "",
    events: ["e6568ce8-b96e-45ba-9c86-169e261175ff"],
    links: {
      bandCamp: "https://azaleh.bandcamp.com/music",
      soundCloud: "https://soundcloud.com/azaleh",
      spotify: "https://open.spotify.com/artist/3yNkKjNBiX09eHZ4EQ3lJY",
      appleMusic: "https://music.apple.com/en/artist/azaleh/1210267119",
      deezer: "https://www.deezer.com/en/artist/7570386",
      beatPort: "https://www.beatport.com/artist/azaleh/582455",
      youTube: "https://www.youtube.com/c/Azaleh",
      instagram: "https://www.instagram.com/azalehmusic/",
      facebook: "https://www.facebook.com/azalehaudio/",
    }
  },
  {
    id: "37b1bb8d-6117-481a-a409-d601d781bef6",
    name: "Enjja",
    description: "",
    events: ["e6568ce8-b96e-45ba-9c86-169e261175ff"],
    links: {
      soundCloud: "https://soundcloud.com/ennja",
    }
  },
  {
    id: "381d15df-a3b6-4ba3-acb0-280afb7b2e20",
    name: "Sibewest",
    description: "",
    events: ["e6568ce8-b96e-45ba-9c86-169e261175ff"],
    links: {
      soundCloud: "https://soundcloud.com/sibewest",
    }
  },
  {
    id: "12f97ed7-8087-4905-8412-75ec534302cb",
    name: "TRIGO",
    description: "",
    events: ["349d015c-1324-46ef-ad38-3dd19068c8da"],
    links: {
      soundCloud: "https://soundcloud.com/trigomusic",
    }
  },
  {
    id: "9083006b-3110-462b-9f96-c38cac1dd44d",
    name: "FERNGAZER",
    description: "",
    events: ["afe53f4b-1f74-44a3-bea9-5e38cd83c32d"],
    links: {
      soundCloud: "https://soundcloud.com/ferngazer",
    }
  },
  {
    id: "7022ea7b-28f3-465d-82cb-6c5bd5f022c2",
    name: "EASTGHOST",
    description: "",
    events: ["afe53f4b-1f74-44a3-bea9-5e38cd83c32d"],
    links: {
      soundCloud: "https://soundcloud.com/eastghost",
    }
  },
  {
    id: "6c37d47b-3215-4ff2-b603-42c9c7c6845f",
    name: "XALA",
    description: "",
    events: ["f870c437-0ee1-404b-a2bb-1aba1215f8b1"],
    links: {
      soundCloud: "https://soundcloud.com/xalamusic",
    }
  },
  {
    id: "c67a3fb1-2ada-489b-94d9-b2eb6b0831c5",
    name: "C-ZER",
    description: "",
    events: ["f870c437-0ee1-404b-a2bb-1aba1215f8b1"],
    links: {
      soundCloud: "https://soundcloud.com/thisisczer",
    }
  },
  {
    id: "660a585a-4ad0-4c73-9686-0732aeff4512",
    name: "Loss Combinator",
    description: "",
    events: ["f870c437-0ee1-404b-a2bb-1aba1215f8b1"],
    links: {
      soundCloud: "https://soundcloud.com/losscombinator",
    }
  },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
  // {
  //   id: "",
  //   name: "",
  //   description: "",
  //   events: [""],
  //   links: {
  //     soundCloud: "",
  //   }
  // },
]

