// - DEV NOTE -> Currently used to partially obscure phone numbers and emails
export const obscureUserInfo = (info: string) => {
  const numChars = info.length;
  const portionOne = info.substring(0,3);
  const portionTwo = info.substring (4, numChars - 3);
  const portionThree = info.substring(numChars - 2, numChars);
  const obscuredPortion = new Array(portionTwo.length + 1).join("*");

  return `${portionOne}${obscuredPortion}${portionThree}`;
}
