// -> Beyond codebase
import React, { useContext, useEffect, useState } from "react";
import { css } from "aphrodite";
import { useTranslation } from "react-i18next";
// -> Within codebase
import { UIContext, ThemeInfo } from "../../../../../../Components/UI_InfoProvider/UI_InfoProvider";
import Spacer from "../../../../../../Components/LayoutUtilities/Spacer/Spacer";
import Indicator from "../../../../../../Components/VisualUtilities/Indicator/Indicator";
import { HORIZONTAL, ROUND, VERTICAL } from "../../../../../../constants";
// -> Within component
import {
  IMFAVerificationOptionProps, IMFAVerificationOptionState, calibrateComponent,
  INDICATOR_BG_COLOR_TRANSITION_STYLE
} from "./helpers";
import { styleGen } from "./MFAVerificationOptionStyles";

const MFAVerificationOption: React.FC<IMFAVerificationOptionProps> = (props) => {
  const [state, setState] = useState<IMFAVerificationOptionState>({ selected: false })
  const { t } = useTranslation("component_MFAVerificationOption");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { selected, verificationOption, onSelectVerificationOption } = props;
  const styles = styleGen(themeInfo, state);
  const {
    radioButtonCradle, restCradle, typeText, messageText,
    selectionIndicatorCradle
  } = styles;
  const { componentCradleStylechain } = calibrateComponent(styles, state);

  useEffect(() => setState({ selected }), [selected])

  const onToggleSelection = (option: string) => {
    const { selected } = state;
    onSelectVerificationOption(option);
    // - DEV NOTE -> These are suppoosed to act as a radio button set together, so
    //               there always has to be at least one option selected.
    if (!selected) setState((prevState) => ({ selected: !prevState.selected }));
  }

  return (
    <div
      className={componentCradleStylechain}
      onClick={() => onToggleSelection(verificationOption)}
    >
      <div className={css(radioButtonCradle)}>
        <div className={css(selectionIndicatorCradle)}>
          <Indicator
            active={state.selected}
            toggleable
            geometry={ROUND}
            color={themeInfo.palette.primary}
            size={15}
            borderWidth={0}
            backgroundColorTransition={INDICATOR_BG_COLOR_TRANSITION_STYLE}
            onClick={() => onToggleSelection(verificationOption)}
          />
        </div>
      </div>
      <Spacer direction={HORIZONTAL} amount={distance.three} />
      <div className={css(restCradle)}>
        <p className={css(typeText)}>{verificationOption}</p>
        {
          (verificationOption !== "None") && (
            <>
              <Spacer direction={VERTICAL} amount={distance.one} />
              <p className={css(messageText)}>{t("verificationCodeMsg")}</p>
            </>
          )
        }
      </div>
    </div>
  );
}

export default MFAVerificationOption;
