import React, { useContext, useEffect, useState } from "react";
import { css } from "aphrodite-jss";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// -> Within codebase
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import DiscoBallIcon from "../../VisualUtilities/IconPresets/DiscoBallIcon";
import Tag from "src/Components/Tag/Tag";
// import Button from "../../../Components/Button/Button";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import { UIContext } from "../../UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL, ROUNDED, SCREEN_WIDTH_450, SPACE_CHAR_UNICODE, VERTICAL } from "../../../constants";
import { computeEventDurationString, determineEventStatus, determineScreenDimensions } from "../../../helpers";
// -> Within component
import { IMAGE_SIZE_LG, IMAGE_SIZE_SM, IEventCardProps } from "./helpers";
import { styleGen } from "./EventCardStyles";

const EventCard: React.FC<IEventCardProps> = (props) => {
  const { onClick, imageSize, event, event: {
    imageURL, name, city, country, type, startDateTime,
    endDateTime, startTimeProvided, endTimeProvided,
  }} = props;
  const [computedImageSize, setComputedImageSize] = useState(imageSize ? imageSize : IMAGE_SIZE_LG);
  const history = useHistory();
  // const { t } = useTranslation("component_EventCard");
  const { themeInfo } = useContext(UIContext);
  const { fonts, distance, palette } = themeInfo;
  const {
    cardCradle, imgSection, eventInfoSection, customCardCradleStyles, fallbackIconCradle,
    eventNameText, locationRow, eventDateTimeText,
  } = styleGen(themeInfo, props, computedImageSize);

  const computedOnClick = (onClick) ? onClick : () => {};

  // -----

  useEffect(() => {
    const { width: screenWidth } = determineScreenDimensions();

    if (screenWidth <= SCREEN_WIDTH_450) {
      setComputedImageSize(IMAGE_SIZE_SM);
    }

    const handleResize = debounce(() => {
      const { width: screenWidth } = determineScreenDimensions();
      if (screenWidth <= SCREEN_WIDTH_450) setComputedImageSize(IMAGE_SIZE_SM)
      else setComputedImageSize(imageSize ? imageSize : IMAGE_SIZE_LG);
    }, 10);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [imageSize]);

  return (
    <div className={css(cardCradle, customCardCradleStyles)}>
      <div className={css(imgSection)}>
        {
          (imageURL) ? (
            <ImageWrapper
              alt={`Event: ${name}`} src={imageURL}
              borderGeometry={ROUNDED}
              height={computedImageSize} width={computedImageSize}
              customImgStyles={{ borderRadius: themeInfo.borderRadii.two }}
              customImgCradleStyles={{ borderRadius: themeInfo.borderRadii.two }}
            />
          ) : (
            <div className={css(fallbackIconCradle)} style={{paddingRight: distance.one}}>
              <DiscoBallIcon color={themeInfo.palette.grey2} size={computedImageSize / 2} />
            </div>
          )
        }
      </div>

      <Spacer direction={HORIZONTAL} amount={distance.three} />

      <div className={css(eventInfoSection)}>
      <p className={css(eventNameText)}>{name}</p>

      <Spacer direction={VERTICAL} amount={distance.two} />

      {/* - TODO: -> Further processing needed for online vs. IRL vs. hybrid vs. multiple locations */}
      <div className={css(locationRow)}>
        <Tag
          labelText={`${city}, ${country}`}
          tagGeometry={ROUNDED}
          activeColor={palette.primary_p1}
          activeTextColor={palette.white}
          customCradleStyles={{
            paddingTop: distance.one, paddingBottom: distance.one,
            paddingLeft: distance.one, paddingRight: distance.one,
          }}
          customTextStyles={{ fontSize: "1.2rem", fontWeight: "bold" }}
        />
      </div>

      <Spacer direction={VERTICAL} amount={distance.two} />

      <p className={css(eventDateTimeText)}>
        {computeEventDurationString({
          startDateTime, endDateTime, startTimeProvided, endTimeProvided
        })}
      </p>
      </div>
    </div>
  );
}

export default EventCard;
