import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import {
  EventListItemProps, DEFAULT_EVENT_LIST_ITEM_HEIGHT, DEFAULT_IMAGE_SIZE
} from "./helpers";

export const styleGen = (props: EventListItemProps, themeInfo: ThemeInfo) => {
  const { customCradleStyles, customEventNameTextStyles } = props;
  const { palette, distance, fonts, borderRadii, transitions } = themeInfo;

  return StyleSheet.create({
    listItemCradle: {
      height: "auto",
      minHeight: DEFAULT_EVENT_LIST_ITEM_HEIGHT,
      width: "100%",
      paddingLeft: distance.three,
      paddingRight: distance.three,
      paddingTop: distance.one,
      paddingBottom: distance.one,
      display: "flex",
      flexDirection: 'row',
      alignItems: "center",
      backgroundColor: palette.white,
      borderRadius: borderRadii.one,
      transition: transitions.bgColorTransition,
      '&:hover': {
        cursor: "pointer",
        backgroundColor: palette.grey1,
      },
    },
    itemNumberTextCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    itemNumberText: {
      fontFamily: fonts.primary,
      fontSize: "1.4rem",
      color: palette.grey6,
      "@media (max-width: 450px)": { fontSize: "1.2rem" },
    },
    eventImageCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    fallbackIconCradle: {
      height: DEFAULT_IMAGE_SIZE,
      width: DEFAULT_IMAGE_SIZE,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: borderRadii.one,
    },
    rightRowContentCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    eventNameText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "1.8rem",
      color: palette.black,
      "@media (max-width: 450px)": { fontSize: "1.6rem" },
    },
    eventDateText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "1.2rem",
      color: palette.grey4,
      "@media (max-width: 450px)": { fontSize: "1rem" },
    },
    customEventNameTextStyles: { ...customEventNameTextStyles },
    customCradleStyles: { ...customCradleStyles },
  });
};
