/* eslint-disable @typescript-eslint/no-unused-vars */
// -> Beyond codebase
import React, { useEffect, useContext, useState, useRef } from "react";
import { css } from "aphrodite";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// -> Within codebase
import ImageWrapper from "../../../../Components/VisualUtilities/ImageWrapper/ImageWrapper";
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import IconButton from "../../../../Components/Button/Variants/IconButton/IconButton";
import Button from "../../../../Components/Button/Button";
import Tag from "../../../../Components/Tag/Tag";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import Fade from "../../../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CalendarIcon from "../../../../Components/VisualUtilities/IconPresets/CalendarIcon";
import EditIcon from "../../../../Components/VisualUtilities/IconPresets/EditIcon";
import { MicrophoneIcon } from "../../../../Components/VisualUtilities/IconPresets";
import { ThemeInfo, UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../../../Components/DataContextProvider/DataContextProvider";
import {
  HORIZONTAL, ROUND, ROUNDED, SCREEN_WIDTH_550, VERTICAL, 
  STATIC_MAP_SMALL_WIDTH, STATIC_MAP_LARGE_WIDTH, STATIC_MAP_XS_WIDTH, EDIT_EVENT_ROUTE, EVENT_ID_ROUTE_PARAM_TOKEN, DEFAULT_TRANSITION_MICROANIMATION_TIME
} from "../../../../constants";
import {
  determineScreenDimensions, mapExternalLinkToIcon, underConstructionAlert,
  determineEventStatus, computeEventDurationString
} from "../../../../helpers";
import { EventStatus, Venue, Artist } from "../../../../Types";
import { getArtistsFromIDs, getVenuesFromIDs } from "../../../../lib";
// -> Within component
import { styleGen } from "./EventDetailsStyles";
import {
  DEFAULT_IMAGE_SIZE, EventDetailsProps, EventDetailsState,
  STATIC_MAP_ASPECT_RATIO, STATIC_MAP_SCALE_COEFFICIENT
} from "./helpers";
import DiscoBallIcon from "src/Components/VisualUtilities/IconPresets/DiscoBallIcon";

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env

const EventDetails: React.FC<EventDetailsProps> = (props) => {
  const [state, setState] = useState<EventDetailsState>({
    hasMultipleExternalLinks: false,
    mainContentInStatus: true,
    staticMapWidth: 0,
    venues: [],
    eventStatus: "Upcoming",
    staticMapURL: "",
  });
  const { t } = useTranslation("page_event");
  const { actionHistoryCardMode, onVenueClick, onArtistClick, event, event: {
    name, description, imageURL, startDateTime, endDateTime,
    venues: venueIDs, artists: artistIDs, links, ticketLinks, id,
    cancelled, type, city, country, startTimeProvided, endTimeProvided,
  }} = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { state: { venues, artists }} = useContext(DataContext);
  const history = useHistory();
  const { distance, palette, transitions, shadows, styles } = themeInfo;
  const {
    componentCradle, topSectionCradle, fallbackIconCradle, eventDetailsCradle, topRow,
    eventNameLocationStatusDateTimeCol, eventNameText, eventLinksCradle, noContentFallbackCradle,
    detailsLabelText, detailsLabelCradle, bodyText, staticMapRow, staticMapCradle,
    infoRow, eventVenuesCradle, eventVenuesTitleBarCradle, eventVenuesTitleBarText,
    eventVenuesTitleCradle, eventVenuesListCradle, eventNoVenuesCradle, itemSeparatorCradle,
    noContentFallbackText, locationAndTypeRow, eventDateTimeText, subtitleLabelText, eventArtistsCradle
  } = styleGen(themeInfo, state, props);

  const { width: screenWidth } = determineScreenDimensions();
  let mainContentMicroanimationTimeout = useRef<any>(-1);
  const componentCradleRef = useRef<HTMLDivElement>(null);

  let computedMapWidth: number;
  let computedMapHeight: number;

  if (actionHistoryCardMode) {
    computedMapWidth = STATIC_MAP_XS_WIDTH;
  }
  else {
    if (screenWidth > SCREEN_WIDTH_550) computedMapWidth = STATIC_MAP_LARGE_WIDTH;
    else computedMapWidth = STATIC_MAP_SMALL_WIDTH;
  }
  
  if (actionHistoryCardMode) {
    computedMapHeight = Math.floor(STATIC_MAP_XS_WIDTH * STATIC_MAP_ASPECT_RATIO);
  }
  else {
    if (screenWidth > SCREEN_WIDTH_550) computedMapHeight = Math.floor(STATIC_MAP_LARGE_WIDTH * STATIC_MAP_ASPECT_RATIO);
    else computedMapHeight = Math.floor(STATIC_MAP_SMALL_WIDTH * STATIC_MAP_ASPECT_RATIO);
  }


  const staticMapWidthRef = useRef<number>(computedMapWidth);
  // const staticMapWidthRef = useRef<number>(
    //   (screenWidth > SCREEN_WIDTH_550) ? STATIC_MAP_LARGE_WIDTH : STATIC_MAP_SMALL_WIDTH
    // );
    const staticMapHeightRef = useRef<number>(computedMapHeight);
    // const staticMapHeightRef = useRef<number>(
    //   (screenWidth > SCREEN_WIDTH_550) ?
    //   (Math.floor(STATIC_MAP_LARGE_WIDTH * STATIC_MAP_ASPECT_RATIO)) :
    //   (Math.floor(STATIC_MAP_SMALL_WIDTH * STATIC_MAP_ASPECT_RATIO))
    // );
    console.log(`Computed static map width and height -> ${staticMapWidthRef.current}, ${staticMapHeightRef.current}`);

  
  // ----
  
  useEffect(() => {
    if (links && Object.keys(links).length > 1) {
      setState((prevState) => ({ ...prevState, hasMultipleExternalLinks: true }));
    }
    
    let eventStatus: EventStatus = determineEventStatus(event);
    
    let eventVenues: Venue[] | undefined = undefined;
    let eventArtists: Artist[] | undefined = undefined;
    if (venueIDs) eventVenues = getVenuesFromIDs(venueIDs, venues);
    if (artistIDs) eventArtists = getArtistsFromIDs(artistIDs, artists)

    // - TODO: -> Improve component to handle an event that is carried out at multiple venues, right now just displaying the first.
    if (eventVenues) {
      let latitude = eventVenues[0].coordinates.lat;
      let longitude = eventVenues[0].coordinates.lng;
      // 0xEA4435
      let staticMapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${`${latitude},${longitude}`}&size=${`${staticMapWidthRef.current}x${staticMapHeightRef.current}`}&maptype="roadmap"&scale=${STATIC_MAP_SCALE_COEFFICIENT}&format=jpg&markers=size:mid|color:0x${palette.primary.substring(1)}|${`${latitude},${longitude}`}&key=${REACT_APP_GOOGLE_MAPS_API_KEY}`;
      setState((prevState) => ({ ...prevState, venues: eventVenues, eventStatus, staticMapURL }));
    }

    if (eventArtists) setState((prevState) => ({ ...prevState, artists: eventArtists }));
  }, [artistIDs, artists, event, links, palette.primary, venueIDs, venues]);
  
  // -----

  const onNavigateToEditEvent = (artistID: string) => {
    
    const computedURL = EDIT_EVENT_ROUTE.replace(EVENT_ID_ROUTE_PARAM_TOKEN, artistID);

    // - TODO: -> Why isn't the set timeout callback invoking?
    history.push(computedURL);
    mainContentMicroanimationTimeout.current = setTimeout(() => {
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----
  
  const onExternalLinkClick = (link: string) => window.open(link, "_blank");
  const externalEventLinks: string[] = links ? Object.values(links) : [];

  // -----

  const { mainContentInStatus, venues: eventVenues, artists: eventArtists, eventStatus, staticMapURL } = state;

  return (
    <Fade inStatus={mainContentInStatus}>
      <div className={css(componentCradle)} ref={componentCradleRef}>
        <div className={css(topSectionCradle)}>
          {/* IMAGE */}
          {
            (imageURL) ? (
              <ImageWrapper
                alt={`Event: ${name}`} src={imageURL}
                borderGeometry={ROUNDED}
                height={DEFAULT_IMAGE_SIZE} width={DEFAULT_IMAGE_SIZE}
              />
            ) : (
              <div className={css(fallbackIconCradle)}>
                <CalendarIcon color={palette.grey2} size={DEFAULT_IMAGE_SIZE - 65} />
              </div>
            )
          }

          <div style={{ marginRight: distance.three, marginTop: distance.three }}></div>

          <div className={css(eventDetailsCradle)}>
            {/* NAME, CITY, COUNTRY, STATUS */}
            <div className={css(topRow)}>
              <div className={css(eventNameLocationStatusDateTimeCol)}>
                <p className={css(eventNameText)}>{name}</p>

                <Spacer direction={VERTICAL} amount={distance.two} />
                
                {/* - TODO: -> Further processing needed for online vs. IRL vs. hybrid vs. multiple locations */}
                <div className={css(locationAndTypeRow)}>
                  <Tag
                    labelText={`${city}, ${country}`}
                    tagGeometry={ROUNDED}
                    activeColor={palette.primary_p1}
                    activeTextColor={palette.white}
                    customCradleStyles={{
                      paddingTop: distance.one + 2, paddingBottom: distance.one + 2,
                      paddingLeft: distance.one + 2, paddingRight: distance.one + 2,
                    }}
                    customTextStyles={{ fontSize: "1.2rem", fontWeight: "bold" }}
                  />

                  <Spacer direction={HORIZONTAL} amount={distance.two} />

                  <Tag
                    labelText={eventStatus}
                    tagGeometry={ROUNDED}
                    activeColor={palette.white}
                    activeTextColor={palette.primary_p1}
                    activeBorder={`2px solid ${palette.primary_p1}`}
                    customCradleStyles={{
                      paddingTop: distance.one, paddingBottom: distance.one,
                      paddingLeft: distance.one, paddingRight: distance.one,
                    }}
                    customTextStyles={{ fontSize: "1.2rem", fontWeight: "bold" }}
                  />
                  
                  <Spacer direction={HORIZONTAL} amount={distance.two} />

                  <Tag
                    labelText={type}
                    tagGeometry={ROUNDED}
                    activeColor={palette.white}
                    activeTextColor={palette.primary_p1}
                    activeBorder={`2px solid ${palette.primary_p1}`}
                    customCradleStyles={{
                      paddingTop: distance.one, paddingBottom: distance.one,
                      paddingLeft: distance.one, paddingRight: distance.one,
                    }}
                    customTextStyles={{ fontSize: "1.2rem", fontWeight: "bold" }}
                  />
                </div>

                <Spacer direction={VERTICAL} amount={distance.two} />

                <p className={css(eventDateTimeText)}>
                  {computeEventDurationString({
                    startDateTime, endDateTime, startTimeProvided, endTimeProvided
                  })}
                </p>
              </div>
              {
                actionHistoryCardMode ? null : (
                  <IconButton
                    height={45}
                    width={45}
                    buttonCradlePadding={0}
                    buttonGeometry={ROUND}
                    color={palette.white}
                    customCradleStyles={{
                      transition: transitions.boxShadowTransition,
                      padding: distance.two,
                      height: 45, width: 45,
                      position: "relative",
                      left: 15
                    }}
                    customCradleHoverStyles={{ boxShadow: shadows.one }}
                    icon={<EditIcon color={palette.black} size={styles.standardIconSize} />}
                    // customCradleStyles={{ padding: 0, justifyContent: 'flex-end' }}
                    onClick={() => onNavigateToEditEvent(id)}
                  />
                )
              }
            </div>

            <LineSegment
              direction={HORIZONTAL}
              width={"100%"} size={2}
              color={palette.black}
              customStyles={{
                marginTop: distance.three, marginBottom: distance.three,
              }}
            />

            {/* VENUE LINKS */}
            <div className={css(eventLinksCradle)}>
              {
                (links && (Object.keys(links).length > 0)) ? (
                  Object.keys(links).map((linkName: string, index: number) => {

                    return (
                      <div key={index}>
                        <IconButton
                          icon={mapExternalLinkToIcon(linkName, 25, palette.black)}
                          onClick={() => onExternalLinkClick(externalEventLinks[index])}
                          buttonGeometry={ROUND}
                          color={palette.white}
                          customCradleStyles={{
                            transition: transitions.boxShadowTransition,
                            padding: distance.two,
                            height: 45, width: 45
                          }}
                          customCradleHoverStyles={{ boxShadow: shadows.one }}
                        />
                        {
                          (index < Object.keys(links).length) ? (
                            <Spacer direction={HORIZONTAL} amount={distance.three} />
                          ) : null
                        }
                      </div>
                    );
                  })
                ) : (
                  <div className={css(noContentFallbackCradle)}>
                    <p className={css(noContentFallbackText)}>{t("noExternalVenueLinksMsg")}</p>
                  </div>
                )
              }
            </div>
          </div>
        </div>

        <Spacer direction={VERTICAL} amount={distance.three} />

        {/* DETAILS */}
        <div className={css(detailsLabelCradle)}>
          <p className={css(detailsLabelText)}>{t("detailsLabel")}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.two} />

        {/* DESCRIPTION */}
        <p className={css(subtitleLabelText)}>{t("descriptionLabel")}</p>
        <Spacer direction={VERTICAL} amount={distance.two} />

        {
          (description) ? (
            <p className={css(bodyText)}>{description}</p>
            ) : (
              <div className={css(noContentFallbackCradle)}>
              <p className={css(noContentFallbackText)}>{t("noEventDescriptionMsg")}</p>
            </div>
          )
        }
        <Spacer direction={VERTICAL} amount={distance.two} />

        {/* ARTISTS */}
        <p className={css(subtitleLabelText)}>{t("artistsLabel")}</p>
        <Spacer direction={VERTICAL} amount={distance.one} />

        <p className={css(eventArtistsCradle)}>
          {
            (eventArtists && eventArtists.length > 0) ? (
              eventArtists.map((artist: Artist, index: number) => {
                const { imageURL, name, id } = artist;

                return (
                  <div key={index} style={{ marginTop: distance.one, marginRight: distance.two }}>
                    <Button
                      onClick={() => onArtistClick(id)}
                      buttonText={name}
                      buttonGeometry={ROUND}
                      icon={
                        imageURL ? (
                          <ImageWrapper
                            alt={`Artist: ${name}`} src={imageURL}
                            borderGeometry={ROUND}
                            height={25} width={25}
                          />
                        ) : (
                          <div style={{
                            height: 25,
                            width: 25,
                            display: "grid",
                            placeItems: "center",
                            backgroundColor: palette.grey6,
                            borderRadius: "50%",
                          }}>
                            <MicrophoneIcon color={palette.grey2} size={15} />
                          </div>
                        )
                      }
                      color={palette.white}
                      textColor={palette.black}
                      // activeBorder={`2px solid ${palette.primary_p1}`}
                      customCradleStyles={{
                        paddingTop: distance.one, paddingBottom: distance.one,
                        paddingLeft: distance.one, paddingRight: distance.one,
                        boxShadow: shadows.one,
                      }}
                      customCradleHoverStyles={{
                        transition: transitions.bgColorTransition,
                        backgroundColor: palette.grey1,
                      }}
                      customTextStyles={{ fontSize: "1.2rem" }}
                    />
                  </div>
                );
              })
            ) : (
              <div className={css(noContentFallbackCradle)}>
                <p className={css(noContentFallbackText)}>{t("noEventArtistsMsg")}</p>
              </div>
            )
          }
        </p>
        <Spacer direction={VERTICAL} amount={distance.three} />

        {/* VENUE(S) */}
        <p className={css(subtitleLabelText)}>
          {(eventVenues && eventVenues.length > 1) ? t("venuesLabel_other") : t("venuesLabel_one")}
        </p>
        <Spacer direction={VERTICAL} amount={distance.one} />

        <p className={css(eventArtistsCradle)}>
          {
            (eventVenues && eventVenues.length > 0) ? (
              eventVenues.map((artist: Artist, index: number) => {
                const { imageURL, name, id } = artist;

                return (
                  <div key={index} style={{ marginTop: distance.one, marginRight: distance.two }}>
                    <Button
                      onClick={() => onVenueClick(id)}
                      buttonText={name}
                      buttonGeometry={ROUND}
                      icon={
                        imageURL ? (
                          <ImageWrapper
                            alt={`Venue: ${name}`} src={imageURL}
                            borderGeometry={ROUND}
                            height={25} width={25}
                          />
                        ) : (
                          <div style={{
                            height: 25,
                            width: 25,
                            display: "grid",
                            placeItems: "center",
                            backgroundColor: palette.grey6,
                            borderRadius: "50%",
                          }}>
                            <DiscoBallIcon color={palette.grey2} size={15} />
                          </div>
                        )
                      }
                      color={palette.white}
                      textColor={palette.black}
                      // activeBorder={`2px solid ${palette.primary_p1}`}
                      customCradleStyles={{
                        paddingTop: distance.one, paddingBottom: distance.one,
                        paddingLeft: distance.one, paddingRight: distance.one,
                        boxShadow: shadows.one,
                      }}
                      customCradleHoverStyles={{
                        transition: transitions.bgColorTransition,
                        backgroundColor: palette.grey1,
                      }}
                      customTextStyles={{ fontSize: "1.2rem" }}
                    />
                  </div>
                );
              })
            ) : (
              <div className={css(noContentFallbackCradle)}>
                <p className={css(noContentFallbackText)}>{t("noEventArtistsMsg")}</p>
              </div>
            )
          }
        </p>
        <Spacer direction={VERTICAL} amount={distance.three} />

        {/* MAP */}
        <div className={css(staticMapRow)}>
          {
            (event.venues) ? (
              <div className={css(staticMapCradle)}>
                <img
                  alt={`Geographic location of ${name}`}
                  src={staticMapURL}
                />
              </div>
            ) : (
              <p className={css(noContentFallbackText)}>{t("noVenueMsg")}</p>
            )
          }
        </div>
        <Spacer direction={VERTICAL} amount={distance.three} />
      </div>
    </Fade>
  );
}

export default EventDetails;
