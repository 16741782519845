import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../UI_InfoProvider/UI_InfoProvider";

export const styleGen = (themeInfo: ThemeInfo) => {
  const {
    distance, palette, typography, borderRadii, transitions,
    shadows, fonts,
  } = themeInfo;
  
  return StyleSheet.create({
    contentCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "flex-start",
      backgroundColor: palette.white,
      boxShadow: shadows.five,
    },
    menuHeader: {
      height: 200,
      width: "100%",
      position: "relative",
      padding: distance.three,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: palette.primary,
    },
    settingsButtonCradle: {
      position: "absolute",
      top: distance.three,
      right: distance.three,
    },
    mainContentCradle: {
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    userNameText: {
      ...typography.default,
      fontSize: "2rem",
      fontWeight: "bold",
      color: palette.white,
    },
    itemRow: {
      height: "auto",
      width: "90%",
      paddingLeft: distance.three,
      paddingTop: distance.three,
      paddingBottom: distance.three,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderTopRightRadius: borderRadii.five,
      borderBottomRightRadius: borderRadii.five,
      backgroundColor: palette.white,
      transition: transitions.bgColorTransition,
      "&:hover": {
        backgroundColor: palette.grey1,
        cursor: "pointer",
      },
    },
    itemRowActive: {
      backgroundColor: palette.primary,
      "&:hover": {
        backgroundColor: palette.primary,
        cursor: "pointer",
      },
    },
    itemRowText: {
      fontFamily: fonts.primary,
      fontSize: "2rem",
      fontWeight: "bold",
      color: palette.primary,
      transition: transitions.colorTransition,
    },
    itemRowTextActive: {
      color: palette.white,
    }
  });
};
