import React from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';

interface IIconPresetProps {
  size?: number;
  color?: string;
}

export const SearchIcon: React.FC<IIconPresetProps> = (props) => {
  const { size, color } = props;

  return (
    <BiSearchAlt2 size={size} color={color} />
  );
}

export default SearchIcon;
