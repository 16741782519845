import { Event } from "../../../../Types";
import { IMAGE_SIZE_LG, IMAGE_SIZE_SM } from "../../../../Components/Cards/EventCard/helpers";

export const SMALL_CARD_WIDTH = IMAGE_SIZE_SM;
export const LARGE_CARD_WIDTH = IMAGE_SIZE_LG;
export const GALLERY_WIDTH_BREAKPOINT = 525;

export interface IEventGalleryProps {
  events: Event[];
  onEventClick(artistID: string): any;
  customEventGalleryCradleStyles?: any;
}