import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import {
  IArtistListItemProps, DEFAULT_ARTIST_LIST_ITEM_HEIGHT, DEFAULT_IMAGE_SIZE
} from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IArtistListItemProps) => {
  const { palette, fonts, distance, borderRadii, transitions } = themeInfo;
  const {
    customListItemCradleStyles, customArtistNameTextStyles, customSongNumTextStyles,
    customArtistMetadataItemLabelTextStyles, customArtistMetadataItemValueTextStyles,
    customItemNumberTextStyles, imageSize,
  } = props;

  return StyleSheet.create({
    listItemCradle: {
      minHeight: DEFAULT_ARTIST_LIST_ITEM_HEIGHT,
      width: "100%",
      paddingLeft: distance.three,
      paddingRight: distance.three,
      display: "flex",
      alignItems: "center",
      backgroundColor: palette.white,
      borderRadius: borderRadii.one,
      transition: transitions.bgColorTransition,
      '&:hover': {
        cursor: "pointer",
        backgroundColor: palette.grey1,
      },
    },
    itemNumberTextCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    itemNumberText: {
      fontFamily: fonts.primary,
      fontSize: "1.4rem",
      color: palette.grey6,
      "@media (max-width: 450px)": { fontSize: "1.2rem" },
    },
    artistImageCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    fallbackIconCradle: {
      height: imageSize ? imageSize : DEFAULT_IMAGE_SIZE,
      width: imageSize ? imageSize : DEFAULT_IMAGE_SIZE,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: borderRadii.one,
    },
    rightRowContentCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      alignItems: "center",
    },
    artistNameCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    artistNameText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "1.8rem",
      color: palette.black,
      "@media (max-width: 450px)": { fontSize: "1.6rem" },
    },
    artistMetadataItemColumn: {
      height: "100%",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    artistMetadataItemValueText: {
      fontFamily: fonts.primary,
      fontSize: "1.6rem",
      fontWeight: "bold",
      color: palette.black,
      "@media (max-width: 450px)": { fontSize: "1.4rem" },
    },
    artistMetadataItemLabelText: {
      fontFamily: fonts.primary,
      fontSize: "1rem",
      color: palette.grey6,
    },
    customListItemCradleStyles: { ...customListItemCradleStyles },
    customArtistNameTextStyles: { ...customArtistNameTextStyles },
    customSongNumTextStyles: { ...customSongNumTextStyles },
    customItemNumberTextStyles: { ...customItemNumberTextStyles },
    customArtistMetadataItemLabelTextStyles: { ...customArtistMetadataItemLabelTextStyles },
    customArtistMetadataItemValueTextStyles: { ...customArtistMetadataItemValueTextStyles },
  })
}