import { StyleSheet } from 'aphrodite-jss';
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { ILoginCardProps, DEFAULT_CARD_WIDTH } from './helpers';

export const styleGen = (props: ILoginCardProps, themeInfo: ThemeInfo) => { 
  const { customCardCradleStyles, isInCardMode } = props;
  const { distance, palette, shadows, borderRadii, typography } = themeInfo;

  return StyleSheet.create({
    cardCradle: {
      height: 'auto',
      width: DEFAULT_CARD_WIDTH,
      padding: (isInCardMode) ? distance.three : 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      boxShadow: (isInCardMode) ? shadows.one : "none",
      borderRadius: borderRadii.one,
      backgroundColor: palette.white,

      "@media (max-width: 320px)": {
        width: 290,
      }
    },
    topBarCradle: {
      minHeight: 100,
      height: "auto",
      width: '100%',
      paddingLeft: distance.three,
      paddingRight: distance.three,
      display: 'flex',
      flexDirection: "column",
      alignItems: 'center',
      backgroundColor: palette.white,
      borderTopLeftRadius: borderRadii.one,
      borderTopRightRadius: borderRadii.one,
    },
    promptText: {
      ...typography.default,
      fontSize: "1.5rem",
      fontStyle: "italic",
      color: palette.grey3,
      marginBottom: 0,
      textAlign: "center",
    },
    cardBottomCradle: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flex: 1,
    },
    customCardCradleStyles: { ...customCardCradleStyles }
  });
}