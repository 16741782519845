import { StyleSheet } from "aphrodite-jss";
import { NAV_BAR_HEIGHT } from "../../constants";
import { ThemeInfo } from "../UI_InfoProvider/UI_InfoProvider";

export const styleGen = (themeInfo: ThemeInfo) => {
  // const { palette } = themeInfo;

  return StyleSheet.create({
    mapContainer: {
      display: "flex",
      justifyContent: "center"
    },
    mapCradle: {
      height: "60vh",
      width: "80%"
    },
    loadingElementCradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      width: "100%",
      display: "grid",
      placeItems: "center",
    },
  });
};
