import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import {
  VenueListItemProps, DEFAULT_VENUE_LIST_ITEM_HEIGHT, DEFAULT_IMAGE_SIZE
} from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: VenueListItemProps) => {
  const { customVenueNameTextStyles, customVenueListItemCradleStyles } = props;
  const { palette, distance, fonts, borderRadii, transitions } = themeInfo;

  return StyleSheet.create({
    listItemCradle: {
      height: "auto",
      minHeight: DEFAULT_VENUE_LIST_ITEM_HEIGHT,
      width: "100%",
      paddingLeft: distance.three,
      paddingRight: distance.three,
      paddingTop: distance.one,
      paddingBottom: distance.one,
      display: "flex",
      alignItems: "center",
      backgroundColor: palette.white,
      borderRadius: borderRadii.one,
      transition: transitions.bgColorTransition,
      '&:hover': {
        cursor: "pointer",
        backgroundColor: palette.grey1,
      },
    },
    itemNumberTextCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    itemNumberText: {
      fontFamily: fonts.primary,
      fontSize: "1.4rem",
      color: palette.grey6,
      "@media (max-width: 450px)": { fontSize: "1.2rem" },
    },
    venueImageCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    fallbackIconCradle: {
      height: DEFAULT_IMAGE_SIZE,
      width: DEFAULT_IMAGE_SIZE,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: borderRadii.one,
    },
    rightRowContentCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      alignItems: "center",

      "@media (max-width: 450px)": {
        flexDirection: "column",
        alignItems: "flex-start",
      }
    },
    venueNameCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    venueNameText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "1.8rem",
      color: palette.black,
      "@media (max-width: 450px)": { fontSize: "1.6rem" },
    },
    venueLocationCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      "@media (max-width: 450px)": { marginTop: distance.two },
    },
    customVenueNameTextStyles: { ...customVenueNameTextStyles },
    customVenueListItemCradleStyles: { ...customVenueListItemCradleStyles },
  });
};
