// -> Beyond codebase
import React, { useContext, useEffect, useState } from "react";
import { css } from "aphrodite";
import { useHistory, useLocation } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
// -> Within codebase
import FillUnderNavBarCradle from "../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import VerticalFade from "../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade";
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import { ThemeInfo, UIContext } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import { mapHttpErrorCodeToMessage, underConstructionAlert, customAlertGen } from "../../helpers";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, HOME_ROUTE, VERTICAL } from "../../constants";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MFAType } from "../../Types";
// -> Within component
import AuthenticateMFACard from "./InternalComponents/AuthenticateMFACard/AuthenticateMFACard"
import { IAuthenticateMFAState } from "./helpers";
import { styleGen } from "./AuthenticateMFAStyles";
import StatusMessage from "../../Components/VisualUtilities/StatusMessage/StatusMessage";
import { XIcon } from "../../Components/VisualUtilities/IconPresets";
import Spacer from "src/Components/LayoutUtilities/Spacer/Spacer";

const AuthenticateMFA: React.FC = () => {
  const [state, setState] = useState<IAuthenticateMFAState>({
    loadingStatus: false,
    errorStatus: false,
    codeEntryInStatus: true,
    loaderInStatus: true,
    MFAScenario: "Confirmation",
    MFAType: "None",
    email: "",
    phoneNumber: "",
  });
  const { state: locationState }: any = useLocation();
  const history = useHistory();
  const { t } = useTranslation("page_authenticateMFA");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { getUser, confirmMFA, provideMFACode, setUser } = useContext(AuthContext);
  const { pageCradle, mainContentCradle } = styleGen(themeInfo);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let loaderTimeout: ReturnType<typeof setTimeout>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let codeEntryTimeout: ReturnType<typeof setTimeout>;
  let networkError: any;

  // -----

  // - DEV NOTE -> This function acts as initial setup when the component first mounts.
  useEffect(() => {
    if (locationState) {
      const { MFAScenario, ephemeralToken, email, phone, mfa_method, MFAType } =
        locationState;
      // - DEV NOTE -> This asserts that the MFAScenario will always be passed in route state when
      //               navigating to this screen.
      setState((prevState) => ({ ...prevState, MFAScenario }));

      // - DEV NOTE -> This denotes the scenario in which the user is activating MFA for the first time.
      //              -> In this case, the MFA methodo they've chosen will be passed down directly,
      //                 rather than from the login endpoint when they're logging in with MFA confirmed.
      if (MFAType) setState((prevState) => ({ ...prevState, MFAType }));

      // - DEV NOTE -> This denotes the scenario in which the user is logging in with MFA enabled.
      //              -> Since there is no currently logged in user, information regarding their
      //                 MFA method and email/phone number is provided as a response from the
      //                 login endpoint.
      if (ephemeralToken && email && phone && mfa_method) {
        let MFAType: MFAType = "None";
        if (mfa_method === "sms_twilio") MFAType = "SMS";
        else if (mfa_method === "email") MFAType = "Email";
        setState((prevState) => ({
          ...prevState,
          ephemeralToken,
          email,
          phoneNumber: phone,
          MFAType,
        }));
      }
    }

    // -----

    // - DEV NOTE -> If the user is setting up MFA initially, they will be logged in and this
    //               screen will have access to their email and phone number from the user object
    //               through the <AuthContextProvider />
    const user = getUser();
    if (user) {
      const { email } = user;
      // - TODO: -> This has unaccounted-for edge cases (e.g. user registers for Email MFA with no
      //            phone number associated to their profile)
      const phoneNumber = user.phoneNumber!;
      setState((prevState) => ({ ...prevState, phoneNumber, email }));
    }
  }, [getUser, locationState]);

  // -----

  const onSubmitCode = async (MFAType: MFAType, code: string) => {
    const { MFAScenario, ephemeralToken } = state;
    let successMessage = "";

    setState((prevState) => ({ ...prevState, codeEntryInStatus: false }));
    codeEntryTimeout = setTimeout(() => {
      setState((prevState) => ({ ...prevState, loadingStatus: true }));
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);

    if (MFAScenario === "Confirmation") {
      const { error } = await confirmMFA(MFAType, code);
      networkError = error;
    } else if (MFAScenario === "Login Code Entry") {
      // - DEV NOTE -> Asserting that ephemeralToken will not be undefined in this scenario since
      //               it would have been pushed in the route state when we transition from the login
      //               screen to this screen during an MFA-enabled login.
      const { error } = await provideMFACode(code, ephemeralToken!);
      networkError = error;
    }

    if (networkError) {
      const errorMessage = mapHttpErrorCodeToMessage(networkError);

      setState((prevState) => ({
        ...prevState,
        errorStatus: true,
        errorMessage,
        loaderInStatus: false,
      }));

      loaderTimeout = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          loadingStatus: false,
          codeEntryInStatus: true,
        }));
        customAlertGen(errorMessage, themeInfo);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    } else {
      if (MFAScenario === "Confirmation")
        successMessage = t("confirmationSuccessMsgTitle");
      if (MFAScenario === "Login Code Entry")
        successMessage = t("loginSuccessMsgTitle");

      setState((prevState) => ({ ...prevState, loaderInStatus: false }));
      loaderTimeout = setTimeout(() => {
        const user = getUser();
        const newUser = cloneDeep(user!);
        newUser.settings.MFAEnabled = true;
        setUser(newUser);
        customAlertGen(successMessage, themeInfo);
        history.push(HOME_ROUTE);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  };

  // -----

  const resendCode = () => underConstructionAlert();

  // -----

  const {
    loadingStatus,
    errorStatus,
    codeEntryInStatus,
    loaderInStatus,
    errorMessage,
    MFAScenario,
    MFAType,
    email,
    phoneNumber,
  } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {!loadingStatus ? (
          <VerticalFade inStatus={codeEntryInStatus}>
            <div className={css(mainContentCradle)}>
              {errorStatus && (
                <>
                  <Fade>
                    <StatusMessage
                      color={themeInfo.palette.errorStatus}
                      headerText={t("authenticationErrorTitleMsg")}
                      messageText={errorMessage}
                      icon={
                        <XIcon
                          color={themeInfo.palette.errorStatus}
                          size={40}
                        />
                      }
                    />
                  </Fade>
                  <Spacer direction={VERTICAL} amount={distance.three} />
                </>
              )}
              <AuthenticateMFACard
                onSubmitCode={onSubmitCode}
                resendCode={resendCode}
                MFAType={MFAType}
                email={email}
                phoneNumber={phoneNumber}
                MFAScenario={MFAScenario}
              />
            </div>
          </VerticalFade>
        ) : (
          <Fade inStatus={loaderInStatus}>
            <CircleLoader spinnerColor={themeInfo.palette.primary} />
          </Fade>
        )}
      </div>
    </FillUnderNavBarCradle>
  );
};

export default AuthenticateMFA;
