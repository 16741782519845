import { StyleSheet } from "aphrodite-jss";
// import { determineScreenDimensions } from "../../../helpers";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IArtistCardProps, BASE_CARD_WIDTH, BASE_CARD_HEIGHT, CARD_ASPECT_RATIO } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IArtistCardProps, computedImageSize: number) => {
  const { customCardCradleStyles, customNameTextStyles } = props;
  const { distance, palette, borderRadii, fonts, shadows } = themeInfo;

  // const { width: screenWidth } = determineScreenDimensions();
  // const computedSmallWidth = (screenWidth / 2) - distance.nine;

  return StyleSheet.create({
    cardCradle: {
      width: BASE_CARD_WIDTH,
      height: BASE_CARD_HEIGHT,
      padding: distance.three,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: palette.white,
      borderRadius: borderRadii.one,
      boxShadow: shadows.one,
      transition: themeInfo.transitions.bgColorTransition,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: palette.grey1,
      },

      "@media (max-width: 450px)": {
        width: BASE_CARD_WIDTH - 25,
        height: BASE_CARD_HEIGHT - 25,
        // width: computedSmallWidth,
        // height: computedSmallWidth * CARD_ASPECT_RATIO,
      },
    },
    imgSection: {
      height: "75%",
      width: "100%",
      display: "grid",
      placeItems: "center",
    },
    fallbackIconCradle: {
      height: computedImageSize,
      width: computedImageSize,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: "50%",
    },
    nameRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
    },
    nameText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "2.4rem",
      color: palette.black,

      "@media (max-width: 450px)": { fontSize: "2.0rem" },
    },
    customCardCradleStyles: { ...customCardCradleStyles },
    customNameTextStyles: { ...customNameTextStyles },
  });
}
