import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { CARD_ASPECT_RATIO_INVERSE } from "../../../../Components/Cards/VenueCard/helpers";
import { VenueGalleryProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: VenueGalleryProps, cardWidth: number) => {
  const { customVenueGalleryCradleStyles } = props;
  const { distance, palette, typography } = themeInfo;

  return StyleSheet.create({
    galleryCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      backgroundColor: palette.white,
    },
    galleryContentCradle: {
      height: "100%",
      width: "100%",
      padding: distance.three,
      display: "grid",
      gridTemplateColumns: `repeat(auto-fill, ${cardWidth}px)`,
      gridTemplateRows: `repeat(auto-fit, ${cardWidth * CARD_ASPECT_RATIO_INVERSE}px)`,
      justifyContent: "center",
      justifyItems: "center",
      alignItems: "start",
      alignContent: "start",
      gridGap: `2.5rem`,
      gridAutoFlow: "dense",
      overflowY: "scroll",
      backgroundColor: palette.white,
    },
    galleryNoVenuesCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: palette.white,
    },
    noContentFallbackText: {
      ...typography.noContentFallbackText,
    },
    customVenueGalleryCradleStyles: { ...customVenueGalleryCradleStyles },
  });
}
