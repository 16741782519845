import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../UI_InfoProvider/UI_InfoProvider";
import { IPaginationProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IPaginationProps) => {
  const {
    customComponentCradleStyles, rtl, customPrevBtnCradleStyles, customPageNumBtnsCradleStyles,
    customNextBtnCradleStyles,
  } = props;
  const { distance } = themeInfo;

  return StyleSheet.create({
    componentCradle: {
      height: "auto",
      width: "auto",
      marginBottom: distance.two,
      display: "flex",
      flexDirection: "row",
      justifyContent: rtl ? "flex-end" : "flex-start",
      alignItems: "center",
    },
    prevBtnCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    pageNumBtnsCradle: {
      height: "100%",
      width: "auto",
      maxWidth: 450,
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",

      "@media (max-width: 450px)": { maxWidth: 200 },
    },
    nextBtnCradle: {
      height: "100%",
      width: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    customPrevBtnCradleStyles: { ...customPrevBtnCradleStyles },
    customPageNumBtnsCradleStyles: { ...customPageNumBtnsCradleStyles },
    customNextBtnCradleStyles: { ...customNextBtnCradleStyles },
    customComponentCradleStyles: { ...customComponentCradleStyles },
  });
}
