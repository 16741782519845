// -> Util
export const VENUE_ID_ROUTE_PARAM_TOKEN = ":venueID";
export const EVENT_ID_ROUTE_PARAM_TOKEN = ":eventID";
export const ARTIST_ID_ROUTE_PARAM_TOKEN = ":artistID";
// -> General
export const HOME_ROUTE = "/";
export const VENUES_ROUTE = "/venues";
export const VENUE_ROUTE = `/venue/${VENUE_ID_ROUTE_PARAM_TOKEN}`;
export const ADD_VENUE_ROUTE = `/add-venue`;
export const EDIT_VENUE_ROUTE = `/edit-venue/${VENUE_ID_ROUTE_PARAM_TOKEN}`;
export const EVENTS_ROUTE = "/events";
export const EVENT_ROUTE = `/event/${EVENT_ID_ROUTE_PARAM_TOKEN}`;
export const ADD_EVENT_ROUTE = `/add-event`;
export const EDIT_EVENT_ROUTE = `/edit-event/${EVENT_ID_ROUTE_PARAM_TOKEN}`;
export const ARTISTS_ROUTE = "/artists";
export const ARTIST_ROUTE = `/artist/${ARTIST_ID_ROUTE_PARAM_TOKEN}`;
export const ADD_ARTIST_ROUTE = `/add-artist`;
export const EDIT_ARTIST_ROUTE = `/edit-artist/${ARTIST_ID_ROUTE_PARAM_TOKEN}`;
export const PROFILE_ROUTE = "/profile";
export const LOGIN_ROUTE = "/login";
export const REGISTRATION_ROUTE = "/registration";
export const REQUEST_RESET_ROUTE = "/request-password-reset";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const ENABLE_MFA_ROUTE = "/enable-mfa";
export const AUTHENTICATE_MFA_ROUTE = "/authenticate-mfa";
export const ADMIN_DASHBOARD_ROUTE = "/admin-dashboard";
export const SETTINGS_ROUTE  = "/settings";
export const TEST_GROUND_ROUTE = "/test-ground";