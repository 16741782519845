import { EventStatus, Event, Venue, Artist } from "../../../../Types";

export const DEFAULT_IMAGE_SIZE = 185;
export const STATIC_MAP_ASPECT_RATIO = (2 / 3);
export const STATIC_MAP_SCALE_COEFFICIENT = 1;

export interface EventDetailsProps {
  event: Event;
  onVenueClick(venueID: string): void;
  onArtistClick(artistID: string): void;
  actionHistoryCardMode?: boolean;
}

export interface EventDetailsState {
  hasMultipleExternalLinks: boolean;
  mainContentInStatus: boolean;
  staticMapWidth: number;
  venues?: Venue[];
  artists?: Artist[];
  eventStatus: EventStatus;
  staticMapURL: string;
}
