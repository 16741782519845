import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { NAV_BAR_HEIGHT } from "../../constants";

export const styleGen = (themeInfo: ThemeInfo) => {
  const { palette, distance } = themeInfo;

  return StyleSheet.create({
    pageCradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      width: "100%",
      padding: distance.three,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: palette.white,
    },
  });
}
