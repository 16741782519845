import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";

export const styleGen = (themeInfo: ThemeInfo) => {
  const { palette, distance, borderRadii, shadows, typography, fonts } = themeInfo;
  
  return StyleSheet.create({
    modalCradle: {
      height: "auto",
      width: `calc(90vw)`,
      maxWidth: 1000,
      maxHeight: `calc(100vh - ${distance.six}px)`,
      overflowY: "scroll",
      padding: distance.three,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: borderRadii.two,
      boxShadow: shadows.three,
      backgroundColor: palette.white,

      "@media(max-width: 450px)": {
        width: `calc(100vw - ${distance.nine}px)`
      }
    },
    topPortion: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexDirection: "column",

    },
    titleText: {
      fontSize: "2.4rem",
      fontFamily: fonts.primary,
      fontWeight: "bold",
      textDecoration: "underline",
      color: palette.black,

      // textAlign: "center",
    },
    subtitleText: {
      fontSize: "1.8rem",
      fontFamily: fonts.primary,
      fontWeight: "bold",
      color: palette.black,
    },
    metadataItemRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    metadataItemLabelText: {
      fontFamily: fonts.primary,
      fontSize: "1rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: palette.grey3,
      "@media (max-width: 450px)": { fontSize: "1rem" },
    },
    entitySnapshotLabelText: {
      fontFamily: fonts.primary,
      fontSize: "1.4rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: palette.black,
      "@media (max-width: 450px)": { fontSize: "1rem" },
    },
    metadataArea: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",

      // "@media (max-width: 450px)": { flexDirection: "column "},
    },
    metadataColumn: {
      height: "auto",
      width: "auto",
      display: "flex",
      flexDirection: "column",
    },
    actionDescriptionText: {
      fontFamily: fonts.primary,
      fontSize: "1.8rem",
      color: palette.black,
      fontWeight: "bold",
    },
    fallbackIconCradle: {
      height: 20,
      width: 20,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: "50%",
    },
    usernameText: {
      fontSize: "1rem",
      color: palette.black,
      fontFamily: fonts.primary,
    },
    bottomPortion: {
      height: "auto",
      minHeight: 200,
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "row",

      "@media (max-width: 700px)": {
        flexDirection: "column",
      },
    },
    beforePane: {
      height: "100%",
      width: `calc(50% - ${distance.six}px)`,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      "@media (max-width: 700px)": {
        width: "100%"
      },
    },
    afterPane: {
      height: "100%",
      width: `calc(50% - ${distance.six}px)`,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      "@media (max-width: 700px)": {
        width: "100%"
      },
    },
    paneTitleRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paneBottomContent: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    noContentPane: {
      backgroundColor: palette.grey1,
      borderRadius: borderRadii.four,
      padding: distance.four,
    },
    noContentFallbackText: {
      ...themeInfo.typography.noContentFallbackText
    }
  });
};
