import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { MAP, NAV_BAR_HEIGHT } from "../../constants";
import { VenuesProps } from "./helpers";
import { VenuesState } from "./stateManagement";

export const styleGen = (
  { themeInfo, props, state } :
  {themeInfo: ThemeInfo, props: VenuesProps, state: VenuesState }
) => {
  const { customPageCradleStyles } = props;
  const { palette, distance } = themeInfo;
  const { displayMode } = state;

  return StyleSheet.create({
    componentCradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      width: '100%',
      backgroundColor: palette.white,
      paddingBottom: (displayMode === MAP) ? "none" : distance.three,
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column"
    },
    venuesContentCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    loaderCradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    addEventBtnCradle: {
      height: "auto",
      width: "auto",
      position: "fixed",
      bottom: distance.three,
      left: (displayMode === "Map") ? distance.three : undefined,
      right: (displayMode === "Map") ? undefined : distance.three,
    },
    customPageCradleStyles: { ...customPageCradleStyles },
  });
};
