// -> Beyond codebase
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { css } from 'aphrodite-jss';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import VerticalFade from "../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import FillUnderNavBarCradle from "src/Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import StatusMessage from "../../Components/VisualUtilities/StatusMessage/StatusMessage";
import Spacer from "../../Components/LayoutUtilities/Spacer/Spacer";
import { XIcon } from "../../Components/VisualUtilities/IconPresets";
import { ThemeInfo, UIContext } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import {
  DEFAULT_TRANSITION_MICROANIMATION_TIME, PROFILE_ROUTE,
  SETTINGS_ROUTE, VERTICAL
} from "../../constants";
import { customAlertGen, underConstructionAlert } from "../../helpers";
// -> Within component
import EditEventForm from "./InternalComponents/EditEventForm/EditEventForm";
import { styleGen } from "./EditEventStyles";
import { IEditEventState, IEditEventArgs } from "./helpers";

const EditEvent: React.FC = () => {
  const [state, setState] = useState<IEditEventState>({
    loadingStatus: true,
    errorStatus: false,
    pageContentInStatus: true,
    loaderInStatus: true,
    formInStatus: true,
  });
  
  const { t } = useTranslation("page_editEvent");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { palette, distance } = themeInfo;
  const { pageCradle } = styleGen(themeInfo);

  let formTimeout = useRef<any>(null);
  let loaderTimeout = useRef<any>(null);

  useEffect(() => (() => {
    clearTimeout(formTimeout.current);
    clearTimeout(loaderTimeout.current);
  }), []);

  useEffect(() => {
    setTimeout(() => {
      setState((prevState: any) => ({ ...prevState, loaderInStatus: false }));
      loaderTimeout.current = setTimeout(() => {
        setState((prevState: any) => ({ ...prevState, loadingStatus: false, formInStatus: true }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }, 1000);
  }, []);

  const onEditEventSubmission = async (args: IEditEventArgs) => {
    underConstructionAlert();
  }

const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadingStatus, loaderInStatus, errorStatus, formInStatus,
  } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {
          (loadingStatus) ? (
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={palette.primary} />
            </Fade>
          ) : (
            <VerticalFade inStatus={formInStatus}>
              <>
                {
                  (errorStatus) && (
                    <>
                      <StatusMessage
                        color={palette.errorStatus}
                        headerText={t("unsuccessfulVenueAdditionMsg")}
                        icon={<XIcon color={palette.errorStatus} size={40} />}
                      />
                      <Spacer direction={VERTICAL} amount={distance.three} />
                    </>
                  )
                }
                <EditEventForm onEditEventSubmission={onEditEventSubmission} />
              </>
            </VerticalFade>
          )
        }
      </div>
    </FillUnderNavBarCradle>
  );
}

export default EditEvent;
