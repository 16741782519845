// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DATA_ITEMS_PER_PAGE, GRID, LANGUAGE__ENGLISH, LANGUAGE__FRENCH, LIST, TEMP_AVATAR_URL, TWO_MINUTES_MS } from "../../constants";
import { IUser, MFAType, BackendMFAType, ISongPair, Artist, Event, Venue, ISongCollection, ISong } from "../../Types";
import { artists, overall_collection_starstuff, songCollections, events, venues } from "../../staticData";

// -> Endpoint constants
export const REACT_APP_BACKEND_BASE_URL = "https://syntronic-mfa.herokuapp.com";
export const REGISTRATION_URL = "/rest-auth/registration/";
export const LOGIN_URL = "/auth/login/";
export const RENEW_ACCESS_URL = "/api/token/refresh/";
export const USER_URL = "/rest-auth/user/";
export const REQUEST_RESET_URL = "/password/reset/";
export const RESET_PASSWORD_URL = "/password/reset/confirm/";
export const ACTIVATE_EMAIL_MFA_URL = "/auth/email/activate/";
export const ACTIVATE_SMS_MFA_URL = "/auth/sms_twilio/activate/";
export const CONFIRM_EMAIL_MFA_URL = "/auth/email/activate/confirm/";
export const CONFIRM_SMS_MFA_URL = "/auth/sms_twilio/activate/confirm/";
export const DEACTIVATE_EMAIL_MFA_URL = "/auth/email/deactivate/";
export const DEACTIVATE_SMS_MFA_URL = "/auth/sms_twilio/deactivate/";
export const PROVIDE_MFA_CODE_URL = "/auth/login/code/";

export const POTENTIAL_SONG_PAIR_URL = "/pairgen";

export const REFRESH_TRIGGER = TWO_MINUTES_MS;

export const staticUserDetails: IUser = {
  id: "1962f6d5-5219-4e8e-a2b7-10cdf7a72804",
  username: "Placeholder Username",
  email: "placeholderemail@test.com",
  name1: "Placeholder",
  name2: "Username",
  phoneNumber: "1 (639) 123-3456",
  avatarURL: TEMP_AVATAR_URL,
  artists: artists.map((artist: Artist) => artist.id),
  songs: overall_collection_starstuff.map((song: ISong) => song.id),
  events: events.map((event: Event) => event.id),
  venues: venues.map((venue: Venue) => venue.id),
  songCollections: songCollections.map((songCollection: ISongCollection) => songCollection.id),
  settings: {
    MFAEnabled: false,
    MFAType: "None",
    defaultTheme: "Light",
    defaultDisplayMode: LIST,
    defaultLanguage: LANGUAGE__ENGLISH,
    setLanguageUsingBrowserSetting: false,
    itemsPerPage: DATA_ITEMS_PER_PAGE,
    musicProcessingSettings: {
      BPMMatchInterval: 10,
      matchParallelKeySigModulation: false,
    }
  }
};

export interface IAuthContext {
  authContextState: IAuthContextState;
  getAccessToken(): string | null;
  setUser(user: Partial<IUser> | undefined): void;
  getUser(): IUser | undefined;
  editUser(args: Partial<IUser>): any;
  register(args: IRegistrationArgs): any;
  login(args: ILoginArgs): any;
  logout(): void;
  requestPasswordReset(args: IRequestResetArgs): any;
  resetPassword(args: IResetPasswordArgs): any;
  activateMFA(MFAType: MFAType): any;
  confirmMFA(MFAType: MFAType, code: string): any;
  deactivateMFA(MFAType: MFAType): any;
  provideMFACode(code: string, epmeheralToken: string): any;
  getPotentialSongPairs(): any;
}

export const initAuthContextValue: IAuthContext = {
  authContextState: {
    loginCheckComplete: false,
    potentialSongPairs: undefined,
    user: undefined,
  },
  getAccessToken: () => null,
  setUser: (user: Partial<IUser> | undefined) => {},
  getUser: (): IUser | undefined => undefined,
  editUser: (args: Partial<IUser>) => {},
  register: (args: IRegistrationArgs) => {},
  login: (args: ILoginArgs) => {},
  logout: () => {},
  requestPasswordReset: (args: IRequestResetArgs) => {},
  resetPassword: (args: IResetPasswordArgs) => {},
  activateMFA: (MFAType: MFAType) => {},
  confirmMFA: (MFAType: MFAType, code: string) => {},
  deactivateMFA: (MFAType: MFAType) => {},
  provideMFACode: (code: string, epmeheralToken: string) => {},
  getPotentialSongPairs: () => undefined,
}

export interface IAuthContextState {
  loginCheckComplete: boolean;
  user?: IUser;
  potentialSongPairs?: ISongPair[];
  ephemeralUserInfo?: {
    MFAType: MFAType;
  }
}

export interface IRegistrationArgs {
  email: string;
  name1: string;
  name2: string;
  company?: string;
  phoneNumber?: string;
  password: string;
  passwordConfirmation: string;
}

export interface ILoginArgs {
  email: string,
  password: string,
}

export interface IResetPasswordArgs {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
}

export interface IRequestResetArgs {
  email: string;
}

interface IBackEndUser {
  email: string
  first_name: string;
  last_name: string;
  phone_number?: string;
  company?: string;
  mfa_method: BackendMFAType;
}

export interface IAccessTokenClaims {
  exp: number; // -> Expiry datetime
  jti: string; // -> JWT Identifier - DEV NOTE -> Explanation for this claim can be found here: https://drive.google.com/file/d/1zZD_6cW-2_tSUkLatxqW4D1cerF5SpP7/view?usp=sharing
  token_type: "access";
  user_id: string;
  iss?: string; // -> Issuer
  sub?: string; // -> Subject
  aud?: [string]; // -> Audience
  nbf?: number; // -> Not Before
  iat?: number; // -> Issued at
}

export const FEtoBERegistrationArgs = (registrationArgs: IRegistrationArgs) => {
  const newObj: any = {};
  const FIRST_NAME_ARG = "first_name";
  const LAST_NAME_ARG = "last_name";
  const PASSWORD_1_ARG = "password1";
  const PASSWORD_2_ARG = "password2";
  const PHONE_NUMBER_ARG = "phone_number";

  const {
    email, name1, name2, password, passwordConfirmation,
    company, phoneNumber
  } = registrationArgs;

  newObj.email = email;
  newObj[FIRST_NAME_ARG] = name1;
  newObj[LAST_NAME_ARG] = name2;
  newObj[PASSWORD_1_ARG] = password;
  newObj[PASSWORD_2_ARG] = passwordConfirmation;
  if (company) newObj.company = company;
  if (phoneNumber) newObj[PHONE_NUMBER_ARG] = phoneNumber;

  return newObj;
}

export const FEtoBEUserKeys = (frontEndUserObj: Partial<IUser>) => {
  const newObj: any = {};
  const FIRST_NAME_ARG = "first_name";
  const LAST_NAME_ARG = "last_name";

  const { email, name1, name2, ...rest } = frontEndUserObj;

  newObj[FIRST_NAME_ARG] = name1;
  newObj[LAST_NAME_ARG] = name2;
  newObj.email = email;

  return Object.assign(newObj, {...rest});
}

export const BEtoFEUserKeys = (backEndUserObj: IBackEndUser): IUser => {
  const newObj: any = {};
  const NAME_ONE_ARG = "name1";
  const NAME_TWO_ARG = "name2";
  const PHONE_NUMBER_ARG = "phoneNumber"
  let MFAType: MFAType = "None";

  const {
    email, first_name, last_name, phone_number, mfa_method, ...rest
  } = backEndUserObj;

  if (mfa_method) {
    if (mfa_method === "email") MFAType = "Email";
    else if (mfa_method === "sms_twilio") MFAType = "SMS"
  }

  newObj.email = email;
  newObj[NAME_ONE_ARG] = first_name;
  newObj[NAME_TWO_ARG] = last_name;
  newObj[PHONE_NUMBER_ARG] = phone_number;
  newObj.MFAType = MFAType;

  return Object.assign(newObj, {...rest});
}

// - DEV NOTE -> Expiry time for the JWT is set in seconds, convert to milliseconds.
export const processBackEndExpiryDateTime = (expiryDateTime: number) => expiryDateTime * 1000;
