// -> Beyond Codebase
import React, { useContext } from 'react';
import { css } from 'aphrodite-jss';
// -> Within Codebase
import Spacer from '../../../LayoutUtilities/Spacer/Spacer';
import ImageWrapper from 'src/Components/VisualUtilities/ImageWrapper/ImageWrapper';
import { ImageFileIcon } from 'src/Components/VisualUtilities/IconPresets';
import { UIContext, ThemeInfo } from '../../../UI_InfoProvider/UI_InfoProvider';
import { HORIZONTAL, ROUNDED } from '../../../../constants';
// import { ITypeAheadSearchItem } from '../../stateManagement';
// -> Within Component
import { styleGen } from './TypeAheadSearchItemStyles';
import { calibrateComponent, DEFAULT_IMAGE_SIZE, DEFAULT_SEARCH_LIST_ITEM_HEIGHT, ITypeAheadSearchItemProps } from './helpers';

const TypeAheadSearchItem: React.FC<ITypeAheadSearchItemProps> = (props) => {
  const { id, label, disabled, imageURL, onSelectItem, noImageModeEnabled, item } = props;

  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const styles = styleGen(props, themeInfo);
  const { borderRadii, distance, palette } = themeInfo;
  const { fallbackIconCradle, searchResultImageCradle } = styles;
  const {
    typeAheadSearchItemCradleStylechain, innerCradleStylechain, searchItemTextStylechain
  } = calibrateComponent(props, themeInfo, styles);

  const computedOnClick = disabled ? () => {} : () => onSelectItem(item);

  return (
    <div className={typeAheadSearchItemCradleStylechain} onClick={computedOnClick}>
      <div className={innerCradleStylechain}>
        {
          (noImageModeEnabled) ? null : (
            <div className={css(searchResultImageCradle)}>
              {
                (imageURL) ? (
                  <ImageWrapper
                    alt={"Search Result Image"} src={imageURL}
                    borderGeometry={ROUNDED}
                    height={DEFAULT_IMAGE_SIZE} width={DEFAULT_IMAGE_SIZE}
                    customImgStyles={{ borderRadius: borderRadii.two }}
                    customImgCradleStyles={{ borderRadius: borderRadii.two }}
                  />
                ) : (
                  <div className={css(fallbackIconCradle)}>
                    <ImageFileIcon color={palette.grey2} size={DEFAULT_IMAGE_SIZE - 15} />
                  </div>
                )
              }
            </div>
          )
        }

        <Spacer direction={HORIZONTAL} amount={distance.three} />
        <p className={searchItemTextStylechain}>{label}</p>
      </div>
    </div>
  );
}

TypeAheadSearchItem.defaultProps = {
  height: DEFAULT_SEARCH_LIST_ITEM_HEIGHT,
}

export default TypeAheadSearchItem;
