export const BANDCAMP = "Bandcamp";
export const SOUNDCLOUD = "SoundCloud";
export const SPOTIFY = "Spotify";
export const APPLE_MUSIC = "Apple Music";
export const TIDAL = "Tidal";
export const BEATPORT = "Beatport";
export const VK = "VKontakte";
export const DEEZER = "Deezer";
export const YOUTUBE = "YouTube";
export const WEBSITE = "Website";
export const INSTAGRAM = "Instagram";
export const FACEBOOK = "Facebook";
export const TWITTER = "Twitter";
export const SLACK = "Slack";
export const EMAIL = "Email";
export const DICE = "Dice";
export const EVENTBRITE = "Eventbrite"

// -> Object keys
export const BANDCAMP_KEY = "bandCamp";
export const SOUNDCLOUD_KEY = "soundCloud";
export const SPOTIFY_KEY = "spotify";
export const APPLE_MUSIC_KEY = "appleMusic";
export const TIDAL_KEY = "tidal";
export const BEATPORT_KEY = "beatPort";
export const VK_KEY = "vk";
export const DEEZER_KEY = "deezer";
export const YOUTUBE_KEY = "youTube";
export const GOOGLE_DRIVE_KEY = "googleDrive";
export const WEBSITE_KEY = "website";
export const INSTAGRAM_KEY = "instagram";
export const FACEBOOK_KEY = "facebook";
export const TWITTER_KEY = "twitter";
export const SLACK_KEY = "slack";
export const EMAIL_KEY = "email";
export const DICE_KEY = "dice";
export const EVENTBRITE_KEY = "eventbrite";

// -> HOME PAGES
export const BANDCAMP_HOME_URL = "https://bandcamp.com/";
export const SOUNDCLOUD_HOME_URL = "https://soundcloud.com/discover";
export const SPOTIFY_HOME_URL = "https://www.spotify.com";
export const APPLE_MUSIC_HOME_URL = "https://music.apple.com";
export const TIDAL_HOME_URL = "https://tidal.com/";
export const BEATPORT_HOME_URL = "https://www.beatport.com/";
export const VK_HOME_URL = "https://vk.com/";
export const DEEZER_HOME_URL = "https://www.deezer.com";
export const YOUTUBE_HOME_URL = "https://www.youtube.com/";
export const GOOGLE_DRIVE_HOME_URL = "https://drive.google.com";
export const WEBSITE_HOME_URL = "https://google.com";
export const INSTAGRAM_HOME_URL = "https://www.instagram.com";
export const FACEBOOK_HOME_URL = "https://www.facebook.com";
export const TWITTER_HOME_URL = "https://twitter.com/home";
export const SLACK_HOME_URL = "https://slack.com";
export const EMAIL_HOME_URL = "https://google.com";
export const DICE_HOME_URL = "https://dice.fm/";
export const EVENTBRITE_HOME_URL = "https://eventbrite.com"
