// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Input from "../../../../Components/FormUtilities/Input/Input";
import Button from "../../../../Components/Button/Button";
import FloatingCircularLogo from "../../../../Components/VisualUtilities/FloatingCircularLogo/FloatingCircularLogo";
import { IResetPasswordFormArgs } from "../../helpers";
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { ROUNDED, VERTICAL } from "../../../../constants";
// -> Within component
import { styleGen } from "./ResetPasswordCardStyles";
import { IResetPasswordCardProps, IResetPasswordFormState } from "./helpers";
import Spacer from "src/Components/LayoutUtilities/Spacer/Spacer";

const initialValues: IResetPasswordFormState = { password: "", passwordConfirmation: "" };

const ResetPasswordCard: React.FC<IResetPasswordCardProps> = (props) => {
  const { t } = useTranslation("page_resetPassword");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { onPasswordResetSubmission } = props;
  const {
    cardCradle, topBarCradle, cardBottomCradle, promptText, formCradle
  } = styleGen(themeInfo);
  
  const validationSchema = yup.object({
    password: yup.string().required(t("passwordRequiredErrorMsg")),
    passwordConfirmation: yup.string().oneOf(
      [yup.ref("password"), ""],
      t("passwordConfirmationMatchErrorMsg")
    ).required(t("passwordConfirmationRequiredErrorMsg")),
  });

  const onFormSubmission = (values: IResetPasswordFormArgs) => onPasswordResetSubmission(values);
  
  return (
    <div className={css(cardCradle)}>
      {/* TOP BAR */}
      <div className={css(topBarCradle)}>
        <FloatingCircularLogo />
        <Spacer direction={VERTICAL} amount={distance.four} />
        <p className={css(promptText)}>{t("cardMessage")}</p>
      </div>
      {/* MAIN CARD CONTENT */}
      <div className={css(cardBottomCradle)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFormSubmission}
        >
          {
            (formik) => (
              <Form className={css(formCradle)}>
                <Spacer direction={VERTICAL} amount={distance.two} />
                <Input
                  required
                  name="password"
                  label={t("newPasswordInputLabel")}
                  width="100%"
                  type="password"
                  borderGeometry={ROUNDED}
                  mode="border"
                  customInputCradleStyles={{ height: 35 }}
                />
                <Spacer direction={VERTICAL} amount={distance.two} />

                <Input
                  required
                  name="passwordConfirmation"
                  label={t("newPasswordConfirmationInputLabel")}
                  type="password"
                  width="100%"
                  borderGeometry={ROUNDED}
                  mode="border"
                  customInputCradleStyles={{ height: 35 }}
                />
                <Spacer direction={VERTICAL} amount={distance.two} />

                <Button
                  buttonText={t("resetPasswordBtnLabel")}
                  onClick={() => {}}
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                  customTextStyles={{ fontSize: '1.6rem', fontWeight: 'bold' }}
                  customCradleStyles={{
                    paddingTop: themeInfo.distance.one,
                    paddingBottom: themeInfo.distance.one,
                    paddingRight: themeInfo.distance.two,
                    paddingLeft: themeInfo.distance.two,
                    width: "100%",
                    height: 35,
                  }}
                />
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
}

export default ResetPasswordCard;
