// -> Beyond codebase
import React, { useContext, useState, useRef, useEffect } from "react";
import * as yup from "yup";
import { Formik, Form, FormikValues, FormikProps } from "formik";
import { css } from "aphrodite-jss";
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import { FuseOptions } from "fuse.js";
// import throttle from "lodash.throttle";
// -> Within codebase
import TextArea from "src/Components/FormUtilities/TextArea/TextArea";
import Fade from "../../AnimationUtilities/TransitionWrappers/Fade/Fade";
import Input from "../../FormUtilities/Input/Input";
import Button from "../../Button/Button";
import LineSegment from "../../VisualUtilities/LineSegment/LineSegment";
import IconButton from "../../Button/Variants/IconButton/IconButton";
import PlusIcon from "../../VisualUtilities/IconPresets/PlusIcon";
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import ExternalLinkEntry from "../../FormUtilities/ExternalLinkEntry/ExternalLinkEntry";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import TypeAheadSearch from "src/Components/TypeAheadSearch/TypeAheadSearch";
import TypeAheadChosenResult from "../../FormUtilities/TypeAheadChosenResult/TypeAheadChosenResult";
import { externalArtistLinkDropdownOptions as externalLinkDropdownOptions } from "../../FormUtilities/ExternalLinkEntry/helpers";
import { LinkIcon, PersonIcon } from "../../VisualUtilities/IconPresets";
import { UIContext, ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../DataContextProvider/DataContextProvider";
import { Artist, IPotentialExternalLinkEntry, Event } from "../../../Types";
import {
  DEFAULT_TRANSITION_MICROANIMATION_TIME, HORIZONTAL, ROUND, URL_PATTERN_REGEX,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ALLOWED_IMAGE_UPLOAD_FILE_TYPES, VERTICAL, DEFAULT_SEARCH_DEBOUNCE_TIME, DEFAULT_INPUT_HEIGHT, ROUNDED,
} from "../../../constants";
import { customAlertGen } from "../../../helpers";
import { IAddArtistArgs } from "../../../Pages/AddArtist/helpers";
import { ITypeAheadSearchItem } from "src/Components/TypeAheadSearch/stateManagement";
import { processTypeAheadSearchItems } from "src/Components/TypeAheadSearch/helpers";
import { getEventFromID } from "src/lib";
// -> Within component
import {
  determineSubmissionButtonStatus, hydrateExternalLinkEntries,
  IAddOrEditArtistFormProps, IAddOrEditArtistFormState
} from "./helpers";
import { styleGen } from "./AddOrEditArtistFormStyles";

const initialValues: Partial<Artist> = {
  name: "",
  imageURL: "",
  description: "",
};

const AddArtistForm: React.FC<IAddOrEditArtistFormProps> = (props) => {
  const [state, setState] = useState<IAddOrEditArtistFormState>({
    links: {},
    manualFormFieldsValid: true,
    microAnimationTimeoutIDs: [],
    artistLinksPlaceholderSurfaceInStatus: true,
    artistEvents: [],
    availableSearchedEvents: [],
    eventSearchTerm: "",
    eventSearchActive: false,
  });
  const inputRef = useRef<HTMLInputElement>(null);
  let artistLinksPlaceholderSurfaceTimeout = useRef<any>(null);
  let resultsTimeout = useRef<any>(null);
  let formRef = useRef<FormikProps<FormikValues>>(null);
  const { onSubmit, formMode, artistDetails: artistDetailsFromProps } = props;
  const { t } = useTranslation((formMode === "ADD") ? "page_addArtist" : "page_editArtist");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, palette, styles, transitions, shadows } = themeInfo;
  const { state: { events }} = useContext(DataContext);
  const {
    formCradle, topBarCradle, titleText, customFormCradleStyles, customFormTitleTextStyles,
    addImagePromptSurface, addInfoPromptText, circularIconCradle, nameCradle,
    artistLinksCradle, bottomFormRow, artistLinksTitleText, artistLinksTopRow, itemSeparatorCradle,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    artistLinksPlaceholderSurface, descriptionCradle, artistEventsCradle, labelCradle, labelText,
    artistEventPreviewsCradle, artistEventsTitleText
  } = styleGen(themeInfo, props);

  // -----

  // -> If form is in editing mode, add pre-existing information
  useEffect(() => {
    if (formMode === "EDIT" && props.artistDetails) {
      const { artistDetails, artistDetails: {
        events: eventIDsFromProps, links: linksFromProps,
      }} = props;
      const { name, description  } = artistDetails;
      
      // -> Retrieve event info from IDs if there are any
      let retrievedEvents: Event[] = [];
      if (eventIDsFromProps) {
        eventIDsFromProps.map((eventID: string) => {
          const retrievedEvent = getEventFromID(eventID, events);
          if (retrievedEvent) retrievedEvents.push(retrievedEvent);
          return "";
        });
        if (retrievedEvents.length !== 0) {
          setState((prevState) => ({ ...prevState, artistEvents: retrievedEvents }));
        }
      }

      // -> Set Formik fields
      if (formRef.current) {
        formRef.current.setFieldValue('name', name);
        formRef.current.setFieldValue('description', description);
      }
      
      // -> Put together external link entries
      if (linksFromProps) {
        // console.log(`artist links -> ${JSON.stringify(linksFromProps, null, 2)}`);
        const potentialLinkEntries = hydrateExternalLinkEntries(linksFromProps);
        // console.log(`external link entries -> ${JSON.stringify(potentialLinkEntries, null, 2)}`);
        setState((prevState) => ({ ...prevState, potentialExternalLinks: potentialLinkEntries}));
      }
    } 
  }, [events, formMode, props])

  // -----
  
  const validationSchema = yup.object({
    name: yup.string().required(t("artistNameRequiredErrorMsg")),
    description: yup.string(),
    imageURL: yup.string().matches(URL_PATTERN_REGEX, t("validURLErrorMsg")),
  });

  // -----

  // -> Clear microanimation timeouts when component unmounts to avoid memory leaks
  useEffect(() => {
    return () => {
      state.microAnimationTimeoutIDs.map((timeoutID) => clearTimeout(timeoutID));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(resultsTimeout.current);
      clearTimeout(artistLinksPlaceholderSurfaceTimeout.current)
    }
  }, [state]);

  // -----

  // - TODO: -> Prepare image file for upload to backend system
  const onFormSubmission = (values: FormikValues, onSubmitProps: any) => {
    const {
      imageToUpload, potentialExternalLinks, manualFormFieldsValid,
      artistEvents,
    } = state;
    const { name, imageURL, description } = values;
    const { resetForm, setSubmitting } = onSubmitProps;

    // -> Run validation on manually controlled form fields. If any of them turn up a
    //    validation error, then return early.
    if (potentialExternalLinks) {
      for (let idx = 0; idx < potentialExternalLinks.length - 1; ++idx) {
        validateExternalLinkEntryURL(idx, potentialExternalLinks[idx].URL); 
      }

      if (manualFormFieldsValid === false) return;
    }

    const addArtistArgs: IAddArtistArgs = {
      artist: {
        name: name.trim(),
        imageURL: imageURL.trim(),
        description: description.trim,
        links: {},
        events: [],
      },
      imageToUpload: null,
    };

    if (potentialExternalLinks) {
      potentialExternalLinks.map((potentialExternalLink: IPotentialExternalLinkEntry) => {
        const { linkName, URL } = potentialExternalLink;

        if ((linkName !== "") && (URL !== "") && addArtistArgs.artist.links) {
          addArtistArgs.artist.links[linkName] = URL.trim();
        }

        return true; // -> TS complains if you don't return anything here.
      });
    }

    if (artistEvents) {
      artistEvents.map((event: Event) => {
        addArtistArgs.artist.events && addArtistArgs.artist.events.push(event.id);
        return ""; // -> TS Complains if you don't return something
      });
    }

    if (imageToUpload) {
      let formData = new FormData();
      formData.append('image', imageToUpload);
      addArtistArgs.imageToUpload = formData;
    }

    console.log(`[AddOrEditArtist]: submission arguments -> ${JSON.stringify(addArtistArgs, null, 1)}`);
    
    onSubmit(addArtistArgs);
    setSubmitting(false);
    resetForm();
  };

  // -----

  const onClickToSelectImage = () => inputRef.current?.click();

  const onSelectImage = () => {
    if (inputRef.current && inputRef.current.files && inputRef.current.files[0]) {
      const file = inputRef.current.files[0];
      const fileType = file.type;
      // console.log(`[AddArtistForm]: File name chosen -> ${inputRef.current.files[0].name}`);
      // console.log(`[AddArtistForm]: File type chosen -> ${inputRef.current.files[0].type}`);
      // console.log(`[AddArtistForm]: File size chosen -> ${inputRef.current.files[0].size}`);
      if (!ALLOWED_IMAGE_UPLOAD_FILE_TYPES.includes(fileType)) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        customAlertGen(t("imgFormatErrorMsg"), themeInfo);
      } else {
        const fileObjURL = URL.createObjectURL(file);
        setState((prevState) => ({
          ...prevState,
          imageToUpload: file,
          imageUploadPreviewURL: fileObjURL
        }))
      }
    }
  };

  // -----

  const addExternalLinkEntry = () => {
    const stateCopy = cloneDeep(state);
    const potentialExternalLinkEntry: IPotentialExternalLinkEntry = {
      linkName: "",
      URL: "",
      URLFieldTouched: false,
      URLFieldDirty: false,
      errorStatus: false,
      errorMessage: "Please enter a valid URL",
      microAnimationInStatus: true,
    };

    if (stateCopy.potentialExternalLinks) {
      stateCopy.potentialExternalLinks.push(potentialExternalLinkEntry);
    } else {
      stateCopy.potentialExternalLinks = [];
      stateCopy.potentialExternalLinks.push(potentialExternalLinkEntry);
    }

    // - TODO: -> Fix this microanimation such that the placeholder surface has time to fade out
    //            before the first potential link entry element fades in.
    if ((!stateCopy.potentialExternalLinks) || (stateCopy.potentialExternalLinks.length === 0)) {
      setState((prevState) => ({ ...prevState, artistLinksPlaceholderSurfaceInStatus: false }));
      artistLinksPlaceholderSurfaceTimeout.current = setTimeout(() => {
        setState(stateCopy);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    } else {
      setState(stateCopy);
    }
  };

  // -----

  // - DEV NOTE -> For whatever reason, the state I clone on the first line of the function already has
  //               the potential link entry missing from the array. As a stop gap, I pass in a copy of the
  //               former state in order to manipulate the boolean which will trigger the fade-out microanimation
  //               in the list item.
  const removeExternalLinkEntry = (indexToDelete: number, prevState: IAddOrEditArtistFormState) => {
    const stateCopy = cloneDeep(state);
    
    if (stateCopy.potentialExternalLinks && prevState.potentialExternalLinks) {
      prevState.potentialExternalLinks[indexToDelete].microAnimationInStatus = false;
      setState((prevState) => ({ ...prevState, potentialExternalLinks: prevState.potentialExternalLinks }));
      const timeOut = setTimeout(() => {
        // - DEV NOTE -> Inner anonymous function seems to need a check on if this is undefined or not as well
        if (stateCopy.potentialExternalLinks) {
          stateCopy.potentialExternalLinks =
            stateCopy.potentialExternalLinks.filter((_, index: number) => {
            return index !== indexToDelete;
          });
        }
        stateCopy.microAnimationTimeoutIDs.push(timeOut);
        setState(stateCopy);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  };

  // -----

  const validateExternalLinkEntryURL = (index: number, URLEntry: string): boolean => {
    // console.log(`[AddArtistForm]: validateExternalLinkEntryURL(): URL Entry -> ${URLEntry}`);
    // console.log(`[AddArtistForm]: validateExternalLinkEntryURL(): URL Valid? -> ${URL_PATTERN_REGEX.test(URLEntry)}`);
    const stateCopy = cloneDeep(state);
    
    if (!stateCopy.potentialExternalLinks) return true;
    
    if (URL_PATTERN_REGEX.test(URLEntry) === false) {
      // console.log(`[AddArtistForm]: validateExternalLinkEntryURL(): URL was invalid`);
      if (stateCopy.manualFormFieldsValid) stateCopy.manualFormFieldsValid = false;
      stateCopy.potentialExternalLinks[index].errorStatus = true;
      // console.log(`[AddArtistForm]: validateExternalLinkEntryURL(): modified state copy after invalid field -> ${JSON.stringify(stateCopy, null, 1)}`);
      setState((_) => ({ ...stateCopy }));
      return false;
    }

    // - TODO: -> Review this after some time, seems sketch
    if (stateCopy.manualFormFieldsValid === false) {
      let anyFieldsHaveAnError = false;
      stateCopy.potentialExternalLinks.map((potentialExternalLink: IPotentialExternalLinkEntry) => {
        if (potentialExternalLink.errorStatus === true) anyFieldsHaveAnError = true;
        return true;
      });
  
      if (!anyFieldsHaveAnError)  stateCopy.manualFormFieldsValid = true;
    }

    setState((_) => ({ ...stateCopy }));
    return true;
  };

  // -----

  const onSelectEvent = (item: ITypeAheadSearchItem) => {
    const { id: eventID } = item;
    const stateCopy = cloneDeep(state);
    const event = getEventFromID(eventID, events);
    if (event) stateCopy.artistEvents.push(event);
    stateCopy.availableSearchedEvents = [];
    stateCopy.eventSearchTerm = "";
    setState({ ...stateCopy });
  };

  // -----

  const onRemoveEvent = (eventID: string) => {
    const stateCopy = cloneDeep(state);
    const { artistEvents } = stateCopy;
    const index = artistEvents.findIndex((event: Event) => event.id === eventID);
    stateCopy.artistEvents.splice(index, 1);
    setState(() => ({ ...stateCopy }));
  }
  
  // -----

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { eventSearchActive } = state;
    const searchTerm = event.target.value;
    if (!eventSearchActive) setState((prevState) => ({ ...prevState, eventSearchActive: true }))
    setState((prevState) => ({ ...prevState, eventSearchTerm: searchTerm }));
    // throttle(() => {

    // }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    // resultsTimeout.current = setTimeout(() => {
    // - TODO: -> Eventually this will be an async server call
    // setTimeout(() => {
    // }, 1500);
    const candidateEvents = searchForEvents(searchTerm, events);
    // console.log(`handleSearchInputChange(): candidate events -> ${JSON.stringify(candidateEvents, null, 4)}`);
    const availableSearchedEvents = processTypeAheadSearchItems(candidateEvents);
    setState((prevState) => ({ ...prevState, availableSearchedEvents, eventSearchActive: false }));
  };

  // -----

  const searchForEvents = (searchTerm: string, events: Event[]): Event[] => {
    let eventSearchResults: any[] = [];
    if (searchTerm === "") return [];
    
    const searchEngineOptions: FuseOptions<Event> = {
      keys: ["name"],
      shouldSort: true, // -> Results sorted by relevance score
      findAllMatches: true,
      threshold: 0.4, // -> This is the default value, it can be tweaked from 0 to 1
    };
  
    const fuse = new Fuse(events, searchEngineOptions);
  
    eventSearchResults = fuse.search(searchTerm);
    const availableSearchedEvents: ITypeAheadSearchItem[] = processTypeAheadSearchItems(eventSearchResults);

    setState((prevState) => ({ ...prevState, availableSearchedEvents }));

    return eventSearchResults;
  };

  // -----

  // let linkKeys: string[];
  // let linkValues: any[];

  // const { links, potentialExternalLinks } = state;
  const {
    potentialExternalLinks, imageUploadPreviewURL, eventSearchTerm,
    artistLinksPlaceholderSurfaceInStatus, availableSearchedEvents,
    artistEvents, eventSearchActive,
  } = state;
  // if (links) {
  //   linkKeys = Object.keys(links);
  //   linkValues = Object.values(links);
  // }
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmission}
      innerRef={formRef}
    >
      {
        ({ isValid, dirty, isSubmitting, setFieldValue }) => (
          <Form className={css(formCradle, customFormCradleStyles)}>
            {/* TITLE */}
            <div className={css(topBarCradle)}>
              <p className={css(titleText, customFormTitleTextStyles)}>{t("pageTitle")}</p>
            </div>

            <Spacer direction={VERTICAL} amount={distance.three} />
            <LineSegment direction={HORIZONTAL} width={"100%"} size={2} color={palette.black} />
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* IMAGE UPLOAD */}
            <div className={`${css(addImagePromptSurface)} button`} onClick={onClickToSelectImage}>
              {
                // - DEV NOTE -> Edge case that there is no new image primed for upload and that the form
                //               is in edit mode/there's a pre-existing image to show.
                ((!imageUploadPreviewURL) && (formMode === "EDIT" && artistDetailsFromProps && artistDetailsFromProps.imageURL)) ? (
                  <ImageWrapper
                    alt={t(`Artist: ${artistDetailsFromProps.name}`)} src={artistDetailsFromProps.imageURL}
                    borderGeometry={ROUNDED}
                    height={85} width={85}
                  />
                ) : (
                  <>
                    {
                      (imageUploadPreviewURL) ? (
                        <ImageWrapper
                          alt={t("artistImageUploadPreviewAltText")} src={imageUploadPreviewURL}
                          borderGeometry={ROUNDED}
                          height={85} width={85}
                        />
                      ) : (
                        <div className={css(circularIconCradle)}>
                          <PersonIcon color={palette.grey6} size={50} />
                        </div>
                      )
                    }
                  </>
                )
              }
              <Spacer direction={VERTICAL} amount={distance.three} />
              <p className={css(addInfoPromptText)}>{`+ ${t("addArtistImagePrompt")}`}</p>
              <input type="file" onChange={onSelectImage} ref={inputRef} hidden />
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* IMAGE URL AND ARTIST NAME */}
            <div className={css(nameCradle)}>
              {/* <Input
                name="imageURL"
                label={t("imageURLInputLabel")}
                mode="border"
                customInputTextStyles={{ fontSize: "1.4rem" }}
                customControlCradleStyles={{ width: "100%" }}
              /> */}

              <Input
                name="name"
                label={t("artistNameInputLabel")}
                mode="border"
                required
                customInputTextStyles={{ fontSize: "1.4rem" }}
                customControlCradleStyles={{  width: "100%", }}
              />
            </div>
            <Spacer direction={VERTICAL} amount={distance.two} />

            {/* DESCRIPTION */}
            <div className={css(descriptionCradle)}>
              <TextArea
                name="description"
                mode="border"
                label={t("descriptionLbl")}
                // width="100%"
                maxWidth="100%"
                // height={90}
                customControlCradleStyles={{ width: "100%" }}
                customTextAreaCradleStyles={{ resize: "vertical", height: 75, fontSize: "1.4rem" }}
              />
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />


            {/* ARTIST EVENTS */}
            <div className={css(artistEventsCradle)}>
              <div className={css(labelCradle)}>
                <p className={css(artistEventsTitleText)}>{t("artistEventsLbl")}</p>
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />
              
              <TypeAheadSearch
                loadingStatus={eventSearchActive}
                searchTerm={eventSearchTerm}
                handleSearchInputChange={handleSearchInputChange}
                onSelectItem={onSelectEvent}
                items={availableSearchedEvents}
                customBaseCradleStyles={{ height: "100%", width: "100%" }}
                customTypeAheadSearchCradleStyles={{ height: "auto", width: "100%", paddingBottom: 0 }}
                noSearchResultsAvailableMsg={t("noEventSearchResultsAvailableMsg")}
                baseCradleHeight={DEFAULT_INPUT_HEIGHT}
                colorMode="Greyscale"
                showBaseCradleIcon={false}
              />
              <Spacer direction={VERTICAL} amount={distance.two} />

              <div className={css(artistEventPreviewsCradle)}>
                {
                  // eslint-disable-next-line array-callback-return
                  artistEvents.map((event: Event) => {
                    const { id, name, imageURL } = event;

                    return (
                      <TypeAheadChosenResult
                        key={id}
                        id={id}
                        name={name}
                        imageURL={imageURL}
                        onRemoveItem={() => onRemoveEvent(id)}
                      />
                    );
                  })
                }
              </div>
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* EXTERNAL LINKS */}
            <div className={css(artistLinksCradle)}>
              <div className={css(artistLinksTopRow)}>
                <p className={css(artistLinksTitleText)}>{t("externalLinksLabel")}</p>
                <Spacer direction={HORIZONTAL} amount={distance.four} />
                <IconButton
                  icon={<PlusIcon color={palette.white} size={styles.standardIconSize - 5} />}
                  onClick={addExternalLinkEntry}
                  buttonGeometry={ROUND}
                  disabled={
                    state.potentialExternalLinks &&
                    state.potentialExternalLinks.length >= externalLinkDropdownOptions.length
                  }
                  customCradleStyles={{
                    padding: 3,
                    backgroundColor: palette.primary,
                    transition: transitions.boxShadowTransition
                  }}
                  customCradleHoverStyles={{ boxShadow: shadows.one }}
                />
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />

              {
                (potentialExternalLinks && (potentialExternalLinks.length > 0)) ? (
                  potentialExternalLinks.map((potentialExternalLink: IPotentialExternalLinkEntry, index: number) => {
                    const {
                      linkName, URL, errorStatus, errorMessage, URLFieldTouched,
                      URLFieldDirty, microAnimationInStatus
                    } = potentialExternalLink;

                    return (
                      <div key={index}>
                        <Fade inStatus={microAnimationInStatus}>
                          <ExternalLinkEntry
                            state={state}
                            setState={setState}
                            index={index}
                            linkName={linkName}
                            URL={URL}
                            errorStatus={errorStatus}
                            errorMessage={errorMessage}
                            URLFieldTouched={URLFieldTouched}
                            URLFieldDirty={URLFieldDirty}
                            removeExternalLinkEntry={removeExternalLinkEntry}
                            validateExternalLinkEntryURL={validateExternalLinkEntryURL}
                            type="ARTIST"
                          />
                        </Fade>
                        {
                          (index < (potentialExternalLinks.length - 1)) ? (
                            <>
                              <Spacer direction={VERTICAL} amount={distance.two} />
                              <div className={css(itemSeparatorCradle)}>
                                <LineSegment
                                  direction={HORIZONTAL}
                                  width={`calc(100% - ${distance.six}px)`}
                                  size={1}
                                  color={palette.grey1}
                                />
                              </div>
                              <Spacer direction={VERTICAL} amount={distance.two} />
                            </>
                          ) : null
                        }
                      </div>
                    );
                  })
                ) : (
                  <Fade inStatus={artistLinksPlaceholderSurfaceInStatus}>
                    <div className={css(artistLinksPlaceholderSurface)} onClick={addExternalLinkEntry}>
                      <div className={css(circularIconCradle)}>
                        <LinkIcon color={palette.grey6} size={50} />
                      </div>
                      <Spacer direction={VERTICAL} amount={distance.three} />
                      <p className={css(addInfoPromptText)}>{`+ ${t("addExternalLinkPrompt")}`}</p>
                    </div>
                  </Fade>
                )
              }
            </div>

            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* FORM SUBMISSION */}

            <div className={css(bottomFormRow)}>
              <Button
                onClick={() => {}}
                buttonText={(formMode === "ADD") ? t("addArtistBtnLbl") : t("editArtistBtnLbl")}
                type="submit"
                disabled={isSubmitting || determineSubmissionButtonStatus(props, { isValid, dirty })}
                customTextStyles={{ fontSize: "1.6rem", fontWeight: "bold" }}
                customCradleStyles={{
                  paddingTop: distance.one,
                  paddingBottom: distance.one,
                  paddingRight: distance.two,
                  paddingLeft: distance.two,
                  height: 35,
                }}
              />
            </div>
          </Form>
        )
      }
    </Formik>
  );
}

export default AddArtistForm;

AddArtistForm.defaultProps = {
  isInCardMode: true,
}
// function FormikProps<T>() {
//   throw new Error("Function not implemented.");
// }

