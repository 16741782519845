// -> Beyond Codebase
import React, { useContext, useEffect, useState } from 'react';
import { css } from 'aphrodite-jss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// -> Within Codebase
import { IRegistrationArgs } from '../../Components/AuthContextProvider/helpers';
import FillUnderNavBarCradle from '../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle'
import VerticalFade from '../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade';
import Fade from '../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade';
import CircleLoader from '../../Components/Loaders/CircleLoader/CircleLoader';
import Spacer from '../../Components/LayoutUtilities/Spacer/Spacer';
import { XIcon } from '../../Components/VisualUtilities/IconPresets';
import { ThemeInfo, UIContext } from '../../Components/UI_InfoProvider/UI_InfoProvider';
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import { LOGIN_ROUTE, DEFAULT_TRANSITION_MICROANIMATION_TIME, VERTICAL } from '../../constants';
import { mapHttpErrorCodeToMessage, customAlertGen } from '../../helpers';
import RegistrationCard from "./InternalComponents/RegistrationCard/RegistrationCard";
// -> Within Component
import { styleGen } from './RegistrationStyles';
import {
  PAGE_STATE_NEW, PAGE_STATE_SUCCESS, IRegistrationState,
  REGISTRATION_SUCCESS_HANG_TIME_MS
} from "./helpers";
import StatusMessage from '../../Components/VisualUtilities/StatusMessage/StatusMessage';

// - TODO: -> Hook this component up full stack to a GraphQL mutation that can carry out registrations.
const Registration: React.FC = () => {
  const [state, setState] = useState<IRegistrationState>({
    loadingStatus: false,
    errorStatus: false,
    cardInStatus: true,
    successCardInStatus: false,
    loaderInStatus: false,
    pageState: PAGE_STATE_NEW,
  })
  const history = useHistory();
  const { t } = useTranslation("page_registration")
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { register } = useContext(AuthContext);
  const { pageCradle, mainContentCradle } = styleGen(themeInfo);

  let registrationCardTimeout: ReturnType<typeof setTimeout>;
  let loaderTimeout: ReturnType<typeof setTimeout>;
  let registrationSuccessTimeout: ReturnType<typeof setTimeout>;
  let successCardTimeout: ReturnType<typeof setTimeout>;

  // -> Clean up timeouts to avoid memory leaks.
  useEffect(() => {
    return () => {
      clearTimeout(registrationCardTimeout);
      clearTimeout(loaderTimeout);
      clearTimeout(registrationSuccessTimeout);
      clearTimeout(successCardTimeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----

  const navigateToLogin = () => {
    setState((prevState) => ({ ...prevState, cardInStatus: false }));
    registrationCardTimeout = setTimeout(() => {
      history.push(LOGIN_ROUTE);
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  }

  // -----

  const onRegistrationSubmission = async (values: IRegistrationArgs) => {
    // -> Allow registration card to transition down before loading spinner is shown
    setState((prevState) => ({ ...prevState, cardInStatus: false }));
    registrationCardTimeout = setTimeout(() => {
      setState((prevState) => ({ ...prevState, loadingStatus: true, loaderInStatus: true, }));
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);

    // -> SUBMIT REGISTRATION INFORMATION TO THE SERVER
    const { error } = await register(values);

    if (error) {
      const errorMessage = mapHttpErrorCodeToMessage(error);

      setState((prevState) => ({ ...prevState, errorStatus: true, loaderInStatus: false, errorMessage }))
      loaderTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loadingStatus: false, cardInStatus: true }));
        customAlertGen(errorMessage, themeInfo);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
    
    else {
      setState((prevState) => ({ ...prevState, loaderInStatus: false }));
      loaderTimeout = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          successCardInStatus: true,
          pageState: PAGE_STATE_SUCCESS,
          loadingStatus: false,
        }));

        // - TODO: -> Somehow interpolate the progression of this timeout period to the progression
        //            of a circular progress indicator
        registrationSuccessTimeout = setTimeout(() => {
          setState((prevState) => ({ ...prevState, successCardInStatus: false }));
          successCardTimeout = setTimeout(() => {
            history.push(LOGIN_ROUTE);
          }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
        }, REGISTRATION_SUCCESS_HANG_TIME_MS);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  };

  // -----

  const {
    loadingStatus, errorStatus, cardInStatus,
    successCardInStatus, loaderInStatus, pageState,
    errorMessage,
  } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {
          (!loadingStatus) ? (
            (pageState === PAGE_STATE_NEW) ? (
              <VerticalFade inStatus={cardInStatus}>
                <div className={css(mainContentCradle)}>
                  {
                    (errorStatus) && (
                      <>
                        <Fade>
                          <StatusMessage
                            color={themeInfo.palette.errorStatus}
                            headerText={t("unsuccessfulRegistrationMsg")}
                            messageText={errorMessage}
                            icon={<XIcon color={themeInfo.palette.errorStatus} size={40} />}
                          />
                        </Fade>
                        <Spacer direction={VERTICAL} amount={distance.three} />
                      </>
                    )
                  }
                  <RegistrationCard onLoginClick={navigateToLogin} onSubmit={onRegistrationSubmission} />
                </div>
              </VerticalFade>
            ) : (
              <VerticalFade inStatus={successCardInStatus}>
                <StatusMessage
                  headerText={t("successfulRegistrationHeaderMsg")}
                  messageText={t("successRegistrationMsg")}
                />
              </VerticalFade>
            )
          ) : (
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={themeInfo.palette.primary} />
            </Fade>
          )
        }
      </div>
    </FillUnderNavBarCradle>
  );
}

export default Registration;
