import { ThemeInfo } from "src/Components/UI_InfoProvider/UI_InfoProvider";
import { css } from "aphrodite-jss";
import { ADMIN_DASHBOARD_ROUTE, ARTISTS_ROUTE, EVENTS_ROUTE, PROFILE_ROUTE, SCREEN_WIDTH_450, VENUES_ROUTE } from "src/constants";

export interface IDrawerContentProps {
  toggleDrawer?(): void;
  closeDrawer(): void;
  backdropVisible: boolean;
  closeBackdrop(): void;
}

export interface IDrawerContentState {
  screenWidth: number;
}

export const calibrateComponent = (state: IDrawerContentState, styles: any, themeInfo: ThemeInfo) => {
  const { screenWidth } = state;
  const { palette } = themeInfo;
  const {
    itemRowActive, itemRow, itemRowText, itemRowTextActive
  } = styles;

  const computedAvatarImageSize = (screenWidth > SCREEN_WIDTH_450) ? 125 : 100;

  const currentURL = window.location.pathname;
  
  const profileOptionActiveStyle = (currentURL === PROFILE_ROUTE) ? itemRowActive : null;
  const profileOptionStyleChain = css(itemRow, profileOptionActiveStyle);
  const profileOptionActiveTextStyle = (currentURL === PROFILE_ROUTE) ? itemRowTextActive : null;
  const profileOptionTextStyleChain = css(itemRowText, profileOptionActiveTextStyle);
  const profileOptionIconColor = (currentURL === PROFILE_ROUTE) ? palette.white : palette.primary;
  
  const artistsOptionActiveStyle = (currentURL === ARTISTS_ROUTE) ? itemRowActive : null;
  const artistsOptionStyleChain = css(itemRow, artistsOptionActiveStyle);
  const artistsOptionActiveTextStyle = (currentURL === ARTISTS_ROUTE) ? itemRowTextActive : null;
  const artistsOptionTextStyleChain = css(itemRowText, artistsOptionActiveTextStyle);
  const artistsOptionIconColor = (currentURL === ARTISTS_ROUTE) ? palette.white : palette.primary;
  
  const eventsOptionActiveStyle = (currentURL === EVENTS_ROUTE) ? itemRowActive : null;
  const eventsOptionStyleChain = css(itemRow, eventsOptionActiveStyle);
  const eventsOptionActiveTextStyle = (currentURL === EVENTS_ROUTE) ? itemRowTextActive : null;
  const eventsOptionTextStyleChain = css(itemRowText, eventsOptionActiveTextStyle);
  const eventsOptionIconColor = (currentURL === EVENTS_ROUTE) ? palette.white : palette.primary;
  
  const venuesOptionActiveStyle = (currentURL === VENUES_ROUTE) ? itemRowActive : null;
  const venuesOptionStyleChain = css(itemRow, venuesOptionActiveStyle);
  const venuesOptionActiveTextStyle = (currentURL === VENUES_ROUTE) ? itemRowTextActive : null;
  const venuesOptionTextStyleChain = css(itemRowText, venuesOptionActiveTextStyle);
  const venuesOptionIconColor = (currentURL === VENUES_ROUTE) ? palette.white : palette.primary;

  const adminDashboardOptionActiveStyle = (currentURL === ADMIN_DASHBOARD_ROUTE) ? itemRowActive : null;
  const adminDashboardOptionStyleChain = css(itemRow, adminDashboardOptionActiveStyle);
  const adminDashboardOptionActiveTextStyle = (currentURL === ADMIN_DASHBOARD_ROUTE) ? itemRowTextActive: null;
  const adminDashboardOptionTextStyleChain = css(itemRowText, adminDashboardOptionActiveTextStyle);
  const adminDashboardOptionIconColor = (currentURL === ADMIN_DASHBOARD_ROUTE) ? palette.white : palette.primary;

  return {
    computedAvatarImageSize, artistsOptionStyleChain, artistsOptionTextStyleChain, artistsOptionIconColor,
    eventsOptionStyleChain, eventsOptionTextStyleChain, eventsOptionIconColor, venuesOptionStyleChain,
    venuesOptionTextStyleChain, venuesOptionIconColor, profileOptionStyleChain, profileOptionTextStyleChain,
    profileOptionIconColor, adminDashboardOptionStyleChain, adminDashboardOptionTextStyleChain,
    adminDashboardOptionIconColor
  };
};
