// -> Beyond codebase
import React, { useCallback, useContext, useEffect } from "react";
// import onClickOutside from "react-onclickoutside";
// -> Within codebase
import { UIContext } from "../UI_InfoProvider/UI_InfoProvider";
import { LEFT } from "../../constants";
// -> Within component
import { styleGen } from "./DrawerStyles";
import { IDrawerProps, calibrateComponent } from "./helpers";

// -> Written as a non-arrow function to avoid a bug caused by "react-onclickoutside"
const Drawer: React.FC<IDrawerProps> & { handleClickOutside?: () => any } = function(props) {
  const {
    children, drawerOpen, closeDrawer, backdropVisible, closeBackdrop,
  } = props;
  const { themeInfo, navBarVisible } = useContext(UIContext);
  const styles = styleGen(themeInfo, props, navBarVisible);
  const { drawerCradleStylechain } = calibrateComponent(props, styles);
  
  const wasEscapePushed = useCallback((evt: any) => {
    if (evt.key === "Escape" && (drawerOpen)) {
      if (backdropVisible) closeBackdrop();
      closeDrawer();
    }
  }, [drawerOpen, closeDrawer, backdropVisible, closeBackdrop]);
  
  useEffect(() => {
    document.addEventListener("keydown", (evt: any) => wasEscapePushed(evt))
    
    return () => document.removeEventListener("keydown", wasEscapePushed);
  }, [wasEscapePushed]);
  

  return (
    <div className={drawerCradleStylechain}>
      { children }
    </div>
  );
}

export default Drawer;

Drawer.defaultProps = {
  position: LEFT,
}
