// -> Beyond codebase
import React, { useContext, useEffect, useState } from "react";
import { css } from "aphrodite";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Button from "../../../../Components/Button/Button";
import ArrowRightIcon from "../../../../Components/VisualUtilities/IconPresets/ArrowRightIcon";
import FloatingCircularLogo from "../../../../Components/VisualUtilities/FloatingCircularLogo/FloatingCircularLogo";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { RIGHT, ROUNDED, VERTICAL } from "../../../../constants";
import { MFAType } from "../../../../Types";
// -> Within component
import MFAVerificationOption from "./InternalComponents/MFAVerificationOption/MFAVerificationOption"
import { IChooseMFAMethodCardProps, IChooseMFAMethodCardState } from "./helpers";
import { styleGen } from "./ChooseMFAMethodCardStyles";

const ChooseMFAMethodCard: React.FC<IChooseMFAMethodCardProps> = (props) => {
  const [state, setState] = useState<IChooseMFAMethodCardState>({ verificationOption: "None" })
  const { onSelectMFAMethod, verificationOption } = props;
  const { t } = useTranslation("page_enableMFA");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { cardCradle, headerText, messageText } = styleGen(themeInfo);

  const onSelectVerificationOption = (option: MFAType) => {
    switch(option) {
      case "SMS":
        setState({ verificationOption: "SMS" });
        break;
      case "Email":
        setState({ verificationOption: "Email" });
        break;
      case "None":
        setState({ verificationOption: "None" });
        break;
      default: break;
    }
  }

  useEffect(() => setState({ verificationOption }), [verificationOption])

  const { verificationOption: MFAOption } = state;

  return (
    <div className={css(cardCradle)}>
      <FloatingCircularLogo />
      <Spacer direction={VERTICAL} amount={distance.four} />
      <p className={css(headerText)}>{t("cardTitle")}</p>
      <Spacer direction={VERTICAL} amount={distance.two} />
      <p className={css(messageText)}>{t("cardMsg")}</p>
      <Spacer direction={VERTICAL} amount={distance.three} />

      <MFAVerificationOption
        onSelectVerificationOption={onSelectVerificationOption}
        displayedVerificationOption={t("verificationOptionEmailLabel")}
        verificationOption="Email"
        selected={MFAOption === "Email"}
      />
      <Spacer direction={VERTICAL} amount={distance.two} />
      
      <MFAVerificationOption
        onSelectVerificationOption={onSelectVerificationOption}
        displayedVerificationOption={t("verificationOptionSMSLabel")}
        verificationOption="SMS"
        selected={MFAOption === "SMS"}
      />
      <Spacer direction={VERTICAL} amount={distance.two} />
      
      <MFAVerificationOption
        onSelectVerificationOption={onSelectVerificationOption}
        displayedVerificationOption={t("verificationOptionNoneLabel")}
        verificationOption="None"
        selected={MFAOption === "None"}
      />

      <Spacer direction={VERTICAL} amount={distance.four} />
      <Button
        buttonText={t("nextButtonLabel")}
        iconPosition={RIGHT}
        icon={<ArrowRightIcon color={themeInfo.palette.white} size={18} />}
        onClick={() => onSelectMFAMethod(MFAOption)}
        buttonGeometry={ROUNDED}
        iconTextSpacing={distance.one}
        customCradleStyles={{ padding: themeInfo.distance.one, width: "100%" }}
        customTextStyles={{ fontWeight: 'bold', fontSize: '1.6rem' }}
      />
    </div>
  );
}

export default ChooseMFAMethodCard;
