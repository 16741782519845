// -> Beyond codebase
import React, { useContext, useEffect, useState, useRef } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
// -> Within codebase
import Dropdown from "../../../../../../Components/Dropdown/Dropdown";
import ContextMenuHorizontalIcon from "../../../../../../Components/VisualUtilities/IconPresets/ContextMenuHorizontalIcon";
import IconButton from "../../../../../../Components/Button/Variants/IconButton/IconButton";
import Tag from "../../../../../../Components/Tag/Tag";
import Spacer from "../../../../../../Components/LayoutUtilities/Spacer/Spacer";
import LineSegment from "src/Components/VisualUtilities/LineSegment/LineSegment";
import { ThemeInfo, UIContext } from "../../../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL, ROUND, ROUNDED, VERTICAL } from "../../../../../../constants";
import { UserActionTargetType } from "../../../../../../Types";
import {
  determineScreenDimensions, computeActionDescriptionText, computeActionDescriptionLabel, computeSemanticActionType,
} from "src/helpers";
// -> Within component
// import ActionListItemContextMenu from "../SongCollectionListItemContextMenu/SongCollectionListItemContextMenu";
import {
  IRecentActionListItemProps, IRecentActionListItemState,
} from "./helpers";
import { styleGen } from "./RecentActionListItemStyles";
import ImageWrapper from "src/Components/VisualUtilities/ImageWrapper/ImageWrapper";
import { PersonIcon } from "src/Components/VisualUtilities/IconPresets";

const RecentActionListItem: React.FC<IRecentActionListItemProps> = (props) => {
  // const [state, setState] = useState<IRecentActionListItemState>({
  //   actionType: "EDIT",
  //   actionTargetType: "EVENT",
  // });

  // console.log(`${JSON.stringify(props, null, 1)}`);

  const { width, height } = determineScreenDimensions();
  const screenWidthRef = useRef<number>(width);

  const {
    feedAction, feedAction: {
      actionID, actionTargetType: actionTargetTypeFromProps, actionType,
      dateTime, userInfo: { avatarURL, username },
    }, onSelectArtistAction, onSelectVenueAction, onSelectEventAction,
  } = props;
  const { t } = useTranslation("page_adminDashboard");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, palette, styles } = themeInfo;
  const {
    actionListItemCradle, actionDescriptionText, metadataItemRow, metadataItemLabelText,
    topPortion, bottomPortion, leftMetadata, rightMetadata, fallbackIconCradle, usernameText,
  } = styleGen(themeInfo, props);

  // useEffect(() => {
  //   setState(() => ({ actionType: computeSemanticActionType(actionType), actionTargetType: actionTargetTypeFromProps }));
  // }, []);

  const onSelectActionListItem = (
    { actionID, actionTargetType }: { actionID: string, actionTargetType: UserActionTargetType }
  ) => {
    switch (actionTargetType) {
      case "ARTIST":
        onSelectArtistAction(actionID);
        break;
      case "EVENT":
        onSelectEventAction(actionID);
        break;
      case "VENUE":
        onSelectVenueAction(actionID);
        break;
      default: break;
    }
  };

  // const { actionType, actionTargetType } = state;

  return (
    <div className={css(actionListItemCradle)} onClick={() => onSelectActionListItem({ actionID, actionTargetType: actionTargetTypeFromProps })}>
      <div className={css(topPortion)}>
        <div className={css(metadataItemRow)}>
          <p className={css(metadataItemLabelText)}>{computeActionDescriptionLabel({ action: feedAction }, t)}</p>
          <Spacer direction={HORIZONTAL} amount={distance.one} />
          <p className={css(actionDescriptionText)}>{computeActionDescriptionText({ action: feedAction }, t)}</p>
        </div>
      </div>
      <Spacer direction={VERTICAL} amount={distance.two} />
      <div className={css(bottomPortion)}>
        <div className={css(leftMetadata)}>
          <div className={css(metadataItemRow)}>
            <p className={css(metadataItemLabelText)}>{t("actionTypeLabel")}</p>
            <Spacer direction={HORIZONTAL} amount={distance.one} />
            <Tag
              labelText={computeSemanticActionType(actionType)}
              tagGeometry={ROUNDED}
              activeColor={palette.white}
              activeTextColor={palette.primary_p1}
              activeBorder={`1px solid ${palette.primary_p1}`}
              customCradleStyles={{
                paddingTop: distance.one, paddingBottom: distance.one,
                paddingLeft: distance.one, paddingRight: distance.one,
              }}
              customTextStyles={{ fontSize: "1rem", fontWeight: "bold" }}
            />
          </div>
          {/* {
            (screenWidthRef.current > SCREEN_WIDTH_450) ? (
              <LineSegment
                direction={VERTICAL}
                height={10} size={1}
                color={palette.grey3}
                customStyles={{ marginLeft: distance.two, marginRight: distance.two }}
              />
            ) : (<Spacer direction={HORIZONTAL} amount={distance.one} />)
          } */}

          <LineSegment
            direction={VERTICAL}
            height={10} size={1}
            color={palette.grey3}
            customStyles={{ marginLeft: distance.two, marginRight: distance.two }}
          />

          <div className={css(metadataItemRow)}>
            <p className={css(metadataItemLabelText)}>{t("actionTargetTypeLabel")}</p>
            <Spacer direction={HORIZONTAL} amount={distance.one} />
            <Tag
              labelText={actionTargetTypeFromProps}
              tagGeometry={ROUNDED}
              activeColor={palette.white}
              activeTextColor={palette.primary_p1}
              activeBorder={`1px solid ${palette.primary_p1}`}
              customCradleStyles={{
                paddingTop: distance.one, paddingBottom: distance.one,
                paddingLeft: distance.one, paddingRight: distance.one,
              }}
              customTextStyles={{ fontSize: "1rem", fontWeight: "bold" }}
            />
          </div>
        </div>
        <div className={css(rightMetadata)}>
          <div className={css(metadataItemRow)}>
            {
              (avatarURL) ? (
                <ImageWrapper
                alt={`Artist: ${username}`} src={avatarURL}
                borderGeometry={ROUND}
                height={15} width={15}
                />
                ) : (
                  <div className={css(fallbackIconCradle)}>
                  <PersonIcon color={themeInfo.palette.grey2} size={15 - 5} />
                </div>
              )
            }
            <Spacer direction={HORIZONTAL} amount={distance.one} />
            <p className={css(usernameText)}>{username}</p>
          </div>
          <Spacer direction={VERTICAL} amount={distance.one} />
          <div>{format(dateTime, "yyyy-MM-dd ")}</div>
          <div>{format(dateTime, "kk:mm:ss")}</div>
        </div>
      </div>

    </div>
  );
}

export default RecentActionListItem;
