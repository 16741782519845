import Fuse, { FuseOptions } from "fuse.js";
import cloneDeep from "lodash.clonedeep";
import { Artist } from "../../Types";
import { mapLanguageCodeToLanguage } from "../../helpers";
import { IArtistsState } from "./stateManagement";
import {
  FILTER_CONDITION__EQUAL_TO, FILTER_CONDITION__NOT_EQUAL_TO, FILTER_CONDITION__GREATER_THAN,
  FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO, FILTER_CONDITION__LESS_THAN,
  FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO, FILTER_CONDITION__INCLUDED_IN, FILTER_CONDITION__NOT_INCLUDED_IN,
  FILTER_CONDITION__NONE, LANGUAGE__ENGLISH, LANGUAGE__FRENCH,
} from "../../constants";

export interface IArtistsProps {
  customPageCradleStyles?: any;
}

// -----

// -> SORT CRITERIA
export const SORT_CRITERIA__NONE = "None";
export const SORT_CRITERIA__NAME = "Name";
export const SORT_CRITERIA__NUM_SONGS = "Number of Songs"
export const availableSortCriteria = [
  SORT_CRITERIA__NONE, SORT_CRITERIA__NAME, SORT_CRITERIA__NUM_SONGS
];
export const SORT_CRITERIA__NONE_FR = "Aucun";
export const SORT_CRITERIA__NAME_FR = "Nom";
export const SORT_CRITERIA__NUM_SONGS_FR = "Numéro de chansons";
export const availableSortCriteria_FR = [
  SORT_CRITERIA__NONE_FR, SORT_CRITERIA__NAME_FR, SORT_CRITERIA__NUM_SONGS_FR
];

// -> SORT DIRECTIONS
export const SORT_DIRECTION__ASCENDING = "Ascending";
export const SORT_DIRECTION__DESCENDING = "Descending";
export const availableSortDirections = [SORT_DIRECTION__ASCENDING, SORT_DIRECTION__DESCENDING];
export const SORT_DIRECTION__ASCENDING_FR = "Croissant";
export const SORT_DIRECTION__DESCENDING_FR = "Décroissant";
export const availableSortDirections_FR = [SORT_DIRECTION__ASCENDING_FR, SORT_DIRECTION__DESCENDING_FR];

// -> FILTER CRITERIA
export const FILTER_CRITERIA__NONE = "None";
export const FILTER_CRITERIA__NAME = "Name";
export const FILTER_CRITERIA__NUM_EVENTS = "Number of Events";
export const availableFilterCriteria = [
  FILTER_CRITERIA__NONE, FILTER_CRITERIA__NAME, FILTER_CRITERIA__NUM_EVENTS
];
export const FILTER_CRITERIA__NONE_FR = "Aucun";
export const FILTER_CRITERIA__NAME_FR = "Nom";
export const FILTER_CRITERIA__NUM_EVENTS_FR = "Numéro d'événements";
export const availableFilterCriteria_FR = [
  FILTER_CRITERIA__NONE_FR, FILTER_CRITERIA__NAME_FR, FILTER_CRITERIA__NUM_EVENTS_FR
];

// -> FILTER CONDITIONS
export const availableFilterConditions = [
  FILTER_CONDITION__NONE, FILTER_CONDITION__EQUAL_TO, FILTER_CONDITION__NOT_EQUAL_TO, FILTER_CONDITION__GREATER_THAN,
  FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO, FILTER_CONDITION__LESS_THAN,
  FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO, FILTER_CONDITION__INCLUDED_IN, FILTER_CONDITION__NOT_INCLUDED_IN
];

// -> FILTER CONDITION TYPES
export const FILTER_CONDITION_TYPE__LIST = "List";
export const FILTER_CONDITION_TYPE__RANGE = "Range";
export const availableFilterConditionTypes = [FILTER_CONDITION_TYPE__LIST, FILTER_CONDITION_TYPE__RANGE];
export const FILTER_CONDITION_TYPE__LIST_FR = "Liste";
export const FILTER_CONDITION_TYPE__RANGE_FR = "Intervalle";
export const availableFilterConditionTypes_FR = [FILTER_CONDITION_TYPE__LIST_FR, FILTER_CONDITION_TYPE__RANGE_FR];

// ----- ARTIST SORTING ----- //

export const sortArtists = (
  state: IArtistsState,
  options?: { artistsToSortParam?: Artist[] }
): Artist[] => {
  const stateCopy = cloneDeep(state);
  const { currentSortCriteria, currentSortDirection, artists } = stateCopy;
  const artistsToSort = (!options?.artistsToSortParam) ? artists : options.artistsToSortParam;
  let sortedArtists: Artist[] = [];

  if (currentSortCriteria === SORT_CRITERIA__NAME) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedArtists = artistsToSort.sort(compareArtistNamesAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedArtists = artistsToSort.sort(compareArtistNamesDesc);
    }
  } else if (currentSortCriteria === SORT_CRITERIA__NUM_SONGS) {
    if (currentSortDirection === SORT_DIRECTION__ASCENDING) {
      sortedArtists = artistsToSort.sort(compareArtistSongNumbersAsc);
    } else if (currentSortDirection === SORT_DIRECTION__DESCENDING) {
      sortedArtists = artistsToSort.sort(compareArtistSongNumbersDesc);
    }
  }

  return sortedArtists;
};

const compareArtistNamesAsc = (artist1: Artist, artist2: Artist): number => {
  return artist1.name.toLowerCase().localeCompare(artist2.name.toLowerCase());
};

const compareArtistNamesDesc = (artist1: Artist, artist2: Artist): number => {
  return artist2.name.toLowerCase().localeCompare(artist1.name.toLowerCase());
};

const compareArtistSongNumbersAsc = (artist1: Artist, artist2: Artist): number => {
  const artist1SongNum = artist1.events ? artist1.events.length : 0;
  const artist2SongNum = artist2.events ? artist2.events.length : 0;
  
  return artist1SongNum - artist2SongNum;
};

const compareArtistSongNumbersDesc = (artist1: Artist, artist2: Artist): number => {
  const artist1SongNum = artist1.events ? artist1.events.length : 0;
  const artist2SongNum = artist2.events ? artist2.events.length : 0;
  
  return artist2SongNum - artist1SongNum;
};

// ----- ARTIST FILTERING ----- //

export const filterArtists = (
  state: IArtistsState,
  options?: { artistsToFilterParam?: Artist[] }
): Artist[] => {
  const stateCopy = cloneDeep(state);
  const {
    currentFilterCriteria, currentFilterCondition, currentFilterConditionType,
    currentFilterArgument, artists
  } = stateCopy;
  const artistsToFilter = (!options?.artistsToFilterParam) ? artists : options.artistsToFilterParam;
  let filteredArtists: Artist[] = [];

  // -> Filtering by name
  if (currentFilterCriteria === FILTER_CRITERIA__NAME) {
    if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        return (processedFilterArgument.localeCompare(artist.name.toLowerCase().trim()) === 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        return (processedFilterArgument.localeCompare(artist.name.toLowerCase().trim()) !== 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        return (processedFilterArgument.localeCompare(artist.name.toLowerCase().trim()) === -1);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        return (processedFilterArgument.localeCompare(artist.name.toLowerCase().trim()) <= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        return (processedFilterArgument.localeCompare(artist.name.toLowerCase().trim()) > 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
      const processedFilterArgument = currentFilterArgument[0].toLowerCase().trim();
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        return (processedFilterArgument.localeCompare(artist.name.toLowerCase().trim()) >= 0);
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredArtists = artistsToFilter.filter((artist: Artist) => {
          return processedFilterArgument.includes(artist.name.toLowerCase().trim());
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredArtists = artistsToFilter.filter((artist: Artist) => {
          return (
            (minElement.localeCompare(artist.name.toLowerCase().trim()) <= 0) &&
            (maxElement.localeCompare(artist.name.toLowerCase().trim()) >= 0)
          );
        });
      }
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => arg.toLowerCase().trim());

        filteredArtists = artistsToFilter.filter((artist: Artist) => {
          return (processedFilterArgument.includes(artist.name.toLowerCase().trim()) === false);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        const minElement = currentFilterArgument[0].toLowerCase().trim();
        const maxElement = currentFilterArgument[1].toLowerCase().trim();

        filteredArtists = artistsToFilter.filter((artist: Artist) => {
          return (
            (minElement.localeCompare(artist.name.toLowerCase().trim()) > 0) ||
            (maxElement.localeCompare(artist.name.toLowerCase().trim()) < 0)
          );
        });
      }
    }
  }

  // -> Filtering by number of events

  else if (currentFilterCriteria === FILTER_CRITERIA__NUM_EVENTS) {
    if (currentFilterCondition === FILTER_CONDITION__EQUAL_TO) {
      let processedFilterArgument = parseInt(currentFilterArgument[0]);
      if (isNaN(processedFilterArgument)) processedFilterArgument = 0;
      
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        const artistSongNum = artist.events ? artist.events.length : 0;
        return processedFilterArgument === artistSongNum;
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_EQUAL_TO) {
      let processedFilterArgument = parseInt(currentFilterArgument[0]);
      if (isNaN(processedFilterArgument)) processedFilterArgument = 0;
      
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        const artistSongNum = artist.events ? artist.events.length : 0;
        return processedFilterArgument !== artistSongNum;
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN) {
      let processedFilterArgument = parseInt(currentFilterArgument[0]);
      if (isNaN(processedFilterArgument)) processedFilterArgument = 0;
      
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        const artistSongNum = artist.events ? artist.events.length : 0;
        return processedFilterArgument < artistSongNum;
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO) {
      let processedFilterArgument = parseInt(currentFilterArgument[0]);
      if (isNaN(processedFilterArgument)) processedFilterArgument = 0;
      
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        const artistSongNum = artist.events ? artist.events.length : 0;
        return processedFilterArgument <= artistSongNum;
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN) {
      let processedFilterArgument = parseInt(currentFilterArgument[0]);
      if (isNaN(processedFilterArgument)) processedFilterArgument = 0;
      
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        const artistSongNum = artist.events ? artist.events.length : 0;
        return processedFilterArgument > artistSongNum;
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO) {
      let processedFilterArgument = parseInt(currentFilterArgument[0]);
      if (isNaN(processedFilterArgument)) processedFilterArgument = 0;
      
      filteredArtists = artistsToFilter.filter((artist: Artist) => {
        const artistSongNum = artist.events ? artist.events.length : 0;
        return processedFilterArgument >= artistSongNum;
      });
    }

    else if (currentFilterCondition === FILTER_CONDITION__INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => {
          let processedArg = parseInt(arg);
          if (isNaN(processedArg)) processedArg = 0;

          return processedArg;
        });

        filteredArtists = artistsToFilter.filter((artist: Artist) => {
          const artistSongNum = artist.events ? artist.events.length : 0;
          return processedFilterArgument.includes(artistSongNum);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        let minSongNum = parseInt(currentFilterArgument[0]);
        let maxSongNum = parseInt(currentFilterArgument[1]);

        if (isNaN(minSongNum)) minSongNum = 0;
        if (isNaN(maxSongNum)) maxSongNum = 0;

        filteredArtists = artistsToFilter.filter((artist: Artist) => {
          const artistSongNum = artist.events ? artist.events.length : 0;
          return ((artistSongNum >= minSongNum) && (artistSongNum <= maxSongNum));
        }); 
      }
    }

    else if (currentFilterCondition === FILTER_CONDITION__NOT_INCLUDED_IN) {
      if (currentFilterConditionType === FILTER_CONDITION_TYPE__LIST) {
        const processedFilterArgument = currentFilterArgument.map((arg: string) => {
          let processedArg = parseInt(arg);
          if (isNaN(processedArg)) processedArg = 0;

          return processedArg;
        });

        filteredArtists = artistsToFilter.filter((artist: Artist) => {
          const artistSongNum = artist.events ? artist.events.length : 0;
          return (processedFilterArgument.includes(artistSongNum) === false);
        });
      } else if (currentFilterConditionType === FILTER_CONDITION_TYPE__RANGE) {
        let minSongNum = parseInt(currentFilterArgument[0]);
        let maxSongNum = parseInt(currentFilterArgument[1]);

        if (isNaN(minSongNum)) minSongNum = 0;
        if (isNaN(maxSongNum)) maxSongNum = 0;

        filteredArtists = artistsToFilter.filter((artist: Artist) => {
          const artistSongNum = artist.events ? artist.events.length : 0;
          return ((artistSongNum < minSongNum) || (artistSongNum > maxSongNum));
        });
      }
    }
  }

  return filteredArtists;
};

// ----- ARTIST SEARCH ----- //

export const searchArtists = (
  searchQuery: string,
  state: IArtistsState,
  options?: { artistsToSearchParam?: Artist[] }
): Artist[] => {
  const { artists, sortingActive } = state;
  const artistsToSearch = (!options?.artistsToSearchParam) ? artists : options.artistsToSearchParam;
  let artistSearchResults: any[] = [];
  
  if (searchQuery === "") return artists;

  const searchEngineOptions: FuseOptions<Artist> = {
    keys: ["name"],
    shouldSort: sortingActive ? false : true, // -> Results sorted by relevance score unless manual sorting is enabled
    findAllMatches: true,
    threshold: 0.4, // -> This is the default value, it can be tweaked from 0 to 1
  };

  const fuse = new Fuse(artistsToSearch, searchEngineOptions);

  artistSearchResults = fuse.search(searchQuery);

  return artistSearchResults;
};

// ----- ARTIST PAGINATION ----- //

export const selectPaginatedArtists = (
  state: IArtistsState,
  options?: {
    artistsToPaginateParam?: Artist[],
    pageNumber?: number
  }
): Artist[] => {
  const { itemsPerPage, currentPageNumber, artists } = state;
  const pageNumberToUse = (!options?.pageNumber) ? currentPageNumber : options.pageNumber;
  const artistsToPaginate = (!options?.artistsToPaginateParam) ? artists : options.artistsToPaginateParam;
  let displayedArtists: Artist[] = [];

  const indexOfLastArtist = pageNumberToUse * itemsPerPage;
  const indexOfFirstArtist = indexOfLastArtist - itemsPerPage;
  displayedArtists = artistsToPaginate.slice(indexOfFirstArtist, indexOfLastArtist);

  return displayedArtists;
};

// ----- LANGUAGE PROCESSING ----- //

// ----- Sort Criteria ----- //

export const mapSortCriteriaToEnglishSortCriteria =
  (sortCriteria: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedSortCriteria: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedSortCriteria = sortCriteria;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (sortCriteria) {
      case SORT_CRITERIA__NONE_FR:
        translatedSortCriteria = SORT_CRITERIA__NONE;
        break;
      case SORT_CRITERIA__NAME_FR:
        translatedSortCriteria = SORT_CRITERIA__NAME;
        break;
      case SORT_CRITERIA__NUM_SONGS_FR:
        translatedSortCriteria = SORT_CRITERIA__NUM_SONGS;
        break;
      default:
        translatedSortCriteria = SORT_CRITERIA__NONE;
        break;
    }
  }

  return translatedSortCriteria;
};

// -----

export const mapEnglishSortCriteriaToTargetLanguage = (sortCriteria: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedSortCriteria: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedSortCriteria = sortCriteria;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (sortCriteria) {
      case SORT_CRITERIA__NONE:
        translatedSortCriteria = SORT_CRITERIA__NONE_FR;
        break;
      case SORT_CRITERIA__NAME:
        translatedSortCriteria = SORT_CRITERIA__NAME_FR;
        break;
      case SORT_CRITERIA__NUM_SONGS:
        translatedSortCriteria = SORT_CRITERIA__NUM_SONGS_FR;
        break;
      default:
        translatedSortCriteria = SORT_CRITERIA__NONE;
        break;
    }
  }

  return translatedSortCriteria;
};

// ----- Sort Direction ----- //

export const mapSortDirectionToEnglishSortDirection =
  (sortDirection: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedSortDirection: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedSortDirection = sortDirection;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (sortDirection) {
      case SORT_DIRECTION__ASCENDING_FR:
        translatedSortDirection = SORT_DIRECTION__ASCENDING;
        break;
      case SORT_DIRECTION__DESCENDING_FR:
        translatedSortDirection = SORT_DIRECTION__DESCENDING;
        break;
      default:
        translatedSortDirection = SORT_DIRECTION__ASCENDING;
        break;
    }
  }

  return translatedSortDirection;
};

// -----

export const mapEnglishSortDirectionToTargetLanguage = (sortDirection: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedSortDirection: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedSortDirection = sortDirection;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (sortDirection) {
      case SORT_DIRECTION__ASCENDING:
        translatedSortDirection = SORT_DIRECTION__ASCENDING_FR;
        break;
      case SORT_DIRECTION__DESCENDING:
        translatedSortDirection = SORT_DIRECTION__DESCENDING_FR;
        break;
      default:
        translatedSortDirection = SORT_DIRECTION__ASCENDING;
        break;
    }
  }

  return translatedSortDirection;
};

// ----- Filter Criteria ----- //

export const mapFilterCriteriaToEnglishFilterCriteria =
  (filterCriteria: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedFilterCriteria: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedFilterCriteria = filterCriteria;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (filterCriteria) {
      case FILTER_CRITERIA__NONE_FR:
        translatedFilterCriteria = FILTER_CRITERIA__NONE;
        break;
      case FILTER_CRITERIA__NAME_FR:
        translatedFilterCriteria = FILTER_CRITERIA__NAME;
        break;
      case FILTER_CRITERIA__NUM_EVENTS_FR:
        translatedFilterCriteria = FILTER_CRITERIA__NUM_EVENTS;
        break;
      default:
        translatedFilterCriteria = FILTER_CRITERIA__NONE;
        break;
    }
  }

  return translatedFilterCriteria;
};

// -----

export const mapEnglishFilterCriteriaToTargetLanguage = (filterCriteria: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedFilterCriteria: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedFilterCriteria = filterCriteria;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (filterCriteria) {
      case FILTER_CRITERIA__NONE:
        translatedFilterCriteria = FILTER_CRITERIA__NONE_FR;
        break;
      case FILTER_CRITERIA__NAME:
        translatedFilterCriteria = FILTER_CRITERIA__NAME_FR;
        break;
      case FILTER_CRITERIA__NUM_EVENTS:
        translatedFilterCriteria = FILTER_CRITERIA__NUM_EVENTS_FR;
        break;
      default:
        translatedFilterCriteria = FILTER_CRITERIA__NONE;
        break;
    }
  }

  return translatedFilterCriteria;
};

// ----- Filter Condition Type ----- //

export const mapFilterConditionTypeToEnglishFilterConditionType = (filterConditionType: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedFilterConditionType: string = FILTER_CONDITION_TYPE__LIST;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedFilterConditionType = filterConditionType;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (filterConditionType) {
      case FILTER_CONDITION_TYPE__LIST:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST_FR;
        break;
      case FILTER_CONDITION_TYPE__RANGE:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__RANGE_FR;
        break;
      default:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST_FR;
        break;
    }
  }

  return translatedFilterConditionType;
};

// -----

export const mapEnglishFilterConditionTypeToTargetLanguage = (filterConditionType: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedFilterConditionType: string = FILTER_CONDITION_TYPE__LIST;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedFilterConditionType = filterConditionType;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (filterConditionType) {
      case FILTER_CONDITION_TYPE__LIST_FR:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST;
        break;
      case FILTER_CONDITION_TYPE__RANGE_FR:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__RANGE;
        break;
      default:
        translatedFilterConditionType = FILTER_CONDITION_TYPE__LIST;
        break;
    }
  }

  return translatedFilterConditionType;
};

// ----- Available Filter Criteria ----- //

export const determineAvailableFilterCriteriaByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableFilterCriteria;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableFilterCriteria = availableFilterCriteria;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableFilterCriteria = availableFilterCriteria_FR;
      break;
    default:
      computedAvailableFilterCriteria = availableFilterCriteria;
      break;
  }

  return computedAvailableFilterCriteria;
};

// ----- Available Filter Condition Types ----- //

export const determineAvailableFilterConditionTypesByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableFilterConditionType;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableFilterConditionType = availableFilterConditionTypes;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableFilterConditionType = availableFilterConditionTypes_FR;
      break;
    default:
      computedAvailableFilterConditionType = availableFilterConditionTypes;
      break;
  }

  return computedAvailableFilterConditionType;
};

// ----- Available Sort Criteria ----- //

export const determineAvailableSortCriteriaByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableSortCriteria;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableSortCriteria = availableSortCriteria;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableSortCriteria = availableSortCriteria_FR;
      break;
    default:
      computedAvailableSortCriteria = availableSortCriteria;
      break;
  }

  return computedAvailableSortCriteria;
};

// ----- Available Sort Directions ----- //

export const determineAvailableSortDirectionsByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let computedAvailableSortDirections;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      computedAvailableSortDirections = availableSortDirections;
      break;
    case LANGUAGE__FRENCH:
      computedAvailableSortDirections = availableSortDirections_FR;
      break;
    default:
      computedAvailableSortDirections = availableSortDirections;
      break;
  }

  return computedAvailableSortDirections;
};
