import { IReducerAction } from "../../../../Types";
import { VenuesState } from "../../stateManagement";

export interface ITopBarProps {
  title?: string;
  setDisplayMode(mode: string): any;
  displayMode: string;
  customTopBarCradleStyles?: any;
  state: VenuesState;
  dispatch: any;
  addMicroanimationTimeoutID(timeout: any): IReducerAction;
  addFilterArgumentFadeInStatus(): IReducerAction;
  removeFilterArgumentFadeInStatus(index: number): IReducerAction;
  setFilterArgumentFadeInStatuses(fadeInStatuses: boolean[]): IReducerAction;
  setSortActivityIndicatorInStatus(inStatus: boolean): IReducerAction;
  setFilterActivityIndicatorInStatus(inStatus: boolean): IReducerAction;
  setSearchActivityIndicatorInStatus(inStatus: boolean): IReducerAction;
  setSortCriteria(criteria: string): IReducerAction;
  setSortDirection(direction: string): IReducerAction;
  applySortConfig(): IReducerAction;
  clearSortConfig(): IReducerAction;
  setFilterCriteria(filterCriteria: string): IReducerAction;
  setFilterCondition(filterCondition: string): IReducerAction;
  setFilterConditionType(filterConditionType: string): IReducerAction;
  setFilterArgument(filterArgument: any): IReducerAction;
  applyFilterConfig(): IReducerAction;
  clearFilterConfig(): IReducerAction;
  searchVenuesAction(searchQuery: string): IReducerAction;
  clearVenueSearch(): IReducerAction;
}

export const TOP_BAR_DEFAULT_HEIGHT = 45;