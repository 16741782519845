import { HOURS_MINUTES_SECONDS_REGEX, MINUTES_SECONDS_REGEX } from "../constants";

// -> Processes time presented in an hours:minutes:seconds format to a number of seconds
export const hoursMinutesSecondsToSeconds = (time: string): number => {
  const hoursMinutesAndSeconds = time.split(":");

  return (
    parseInt(hoursMinutesAndSeconds[0]) * 3600 +
    parseInt(hoursMinutesAndSeconds[1]) * 60 +
    parseInt(hoursMinutesAndSeconds[2])
  );
};

// -> Processes time presented in a minutes:seconds format to a number of seconds
export const minutesSecondsToSeconds = (time: string): number => {
  const minutesAndSeconds = time.split(":");

  return (parseInt(minutesAndSeconds[0]) * 60) + parseInt(minutesAndSeconds[1]);
};

// -> Processes time presented in an hours:minutes format to a number of minutes
export const hoursMinutesToMinutes = (time: string): number => {
  const hoursAndMinutes = time.split(":");

  return (parseInt(hoursAndMinutes[0]) * 60) + parseInt(hoursAndMinutes[1]);
}

// -> Processes time presented in a seconds format to an hours:minutes:seconds format
export const secondsToHoursMinutesSeconds = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor(time % 3600 / 60);
  const seconds = Math.floor(time % 3600 % 60);

  const hoursDisplay = (hours > 0) ? `${hours}` : null;
  const minutesDisplay = (minutes > 0) ? ((minutes >= 10) ? `${minutes}` : `0${minutes}`) : "00";
  const secondsDisplay = (seconds > 0) ? ((seconds >= 10) ? `${seconds}` : `0${seconds}`) : "00";

  let finalReadout;

  if (hoursDisplay) finalReadout = `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`;
  else finalReadout = `${minutesDisplay}:${secondsDisplay}`;

  return finalReadout;
};

// -> Processes time presented in a minutes format to an hours:minutes format
// - DEV NOTE -> Would need additional logic to curtail the output to within just 
//               the 24 hours possible in a day.
export const minutesToHoursMinutes = (time: number): string => {
  const hours = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);

  return "";
};

// -> Processes time from input song data down to seconds
export const convertInputSongTimeToSeconds = (time: string): number | boolean => {
  let convertedTime: number | boolean = false;

  if (HOURS_MINUTES_SECONDS_REGEX.test(time)) {
    convertedTime = hoursMinutesSecondsToSeconds(time);
  }
  else if (MINUTES_SECONDS_REGEX.test(time)) {
    convertedTime = minutesSecondsToSeconds(time);
  }

  return convertedTime;
};

// -> Processes a time in hours to AM/PM instead of 24hr format
export const convert24HrHoursToAMPMHours = (time: number): number =>{
  let returnedTime = time;
  if (time > 12) returnedTime = time - 12;
  return returnedTime;
}