// -> Beyond codebase
import React, { useEffect, useContext, useState, useRef } from "react";
import { css } from "aphrodite";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// -> Within codebase
import ImageWrapper from "../../../../Components/VisualUtilities/ImageWrapper/ImageWrapper";
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import IconButton from "../../../../Components/Button/Variants/IconButton/IconButton";
import Button from "../../../../Components/Button/Button";
import Tag from "../../../../Components/Tag/Tag";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import Fade from "../../../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import DiscoBallIcon from "../../../../Components/VisualUtilities/IconPresets/DiscoBallIcon";
import EditIcon from "../../../../Components/VisualUtilities/IconPresets/EditIcon";
import LinkWrapper from "../../../../Components/VisualUtilities/LinkWrapper/LinkWrapper";
import EventListItem from "../../../../Components/ListItems/EventListItem/EventListItem";
import { EmailIcon, GlobeIcon, MarkerIcon, PhoneIcon } from "../../../../Components/VisualUtilities/IconPresets";
import { ThemeInfo, UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../../../Components/DataContextProvider/DataContextProvider";
import {
  HORIZONTAL, ROUND, ROUNDED, VERTICAL, SCREEN_WIDTH_550,
  STATIC_MAP_SMALL_WIDTH, STATIC_MAP_LARGE_WIDTH, STATIC_MAP_XS_WIDTH, EDIT_VENUE_ROUTE,
  VENUE_ID_ROUTE_PARAM_TOKEN, DEFAULT_TRANSITION_MICROANIMATION_TIME,
} from "../../../../constants";
import { determineScreenDimensions, mapExternalLinkToIcon } from "../../../../helpers";
import { Event } from "../../../../Types";
import { getEventsFromIDs } from "../../../../lib";
// -> Within component
import { styleGen } from "./VenueDetailsStyles";
import {
  DEFAULT_IMAGE_SIZE, VenueDetailsProps, VenueDetailsState,
  STATIC_MAP_ASPECT_RATIO, STATIC_MAP_SCALE_COEFFICIENT
} from "./helpers";

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

const VenueDetails: React.FC<VenueDetailsProps> = (props) => {
  const [state, setState] = useState<VenueDetailsState>({
    hasMultipleExternalLinks: false,
    mainContentInStatus: true,
    staticMapWidth: 0,
    events: [],
  });
  const { t } = useTranslation("page_venue");
  const {onEventClick, actionHistoryCardMode, venue: {
    name, description, imageURL, coordinates, address, city,
    country, phoneNumber, links, email, events: eventIDs, venueStatus,
    id
  }} = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const history = useHistory();
  const { state: { events } } = useContext(DataContext);
  const { distance, palette, transitions, shadows, styles, fonts } = themeInfo;
  const {
    componentCradle, topSectionCradle, fallbackIconCradle, venueNameText, venueDetailsCradle,
    topRow, noContentFallbackCradle, venueLinksCradle, noContentFallbackText, bodyText,
    descriptionLabelText, descriptionLabelCradle, venueNameAndLocationCol, staticMapCradle, infoRow,
    staticMapRow, venueEventsCradle, venueEventsTitleBarCradle, venueEventsTitleBarText,
    venueEventsTitleCradle, venueEventsListCradle, venueNoEventsCradle, itemSeparatorCradle,
    locationAndStatusRow
  } = styleGen(themeInfo, state, props);

  const { width: screenWidth } = determineScreenDimensions();
  
  let mainContentMicroanimationTimeout = useRef<any>(-1);
  const componentCradleRef = useRef<HTMLDivElement>(null);

  let computedMapWidth: number;
  let computedMapHeight: number;

  if (actionHistoryCardMode) {
    computedMapWidth = STATIC_MAP_XS_WIDTH;
  }
  else {
    if (screenWidth > SCREEN_WIDTH_550) computedMapWidth = STATIC_MAP_LARGE_WIDTH;
    else computedMapWidth = STATIC_MAP_SMALL_WIDTH;
  }
  
  if (actionHistoryCardMode) {
    computedMapHeight = Math.floor(STATIC_MAP_XS_WIDTH * STATIC_MAP_ASPECT_RATIO);
  }
  else {
    if (screenWidth > SCREEN_WIDTH_550) computedMapHeight = Math.floor(STATIC_MAP_LARGE_WIDTH * STATIC_MAP_ASPECT_RATIO);
    else computedMapHeight = Math.floor(STATIC_MAP_SMALL_WIDTH * STATIC_MAP_ASPECT_RATIO);
  }

  // const staticMapWidthRef = useRef<number>(
  //   (screenWidth > SCREEN_WIDTH_550) ? STATIC_MAP_LARGE_WIDTH : STATIC_MAP_SMALL_WIDTH
  // );
  const staticMapWidthRef = useRef<number>(computedMapWidth);
  

  // const staticMapHeightRef = useRef<number>(
  //   (screenWidth > SCREEN_WIDTH_550) ?
  //   (Math.floor(STATIC_MAP_LARGE_WIDTH * STATIC_MAP_ASPECT_RATIO)) :
  //   (Math.floor(STATIC_MAP_SMALL_WIDTH * STATIC_MAP_ASPECT_RATIO))
  // );
  const staticMapHeightRef = useRef<number>(computedMapHeight);

  // console.log("Events -> ", JSON.stringify(eventIDs, null ,1));
  // 0xEA4435
  const staticMapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${`${coordinates.lat},${coordinates.lng}`}&size=${`${staticMapWidthRef.current}x${staticMapHeightRef.current}`}&maptype="roadmap"&scale=${STATIC_MAP_SCALE_COEFFICIENT}&format=jpg&markers=size:mid|color:0x${palette.primary.substring(1)}|${`${coordinates.lat},${coordinates.lng}`}&key=${REACT_APP_GOOGLE_MAPS_API_KEY}`;

  // -----

  useEffect(() => {
    if (links && Object.keys(links).length > 1) {
      setState((prevState) => ({ ...prevState, hasMultipleExternalLinks: true }));
    }

    let venueEvents: Event[] | undefined = undefined;
    if (eventIDs) venueEvents = getEventsFromIDs(eventIDs, events);
    if (venueEvents) {
      setState((prevState) => ({ ...prevState, events: venueEvents! }));
    }
  }, [eventIDs, events, links]);

  useEffect(() => {
    return () => clearTimeout(mainContentMicroanimationTimeout.current);
  }, [mainContentMicroanimationTimeout]);


  // -----
  
  const onNavigateToEditVenue = (venueID: string) => {
    
    const computedURL = EDIT_VENUE_ROUTE.replace(VENUE_ID_ROUTE_PARAM_TOKEN, venueID);

    // - TODO: -> Why isn't the set timeout callback invoking?
    history.push(computedURL);
    mainContentMicroanimationTimeout.current = setTimeout(() => {
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };
  
  // -----

  const onExternalLinkClick = (link: string) => window.open(link, "_blank");

  const externalVenueLinks: string[] = links ? Object.values(links) : [];

  // -----

  // const onEventClick = (link: string) => {
  //   if (link === "") alert("There is no link associated with this event.");
  //   else onExternalLinkClick(link);
  // };

  // -----

  const { mainContentInStatus, events: venueEvents } = state;

  return (
    <Fade inStatus={mainContentInStatus}>
      <div className={css(componentCradle)} ref={componentCradleRef}>
        <div className={css(topSectionCradle)}>
          {/* IMAGE */}
          {
            (imageURL) ? (
              <ImageWrapper
                alt={`Venue: ${name}`} src={imageURL}
                borderGeometry={ROUNDED}
                height={DEFAULT_IMAGE_SIZE} width={DEFAULT_IMAGE_SIZE}
              />
            ) : (
              <div className={css(fallbackIconCradle)}>
                <DiscoBallIcon color={palette.grey2} size={DEFAULT_IMAGE_SIZE - 65} />
              </div>
            )
          }

          <div style={{ marginRight: distance.three, marginTop: distance.three }}></div>

          <div className={css(venueDetailsCradle)}>
            {/* NAME, CITY, COUNTRY */}
            <div className={css(topRow)}>
              <div className={css(venueNameAndLocationCol)}>
                <p className={css(venueNameText)}>{name}</p>

                <Spacer direction={VERTICAL} amount={distance.two} />

                <div className={css(locationAndStatusRow)}>
                  <Tag
                    labelText={`${city}, ${country}`}
                    tagGeometry={ROUNDED}
                    activeColor={palette.primary_p1}
                    activeTextColor={palette.white}
                    customCradleStyles={{
                      paddingTop: distance.one + 2, paddingBottom: distance.one + 2,
                      paddingLeft: distance.one + 2, paddingRight: distance.one + 2,
                    }}
                    customTextStyles={{ fontSize: "1.2rem", fontWeight: "bold" }}
                  />

                  <Spacer direction={HORIZONTAL} amount={distance.two} />

                  <Tag
                      labelText={venueStatus}
                      tagGeometry={ROUNDED}
                      activeColor={palette.white}
                      activeTextColor={(venueStatus === "Permanently Closed") ? palette.errorStatus : palette.primary_p1}
                      activeBorder={`2px solid ${(venueStatus === "Permanently Closed") ? palette.errorStatus : palette.primary_p1}`}
                      customCradleStyles={{
                        paddingTop: distance.one, paddingBottom: distance.one,
                        paddingLeft: distance.one, paddingRight: distance.one,
                      }}
                      customTextStyles={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    />
                </div>
              </div>

              {
                actionHistoryCardMode ? null : (
                  // <IconButton
                  //   height={40}
                  //   width={40}
                  //   transparent
                  //   buttonCradlePadding={0}
                  //   icon={<EditIcon color={palette.black} size={styles.standardIconSize} />}
                  //   customCradleStyles={{ padding: 0, justifyContent: 'flex-end' }}
                  //   onClick={underConstructionAlert}
                  // />
                  <IconButton
                    height={45}
                    width={45}
                    buttonCradlePadding={0}
                    buttonGeometry={ROUND}
                    color={palette.white}
                    customCradleStyles={{
                      transition: transitions.boxShadowTransition,
                      padding: distance.two,
                      height: 45, width: 45,
                      position: "relative",
                      left: 15
                    }}
                    customCradleHoverStyles={{ boxShadow: shadows.one }}
                    icon={<EditIcon color={palette.black} size={styles.standardIconSize} />}
                    // customCradleStyles={{ padding: 0, justifyContent: 'flex-end' }}
                    onClick={() => onNavigateToEditVenue(id)}
                  />
                )
              }
            </div>


            <LineSegment
              direction={HORIZONTAL}
              width={"100%"} size={2}
              color={palette.black}
              customStyles={{
                marginTop: distance.three, marginBottom: distance.three,
              }}
            />

            {/* VENUE LINKS */}
            <div className={css(venueLinksCradle)}>
              {
                (links && (Object.keys(links).length > 0)) ? (
                  Object.keys(links).map((linkName: string, index: number) => {

                    return (
                      <div key={index}>
                        <IconButton
                          icon={mapExternalLinkToIcon(linkName, 25, palette.black)}
                          onClick={() => onExternalLinkClick(externalVenueLinks[index])}
                          buttonGeometry={ROUND}
                          color={palette.white}
                          customCradleStyles={{
                            transition: transitions.boxShadowTransition,
                            padding: distance.two,
                            height: 45, width: 45
                          }}
                          customCradleHoverStyles={{ boxShadow: shadows.one }}
                        />
                        {
                          (index < Object.keys(links).length) ? (
                            <Spacer direction={HORIZONTAL} amount={distance.three} />
                          ) : null
                        }
                      </div>
                    );
                  })
                ) : (
                  <div className={css(noContentFallbackCradle)}>
                    <p className={css(noContentFallbackText)}>{t("noExternalVenueLinksMsg")}</p>
                  </div>
                )
              }
            </div>
          </div>
        </div>

        <Spacer direction={VERTICAL} amount={distance.three} />

        {/* DESCRIPTION */}
        <div className={css(descriptionLabelCradle)}>
          <p className={css(descriptionLabelText)}>{t("descriptionLabel")}</p>
        </div>

        <Spacer direction={VERTICAL} amount={distance.two} />

        {
          (description) ? (
            <p className={css(bodyText)}>{description}</p>
          ) : (
            <div className={css(noContentFallbackCradle)}>
              <p className={css(noContentFallbackText)}>{t("noVenueDescriptionMsg")}</p>
            </div>
          )
        }

        <Spacer direction={VERTICAL} amount={distance.three} />

        {/* MAP */}
        <div className={css(staticMapRow)}>
          <div className={css(staticMapCradle)}>
            <img
              alt={`Geographic location of ${name}`}
              src={staticMapURL}
            />
          </div>
        </div>

        <Spacer direction={VERTICAL} amount={distance.three} />

        {/* ADDRESS */}
        <div className={css(infoRow)}>
          <MarkerIcon color={palette.black} size={styles.standardIconSize} />
          <Spacer direction={HORIZONTAL} amount={distance.two} />
          {/* <p className={css(bodyText)}>{address}</p> */}
          <LinkWrapper
            link={`https://maps.google.com/?q=${address}`}
            customStyles={{
              font: fonts.primary,
              fontSize: "1.6rem",
              color: palette.black,
              "&:hover": { textDecoration: "underline" }
            }}
          >
            {address}
          </LinkWrapper>
        </div>
        <Spacer direction={VERTICAL} amount={distance.two} />

        {/* PHONE NUMBER */}
        {
          (phoneNumber) ? (
            <>
              <div className={css(infoRow)}>
                <PhoneIcon color={palette.black} size={styles.standardIconSize} />
                <Spacer direction={HORIZONTAL} amount={distance.two} />
                <LinkWrapper
                  link={`tel:${phoneNumber}`}
                  customStyles={{
                    font: fonts.primary,
                    fontSize: "1.6rem",
                    color: palette.black,
                    "&:hover": { textDecoration: "underline" }
                  }}
                >
                  {phoneNumber}
                </LinkWrapper>
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />
            </>
          ) : null
        }

        {/* EMAIL */}
        {
          (email) ? (
            <>
              <div className={css(infoRow)}>
                <EmailIcon color={palette.black} size={styles.standardIconSize} />
                <Spacer direction={HORIZONTAL} amount={distance.two} />
                <LinkWrapper
                  link={`mailto:${email}`}
                  customStyles={{
                    font: fonts.primary,
                    fontSize: "1.6rem",
                    color: palette.black,
                    "&:hover": { textDecoration: "underline" }
                  }}
                >
                  {email}
                </LinkWrapper>
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />
            </>
          ) : null
        }

        {/* WEBSITE */}
        {
          (links?.website) ? (
            <>
              <div className={css(infoRow)}>
                <GlobeIcon color={palette.black} size={styles.standardIconSize} />
                <Spacer direction={HORIZONTAL} amount={distance.two} />
                <Button
                  buttonText={links.website}
                  onClick={(evt: any) => {
                    evt.stopPropagation();
                    onExternalLinkClick(links.website!)
                  }}
                  transparent
                  customTextStyles={{
                    fontFamily: fonts.primary,
                    fontSize: "1.6rem",
                    color: palette.black,
                  }}
                  customTextHoverStyles={{ textDecoration: "underline" }}
                  customCradleStyles={{ padding: 0, width: "min-content", whiteSpace: "nowrap" }}
                />
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />
            </>
          ) : null
        }

        <Spacer direction={VERTICAL} amount={distance.one} />

        {/* VENUE EVENTS */}
        <div className={css(venueEventsCradle)}>
          <div className={css(venueEventsTitleBarCradle)}>
            <div className={css(venueEventsTitleCradle)}>
              <p className={css(venueEventsTitleBarText)}>{t("eventsLbl")}</p>
            </div>
            <Tag
              labelText={(venueEvents) ? venueEvents.length.toString() : "0"}
              tagGeometry={ROUND}
              activeColor={palette.primary}
              activeTextColor={palette.white}
              customCradleStyles={{
                height: 30, width: 30,
                padding: distance.one,
              }}
              customTextStyles={{ fontWeight: "bold" }}
            />
          </div>

          <Spacer direction={VERTICAL} amount={distance.two} />

          {
            (venueEvents && venueEvents.length > 0) ? (
              <div className={css(venueEventsListCradle)}>
                {/* <div className={css(scrollOverlay)}></div> */}
                {
                  venueEvents.map((event: Event, index: number) => {
                    const {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      id, startDateTime, name, imageURL, links
                    } = event;

                    // const computedEventLink = links ? Object.values(links)[0] : "";

                    return (
                      <div key={id}>
                        <EventListItem
                        // itemNumber={index + 1}
                          imageURL={imageURL}
                          name={name}
                          date={startDateTime}
                          onClick={() => onEventClick(id)}
                        />
                        {
                          (index < (venueEvents.length - 1)) ? (
                            <div className={css(itemSeparatorCradle)}>
                              <LineSegment
                                direction={HORIZONTAL}
                                width={`calc(100% - ${distance.six}px)`}
                                size={1}
                                color={palette.grey1}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                    );
                  })
                }
              </div>
            ) : (
              <div className={css(venueNoEventsCradle)}>
                <p className={css(noContentFallbackText)}>{t("noEventsMsg")}</p>
              </div>
            )
          }
        </div>
      </div>
    </Fade>
  );
}

export default VenueDetails;
