import { StyleSheet } from "aphrodite";
import { ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { NAV_BAR_HEIGHT } from "../../constants";

export const styleGen = (themeInfo: ThemeInfo) => {
  const { palette, distance } = themeInfo;

  return StyleSheet.create({
    pageCradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT})`,
      width: "100%",
      paddingLeft: distance.three,
      paddingRight: distance.three,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.white,
    },
    successContextCradle: {
      width: "auto",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    mainContentCradle: {
      height: "auto",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }
  });
}
