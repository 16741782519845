export interface IAddPhoneNumberCardProps {
  onAddPhoneNumberSubmission(phoneNumber: string): any;
  closeModal(): any;
}

export interface IAddPhoneNumberCardState {
  phoneNumber: string;
}

export const DEFAULT_CARD_WIDTH = 350;
export const DEFAULT_CARD_HEIGHT = 290;
