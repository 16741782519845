import {
  isPast, isFuture, format, getHours, getMinutes, intervalToDuration,
  formatDistanceToNowStrict
} from 'date-fns';
import { utcToZonedTime } from "date-fns-tz";
import { EventStatus, Event } from "src/Types";
import { convert24HrHoursToAMPMHours } from "./timeUtils";

// -> Determine the status of an event
// - TODO: -> Improve this function to cover more edge cases
//  -> Event exists over an interval of days
//  -> Event stretches over from start day into next since it goes past midnight
//  -> On the event day but still upcoming (express semantically in hours or such)
export const determineEventStatus = (event: Event): EventStatus => {
  let eventStatus: EventStatus;
  let isUpcoming: boolean;
  const { startDateTime, endDateTime } = event;
  // const currentDateTime = Date.now();

  // -> Cases
  //  1. Current day is before event day - event is upcoming
  //  2. It is event day but it is before starting time
  //  3. The event is ongoing
  //  4. The event has  

  if (isFuture(startDateTime)) {
    eventStatus = "Upcoming"
    isUpcoming = true;
  }
  else if (isPast(startDateTime)) {

    if (endDateTime && isFuture(endDateTime)) {
      eventStatus = "Ongoing";
    }
    else eventStatus = "Historical";
  }
  else eventStatus = "Ongoing"

  console.log(`[determineEventStatus] event status -> ${eventStatus}`);

  return eventStatus;
}


// -> Cases
// 1. The event is multiple days away
// 2. The event is the next day (change start date to "Tomorrow" and then show time)
// 3. The event is on the current day but hasn't started yet
// 4. The event is on the current day, has started and spans throughout the day/evening/early morning (until 6AM next day)
// 5. The event is on the current day and spans to the next day past 6AM
// 6. The event is on the current day and spans for more than one day past current one
// 7. The event has concluded
export const computeEventDurationString = (
  {startDateTime, endDateTime, startTimeProvided, endTimeProvided}:
  {startDateTime: number, endDateTime?: number, startTimeProvided?: boolean, endTimeProvided?: boolean}
): string => {
  let computedEventDurationString: string = "";
  let computedStartDate: string = "";
  let computedEndDate: string = "";
  let computedStartTime: string = "";
  let computedEndTime: string = "";

  console.log(`distance object for interval: present to startdatetime -> ${JSON.stringify(intervalToDuration({ end: Date.now(), start: startDateTime }), null, 1)}`);
  if (endDateTime) console.log(`distance object for interval: present to enddatetime -> ${intervalToDuration({ start: Date.now(), end: endDateTime })}`);
  console.log(`formatted distance -> ${formatDistanceToNowStrict(startDateTime, { addSuffix: true, unit: 'day' })}`);
  console.log(`zoned-time -> ${utcToZonedTime(startDateTime, "America/Los_Angeles", { addSuffix: true,  })}`);

  computedStartDate = format(startDateTime, "EEEE, LLLL do, yyyy").toUpperCase();
  if (endDateTime) computedEndDate = format(endDateTime, "EEEE, LLLL do, yyyy").toUpperCase();
  if (startTimeProvided) {
    let isAM = true;
    let hoursResult = getHours(startDateTime);
    if (hoursResult >= 12) isAM = false;
    hoursResult = convert24HrHoursToAMPMHours(hoursResult);
    if (getMinutes(startDateTime) === 0) computedStartTime = `${hoursResult}`;
    else computedStartTime = `${hoursResult}:${getMinutes(startDateTime)}`;
    computedStartTime = (isAM) ? `${computedStartTime} AM` : `${computedStartTime} PM`;
  }
  
  if (endDateTime && endTimeProvided) {
    let isAM = true;
    let hoursResult = getHours(endDateTime);
    if (hoursResult >= 12) isAM = false;
    hoursResult = convert24HrHoursToAMPMHours(hoursResult);

    if (getMinutes(endDateTime) === 0) computedEndTime = `${hoursResult}`;
    else computedEndTime = `${getHours(hoursResult)}:${getMinutes(endDateTime)}`;
    computedEndTime = (isAM) ? `${computedEndTime} AM` : `${computedEndTime} PM`;
  }

  if (!endDateTime && !startTimeProvided) {
    computedEventDurationString = computedStartDate;
  }

  if (!endDateTime && startTimeProvided && !endTimeProvided) {
    computedEventDurationString = `${computedStartDate} AT ${computedStartTime}`;
  }
  
  if (endDateTime && startTimeProvided && endTimeProvided) {
    computedEventDurationString = `${computedStartDate} FROM ${computedStartTime} TO ${computedEndTime}`;
  }

  console.log(computedStartDate);
  console.log(computedEndDate);
  console.log(computedStartTime);
  console.log(computedEndTime);

  return computedEventDurationString;
}
