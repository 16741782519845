// import { LANGUAGE__ENGLISH } from "../../constants";
// import { AvailableLanguagesType } from "../../Types";
import { ThemeInfo } from "./UI_InfoProvider";

export enum themes {
  LIGHT = "light",
  DARK = "dark",
}

export type IUIContext = {
  navBarVisible: boolean;
  backdropVisible: boolean;
  drawerOpen: boolean;
  modalOpen: boolean;
  currentLanguage: string;
  currentTheme: string;
  adminLoginButtonVisible: boolean;
  toggleTheme(): void;
  setTheme(theme: string): void;
  toggleBackdrop(): void;
  openBackdrop(): void;
  closeBackdrop(): void;
  toggleDrawer(): void;
  openDrawer(): void;
  closeDrawer(): void;
  toggleModal(): void;
  openModal(): void;
  closeModal(): void;
  showNavBar(): void;
  hideNavBar(): void;
  setLanguage(language: string): void;
  themeInfo: ThemeInfo;
}

export const initContextValue: IUIContext = {
  navBarVisible: true,
  backdropVisible: false,
  drawerOpen: false,
  modalOpen: false,
  currentLanguage: "English",
  currentTheme: "Light",
  adminLoginButtonVisible: false,
  toggleTheme: () => {},
  setTheme: (theme: string): void => {},
  toggleBackdrop: () => {},
  openBackdrop: () => {},
  closeBackdrop: () => {},
  toggleDrawer: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
  toggleModal: () => {},
  openModal: () => {},
  closeModal: () => {},
  showNavBar: () => {},
  hideNavBar: () => {},
  setLanguage: (language: string): void => {},
  themeInfo: {
    palette: {},
    fonts: {},
    typography: {},
    distance: {},
    stackingIndexes: {},
    borderRadii: {},
    shadows: {},
    gradients: {},
    transitions: {},
    styles: {}
  }
}