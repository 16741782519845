// -> Beyond codebase 
import React, { useContext, useEffect, useRef, useState } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Formik, Form, FormikValues } from "formik";
import cloneDeep from "lodash.clonedeep";
// -> Within codebase
import Input from "../../../../Components/FormUtilities/Input/Input";
import Button from "../../../../Components/Button/Button";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import IconButton from "../../../../Components/Button/Variants/IconButton/IconButton";
import ImageWrapper from "../../../../Components/VisualUtilities/ImageWrapper/ImageWrapper";
import EditIcon from "../../../../Components/VisualUtilities/IconPresets/EditIcon";
import { GearIcon, PersonIcon } from "../../../../Components/VisualUtilities/IconPresets";
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { IUser } from "../../../../Types";
import {
  ALLOWED_IMAGE_UPLOAD_FILE_TYPES, HORIZONTAL, ROUND, ROUNDED, VERTICAL
} from "../../../../constants";
import { customAlertGen } from "../../../../helpers";
// -> Within component
import { styleGen } from "./EditEventFormStyles";
import {
  IEditEventFormFormikState, IEditEventFormProps, IEditEventFormState,
  DEFAULT_IMAGE_SIZE, initialManualFormState
} from "./helpers";

const EditEventForm: React.FC<IEditEventFormProps> = (props) => {

  return (
    <div>edit event form scaffold content</div>
  );
}

export default EditEventForm;
