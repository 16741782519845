import * as React from "react";
import { IIconProps } from "../../../Types"

const SvgTidalIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...Object.assign({}, {...props}, {...interpretedProps})}>
      <path d="M8.01 8.002l4-4.005 4.004 4-4 4.005zM24 8l-3.979-3.975L16.042 8l3.979 3.975zM.002 7.998l4.004-4 4 4.004-4.004 4z" />
      <path d="M8.01 15.997l4.004-4 4 4.004-4.004 4z" />
    </svg>
  );
}

export default SvgTidalIcon;

SvgTidalIcon.defaultProps = {
  color: "black"
}
