import { AvailableLanguagesType, AvailableThemesType, AvailableDisplayModesType, MFAType } from "src/Types";
import {
  LANGUAGE__ENGLISH, LANGUAGE__FRENCH, LANGUAGE_CODE__ENGLISH, LANGUAGE_CODE__FRENCH,
  LANGUAGE__ENGLISH_FR, LANGUAGE__FRENCH_FR, THEME__LIGHT_FR, THEME__LIGHT, THEME__DARK_FR,
  THEME__DARK, AVAILABLE_LANGUAGES, AVAILABLE_LANGUAGES_FR, AVAILABLE_THEMES, AVAILABLE_THEMES_FR,
  GRID, GRID_FR, LIST, LIST_FR, MFA_TYPE__NONE, MFA_TYPE__SMS, MFA_TYPE__EMAIL, MFA_TYPE__NONE_FR,
  MFA_TYPE__EMAIL_FR, MFA_TYPE__SMS_FR, FILTER_CONDITION__NONE, FILTER_CONDITION__NONE_FR,
  FILTER_CONDITION__EQUAL_TO, FILTER_CONDITION__EQUAL_TO_FR, FILTER_CONDITION__NOT_EQUAL_TO,
  FILTER_CONDITION__NOT_EQUAL_TO_FR, FILTER_CONDITION__GREATER_THAN, FILTER_CONDITION__GREATER_THAN_FR,
  FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO, FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO_FR,
  FILTER_CONDITION__LESS_THAN, FILTER_CONDITION__LESS_THAN_FR, FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO,
  FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO_FR, FILTER_CONDITION__INCLUDED_IN, FILTER_CONDITION__INCLUDED_IN_FR,
  FILTER_CONDITION__NOT_INCLUDED_IN, FILTER_CONDITION__NOT_INCLUDED_IN_FR, AVAILABLE_FILTER_CONDITIONS,
  AVAILABLE_FILTER_CONDITIONS_FR,
} from "../constants";

// ---- Language Codes ----- //

export const mapLanguageToLanguageCode = (language: string): string => {
  let languageCode: string;
  switch(language) {
    case LANGUAGE__ENGLISH:
      languageCode = LANGUAGE_CODE__ENGLISH;
      break;
    case LANGUAGE__FRENCH:
      languageCode = LANGUAGE_CODE__FRENCH;
      break;
    default:
      languageCode = LANGUAGE_CODE__ENGLISH;
      break;
  }

  return languageCode;
};

// -----

export const mapLanguageCodeToLanguage = (languageCode: string): string => {
  let language: string;

  switch(languageCode.toLowerCase().trim()) {
    case LANGUAGE_CODE__ENGLISH:
      language = LANGUAGE__ENGLISH;
      break;
    case LANGUAGE_CODE__FRENCH:
      language = LANGUAGE__FRENCH;
      break;
    default:
      language = LANGUAGE__ENGLISH;
      break;
  }

  return language;
};

// ----- Language Names ----- //

export const mapLanguageNameToEnglishLanguageName = (languageName: string, currentLanguageCode: string): AvailableLanguagesType => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let translatedLanguageName: AvailableLanguagesType = LANGUAGE__ENGLISH;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedLanguageName = languageName as AvailableLanguagesType;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (languageName) {
      case LANGUAGE__ENGLISH_FR:
        translatedLanguageName = LANGUAGE__ENGLISH;
        break;
      case LANGUAGE__FRENCH_FR:
        translatedLanguageName = LANGUAGE__FRENCH;
        break;
      default:
        translatedLanguageName = LANGUAGE__ENGLISH;
        break;
    }
  }

  return translatedLanguageName;
};

// -----

export const mapEnglishLanguageNameToTargetLanguage = (languageName: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedLanguageName: string = LANGUAGE__ENGLISH;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedLanguageName = languageName;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (languageName) {
      case LANGUAGE__ENGLISH:
        translatedLanguageName = LANGUAGE__ENGLISH_FR;
        break;
      case LANGUAGE__FRENCH:
        translatedLanguageName = LANGUAGE__FRENCH_FR;
        break;
      default:
        translatedLanguageName = LANGUAGE__ENGLISH_FR;
        break;
    }
  }

  return translatedLanguageName;
};

// ----- Theme Names ----- //

export const mapThemeNameToEnglishThemeName = (languageName: string, currentLanguageCode: string): AvailableThemesType => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let translatedThemeName: AvailableThemesType = THEME__LIGHT;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedThemeName = languageName as AvailableThemesType;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (languageName) {
      case THEME__LIGHT_FR:
        translatedThemeName = THEME__LIGHT;
        break;
      case THEME__DARK_FR:
        translatedThemeName = THEME__DARK;
        break;
      default:
        translatedThemeName = THEME__LIGHT;
        break;
    }
  }

  return translatedThemeName;
};

// -----

export const mapEnglishThemeNameToTargetLanguage = (themeName: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedThemeName: string = THEME__LIGHT;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedThemeName = themeName;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (themeName) {
      case THEME__LIGHT:
        translatedThemeName = THEME__LIGHT_FR;
        break;
      case THEME__DARK:
        translatedThemeName = THEME__DARK_FR;
        break;
      default:
        translatedThemeName = THEME__LIGHT_FR;
        break;
    }
  }

  return translatedThemeName;
};

// ----- Display Mode Names ----- //

export const mapDisplayModeNameToEnglishDisplayModeName =
  (displayModeName: string, currentLanguageCode: string): AvailableDisplayModesType => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let translatedDisplayModeName: AvailableDisplayModesType = GRID;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedDisplayModeName = displayModeName as AvailableDisplayModesType;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (displayModeName) {
      case GRID_FR:
        translatedDisplayModeName = GRID;
        break;
      case LIST_FR:
        translatedDisplayModeName = LIST;
        break;
      default:
        translatedDisplayModeName = GRID;
        break;
    }
  }

  return translatedDisplayModeName;
};

// -----

export const mapEnglishDisplayModeNameToTargetLanguage = (displayModeName: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedDisplayModeName: string = GRID;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedDisplayModeName = displayModeName;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (displayModeName) {
      case GRID:
        translatedDisplayModeName = GRID_FR;
        break;
      case LIST:
        translatedDisplayModeName = LIST_FR;
        break;
      default:
        translatedDisplayModeName = GRID_FR;
        break;
    }
  }

  return translatedDisplayModeName;
};

// ----- MFA Types ----- //

export const mapMFATypeNameToEnglishMFATypeName =
  (MFATypeName: string, currentLanguageCode: string): MFAType => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedMFATypeName: MFAType = "None";

  if (currentLanguage === LANGUAGE__ENGLISH) translatedMFATypeName = MFATypeName as MFAType;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (MFATypeName) {
      case MFA_TYPE__NONE_FR:
        translatedMFATypeName = MFA_TYPE__NONE;
        break;
      case MFA_TYPE__SMS_FR:
        translatedMFATypeName = MFA_TYPE__SMS;
        break;
      case MFA_TYPE__EMAIL_FR:
        translatedMFATypeName = MFA_TYPE__EMAIL;
        break;
      default:
        translatedMFATypeName = MFA_TYPE__NONE;
        break;
    }
  }

  return translatedMFATypeName;
};

// -----

export const mapEnglishMFATypeNameToTargetLanguage = (MFATypeName: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedMFATypeName: string = MFA_TYPE__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedMFATypeName = MFATypeName;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (MFATypeName) {
      case MFA_TYPE__NONE:
        translatedMFATypeName = MFA_TYPE__NONE_FR;
        break;
      case MFA_TYPE__SMS:
        translatedMFATypeName = MFA_TYPE__SMS_FR;
        break;
      case MFA_TYPE__EMAIL:
        translatedMFATypeName = MFA_TYPE__EMAIL_FR;
        break;
      default:
        translatedMFATypeName = MFA_TYPE__NONE_FR;
        break;
    }
  }

  return translatedMFATypeName;
};

// ----- Filter Conditions ----- //

export const mapFilterConditionToEnglishFilterCondition =
  (filterCondition: string, currentLanguageCode: string): string => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);

  let translatedFilterCondition: string = FILTER_CONDITION__NONE;

  if (currentLanguage === LANGUAGE__ENGLISH) translatedFilterCondition = filterCondition;
  else if (currentLanguage === LANGUAGE__FRENCH) {
    switch (filterCondition) {
      case FILTER_CONDITION__NONE_FR:
        translatedFilterCondition = FILTER_CONDITION__NONE;
        break;
      case FILTER_CONDITION__EQUAL_TO_FR:
        translatedFilterCondition = FILTER_CONDITION__EQUAL_TO;
        break;
      case FILTER_CONDITION__NOT_EQUAL_TO_FR:
        translatedFilterCondition = FILTER_CONDITION__NOT_EQUAL_TO;
        break;
      case FILTER_CONDITION__GREATER_THAN_FR:
        translatedFilterCondition = FILTER_CONDITION__GREATER_THAN;
        break;
      case FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO_FR:
        translatedFilterCondition = FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO;
        break;
      case FILTER_CONDITION__LESS_THAN_FR:
        translatedFilterCondition = FILTER_CONDITION__LESS_THAN;
        break;
      case FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO_FR:
        translatedFilterCondition = FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO;
        break;
      case FILTER_CONDITION__INCLUDED_IN_FR:
        translatedFilterCondition = FILTER_CONDITION__INCLUDED_IN;
        break;
      case FILTER_CONDITION__NOT_INCLUDED_IN_FR:
        translatedFilterCondition = FILTER_CONDITION__NOT_INCLUDED_IN;
        break;
      default:
        translatedFilterCondition = FILTER_CONDITION__NONE;
        break;
    }
  }

  return translatedFilterCondition;
};

// -----

export const mapEnglishFilterConditionToTargetLanguage = (filterCondition: string, targetLanguageCode: string): string => {
  const targetLanguage = mapLanguageCodeToLanguage(targetLanguageCode);
  let translatedFilterCondition: string = FILTER_CONDITION__NONE;

  if (targetLanguage === LANGUAGE__ENGLISH) translatedFilterCondition = filterCondition;
  else if (targetLanguage === LANGUAGE__FRENCH) {
    switch (filterCondition) {
      case FILTER_CONDITION__NONE:
        translatedFilterCondition = FILTER_CONDITION__NONE_FR;
        break;
      case FILTER_CONDITION__EQUAL_TO:
        translatedFilterCondition = FILTER_CONDITION__EQUAL_TO_FR;
        break;
      case FILTER_CONDITION__NOT_EQUAL_TO:
        translatedFilterCondition = FILTER_CONDITION__NOT_EQUAL_TO_FR;
        break;
      case FILTER_CONDITION__GREATER_THAN:
        translatedFilterCondition = FILTER_CONDITION__GREATER_THAN_FR;
        break;
      case FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO:
        translatedFilterCondition = FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO_FR;
        break;
      case FILTER_CONDITION__LESS_THAN:
        translatedFilterCondition = FILTER_CONDITION__LESS_THAN_FR;
        break;
      case FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO:
        translatedFilterCondition = FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO_FR;
        break;
      case FILTER_CONDITION__INCLUDED_IN:
        translatedFilterCondition = FILTER_CONDITION__INCLUDED_IN_FR;
        break;
      case FILTER_CONDITION__NOT_INCLUDED_IN:
        translatedFilterCondition = FILTER_CONDITION__NOT_INCLUDED_IN_FR;
        break;
      default:
        translatedFilterCondition = FILTER_CONDITION__NONE;
        break;
    }
  }

  return translatedFilterCondition;
};

// ----- Available Languages ----- //

export const determineAvailableLanguagesByLanguage = (currentLanguageCode: string): string[] => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let availableLanguages;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      availableLanguages = AVAILABLE_LANGUAGES;
      break;
    case LANGUAGE__FRENCH:
      availableLanguages = AVAILABLE_LANGUAGES_FR;
      break;
    default:
      availableLanguages = AVAILABLE_LANGUAGES;
      break;
  }

  return availableLanguages;
};

// ----- Available Themes ----- //

export const determineAvailableThemesByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let availableThemes;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      availableThemes = AVAILABLE_THEMES;
      break;
    case LANGUAGE__FRENCH:
      availableThemes = AVAILABLE_THEMES_FR;
      break;
    default:
      availableThemes = AVAILABLE_THEMES;
      break;
  }

  return availableThemes;
};

// ----- Available Display Modes ----- //

export const determineAvailableDisplayModesByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let availableDisplayModes;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      availableDisplayModes = [GRID, LIST];
      break;
    case LANGUAGE__FRENCH:
      availableDisplayModes = [GRID_FR, LIST_FR];
      break;
    default:
      availableDisplayModes = [GRID, LIST];
      break;
  }

  return availableDisplayModes;
};

// ----- Available filter Conditions ----- //

export const determineAvailableFilterConditionsByLanguage = (currentLanguageCode: string) => {
  const currentLanguage = mapLanguageCodeToLanguage(currentLanguageCode);
  let availableFilterConditions;

  switch(currentLanguage) {
    case LANGUAGE__ENGLISH:
      availableFilterConditions = AVAILABLE_FILTER_CONDITIONS;
      break;
    case LANGUAGE__FRENCH:
      availableFilterConditions = AVAILABLE_FILTER_CONDITIONS_FR;
      break;
    default:
      availableFilterConditions = AVAILABLE_FILTER_CONDITIONS;
      break;
  }

  return availableFilterConditions;
};
