import * as React from "react";
import { IIconProps } from "../../../Types";

const BandcampIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width={97.236}
      // height={97.236}
      viewBox="0 0 98 98"
      {...Object.assign({}, {...props}, {...interpretedProps})}
    >
      <path d="M0 78.075h70.006l27.23-58.914H27.23z" />
    </svg>
  );
}

export default BandcampIcon;

BandcampIcon.defaultProps = {
  color: "black"
}
