// -> Beyond codebase
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
// -> Within codebase
import FillUnderNavBarCradle from '../LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle';
import CircleLoader from '../Loaders/CircleLoader/CircleLoader';
import { AuthContext } from "../AuthContextProvider/AuthContextProvider";
import { ThemeInfo, UIContext } from '../UI_InfoProvider/UI_InfoProvider';
import { LOGIN_ROUTE } from '../../constants';
import { IUser } from '../../Types';

const LoginProtectedRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { getUser, authContextState: { loginCheckComplete } } = useContext(AuthContext);
  let user: IUser | undefined;

  // console.log(`[LoginProtectedRoute]: accessTokenRefreshing -> ${loginCheckComplete}`);

  if (loginCheckComplete) user = getUser();

  return (
    <Route {...rest} render={
      (props) => {
        if (!loginCheckComplete) return (
          <FillUnderNavBarCradle customCradleStyles={{
            display: "flex", flex: 1, minHeight: "100vh",
            justifyContent: "center", alignItems: "center",
            backgroundColor: themeInfo.palette.white,
          }}>
              <CircleLoader spinnerColor={themeInfo.palette.primary} />
          </FillUnderNavBarCradle>
        );
        else if (user) return <Component {...rest} {...props} />;
        else {
          return (
            <Redirect to={{
              pathname: LOGIN_ROUTE,
              state: { redirectURL: props.location }
            }}/>
          );
        }
      }
    }/>
  );
}

export default LoginProtectedRoute;
