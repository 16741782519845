import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../UI_InfoProvider/UI_InfoProvider";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LEFT, NAV_BAR_HEIGHT, DEFAULT_DRAWER_ANIMATION_TIME } from "../../constants";
import { determineScreenDimensions } from "../../helpers";
import { IDrawerProps } from "./helpers";


export const styleGen = (themeInfo: ThemeInfo, props: IDrawerProps, navBarVisible: boolean) => {
  const {
    backgroundColor, height, width, customCradleStyles,
    horizontalGap, position,
  } = props;
  const { width: screenWidth } = determineScreenDimensions();
  const { palette } = themeInfo;

  return StyleSheet.create({
    drawerCradle: {
      position: "fixed",
      // top: (NAV_BAR_HEIGHT / 2),
      // left: themeInfo.distance.namedVariants.screenPadding,
      top: 0,
      left: 0,
      // height: height ? height : (navBarVisible ? `calc(100vh - ${NAV_BAR_HEIGHT}px)` : '100vh'),
      height: height ? height : "100vh",
      width: (width) ? width : 350,
      "@media (max-width: 480px)": { width: (screenWidth * (4/5))},
      display: "flex",
      flexDirection: "column",
      backgroundColor: (backgroundColor) ? backgroundColor : palette.white,
      transform: (position === LEFT) ? 'translateX(-120%)' : 'translateX(120%)',
      transition: `all ${DEFAULT_DRAWER_ANIMATION_TIME}ms ease-in-out`,
      zIndex: themeInfo.stackingIndexes.drawerMenu,
    },
    drawerRaised: { boxShadow: themeInfo.shadows.one },
    drawerCradleRounded: { borderRadius: themeInfo.borderRadii.one },
    drawerCradleRound: { borderRadius: 50000 },
    drawerOpenTranslation: { transform: "translateX(0)" },
    leftGapStyle: { left: horizontalGap },
    rightGapStyle: { right: horizontalGap },
    customCradleStyles: { ...customCradleStyles },
  });
}
