import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { IRecentActionListItemProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IRecentActionListItemProps) => {
  // const {} = props;
  const {
    palette, distance, borderRadii, transitions, fonts, shadows,
  } = themeInfo;

  return StyleSheet.create({
    actionListItemCradle: {
      height: "auto",
      minHeight: 100,
      width: "96%",
      paddingTop: distance.two, paddingLeft: distance.two,
      paddingRight: distance.two, paddingBottom: distance.one,
      display: "flex",
      flexDirection: "column", 
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: borderRadii.one,
      transition: transitions.bgColorTransition,
      backgroundColor: palette.white,
      boxShadow: shadows.one,

      "&:hover": {
        backgroundColor: palette.grey1,
        cursor: "pointer",
      }
    },
    // listItemLeftContent: {
    //   height: "100%",
    //   width: "auto",
    //   display: "flex",
    //   flex: 1,
    //   alignItems: "center",
    //   // flexWrap: "wrap",
    // },
    // listItemRightContent: {
    //   height: "100%",
    //   width: "auto",
    //   display: "flex",
    //   alignItems: "center",
    // },
    topPortion: {
      width: "100%",
      height: "auto",
      display: "flex",
      flex: 1,
      justifyContent: "start",
      alignItems: "flex-start",
    },
    bottomPortion: {
      width: "100%",
      height: "auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    leftMetadata: {
      width: "auto",
      height: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",

      "@media (max-width: 450px)": {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }
    },
    rightMetadata: {
      width: "auto",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
    },
    actionDescriptionText: {
      fontFamily: fonts.primary,
      fontSize: "1.8rem",
      color: palette.black,
      fontWeight: "bold",
    },
    metadataItemRow: {
      height: "auto",
      width: "auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    metadataItemLabelText: {
      fontFamily: fonts.primary,
      fontSize: "1rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: palette.grey3,
      "@media (max-width: 450px)": { fontSize: "1rem" },
    },
    fallbackIconCradle: {
      height: 20,
      width: 20,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: "50%",
    },
    usernameText: {
      fontSize: "1rem",
      color: palette.black,
      fontFamily: fonts.primary,
    }
    // customMetadataItemLabelTextStyles: { ...customMetadataItemLabelTextStyles },
    // customMetadataItemValueTextStyles: { ...customMetadataItemValueTextStyles },
  });
};
