import * as React from "react";
import { IIconProps } from "../../../Types";

const SvgDeezerIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width={94} height={94}
      viewBox="0 0 95 95"
      {...Object.assign({}, {...props}, {...interpretedProps})}
    >
      <path d="M89 0H5a5 5 0 00-5 5v84a5 5 0 005 5h84a5 5 0 005-5V5a5 5 0 00-5-5zM22.204 68.775H7.978v-4.312h14.227l-.001 4.312zm0-5.603H7.978V58.86h14.227l-.001 4.312zm0-5.606H7.978v-4.313h14.227l-.001 4.313zm0-5.606H7.978v-4.312h14.227l-.001 4.312zm0-5.606H7.978v-4.311h14.227l-.001 4.311zm15.955 22.421H23.931v-4.312H38.16l-.001 4.312zm0-5.603H23.931V58.86H38.16l-.001 4.312zm0-5.606H23.931v-4.313H38.16l-.001 4.313zm15.957 11.209H39.884v-4.312h14.232v4.312zm0-5.603H39.884V58.86h14.232v4.312zm0-5.606H39.884v-4.313h14.232v4.313zm0-5.606H39.884v-4.312h14.232v4.312zm0-5.606H39.884v-4.311h14.232v4.311zm0-5.605H39.884v-4.313h14.232v4.313zm0-5.604H39.884v-4.313h14.232v4.313zm15.95 33.63H55.839v-4.312h14.229v4.312h-.002zm0-5.603H55.839V58.86h14.229v4.312h-.002zm0-5.606H55.839v-4.313h14.229v4.313h-.002zm0-5.606H55.839v-4.312h14.229v4.312h-.002zm0-5.606H55.839v-4.311h14.229v4.311h-.002zm15.955 22.421H71.794v-4.312h14.229v4.312h-.002zm0-5.603H71.794V58.86h14.229v4.312h-.002zm0-5.61H71.794V53.25h14.229v4.312h-.002zm0-5.602H71.794v-4.313h14.229v4.313h-.002zm0-5.606H71.794v-4.313h14.229v4.313h-.002zm0-5.607H71.794v-4.31h14.229v4.31h-.002zm0-5.605H71.794v-4.311h14.229v4.311h-.002zm0-5.606H71.794v-4.313h14.229v4.313h-.002z" />
    </svg>
  );
}

export default SvgDeezerIcon;

SvgDeezerIcon.defaultProps = {
  color: "black"
}
