import { StyleSheet } from  "aphrodite-jss";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { IAddOrEditVenueFormProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IAddOrEditVenueFormProps) => {
  const { palette, distance, fonts, shadows, borderRadii, typography } = themeInfo;
  const { customFormCradleStyles, customFormTitleTextStyles, isInCardMode, rtl } = props;

  return StyleSheet.create({
    formCradle: {
      height: "auto", // - TODO: -> Potentially fix the height at 100% and have any overflow content scroll
      width: "70%",
      maxWidth: 800,
      padding: (isInCardMode) ? distance.three : 0,
      boxShadow: (isInCardMode) ? shadows.one : "none",
      borderRadius: borderRadii.one,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      background: palette.white,
      overflow: "visible",

      "@media (max-width: 750px)": {
        width: "100%",
      },

      "@media (min-width: 1150px)": {
        width: 1000,
      }
    },
    topBarCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    labelCradle: {
      display: 'flex',
      alignItmes: 'center',
      justifyContent: (rtl) ? 'flex-end' : 'flex-start',
    },
    labelText: {
      ...themeInfo.typography.default,
      margin: 0,
    },
    titleText: {
      fontFamily: fonts.primary,
      fontSize: "3rem",
      fontWeight: "bold",
      color: palette.black,
      lineHeight: 0.8,
    },
    addImagePromptSurface: {
      height: "auto",
      minHeight: 150,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: borderRadii.two,
      background: palette.grey1,

      "&:hover": {
        cursor: "pointer",
      },
    },
    circularIconCradle: {
      height: 85,
      width: 85,
      display: "grid",
      placeItems: "center",
      background: "transparent",
      borderRadius: "50%",
      border: `3px solid ${palette.grey6}`
    },
    addInfoPromptText: {
      fontFamily: fonts.primary,
      fontStyle: "italic",
      fontSize: "1.6rem",
      color: palette.grey6,
    },
    oneItemCradle: {
      height: "auto",
      width: "100%",
      display: "grid",
      alignContent: "center",
      gridTemplateColumns: "1fr",
      gridAutoFlow: "row",
      gap: `${distance.three}px`,

      "@media (max-width: 550px)": {
        gridTemplateColumns: "1fr",
      }
    },
    twoItemCradle: {
      height: "auto",
      width: "100%",
      display: "grid",
      alignContent: "center",
      gridTemplateColumns: "1fr 1fr",
      gridAutoFlow: "row",
      gap: `${distance.three}px`,

      "@media (max-width: 550px)": {
        gridTemplateColumns: "1fr",
      }
    },
    labelAndDropdownColumn: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    descriptionCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
    },
    venueEventsTitleText: { ...typography.h2 },
    venueEventsCradle: {
      height: "min-content",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    venueEventPreviewsCradle: {
      height: "auto",
      width: "100%",
      display: "grid",
      // maxHeight: 750,
      // overflowY: "scroll",
      // padding: distance.one,
      alignContent: "center",
      gridTemplateColumns: "1fr 1fr",
      gridAutoFlow: "row",
      gap: `${distance.three}px`,

      "@media (max-width: 550px)": {
        gridTemplateColumns: "1fr",
      }
    },
    venueLinksCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      // overflowY: "scroll",
    },
    venueLinksPlaceholderSurface: {
      height: "100%",
      width: "100%",
      paddingTop: distance.three,
      paddingBottom: distance.three,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: borderRadii.two,
      background: palette.grey1,

      "&:hover": {
        cursor: "pointer",
      },
    },
    venueLinksTopRow: {
      height: "auto",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    venueLinksTitleText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "2.2rem",
      color: palette.black,
    },
    requiredElement: {
      color: palette.errorStatus,
      fontSize: "1.6rem",
      paddingTop: 1,
      lineHeight: 0.4,
    },
    bottomFormRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    itemSeparatorCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    customFormCradleStyles: { ...customFormCradleStyles },
    customFormTitleTextStyles: { ...customFormTitleTextStyles },

    // -> Address Typeahead //

    addressTypeAheadCradle: {
      position: 'relative',
      // minHeight: baseCradleHeight,
      height: "100%",
      width: "100%",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    addressTypeAheadBaseCradle: {
      height: "100%",
      width: "100%",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: distance.one,
      paddingBottom: 0,
      paddingTop: 0,
      backgroundColor: palette.white,
      border: `1px solid ${palette.grey2}`,
      borderRadius: borderRadii.one,
      // boxShadow: shadows.one,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    // - TODO: -> This is not doing the trick, want overflowing base item text not to wrap and to have an end ellipsis.
    staticBaseItemTextStyles: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    addressTypeAheadBaseItemTextStyles : {
      fontFamily: themeInfo.fonts.primary,
      fontSize: '1.4rem',
      color: palette.black,
    },
    addressTypeAheadDropCradle: {
      zIndex: themeInfo.stackingIndexes.dropdown,
      position: 'absolute',
      // left: computeLeftPropertyValue(props),
      // right: computeRightPropertyValue(props),
      left: 0, right: 0,
      // top: (baseCradleHeight && dropCradleGap) ? (baseCradleHeight + dropCradleGap) : 0, 
      top: 35,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: distance.one,
      backgroundColor: palette.white,
      border: 'none',
      height: 'auto',
      width: 'auto',
      // minHeight: minDropCradleHeight,
      // minWidth: computedMinDropCradleWidth,
      // maxHeight: maxDropCradleHeight,
      // maxWidth: maxDropCradleWidth,
      overflowY: 'scroll',
      overflowX: 'hidden',
      boxShadow: shadows.one,
      borderRadius: borderRadii.one,
    },
    // dropCradleRounded: {...borderRadiusConfig(props, themeInfo, state, {segment: 'dropcradle'})},
    // dropCradleRound: {...borderRadiusConfig(props, themeInfo, state, {segment: 'dropcradle'})},
    noSearchResultsAvailableCradle: {
      height: 50,
      width: "100%",
      display: "grid",
      placeItems: "center",
    },
    noSearchResultsAvailableText: { ...typography.noContentFallbackText },
    searchResultCradle: {
      width: 'auto',
      height: "100%",
    },
    innerSearchResultCradle: {
      height: '100%',
      width: 'auto',
      paddingLeft: distance.one,
      paddingRight: distance.one,
      paddingTop: distance.one,
      paddingBottom: distance.one,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      transition: themeInfo.transitions.bgColorTransition,
      borderRadius: borderRadii.one,
      backgroundColor: palette.white,
      '&:hover': {
        cursor: "pointer",
        backgroundColor: palette.grey1,
      },
    },
    searchResultText: {
      fontFamily: fonts.primary,
      // fontWeight: "bold",
      fontSize: "1.4rem",
      color: palette.black,
      "@media (max-width: 450px)": { fontSize: "1.2rem" },
    },
  });
}
