// -> Beyond codebase
import React, { useContext, useState, useRef, useEffect } from "react";
import * as yup from "yup";
import { Formik, Form, FormikValues, FormikProps } from "formik";
import { css } from "aphrodite-jss";
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import { FuseOptions } from "fuse.js";
import { DatePickerProps, TimePickerProps } from "antd";
import { DatePicker, TimePicker } from "antd";
// import throttle from "lodash.throttle";
// -> Within codebase
import TextArea from "src/Components/FormUtilities/TextArea/TextArea";
import Fade from "../../AnimationUtilities/TransitionWrappers/Fade/Fade";
import Input from "../../FormUtilities/Input/Input";
import Button from "../../Button/Button";
import LineSegment from "../../VisualUtilities/LineSegment/LineSegment";
import IconButton from "../../Button/Variants/IconButton/IconButton";
import PlusIcon from "../../VisualUtilities/IconPresets/PlusIcon";
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import ExternalLinkEntry from "../../FormUtilities/ExternalLinkEntry/ExternalLinkEntry";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import TypeAheadSearch from "src/Components/TypeAheadSearch/TypeAheadSearch";
import TypeAheadChosenResult from "../../FormUtilities/TypeAheadChosenResult/TypeAheadChosenResult";
import { externalEventLinkDropdownOptions as externalLinkDropdownOptions } from "../../FormUtilities/ExternalLinkEntry/helpers";
import { LinkIcon, TicketIcon } from "../../VisualUtilities/IconPresets";
import i18n from "src/i18n/i18n";
import { IDropdownItem } from "src/Components/Dropdown/stateManagement";
import Dropdown from "src/Components/Dropdown/Dropdown";
import { UIContext, ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../DataContextProvider/DataContextProvider";
import { Event, IPotentialExternalLinkEntry, Artist, Venue } from "../../../Types";
import {
  DEFAULT_TRANSITION_MICROANIMATION_TIME, HORIZONTAL, ROUND, URL_PATTERN_REGEX,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ALLOWED_IMAGE_UPLOAD_FILE_TYPES, VERTICAL, DEFAULT_SEARCH_DEBOUNCE_TIME,
  DEFAULT_INPUT_HEIGHT, ROUNDED, GREYSCALE, LEFT,
} from "../../../constants";
import { customAlertGen, generateDropdownItems } from "../../../helpers";
import { IAddEventArgs } from "../../../Pages/AddEvent/helpers";
import { ITypeAheadSearchItem } from "src/Components/TypeAheadSearch/stateManagement";
import { processTypeAheadSearchItems } from "src/Components/TypeAheadSearch/helpers";
import { getArtistFromID, getVenueFromID } from "src/lib";
// -> Within component
import {
  determineSubmissionButtonStatus, hydrateExternalLinkEntries,
  IAddOrEditEventFormProps, IAddOrEditEventFormState, mapEventTypeToEnglishEventType,
  determineAvailableEventTypesByLanguage, mapEnglishEventTypeToTargetLanguage,
} from "./helpers";
import { styleGen } from "./AddOrEditEventFormStyles";
import CalendarIcon from "src/Components/VisualUtilities/IconPresets/CalendarIcon";

const initialValues: Partial<Event> = {
  name: "",
  imageURL: "",
  description: "",
};

const AddOrEditEventForm: React.FC<IAddOrEditEventFormProps> = (props) => {
  const [state, setState] = useState<IAddOrEditEventFormState>({
    links: {},
    manualFormFieldsValid: true,
    microAnimationTimeoutIDs: [],
    eventLinksPlaceholderSurfaceInStatus: true,
    eventTicketsLinkPlaceholderSurfaceInStatus: true,
    ticketLinkEntryInStatus: true,
    eventStartDate: 0,
    eventStartTime: 0,
    eventEndDate: 0,
    eventEndTime: 0,
    eventType: "In Person",
    eventArtists: [],
    availableSearchedArtists: [],
    artistSearchTerm: "",
    artistSearchActive: false,
    eventVenues: [],
    availableSearchedVenues: [],
    venueSearchTerm: "",
    venueSearchActive: false,
  });
  const { onSubmit, formMode, eventDetails: eventDetailsFromProps, rtl } = props;
  const { t } = useTranslation((formMode === "ADD") ? "page_addEvent" : "page_editEvent");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, palette, styles, transitions, shadows, borderRadii } = themeInfo;
  const { state: { artists, venues }} = useContext(DataContext);
  const {
    formCradle, topBarCradle, titleText, customFormCradleStyles, customFormTitleTextStyles,
    addImagePromptSurface, addInfoPromptText, circularIconCradle, oneItemCradle,
    eventLinksCradle, bottomFormRow, eventLinksTitleText, eventLinksTopRow, itemSeparatorCradle,
    eventLinksPlaceholderSurface, eventArtistsOrVenuesCradle, labelCradle,
    requiredElement, eventArtistOrVenuePreviewsCradle, h2Text, twoItemCradle, eventDateTimesCradle,
    labelAndFieldColumn, labelText, eventTicketsLinkCradle, nameAndTypeCradle, customBorderStyle,
  } = styleGen(themeInfo, props);
  
  // let addressTypeAheadLoaderTimeout = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  let eventLinksPlaceholderSurfaceTimeout = useRef<any>(null);
  let resultsTimeout = useRef<any>(null);
  let formRef = useRef<FormikProps<FormikValues>>(null);
  let eventTicketsLinkPlaceholderSurfaceTimeout = useRef<any>(null);
  let eventTicketsLinkTimeout = useRef<any>(null);

  // -----

  const validationSchema = yup.object({
    name: yup.string().required(t("eventNameRequiredErrorMsg")),
    description: yup.string(),
    imageURL: yup.string().matches(URL_PATTERN_REGEX, t("validURLErrorMsg")),
  });

  // -> Clear microanimation timeouts when component unmounts to avoid memory leaks
  useEffect(() => {
    return () => {
      state.microAnimationTimeoutIDs.map((timeoutID) => clearTimeout(timeoutID));
      clearTimeout(resultsTimeout.current);
      clearTimeout(eventLinksPlaceholderSurfaceTimeout.current);
      clearTimeout(eventTicketsLinkPlaceholderSurfaceTimeout.current);
      clearTimeout(eventTicketsLinkTimeout.current);
    }
  }, [state]);

  // -----

  // - TODO: -> Finish onformsubmission function
  const onFormSubmission = (values: FormikValues, onSubmitProps: any) => {

  }

  // -----

  const onSetEventType = (eventType: string) => {
    setState((prevState) => ({
      ...prevState,
      eventType: mapEventTypeToEnglishEventType(eventType, i18n.language)
    }));
  };

  const onClickToSelectImage = () => inputRef.current?.click();

  const onSelectImage = () => {
    if (inputRef.current && inputRef.current.files && inputRef.current.files[0]) {
      const file = inputRef.current.files[0];
      const fileType = file.type;
      // console.log(`[AddOrEditVenueForm]: File name chosen -> ${inputRef.current.files[0].name}`);
      // console.log(`[AddOrEditVenueForm]: File type chosen -> ${inputRef.current.files[0].type}`);
      // console.log(`[AddOrEditVenueForm]: File size chosen -> ${inputRef.current.files[0].size}`);
      if (!ALLOWED_IMAGE_UPLOAD_FILE_TYPES.includes(fileType)) {
        customAlertGen(t("imgFormatErrorMsg"), themeInfo);
      } else {
        const fileObjURL = URL.createObjectURL(file);
        setState((prevState) => ({
          ...prevState,
          imageToUpload: file,
          imageUploadPreviewURL: fileObjURL
        }))
      }
    }
  };

  // -----

  const onSelectDate = (value: any, type: 'start' | 'end') => {

  }

  const onSelectTime = (value: any, type: 'start' | 'end') => {

  }

  // -----

  const addExternalLinkEntry = () => {
    const stateCopy = cloneDeep(state);
    const potentialExternalLinkEntry: IPotentialExternalLinkEntry = {
      linkName: "",
      URL: "",
      URLFieldTouched: false,
      URLFieldDirty: false,
      errorStatus: false,
      errorMessage: "Please enter a valid URL",
      microAnimationInStatus: true,
    };

    if (stateCopy.potentialExternalLinks) {
      stateCopy.potentialExternalLinks.push(potentialExternalLinkEntry);
    } else {
      stateCopy.potentialExternalLinks = [];
      stateCopy.potentialExternalLinks.push(potentialExternalLinkEntry);
    }

    // - TODO: -> Fix this microanimation such that the placeholder surface has time to fade out
    //            before the first potential link entry element fades in.
    if ((!stateCopy.potentialExternalLinks) || (stateCopy.potentialExternalLinks.length === 0)) {
      setState((prevState) => ({ ...prevState, eventLinksPlaceholderSurfaceInStatus: false }));
      eventLinksPlaceholderSurfaceTimeout.current = setTimeout(() => {
        setState(stateCopy);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    } else {
      setState(stateCopy);
    }
  };

  // -----

  // - DEV NOTE -> For whatever reason, the state I clone on the first line of the function already has
  //               the potential link entry missing from the array. As a stop gap, I pass in a copy of the
  //               former state in order to manipulate the boolean which will trigger the fade-out microanimation
  //               in the list item.
  const removeExternalLinkEntry = (indexToDelete: number, prevState: IAddOrEditEventFormState) => {
    const stateCopy = cloneDeep(state);
    
    if (stateCopy.potentialExternalLinks && prevState.potentialExternalLinks) {
      prevState.potentialExternalLinks[indexToDelete].microAnimationInStatus = false;
      setState((prevState) => ({ ...prevState, potentialExternalLinks: prevState.potentialExternalLinks }));
      const timeOut = setTimeout(() => {
        // - DEV NOTE -> Inner anonymous function seems to need a check on if this is undefined or not as well
        if (stateCopy.potentialExternalLinks) {
          stateCopy.potentialExternalLinks =
            stateCopy.potentialExternalLinks.filter((_, index: number) => {
            return index !== indexToDelete;
          });
        }
        stateCopy.microAnimationTimeoutIDs.push(timeOut);
        setState(stateCopy);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  };

  // -----

  const validateExternalLinkEntryURL = (index: number, URLEntry: string): boolean => {
    const stateCopy = cloneDeep(state);
    
    if (!stateCopy.potentialExternalLinks) return true;
    
    if (URL_PATTERN_REGEX.test(URLEntry) === false) {
      // console.log(`[AddOrEditVenueForm]: validateExternalLinkEntryURL(): URL was invalid`);
      if (stateCopy.manualFormFieldsValid) stateCopy.manualFormFieldsValid = false;
      stateCopy.potentialExternalLinks[index].errorStatus = true;
      // console.log(`[AddOrEditVenueForm]: validateExternalLinkEntryURL(): modified state copy after invalid field -> ${JSON.stringify(stateCopy, null, 1)}`);
      setState((_) => ({ ...stateCopy }));
      return false;
    }

    // - TODO: -> Review this after some time, seems sketch
    if (stateCopy.manualFormFieldsValid === false) {
      let anyFieldsHaveAnError = false;
      stateCopy.potentialExternalLinks.map((potentialExternalLink: IPotentialExternalLinkEntry) => {
        if (potentialExternalLink.errorStatus === true) anyFieldsHaveAnError = true;
        return true;
      });
  
      if (!anyFieldsHaveAnError) stateCopy.manualFormFieldsValid = true;
    }

    setState((_) => ({ ...stateCopy }));
    return true;
  };

  // -----

  const addTicketsLink = () => {
    const stateCopy = cloneDeep(state);
    const potentialTicketsLinkEntry: IPotentialExternalLinkEntry = {
      linkName: "",
      URL: "",
      URLFieldTouched: false,
      URLFieldDirty: false,
      errorStatus: false,
      errorMessage: "Please enter a valid URL",
      microAnimationInStatus: true,
    };

    if (stateCopy.potentialTicketsLink) {
      stateCopy.potentialTicketsLink = potentialTicketsLinkEntry;
    } else {
      stateCopy.potentialTicketsLink = undefined;
      stateCopy.potentialTicketsLink = potentialTicketsLinkEntry;
    }

    // - TODO: -> Fix this microanimation such that the placeholder surface has time to fade out
    //            before the first potential link entry element fades in.
    if (!stateCopy.potentialTicketsLink) {
      setState((prevState) => ({ ...prevState, eventTicketsLinkPlaceholderSurfaceInStatus: false }));
      eventTicketsLinkPlaceholderSurfaceTimeout.current = setTimeout(() => {
        // stateCopy.ticketLinkEntryInStatus = false;
        setState(stateCopy);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    } else {
      setState(stateCopy);
    }
  };

  // -----

  // - DEV NOTE -> For whatever reason, the state I clone on the first line of the function already has
  //               the potential link entry missing from the array. As a stop gap, I pass in a copy of the
  //               former state in order to manipulate the boolean which will trigger the fade-out microanimation
  //               in the list item.
  const removeTicketsLink = () => {
    const stateCopy = cloneDeep(state);
    
    if (stateCopy.potentialTicketsLink) {
      setState((prevState) => ({ ...prevState, ticketLinkEntryInStatus: false }));
      setState((prevState) => ({
        ...prevState, potentialTicketsLink: undefined,
        eventTicketsLinkPlaceholderSurfaceInStatus: true
      }));
      // - TODO: -> Code isn't running inside of this when I move it in
      eventTicketsLinkTimeout.current = setTimeout(() => {
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }
  };

  // -----

  const validateTicketsLinkEntryURL = (URLEntry: string): boolean => {
    const stateCopy = cloneDeep(state);
    
    if (!stateCopy.potentialExternalLinks) return true;
    
    if (URL_PATTERN_REGEX.test(URLEntry) === false) {
      if (stateCopy.manualFormFieldsValid) stateCopy.manualFormFieldsValid = false;
      stateCopy.potentialTicketsLink!.errorStatus = true;
      setState(stateCopy);
      return false;
    }

    // setState(stateCopy);
    return true;
  };

  // -----

  const onSelectArtist = (item: ITypeAheadSearchItem) => {
    const { id: artistID } = item;
    const stateCopy = cloneDeep(state);
    const artist = getArtistFromID(artistID, artists);
    if (artist) stateCopy.eventArtists.push(artist);
    stateCopy.availableSearchedArtists = [];
    stateCopy.artistSearchTerm = "";
    setState({ ...stateCopy });
  };

  // -----

  const onRemoveArtist = (artistID: string) => {
    const stateCopy = cloneDeep(state);
    const { eventArtists } = stateCopy;
    const index = eventArtists.findIndex((artist: Artist) => artist.id === artistID);
    stateCopy.eventArtists.splice(index, 1);
    setState(() => ({ ...stateCopy }));
  };
  
  // -----

  const handleArtistSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { artistSearchActive } = state;
    const searchTerm = event.target.value;
    if (!artistSearchActive) setState((prevState) => ({ ...prevState, artistSearchActive: true }))
    setState((prevState) => ({ ...prevState, artistSearchTerm: searchTerm }));
    // throttle(() => {

    // }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    // resultsTimeout.current = setTimeout(() => {
    // - TODO: -> Eventually this will be an async server call
    // setTimeout(() => {
    // }, 1500);
    const candidateArtists = searchForArtists(searchTerm, artists);
    const availableSearchedArtists = processTypeAheadSearchItems(candidateArtists);
    setState((prevState) => ({ ...prevState, availableSearchedArtists, artistSearchActive: false }));
  };

  // -----

  const searchForArtists = (searchTerm: string, artists: Artist[]): Artist[] => {
    let artistSearchResults: any[] = [];
    if (searchTerm === "") return [];
    
    const searchEngineOptions: FuseOptions<Artist> = {
      keys: ["name"],
      shouldSort: true, // -> Results sorted by relevance score
      findAllMatches: true,
      threshold: 0.4, // -> This is the default value, it can be tweaked from 0 to 1
    };
  
    const fuse = new Fuse(artists, searchEngineOptions);
  
    artistSearchResults = fuse.search(searchTerm);
    const availableSearchedEvents: ITypeAheadSearchItem[] = processTypeAheadSearchItems(artistSearchResults);

    setState((prevState) => ({ ...prevState, availableSearchedEvents }));

    return artistSearchResults;
  };

  // -----

  const onSelectVenue = (item: ITypeAheadSearchItem) => {
    const { id: venueID } = item;
    const stateCopy = cloneDeep(state);
    const venue = getVenueFromID(venueID, venues);
    if (venue) stateCopy.eventVenues.push(venue);
    stateCopy.availableSearchedVenues = [];
    stateCopy.venueSearchTerm = "";
    setState({ ...stateCopy });
  };

  // -----

  const onRemoveVenue = (venueID: string) => {
    const stateCopy = cloneDeep(state);
    const { eventVenues } = stateCopy;
    const index = eventVenues.findIndex((venue: Venue) => venue.id === venueID);
    stateCopy.eventVenues.splice(index, 1);
    setState(() => ({ ...stateCopy }));
  };
  
  // -----

  const handleVenueSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { venueSearchActive } = state;
    const searchTerm = event.target.value;
    if (!venueSearchActive) setState((prevState) => ({ ...prevState, venueSearchActive: true }))
    setState((prevState) => ({ ...prevState, venueSearchTerm: searchTerm }));
    // throttle(() => {

    // }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    // resultsTimeout.current = setTimeout(() => {
    // - TODO: -> Eventually this will be an async server call
    // setTimeout(() => {
    // }, 1500);
    const candidateVenues = searchForVenues(searchTerm, venues);
    const availableSearchedVenues = processTypeAheadSearchItems(candidateVenues);
    setState((prevState) => ({ ...prevState, availableSearchedVenues, artistSearchActive: false }));
  };

  // -----

  const searchForVenues = (searchTerm: string, venues: Venue[]): Venue[] => {
    let venueSearchResults: any[] = [];
    if (searchTerm === "") return [];
    
    const searchEngineOptions: FuseOptions<Venue> = {
      keys: ["name"],
      shouldSort: true, // -> Results sorted by relevance score
      findAllMatches: true,
      threshold: 0.4, // -> This is the default value, it can be tweaked from 0 to 1
    };
  
    const fuse = new Fuse(venues, searchEngineOptions);
  
    venueSearchResults = fuse.search(searchTerm);
    const availableSearchedVenues: ITypeAheadSearchItem[] = processTypeAheadSearchItems(venueSearchResults);

    setState((prevState) => ({ ...prevState, availableSearchedVenues }));

    return venueSearchResults;
  };

  // -----

  const eventTypeDropdownItems: IDropdownItem[] =
    generateDropdownItems(determineAvailableEventTypesByLanguage(i18n.language));

  const {
    potentialExternalLinks, imageUploadPreviewURL, artistSearchTerm,
    eventLinksPlaceholderSurfaceInStatus, availableSearchedArtists,
    eventArtists, artistSearchActive, eventType, venueSearchTerm,
    availableSearchedVenues, eventVenues, venueSearchActive, potentialTicketsLink,
    eventTicketsLinkPlaceholderSurfaceInStatus, ticketLinkEntryInStatus,
  } = state;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmission}
      innerRef={formRef}
    >
      {
        ({isValid, dirty, isSubmitting, setFieldValue, values}) => (
          <Form className={css(formCradle, customFormCradleStyles)}>
            {/* TITLE */}
            <div className={css(topBarCradle)}>
              <p className={css(titleText, customFormTitleTextStyles)}>{t("pageTitle")}</p>
            </div>

            <Spacer direction={VERTICAL} amount={distance.three} />
            <LineSegment direction={HORIZONTAL} width={"100%"} size={2} color={palette.black} />
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* IMAGE UPLOAD */}
            <div className={`${css(addImagePromptSurface)} button`} onClick={onClickToSelectImage}>
              {
                // - DEV NOTE -> Edge case that there is no new image primed for upload and that the form
                //               is in edit mode/there's a pre-existing image to show.
                ((!imageUploadPreviewURL) && (formMode === "EDIT" && eventDetailsFromProps && eventDetailsFromProps.imageURL)) ? (
                  <ImageWrapper
                    alt={t(`Venue: ${eventDetailsFromProps.name}`)} src={eventDetailsFromProps.imageURL}
                    borderGeometry={ROUNDED}
                    height={85} width={85}
                  />
                ) : (
                  <>
                    {
                      (imageUploadPreviewURL) ? (
                        <ImageWrapper
                          alt={t("eventImageUploadPreviewAltText")} src={imageUploadPreviewURL}
                          borderGeometry={ROUNDED}
                          height={85} width={85}
                        />
                      ) : (
                        <div className={css(circularIconCradle)}>
                          <CalendarIcon color={palette.grey6} size={50} />
                        </div>
                      )
                    }
                  </>
                )
              }
              <Spacer direction={VERTICAL} amount={distance.three} />
              <p className={css(addInfoPromptText)}>{`+ ${t("addEventImagePrompt")}`}</p>
              <input type="file" onChange={onSelectImage} ref={inputRef} hidden />
            </div>
            
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* EVENT NAME AND TYPE */}
            <div className={css(nameAndTypeCradle)}>
              {/* <Input
                name="imageURL"
                label={t("imageURLInputLabel")}
                mode="border"
                customInputTextStyles={{ fontSize: "1.4rem" }}
                customControlCradleStyles={{ width: "100%" }}
              /> */}

              <Input
                name="name"
                label={t("eventNameInputLabel")}
                mode="border"
                required
                customInputTextStyles={{ fontSize: "1.4rem" }}
                customControlCradleStyles={{  width: "100%", }}
              />

              <div className={css(labelAndFieldColumn)}>
                <div style={{ display: 'flex', justifyContent: (rtl) ? 'flex-end' : 'flex-start' }}>
                  <p className={css(labelText)}>{t("eventTypeDropdownLabel")}</p>
                  <div style={{ marginRight: 2}}></div>
                  <p className={css(requiredElement)}>*</p>
                </div>
                <Spacer direction={VERTICAL} amount={distance.one} />
                <Dropdown
                  selectedItemValue={mapEnglishEventTypeToTargetLanguage(eventType, i18n.language)}
                  onSelectItem={onSetEventType}
                  items={eventTypeDropdownItems}
                  colorMode={GREYSCALE}
                  dropCradleAlignment={LEFT}
                  customDropdownCradleStyles={{ height: DEFAULT_INPUT_HEIGHT, width: "100%" }}
                />
              </div>
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* START AND END DATE/TIME */}
            <div className={css(eventDateTimesCradle)}>
              <div className={css(labelAndFieldColumn)}>
                <div style={{ display: 'flex', justifyContent: (rtl) ? 'flex-end' : 'flex-start' }}>
                  <p className={css(labelText)}>{t("eventStartDateLabel")}</p>
                  <div style={{ marginRight: 2}}></div>
                  <p className={css(requiredElement)}>*</p>
                </div>
                <Spacer direction={VERTICAL} amount={distance.one} />
                <div className={css(customBorderStyle)}>
                  <DatePicker
                    onChange={(value) => onSelectDate(value, 'start')}
                    bordered={false}
                  />
                </div>
              </div>
              <div className={css(labelAndFieldColumn)}>
                <p className={css(labelText)}>{t("eventStartTimeLabel")}</p>
                <Spacer direction={VERTICAL} amount={distance.one} />
                <TimePicker onChange={(value) => onSelectTime(value, 'start')}/>
              </div>
              <div className={css(labelAndFieldColumn)}>
                <p className={css(labelText)}>{t("eventEndDateLabel")}</p>
                <Spacer direction={VERTICAL} amount={distance.one} />
                <DatePicker onChange={(value) => onSelectDate(value, 'end')}/>
              </div>
              <div className={css(labelAndFieldColumn)}>
                <p className={css(labelText)}>{t("eventEndTimeLabel")}</p>
                <Spacer direction={VERTICAL} amount={distance.one} />
                <TimePicker onChange={(value) => onSelectTime(value, 'end')}/>
              </div>
            </div>
            <Spacer direction={VERTICAL} amount={distance.two} />

            {/* DESCRIPTION */}
            <div className={css(oneItemCradle)}>
              <TextArea
                name="description"
                mode="border"
                label={t("descriptionLbl")}
                // width="100%"
                maxWidth="100%"
                // height={90}
                customControlCradleStyles={{ width: "100%" }}
                customTextAreaCradleStyles={{ resize: "vertical", height: 75, fontSize: "1.4rem" }}
              />
            </div>
            <Spacer direction={VERTICAL} amount={distance.two} />

            {/* EVENT ARTISTS */}
            <div className={css(eventArtistsOrVenuesCradle)}>
              <div className={css(labelCradle)}>
                <p className={css(h2Text)}>
                  {(eventArtists && eventArtists.length > 1) ? t("eventArtistsLbl_plural") : t("eventArtistsLbl_one")}
                </p>
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />
              
              <TypeAheadSearch
                loadingStatus={artistSearchActive}
                searchTerm={artistSearchTerm}
                handleSearchInputChange={handleArtistSearchInputChange}
                onSelectItem={onSelectArtist}
                items={availableSearchedArtists}
                customBaseCradleStyles={{ height: "100%", width: "100%" }}
                customTypeAheadSearchCradleStyles={{ height: "auto", width: "100%", paddingBottom: 0 }}
                noSearchResultsAvailableMsg={t("noEventSearchResultsAvailableMsg")}
                baseCradleHeight={DEFAULT_INPUT_HEIGHT}
                colorMode="Greyscale"
                showBaseCradleIcon={false}
              />
              <Spacer direction={VERTICAL} amount={distance.three} />

              <div className={css(eventArtistOrVenuePreviewsCradle)}>
                {
                  // eslint-disable-next-line array-callback-return
                  eventArtists.map((artist: Artist) => {
                    const { id, name, imageURL } = artist;

                    return (
                      <TypeAheadChosenResult
                        key={id}
                        id={id}
                        name={name}
                        imageURL={imageURL}
                        onRemoveItem={() => onRemoveArtist(id)}
                      />
                    );
                  })
                }
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />
            </div>

            {/* EVENT VENUE(S) */}
            <div className={css(eventArtistsOrVenuesCradle)}>
              <div className={css(labelCradle)}>
                <p className={css(h2Text)}>
                  {(eventVenues && eventVenues.length > 1) ? t("eventVenuesLbl_plural") : t("eventVenuesLbl_one")}
                </p>
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />
              
              <TypeAheadSearch
                loadingStatus={venueSearchActive}
                searchTerm={venueSearchTerm}
                handleSearchInputChange={handleVenueSearchInputChange}
                onSelectItem={onSelectVenue}
                items={availableSearchedVenues}
                customBaseCradleStyles={{ height: "100%", width: "100%" }}
                customTypeAheadSearchCradleStyles={{ height: "auto", width: "100%", paddingBottom: 0 }}
                noSearchResultsAvailableMsg={t("noEventSearchResultsAvailableMsg")}
                baseCradleHeight={DEFAULT_INPUT_HEIGHT}
                colorMode="Greyscale"
                showBaseCradleIcon={false}
              />
              <Spacer direction={VERTICAL} amount={distance.three} />

              <div className={css(eventArtistOrVenuePreviewsCradle)}>
                {
                  eventVenues.map((venue: Venue) => {
                    const { id, name, imageURL } = venue;

                    return (
                      <TypeAheadChosenResult
                        key={id}
                        id={id}
                        name={name}
                        imageURL={imageURL}
                        onRemoveItem={() => onRemoveVenue(id)}
                      />
                    );
                  })
                }
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />
            </div>

            {/* TICKETS LINK */}
            <div className={css(eventTicketsLinkCradle)}>
              <p className={css(h2Text)}>{t("eventTicketsLinkLabel")}</p>
            </div>
            <Spacer direction={VERTICAL} amount={distance.two} />

            {
              (potentialTicketsLink) ? (
                <Fade inStatus={ticketLinkEntryInStatus}>
                  <ExternalLinkEntry
                    state={state}
                    setState={setState}
                    index={1}
                    linkName={potentialTicketsLink.linkName}
                    URL={potentialTicketsLink.URL}
                    errorStatus={potentialTicketsLink.errorStatus}
                    errorMessage={potentialTicketsLink.errorMessage}
                    URLFieldTouched={potentialTicketsLink.URLFieldTouched}
                    URLFieldDirty={potentialTicketsLink.URLFieldDirty}
                    removeExternalLinkEntry={removeTicketsLink}
                    validateExternalLinkEntryURL={validateTicketsLinkEntryURL}
                    type="EVENT_TICKETS"
                  />
                </Fade>
                ) : (
                <Fade inStatus={eventTicketsLinkPlaceholderSurfaceInStatus}>
                  <div className={css(eventLinksPlaceholderSurface)} onClick={addTicketsLink}>
                    <div className={css(circularIconCradle)}>
                      <TicketIcon color={palette.grey6} size={50} />
                    </div>
                    <Spacer direction={VERTICAL} amount={distance.three} />
                    <p className={css(addInfoPromptText)}>{`+ ${t("addTicketsLinkPrompt")}`}</p>
                  </div>
                </Fade>
              )
            }
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* EXTERNAL LINKS */}
            <div className={css(eventLinksCradle)}>
              <div className={css(eventLinksTopRow)}>
                <p className={css(eventLinksTitleText)}>{t("externalLinksLabel")}</p>
                <Spacer direction={HORIZONTAL} amount={distance.four} />
                <IconButton
                  icon={<PlusIcon color={palette.white} size={styles.standardIconSize - 5} />}
                  onClick={addExternalLinkEntry}
                  buttonGeometry={ROUND}
                  disabled={
                    state.potentialExternalLinks &&
                    state.potentialExternalLinks.length >= externalLinkDropdownOptions.length
                  }
                  customCradleStyles={{
                    padding: 3,
                    backgroundColor: palette.primary,
                    transition: transitions.boxShadowTransition
                  }}
                  customCradleHoverStyles={{ boxShadow: shadows.one }}
                />
              </div>
              <Spacer direction={VERTICAL} amount={distance.two} />

              {
                (potentialExternalLinks && (potentialExternalLinks.length > 0)) ? (
                  potentialExternalLinks.map((potentialExternalLink: IPotentialExternalLinkEntry, index: number) => {
                    const {
                      linkName, URL, errorStatus, errorMessage, URLFieldTouched,
                      URLFieldDirty, microAnimationInStatus
                    } = potentialExternalLink;

                    return (
                      <div key={index}>
                        <Fade inStatus={microAnimationInStatus}>
                          <ExternalLinkEntry
                            state={state}
                            setState={setState}
                            index={index}
                            linkName={linkName}
                            URL={URL}
                            errorStatus={errorStatus}
                            errorMessage={errorMessage}
                            URLFieldTouched={URLFieldTouched}
                            URLFieldDirty={URLFieldDirty}
                            removeExternalLinkEntry={removeExternalLinkEntry}
                            validateExternalLinkEntryURL={validateExternalLinkEntryURL}
                            type="EVENT"
                          />
                        </Fade>
                        {
                          (index < (potentialExternalLinks.length - 1)) ? (
                            <>
                              <Spacer direction={VERTICAL} amount={distance.two} />
                              <div className={css(itemSeparatorCradle)}>
                                <LineSegment
                                  direction={HORIZONTAL}
                                  width={`calc(100% - ${distance.six}px)`}
                                  size={1}
                                  color={palette.grey1}
                                />
                              </div>
                              <Spacer direction={VERTICAL} amount={distance.two} />
                            </>
                          ) : null
                        }
                      </div>
                    );
                  })
                ) : (
                  <Fade inStatus={eventLinksPlaceholderSurfaceInStatus}>
                    <div className={css(eventLinksPlaceholderSurface)} onClick={addExternalLinkEntry}>
                      <div className={css(circularIconCradle)}>
                        <LinkIcon color={palette.grey6} size={50} />
                      </div>
                      <Spacer direction={VERTICAL} amount={distance.three} />
                      <p className={css(addInfoPromptText)}>{`+ ${t("addExternalLinkPrompt")}`}</p>
                    </div>
                  </Fade>
                )
              }
            </div>
            <Spacer direction={VERTICAL} amount={distance.three} />

            {/* FORM SUBMISSION */}

            <div className={css(bottomFormRow)}>
              <Button
                onClick={() => {}}
                buttonText={(formMode === "ADD") ? t("addEventBtnLbl") : t("editEventBtnLbl")}
                type="submit"
                disabled={isSubmitting || determineSubmissionButtonStatus(props, { isValid, dirty })}
                customTextStyles={{ fontSize: "1.6rem", fontWeight: "bold" }}
                customCradleStyles={{
                  paddingTop: distance.one,
                  paddingBottom: distance.one,
                  paddingRight: distance.two,
                  paddingLeft: distance.two,
                  height: 35,
                }}
              />
            </div>
          </Form>
        )
      }
    </Formik>
  );
}

export default AddOrEditEventForm;

AddOrEditEventForm.defaultProps = {
  isInCardMode: true,
};
