export interface IEnableMFAState {
  loadingStatus: boolean;
  errorStatus: boolean;
  addPhoneNumberStatus: boolean;
  errorHeaderMessage?: string;
  errorMessage?: string;
  loaderInStatus: boolean;
  addPhoneNumberInStatus: boolean;
  mainContentInStatus: boolean;
}

export const DEFAULT_CARD_WIDTH = 350;
export const DEFAULT_CARD_HEIGHT = 290;
