import { ITypeAheadSearchItem } from "src/Components/TypeAheadSearch/stateManagement";
import { BANDCAMP } from "src/constants";
import { Artist, ArtistLinks, Event, IPotentialExternalLinkEntry } from "../../../Types";

export interface IAddOrEditArtistFormProps {
  formMode: 'ADD' | 'EDIT';
  artistDetails?: Artist;
  onSubmit: any;
  isInCardMode?: boolean;
  customFormCradleStyles?: any;
  customFormTitleTextStyles?: any;
  rtl?: boolean;
}

export interface IAddOrEditArtistFormState {
  links?: ArtistLinks
  potentialExternalLinks?: IPotentialExternalLinkEntry[];
  manualFormFieldsValid: boolean;
  microAnimationTimeoutIDs: any[];
  artistLinksPlaceholderSurfaceInStatus: boolean;
  imageUploadPreviewURL?: string;
  imageToUpload?: any; // - TODO: -> What kind of data structure will the file be?
  eventSearchActive: boolean;
  artistEvents: Event[];
  eventSearchTerm: string;
  availableSearchedEvents: ITypeAheadSearchItem[];
}

export const hydrateExternalLinkEntries = (links: ArtistLinks): IPotentialExternalLinkEntry[] => {
  const hydratedLinkEntries: IPotentialExternalLinkEntry[] = [];

  Object.keys(links).map((linkKey: string) => {
    let newLinkEntry: IPotentialExternalLinkEntry = {
      linkName: BANDCAMP,
      URL: "",
      URLFieldTouched: true,
      URLFieldDirty: false,
      errorStatus: false,
      errorMessage: "Please enter a valid URL",
      microAnimationInStatus: true,
    };

    newLinkEntry.linkName = linkKey;
    newLinkEntry.URL = links[linkKey];

    hydratedLinkEntries.push(newLinkEntry);
    return "";
  })

  return hydratedLinkEntries;
}

export const determineSubmissionButtonStatus = (
  props: IAddOrEditArtistFormProps,
  { isValid, dirty }: { isValid: boolean, dirty: boolean }
) => {
  const { formMode } = props;
  let buttonDisabled = false;

  if (formMode === "ADD") {
    if (!isValid || !dirty) buttonDisabled = true;
  }

  return buttonDisabled;
}
