// -> Beyond codebase
import React, { useContext, useEffect, useRef, useState } from "react";
import { css } from 'aphrodite-jss';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import VerticalFade from "../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import FillUnderNavBarCradle from "src/Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import StatusMessage from "../../Components/VisualUtilities/StatusMessage/StatusMessage";
import AddOrEditArtistForm from "src/Components/Forms/AddOrEditArtistForm/AddOrEditArtistForm";
import Spacer from "../../Components/LayoutUtilities/Spacer/Spacer";
import { XIcon } from "../../Components/VisualUtilities/IconPresets";
import { ThemeInfo, UIContext } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../Components/DataContextProvider/DataContextProvider";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, VERTICAL } from "../../constants";
import { underConstructionAlert } from "../../helpers";
// -> Within component
import { styleGen } from "./EditArtistStyles";
import { IEditArtistState, IEditArtistArgs } from "./helpers";

const EditArtist: React.FC = () => {
  const [state, setState] = useState<IEditArtistState>({
    loadingStatus: true,
    errorStatus: false,
    pageContentInStatus: true,
    loaderInStatus: true,
    formInStatus: true,
  });
  
  const { artistID }: { artistID: string } = useParams();
  const { t } = useTranslation("page_editArtist");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { getArtistFromID } = useContext(DataContext);
  const { palette, distance } = themeInfo;
  const { pageCradle } = styleGen(themeInfo);

  let formTimeout = useRef<any>(null);
  let loaderTimeout = useRef<any>(null);

  useEffect(() => (() => {
    clearTimeout(formTimeout.current);
    clearTimeout(loaderTimeout.current);
  }), []);

  useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, loaderInStatus: false }));
      loaderTimeout.current = setTimeout(() => {
        const artistDetails = getArtistFromID(artistID);
        setState((prevState) => ({
          ...prevState, loadingStatus: false,
          formInStatus: true, artistDetails,
        }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }, 1000);
  }, [artistID, getArtistFromID]);

  const onEditArtistSubmission = async (args: IEditArtistArgs) => {
    underConstructionAlert();
  }

const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadingStatus, loaderInStatus, errorStatus, formInStatus, artistDetails
  } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {
          (loadingStatus) ? (
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={palette.primary} />
            </Fade>
          ) : (
            <VerticalFade inStatus={formInStatus}>
              <>
                {
                  (errorStatus) && (
                    <>
                      <StatusMessage
                        color={palette.errorStatus}
                        headerText={t("unsuccessfulVenueAdditionMsg")}
                        icon={<XIcon color={palette.errorStatus} size={40} />}
                      />
                      <Spacer direction={VERTICAL} amount={distance.three} />
                    </>
                  )
                }
                <AddOrEditArtistForm
                  onSubmit={onEditArtistSubmission}
                  formMode="EDIT"
                  artistDetails={artistDetails}
                />
              </>
            </VerticalFade>
          )
        }
      </div>
    </FillUnderNavBarCradle>
  );
}

export default EditArtist;
