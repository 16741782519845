import { StyleSheet } from "aphrodite-jss";
import { determineScreenDimensions } from "../../../helpers";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import {
  VenueCardProps, BASE_CARD_HEIGHT, CARD_ASPECT_RATIO,
  CARD_ASPECT_RATIO_INVERSE,
} from "./helpers";

export const styleGen = (
  themeInfo: ThemeInfo, props: VenueCardProps, imgSectionHeight: number
) => {
  const { customCardCradleStyles } = props;
  const { distance, palette, borderRadii, fonts, shadows, transitions } = themeInfo;

  const { width: screenWidth } = determineScreenDimensions();
  const computedSmallWidth = (screenWidth / 2) - distance.nine;

  return StyleSheet.create({
    cardCradle: {
      height: BASE_CARD_HEIGHT,
      width: BASE_CARD_HEIGHT * CARD_ASPECT_RATIO,
      padding: distance.two,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: palette.white,
      borderRadius: borderRadii.one,
      boxShadow: shadows.one,
      transition: transitions.bgColorTransition,

      "&:hover": {
        cursor: "pointer",
        backgroundColor: palette.grey1,
      },

      "@media (max-width: 450px)": {
        width: computedSmallWidth,
        height: computedSmallWidth * CARD_ASPECT_RATIO_INVERSE,
      }
    },
    imgSection: {
      height: "65%",
      width: "100%",
      display: "grid",
      placeItems: "center",
    },
    fallbackIconCradle: {
      height: imgSectionHeight - 15,
      width: imgSectionHeight - 15,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: borderRadii.one,
    },
    bottomRow: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    nameText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "2.4rem",
      color: palette.black,

      "@media (max-width: 450px)": { fontSize: "1.8rem" },
    },
    customCardCradleStyles: { ...customCardCradleStyles },
  });
};
