// -> Beyond Codebase
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useContext } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
// -> Within Codebase
// import { ThemeInfo, UIContext } from '../../Components/UI_InfoProvider/UI_InfoProvider';
import Spacer from 'src/Components/LayoutUtilities/Spacer/Spacer';
import VenueCard from "../../Components/Cards/VenueCard/VenueCard";
import VenueListItem from '../../Components/ListItems/VenueListItem/VenueListItem';
import EventCard from "src/Components/Cards/EventCard/EventCard";
import FillUnderNavBarCradle from '../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle';
import { NAV_BAR_HEIGHT, VERTICAL } from '../../constants';
import { underConstructionAlert } from '../../helpers';
import { events } from "../../staticData/events";
import { DatePicker, TimePicker } from "antd";

const styleGen = () => {
  return StyleSheet.create({
    cradle: {
      height: "auto",
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      width: '100%',
      padding: 15,
      display: 'flex',
      flexDirection: "column",
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: 'scroll',
    },
  });
}

const TestGround: React.FC = () => {
  const { cradle } = styleGen();
  // const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);

  const onSelectDate = (value: any, type: 'start' | 'end') => {

  }

  const onSelectTime = (value: any, type: 'start' | 'end') => {

  }

  return (
    <FillUnderNavBarCradle>
      <div className={css(cradle)}>
        <DatePicker onChange={(value) => onSelectDate(value, 'start')}/>
        <TimePicker onChange={(value) => onSelectTime(value, 'start')}/>     
      </div>

    </FillUnderNavBarCradle>
  );
}

export default TestGround;
