// -> Beyond Codebase
import React, { useContext } from 'react';
import { Field, ErrorMessage } from 'formik';
import { css } from "aphrodite-jss";
// -> Within Codebase
import Spacer from '../../LayoutUtilities/Spacer/Spacer';
import { UIContext, ThemeInfo } from '../../UI_InfoProvider/UI_InfoProvider';
import { VERTICAL } from '../../../constants';
// -> Within Component
import { styleGen } from './InputStyles';
import { IInputProps, calibrateComponent } from './helpers';

const Input: React.FC<IInputProps> = (props) => {
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const {
    label, name, rtl, required, useManualInputField, numberOnly,
    maxLength, value, onChange, errorMessage, placeholder, ...rest
  } = props;
  let { showLabel, showErrorMessage } = props;

  // const validateCode = (value: any) => {
  //   if (numberOnly) {
  //     let error;
  //     if (isNaN(value)) {
  //       error = `Use number`;
  //     }
  //     return error;
  //   }
  // };

  if (!showLabel) showLabel = true;
  if (showErrorMessage === undefined) showErrorMessage = true;

  const styles = styleGen(props, themeInfo);
  const { requiredElement } = styles;
  const {
    formControlCradleStylechain, labelCradleStylechain,
    labelTextStylechain, inputStylechain,
    errorCradleStylechain, errorTextStylechain
  } = calibrateComponent(props, styles);

  const rtlStatus = (rtl) ? "rtl" : undefined;

  return (
    <div className={formControlCradleStylechain}>
      {
        (showLabel && label) ? (
          <>
            <div className={labelCradleStylechain}>
              <p className={labelTextStylechain}>{label}</p>
              {
                (required) && (
                  <>
                    <div style={{ marginRight: 2}}></div>
                    <p className={css(requiredElement)}>*</p>
                  </>
                )
              }
            </div>
            <Spacer direction={VERTICAL} amount={distance.one} />
          </>
        ) : null
      }
      {
        (useManualInputField) ? (
          <input
            className={inputStylechain}
            id={name}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            dir={rtlStatus}
            value={value}
            onChange={onChange}
            {...rest}
          />
        ) : (
          <Field
            autoComplete={numberOnly && "off"}
            maxLength={maxLength}
            className={inputStylechain}
            id={name}
            name={name}
            placeholder={placeholder}
            dir={rtlStatus}
            {...rest}
          />
        )
      }
      {
        (showErrorMessage) ? (
          (useManualInputField) ? (
            <>
              <Spacer direction={VERTICAL} amount={distance.one} />
              <div className={errorCradleStylechain}>
                <p className={errorTextStylechain}>{errorMessage}</p>
              </div>
            </>
          ) : (
            <>
              <Spacer direction={VERTICAL} amount={distance.one} />
              <ErrorMessage
                name={name}
                render={(msg: string) => (
                  <div className={errorCradleStylechain}>
                    <p className={errorTextStylechain}>{msg}</p>
                  </div>
                )}
              />
            </>
          )
        ) : null
      }
    </div>
  );
}

export default Input;
