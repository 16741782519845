import { Artist } from "../../../../Types";
import { BASE_CARD_WIDTH } from "../../../../Components/Cards/ArtistCard/helpers";

export const SMALL_CARD_WIDTH = BASE_CARD_WIDTH - 25;
export const LARGE_CARD_WIDTH = BASE_CARD_WIDTH;
export const GALLERY_WIDTH_BREAKPOINT = 525;

export interface IArtistGalleryProps {
  artists: Artist[];
  onArtistClick(artistID: string): any;
  customArtistGalleryCradleStyles?: any;
}