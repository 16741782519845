import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { IAdminDashboardDetailsProps, DEFAULT_IMAGE_SIZE } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IAdminDashboardDetailsProps) => {
  // const { } = props;
  const { palette, distance, fonts } = themeInfo;

  return StyleSheet.create({
    componentCradle: {
      height: "100%",
      width: "80%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      overflowY: "scroll",
      paddingBottom: distance.three,

      "@media (max-width: 850px)": {
        width: "100%",
      },

      "@media (min-width: 1150px)": {
        width: 1000,
      }
    },
    topSectionCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "flex-start",

      "@media (max-width: 550px)": {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }
    },
    fallbackIconCradle: {
      height: DEFAULT_IMAGE_SIZE,
      width: DEFAULT_IMAGE_SIZE,
      display: "grid",
      placeItems: "center",
      backgroundColor: palette.grey6,
      borderRadius: "50%",
    },
    dashboardTopDetailsCradle: {
      height: "auto",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      flex: 1,

      "@media (max-width: 550px)": {
        width: "100%",
        flex: "0 1 auto",
      }
    },
    dashboardTitleRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    dashboardTitleText: {
      fontFamily: fonts.primary,
      fontSize: "3.4rem",
      fontWeight: "bold",
      color: palette.black,
    },
    navLinkRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    metricsGridCradle: {
      height: "auto",
      width: "100%",
      display: "grid",
      gridAutoRows: "auto",
      gridGap: "2rem",
      justifyContent: "start",
      justifyItems: "start",
      gridAutoFlow: "dense",
      gridTemplateColumns: "repeat(auto-fit, minmax(125px, 150px))",
    },
    metricCradle: {
      height: 150,
      width: 175,
      display: "flex",
      flexDirection:"column",
      justifyContent: "center",
      alignContent: "start",
    },
    metricValueText: {
      fontFamily: fonts.primary,
      fontSize: "5.5rem",
      fontWeight: "bold",
      color: palette.primary,
    },
    metricContextText: {
      fontFamily: fonts.primary,
      fontSize: "2.4rem",
      // fontWeight: 
      color: palette.black,
    }
  });
};
