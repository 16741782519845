// -> Beyond Codebase
import React, { useContext } from 'react';
import { css } from 'aphrodite-jss';
import { useTranslation } from "react-i18next";
// -> Within Codebase
import FloatingCircularLogo from '../../../../Components/VisualUtilities/FloatingCircularLogo/FloatingCircularLogo';
import Spacer from '../../../../Components/LayoutUtilities/Spacer/Spacer';
import { UIContext, ThemeInfo } from '../../../../Components/UI_InfoProvider/UI_InfoProvider';
import { VERTICAL } from '../../../../constants';
// -> Within Component
import RegistrationForm from './InternalComponents/RegistrationForm/RegistrationForm';
import { IRegistrationCardProps } from './helpers';
import { styleGen } from './RegistrationCardStyles';

const RegistrationCard: React.FC<IRegistrationCardProps> = (props) => {
  const { t } = useTranslation("page_registration");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { onSubmit, onLoginClick } = props;
  const {
    cardCradle, topBarCradle, cardBottomCradle, promptText,
    customCardCradleStyles
  } = styleGen(props, themeInfo);

  return (
    <div className={css(cardCradle, customCardCradleStyles)}>
      {/* TOP BAR */}
      <div className={css(topBarCradle)}>
        <FloatingCircularLogo />
        <Spacer direction={VERTICAL} amount={distance.four} />
        {/* <p className={css(promptText)}>Sign up for an account</p> */}
        <p className={css(promptText)}>{t("cardMessage")}</p>
        <Spacer direction={VERTICAL} amount={distance.three} />
      </div>
      {/* MAIN CARD CONTENT */}
      <div className={css(cardBottomCradle)}>
        <RegistrationForm onLoginClick={onLoginClick} onSubmit={onSubmit} />
      </div>
    </div>
  );
}

export default RegistrationCard;

RegistrationCard.defaultProps = {
  isInCardMode: true,
};
