// -> Beyond codebase
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { css } from "aphrodite-jss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// -> Within codebase
import ImageWrapper from "../../../../Components/VisualUtilities/ImageWrapper/ImageWrapper";
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import Tag from "../../../../Components/Tag/Tag";
import IconButton from "../../../../Components/Button/Variants/IconButton/IconButton";
import EventListItem from "../../../../Components/ListItems/EventListItem/EventListItem";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import Fade from "../../../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import { PersonIcon } from "../../../../Components/VisualUtilities/IconPresets";
import EditIcon from "../../../../Components/VisualUtilities/IconPresets/EditIcon";
import { UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../../../Components/DataContextProvider/DataContextProvider";
import { IDataContextState } from "../../../../Components/DataContextProvider/stateManagement";
import { Event } from "../../../../Types";
import {
  DEFAULT_TRANSITION_MICROANIMATION_TIME, HORIZONTAL, ROUND, VERTICAL,
  EVENT_ID_ROUTE_PARAM_TOKEN, EVENT_ROUTE, ARTIST_ID_ROUTE_PARAM_TOKEN, EDIT_ARTIST_ROUTE,
} from "../../../../constants";
import { mapExternalLinkToIcon, underConstructionAlert } from "../../../../helpers";
// -> Within component
import { styleGen } from "./ArtistDetailsStyles";
import { IArtistDetailsProps, DEFAULT_IMAGE_SIZE, IArtistDetailsState } from "./helpers";

const ArtistDetails: React.FC<IArtistDetailsProps> = (props) => {
  const [state, setState] = useState<IArtistDetailsState>({
    artistEvents: undefined,
    artistEventsLoadingStatus: false,
    artistEventsLoaderInStatus: false,
    hasMultipleExternalLinks: false,
    mainContentInStatus: true,
    eventsScrollBegan: false,
    eventsScrollActive: false,
  });
  const history = useHistory();
  const { t } = useTranslation("page_artist");
  const { actionHistoryCardMode, artist: {
    name, events: eventIDs, links, imageURL, description, id,
  }} = props;
  const { themeInfo } = useContext(UIContext);
  const { state: dataContextState, state: { events: eventsFromDataContext }}: { state: IDataContextState } = useContext(DataContext);
  const { distance, palette, transitions, shadows, styles } = themeInfo;
  const {
    componentCradle, customComponentCradleStyles, artistNameText, customArtistNameTextStyles,
    topSectionCradle, artistDetailsCradle, fallbackIconCradle, artistLinksCradle,
    noArtistLinksFallbackCradle, noContentFallbackText, artistEventsCradle, artistEventsTitleBarCradle,
    artistEventsListCradle, artistNoEventsCradle, itemSeparatorCradle, artistNameRow, h2Cradle,
    h2Text, bodyText, noContentFallbackCradle,
  } = styleGen(themeInfo, props, state);

  let mainContentMicroanimationTimeout = useRef<any>(-1);

  // console.log(`[ArtistDetails] data context events -> ${JSON.stringify(dataContextState.events, null, 4)}`);
  // console.log(`[ArtistDetails] data context events -> ${JSON.stringify(dataContextState.events, null, 4)}`);
  // -----

  const getArtistEvents = useCallback((eventIDs: string[]): any => {
    return eventIDs.map((eventID: string) => {
      return eventsFromDataContext.find((potentialEvent: Event) => (potentialEvent.id === eventID));
    });
  }, [eventsFromDataContext]);

   // ------

  useEffect(() => {
    if (eventIDs) {
      const events: Event[] | undefined = getArtistEvents(eventIDs);
      if (events) {
        // console.log(`[ArtistDetails] Events data -> ${JSON.stringify(events, null, 4)}`);
        setState((prevState) => ({ ...prevState, artistEvents: events }));
      }
    }

    if (links && Object.keys(links).length > 1) {
      setState((prevState) => ({ ...prevState, hasMultipleExternalLinks: true }));
    }
  }, [eventIDs, links, getArtistEvents]);

  useEffect(() => {
    return () => clearTimeout(mainContentMicroanimationTimeout.current);
  }, [mainContentMicroanimationTimeout]);

  // -----

  const onEventClick = (eventID: string) => {
    const computedURL = EVENT_ROUTE.replace(EVENT_ID_ROUTE_PARAM_TOKEN, eventID);
    setState((prevState => ({ ...prevState, mainContentInStatus: false})));
    history.push(computedURL);

    mainContentMicroanimationTimeout.current = setTimeout(() => {
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  const onNavigateToEditArtist = (artistID: string) => {
    
    const computedURL = EDIT_ARTIST_ROUTE.replace(ARTIST_ID_ROUTE_PARAM_TOKEN, artistID);

    // - TODO: -> Why isn't the set timeout callback invoking?
    history.push(computedURL);
    mainContentMicroanimationTimeout.current = setTimeout(() => {
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  const onEventListScroll = (evt: any) => {
    const { eventsScrollBegan } = state;
    const target = evt.target;

    if (target.scrollTop > 0) {
      if (!eventsScrollBegan) {
        setState((prevState) => ({ ...prevState, eventsScrollActive: true, eventsScrollBegan: true }));
      }
    }
    else if (target.scrollTop === 0) {
      setState((prevState) => ({ ...prevState, eventsScrollActive: false, eventsScrollBegan: false }));
    }

    // console.log('Current scroll position:', target.scrollTop);
    // console.log(`Scroll height of container div -> ${target.scrollHeight}`);
    // console.log(`Element height of container div -> ${target.clientHeight}`);
  }

  // ------

  const onArtistLinkClick = (link: string) => window.open(link, "_blank");

  const externalArtistLinks: string[] = links ? Object.values(links) : [];

  // -----

  const { artistEvents, mainContentInStatus } = state;

  return (
    <Fade inStatus={mainContentInStatus}>
      <div className={css(componentCradle, customComponentCradleStyles)}>
        <div className={css(topSectionCradle)}>
          {/* IMAGE */}
          {
            (imageURL) ? (
              <ImageWrapper
                alt={`Artist: ${name}`} src={imageURL}
                borderGeometry={ROUND}
                height={DEFAULT_IMAGE_SIZE} width={DEFAULT_IMAGE_SIZE}
              />
            ) : (
              <div className={css(fallbackIconCradle)}>
                <PersonIcon color={themeInfo.palette.grey2} size={DEFAULT_IMAGE_SIZE - 65} />
              </div>
            )
          }

          <div style={{ marginRight: distance.three, marginTop: distance.three }}></div>

          {/* ARTIST DETAILS */}
          <div className={css(artistDetailsCradle)}>
            <div className={css(artistNameRow)}>
              <p className={css(artistNameText, customArtistNameTextStyles)}>{name}</p>
              {
                actionHistoryCardMode ? null : (
                  <IconButton
                    height={45}
                    width={45}
                    buttonCradlePadding={0}
                    buttonGeometry={ROUND}
                    color={palette.white}
                    customCradleStyles={{
                      transition: transitions.boxShadowTransition,
                      padding: distance.two,
                      height: 45, width: 45,
                      position: "relative",
                      left: 15
                    }}
                    customCradleHoverStyles={{ boxShadow: shadows.one }}
                    icon={<EditIcon color={palette.black} size={styles.standardIconSize} />}
                    // customCradleStyles={{ padding: 0, justifyContent: 'flex-end' }}
                    onClick={() => onNavigateToEditArtist(id)}
                  />
                )
              }
            </div>
            
            <Spacer direction={VERTICAL} amount={distance.two} />
            <LineSegment direction={HORIZONTAL} width={"100%"} size={2} color={palette.black} />
            <Spacer direction={VERTICAL} amount={distance.three} />

            <div className={css(artistLinksCradle)}>
              {
                (links && (Object.keys(links).length > 0)) ? (
                  Object.keys(links).map((linkName: string, index: number) => {

                    return (
                      <div key={index}>
                        <IconButton
                          icon={mapExternalLinkToIcon(linkName, 25, palette.black)}
                          onClick={() => onArtistLinkClick(externalArtistLinks[index])}
                          buttonGeometry={ROUND}
                          color={palette.white}
                          customCradleStyles={{
                            transition: transitions.boxShadowTransition,
                            padding: distance.two,
                            height: 45, width: 45
                          }}
                          customCradleHoverStyles={{ boxShadow: shadows.one }}
                        />
                        {
                          (index < Object.keys(links).length) ? (
                            <Spacer direction={HORIZONTAL} amount={distance.three} />
                          ) : null
                        }
                      </div>
                    );
                  })
                ) : (
                  <div className={css(noArtistLinksFallbackCradle)}>
                    <p className={css(noContentFallbackText)}>{t("noExternalArtistLinksMsg")}</p>
                  </div>
                )
              }
            </div>
          </div>
        </div>

        <Spacer direction={VERTICAL} amount={distance.three} />

        {/* DESCRIPTION */}
        <div className={css(h2Cradle)}>
          <p className={css(h2Text)}>{t("descriptionLabel")}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.two} />

        {
          (description) ? (
            <p className={css(bodyText)}>{description}</p>
            ) : (
              <div className={css(noContentFallbackCradle)}>
              <p className={css(noContentFallbackText)}>{t("noArtistDescriptionMsg")}</p>
            </div>
          )
        }
        <Spacer direction={VERTICAL} amount={distance.two} />

        {/* ARTIST EVENTS */}
        <div className={css(artistEventsCradle)}>
          <div className={css(artistEventsTitleBarCradle)}>
            <div className={css(h2Cradle)}>
              <p className={css(h2Text)}>{t("eventsLabel")}</p>
            </div>
            <Tag
              labelText={(artistEvents) ? artistEvents.length.toString() : "0"}
              tagGeometry={ROUND}
              activeColor={palette.primary}
              activeTextColor={palette.white}
              customCradleStyles={{
                height: 30, width: 30,
                padding: distance.one,
              }}
              customTextStyles={{ fontWeight: "bold" }}
            />
          </div>

          <Spacer direction={VERTICAL} amount={distance.two} />

          {
            (artistEvents && artistEvents.length > 0) ? (
              <div className={css(artistEventsListCradle)} onScroll={onEventListScroll}>
                {/* <div className={css(scrollOverlay)}></div> */}
                {
                  artistEvents.map((event: Event, index: number) => {
                    console.log(`[ArtistDetails] Event data -> ${JSON.stringify(event, null, 4)}`);
                    const {
                      id, name, startDateTime, imageURL
                    } = event;

                    return (
                      <div key={id}>
                        <EventListItem
                          // itemNumber={index + 1}
                          imageURL={imageURL}
                          date={startDateTime}
                          name={name}
                          onClick={() => onEventClick(id)}
                        />
                        {
                          (index < (artistEvents.length - 1)) ? (
                            <div className={css(itemSeparatorCradle)}>
                              <LineSegment
                                direction={HORIZONTAL}
                                width={`calc(100% - ${distance.six}px)`}
                                size={1}
                                color={palette.grey1}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                    );
                  })
                }
              </div>
            ) : (
              <div className={css(artistNoEventsCradle)}>
                <p className={css(noContentFallbackText)}>No events to display for this artist.</p>
              </div>
            )
          }
        </div>
      </div>
    </Fade>
  );
}

export default ArtistDetails;
