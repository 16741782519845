import * as React from "react";
import { IIconProps } from "../../../Types";

const DiscoBallIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...Object.assign({}, {...props}, {...interpretedProps})}
    >
      <title>DiscoBall</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Tech_Stack" data-name="Tech Stack">
          <path d="M8.24,1a1.73,1.73,0,0,0,.19,1A2.19,2.19,0,0,0,10,3.2a7.93,7.93,0,0,0,1,0h.74V6.37l-.25.19a4.85,4.85,0,0,0-1.27,1.78,14.41,14.41,0,0,0-.73,2l0,.13h2.28V6.38h1.42v4.08h2.28l0-.13a14.29,14.29,0,0,0-.72-2,5,5,0,0,0-1.27-1.78l-.26-.19V3.25H14a8.16,8.16,0,0,0,1,0A2.19,2.19,0,0,0,16.57,2a1.65,1.65,0,0,0,.19-1l0-.66H8.22Z"/>
          <path d="M21.14,1.85v.73H19.71V4h1.43V5.43h1.42V4H24V2.58H22.56V1.11H21.14ZM22.56,3.3V4H21.14V2.58h1.42Z"/>
          <path d="M2.45,4.72v.71H1l0,.72v.73H2.42l0-.73V5.43H3.87V6.9H2.45V8.32H3.87V6.9H5.29V5.43H3.87V4H2.45Z"/>
          <path d="M8.92,6.88A9.55,9.55,0,0,0,4.8,10.21l-.18.25H8l0-.18a13.43,13.43,0,0,1,1.09-3,4.58,4.58,0,0,0,.29-.6A3.92,3.92,0,0,0,8.92,6.88Z"/>
          <path d="M15.55,6.7s.14.3.31.62a14,14,0,0,1,1.08,3l0,.18h3.41l-.26-.37a9.54,9.54,0,0,0-4.29-3.32C15.67,6.71,15.54,6.68,15.55,6.7Z"/>
          <path d="M3.85,12a10.47,10.47,0,0,0-.63,2.42l0,.37H7.47l0-.51c0-.48.13-1.53.19-2.08l0-.25H5.8A13.23,13.23,0,0,0,3.85,12Z"/>
          <path d="M9.16,12.13c-.08.51-.16,1.3-.19,1.81,0,.32,0,.63-.05.7l0,.13h2.89V11.93H9.2Z"/>
          <path d="M13.21,13.35v1.42h2.9l0-.13c0-.07,0-.38,0-.7,0-.51-.11-1.3-.2-1.81l0-.2h-2.6Z"/>
          <path d="M17.31,12.18c.06.48.16,1.58.2,2.08l0,.51h4.29l0-.37a10.64,10.64,0,0,0-.46-1.94L21.16,12H17.28Z"/>
          <path d="M3.22,16.59a9.77,9.77,0,0,0,.52,2.17l.11.31H7.73l0-.25c-.06-.55-.16-1.6-.19-2.09l0-.51H3.18Z"/>
          <path d="M8.92,16.37c0,.08,0,.39.05.7,0,.52.11,1.31.19,1.82l0,.2h2.59V16.24H8.9Z"/>
          <path d="M13.21,17.67v1.42h2.6l0-.2c.09-.51.17-1.3.2-1.82,0-.31,0-.62,0-.7l0-.13h-2.9Z"/>
          <path d="M17.51,16.75c0,.51-.14,1.61-.2,2.09l0,.25h3.88l.17-.51a10.55,10.55,0,0,0,.46-1.95l0-.37H17.54Z"/>
          <path d="M4.77,20.77A9.79,9.79,0,0,0,8.49,24a3.58,3.58,0,0,0,1,.37l-.33-.65a13.35,13.35,0,0,1-1-2.51L8,20.56H4.62Z"/>
          <path d="M9.51,20.61s.11.37.24.77a6.73,6.73,0,0,0,1.82,3.11l.22.16V20.56H10.65A5.34,5.34,0,0,0,9.51,20.61Z"/>
          <path d="M13.21,22.61v2l.22-.16a6.69,6.69,0,0,0,1.8-3c.11-.35.22-.69.23-.77l0-.13H13.21Z"/>
          <path d="M16.94,20.74a13.69,13.69,0,0,1-1.09,3c-.17.33-.3.6-.3.61a7.93,7.93,0,0,0,1.31-.56,9.59,9.59,0,0,0,3.35-3l.18-.25H17Z"/>
          <rect style={{ fill: "none" }} width="25" height="25"/>
        </g>
      </g>
    </svg>
  );
}

export default DiscoBallIcon;

DiscoBallIcon.defaultProps = {
  color: "black"
}
