/* eslint-disable @typescript-eslint/no-unused-vars */
// -> Beyond codebase
import React, { useContext, useRef } from "react";
import { css } from "aphrodite-jss";
// -> Within codebase
import Button from "../Button/Button";
// import Dropdown from "../Dropdown/Dropdown";
import { ChevronLeftIcon, ChevronRightIcon } from "../VisualUtilities/IconPresets";
import { ThemeInfo, UIContext } from "../UI_InfoProvider/UI_InfoProvider";
import { LEFT, RIGHT, ROUNDED } from "../../constants";
import { styleGuide } from "../../styleGuide/styleGuide";
// -> Within component
// import HiddenPagesPanel from "./InternalComponents/HiddenPagesPanel/HiddenPagesPanel";
import { styleGen } from "./PaginationStyles";
import { IPaginationProps, calibrateComponent } from "./helpers";

const Pagination: React.FC<IPaginationProps> = (props) => {
  const {
    customPrevBtnTextStyles, customPrevBtnStyles, customNextBtnStyles, customNextBtnTextStyles,
    customPageNumBtnStyles, customPageNumBtnTextStyles, buttonGeometry, currentPage, customNextBtnHoverStyles,
    customBreak1BtnStyles,  customBreak1BtnHoverStyles, customPrevBtnHoverStyles, customPageNumBtnHoverStyles,
    customBreak1BtnTextStyles, customBreak2BtnStyles, customBreak2BtnHoverStyles, customBreak2BtnTextStyles,
    customPrevBtnTextHoverStyles, customNextBtnTextHoverStyles, customBreak1BtnTextHoverStyles,
    customBreak2BtnTextHoverStyles, customPageNumBtnTextHoverStyles, showHoverStylesOnCurrPageBtn, setCurrentPage,
    primaryColor, secondaryColor, itemGap, showPrevButtonIcon, showNextButtonIcon, rtl, numPagesUntilBreak1,
    numPagesUntilBreak2, numPagesBeforeBreak1, numPagesBetweenBreak1AndBreak2, numPagesAfterBreak2,
    showWithOnePage, numberOfItems, itemsPerPage
  } = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, transitions } = themeInfo;
  const {
    componentCradle, customComponentCradleStyles, prevBtnCradle, pageNumBtnsCradle, nextBtnCradle,
    customPrevBtnCradleStyles, customPageNumBtnsCradleStyles, customNextBtnCradleStyles
  } = styleGen(themeInfo, props);

  const {
    numberOfPages, pageNumbers, prevButtonVisible, nextButtonVisible,
    computedPrevButtonText, computedPrevButtonIcon, computedNextButtonText,
    computedNextButtonIcon
  } = calibrateComponent(props);

  const pageNumbersToIterateOver = rtl ? pageNumbers.reverse() : pageNumbers;

  const onPageNumButtonClick = (pageNum: number) => {
    if (pageNum === currentPage) return;

    setCurrentPage(pageNum);
  }

  return (
    ((numberOfItems <= itemsPerPage) && (showWithOnePage === false)) ? null : (
      <div className={css(componentCradle, customComponentCradleStyles)}>
        {/* LEFT BUTTON */}
        {
          rtl ? (
            <>
              {
                (nextButtonVisible) ? (
                  <div className={css(nextBtnCradle, customNextBtnCradleStyles)}>
                    <Button
                      onClick={() => onPageNumButtonClick(currentPage + 1)}
                      disabled={currentPage === numberOfPages}
                      buttonText={computedNextButtonText}
                      color={secondaryColor}
                      textColor={primaryColor}
                      icon={computedNextButtonIcon}
                      iconPosition={LEFT}
                      iconTextSpacing={distance.one}
                      customTextStyles={
                        Object.assign({ transition: transitions.colorTransition }, customNextBtnTextStyles)
                      }
                      customTextHoverStyles={customNextBtnTextHoverStyles}
                      customCradleHoverStyles={customNextBtnHoverStyles}
                      customCradleStyles={
                        Object.assign({
                          minHeight: 42, minWidth: 42, width: "auto",
                          paddingLeft: (showNextButtonIcon) ? 0 : distance.one,
                          paddingRight: distance.one,
                          marginTop: distance.two,
                          border: `1px solid ${primaryColor}`,
                          transition: transitions.bgColorTransition,
                        }, customNextBtnStyles)
                      }
                    />
                  </div>
                ) : null
              }
            </>
          ) : (
            <>
              {
                (prevButtonVisible) ? (
                  <div className={css(prevBtnCradle, customPrevBtnCradleStyles)}>
                    <Button
                      onClick={() => onPageNumButtonClick(currentPage - 1)}
                      disabled={currentPage === 1}
                      buttonText={computedPrevButtonText}
                      color={secondaryColor}
                      textColor={primaryColor}
                      icon={computedPrevButtonIcon}
                      iconPosition={LEFT}
                      iconTextSpacing={distance.one}
                      customTextStyles={
                        Object.assign({ transition: transitions.colorTransition }, customPrevBtnTextStyles)
                      }
                      customTextHoverStyles={customPrevBtnTextHoverStyles}
                      customCradleHoverStyles={customPrevBtnHoverStyles}
                      customCradleStyles={
                        Object.assign({
                          minHeight: 42, minWidth: 42, width: "auto",
                          paddingLeft: (showPrevButtonIcon) ? 0 : distance.one,
                          paddingRight: distance.one,
                          marginTop: distance.two,
                          border: `1px solid ${primaryColor}`,
                          transition: transitions.bgColorTransition,
                        }, customPrevBtnStyles)
                      }
                    />
                  </div>
                ) : null
              }
            </>
          )
        } {/* Left Button Code Block End */}

        <div style={{ marginRight: itemGap }}></div>

        {/* PAGE NUMBER AND BREAK BUTTONS */}
        <div className={css(pageNumBtnsCradle, customPageNumBtnsCradleStyles)}>
          {
            pageNumbersToIterateOver.map((pageNumber: number, index: number) => {

              // - TODO: -> Break 1 and Break 2 implementation
              // if (numberOfPages > numPagesUntilBreak1) {

              // }

              return (
                <div key={pageNumber} style={{ display: "flex" }}>
                  <Button
                    buttonText={pageNumber.toString()}
                    onClick={() => onPageNumButtonClick(pageNumber)}
                    buttonGeometry={buttonGeometry}
                    color={(currentPage === pageNumber) ? primaryColor : secondaryColor}
                    textColor={(currentPage === pageNumber) ? secondaryColor : primaryColor}
                    customCradleStyles={
                      Object.assign({
                        minHeight: 42, minWidth: 42, width: "auto",
                        paddingLeft: distance.three,
                        paddingRight: distance.three,
                        paddingTop: distance.two,
                        paddingBottom: distance.two,
                        marginTop: distance.two,
                        border: (currentPage === pageNumber) ? "none" : `1px solid ${primaryColor}`,
                        transition: transitions.bgColorTransition,
                      }, customPageNumBtnStyles)
                    }
                    customTextStyles={
                      Object.assign({ fontSize: "1.6rem", transition: transitions.colorTransition }, customPageNumBtnTextStyles)
                    }
                    customCradleHoverStyles={
                      (!showHoverStylesOnCurrPageBtn && (currentPage === pageNumber)) ? undefined : customPageNumBtnHoverStyles
                    }
                    customTextHoverStyles={
                      (!showHoverStylesOnCurrPageBtn && (currentPage === pageNumber)) ? undefined : customPageNumBtnTextHoverStyles
                    }
                  />
                  {
                    (index < pageNumbers.length - 1) ? (
                      <div style={{ marginRight: itemGap, marginTop: itemGap }}></div>
                    ) : null
                  }
                </div>
              );
            })
          }
        </div> {/* Page Numbers Code Block End */}

        <div style={{ marginRight: itemGap }}></div>

        {/* RIGHT BUTTON */}
        {
          rtl ? (
            <>
              {
                (prevButtonVisible) ? (
                  <div className={css(prevBtnCradle, customPrevBtnCradleStyles)}>
                    <Button
                      onClick={() => onPageNumButtonClick(currentPage - 1)}
                      disabled={currentPage === 1}
                      buttonText={computedPrevButtonText}
                      color={secondaryColor}
                      textColor={primaryColor}
                      icon={computedPrevButtonIcon}
                      iconPosition={RIGHT}
                      iconTextSpacing={distance.one}
                      customTextStyles={
                        Object.assign({ transition: transitions.colorTransition }, customPrevBtnTextStyles)
                      }
                      customTextHoverStyles={customPrevBtnTextHoverStyles}
                      customCradleHoverStyles={customPrevBtnHoverStyles}
                      customCradleStyles={
                        Object.assign({
                          minHeight: 42, minWidth: 42, width: "auto",
                          paddingLeft: distance.one,
                          marginTop: distance.two,
                          paddingRight: (showPrevButtonIcon) ? 0 : distance.one, 
                          border: `1px solid ${primaryColor}`,
                          transition: transitions.bgColorTransition,
                        }, customPrevBtnStyles)
                      }
                    />
                  </div>
                ) : null
              }
            </>
          ) : (
            <>
              {
                (nextButtonVisible) ? (
                  <div className={css(nextBtnCradle, customNextBtnCradleStyles)}>
                    <Button
                      onClick={() => onPageNumButtonClick(currentPage + 1)}
                      disabled={currentPage === numberOfPages}
                      buttonText={computedNextButtonText}
                      color={secondaryColor}
                      textColor={primaryColor}
                      icon={computedNextButtonIcon}
                      iconPosition={RIGHT}
                      iconTextSpacing={distance.one}
                      customTextStyles={
                        Object.assign({ transition: transitions.colorTransition }, customNextBtnTextStyles)
                      }
                      customTextHoverStyles={customNextBtnTextHoverStyles}
                      customCradleHoverStyles={customNextBtnHoverStyles}
                      customCradleStyles={Object.assign({
                        minHeight: 42, minWidth: 42, width: "auto",
                        paddingLeft: distance.one,
                        marginTop: distance.two,
                        paddingRight: (showNextButtonIcon) ? 0 : distance.one,
                        border: `1px solid ${primaryColor}`,
                        transition: transitions.bgColorTransition,
                      }, customNextBtnStyles)}
                    />
                  </div>
                ) : null
              }
            </>
          )
        } {/* Right Button Code Block End */}
      </div>
    )
  );
}

export default Pagination;

Pagination.defaultProps = {
  numPagesUntilBreak1: 11,
  numPagesUntilBreak2: 21,
  numPagesBeforeBreak1: 3,
  numPagesBetweenBreak1AndBreak2: 4,
  numPagesAfterBreak2: 3,
  showHoverStylesOnCurrPageBtn: false,
  showDirectionButtonsWithOnePage: true,
  rtl: false,
  nextPageLabel: "Next",
  showNextButtonLabel: true,
  primaryColor: styleGuide.palette.primary,
  secondaryColor: styleGuide.palette.white,
  itemGap: styleGuide.distance.two,
  rightwardsIcon: <ChevronRightIcon color={styleGuide.palette.primary} size={styleGuide.styles.standardIconSize} />,
  showNextButtonIcon: true,
  prevPageLabel: "Prev",
  showPrevButtonLabel: true,
  leftwardsIcon: <ChevronLeftIcon color={styleGuide.palette.primary} size={styleGuide.styles.standardIconSize} />,
  showPrevButtonIcon: true,
  showPrevButton: true,
  showPrevButtonOnFirstPage: true,
  showNextButton: true,
  showNextButtonOnLastPage: true,
  showWithOnePage: false,
  buttonGeometry: ROUNDED,
};
