import { ISong } from "../Types";
import { artists } from "./artists";

export const sampleSongs: ISong[] = [
  {
    id: "1b9c1250-47e7-4032-aaa2-fa8c83b3615b",
    title: "Guidance",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0233ac95d38c932415ee1325b9",
    // -> Artist: Planet V
    creators: artists.filter((artist) => artist.id === "37d2529d-14b9-4a90-9caf-c024052ffe28"),
    length: 199,
    keySignature: "6B",
    tempo: 111,
    links: {
      spotify: "https://open.spotify.com/track/4kxSrxFWbODczoG11jUiO0?si=yLgLi8qwQba-W9vDkQGIcg"
    }
  },
  {
    id: "4ac5e277-e30d-44c1-98ae-a5b1f1879868",
    title: "Andromeda",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c66c5f3ff334b024f94a03bf",
    // -> Artist: Rednog
    creators: artists.filter((artist) => artist.id === "10543a27-c99f-45b5-8372-26bc9c04ff31"),
    length: 264,
    keySignature: "5B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/4vIvFKHAzS4e5PzpgmtTKs?si=pXsWek3nTKSGxYNN40peKQ"
    }
  },
  {
    id: "be65172a-77db-43b4-8221-06b1e8faaa63",
    // -> Artist: Øfdream
    title: "First Woe",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027113f7b388fb145de6cfcc09",
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 229,
    keySignature: "6B",
    tempo: 90,
    links: {
      spotify: "https://open.spotify.com/track/5r9jssGySumsa9ITKL6uga?si=dpMtCwPvTtuaOV1SjTuJMw"
    }
  },
  {
    id: "fdc3b0ad-e925-4723-8b89-9a6b50bab0a5",
    title: "The Great Beast",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 240,
    keySignature: "6A",
    tempo: 88.8,
    links: {
      googleDrive: "https://drive.google.com/file/d/14_5ew_DJKajx3AbiHATKGPeNI-6kDY3_/view?usp=sharing"
    }
  },
  {
    id: "c64cf739-960e-4288-b9c4-e1d89a1f7942",
    title: "AAA Flip",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cfd5b6cf21ebfe1459b95e95",
    // -> Artists: Visene, Beau
    creators: artists.filter(
      (artist) => ["86ef7707-fef6-4a8a-a311-8d1f1653f87d", "1dfe9974-ebfa-4772-9ab8-a1205148874e"].includes(artist.id)
    ),
    length: 156,
    keySignature: "11B",
    tempo: 120,
    links: {
      soundCloud: "https://soundcloud.com/visene/aaa-flip",
      spotify: "https://open.spotify.com/track/0bRpxXlG9kz3LGg86cV5OK?si=n3i684r6QreplNw9S3oGjw",
      appleMusic: "https://music.apple.com/us/album/aaa-flip-feat-beau/1436907965?i=1436907966",
    }
  },
  {
    id: "e7cad9b0-54b3-4083-b44b-9dd0576d8822",
    title: "Unity",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e89f67dfc9d9099e8a346b9",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 298,
    keySignature: "12B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/4qPnWPJUomSoelIL97wkOv"
    }
  },
  {
    id: "bdfe4eb6-9c46-47f5-8a94-8b8c33b428a8",
    title: "Holding On",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a4c5bd2fcf0f815cbd1df271",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 206,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/1K8mFMTpiqMdigKnSHPLMx?si=AVpVtRJgTLa6YYNBEg92YA"
    }
  },
  {
    id: "d8016e20-5691-4b29-ada2-611d3d013194",
    title: "Assassination",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02b29f45452917a92d6629aaf4",
    // -> Artists: Da Vosk Docta, MYSTXRIVL
    creators: artists.filter((artist) => ["b323309d-9910-421b-a60e-161d8ac3633c", "c7f75b69-5a29-4f60-a486-ace3354d3df6"].includes(artist.id)),
    length: 177,
    keySignature: "2B",
    tempo: 142,
    links: {
      spotify: "https://open.spotify.com/track/15KrREW0B5glUcyylbLdIw?si=52042cf99094456c"
    }
  },
  {
    id: "078ca2bb-e865-4096-83b3-a862b8da49f1",
    title: "nobody knows",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02bafc8cbbafefe40ce4cb7117",
    // -> Artist: almogfx
    creators: artists.filter((artist) => artist.id === "5a29ca1f-c903-4b43-b6bf-0eba13d43ddc"),
    length: 155,
    keySignature: "12B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/00TKUyC9BfJKh19sLF1nx4?si=oNRhdxlKSN6fn6EoaUEYIw"
    }
  },
  {
    id: "0d2b2477-9e32-447d-8c86-89488b1a1ab9",
    title: "Conscience",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d988f7bcd56007c1f3e0ba48",
    // -> Artist: Weaver Beats
    creators: artists.filter((artist) => artist.id === "49950c18-900e-4def-a4b8-bc15d0dafaff"),
    length: 200,
    keySignature: "1B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5KFRHLVP4C1CR4OMhAbQff?si=1h3QK4GzSDW3S0E8AqasTw",
    },
  },
  {
    id: "2e28aae5-5cd1-49d0-a390-5a5254cca2a9",
    title: "Rockstar (barnacle boi flip)",
    imageURL: "https://i1.sndcdn.com/artworks-000280439129-ttqlxc-t500x500.jpg",
    // -> Artists: Post Malone, barnacle boi
    creators: artists.filter(
      (artist) => ["774c9e44-6ade-4698-9466-5219c669035f", "839a4f4a-4b49-4ef9-b7d3-a3550b9ca1c6"].includes(artist.id)
    ),
    length: 226,
    keySignature: "7B",
    tempo: 140,
    links: {
      soundCloud: "https://soundcloud.com/barnacleboimusic/post-malone-rockstar-barnacle-boi-flip"
    },
  },
  {
    id: "0e509944-b5af-4338-a06d-a2e5677b5348",
    title: "STARGAZING",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022e0fe6b43f06d8ffc4874c72",
    // -> Artist: Travis Scott, Artonoise
    creators: artists.filter((artist) => ["8bdba741-43a0-4b28-a94d-bd34f45e9846", "598dfd8b-d9ed-429e-87a9-31e1c207293b"].includes(artist.id)),
    length: 214,
    keySignature: "6B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/6lar3kIiwgsODM245rXMm9"
    }
  },
  {
    id: "038c9344-b3c8-45a8-bfe9-a1174280d3dc",
    title: "GUCCI",
    imageURL: "https://i1.sndcdn.com/artworks-000206096441-y1606d-t500x500.jpg",
    // -> Artist: juku
    creators: artists.filter((artist) => artist.id === "6b3f7af5-f773-4255-8ea8-3254235e11fc"),
    length: 164,
    keySignature: "6B",
    tempo: 117,
    links: {
      soundCloud: "https://soundcloud.com/juku2000/x-1"
    }
  },
  {
    id: "5186cbe5-409a-40d9-af7f-fc1640e5e77e",
    title: "Silver Dream",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273a9c424d9b874f3079c13dbd5",
    // -> Artist: CHVRN
    creators: artists.filter((artist) => artist.id === "8a9aac1e-19bf-4566-998e-05cd023b0bec"),
    length: 232,
    keySignature: "5B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5vUUUMVHHYZWKCGBhOmjL0"
    }
  },
  {
    id: "6b9bda1a-63ee-49cf-baa8-703c54b4dfc1",
    title: "Labyrinth",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273489eaf58d751cf575c23f8a5",
    // -> Artist: Mannequin
    creators: artists.filter((artist) => artist.id === "5c63804d-531c-43d7-866a-58de5b67f5e4"),
    length: 314,
    keySignature: "11B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/3ioHEkY18dP4sommZJIbow?si=rQEYX0iWSO6XwJPiB5FVwQ"
    }
  },
  {
    id: "4dec22e7-1f14-43c9-ab36-dd6d87dbea35",
    title: "Aero",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ca1f7e0cbc52b64ae4251e6d",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 199,
    keySignature: "10B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/31uvu1q6bNkMddXY60opor"
    }
  },
  {
    id: "c0338f80-6881-46b0-8e0e-ace39922443d",
    title: "Howls",
    imageURL: "https://i1.sndcdn.com/artworks-000176804436-72mcpw-t500x500.jpg",
    // -> Artist: Trash Lord
    creators: artists.filter((artist) => artist.id === "c0338f80-6881-46b0-8e0e-ace39922443d"),
    length: 293,
    keySignature: "3B",
    tempo: 140,
    links: {
      soundCloud: "https://soundcloud.com/trshlrd/howls"
    }
  },
  {
    id: "a7cf527a-9e89-4bec-899a-18dbd1d1cd4a",
    title: "Over You",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273a3875c03a6d9d85f1def4cc6",
    // -> Artist: Vacant
    creators: artists.filter((artist) => artist.id === "125105d9-775a-429c-ab9e-04f12f440cdc"),
    length: 324,
    keySignature: "4B",
    tempo: 138,
    links: {
      spotify: "https://open.spotify.com/track/1mMVFewaGBo5wPaamAoZU6"
    }
  },
  {
    id: "ccc8a49e-379e-460c-81a4-19cc29bd4397",
    title: "Palace",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0282b211685c80166f8eddef4c",
    // -> Artists: Skit, Kareful
    creators: artists.filter(
      (artist) => ["ca5623c9-4268-47f0-8569-8a562e4e9bbe", "f828cc93-b86e-4fe6-abde-40dfcc37686a"].includes(artist.id)
    ),
    length: 221,
    keySignature: "1B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/7u1asB0CHXg3vJjpZh6Mxa?si=LxabQpcJTiq-q9aCPDWkUQ"
    }
  },
  {
    id: "6ac54d64-aa05-4db0-a16a-4a32bfd3fba7",
    title: "Stay",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02415463c8ee65ba48519c0289",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 240,
    keySignature: "2B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/230JSWdJAsuRiqercV05uO"
    }
  },
  {
    id: "f07a7229-4ede-4e35-b2b3-2e3851e080b9",
    title: "Pink Echoes",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27329125329b606b0ee27d19bef",
    // -> Artists: gl00my, Mannequin
    creators: artists.filter(
      (artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "5c63804d-531c-43d7-866a-58de5b67f5e4"].includes(artist.id)
    ),
    length: 179,
    keySignature: "2B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/68x1nLcxbVNtfSeqkl7nN5"
    }
  },
  {
    id: "c52b45d0-0944-4d47-ab1e-a5db94a36252",
    title: "Neonboy (Spaceouters Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0246c6c7e7804f2d1d2ec3cd77",
    // -> Artists: Sibewest, Spaceouters
    creators: artists.filter(
      (artist) => ["96187786-5425-4a35-b8b0-e9a5f98702ad", "ded31fda-f7f9-41a2-ac63-3c1506c61efe"].includes(artist.id)
    ),
    length: 323,
    keySignature: "3B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/75XtRkPK1j3z99wgIPNi5x"
    }
  },
  {
    id: "d5514e71-3ea7-40a4-a115-3031eb361ecc",
    title: "Endless Sky",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2736b779fb1998321cd78b77849",
    // -> Artist: Noah B
    creators: artists.filter((artist) => artist.id === "5613bb7a-d148-4164-a329-812a72eb74e5"),
    length: 243,
    keySignature: "4B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/2V3lxcAlbBk0rl94lSoDFz"
    }
  },
  {
    id: "6e295c39-49ca-4895-be0c-73f39f341d03",
    title: "Let Off",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273c99ebd1602af9a328f138302",
    // -> Artist: Vacant
    creators: artists.filter((artist) => artist.id === "125105d9-775a-429c-ab9e-04f12f440cdc"),
    length: 200,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/600uPEYmDBWCzFf6fKz63H"
    }
  },
  {
    id: "70896022-82f3-4454-bebf-be6d93c2537e",
    title: "Thelema",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2737113f7b388fb145de6cfcc09",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 210,
    keySignature: "3B",
    tempo: 142,
    links: {
      spotify: "https://open.spotify.com/track/098PU9y0wA153H6UI36uMy"
    }
  },
  {
    id: "c9c04990-9a16-4600-ab57-99c00911f294",
    title: "Evoke (Dyzphoria Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e020aadc24f0887594974a688b5",
    // -> Artists: TIGEREYES, Dyzphoria
    creators: artists.filter((artist) => ["ab918827-aa3e-4462-9a4d-dacf3759e37e", "169286b0-b3d4-4829-b080-8ba4d8c471ca"].includes(artist.id)),
    length: 291,
    keySignature: "2B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/1x5C3xtniMCLy9tO8Ook7E"
    }
  },
  {
    id: "5335848b-ac30-4a06-b1bd-b186603530d0",
    title: "Fallen Angel",
    imageURL: "https://i1.sndcdn.com/artworks-000550239411-4wgpyw-t500x500.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 237,
    keySignature: "2B",
    tempo: 150,
    links: {
      spotify: "https://open.spotify.com/track/6iKJAUeHx0saIoZ8z69Wam"
    }
  },
  {
    id: "0942112b-ad02-4ef0-b87f-bf6b9e53d0ed",
    title: "Emerald Halls",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0267b435fd554b1f83d772d92f",
    // -> Artist: Kareful
    creators: artists.filter((artist) => artist.id === "ca5623c9-4268-47f0-8569-8a562e4e9bbe"),
    length: 226,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/7wokPgT5SPTGxit5mVevsB"
    }
  },
  {
    id: "4592367d-db18-40b1-a73e-ede25f33715f",
    title: "Respire",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b4e3006b1e48ba76be9a0f19",
    // -> Artist: AyyJay
    creators: artists.filter((artist) => artist.id === "7ee42c8a-2982-4760-a12f-b1b752967a4a"),
    length: 293,
    keySignature: "1B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/7pDIyFS4ZcfyIHhhMdCvo2"
    }
  },
  {
    id: "5179a5cc-3829-4377-b5f5-b6d67229ca14",
    title: "Memories (Fyoomz Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027e76ab0aa55b356114013528",
    // -> Artists: Melleefresh, Fyoomz
    creators: artists.filter((artist) => ["", "195d1986-f79a-44d1-a36c-42634a2c1df0"].includes(artist.id)),
    length: 217,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5RtXBN9DiVtKmxDiJeyPFG"
    }
  },
  {
    id: "12486fc7-6b89-4cde-95fa-af3d94444a5f",
    title: "Know Your Worth",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b1854fb5dcc269b0bb4714a5",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 388,
    keySignature: "2B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5fHtiIKhRSPF5AmQxs3Srh?si=dxb3REANTTeFexf2DVO6LA"
    }
  },
  {
    id: "298c25e1-2f17-4e46-b29c-309e19d29f83",
    title: "SCORCH",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0232e0d30a09aed07513ff227c",
    // -> Artist: kim
    creators: artists.filter((artist) => artist.id === "9c535188-b27d-4a20-8ea4-0586e1a7c1e9"),
    length: 220,
    keySignature: "3B",
    tempo: 111,
    links: {
      spotify: "https://open.spotify.com/track/0VADUQQTb3rfmQN3QFTurV",
    }
  },
  {
    id: "b326080d-2101-4188-996e-90c6c8f14ec9",
    title: "Less Education",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273baada8fbe52f5ff1ea516e0e",
    // -> Artist: WTCHCRFT
    creators: artists.filter((artist) => artist.id === "cb9586c1-45fc-4ce8-b108-48abfeb0e52c"),
    length: 223,
    keySignature: "8B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/0QBaH1j51Jyw4fMuZbQy1z"
    }
  },
  {
    id: "323b61de-28a6-4a27-97bf-e81f41390769",
    title: "She Kept My Heart",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029f67ac958f98be54fc4368fd",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 226,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3ibskhaN6PdPjqYGq6kMtq"
    }
  },
  {
    id: "3d3281d0-b218-4ce6-83b1-dd04fc556770",
    title: "Inta",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2734c51824c35a3a282be2421db",
    // -> Artist: Mssingno
    creators: artists.filter((artist) => artist.id === "a209cc6d-cc0d-4415-833b-d4b9352b6d4b"),
    length: 214,
    keySignature: "2B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/4uhigmJ8Quq8VDFKEg52Sk"
    }
  },
  {
    id: "39734a94-ff3e-47bf-abe7-f9e45e1ce305",
    title: "Hymn",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b29ca323f6ddadb2d880f46e",
    // -> Artists: Forever, Chemist
    creators: artists.filter((artist) => ["05e91f75-c077-43e5-bfde-dfa79043929b", "0881c3ee-96b0-4e2c-8b62-eb50aacbc854"].includes(artist.id)),
    length: 177,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/6FYgKkLH1p0wVeqtvHR8IS"
    }
  },
  {
    id: "8b79905e-d705-4d8c-ab1d-8f031998686b",
    title: "Sidewalks",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273afbe810b9f0678764bf20136",
    // -> Artists: CHVRN, Landechoe
    creators: artists.filter(
      (artist) => ["8a9aac1e-19bf-4566-998e-05cd023b0bec", "f56349f7-c4fc-4ce9-afc8-fe7ae8da90bf"].includes(artist.id)
    ),
    length: 234,
    keySignature: "1B",
    tempo: 117,
    links: {
      spotify: "https://open.spotify.com/track/7J1tb7lzCdly5bbh4uehMD"
    }
  },
  {
    id: "45545237-c564-4ec8-ba88-c7ba78e73293",
    title: "Mantle",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273f8374d25594fc2c12d3d4190",
    // -> Artist: Giiant Ibis
    creators: artists.filter((artist) => artist.id === "2777e990-49e8-4a79-8440-89f213ecc411"),
    length: 260,
    keySignature: "2B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/60w9keoABmlsXly49t2MNj"
    }
  },
  {
    id: "97ca2c80-c862-4f51-a03a-1d58de944919",
    title: "Drown",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028b123cd6721bff6d402e8f6c",
    // -> Artist: Gastah 
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 219,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4orMM8SIVPnrnSAbvp0Jg7"
    }
  },
  {
    id: "ebf46227-2c73-4f67-9e16-fd9510ef0b2e",
    title: "On My Own",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273b9ea372c3f38797f1748eaae",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 244,
    keySignature: "8B",
    tempo: 129,
    links: {
      spotify: "https://open.spotify.com/track/7yiVsNHRxbv4Yy9TfnBxcd"
    }
  },
  {
    id: "dc14b2ca-486c-4496-b16d-3b53c4517f27",
    title: "Delusion",
    imageURL: "https://i1.sndcdn.com/artworks-eKhU9Nz6doTnnKOF-yhGQzA-t500x500.jpg",
    // -> Artist: Kyddiekafka
    creators: artists.filter((artist) => artist.id === "3e0ae7fe-aecf-4b50-bab8-0c4875265daf"),
    length: 210,
    keySignature: "8A",
    tempo: 138.5,
    links: {
      soundCloud: "https://soundcloud.com/kyddiekafka/delusion"
    }
  },
  {
    id: "51f8188b-474a-4fcf-873f-47df83e196fa",
    title: "Rose Petals",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273e6545bada50bb901de6c33e8",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 178,
    keySignature: "1B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/52yTpTfOqRHXyn0lyQgwr3"
    }
  },
  {
    id: "312c4e9c-8d71-4d12-9b8f-6836f7ce285a",
    title: "Why?",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a7618b5b079179b77e3bedb6",
    // -> Artist: Cardio
    creators: artists.filter((artist) => artist.id === "04c5f330-059b-4240-80ff-c471248dd417"),
    length: 228,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4OY395kmdKymBKjVLSwtmR"
    }
  },
  {
    id: "98f83084-709e-4cfa-8edc-c9e7c9066925",
    title: "Come Around",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2734af40f60c15e486c487d588c",
    // -> Artist: Darci
    creators: artists.filter((artist) => artist.id === "7156744d-9cad-4c92-a1e5-c552e60309e1"),
    length: 204,
    keySignature: "9B",
    tempo: 132,
    links: {
      spotify: "https://open.spotify.com/track/0zw0dzP2N15Hc1FlI9dXFN"
    }
  },
  {
    id: "8d9ddc85-5153-41a9-b1dc-976169e57eb7",
    title: "Medi",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d380c0d379abd69a6cbbdec5",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 194,
    keySignature: "8B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5TAosLWrQHZNs42r3xwFxq"
    }
  },
  {
    id: "f0bde5ac-85b1-410c-832d-a03478f69c19",
    title: "Foreign Blocks",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e0f82cad03d8711ffe36a8c",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 182,
    keySignature: "12B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4GUOGUGSkSpzVbmZddEoPk"
    }
  },
  {
    id: "faef9e70-0320-458a-8c30-bdf8e6c9fb35",
    title: "Hypersquare",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023294bafa9d6598329b43afe8",
    // -> Artists: MYSTXRIVL, Deadcrow
    creators: artists.filter((artist) => ["c7f75b69-5a29-4f60-a486-ace3354d3df6", "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"].includes(artist.id)),
    length: 248,
    keySignature: "11B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5pDFJgkTXczsJIIDspl8hF"
    }
  },
  {
    id: "d889d585-654f-49be-9fc4-1e7f01182e25",
    title: "Night Racer",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273c5e2556621a0155dcc02089a",
    // -> Artist: Bucky
    creators: artists.filter((artist) => artist.id === "be3cc0c8-3e65-407a-ad52-14688e098ba5"),
    length: 289,
    keySignature: "2B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/4kR8Mz23J5PoKNe9lKy3k9"
    }
  },
  {
    id: "8539d04c-07e5-49b3-b203-4b3d44ab15cf",
    title: "With You",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026a19825222b717ff55d4c314",
    // -> Artist: Yedgar
    creators: artists.filter((artist) => artist.id === "42d3afdd-cb05-4d6d-ac80-f83a94a326e3"),
    length: 200,
    keySignature: "3B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/33nGXmfwJqBA6Ethd3mtLc"
    }
  },
  {
    id: "850ce5b8-94de-4ba7-b854-849f641140d1",
    title: "Ascend (Silk Road Assassins Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2732775ec767d4207af21a5a545",
    // -> Artists: Last Japan, AJ Tracey, Silk Road Assassins
    creators: artists.filter(
      (artist) => [
        "9d66c812-5915-49a0-9dd4-7ba3742fba97", "505b7ead-1656-489a-b251-91ee60e2f6da",
        "e43371e3-20c0-4f81-ab86-172e01d1cda9"
      ].includes(artist.id)
    ),
    length: 232,
    keySignature: "5B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3omU9taE18yMpoeLMLOBsz"
    }
  },
  {
    id: "59d0f4d6-d840-4c5e-9505-51a5609aec0a",
    title: "FADE",
    imageURL: "https://i.scdn.co/image/ab67616d00001e021c4af85ac938e93018a6ada2",
    // -> Artist: STAHL
    creators: artists.filter((artist) => artist.id === "50b5d998-682b-42af-91ec-c34122977bcf"),
    length: 151,
    keySignature: "1B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/1LrVEYnPA35LB0ZvMayIb9"
    }
  },
  {
    id: "e2b5603c-e4d4-45f0-b3b2-0340e5e2f67e",
    title: "Rain",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e4d8e22efdb6dc539c6673b5",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 213,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5kmTAysq1Wqa7ymrnIsiSM",
    }
  },
  {
    id: "bac74603-1b0e-4518-8411-848854288cb8",
    title: "Coves",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273ef65e22585378cebbed6e977",
    // -> Artist: Harukasuka
    creators: artists.filter((artist) => artist.id === "9dcd8cba-6112-432f-93b2-47b2b1fcf203"),
    length: 213,
    keySignature: "7B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/67LKQzCUIdGSIPzJZk7iYi"
    }
  },
  {
    id: "29c3bf35-e1bf-4405-b86f-d4869185bba4",
    title: "Guiding Light",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ed364b6828739dd13243feaa",
    // -> Artists: Fyoomz, Djedi
    creators: artists.filter((artist) => ["195d1986-f79a-44d1-a36c-42634a2c1df0", "03dc31b6-213f-4b29-ae1c-0813c5e7b695"].includes(artist.id)),
    length: 199,
    keySignature: "6B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/2tMBcLMv3XyJE91ktLjPK5"
    }
  },
  {
    id: "542f7842-cf0d-44b5-869e-f04aef1b1893",
    title: "midnight.",
    imageURL: "https://i1.sndcdn.com/artworks-000342375831-i6li8o-t500x500.jpg",
    // -> Artist: Hyperforms
    creators: artists.filter((artist) => artist.id === "aa1ea277-75b1-45e0-aef8-3fda501c0334"),
    length: 185,
    keySignature: "12B",
    tempo: 136,
    links: {
      soundCloud: "https://soundcloud.com/lstcs/midnight"
    }
  },
  {
    id: "e7d99850-64e2-4435-9c12-515b5296ddc9",
    title: "Flare",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e754ef4aeadc5d37fe5f0ace",
    // -> Artist: Lostageless
    creators: artists.filter((artist) => artist.id === "d578cf18-8f1f-4a2b-8a40-d121f172442f"),
    length: 199,
    keySignature: "1B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/7jUew7pOOFd06T19A2CHKR"
    }
  },
  {
    id: "abdf1b1c-e83c-4dff-ae2a-453e30f29343",
    title: "Fall Apart",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c751ec994777d93ea6d84749",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 284,
    keySignature: "11B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1VNYE6dZEBMiMreTtpcbVa"
    }
  },
  {
    id: "9085ff91-1e27-40bc-8b91-6d524ec66978",
    title: "Beneath Me",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d1495c3e607052ce182553bf",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 248,
    keySignature: "12B",
    tempo: 114,
    links: {
      spotify: "https://open.spotify.com/track/6A2RF5m6z0IvPDs4EbFIWv"
    }
  },
  {
    id: "53c2a401-53ee-4f9a-beeb-40380fadf40f",
    title: "It Is What It Is",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2738458f01ead08e958faa24284",
    // -> Artist: MRKRYL
    creators: artists.filter((artist) => artist.id === "f0073e5b-c23a-4add-a29b-c5000e4c96d6"),
    length: 312,
    keySignature: "9B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/5kwltTpGoYh6dUme4vQ9ZG"
    }
  },
  {
    id: "1d6cd77c-f04e-4416-80dd-62f18a4ae970",
    title: "Through the Night",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02990e55746f9d2ff22aea61cb",
    // -> Artists: MRKRYL, Sorsari
    creators: artists.filter(
      (artist) => ["f0073e5b-c23a-4add-a29b-c5000e4c96d6", "ef839db3-ae41-4af9-9078-a8d211089962"].includes(artist.id)
    ),
    length: 256,
    keySignature: "8B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1msfHXYmHnFXWvLl0gAiNG",
    },
  },
  {
    id: "3809d3a8-9a99-455c-8b4f-355d7ab802a0",
    title: "Pilot",
    imageURL: "https://i.scdn.co/image/ab67616d00001e022d4baec5608ffeeef9d4db34",
    // -> Artist: Jaga
    creators: artists.filter((artist) => artist.id === "d3773310-fb18-4a8c-850d-0de9d2cd0499"),
    length: 224,
    keySignature: "9B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/3bP7IiSYR2YVN4NPyJsFKa"
    }
  },
  {
    id: "0d420245-2fde-4bfd-a068-ae1ed2b5d986",
    title: "Pit Viper (MRKRYL Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026fd3ba3b4d54d7f61fcd7cfc",
    // -> Artists: Pictureplane, MRKRYL
    creators: artists.filter(
      (artist) => ["fef5be9d-6223-43cb-a3db-e6048a070fec", "0d420245-2fde-4bfd-a068-ae1ed2b5d986"].includes(artist.id)
    ),
    length: 320,
    keySignature: "12B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/3TNmb3tu9EwPL73AQbecBt"
    }
  },
  {
    id: "49617794-a4bb-4911-b755-2b6316d03d11",
    title: "Beachaus",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d9014b05dc0a7f8ad9066c2c",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 198,
    keySignature: "1B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/4A8pfRjSc0U3xA73UtxIA0"
    }
  },
  {
    id: "94eaea02-cc3e-4de1-8479-ac98954f74b6",
    title: "Cartel",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02d8906b0e1fc8daae7d1af11d",
    // -> Artist: Boy Racers
    creators: artists.filter((artist) => artist.id === "271c33ac-1a6e-41df-832e-d92408099fb1"),
    length: 249,
    keySignature: "3B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/0mbRZr0IGbdOagC7chHhvo"
    }
  },
  {
    id: "cb053330-b4bb-4f7e-86e5-39387b0b3ed1",
    title: "Paradise City",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a6c49dc2a557f2177d87ce66",
    // -> Artist: Gastah
    creators: artists.filter((artist) => artist.id === "56fb0655-b417-4669-9efd-857added9ca2"),
    length: 213,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3ZVuDcMtcjMuKBRdUtVbSh",
    }
  },
  {
    id: "385c0fde-515a-4101-8745-0a686f098a39",
    title: "Tokyo Train",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cd39ea595a3ceac21cb5203b",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 220,
    keySignature: "4B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/18YjCNwwuuNZFLZHXQe31G"
    }
  },
  {
    id: "42a03ce3-0eaa-458b-86d9-dae7da335f93",
    title: "ICED",
    imageURL: "https://i1.sndcdn.com/artworks-000297918183-u0mqi6-t500x500.jpg",
    // -> Artist: STAHL
    creators: artists.filter((artist) => artist.id === "50b5d998-682b-42af-91ec-c34122977bcf"),
    length: 152,
    keySignature: "10A",
    tempo: 120.2,
    links: {
      soundCloud: "https://soundcloud.com/stahlsound/iced"
    }
  },
  {
    id: "b7f2a84b-7b53-45a6-8a24-c3f671eca679",
    title: "We Once Knew",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0260923a13dd6274574aa47ce7",
    // -> Artist: Jaga
    creators: artists.filter((artist) => artist.id === "d3773310-fb18-4a8c-850d-0de9d2cd0499"),
    length: 330,
    keySignature: "10B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/0LG5JGMmV5rpnYVuW9F3Co"
    }
  },
  {
    id: "6481f3aa-d46d-4c3d-9971-f4b8e435936d",
    title: "Liff",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026bd7a79ffd0ded312ff5147c",
    // -> Artist: Toka
    creators: artists.filter((artist) => artist.id === "86987cf5-db81-455a-8310-11143edef563"),
    length: 297,
    keySignature: "1B",
    tempo: 133,
    links: {
      spotify: "https://open.spotify.com/track/2B3rukQptaD2DCqxPxlBcP"
    }
  },
  {
    id: "162612a5-83e6-42f5-be85-8c9bf1fa5a89",
    title: "One Day",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028c8c93e2ab245f08b42a1384",
    // -> Artist: Skeler
    creators: artists.filter((artist) => artist.id === "4f1bb9a6-fb96-499d-adbc-b9043ee1fa22"), 
    length: 240,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/7obCERKA5H8m7zpEVxndmM"
    }
  },
  {
    id: "3d41824b-5ed0-4017-a55f-a8e29456b290",
    title: "Phantom Feelings",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02248215074d06c01ef819dca4",
    // -> Artists: Palm Fires, w/out
    creators: artists.filter(
      (artist) => ["6eff3e12-1cee-47a6-9335-7694bf8148fe", "75c02207-5bcc-4377-bf1b-f25e0b9d1579"].includes(artist.id)
    ),
    length: 188,
    keySignature: "5B",
    tempo: 126,
    links: {
      spotify: "https://open.spotify.com/track/1hYzPSPtCSNprvXhiwM9hp"
    }
  },
  {
    id: "fa04578f-3c2a-475a-8ca7-c58cafc59c26",
    title: "Even More",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02407f2df24bd122b42cd7a7c0",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 222,
    keySignature: "6B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/1IFpT9fY2UCZHW392zgCEs"
    }
  },
  {
    id: "c7ed2ebe-e168-422b-a886-2ef33564f436",
    title: "lalala",
    imageURL: "https://i1.sndcdn.com/artworks-000577129250-y2x0iq-t500x500.jpg",
    // -> Artist: Calicry
    creators: artists.filter((artist) => artist.id === "5bb2ec42-87be-4fd1-8c9f-3fb94f58991f"),
    length: 251,
    keySignature: "8A",
    tempo: 93.5,
    links: {
      soundCloud: "https://soundcloud.com/sekai-collective/c-a-l-i-c-r-y-lalala"
    }
  },
  {
    id: "cb3c0a4a-461f-4ed2-bc1f-65209932f682",
    title: "Buster",
    imageURL: "https://i1.sndcdn.com/artworks-icNDDGaiNLmtRhsm-tA3KiA-t500x500.jpg",
    // -> Artist: Pholo
    creators: artists.filter((artist) => artist.id === "59ad7bf8-4667-45b6-96d4-87f70573b936"),
    length: 186,
    keySignature: "9A",
    // - TODO: -> Tempo seems a bit slow here to my ear, look into this further.
    tempo: 98.5,
    links: {
      soundCloud: "https://soundcloud.com/pholouk/buster",
    }
  },
  {
    id: "a16e43ba-9c8a-4894-bfa2-4528b95115de",
    title: "If You Only",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0280d3dcc9e028037bea4c8d30",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 206,
    keySignature: "2B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/1EytPqsNuzeseja54PmlVK"
    }
  },
  {
    id: "b8782bd8-bb04-4ee8-89df-df6d56e2e406",
    title: "Despite It All",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27382bfd4a7ebc3bd724b44851c",
    // -> Artist: Om Unit
    creators: artists.filter((artist) => artist.id === "3aa7f9ac-7cc0-42ca-aab9-42bce1245610"),
    length: 257,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/3d1BdVcWJXuMFWx5MBAkOP",
    }
  },
  {
    id: "0cfbc6bb-a80d-4236-a9d0-10572d4dd65f",
    title: "Apart",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02cbed46a6fa0dde9b94f0463e",
    // -> Artist: Vacant
    creators: artists.filter((artist) => artist.id === "125105d9-775a-429c-ab9e-04f12f440cdc"),
    length: 228,
    keySignature: "1B",
    tempo: 133,
    links: {
      spotify: "https://open.spotify.com/track/4mJjs2cYHqKo7L54qfTLO1"
    }
  },
  {
    id: "23f8a0ba-8575-42b2-9966-b1442e9d8970",
    title: "LOVE TWO",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02328dd362fd35eddaa08c0d11",
    // -> Artist: Jato
    creators: artists.filter((artist) => artist.id === "b14341ab-3d48-4170-841b-55255e13f3ea"),
    length: 250,
    keySignature: "2B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/6uSJLqe9gQPIY06azOc9mH"
    }
  },
  {
    id: "a96a0ea1-0d33-4a97-a749-d031e234c0a6",
    title: "never say goodbye",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ff5330766f5b755b3ee7346b",
    // -> Artist: Enjoii
    creators: artists.filter((artist) => artist.id === "d2bc4672-f7cb-4ebd-a25e-9b78048a8b0f"),
    length: 181,
    keySignature: "5B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/7qss1vfkxeWVMDJ1LyLvHK"
    }
  },
  {
    id: "5663898f-c2d7-4b52-b4b5-6e4b14f7b01a",
    title: "Here, at the End of All Things",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0258dd326e049d097beba4eb96",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 250,
    keySignature: "4B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1q6vKnTqKtKFJRzDNu8Mb4"
    }
  },
  {
    id: "5b610807-f613-40cd-a686-f9a9bdf7180c",
    title: "Reload",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0244114a42183e58ac04201416",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 285,
    keySignature: "1B",
    tempo: 138,
    links: {
      spotify: "https://open.spotify.com/track/0QPF7HFemmBJMrIOcWwoMi"
    }
  },
  {
    id: "48ff2e07-ed46-411d-8a9a-02010f14b9de",
    title: "Evoke",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2736ffe019f956ebdaefb322b07",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 169,
    keySignature: "2B",
    tempo: 145,
    links: {
      spotify: "https://open.spotify.com/track/1wHJs0FZ2rfnF6WPbf7J9D"
    }
  },
  {
    id: "bed0e946-9cb0-4963-a0c0-7ac4b035937c",
    title: "Dreams",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2739e3928eeeed5e267325e7405",
    // -> Artist: Deadcrow
    creators: artists.filter((artist) => artist.id === "9c4a40d6-3e11-4dbb-8038-7639bde1ff11"),
    length: 288,
    keySignature: "4B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1G08Ywi9tuZm5ieiZZpDN2"
    }
  },
  {
    id: "56f1af02-aed4-4eac-9bf4-e6fd88dd9f7e",
    title: "Dyne",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273aa47dda37351b5d8af2a5b74",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 261,
    keySignature: "3B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/4UCxJssdyskC2vMvbcrheM"
    }
  },
  {
    id: "706b2316-53ea-47a7-9f2f-80b7559cb0d2",
    title: "Caprize",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273c8b1aaeebf6118a5a442dc97",
    // -> Artist: The Obsidian
    creators: artists.filter((artist) => artist.id === "bd6727fc-1ca8-4146-8430-7c2b72f1843b"),
    length: 255,
    keySignature: "12B",
    tempo: 113,
    links: {
      spotify: "https://open.spotify.com/track/75cFpVLSdRgcAM7bZHcYFL"
    }
  },
  {
    id: "c32ab8af-fa41-4fe7-acc8-3581d2374bfc",
    title: "Shawty",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273e3cd62e1bec5f37f4b7433af",
    // -> Artists: $kyhook, Hermei
    creators: artists.filter((artist) => ["6d25fc91-0404-4cd9-a4a6-0ac94e530c05", "a550a7ef-13d0-4ee8-ad3d-7eade79cbebb"].includes(artist.id)),
    length: 179,
    keySignature: "1B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/7mf16gxAzI8lS6QFOBk9em"
    }
  },
  {
    id: "a82245c9-ccf5-47b8-8301-d4d2c895778b",
    title: "Keep My Cool",
    imageURL: "https://i.scdn.co/image/b26e6916ec7f3ff3c0bdadacbc55ecc54d74260b",
    // -> Artists: gl00my, Vexxy
    creators: artists.filter((artist) => ["483a9c78-cbfa-4b7c-8494-48e2e8631846", "aa8507e3-e295-442d-af3c-7036e9ef44aa"].includes(artist.id)),
    length: 219,
    keySignature: "12B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/47XIg9zK8jMyZE4XmgXiUn",
    }
  },
  {
    id: "3fcab479-9541-411f-93ee-4b04b46a2a69",
    title: "Nylon",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026838b11a303be3135018af19",
    // -> Artists: Jato, Vexxy
    creators: artists.filter((artist) => ["b14341ab-3d48-4170-841b-55255e13f3ea", "aa8507e3-e295-442d-af3c-7036e9ef44aa"].includes(artist.id)),
    length: 199,
    keySignature: "1B",
    tempo: 136,
    links: {
      spotify: "https://open.spotify.com/track/6quEGPb7LoA1f2j7j8w0YD"
    }
  },
  {
    id: "9c950f39-ff88-4c72-9bf0-d88afdbcdd21",
    title: "Pour It Down the Drain",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02a5dde1ad3446055b073a32e0",
    // -> Artist: M!NGO
    creators: artists.filter((artist) => artist.id === "a77f263a-3296-4f8c-84ff-2208134ddcfe"),
    length: 237,
    keySignature: "7B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/51WIbePwKQKVqioarUj3On"
    }
  },
  {
    id: "61bf6cc5-8232-4c38-96a4-f49b1f07b37f",
    title: "Faxing Berlin (Fyoomz Remix)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02ed364b6828739dd13243feaa",
    // -> Artists: Deadmau5, Fyoomz
    creators: artists.filter((artist) => ["7ca7e8b4-910a-42bd-8693-82192114ec88", "195d1986-f79a-44d1-a36c-42634a2c1df0"].includes(artist.id)),
    length: 255,
    keySignature: "9B",
    tempo: 132,
    links: {
      spotify: "https://open.spotify.com/track/1x8Binnp7g1U41qxk4eb2S"
    }
  },
  {
    id: "408b4588-9d28-4308-9af5-9e9d0a741a1e",
    title: "Shinobi Flow",
    imageURL: "https://i.scdn.co/image/ab67616d00001e020f4704a4efc14de52f380153",
    // -> Artist: Whispa
    creators: artists.filter((artist) => artist.id === "52d05eae-fb77-4406-b059-d9045bdc943b"), 
    length: 188,
    keySignature: "10B",
    tempo: 127,
    links: {
      spotify: "https://open.spotify.com/track/4BPMeYQn01PdrTT4oxjW3L"
    }
  },
  {
    id: "7fe6c2b7-d5d3-4f38-9a38-d987c09c50b2",
    title: "Introspective",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023a42d4f5d6a6eb3578e7357b",
    // -> Artist: Five Suns
    creators: artists.filter((artist) => artist.id === "cf86ac9a-861e-4ca5-930e-8b2ca003ea38"),
    length: 228,
    keySignature: "2B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/5kHkn1L2wwYPMiCBPsWu30"
    }
  },
  {
    id: "36e38d9a-9b49-4645-b02f-af00d7c26624",
    title: "Awake",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02164ceed681edd60045dc728c",
    // -> Artist: Forxst
    creators: artists.filter((artist) => artist.id === "3a997d7a-2282-46db-8357-331508091e83"),
    length: 193,
    keySignature: "3B",
    tempo: 122,
    links: {
      spotify: "https://open.spotify.com/track/6bpJGOXT9hjCD4qGNBwn9I"
    }
  },
  {
    id: "952b0d96-4281-46c4-9e4a-a42485506e75",
    title: "Dreamers",
    imageURL: "https://i.scdn.co/image/ab67616d00001e027b48a01785b82a2493a97ca0",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 226,
    keySignature: "2B",
    tempo: 139,
    links: {
      spotify: "https://open.spotify.com/track/6HutR0IgWZfcFwIS9GLW8w"
    }
  },
  {
    id: "496d0df2-f1c1-42a0-93d1-691bf4753e7d",
    title: "Lunar",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02c65bebd6528db77f21f16fc9",
    // -> Artists: Jake Robertz, Sorsari
    creators: artists.filter((artist) => ["f2e4ba6b-9fbc-4dcb-bad6-341e21f1e32c", "ef839db3-ae41-4af9-9078-a8d211089962"].includes(artist.id)),
    length: 264,
    keySignature: "3B",
    tempo: 174,
    links: {
      spotify: "https://open.spotify.com/track/49LgyQFqL0btazSJPnOwO6"
    }
  },
  {
    id: "f1354751-afd2-4f4e-b484-ba8ee3edb3ce",
    title: "Stargazing (TIGEREYES Flip)",
    imageURL: "https://i1.sndcdn.com/artworks-Uyi9ABTnQkyvnHoS-xbfOBQ-t500x500.jpg",
    // -> Artists: Tinashe, TIGERYES
    creators: artists.filter((artist) => ["153a21ba-7249-4ee8-9877-cbce6fa55d70", "ab918827-aa3e-4462-9a4d-dacf3759e37e"].includes(artist.id)),
    length: 233,
    keySignature: "3A",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 171.3,
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/tinashe-stargazing-tigereyes-flip"
    }
  },
  {
    id: "b0a7b92f-554f-4688-818f-edbb49c2458a",
    title: "Moon Shard",
    imageURL: "https://i1.sndcdn.com/artworks-000090417841-k6yamb-t500x500.jpg",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 328,
    keySignature: "2A",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 173.3,
    links: {
      soundCloud: "https://soundcloud.com/silkroadassassins/m-o-o-n-s-h-a-r-d"
    }
  },
  {
    id: "a9f4ca91-8e8c-4a9c-9b62-d6cd96b0969c",
    title: "Our Father Enki",
    imageURL: "https://www.theplayground.co.uk/wp-content/uploads/2019/06/ofdream.jpg",
    // -> Artist: Øfdream
    creators: artists.filter((artist) => artist.id === "e1c0dc8e-ef6f-4be8-b544-f59c78595481"),
    length: 206,
    keySignature: "3A",
    tempo: 179, // - TODO: -> Seems fast, maybe half-time (89.5)
    links: {
      googleDrive: "https://drive.google.com/file/d/15cDhm7l0ge5FZynH5ljg-zAKmOfDPPnU/view?usp=sharing"
    }
  },
  {
    id: "84e31dda-7479-45ff-9711-8ef9180c5064",
    title: "Talkin' (Blade Runner Blues Flip)",
    imageURL: "https://i.scdn.co/image/ab67616d00001e024d82e66ee889a273747881c1",
    // -> Artist: Weaver Beats
    creators: artists.filter((artist) => artist.id === "49950c18-900e-4def-a4b8-bc15d0dafaff"),
    length: 249,
    keySignature: "7B",
    tempo: 141,
    links: {
      spotify: "https://open.spotify.com/track/17o5xNABOJvDcvEcoYtqv7",
    }
  },
  {
    id: "14cc6adf-2092-4b46-8a3b-18a9fc2a9523",
    title: "Time Lapse",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026ffe019f956ebdaefb322b07",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 191,
    keySignature: "6B",
    tempo: 151,
    links: {
      spotify: "https://open.spotify.com/track/2CO6zld3nOnNQmY5um0wbS"
    }
  },
  {
    id: "890b7b50-9a09-4e1c-9967-e6ee0fe15c4f",
    title: "lost",
    imageURL: "https://i.scdn.co/image/ab67616d00001e026985a33761732dff68944a40",
    // -> Artist: lovewithme
    creators: artists.filter((artist) => artist.id === "d0cb8c08-e74b-430c-8054-52c1c99dd6c8"),
    length: 206,
    keySignature: "4B",
    tempo: 121,
    links: {
      spotify: "https://open.spotify.com/track/1pHK9YDtH2T1I19djXXOJ2",
    }
  },
  {
    id: "54448eed-f9ea-44f5-b942-64cf40ed1d56",
    title: "Eternally Yours",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0231cf84b55b54efea6d18153d",
    // -> Artist: Klimeks
    creators: artists.filter((artist) => artist.id === "9a4110ff-7417-431f-afb1-02d3d9d385fa"),
    length: 263,
    keySignature: "1B",
    tempo: 127,
    links: {
      spotify: "https://open.spotify.com/track/3ju6S1v9i1s9hzOm7hMRQr"
    }
  },
  {
    id: "b053a345-5352-4aca-ad9d-806da7c3e7c3",
    title: "Children of Gaia",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02aa47dda37351b5d8af2a5b74",
    // -> Artist: Sorsari
    creators: artists.filter((artist) => artist.id === "ef839db3-ae41-4af9-9078-a8d211089962"),
    length: 231,
    keySignature: "2B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/1HbFovNkmIkEtAWIjzMIHT"
    }
  },
  {
    id: "7110c2c6-200c-499a-b032-14998da04a50",
    title: "Guardian",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f75ec56e7854ae346d84ea63",
    // -> Artist: Noah B
    creators: artists.filter((artist) => artist.id === "5613bb7a-d148-4164-a329-812a72eb74e5"),
    length: 267,
    keySignature: "2B",
    tempo: 126,
    links: {
      spotify: "https://open.spotify.com/track/2vSPdjRb4TLcxPYHQl9FZx"
    }
  },
  {
    id: "b5a84189-d35e-4cfe-9818-deae2bb69227",
    title: "DÉJALO",
    imageURL: "https://i.scdn.co/image/ab67616d00001e021af79b520559bec7fa431d12",
    // -> Artist: A.CHAL
    creators: artists.filter((artist) => artist.id === "242463c2-3c15-40b1-920a-d197274109d7"), 
    length: 199,
    keySignature: "1B",
    tempo: 131,
    links: {
      spotify: "https://open.spotify.com/track/7KP8ydBNwzR5OIV9KWoLQc"
    }
  },
  {
    id: "42b523ae-791b-4e85-9d7c-9fbfcefa9cd1",
    title: "Two Days",
    imageURL: "https://i.scdn.co/image/ab67616d00001e028245ddb0cc806275e6b1d5fb",
    // -> Artist: Grey Killer
    creators: artists.filter((artist) => artist.id === "a969094d-4883-4140-9fb3-2cea6b032584"),
    length: 267,
    keySignature: "3B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/4cIemtxB4N3qOh2obXrqaG"
    }
  },
  {
    id: "9862dad5-d28f-48c4-9fbb-e8ca77dce2e4",
    title: "I'm Gonna Live Forever",
    imageURL: "https://i.scdn.co/image/ab67616d00001e025e9cf50da1bf161a022089f7",
    // -> Artist: Da Vosk Docta
    creators: artists.filter((artist) => artist.id === "b323309d-9910-421b-a60e-161d8ac3633c"),
    length: 227,
    keySignature: "2B",
    tempo: 110,
    links: {
      spotify: "https://open.spotify.com/track/3KJJtuYAPwSdt2B3Sx8UzV"
    }
  },
  {
    id: "5ebc3901-f5c1-4bc8-8c8c-0413dee34fe4",
    title: "Worth",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02aae3b839d3d24d30d8576e16",
    // -> Artist: Dyzphoria 
    creators: artists.filter((artist) => artist.id === "169286b0-b3d4-4829-b080-8ba4d8c471ca"),
    length: 249,
    keySignature: "5B",
    tempo: 134,
    links: {
      spotify: "https://open.spotify.com/track/6pzfjQyNPdYILP91GvZz7o"
    }
  },
  {
    id: "2773dad6-b45a-4034-9554-4926110e66b1",
    title: "Battle Angel",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273fea592d00606545962c47138",
    // -> Artist: Klasey Jones
    creators: artists.filter((artist) => artist.id === "b09d61f7-4313-4862-9e08-3a3621275d93"),
    length: 302,
    keySignature: "6B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/7gxMZnQjNzTfaGOgV7i7j9"
    }
  },
  {
    id: "328e8999-9d89-48ee-a598-a1e99738ad9f",
    title: "Emerald Rush",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029d9126ec04bb005c687291a5",
    // -> Artist: Jon Hopkins
    creators: artists.filter((artist) => artist.id === "afcec413-2dc5-4380-b37b-f7071c6a9e71"),
    length: 337,
    keySignature: "3B",
    tempo: 107,
    links: {
      spotify: "https://open.spotify.com/track/5S3F5GL8LASIjPg2PMiU1Q"
    }
  },
  {
    id: "2b988a9e-30b0-4733-bc57-0ab45f6a29f8",
    title: "medecine",
    imageURL: "https://i.scdn.co/image/ab67616d0000b273f7d360fe9f4ee94724b599d2",
    // -> Artist: foxwedding
    creators: artists.filter((artist) => artist.id === "4283d9e4-92ef-41f0-a6cf-b48897facae1"),
    length: 207,
    keySignature: "4B",
    tempo: 102,
    links: {
      spotify: "https://open.spotify.com/track/6pr6sb0NygETFtpRgCJvCh"
    }
  },
  {
    id: "2f4a2d79-5f4c-4fdf-bab5-a1b59af7c9d2",
    title: "Old Friend",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02659d9269fc8b0b81beaa1abd",
    // -> Artists: Elderbrook
    creators: artists.filter((artist) => artist.id === "4b98a187-1459-4e6a-b07c-34a25f755d32"), 
    length: 200,
    keySignature: "5B",
    tempo: 122,
    links: {
      spotify: "https://open.spotify.com/track/7l1NMHXBISVLarwGyfgmFP"
    }
  },
  {
    id: "e30ada19-d183-4c62-811a-49e85a5e54ac",
    title: "Lost",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02e131998db0fa5ddbac755a72",
    // -> Artists: Kareful
    creators: artists.filter((artist) => artist.id === "ca5623c9-4268-47f0-8569-8a562e4e9bbe"),
    length: 177,
    keySignature: "6B",
    tempo: 115,
    links: {
      spotify: "https://open.spotify.com/track/06zt2XBRUVFpnePcUPMVlS"
    }
  },
  {
    id: "44a9bc9c-12bf-4380-8d22-aa658aaf287f",
    title: "Weekend",
    imageURL: "https://i.scdn.co/image/ab67616d00001e029a695704d06016a608ea5bd6",
    // -> Artists: Flume, Moses Sumney
    creators: artists.filter((artist) => ["22ee6f55-4930-451a-9c54-aa781a269be3", "4863d296-41f1-4db6-87b6-186bf552a35b"].includes(artist.id)),
    length: 259,
    keySignature: "3B",
    tempo: 130,
    links: {
      spotify: "https://open.spotify.com/track/797cARXs5UuXT7anWuILTP"
    }
  },
  {
    id: "fbb27f44-3d4b-4ec9-918b-898335b6f931",
    title: "Lines",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02bc457208d83aa168ddad4e3c",
    // -> Artist: Landechoe
    creators: artists.filter((artist) => artist.id === "f56349f7-c4fc-4ce9-afc8-fe7ae8da90bf"),
    length: 233,
    keySignature: "4B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/159KGXUNcJ8a4EUXlF4ITQ"
    }
  },
  {
    id: "0dbdd39d-a2bf-4ccb-9904-97e12134df96",
    title: "You Mean",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0299ef126a972968f598fcf796",
    // -> Artist: Juche
    creators: artists.filter((artist) => artist.id === "372c112f-75fc-4dcf-a4f5-bd514bdd6a18"),
    length: 216,
    keySignature: "1B",
    tempo: 138,
    links: {
      spotify: "https://open.spotify.com/track/6AzyTi3vfBHrOcEKiYLVCh"
    }
  },
  {
    id: "28244d1a-1061-4cff-a035-e52765640349",
    title: "Admire",
    imageURL: "https://i.scdn.co/image/ab67616d00001e025de30a2b016327c0fb8e34f1",
    // -> Artists: Pholo, gl00my
    creators: artists.filter((artist) => ["59ad7bf8-4667-45b6-96d4-87f70573b936", "483a9c78-cbfa-4b7c-8494-48e2e8631846"].includes(artist.id)),
    length: 151,
    keySignature: "2B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/6kTpVYekCxIc5GQEyTvCqt"
    }
  },
  {
    id: "d5395e58-9e64-4fec-9622-a478fa7f567c",
    title: "Bowman",
    imageURL: "https://i.scdn.co/image/ab67616d0000b2737ca0a5beed6b21ad2ff08dad",
    // -> Artist: Silk Road Assassins
    creators: artists.filter((artist) => artist.id === "e43371e3-20c0-4f81-ab86-172e01d1cda9"),
    length: 218,
    keySignature: "2B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further.
    tempo: 167,
    links: {
      spotify: "https://open.spotify.com/track/4CSCra3pJ8LfqUSaRgD7jr"
    }
  },
  {
    id: "0a39acb3-9d29-4cab-a83a-6235e9c7e0f0",
    title: "Deep Down",
    imageURL: "https://i.scdn.co/image/ab67616d00001e02f6e963a2c1a65a1dd668d265",
    // -> Artist: Vacant
    creators: artists.filter((artist) => artist.id === "125105d9-775a-429c-ab9e-04f12f440cdc"),
    length: 293,
    keySignature: "3B",
    // - TODO: -> Tempo seems a bit fast here to my ear, look into this further, could be half time of this (88.5)
    tempo: 177,
    links: {
      spotify: "https://open.spotify.com/track/36ncF2SN4Och67K02kljQf"
    }
  },
  {
    id: "d7938e6c-a1cd-4b7c-afd2-4fbfa944b4e4",
    title: "Lotus",
    imageURL: "https://i.scdn.co/image/ab67616d00001e0231cf84b55b54efea6d18153d",
    // -> Artists: Skit, Kareful
    creators: artists.filter((artist) => ["f828cc93-b86e-4fe6-abde-40dfcc37686a", "ca5623c9-4268-47f0-8569-8a562e4e9bbe"].includes(artist.id)),
    length: 197,
    keySignature: "3B",
    tempo: 140,
    links: {
      spotify: "https://open.spotify.com/track/4qEv3o9fRYpUrb9VenkC1P"
    }
  },
  {
    id: "efd49e40-36e3-43e9-aba1-390c2c1e909d",
    title: "Iridescent",
    imageURL: "https://i.scdn.co/image/ab67616d00001e023a54566749e16210ace2d62a",
    // -> Artist: Five Suns
    creators: artists.filter((artist) => artist.id === "cf86ac9a-861e-4ca5-930e-8b2ca003ea38"),
    length: 325,
    keySignature: "2B",
    tempo: 135,
    links: {
      spotify: "https://open.spotify.com/track/41SyUySjOYAMCBL9Me2JC7"
    }
  },
  {
    id: "cb13e07a-7f59-495c-bd54-d133994d5176",
    title: "Cement",
    imageURL: "https://i.scdn.co/image/ab67616d0000b27398dcfc644d6c13a724b3e0c9",
    // -> Artist: Klasey Jones
    creators: artists.filter((artist) => artist.id === "b09d61f7-4313-4862-9e08-3a3621275d93"),
    length: 289,
    keySignature: "1B",
    tempo: 120,
    links: {
      spotify: "https://open.spotify.com/track/57VM8YqkSLUbP3ovGQBW4g"
    }
  },
  {
    id: "16b9b97c-cf1e-4e02-997d-7df4e76389cb",
    title: "Behemoth (TIGEREYES Remix)",
    imageURL: "https://i1.sndcdn.com/artworks-000599187917-yru72v-t500x500.jpg",
    // -> Artist: TIGEREYES
    creators: artists.filter((artist) => artist.id === "ab918827-aa3e-4462-9a4d-dacf3759e37e"),
    length: 185,
    keySignature: "2A",
    tempo: 124.5,
    links: {
      soundCloud: "https://soundcloud.com/tigerr-eyes/bosa-behemoth-tigereyes-remix"
    }
  },
  {
    id: "fdc6063a-0e7c-407c-8b5e-1e2570ea5bd6",
    title: "Stwo Flip",
    imageURL: "https://i1.sndcdn.com/artworks-oaVSPwN9Ri29HurH-IOjSsQ-t500x500.jpg",
    // -> Artist: gl00my
    creators: artists.filter((artist) => artist.id === "483a9c78-cbfa-4b7c-8494-48e2e8631846"),
    length: 183,
    keySignature: "6A",
    tempo: 93.5,
    links: {
      soundCloud: "https://soundcloud.com/somethinginside/gl00my-stwo-flip"
    }
  },
  {
    id: "060fdc56-d1a9-4c7c-9d25-f910174765a9",
    title: "End of Cycle",
    imageURL: "https://i1.sndcdn.com/artworks-000555769470-9pv5uw-t500x500.jpg",
    // -> Artists: Øfdream, hidden jayeem
    creators: artists.filter((artist) => ["e1c0dc8e-ef6f-4be8-b544-f59c78595481", "cf7d712d-49ad-41f8-9e6f-8df8679ccfba"].includes(artist.id)),
    length: 208,
    keySignature: "5A",
    tempo: 99,
    links: {
      soundCloud: "https://soundcloud.com/jayeem_music/ofdream-w-hidden-jayeem-end-of-cycle"
    }
  },
  
];
