import { css } from 'aphrodite-jss';
import { Artist, Venue, Event } from 'src/Types';
import {
  ORTHOGONAL , ROUNDED, ROUND, LEFT, CENTER, RIGHT
} from '../../constants';
import { ThemeInfo } from '../UI_InfoProvider/UI_InfoProvider';
import { ITypeAheadSearchItem } from './stateManagement';

// const BASE_CRADLE = "Base Cradle";
// const DROP_CRADLE = "Drop Cradle";

// - TODO: -> Weakness in the code, want to use string constants but have to use string literals.
export interface ITypeAheadSearchProps {
  // -> General
  items: ITypeAheadSearchItem[];
  searchTerm: string;
  noImageModeEnabled?: boolean;
  searchInputPlaceholder?: string;
  handleSearchInputChange: any;
  noSearchResultsAvailableMsg?: string;
  isOpen?: boolean;
  disabled?: boolean;
  loadingStatus: boolean;
  onSelectItem: any;
  closeDropCradleOnSelect?: boolean;
  typeAheadSearchPadding?: number;
  openIcon?: any;
  closedIcon?: any;
  showBaseCradleIcon?: boolean;
  separator?: any;
  backgroundColor?: string;
  raised?: boolean;
  shadow?: string;
  geometry?: 'orthogonal' | 'rounded' | 'round';
  colorMode?: 'Color' | 'Greyscale';
  // -> Sizing and positioning and decoration
  baseCradleHeight?: number;
  baseCradleWidth?: number;
  baseCradleBorder?: string;
  dropCradleGap?: number;
  dropCradleBorder?: string;
  dropCradleHeight?: number;
  dropCradleWidth?: number;
  minDropCradleHeight?: number;
  minDropCradleWidth?: number;
  maxDropCradleHeight?: number;
  maxDropCradleWidth?: number;
  dropCradleAlignment?: 'left' | 'center' | 'right',
  // -> TypeAheadSearch item
  dropdownItemHeight?: number;
  dropdownItemGap?: number;
  showInnerCradleUnderlay?: boolean;
  // -> Custom styles
  customTypeAheadSearchCradleStyles?: any;
  customBaseCradleStyles?: any;
  customBaseItemTextStyles?: any;
  customDropCradleStyles?: any;
  customTypeAheadSearchItemCradleStyles?: any;
  customTypeAheadSearchItemInnerCradleStyles?: any;
  customTypeAheadSearchItemTextStyles?: any;
}

export type ITypeAheadSearchResult = Event | Artist | Venue;

// export interface ITypeAheadEventSearchResult {
//   resultDetails: Event;
// }

// export interface ITypeAheadArtistSearchResult {
//   resultDetails: Artist;
// }

// export interface ITypeAheadVenueSearchResult {
//   resultDetails: Venue;
// }

export type ISearchResultType = "EVENT" | "ARTIST" | "VENUE";

const determineTypeAheadSearchGeometry = (props: ITypeAheadSearchProps, styles: any) => {
  const { geometry } = props;
  const {
    baseCradleRounded, baseCradleRound,
    dropCradleRounded, dropCradleRound
  } = styles;
  let baseCradleGeometryStyle, dropCradleGeometryStyle;

  switch(geometry) { 
    case ORTHOGONAL: break;
    case ROUNDED:
      baseCradleGeometryStyle = baseCradleRounded;
      dropCradleGeometryStyle = dropCradleRounded;
      break;
    case ROUND:
      baseCradleGeometryStyle = baseCradleRound;
      dropCradleGeometryStyle = dropCradleRound;
      break;
    default: break;
  }

  return { baseCradleGeometryStyle, dropCradleGeometryStyle };
}

// - TODO: Figure this out for left, center, right align, as well as 
//         overrides for when drop cradle will fall out of viewport bounds.
// const computeDropCradleAlignment = (props: ITypeAheadSearchProps, styles: any) => {
  // const { dropCradleAlignment } = props;
  // - TODO: -> Get information about:
  //           -> Viewport dimensions
  //           -> Start/End X, and overall width for drop cradle
  //         -> Figure out if width of dropdown cradle will overflow 
  //            horizontal bounds of viewport.
  //            -> Come up with positioning contingencies for both
  //               left and right overflows.
// }typeAheadCradleStylechain

export const calibrateComponent = (
  props: ITypeAheadSearchProps,
  themeInfo: ThemeInfo,
  styles: any
) => {
  const { raised, disabled } = props;
  const {
    componentCradle, baseCradleRaised, baseCradleDisabled, baseCradle, staticBaseItemTextStyles,
    baseItemTextStyles, dropCradle, dropCradleRaised, customBaseCradleStyles,
    customBaseItemTextStyles, customDropCradleStyles, customTypeAheadSearchCradleStyles
  } = styles;

  const {
    baseCradleGeometryStyle, dropCradleGeometryStyle
  } = determineTypeAheadSearchGeometry(props, styles);

  const baseCradleRaisedStyle = raised ? baseCradleRaised : undefined;
  const disabledStyle = disabled ? baseCradleDisabled : undefined;

  const typeAheadCradleStylechain = css(componentCradle, customTypeAheadSearchCradleStyles);

  const baseCradleStylechain = css(
    baseCradle, baseCradleGeometryStyle,
    baseCradleRaisedStyle, disabledStyle, customBaseCradleStyles
  );
  const baseItemTextStylechain = css(staticBaseItemTextStyles, baseItemTextStyles, customBaseItemTextStyles);
  const dropCradleStylechain = css(dropCradle, dropCradleGeometryStyle, dropCradleRaised, customDropCradleStyles);

  return {
    typeAheadCradleStylechain, baseCradleStylechain,
    baseItemTextStylechain, dropCradleStylechain
  };
}

export const determineTypeAheadSearchItemGap = (props: ITypeAheadSearchProps, themeInfo: ThemeInfo) => {
  const {dropdownItemGap} = props;

  if (dropdownItemGap) return dropdownItemGap;
  else return themeInfo.distance.one;
}

export const computeLeftPropertyValue = (props: ITypeAheadSearchProps): number | string => {
  const { dropCradleAlignment } = props;
  let leftValue;

  if (dropCradleAlignment === LEFT) {
    leftValue = 0;
  } else if (dropCradleAlignment === CENTER) {
    leftValue = 0;
  } else if (dropCradleAlignment === RIGHT) {
    leftValue = "auto";
  } else leftValue = 0;

  return leftValue;
}

export const computeRightPropertyValue = (props: ITypeAheadSearchProps): number | string => {
  const { dropCradleAlignment } = props;
  let rightValue: number | string;

  if (dropCradleAlignment === LEFT) {
    rightValue = "auto";
  } else if (dropCradleAlignment === CENTER) {
    rightValue = 0;
  } else if (dropCradleAlignment === RIGHT) {
    rightValue = 0;
  } else rightValue = 0;

  return rightValue;
}

export const processTypeAheadSearchItems = (searchItems: ITypeAheadSearchResult[]) => {
  const processedItems: ITypeAheadSearchItem[] = [];
  
  searchItems.map((searchResult: ITypeAheadSearchResult) => {
    const { id, name, imageURL } = searchResult;
    processedItems.push({ id, name, imageURL });
    return "";
  })
  
  return processedItems;
}
