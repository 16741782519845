// -> Beyond codebase
import React, { useContext, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import EventCard from "../../../../Components/Cards/EventCard/EventCard";
import { SMALL_CARD_HEIGHT, BASE_CARD_HEIGHT, BASE_CARD_WIDTH } from "../../../../Components/Cards/EventCard/helpers";
import { ThemeInfo, UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { determineScreenDimensions } from "../../../../helpers";
import { Event } from "../../../../Types";
// -> Within component
import { styleGen } from "./EventGalleryStyles.ts";
import {
  IEventGalleryProps, SMALL_CARD_WIDTH, LARGE_CARD_WIDTH, GALLERY_WIDTH_BREAKPOINT,
} from "./helpers"

const { width: screenW } = determineScreenDimensions();

const EventGallery: React.FC<IEventGalleryProps> = (props) => {
  const [computedCardWidth, setComputedCardWidth] = useState(BASE_CARD_WIDTH);
  const [computedCardHeight, setComputedCardHeight] = useState(BASE_CARD_HEIGHT);
  const { events, onEventClick } = props;
  const { t } = useTranslation("page_events");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  // const computedCardWidth = (screenW <= GALLERY_WIDTH_BREAKPOINT) ? SMALL_CARD_WIDTH : LARGE_CARD_WIDTH;
  // const computedCardWidth = (screenW <= GALLERY_WIDTH_BREAKPOINT) ? (screenW / 2) - distance.nine : LARGE_CARD_WIDTH;
  const {
    galleryCradle, galleryNoEventsCradle, noContentFallbackText, customEventGalleryCradleStyles,
    galleryContentCradle
  } = styleGen(themeInfo, props, { cardWidth: computedCardWidth, cardHeight: computedCardHeight });

  useEffect(() => {
    const { width: screenWidth } = determineScreenDimensions();

    if (screenWidth <= GALLERY_WIDTH_BREAKPOINT) {
      setComputedCardWidth(screenWidth - distance.six);
      setComputedCardHeight(SMALL_CARD_HEIGHT);
    }

    const handleResize = debounce(() => {
      const { width: screenWidth } = determineScreenDimensions();

      if (screenWidth <= GALLERY_WIDTH_BREAKPOINT) {
        setComputedCardWidth(screenWidth - distance.six);
        setComputedCardHeight(SMALL_CARD_HEIGHT)
      }
      else {
        setComputedCardWidth(BASE_CARD_WIDTH);
        setComputedCardHeight(BASE_CARD_HEIGHT);
      }
    }, 10);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [setComputedCardWidth, setComputedCardHeight, distance]);

  return (
    <div className={css(galleryCradle, customEventGalleryCradleStyles)}>
      {
        (events.length > 0) ? (
          <div className={css(galleryContentCradle)}>
            {
              events.map((event: Event) => {
                const { id, name, imageURL } = event;
    
                return (
                  <EventCard
                    key={id}
                    event={event}
                    onClick={() => onEventClick(id)}
                  />
                );
              })
            }
          </div>
        ) : (
          <div className={css(galleryNoEventsCradle)}>
            <p className={css(noContentFallbackText)}>{t("noEventsToDisplayMsg")}</p>
          </div>
        )
      }
    </div>
  );
}

export default EventGallery;
