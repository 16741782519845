// -> Beyond Codebase
import React, { useContext } from 'react';
import * as yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { css } from 'aphrodite-jss';
import { useTranslation } from "react-i18next";
// import "yup-phone";
// -> Within Codebase
import Input from '../../../../../../Components/FormUtilities/Input/Input';
import Button from '../../../../../../Components/Button/Button';
import Spacer from '../../../../../../Components/LayoutUtilities/Spacer/Spacer';
import { UIContext, ThemeInfo } from '../../../../../../Components/UI_InfoProvider/UI_InfoProvider';
import { ROUNDED, TEMP_AVATAR_URL, TEMP_USER_ID, VERTICAL } from "../../../../../../constants";
// -> Within Component
import { IRegistrationFormState, IRegistrationFormProps } from './helpers';
import { styleGen } from './RegistrationFormStyles';

const initialValues: IRegistrationFormState = {
  name1: "",
  name2: "",
  email: "",
  phoneNumber: "",
  username: "",
  password: "",
  passwordConfirmation: "",
  invitationCode: "",
};


const RegistrationForm: React.FC<IRegistrationFormProps> = (props) => {
  const { t } = useTranslation("page_registration");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { onSubmit, onLoginClick } = props;
  const {
    formCradle, topCradle, bottomCradle, bottomMessageCradle, bottomMessageText,
    twoFieldsCradle
  } = styleGen(themeInfo);

  // - TODO: -> Build in asynchronous validation with a backend once that's full stack
  //            in order to validate the availability of a username (like Twitter does).
  //            Use lodash's throttle on the async validation so that it doesn't fire
  //            after every keypress, but only after a certain amount of time has passed.
  const validationSchema = yup.object({
    email: yup.string().email(t("emailFormatErrorMessage")), //.required(t("emailRequiredErrorMsg")),
    name1: yup.string(), //.required(t("firstNameRequiredErrorMsg")),
    name2: yup.string(), //.required(t("lastNameRequiredErrorMsg")),
    phoneNumber: yup.string(), //.phone("", true, t("phoneNumberFormatErrorMsg")), // -> First arg can be a country/region code
    username: yup.string(), //.required(t("usernameRequiredErrorMsg")),
    password: yup.string(), //.required(t("passwordRequiredErrorMsg")),
    passwordConfirmation: yup.string().oneOf(
      [yup.ref('password'), ''],
      t("passwordConfirmationMatchErrorMsg")
      ), //.required(t("passwordConfirmationRequiredErrorMsg")),
    invitationCode: yup.string(), //.required(t("invitationCodeRequiredErrorMsg")),
  });

  const onFormSubmission = (values: FormikValues, onSubmitProps: any) => {
    // const { resetForm, setSubmitting } = onSubmitProps;

    const enhancedValues = { ...values };
    enhancedValues.avatarUrl = TEMP_AVATAR_URL;
    enhancedValues.userID = TEMP_USER_ID;
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmission}
    >
      {
        (formik) => {
          return (
            <Form className={css(formCradle)}>
              <div className={css(topCradle)}>

                <div className={css(twoFieldsCradle)}>
                  <Input
                    required
                    name="name1"
                    label={t("firstNameInputLabel")}
                    width="100%"
                    borderGeometry={ROUNDED}
                    mode="border"
                    customInputCradleStyles={{ height: 35 }}
                  />

                  <Input
                    // required
                    name="name2"
                    label={t("lastNameInputLabel")}
                    width="100%"
                    borderGeometry={ROUNDED}
                    mode="border"
                    customInputCradleStyles={{ height: 35 }}
                  />
                </div>
                <Spacer direction={VERTICAL} amount={distance.two} />

                <div className={css(twoFieldsCradle)}>
                  <Input
                    required
                    name="email"
                    label={t("emailInputLabel")}
                    width="100%"
                    borderGeometry={ROUNDED}
                    mode="border"
                    customInputCradleStyles={{ height: 35 }}
                  />
                  
                  <Input
                    name="phoneNumber"
                    label={t("phoneNumberInputLabel")}
                    width="100%"
                    borderGeometry={ROUNDED}
                    mode="border"
                    customInputCradleStyles={{ height: 35 }}
                  />
                </div>
                <Spacer direction={VERTICAL} amount={distance.two} />

                <div className={css(twoFieldsCradle)}>
                  <Input
                    required
                    name="username"
                    label={t("usernameInputLabel")}
                    width="100%"
                    borderGeometry={ROUNDED}
                    mode="border"
                    customInputCradleStyles={{ height: 35 }}
                  />

                  <Input
                    required
                    name="password"
                    label={t("passwordInputLabel")}
                    width="100%"
                    type="password"
                    borderGeometry={ROUNDED}
                    mode="border"
                    customInputCradleStyles={{ height: 35 }}
                  />
                </div>
                <Spacer direction={VERTICAL} amount={distance.two} />

                <div className={css(twoFieldsCradle)}>
                  <Input
                    required
                    name="passwordConfirmation"
                    label={t("passwordConfirmationInputLabel")}
                    width="100%"
                    type="password"
                    borderGeometry={ROUNDED}
                    mode="border"
                    customInputCradleStyles={{ height: 35 }}
                  />

                  <Input
                    required
                    name="invitationCode"
                    label={t("invitationCodeInputLabel")}
                    width="100%"
                    borderGeometry={ROUNDED}
                    mode="border"
                    customInputCradleStyles={{ height: 35 }}
                  />
                </div>
              </div>
              
              <Spacer direction={VERTICAL} amount={distance.two} />

              <div className={css(bottomCradle)}>
                <Button
                  onClick={() => {}}
                  buttonText={t("signUpButtonLabel")}
                  type="submit"
                  // disabled={!formik.isValid || !formik.dirty || formik.isSubmitting }
                  customTextStyles={{
                    fontSize: '1.6rem',
                    fontWeight: 'bold'
                  }}
                  customCradleStyles={{
                    paddingTop: themeInfo.distance.one,
                    paddingBottom: themeInfo.distance.one,
                    paddingRight: themeInfo.distance.two,
                    paddingLeft: themeInfo.distance.two,
                    width: "100%",
                    height: 35,
                  }}
                />
                <Spacer direction={VERTICAL} amount={distance.two} />

                <div className={css(bottomMessageCradle)}>
                  <p className={css(bottomMessageText)}>{t("existingAccountMsg")}</p>
                  <Button
                    buttonText={t("loginButtonLabel")}
                    transparent
                    onClick={onLoginClick}
                    customTextStyles={{
                      fontSize: '1.2rem',
                      color: themeInfo.palette.primary
                    }}
                    customCradleStyles={{
                      paddingTop: themeInfo.distance.one,
                      paddingBottom: themeInfo.distance.one,
                      paddingRight: themeInfo.distance.two,
                      paddingLeft: themeInfo.distance.two,
                    }}
                  />
                </div>
              </div>
            </Form>
          )
        }
      }
    </Formik>
  );
}

export default RegistrationForm;