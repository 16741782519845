import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { ITopBarProps, TOP_BAR_DEFAULT_HEIGHT } from "./helpers";

export const styleGen = (props: ITopBarProps, themeInfo: ThemeInfo) => {
  const { distance, palette, fonts, borderRadii } = themeInfo;
  const { customTopBarCradleStyles } = props;

  return StyleSheet.create({
    topBarCradle: {
      // height: "auto",
      height: TOP_BAR_DEFAULT_HEIGHT,
      width: "100%",
      paddingRight: distance.three,
      paddingLeft: distance.three,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: palette.white,
    },
    titleText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "3.0rem",
      color: palette.black,
    },
    topBarButtonRow: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    additionalOptionsButtonRow: {
      height: "auto",
      width: "min-content",
      padding: 0,
      // paddingBottom: distance.one,
      display: "flex",
      alignItems: "center",
      borderRadius: borderRadii.one,
      backgroundColor: palette.white,
      transition: themeInfo.transitions.bgColorTransition,
    },
    additionalOptionsButtonRowOpen: {
      backgroundColor: palette.grey1,
    },
    activityIndicatorCradle: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    customTopBarCradleStyles: { ...customTopBarCradleStyles },
  });
}
