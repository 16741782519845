import { StyleSheet } from 'aphrodite-jss';
import { NAV_BAR_HEIGHT } from '../../constants';
import { ThemeInfo } from '../../Components/UI_InfoProvider/UI_InfoProvider';

export const styleGen = (themeInfo: ThemeInfo) => {
  const { palette, distance, typography } = themeInfo;

  return StyleSheet.create({
    pageCradle: {
      height: 'auto',
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      width: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: distance.three,
      background: palette.white,
    },
    noContentFallbackTextCradle: {
      height: '100%',
      width: '100%',
      padding: distance.three,
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: "center",
      background: palette.white,
    },
    noContentFallbackText: { ...typography.noContentFallbackText },
  });
}
