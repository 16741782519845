import * as React from "react";
import { IIconProps } from "../../../Types";

const GraphIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...Object.assign({}, {...props}, {...interpretedProps})}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle cx="3.8" cy="16.64" r="3.3"/>
          <circle cx="9.12" cy="5.91" r="3.3"/>
          <circle cx="21.2" cy="7.77" r="3.3"/>
          <circle cx="15.71" cy="19.09" r="3.3"/>
          <line style={{ stroke: "#000", strokeMiterlimit: 10 }} x1="3.8" y1="16.64" x2="9.12" y2="5.91"/>
          <line style={{ stroke: "#000", strokeMiterlimit: 10 }} x1="21.2" y1="7.77" x2="9.12" y2="5.91"/>
          <line style={{ stroke: "#000", strokeMiterlimit: 10 }} x1="15.71" y1="19.09" x2="9.12" y2="5.91"/>
          <line style={{ stroke: "#000", strokeMiterlimit: 10 }} x1="3.8" y1="16.64" x2="21.2" y2="7.77"/>
          <line style={{ stroke: "#000", strokeMiterlimit: 10 }} x1="15.71" y1="19.09" x2="21.2" y2="7.77"/>
          <line style={{ stroke: "#000", strokeMiterlimit: 10 }} x1="3.8" y1="16.64" x2="15.71" y2="19.09"/>
          <rect style={{ fill: "none" }} width="25" height="25"/>
        </g>
      </g>
    </svg>
  );
}

export default GraphIcon;

GraphIcon.defaultProps = {
  color: "black"
}
