import { v4 as uuid } from 'uuid';
import { IDropdownItem } from "src/Components/Dropdown/stateManagement";

export const generateDropdownItems = (options: string[]): IDropdownItem[] => {
  let dropdownItems: IDropdownItem[] = [];

  options.map((option: string) => {
    dropdownItems.push({ id: uuid(), label: option });
    return true;
  });

  return dropdownItems;
}
