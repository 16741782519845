// -> Beyond Codebase
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// -> Within Codebase
// import FullPageLoader from "../../Pages/FullPageLoader/FullPageLoader";
import DataContextProvider from "../DataContextProvider/DataContextProvider";
import UI_InfoProvider from '../UI_InfoProvider/UI_InfoProvider';
import AuthContextProvider from "../AuthContextProvider/AuthContextProvider";
import App from '../App/App';

const WrappedApp: React.FC = () => {
  return (
    <DataContextProvider>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <UI_InfoProvider>
        <AuthContextProvider>
          <Router>
            {/* <Suspense fallback={FullPageLoader}>
            </Suspense> */}
            <App />
          </Router>
        </AuthContextProvider>
      </UI_InfoProvider>
    </DataContextProvider>
  );
}

export default WrappedApp;
