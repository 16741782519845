import { css } from 'aphrodite-jss';
import { ORTHOGONAL, ROUNDED, ROUND, BORDER } from '../../../constants';

export interface ITextAreaProps {
  height?: number;
  width?: number | string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  name: string;
  type?: string;
  rtl?: boolean;
  resize?: string;
  mode?: 'underline' | 'border';
  numberOnly?: boolean;
  maxLength?: number;
  min?: string;
  max?: string;
  maxWidth?: number | string;
  borderGeometry?: 'orthogonal' | 'rounded' | 'round';
  showLabel?: boolean;
  showErrorMessage?: boolean;
  useManualTextAreaField?: boolean;
  value?: string;
  onChange?: any;
  errorMessage?: string;
  customControlCradleStyles?: any;
  customControlCradleHoverStyles?: any;
  customLabelCradleStyles?: any;
  customLabelTextStyles?: any;
  customTextAreaCradleStyles?: any;
  customTextAreaTextStyles?: any;
  customErrorCradleStyles?: any;
  customErrorTextStyles?: any;
}

const determineTextAreaBorderGeometry = (borderGeometry: string | undefined, styles: any) => {
  const { borderRounded, borderRound } = styles;
  let borderGeometryStyle;

  switch (borderGeometry) {
    case ORTHOGONAL: break;
    case ROUNDED:
      borderGeometryStyle = borderRounded;
      break;
    case ROUND:
      borderGeometryStyle = borderRound;
      break;
    default:
      borderGeometryStyle = borderRounded;
      break;
  }

  return borderGeometryStyle;
}

export const calibrateComponent = (props: ITextAreaProps, styles: any) => {
  const { borderGeometry, mode } = props;
  const {
    customControlCradleStyles, customLabelCradleStyles,
    customLabelTextStyles, customErrorCradleStyles,
    customErrorTextStyles, formControlCradle, labelCradle,
    labelText, errorCradle, errorText, customControlCradleHoverStyles,
    customTextAreaCradleStyles, customTextAreaTextStyles, textAreaCradle, textAreaText,
    defaultBorderStyle, defaultUnderlineStyle,
  } = styles;

  // -> Style computations
  const textAreaThemeStyle = (mode === BORDER) ? defaultBorderStyle : defaultUnderlineStyle;
  const borderStyle = determineTextAreaBorderGeometry(borderGeometry, styles);
  const conditionalBorderStyle = (mode === BORDER) ? borderStyle : undefined;

  // -> Stylechain construction
  const formControlCradleStylechain = css(
    formControlCradle, customControlCradleStyles, customControlCradleHoverStyles
  );
  const labelCradleStylechain = css(labelCradle, customLabelCradleStyles);
  const labelTextStylechain = css(labelText, customLabelTextStyles);
  const textAreaStylechain = css(
    textAreaCradle, textAreaText, conditionalBorderStyle, textAreaThemeStyle,
    customTextAreaCradleStyles, customTextAreaTextStyles, 
  );
  const errorCradleStylechain = css(errorCradle, customErrorCradleStyles);
  const errorTextStylechain = css(errorText, customErrorTextStyles);

  return {
    formControlCradleStylechain, labelCradleStylechain,
    labelTextStylechain, textAreaStylechain,
    errorCradleStylechain, errorTextStylechain
  };
}
