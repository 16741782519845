import { useEffect, useRef } from "react";

export const useClickOutside = (onClickAction: any) => {
  let boundaryNodeRef = useRef<any>();

  useEffect(() => {
    const clickHandler = (evt: any) => {
      if (!(boundaryNodeRef.current?.contains(evt.target))) {
        onClickAction(evt);
      }
    }

    document.addEventListener("mousedown", clickHandler);

    return () => document.removeEventListener("mousedown", clickHandler);
  }, [onClickAction]);

  return boundaryNodeRef;
}
