import { StyleSheet } from 'aphrodite-jss';
import { ThemeInfo } from '../../Components/UI_InfoProvider/UI_InfoProvider';
import { NAV_BAR_HEIGHT } from '../../constants';

export const styleGen = (themeInfo: ThemeInfo) => {
  const {
    distance, fonts, borderRadii, shadows, palette,
    stackingIndexes, typography,
  } = themeInfo;

  return StyleSheet.create({
    cradle: {
      height: '100vh',
      // minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      // minHeight: "100vh",
      width: '100%',
      display: 'flex',
      position: "relative",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: palette.grey8,
    },
    titleText: {
      fontFamily: fonts.primary,
      fontSize: "3rem",
      fontWeight: 'bold',
      color: palette.primary,
    },
    titleCradle: {
      padding: distance.one,
      paddingLeft: distance.five,
      paddingRight: distance.five,
      borderRadius: borderRadii.one,
      backgroundColor: palette.primary_p4,
      width: "min-content",
      "whiteSpace": "nowrap",
    },
    bodyText: {
      fontFamily: fonts.primary,
      fontSize: "1.8rem",
      color: palette.black,
      textAlign: "center",
    },
    cardCradle: {
      height: "auto",
      width: 425,
      padding: distance.three,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      borderRadius: borderRadii.two,
      backgroundColor: palette.white,
      boxShadow: shadows.five,
      zIndex: stackingIndexes.backdrop - 1,

      "@media (max-width: 450px)": {
        width: 350,
      },
    },
    topOfCard: {
      height: "auto",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
    },
    addVenueRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    addVenuePromptText: {
      ...typography.default,
      fontSize: "1.4rem",
      color: palette.grey3,
      fontStyle: "italic",
      textAlign: "center",
    },
    blobGroup1Cradle: {
      position: "absolute",
      transform: "translateY(50%)",
    },
    blobGroup2Cradle: {
      position: "absolute",
      transform: "translateY(80%)",
    },
    blobGroup3Cradle: {
      position: "absolute",
      transform: "translateY(60%)",
    },
    blobGroup4Cradle: {
      position: "absolute",
      transform: "translateY(82%)",
    },
    blobGroup5Cradle: {
      position: "absolute",
      transform: "translateY(20%)",
    },
    blobGroup6Cradle: {
      position: "absolute",
      transform: "translateY(20%)",
    },
    blobGroup7Cradle: {
      position: "absolute",
      transform: "translateY(55%)",
    },
    blobGroup8Cradle: {
      position: "absolute",
      transform: "translateY(95%)",
    },
  })
}

export const wavyStyleGen = (themeInfo: ThemeInfo) => {
  const { palette, distance } = themeInfo;

  return StyleSheet.create({
    cradle: {
      height: 'auto',
      minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
      width: '100%',
      display: 'flex',
      flexDirection: "column",
      alignItems: 'center',
      color: palette.white,
      backgroundColor: palette.grey8,
      overflowX: "hidden",
    },
    section: {
      minHeight: 250,
      height: "auto",
      width: "100%",
      paddingTop: distance.eight,
      paddingBottom: distance.eight,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    primaryBG: { background: palette.primary },
    secondaryBG: { background: palette.secondary },
    tertiaryBG: { background: palette.tertiary },
    quaternaryBG: { background: palette.quaternary },
    SVGSpacer: {
      aspectRatio: 900/300,
      width: "100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    spacerLayer1: {
      backgroundImage: "url('./SVGWavesBG1.svg')",
    },
    blobGroup1Cradle: {
      position: "absolute",
      // top: "20%", left: 0, right: 0,
      transform: "translateY(-20%)",
    }
  })
}