// -> Beyond codebase
import React, { useContext, ChangeEvent, useRef, useEffect, useState, useMemo } from "react";
import { css } from "aphrodite-jss";
import cloneDeep from 'lodash.clonedeep';
import { useTranslation } from "react-i18next";
// -> Within codebase
import Dropdown from "../../Dropdown/Dropdown";
import Input from "../Input/Input";
import { UIContext, ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";
import {
  GREYSCALE, LEFT, ROUND, ROUNDED, HORIZONTAL,
  VERTICAL, DEFAULT_INPUT_HEIGHT
} from "../../../constants";
import {
  mapExternalLinkKeyToName, mapExternalLinkToIcon, mapExternalLinkNameToKey,
  navigateToExternalLinkHomePage
} from "../../../helpers";
import { IDropdownItem } from "../../Dropdown/stateManagement";
import IconButton from "../../Button/Variants/IconButton/IconButton";
import MinusIcon from "../../VisualUtilities/IconPresets/MinusIcon";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
// -> Within component
import {
  IExternalLinkEntryProps, computeAvailableExternalLinkOptions, determineDropdownOptions
} from "./helpers";
import { styleGen } from "./ExternalLinkEntryStyles";

const ExternalLinkEntry: React.FC<IExternalLinkEntryProps> = (props) => {
  const [URLFieldErrorStatus, setURLFieldErrorStatus] = useState(false);
  const {
    setState, index, linkName, URL, removeExternalLinkEntry, state, errorStatus,
    validateExternalLinkEntryURL, type, errorMessage
  } = props;

  const externalLinkDropdownOptions = useMemo(() => determineDropdownOptions(type), [type])

  const externalLinkOptions = useRef<IDropdownItem[]>([]);

  const { t } = useTranslation("component_ExternalLinkEntry");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { palette, shadows, transitions, styles, distance } = themeInfo;
  const {
    componentCradle, inputElementsCradle, dropdownRow, labelText,
    labelAndDropdownColumn,
  } = styleGen(themeInfo);

  useEffect(() => {
    externalLinkOptions.current = computeAvailableExternalLinkOptions(state, externalLinkDropdownOptions)
  }, [externalLinkDropdownOptions, state]);

  useEffect(() => {
    setURLFieldErrorStatus(errorStatus);
  }, [errorStatus]);

  // -----

  const onSelectLinkName = (linkName: string) => {
    const stateCopy = cloneDeep(state);

    if (type === "EVENT_TICKETS") {
      stateCopy.potentialTicketsLink.linkName = mapExternalLinkNameToKey(linkName);
    } else {
      if (stateCopy.potentialExternalLinks) {
        stateCopy.potentialExternalLinks[index].linkName = mapExternalLinkNameToKey(linkName);
      }
    }


    setState(stateCopy);
  };

  // -----

  const onChangeInputValue = (evt: ChangeEvent<HTMLInputElement>) => {
    const stateCopy = cloneDeep(state);

    if (type === "EVENT_TICKETS") {
      let { URLFieldTouched, URLFieldDirty } = stateCopy.potentialTicketsLink;
      if (!URLFieldTouched) URLFieldTouched = true;
  
      if (evt.target.value !== "") URLFieldDirty = true;
      else URLFieldDirty = true;

      stateCopy.potentialTicketsLink.URL = evt.target.value;
      stateCopy.potentialTicketsLink.URLFieldTouched = URLFieldTouched;
      stateCopy.potentialTicketsLink.URLFieldDirty = URLFieldDirty;

      validateExternalLinkEntryURL(evt.target.value);;
    } else {
      if (stateCopy.potentialExternalLinks) {
        let { URLFieldTouched, URLFieldDirty } = stateCopy.potentialExternalLinks[index];
  
        if (!URLFieldTouched) URLFieldTouched = true;
  
        if (evt.target.value !== "") URLFieldDirty = true;
        else URLFieldDirty = true;
  
        stateCopy.potentialExternalLinks[index].URL = evt.target.value;
        stateCopy.potentialExternalLinks[index].URLFieldTouched = URLFieldTouched;
        stateCopy.potentialExternalLinks[index].URLFieldDirty = URLFieldDirty;
  
        validateExternalLinkEntryURL(index, evt.target.value);;
      }
    }

    setState(stateCopy);
  };

  return (
    <div className={css(componentCradle)}>
      <div className={css(inputElementsCradle)}>
        <div className={css(dropdownRow)}>
          <div className={css(labelAndDropdownColumn)}>
            <p className={css(labelText)}>{t("linkSourceDropdownLabel")}</p>
            <Spacer direction={VERTICAL} amount={distance.one} />
            <Dropdown
              selectedItemValue={mapExternalLinkKeyToName(linkName)}
              onSelectItem={onSelectLinkName}
              // items={computeAvailableExternalLinkOptions(state, externalLinkDropdownOptions)}
              items={externalLinkDropdownOptions}
              colorMode={GREYSCALE}
              geometry={ROUNDED}
              showSelectionIndicatorIcon // - TODO: -> This is not working
              dropCradleAlignment={LEFT}
              customDropdownCradleStyles={{ height: DEFAULT_INPUT_HEIGHT, width: "100%" }}
              customDropCradleStyles={{ width: "100%" }}
            />
          </div>
          {
            (linkName !== "") ? (
              <>
                <Spacer direction={HORIZONTAL} amount={distance.three} />
                <IconButton
                  icon={mapExternalLinkToIcon(linkName, 20, palette.black)}
                  onClick={() => navigateToExternalLinkHomePage(linkName)}
                  buttonGeometry={ROUND}
                  color={palette.white}
                  customCradleStyles={{
                    transition: transitions.boxShadowTransition,
                    padding: distance.two,
                    height: 40, width: 40
                  }}
                  customCradleHoverStyles={{ boxShadow: shadows.one }}
                />
              </>
            ) : null
          }
        </div>

        <Input
          name={`externalLink-${index + 1}`}
          label={t("externalLinkURLLabel")}
          mode="border"
          useManualInputField={true}
          value={URL}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeInputValue(evt)}
          showErrorMessage={URLFieldErrorStatus}
          errorMessage={errorMessage}
          customInputTextStyles={{ fontSize: "1.4rem" }}
          customControlCradleStyles={{ width: "100%" }}
        />
      </div>

      <Spacer direction={HORIZONTAL} amount={distance.three} />

      <IconButton
        icon={<MinusIcon color={palette.white} size={styles.standardIconSize - 5} />}
        onClick={() => removeExternalLinkEntry(index, state)}
        buttonGeometry={ROUND}
        customCradleStyles={{
          padding: 3,
          backgroundColor: palette.primary,
          transition: transitions.boxShadowTransition
        }}
        customCradleHoverStyles={{ boxShadow: shadows.one }}
      />
    </div>
  );
}

export default ExternalLinkEntry;
