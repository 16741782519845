import { StyleSheet } from 'aphrodite-jss';
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";

export const styleGen = (themeInfo: ThemeInfo) => {
  const { fonts, palette, distance, borderRadii } = themeInfo;

  return StyleSheet.create({
    panelCradle: {
      height: "auto",
      width: 200,
      display: 'flex',
      flexDirection: 'column',
      overflow: "visible",
    },
    sortPanelTitleCradle: {
      padding: distance.one,
      borderRadius: borderRadii.one,
      background: palette.grey1,
      width: "min-content",
      "whiteSpace": "nowrap",
    },
    sortPanelTitleText: {
      fontFamily: fonts.primary,
      fontSize: "1.8rem",
      color: palette.black,
      fontWeight: "bold",
    },
    selectionDropdownLabel: {
      fontFamily: fonts.primary,
      fontSize: "1.2rem",
      color: palette.black,
    },
    bottomRow: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    bottomRightBtns: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
    }
  });
}
