// -> Beyond codebase
import React, { useCallback, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { css } from "aphrodite-jss";
// -> Within codebase
import Backdrop from "../Backdrop/Backdrop";
import { UIContext } from "../UI_InfoProvider/UI_InfoProvider";
// -> Within component
import { styleGen } from "./ModalStyles";
import { IModalProps } from "./helpers";

const Modal: React.FC<IModalProps> = (props) => {
  const { children, modalOpen, closeModal } = props;
  const UIInfo = useContext(UIContext);
  const { themeInfo, closeBackdrop } = UIInfo;
  const { modalCradle, customCradleStyles } = styleGen(themeInfo, props);

  const wasEscapePushed = useCallback((evt: any) => {
    if (evt.key === "Escape" && (modalOpen)) {
      if (closeModal) closeModal();
    }
  }, [modalOpen, closeModal]);
  
  useEffect(() => {
    document.addEventListener("keydown", (evt: any) => wasEscapePushed(evt));
    
    return () => document.removeEventListener("keydown", wasEscapePushed);
  }, [wasEscapePushed]);

  return (modalOpen) ? ReactDOM.createPortal(
    <>
      <Backdrop
        modalOpen={modalOpen}
        closeModal={closeModal}
        closeBackdrop={closeBackdrop}
      />
      <div className={css(modalCradle, customCradleStyles)}>
        {children}
      </div>
    </>,
    // - DEV NOTE -> Careful because this assumes you've created a div with an id of
    //               modal-root in index.html
    document.getElementById("modal-root")!
  ): null;
}

export default Modal;

Modal.defaultProps = {
  modalOpen: true,
}
