// -> Beyond codebase
import React, { useContext, useEffect, useRef, useState } from "react";
import { css } from "aphrodite-jss";
// -> Within codebase
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import VerticalFade from "../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import Modal from "../../Components/Modal/Modal";
import { UIContext, ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
import { ArtistAction, EventAction, IUser, VenueAction } from "../../Types";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, TEMP_AVATAR_URL } from "../../constants";
// -> Within component
import AdminDashboardDetails from "./InternalComponents/AdminDashboardDetails/AdminDashboardDetails";
import { styleGen } from "./AdminDashboardStyles";
import { IAdminDashboardState, mockRecentActions } from "./helpers";
import HistoricalActionModal from "src/Components/Modals/HistoricalActionModal/HistoricalActionModal";

const AdminDashboard: React.FC = () => {
  const [state, setState] = useState<IAdminDashboardState>({
    loadingStatus: true,
    loaderInStatus: true,
    fallbackContentInStatus: true,
    modalInStatus: true,
    userActionInfo: {
      actionID: "",
      actionType: "CREATE",
      actionTargetType: "ARTIST",
      dateTime: 290320182,
      userInfo: {
        userID: "69279a36-a59c-4dfd-9d4c-df703903d9fe",
        username: "Contributor X",
        avatarURL: TEMP_AVATAR_URL,
      },
      previousInfoState: {
        id: "",
        name: "Artist X",
      },
      newInfoState: undefined,
    },
  });
  const { getUser } = useContext(AuthContext);
  let user = useRef<IUser | undefined>(undefined);
  const UIInfo = useContext(UIContext);
  const { themeInfo, modalOpen, openModal, closeModal } = UIInfo;
  const { palette } = themeInfo;
  const {
    pageCradle, noContentFallbackText, noContentFallbackTextCradle
  } = styleGen(themeInfo);

  const adminContentArrivedTemp = true;
  let modalMicroanimationTimeout: any = -1;

  // -----

  const onOpenModal = (actionID: string) => {
    const actionToSet = mockRecentActions.find((action: ArtistAction | EventAction | VenueAction) => actionID === action.actionID);
    if (actionToSet) {
      setState((prevState) => ({
        ...prevState,
        modalInStatus: true,
        userActionInfo: actionToSet,
      }));
    }
    else setState((prevState) => ({ ...prevState, modalInStatus: true }));
    openModal();
  };

  const onCloseModal = () => {
    setState((prevState) => ({ ...prevState, modalInStatus: false }));
    modalMicroanimationTimeout = setTimeout(() => {
      closeModal();
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, loaderInStatus: false }));
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, loadingStatus: false }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }, 1000);
  }, []);

  const { 
    loadingStatus, loaderInStatus, fallbackContentInStatus,
    modalInStatus, userActionInfo
  } = state;

  return (
    <div className={css(pageCradle)}>
      {
        (loadingStatus) ? (
          <Fade inStatus={loaderInStatus}>
            <CircleLoader spinnerColor={palette.primary}/>
          </Fade>
        ) : (
          <>
            {
              (adminContentArrivedTemp) ? (
                <>
                  <AdminDashboardDetails actions={mockRecentActions} onOpenModal={onOpenModal} />
                  <Modal
                    modalOpen={modalOpen}
                    closeModal={onCloseModal}
                  >
                    <VerticalFade inStatus={modalInStatus}>
                      <HistoricalActionModal
                        action={userActionInfo}
                        onCloseModal={onCloseModal}
                      />
                    </VerticalFade>
                  </Modal>
                </>
              ) : (
                <Fade inStatus={fallbackContentInStatus}>
                  <div className={css(noContentFallbackTextCradle)}>
                    <p className={css(noContentFallbackText)}>
                      Something went wrong while trying to retrieve your profile information.
                    </p>
                  </div>
                </Fade>
              )
            }
          </>
        )
      }
    </div>
  );
}

export default AdminDashboard;
