// -> UI FEATURE DIMENSIONS
export const NAV_BAR_HEIGHT = 45;
export const DEFAULT_SWITCH_HEIGHT = 25;
export const DEFAULT_SWITCH_WIDTH = 50;
export const STATIC_MAP_XS_WIDTH = 290;
export const STATIC_MAP_SMALL_WIDTH = 320;
export const STATIC_MAP_LARGE_WIDTH = 520;
export const DEFAULT_INPUT_HEIGHT = 32.02;

// -> CHARACTER CODES
export const SPACE_CHAR_UNICODE = "\xa0";

// -> LANGUAGES
export const LANGUAGE__ENGLISH = "English";
export const LANGUAGE__FRENCH = "French";
export const AVAILABLE_LANGUAGES = [LANGUAGE__ENGLISH, LANGUAGE__FRENCH];
export const LANGUAGE__ENGLISH_FR = "Anglais";
export const LANGUAGE__FRENCH_FR = "Français";
export const AVAILABLE_LANGUAGES_FR = [LANGUAGE__ENGLISH_FR, LANGUAGE__FRENCH_FR];
export const LANGUAGE_CODE__ENGLISH = "en";
export const LANGUAGE_CODE__FRENCH = "fr";

// -> REGULAR EXPRESSIONS
// eslint-disable-next-line no-useless-escape
export const URL_PATTERN_REGEX = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
export const EMAIL_PATTERN_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// -> SCREEN SIZE BREAKPOINTS
export const SCREEN_WIDTH_375 = 375;
export const SCREEN_WIDTH_450 = 450;
export const SCREEN_WIDTH_500 = 500;
export const SCREEN_WIDTH_550 = 550;
export const SCREEN_WIDTH_750 = 750;
export const SCREEN_WIDTH_800 = 800;

// -> DISPLAY MODES
export const BORDER = 'border';
export const UNDERLINE = 'underline';
export const GRID = 'Grid';
export const LIST = 'List';
export const MAP = "Map";
export const AVAILABLE_DISPLAY_MODES = [GRID, LIST];
export const GRID_FR = "Cartes";
export const LIST_FR = "Liste";
export const MAP_FR = "Carte";
export const AVAILABLE_DISPLAY_MODES_FR = [GRID_FR, LIST_FR];

// -> THEMES
export const THEME__LIGHT = "Light";
export const THEME__DARK = "Dark";
export const AVAILABLE_THEMES = [THEME__LIGHT, THEME__DARK];
export const THEME__LIGHT_FR = "Clair";
export const THEME__DARK_FR = "Sombre";
export const AVAILABLE_THEMES_FR = [THEME__LIGHT_FR, THEME__DARK_FR];

// -> TIMING CONSTANTS
export const DEFAULT_TRANSITION_MICROANIMATION_TIME = 200;
export const DEFAULT_ALERT_HANG_TIME = 3500;
export const DEFAULT_DRAWER_ANIMATION_TIME = 350;
export const TOGGLE_SWITCH_TRANSITION_TIME = 250;
export const FIVE_MINUTES_MS = 5 * 60 * 1000;
export const TWO_MINUTES_MS = 2 * 60 * 1000;
export const PERIOD_MS_FOR_60_FPS = 1000 / 60;

// -> STORAGE KEYS
export const REFRESH_TOKEN_KEY = "refresh_token";
export const MFA_LOGOUT_EVENT_KEY = "mfa_logout_event";

// -> TEMP
export const TEMP_AVATAR_URL = "https://images.unsplash.com/photo-1576158113840-43db9ff3ef09?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1106&q=80";
export const TEMP_USER_ID = "6f0f8b27-b61b-42ed-8bf2-780e328f2360";
export const TEMP_LOGGED_IN_TOKEN = "TEMP_LOGGED_IN_TOKEN";

// -> FILE UPLOADS
export const ALLOWED_IMAGE_UPLOAD_FILE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

// -> FILTER CONDITIONS
// -> English
export const FILTER_CONDITION__NONE = "None";
export const FILTER_CONDITION__EQUAL_TO = "(==) Equal to";
export const FILTER_CONDITION__NOT_EQUAL_TO = "(!=) Not Equal to";
export const FILTER_CONDITION__GREATER_THAN = "(>) Greater Than";
export const FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO = "(>=) Greater Than or Equal to";
export const FILTER_CONDITION__LESS_THAN = "(<) Less Than";
export const FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO = "(<=) Less Than or Equal to";
export const FILTER_CONDITION__INCLUDED_IN = ("Included in");
export const FILTER_CONDITION__NOT_INCLUDED_IN = ("Not Included in");
export const AVAILABLE_FILTER_CONDITIONS = [
  FILTER_CONDITION__NONE, FILTER_CONDITION__EQUAL_TO, FILTER_CONDITION__NOT_EQUAL_TO,
  FILTER_CONDITION__GREATER_THAN, FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO,
  FILTER_CONDITION__LESS_THAN, FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO,
  FILTER_CONDITION__INCLUDED_IN, FILTER_CONDITION__NOT_INCLUDED_IN
];
// -> French
export const FILTER_CONDITION__NONE_FR = "Aucun";
export const FILTER_CONDITION__EQUAL_TO_FR = "(==) Égal";
export const FILTER_CONDITION__NOT_EQUAL_TO_FR = "(!=) Pas égal";
export const FILTER_CONDITION__GREATER_THAN_FR = "(>) Plus grand que";
export const FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO_FR = "(>=) Plus grand que ou égal";
export const FILTER_CONDITION__LESS_THAN_FR = "(<) Moins que";
export const FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO_FR = "(<=) Moins que ou égal";
export const FILTER_CONDITION__INCLUDED_IN_FR = ("Inclus dans");
export const FILTER_CONDITION__NOT_INCLUDED_IN_FR = ("Non inclus dans");
export const AVAILABLE_FILTER_CONDITIONS_FR = [
  FILTER_CONDITION__NONE_FR, FILTER_CONDITION__EQUAL_TO_FR, FILTER_CONDITION__NOT_EQUAL_TO_FR,
  FILTER_CONDITION__GREATER_THAN_FR, FILTER_CONDITION__GREATER_THAN_OR_EQUAL_TO_FR,
  FILTER_CONDITION__LESS_THAN_FR, FILTER_CONDITION__LESS_THAN_OR_EQUAL_TO_FR,
  FILTER_CONDITION__INCLUDED_IN_FR, FILTER_CONDITION__NOT_INCLUDED_IN_FR
];

// -> PAGINATION
export const DATA_ITEMS_PER_PAGE = 100;
export const MIN_DATA_ITEMS_PER_PAGE = 25;
export const MAX_DATA_ITEMS_PER_PAGE = 200;

// -> MUSIC PROCESSING
export const DEFAULT_BPM_MATCHING_RANGE = 10;

// -> QUERY PARAM KEYS
export const RESET_PASSWORD_TOKEN_KEY="password_reset_token";
export const PAGE_NUM_PARAM_KEY = "page";

// -> AUTH
export const MFA_TYPE__NONE = "None";
export const MFA_TYPE__SMS = "SMS";
export const MFA_TYPE__EMAIL = "Email";
export const AVAILABLE_MFA_TYPES = [MFA_TYPE__NONE, MFA_TYPE__SMS, MFA_TYPE__EMAIL];
export const MFA_TYPE__NONE_FR = "Aucun";
export const MFA_TYPE__SMS_FR = "Méssage texte";
export const MFA_TYPE__EMAIL_FR = "Email";
export const AVAILABLE_MFA_TYPES_FR = [MFA_TYPE__NONE_FR, MFA_TYPE__SMS_FR, MFA_TYPE__EMAIL_FR];

export const DEFAULT_SEARCH_DEBOUNCE_TIME = 250;
