import * as React from "react";
import { IIconProps } from "../../../Types";

const EventbriteIcon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { size, color } = props;
  const interpretedProps: any = { };

  if (size) {
    interpretedProps.height = size;
    interpretedProps.width = size;
  }
  if (color) {
    interpretedProps.fill = color;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...Object.assign({}, {...props}, {...interpretedProps})}
    >
      <title>DiscoBall</title>
      <g id="Icons">
        <path d="M6.47,15.26s.04,.07,.06,.1c.92,1.25,2.12,2.07,3.63,2.46,.56,.14,1.12,.21,1.69,.19,1.4-.05,2.66-.5,3.76-1.37,.48-.38,.89-.81,1.24-1.31,.07-.1,.07-.1,.19-.07,1.71,.39,3.41,.78,5.12,1.17,.05,.01,.09,.03,.15,.04-.02,.05-.03,.08-.05,.12-1.43,3.09-4.14,5.4-7.42,6.32-.6,.17-1.21,.29-1.83,.36-.64,.08-1.27,.1-1.91,.06-2.99-.18-5.56-1.31-7.68-3.41-1.54-1.52-2.55-3.35-3.08-5.46-.14-.55-.23-1.1-.28-1.66-.04-.45-.07-.91-.06-1.36,.08-2.81,1.03-5.29,2.86-7.41C4.51,2.12,6.58,.89,9.03,.31c.5-.12,1-.2,1.5-.25,.5-.05,.99-.07,1.49-.05,2.6,.1,4.92,.95,6.96,2.57,1.84,1.47,3.11,3.35,3.83,5.6,.2,.62,.34,1.26,.43,1.91,.05,.38,.09,.76,.1,1.15,0,.13,0,.13-.12,.16-.94,.22-1.88,.43-2.83,.65-1.64,.38-3.27,.76-4.91,1.13-1.57,.36-3.14,.72-4.71,1.09-1.4,.32-2.79,.64-4.19,.97-.03,0-.07,.02-.11,.03Zm-1.05-4.58c.06-.01,.1-.02,.14-.03,.63-.14,1.26-.29,1.88-.44,1.45-.33,2.9-.67,4.36-1,1.01-.23,2.02-.47,3.04-.7,.65-.15,1.29-.3,1.94-.45,.03,0,.05-.02,.08-.03-1.3-1.92-3.99-3.25-6.84-2.47-1.41,.39-2.56,1.18-3.43,2.35-.61,.82-.99,1.74-1.16,2.77Z"/>
      </g>
    </svg>
  );
}

export default EventbriteIcon;

EventbriteIcon.defaultProps = {
  color: "black"
}