import { StyleSheet } from "aphrodite";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DEFAULT_CARD_WIDTH } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo) => {
  return StyleSheet.create({
    cardCradle: {
      height: "auto",
      width: DEFAULT_CARD_WIDTH,
      padding: themeInfo.distance.three,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: themeInfo.shadows.one,
      borderRadius: themeInfo.borderRadii.one,
      backgroundColor: themeInfo.palette.white,
    },
    promptText: {
      ...themeInfo.typography.default,
      fontSize: "1.5rem",
      fontStyle: "italic",
      color: themeInfo.palette.grey3,
      marginBottom: 0,
      textAlign: "center",
    },
    formCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
    },
    digitsCradle: {
      height: "auto",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  });
};
