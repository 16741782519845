// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import VenueCard from "../../../../Components/Cards/VenueCard/VenueCard";
import { ThemeInfo, UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { determineScreenDimensions } from "../../../../helpers";
import { Venue } from "../../../../Types";
// -> Within component
import { styleGen } from "./VenueGalleryStyles";
import {
  VenueGalleryProps, SMALL_CARD_WIDTH, LARGE_CARD_WIDTH, GALLERY_WIDTH_BREAKPOINT
} from "./helpers";

const { width: screenW } = determineScreenDimensions();

const VenueGallery: React.FC<VenueGalleryProps> = (props) => {
  const { venues, onVenueClick } = props;
  const { t } = useTranslation("page_venues");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const computedCardWidth = (screenW <= GALLERY_WIDTH_BREAKPOINT) ? SMALL_CARD_WIDTH : LARGE_CARD_WIDTH;
  const {
    galleryCradle, galleryNoVenuesCradle, noContentFallbackText, customVenueGalleryCradleStyles,
    galleryContentCradle
  } = styleGen(themeInfo, props, computedCardWidth);

  return (
    <div className={css(galleryCradle, customVenueGalleryCradleStyles)}>
      {
        (venues.length > 0) ? (
          <div className={css(galleryContentCradle)}>
            {
              venues.map((artist: Venue) => {
                const { id, name, imageURL, city, country } = artist;
    
                return (
                  <VenueCard
                    key={id}
                    name={name}
                    imageURL={imageURL}
                    city={city}
                    country={country}
                    onClick={() => onVenueClick(id)}
                  />
                );
              })
            }
          </div>
        ) : (
          <div className={css(galleryNoVenuesCradle)}>
            <p className={css(noContentFallbackText)}>{t("noVenuesToDisplayMsg")}</p>
          </div>
        )
      }
    </div>
  );
}

export default VenueGallery;
