// -> Beyond Codebase
import React, { useContext } from 'react';
import { css } from 'aphrodite';
import { useTranslation } from 'react-i18next';
// -> Within Codebase
import { ThemeInfo, UIContext } from '../../../UI_InfoProvider/UI_InfoProvider';
import { MoreDetailsIcon, PersonIcon, ClipboardIcon } from '../../../VisualUtilities/IconPresets'
import { underConstructionAlert } from '../../../../helpers';
// -> Within Component
import { styleGen } from './DropdownDropComponentStyles';

interface IDropdownDropComponentProps {
  logout(): void;
  navigateToUserProfile(): void;
  navigateToAdminDashboard(): void;
}

const DropdownDropComponent: React.FC<IDropdownDropComponentProps> = (props) => {
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { palette, styles } = themeInfo;
  const { t } = useTranslation("component_navBarDropdown")
  const { logout, navigateToUserProfile, navigateToAdminDashboard } = props;
  const { cradle, dropdownOption, dropdownOptionText } = styleGen(themeInfo);

  return (
    <div className={css(cradle)}>
      <div className={css(dropdownOption)} onClick={() => navigateToUserProfile()}>
        <p className={css(dropdownOptionText)}>{t("profileLabel").toUpperCase()}</p>
        <PersonIcon color={palette.primary} size={styles.standardIconSize} />
      </div>
      <div className={css(dropdownOption)} onClick={navigateToAdminDashboard}>
        <p className={css(dropdownOptionText)}>{t("adminPanelLabel").toUpperCase()}</p>
        <ClipboardIcon color={palette.primary} size={styles.standardIconSize + 5} />
      </div>
      <div className={css(dropdownOption)} onClick={logout}>
        <p className={css(dropdownOptionText)}>{t("logoutLabel").toUpperCase()}</p>
        <MoreDetailsIcon color={palette.primary} size={styles.standardIconSize} />
      </div>
    </div>
  );
}

export default DropdownDropComponent;
