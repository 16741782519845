// -> Beyond Codebase
import React, { useContext } from 'react';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// -> Within Codebase
import Drawer from "../Drawer/Drawer";
import DrawerContent from "../Drawer/InternalComponents/DrawerContent/DrawerContent";
import NavBar from '../NavBar/NavBar';
import Backdrop from '../Backdrop/Backdrop';
import RoutingModule from '../RoutingModule/RoutingModule';
import { UIContext } from '../UI_InfoProvider/UI_InfoProvider';

const App: React.FC = () => {
  const UIInfo = useContext(UIContext);
  const {
    backdropVisible, closeDrawer, drawerOpen, closeBackdrop,
    themeInfo: { palette }, modalOpen, closeModal, navBarVisible,
  } = UIInfo;

  return (
    <>
      { (navBarVisible) ? <NavBar /> : null }
      <Drawer
        backgroundColor={palette.white}
        drawerOpen={drawerOpen}
        closeDrawer={closeDrawer}
        backdropVisible={backdropVisible}
        closeBackdrop={closeBackdrop}
      >
        <DrawerContent
          closeDrawer={closeDrawer}
          closeBackdrop={closeBackdrop}
          backdropVisible={backdropVisible}
        />
      </Drawer>
      {
        backdropVisible ? (
          <Backdrop
            drawerOpen={drawerOpen}
            modalOpen={modalOpen}
            closeDrawer={closeDrawer}
            closeModal={closeModal}
            closeBackdrop={closeBackdrop}
          />
        ) : null
      }
      <RoutingModule />
      <ToastContainer />
    </>
  );
}

export default App;
