// -> Beyond Codebase
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from 'aphrodite-jss';
import { useTranslation } from "react-i18next";
// -> Within Codebase
import FillUnderNavBarCradle from '../../LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle';
import Button from '../../Button/Button';
import SadFaceIcon from '../../VisualUtilities/IconPresets/SadFaceIcon';
import { UIContext, ThemeInfo } from '../../UI_InfoProvider/UI_InfoProvider';
import { HOME_ROUTE, HORIZONTAL, VERTICAL } from '../../../constants';
// -> Within Component
import { styleGen } from './RouteNotFoundFallbackStyles';
import Spacer from 'src/Components/LayoutUtilities/Spacer/Spacer';

const RouteNotFoundFallback: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation("page_404Fallback")
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const {
    pageCradle, headerCradle, headerText, textCradle, bodyText
  } = styleGen(themeInfo);

  const navigateToTheHub = () => history.push(HOME_ROUTE);

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        <div className={css(headerCradle)}>
          <SadFaceIcon color={themeInfo.palette.primary} size={30} />
          <Spacer direction={HORIZONTAL} amount={distance.two} />
          <p className={css(headerText)}>{t("pageTitleMsg")}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.one} />
        <div className={css(textCradle)}>
          <p className={css(bodyText)}>{t("pageDetailMsg")}</p>
        </div>
        <Spacer direction={VERTICAL} amount={distance.four} />
        <Button
          // - TODO: -> Internationalize text
          buttonText={t("homeBtnLabel")}
          onClick={navigateToTheHub}
          customCradleHoverStyles={{ boxShadow: themeInfo.shadows.two }}
          customCradleStyles={{ transition: themeInfo.transitions.boxShadowTransition }}
        />
      </div>
    </FillUnderNavBarCradle>
  );
}

export default RouteNotFoundFallback;
