// -> Beyond Codebase
import React, { useContext } from 'react';
import { css } from 'aphrodite-jss';
import { useTranslation } from "react-i18next";
// -> Within Codebase
import FloatingCircularLogo from "../../../../Components/VisualUtilities/FloatingCircularLogo/FloatingCircularLogo";
import Spacer from '../../../../Components/LayoutUtilities/Spacer/Spacer';
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider"
import { VERTICAL } from '../../../..//constants';
// -> Within Component
import EmailLoginForm from './InternalComponents/EmailLoginForm/EmailLoginForm';
import { ILoginCardProps } from './helpers';
import { styleGen } from './LoginCardStyles';

const LoginCard: React.FC<ILoginCardProps> = (props) => {
  const { onRegistrationClick, onSubmit, onResetPasswordClick } = props;
  const { t } = useTranslation("page_login");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const {
    cardCradle, topBarCradle, cardBottomCradle, promptText, customCardCradleStyles
  } = styleGen(props, themeInfo);

  return (
    <div className={css(cardCradle, customCardCradleStyles)}>
      {/* TOP BAR */}
      <div className={css(topBarCradle)}>
        <FloatingCircularLogo />
        <Spacer direction={VERTICAL} amount={distance.four} />
        <p className={css(promptText)}>{t("cardMessage")}</p>
        <Spacer direction={VERTICAL} amount={distance.two} />
      </div>
      {/* MAIN CARD CONTENT */}
      <div className={css(cardBottomCradle)}>
        <EmailLoginForm
          onRegistrationClick={onRegistrationClick}
          onResetPasswordClick={onResetPasswordClick}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}

export default LoginCard;

LoginCard.defaultProps = {
  isInCardMode: true,
};
