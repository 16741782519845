// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite-jss";
// -> Within codebase
import ImageWrapper from "../../VisualUtilities/ImageWrapper/ImageWrapper";
import Spacer from "../../LayoutUtilities/Spacer/Spacer";
import Tag from "../../Tag/Tag";
import DiscoBallIcon from "../../VisualUtilities/IconPresets/DiscoBallIcon";
import { ThemeInfo, UIContext } from "../../UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL, ROUNDED } from "../../../constants";
import { computeItemNumberString } from "../../../helpers";
// -> Within component
import { styleGen } from "./VenueListItemStyles";
import { VenueListItemProps, DEFAULT_IMAGE_SIZE } from "./helpers";

const VenueListItem: React.FC<VenueListItemProps> = (props) => {
  const { itemNumber, name, imageURL, city, country, onClick } = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, palette } = themeInfo;
  const {
    listItemCradle, itemNumberTextCradle, itemNumberText, venueImageCradle,
    fallbackIconCradle, rightRowContentCradle, venueNameCradle, venueNameText,
    customVenueListItemCradleStyles, customVenueNameTextStyles, venueLocationCradle
  } = styleGen(themeInfo, props);

  return (
    <div className={css(listItemCradle, customVenueListItemCradleStyles)} onClick={onClick}>
      {
        (itemNumber) ? (
          <>
            <div className={css(itemNumberTextCradle)}>
              <p className={css(itemNumberText)}>{computeItemNumberString(itemNumber)}</p>
            </div>
            <Spacer direction={HORIZONTAL} amount={distance.three} />
          </>
        ) : null
      }
      <div className={css(venueImageCradle)}>
        {
          (imageURL) ? (
            <ImageWrapper
              alt={`Venue: ${name}`} src={imageURL}
              borderGeometry={ROUNDED}
              height={DEFAULT_IMAGE_SIZE} width={DEFAULT_IMAGE_SIZE}
              customImgStyles={{ borderRadius: themeInfo.borderRadii.two }}
              customImgCradleStyles={{ borderRadius: themeInfo.borderRadii.two }}
            />
          ) : (
            <div className={css(fallbackIconCradle)}>
              <DiscoBallIcon color={palette.grey2} size={DEFAULT_IMAGE_SIZE - 20} />
            </div>
          )
        }
      </div>
      <Spacer direction={HORIZONTAL} amount={distance.three} />
      <div className={css(rightRowContentCradle)}>
        <div className={css(venueNameCradle)}>
          <p className={css(venueNameText, customVenueNameTextStyles)}>{name}</p>
        </div>
        <div className={css(venueLocationCradle)}>
          <Tag
            labelText={`${city}, ${country}`}
            tagGeometry={ROUNDED}
            activeColor={palette.primary_p1}
            activeTextColor={palette.white}
            customCradleStyles={{
              paddingTop: distance.one, paddingBottom: distance.one,
              paddingLeft: distance.one, paddingRight: distance.one,
            }}
            customTextStyles={{ fontSize: "1.2rem", fontWeight: "bold" }}
          />
        </div>
      </div>
    </div>
  );
}

export default VenueListItem;
