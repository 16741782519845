import { StyleSheet } from 'aphrodite-jss';
import { ThemeInfo } from '../../UI_InfoProvider/UI_InfoProvider';
import { DEFAULT_FORM_CONTROL_WIDTH } from '../helpers';
import { ITextAreaProps } from './helpers';
import { DISTANCE_COEFFICIENT } from '../../../styleGuide/distanceTable';

export const styleGen = (props: ITextAreaProps, themeInfo: ThemeInfo) => {
  const {
    height, width, customControlCradleHoverStyles,
    customControlCradleStyles, customLabelCradleStyles,
    customLabelTextStyles, customErrorCradleStyles,
    customErrorTextStyles, customTextAreaCradleStyles,
    customTextAreaTextStyles, rtl, maxWidth
  } = props;
  const { distance, palette, borderRadii, transitions } = themeInfo;

  return StyleSheet.create({
    formControlCradle: {
      height: height ? height: 'min-content',
      width: width ? width : DEFAULT_FORM_CONTROL_WIDTH,
      maxWidth: maxWidth ? maxWidth : 0,
      '&:hover': { ...customControlCradleHoverStyles }
    },
    labelCradle: {
      display: 'flex',
      alignItmes: 'center',
      justifyContent: (rtl) ? 'flex-end' : 'flex-start',
    },
    labelText: {
      ...themeInfo.typography.default,
      margin: 0,
    },
    requiredElement: {
      color: themeInfo.palette.errorStatus,
      fontSize: "1.6rem",
      paddingTop: 1,
      lineHeight: 0.4,
    },
    textAreaCradle: {
      width: '100%',
      paddingTop: distance.one,
      paddingBottom: distance.one,
      paddingRight: DISTANCE_COEFFICIENT * 1.5,
      paddingLeft: DISTANCE_COEFFICIENT * 1.5,
      backgroundColor: palette.white,
      border: 'none' // -> Removes default inset border given to textArea HTML elements.
    },
    defaultBorderStyle: {
      border: `1px solid ${palette.grey2}`,
      transition: transitions.borderColorTransition,
      '&:focus': {
        border: `1px solid ${palette.primary}`,
        outline: 'none',
      }
    },
    defaultUnderlineStyle: {
      borderBottom: `1px solid ${palette.black}`,
      transition: transitions.borderColorTransition,
      '&:focus': {
        borderBottom: `1px solid ${palette.primary}`,
        outline: 'none',
      }
    },
    borderRounded: { borderRadius: borderRadii.one },
    borderRound: { borderRadius: 10000 },
    textAreaText: {
      ...themeInfo.typography.default,
      lineHeight: 1.43,
    },
    errorCradle: {
      width: "100%",
      display: 'flex',
      alignItems: 'center',
      justifyContent: (rtl) ? 'flex-end' : 'flex-start',
    },
    errorText: {
      fontFamily: themeInfo.fonts.primary,
      color: palette.errorStatus,
      fontSize: '1.1rem',
    },
    customControlCradleStyles: { ...customControlCradleStyles },
    customLabelCradleStyles: { ...customLabelCradleStyles },
    customLabelTextStyles: { ...customLabelTextStyles },
    customTextAreaCradleStyles: { ...customTextAreaCradleStyles },
    customTextAreaTextStyles: { ...customTextAreaTextStyles },
    customErrorCradleStyles: { ...customErrorCradleStyles },
    customErrorTextStyles: { ...customErrorTextStyles },
    formField: {
      display: 'block',
      width: 400,
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 12,
      paddingRight: 12,
      fontSize: 14,
      lineHeight: 1.42857143,
      color: '#555',
      backgroundColor: '#FFF',
      backgroundImage: 'none',
      border: `1px solid #CCC`,
      borderRadius: 4,
    },
  });
}