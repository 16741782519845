// -> Beyond codebase
import React, { useContext, useEffect, useRef, useState } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Tag from "../../../../Components/Tag/Tag";
import Fade from "../../../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CircleLoader from "../../../../Components/Loaders/CircleLoader/CircleLoader";
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import Spacer from "../../../../Components/LayoutUtilities/Spacer/Spacer";
import { ThemeInfo, UIContext } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, HORIZONTAL, ROUND, VERTICAL } from "../../../../constants";
// import { ISongCollection } from "../../../../Types";
// -> Within component
import RecentActionListItem from "./InternalComponents/RecentActionListItem/RecentActionListItem";
import { styleGen } from "./RecentActionsFeedStyles";
import { IRecentActionsFeedProps, IRecentActionsFeedState } from "./helpers";
import { ArtistAction, EventAction, VenueAction } from "src/Types";

const RecentActionsFeed: React.FC<IRecentActionsFeedProps> = (props) => {
  const [state, setState] = useState<IRecentActionsFeedState>({
    loadingStatus: true,
    errorStatus: false,
    loaderInStatus: true,
    mainContentInStatus: true,
  });
  const {
    onSelectArtistAction, onSelectEventAction, onSelectVenueAction, feedActions,
  } = props;
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { t } = useTranslation(["page_adminDashboard"]);
  const { distance, palette } = themeInfo;
  const {
    componentCradle, customRecentActionsFeedCradleStyles, titleBarCradle, titleText,
    recentActionsFeedCradle, itemSeparatorCradle, noContentFallbackText, noRecentActionsFallbackCradle,
    loaderCradle, titleCradle, itemSeperatorStartCap, itemSeperatorEndCap,
  } = styleGen(themeInfo, props);

  let loaderMicroanimationTimeout = useRef<any>(null);

  // -----

  useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, loaderInStatus: false }));
      loaderMicroanimationTimeout.current = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loadingStatus: false }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }, 1000)
  }, []);

  useEffect(() => {
    return () => clearTimeout(loaderMicroanimationTimeout.current);
  }, [loaderMicroanimationTimeout]);

  const tempContentPresentStatus = true;

  // -----

  const { loadingStatus, loaderInStatus, mainContentInStatus } = state;
  
  return (
    <div className={css(componentCradle, customRecentActionsFeedCradleStyles)}>
      <div className={css(titleBarCradle)}>
        <div className={css(titleCradle)}>
          <p className={css(titleText)}>{t("recentActionsTitle")}</p>
        </div>

        {
          (!loadingStatus) ? (
            <Fade>
              <Tag
                labelText={`${feedActions.length}`}
                tagGeometry={ROUND}
                activeColor={palette.primary}
                activeTextColor={palette.white}
                customCradleStyles={{
                  height: 25, width: 25,
                  padding: distance.one,
                }}
                customTextStyles={{ fontWeight: "bold" }}
              />
            </Fade>
          ) : null
        }
      </div>

      <LineSegment
        direction={HORIZONTAL}
        width="100%" size={1}
        color={palette.black}
        customStyles={{ marginTop: distance.two, marginBottom: distance.two }}
      />

      {
        (!loadingStatus) ? (
          <Fade inStatus={mainContentInStatus}>
            <>
              <Spacer direction={VERTICAL} amount={distance.two} />
              {
                (tempContentPresentStatus) ? (
                  <div className={css(recentActionsFeedCradle)}>
                    {
                      feedActions.map((feedAction: ArtistAction | VenueAction | EventAction, index: number) => {
                        const { actionID } = feedAction;

                          // -> Ensure that first entry has a seperator line above it with a round cap
                          if (index === 0) return (
                            <div key={actionID} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                              <div className={css(itemSeparatorCradle)}>
                                <div className={css(itemSeperatorStartCap)}></div>
                                <LineSegment
                                  direction={VERTICAL}
                                  size={5}
                                  height={50} color={palette.primary_p3}
                                />
                              </div>
                              <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <RecentActionListItem
                                  feedAction={feedAction}
                                  onSelectArtistAction={onSelectArtistAction}
                                  onSelectVenueAction={onSelectVenueAction}
                                  onSelectEventAction={onSelectEventAction}
                                />
                              </div>
                              <div className={css(itemSeparatorCradle)}>
                                <LineSegment
                                  direction={VERTICAL}
                                  size={5}
                                  height={50} color={palette.primary_p3}
                                />
                              </div>
                            </div>
                          );

                          // -> Ensure that last entry has a seperator line below it with a round cap
                          if (index === (feedActions.length - 1)) return (
                            <div key={actionID} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                              <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <RecentActionListItem
                                  feedAction={feedAction}
                                  onSelectArtistAction={onSelectArtistAction}
                                  onSelectVenueAction={onSelectVenueAction}
                                  onSelectEventAction={onSelectEventAction}
                                />
                              </div>
                              <div className={css(itemSeparatorCradle)}>
                                <LineSegment
                                  direction={VERTICAL}
                                  size={5}
                                  height={50} color={palette.primary_p3}
                                />
                                <div className={css(itemSeperatorEndCap)}></div>
                              </div>
                            </div>
                          );
                        

                        
                        return (
                          <div key={actionID} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <RecentActionListItem
                                  feedAction={feedAction}
                                  onSelectArtistAction={onSelectArtistAction}
                                  onSelectVenueAction={onSelectVenueAction}
                                  onSelectEventAction={onSelectEventAction}
                                />
                              </div>
                            <div className={css(itemSeparatorCradle)}>
                              <LineSegment
                                direction={VERTICAL}
                                size={5}
                                height={50} color={palette.primary_p3}
                              />
                            </div>
                            {/* {
                              (index < (feedActions.length - 1)) ? (
                                <div className={css(itemSeparatorCradle)}>
                                  <LineSegment
                                    direction={VERTICAL}
                                    size={5}
                                    height={35} color={palette.primary}
                                    customStyles={{ position: "relative", left: 50 }}
                                  />
                                </div>
                              ) : null
                            } */}
                          </div>
                        );
                      })
                    }
                  </div>
                ) : (
                  <div className={css(noRecentActionsFallbackCradle)}>
                    <p className={css(noContentFallbackText)}>{t("noSongCollectionsFallbackMsg")}</p>
                  </div>
                )
              }
            </>
          </Fade>
        ) : (
          <div className={css(loaderCradle)}>
            <Fade inStatus={loaderInStatus}>
              <CircleLoader spinnerColor={palette.primary} size={35} />
            </Fade>
          </div>
        )
      }

    </div>
  );
}

export default RecentActionsFeed;

RecentActionsFeed.defaultProps = {
  isInCardMode: true,
};
