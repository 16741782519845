// -> Beyond Codebase
import React, { useContext } from 'react';
import * as yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { css } from 'aphrodite-jss';
import { useTranslation } from "react-i18next";
// -> Within Codebase
import { UIContext, ThemeInfo } from "../../../../../../Components/UI_InfoProvider/UI_InfoProvider";
import Input from "../../../../../../Components/FormUtilities/Input/Input"
import Spacer from '../../../../../../Components/LayoutUtilities/Spacer/Spacer';
import Button from "../../../../../../Components/Button/Button";
import { ROUNDED, VERTICAL } from '../../../../../../constants';
// -> Within Component
import { ILoginFormState, ILoginFormProps } from './helpers';
import { styleGen } from './EmailLoginFormStyles';

const initialValues: ILoginFormState = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format'),//.required('Email is required'),
  password: yup.string(),//.required('Password is required')
});

const EmailLoginForm: React.FC<ILoginFormProps> = (props) => {
  const { t } = useTranslation("page_login");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onSubmit, onRegistrationClick, onResetPasswordClick } = props;
  const {
    formCradle, topCradle, bottomCradle, bottomMessageCradle,
    bottomMessageText
  } = styleGen(themeInfo);

  const onFormSubmission = (values: FormikValues, onSubmitProps: any) => {
    // const { resetForm, setSubmitting } = onSubmitProps;
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmission}
    >
      {
        (formik) => {
          return (
            <Form className={css(formCradle)}>
              <Spacer direction={VERTICAL} amount={distance.two} />
              <div className={css(topCradle)}>

                <Input
                  required
                  name="email"
                  label={t("emailInputLabel")}
                  width="100%"
                  borderGeometry={ROUNDED}
                  mode="border"
                  customInputCradleStyles={{ height: 35 }}
                />
                <Spacer direction={VERTICAL} amount={distance.two} />

                <Input
                  required
                  name="password"
                  label={t("passwordInputLabel")}
                  type="password"
                  width="100%"
                  borderGeometry={ROUNDED}
                  mode="border"
                  customInputCradleStyles={{ height: 35 }}
                />
              </div>

              <Spacer direction={VERTICAL} amount={distance.four} />

              <div className={css(bottomCradle)}>
                <Button
                  onClick={() => {}}
                  buttonText={t("loginBtnLabel")}
                  type="submit"
                  // disabled={!formik.isValid || !formik.dirty || formik.isSubmitting }
                  customTextStyles={{ fontSize: '1.6rem', fontWeight: 'bold' }}
                  customCradleStyles={{
                    paddingTop: themeInfo.distance.one,
                    paddingBottom: themeInfo.distance.one,
                    paddingRight: themeInfo.distance.two,
                    paddingLeft: themeInfo.distance.two,
                    width: "100%",
                    height: 35,
                  }}
                />
                
                <Spacer direction={VERTICAL} amount={distance.two} />

                <div className={css(bottomMessageCradle)}>
                  <p className={css(bottomMessageText)}>Don't have an account?</p>
                  <Button
                    buttonText={t("registerBtnLabel")}
                    transparent
                    onClick={onRegistrationClick}
                    customTextStyles={{ fontSize: '1.2rem', color: themeInfo.palette.primary }}
                    customCradleStyles={{
                      paddingTop: themeInfo.distance.one,
                      paddingBottom: themeInfo.distance.one,
                      paddingRight: themeInfo.distance.two,
                      paddingLeft: themeInfo.distance.two,
                    }}
                  />
                </div>

                <Button
                    buttonText={t("forgotPasswordBtnLabel")}
                    transparent
                    onClick={(e: Event) => {
                      e.preventDefault();
                      onResetPasswordClick();
                    }}
                    customTextStyles={{
                      fontSize: '1.2rem',
                      color: themeInfo.palette.primary,
                      fontStyle: "italic",
                    }}
                    customCradleStyles={{
                      paddingTop: themeInfo.distance.one,
                      paddingBottom: themeInfo.distance.one,
                      paddingRight: themeInfo.distance.two,
                      paddingLeft: themeInfo.distance.two,
                    }}
                  />
              </div>
            </Form>
          );
        }
      }
    </Formik>
  );
}

export default EmailLoginForm;
