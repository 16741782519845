import { StyleSheet } from "aphrodite";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { DEFAULT_CARD_WIDTH } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo) => {
  return StyleSheet.create({
    cardCradle: {
      height: 'auto',
      width: DEFAULT_CARD_WIDTH,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      boxShadow: themeInfo.shadows.one,
      borderRadius: themeInfo.borderRadii.one,
      backgroundColor: themeInfo.palette.white,

      "@media (max-width: 320px)": {
        width: 290,
      }
    },
    topBarCradle: {
      minHeight: 100,
      height: "auto",
      width: '100%',
      paddingLeft: themeInfo.distance.three,
      paddingRight: themeInfo.distance.three,
      paddingTop: themeInfo.distance.three,
      display: 'flex',
      flexDirection: "column",
      alignItems: 'center',
      backgroundColor: themeInfo.palette.white,
      borderTopLeftRadius: themeInfo.borderRadii.one,
      borderTopRightRadius: themeInfo.borderRadii.one,
    },
    promptText: {
      fontFamily: themeInfo.fonts.primary,
      fontSize: "1.5rem",
      fontStyle: "italic",
      color: themeInfo.palette.grey3,
      marginBottom: 0,
    },
    cardBottomCradle: {
      height: '100%',
      width: '100%',
      padding: themeInfo.distance.three,
      display: 'flex',
      flex: 1,
    },
    formCradle: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  });
}
