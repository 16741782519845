// -> Beyond codebase
import React, { useContext, MouseEvent, useEffect } from "react";
import { css } from 'aphrodite-jss';
import { useTranslation } from "react-i18next";
// -> Within codebase
import Dropdown from "../../../../Components/Dropdown/Dropdown";
import Button from '../../../../Components/Button/Button';
import Spacer from '../../../../Components/LayoutUtilities/Spacer/Spacer';
import { ThemeInfo, UIContext } from '../../../../Components/UI_InfoProvider/UI_InfoProvider';
import { generateDropdownItems } from "../../../../helpers";
import { IDropdownItem } from '../../../../Components/Dropdown/stateManagement';
import {
  SORT_CRITERIA__NONE, determineAvailableSortCriteriaByLanguage,
  determineAvailableSortDirectionsByLanguage, mapSortCriteriaToEnglishSortCriteria,
  mapSortDirectionToEnglishSortDirection, mapEnglishSortCriteriaToTargetLanguage,
  mapEnglishSortDirectionToTargetLanguage,
} from "../../helpers";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, GREYSCALE, LEFT, VERTICAL } from '../../../../constants';
// -> Within component
import { styleGen } from './SortPanelStyles';
import { ISortPanelProps } from './helpers';

const SortPanel: React.FC<ISortPanelProps> = (props) => {
  const { t, i18n } = useTranslation("page_venues");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { fonts, palette, distance } = themeInfo;
  const {
    setSortCriteria, dispatch, setSortDirection, applySortConfig,
    clearSortConfig, setSortActivityIndicatorInStatus, state,
  } = props;
  let sortActivityIndicatorMicroanimationTimeout: any = -1;
  const { currentSortCriteria, currentSortDirection } = state
  const {
    panelCradle, sortPanelTitleCradle, sortPanelTitleText, selectionDropdownLabel,
    bottomRow, bottomRightBtns
  } = styleGen(themeInfo);

  useEffect(() => {
    return () => clearTimeout(sortActivityIndicatorMicroanimationTimeout);
  }, [sortActivityIndicatorMicroanimationTimeout]);

  const onSetSortCriteria = (sortCriteria: string) => {
    dispatch(setSortCriteria(mapSortCriteriaToEnglishSortCriteria(sortCriteria, i18n.language)));
  };
  
  const onSetSortDirection = (sortDirection: string) => {
    dispatch(setSortDirection(mapSortDirectionToEnglishSortDirection(sortDirection, i18n.language)));
  };

  const sortCriteriaDropdownItems: IDropdownItem[] = 
    generateDropdownItems(determineAvailableSortCriteriaByLanguage(i18n.language));
  const sortDirectionItems: IDropdownItem[] =
    generateDropdownItems(determineAvailableSortDirectionsByLanguage(i18n.language));

  return (
    <div className={css(panelCradle)}>
      <div className={css(sortPanelTitleCradle)}>
        <p className={css(sortPanelTitleText)}>{t("sortPanel_panelTitle")}</p>
      </div>

      <Spacer direction={VERTICAL} amount={distance.two} />

      {/* SORT CRITERIA */}
      <p className={css(selectionDropdownLabel)}>{t("sortPanel_sortCriteriaLabel")}</p>
      <Dropdown
        selectedItemValue={mapEnglishSortCriteriaToTargetLanguage(currentSortCriteria, i18n.language)}
        onSelectItem={onSetSortCriteria}
        items={sortCriteriaDropdownItems}
        colorMode={GREYSCALE}
        dropCradleAlignment={LEFT}
        customDropdownCradleStyles={{ width: "100%" }}
      />

      <Spacer direction={VERTICAL} amount={distance.two} />

      {/* SORT DIRECTION */}
      <p className={css(selectionDropdownLabel)}>{t("sortPanel_sortDirectionLabel")}</p>
      <Dropdown
        selectedItemValue={mapEnglishSortDirectionToTargetLanguage(currentSortDirection, i18n.language)}
        onSelectItem={onSetSortDirection}
        items={sortDirectionItems}
        colorMode={GREYSCALE}
        dropCradleAlignment={LEFT}
        customDropdownCradleStyles={{ width: "100%" }}
      />

      {/* BOTTOM BUTTON ROW */}
      <Spacer direction={VERTICAL} amount={distance.two} />
      <div className={css(bottomRow)}>
        <Button
          buttonText={t("sortPanel_clearBtnLabel")}
          onClick={(evt: MouseEvent<HTMLButtonElement>) => {
            evt.stopPropagation();
            dispatch(setSortActivityIndicatorInStatus(false));
            sortActivityIndicatorMicroanimationTimeout = setTimeout(() => {
              dispatch(clearSortConfig());
            }, DEFAULT_TRANSITION_MICROANIMATION_TIME)
          }}
          transparent
          customTextStyles={{
            fontFamily: fonts.primary,
            fontSize: "1.4rem",
            fontWeight: "bold",
            color: palette.grey4,
          }}
          customTextHoverStyles={{ textDecoration: "underline" }}
          customCradleStyles={{ padding: 0, width: "min-content", whiteSpace: "nowrap" }}
        />

        <div className={css(bottomRightBtns)}>
          {/* <Button
            buttonText="Cancel"
            onClick={(evt: MouseEvent<HTMLButtonElement>) => {
              evt.stopPropagation();
              // - TODO: -> Figure out how to close <Dropdown /> from outside of it.
              underConstructionAlert();
            }}
            transparent
            customTextStyles={{
              fontFamily: fonts.primary,
              fontSize: "1.4rem",
              fontWeight: "bold",
              color: palette.grey4,
            }}
            customTextHoverStyles={{ textDecoration: "underline" }}
            customCradleStyles={{ padding: 0, width: "min-content", whiteSpace: "nowrap" }}
          />
          <Spacer direction={HORIZONTAL} amount={distance.two} /> */}
          <Button
            buttonText={t("sortPanel_applyBtnLabel")}
            disabled={currentSortCriteria === SORT_CRITERIA__NONE}
            onClick={(evt: MouseEvent<HTMLButtonElement>) => {
              evt.stopPropagation();
              dispatch(applySortConfig());
            }}
            customCradleStyles={{
              paddingTop: distance.one,
              paddingBottom: distance.one,
            }}
            customTextStyles={{
              fontFamily: fonts.primary,
              fontSize: "1.4rem",
              fontWeight: "bold",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SortPanel;
