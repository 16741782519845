import { StyleSheet } from "aphrodite-jss";
import { ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { IRecentActionsFeedProps } from "./helpers";

export const styleGen = (themeInfo: ThemeInfo, props: IRecentActionsFeedProps) => {
  const { isInCardMode, customRecentActionsFeedCradleStyles } = props;
  const {
    distance, palette, borderRadii, shadows, fonts, typography,
  } = themeInfo;

  return StyleSheet.create({
    componentCradle: {
      height: "min-content",
      minHeight: 150,
      maxHeight: 1000,
      width: "98%",
      paddingTop: distance.three,
      paddingBottom: distance.three,
      paddingLeft: isInCardMode ? distance.two : 0,
      paddingRight: isInCardMode ? distance.two : 0,
      display: "flex",
      flexDirection: "column",
      borderRadius: borderRadii.one,
      boxShadow: isInCardMode ? shadows.one : "none",
      backgroundColor: palette.white,
      
      "@media (max-width: 450px)": {
        width: "100%",
      }
    },
    titleBarCradle: {
      height: "min-content",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    titleText: {
      fontFamily: fonts.primary,
      fontWeight: "bold",
      fontSize: "2.4rem",
      color: palette.black,
      overflowWrap: "word-break",
      "whiteSpace": "nowrap",
    },
    titleCradle: {
      padding: distance.one,
      borderRadius: borderRadii.one,
      background: palette.grey1,
      width: "min-content",
      // "whiteSpace": "nowrap",
      overflowWrap: "word-break",
    },
    loaderCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    recentActionsFeedCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      overflowY: "scroll",
    },
    noRecentActionsFallbackCradle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      overflowY: "scroll",
    },
    itemSeparatorCradle: {
      height: "auto",
      width: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      left: 150,

      "@media (max-width: 450px)": {
        left: 100,
      }
    },
    itemSeperatorStartCap: {
      height: 15, width: 15,
      borderRadius: 10000,
      backgroundColor: palette.primary_p3,
      position: "relative", top: 4,
    },
    itemSeperatorEndCap: {
      height: 15, width: 15,
      borderRadius: 10000,
      backgroundColor: palette.primary_p3,
      position: "relative", bottom: 4,
    },
    customRecentActionsFeedCradleStyles: { ...customRecentActionsFeedCradleStyles },
    noContentFallbackText: { ...typography.noContentFallbackText },
  });
};
