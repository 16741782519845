const encodeBuffer = (buffer: Buffer) => buffer.toString("base64");
const encodeString = (string: string) => encodeBuffer(Buffer.from(string));
const encodeData = (data: any) => encodeString(JSON.stringify(data));

export const encodeBase64 = (data: any) => {
  if (Buffer.isBuffer(data)) return encodeBuffer(data);
  if (typeof data === "string") return encodeString(data);
  return encodeData(data);
};

export const decodeBase64 = (string: string) => {
  const decodedContent = Buffer.from(string, "base64").toString();
  return JSON.parse(decodedContent);
};
