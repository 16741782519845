/* eslint-disable react-hooks/exhaustive-deps */
// -> Beyond codebase
import React, { useContext, useEffect, useState } from "react";
import { css } from "aphrodite";
import { useHistory } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
// -> Within codebase
import FillUnderNavBarCradle from "../../Components/LayoutUtilities/Cradles/FillUnderNavBarCradle/FillUnderNavBarCradle";
import VerticalFade from "../../Components/AnimationUtilities/TransitionWrappers/VerticalFade/VerticalFade";
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import Spacer from "../../Components/LayoutUtilities/Spacer/Spacer";
import StatusMessage from "../../Components/VisualUtilities/StatusMessage/StatusMessage";
import AddPhoneNumberCard from "../../Components/Cards/AddPhoneNumberCard/AddPhoneNumberCard";
import { XIcon } from "../../Components/VisualUtilities/IconPresets";
import { UIContext, ThemeInfo } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { AuthContext } from "../../Components/AuthContextProvider/AuthContextProvider";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AUTHENTICATE_MFA_ROUTE, DEFAULT_TRANSITION_MICROANIMATION_TIME, PROFILE_ROUTE, VERTICAL } from "../../constants";
// import PlaceholderQRCode from "../../assets/images/placeholder_qr_code.png";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MFAType, MFAScenario } from "../../Types";
import { determineScreenDimensions, mapHttpErrorCodeToMessage, customAlertGen } from "../../helpers";
// -> Within component
// import ScanQRCodeCard from "./InternalComponents/ScanQRCodeCard/ScanQRCodeCard";
import ChooseMFAMethodCard from "./InternalComponents/ChooseMFAMethodCard/ChooseMFAMethodCard";
import { IEnableMFAState } from "./helpers";
import { styleGen } from "./EnableMFAStyles";

// - TODO: -> Receive the QR code along with appropriately embedded information from the backend
//            rather than just using a static example here.
//           -> This will be the result of some user action involving indicating that you'd like
//              to enable two-factor authentication.
//           -> This will send a request to the server. It will then prepare the QR code adn send
//              it back in some format like a Base64, URL-encoded PNG image.
const EnableMFA: React.FC = () => {
  const [state, setState] = useState<IEnableMFAState>({
    loadingStatus: true,
    errorStatus: false,
    addPhoneNumberStatus: false,
    loaderInStatus: true,
    mainContentInStatus: false,
    addPhoneNumberInStatus: false,
  });
  const { t } = useTranslation("page_enableMFA");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance } = themeInfo;
  const { toggleBackdrop } = useContext(UIContext);
  const { getUser, activateMFA, editUser, setUser } = useContext(AuthContext);
  const history = useHistory();
  const { height: screenHeight, width: screenWidth } =
    determineScreenDimensions();
  const { pageCradle, mainContentCradle, addPhoneNumberCradle } = styleGen(
    themeInfo,
    screenHeight,
    screenWidth
  );

  let MFAType: MFAType = "None";
  const user = getUser();
  //console.log("the user is:", user);
  const phoneNumber = user?.phoneNumber;
  let loaderTimeout: ReturnType<typeof setTimeout>;
  let mainContentTimeout: ReturnType<typeof setTimeout>;
  let demoTimeout: ReturnType<typeof setTimeout>;
  let addPhoneNumberTimeout: ReturnType<typeof setTimeout>;

  // -----

  useEffect(() => {
    (async () => {
      demoTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loaderInStatus: false }));
        loaderTimeout = setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            mainContentInStatus: true,
            loadingStatus: false,
          }));
        }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
      }, 1200);
    })();
  }, []);

  // -----

  // -> Clean up resources to avoid memory leaks.
  useEffect(() => {
    return () => {
      clearTimeout(loaderTimeout);
      clearTimeout(mainContentTimeout);
      clearTimeout(demoTimeout);
      clearTimeout(addPhoneNumberTimeout);
    };
  }, []);

  // -----

  const navigateToAuthenticateMFA = (MFAType: MFAType) => {
    const MFAScenario: MFAScenario = "Confirmation";
    setState((prevState) => ({ ...prevState, mainContentInStatus: false }));
    mainContentTimeout = setTimeout(() => {
      history.push(AUTHENTICATE_MFA_ROUTE, { MFAScenario, MFAType });
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  // -----

  const navigateToEditProfile = () => {
    setState((prevState) => ({ ...prevState, mainContentInStatus: false }));
    mainContentTimeout = setTimeout(() => {
      history.push(PROFILE_ROUTE);
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  }
  // const navigateToEditProfile = () => history.push(EDIT_PROFILE_ROUTE);

  // -----

  const closeModal = () => {
    setState((prevState) => ({ ...prevState, addPhoneNumberInStatus: false }));
    toggleBackdrop();
  };

  // -----

  const onAddPhoneNumberSubmission = async (phoneNumber: string) => {
    setState((prevState) => ({ ...prevState, addPhoneNumberInStatus: false }));
    addPhoneNumberTimeout = setTimeout(() => {
      setState((prevState) => ({ ...prevState, loadingStatus: true }));
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);

    const { error } = await editUser({ phoneNumber });

    if (error) {
      const errorMessage = mapHttpErrorCodeToMessage(error);

      setState((prevState) => ({
        ...prevState,
        errorStatus: true,
        errorHeaderMessage: t("addPHoneNumberErrorTitleMsg"),
        errorMessage,
        loaderInStatus: false,
      }));

      loaderTimeout = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          addPhoneNumberInStatus: true,
          loadingStatus: false,
        }));
        customAlertGen(errorMessage, themeInfo);
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
    }

    // - TODO: -> Fix underlying problem with profile editing data flow.
    //           -> Once this is fixed, change this method to "SMS".
    else {
      toggleBackdrop();
      await onSelectMFAMethod("None");
    }
  };

  // -----

  const onSelectMFAMethod = async (MFAType: MFAType) => {
    // - DEV NOTE -> Edge case where a user decides they'd like to activate SMS, but haven't yet
    //               associated a phone number with their account profile.
    if (MFAType === "SMS" && (!phoneNumber || phoneNumber === "")) {
      toggleBackdrop();
      setState((prevState) => ({
        ...prevState,
        addPhoneNumberInStatus: true,
        addPhoneNumberStatus: true,
      }));
      return;
    }

    if (MFAType === "None") navigateToEditProfile();
    
    if (MFAType !== "None") {
      setState((prevState) => ({ ...prevState, mainContentInStatus: false }));
      mainContentTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, loadingStatus: true }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
      const { error } = await activateMFA(MFAType);

      if (error) {
        const errorMessage = mapHttpErrorCodeToMessage(error);

        setState((prevState) => ({
          ...prevState,
          errorStatus: true,
          errorMessage,
          errorHeaderMessage: t("enableMFAErrorTitleMsg"),
          loaderInStatus: false,
        }));

        loaderTimeout = setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            loadingStatus: false,
            mainContentInStatus: true,
          }));
          customAlertGen(errorMessage, themeInfo);
        }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
      } else {
        const newUser = cloneDeep(user!);
        newUser.settings.MFAType = MFAType;
        setUser(newUser);
        navigateToAuthenticateMFA(MFAType);
      }
    }
  };

  // -----

  const {
    loadingStatus, errorStatus, loaderInStatus,
    mainContentInStatus, errorMessage, addPhoneNumberInStatus,
    errorHeaderMessage, addPhoneNumberStatus,
  } = state;

  return (
    <FillUnderNavBarCradle>
      <div className={css(pageCradle)}>
        {!loadingStatus ? (
          <VerticalFade inStatus={mainContentInStatus}>
            <div className={css(mainContentCradle)}>
              {errorStatus && (
                <>
                  <Fade>
                    <StatusMessage
                      color={themeInfo.palette.errorStatus}
                      headerText={errorHeaderMessage}
                      messageText={errorMessage}
                      icon={
                        <XIcon
                          color={themeInfo.palette.errorStatus}
                          size={40}
                        />
                      }
                    />
                  </Fade>
                  <Spacer direction={VERTICAL} amount={distance.three} />
                </>
              )}
              {/* <ScanQRCodeCard
                  imgURL={PlaceholderQRCode}
                  navigateToAuthenticateMFA={navigateToAuthenticateMFA}
                /> */}
              <ChooseMFAMethodCard
                onSelectMFAMethod={onSelectMFAMethod}
                verificationOption={MFAType}
              />
            </div>
          </VerticalFade>
        ) : (
          <Fade inStatus={loaderInStatus}>
            <CircleLoader spinnerColor={themeInfo.palette.primary} />
          </Fade>
        )}
        {addPhoneNumberStatus && (
          <div className={css(addPhoneNumberCradle)}>
            <VerticalFade inStatus={addPhoneNumberInStatus}>
              <AddPhoneNumberCard
                onAddPhoneNumberSubmission={onAddPhoneNumberSubmission}
                closeModal={closeModal}
              />
            </VerticalFade>
          </div>
        )}
      </div>
    </FillUnderNavBarCradle>
  );
};

export default EnableMFA;
