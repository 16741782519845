import { ReactNode } from "react";
import { css } from "aphrodite-jss";
import { ThemeInfo } from "../../UI_InfoProvider/UI_InfoProvider";

export interface IStatusMessageProps {
  color?: string;
  headerText?: string;
  messageText?: string;
  icon?: ReactNode;
  iconSize?: number;
  statusCircle?: boolean;
  customHeaderTextStyles?: any;
  customMessageTextStyles?: any;
  customCradleStyles?: any;
}

export const calibrateComponent = (themeInfo: ThemeInfo, props: IStatusMessageProps, styles: any) => {
  const { successMessageCradle, headerText, messageText } = styles;
  const { customCradleStyles, customHeaderTextStyles, customMessageTextStyles } = props;

  const cradleStylechain = css(successMessageCradle, customCradleStyles);
  const headerTextStylechain = css(headerText, customHeaderTextStyles);
  const messageTextStylechain = css(messageText, customMessageTextStyles);

  return { cradleStylechain, headerTextStylechain, messageTextStylechain };
}
