// -> Beyond codebase
import React, { useContext, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import Fade from "../../Components/AnimationUtilities/TransitionWrappers/Fade/Fade";
import CircleLoader from "../../Components/Loaders/CircleLoader/CircleLoader";
import { ThemeInfo, UIContext } from "../../Components/UI_InfoProvider/UI_InfoProvider";
import { DataContext } from "../../Components/DataContextProvider/DataContextProvider";
import { Venue } from "../../Types";
import { DEFAULT_TRANSITION_MICROANIMATION_TIME, EVENT_ID_ROUTE_PARAM_TOKEN, EVENT_ROUTE } from "../../constants";
// -> Within component
import VenueDetails from "./InternalComponents/VenueDetails/VenueDetails";
import { styleGen } from "./VenueStyles";
import { VenueState } from "./helpers";

const VenuePage: React.FC = () => {
  const [state, setState] = useState<VenueState>({
    venue: undefined,
    loadingStatus: true,
    errorStatus: false,
    loaderInStatus: true,
    fallbackContentInStatus: true,
  });
  const { venueID }: { venueID: string } = useParams();
  const routerHistory = useHistory();
  const { t } = useTranslation("page_venue");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { getVenueFromID } = useContext(DataContext);
  const { palette } = themeInfo;
  const { pageCradle, noContentFallbackText, noContentFallbackTextCradle } = styleGen(themeInfo);

  let mainContentMicroanimationTimeout: any = -1;
  let fallbackContentMicroanimationTimeout: any = -1;

  useEffect(() => {
    const venueToSet: Venue | undefined = getVenueFromID(venueID);
    setState((prevState) => ({ ...prevState, venue: venueToSet }));

    setTimeout(() => {
      setState((prevState) => ({ ...prevState, loaderInStatus: false }));
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, loadingStatus: false }));
      }, DEFAULT_TRANSITION_MICROANIMATION_TIME)
    }, 1000);
  }, [venueID, getVenueFromID]);

  useEffect(() => {
    return () => {
      clearTimeout(mainContentMicroanimationTimeout);
      clearTimeout(fallbackContentMicroanimationTimeout);
    }
  }, [mainContentMicroanimationTimeout, fallbackContentMicroanimationTimeout]);

  const onEventClick = (eventID: string) => {
    const computedURL = EVENT_ROUTE.replace(EVENT_ID_ROUTE_PARAM_TOKEN, eventID);

    setState((prevState) => ({ ...prevState, mainContentInStatus: false}));
    mainContentMicroanimationTimeout = setTimeout(() => {
      routerHistory.push(computedURL);
    }, DEFAULT_TRANSITION_MICROANIMATION_TIME);
  };

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    venue, loadingStatus, loaderInStatus, errorStatus, fallbackContentInStatus,
  } = state;

  return (
    <div className={css(pageCradle)}>
      {
        (loadingStatus) ? (
          <Fade inStatus={loaderInStatus}>
            <CircleLoader spinnerColor={palette.primary} />
          </Fade>
        ) : (
          <>
            {
              (venue) ? (
                <VenueDetails venue={venue} onEventClick={onEventClick} />
              ) : (
                <Fade inStatus={fallbackContentInStatus}>
                  <div className={css(noContentFallbackTextCradle)}>
                    <p className={css(noContentFallbackText)}>
                      {t("loadErrorMsg")}
                    </p>
                  </div>
                </Fade>
              )
            }
          </>
        )
      }
    </div>
  );
}

export default VenuePage;
