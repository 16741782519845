// -> Beyond codebase
import React, { useContext } from "react";
import { css } from "aphrodite-jss";
import { useTranslation } from "react-i18next";
// -> Within codebase
import LineSegment from "../../../../Components/VisualUtilities/LineSegment/LineSegment";
import { UIContext, ThemeInfo } from "../../../../Components/UI_InfoProvider/UI_InfoProvider";
import { HORIZONTAL } from "../../../../constants";
import { Artist } from "../../../../Types";
// -> Within component
import ArtistListItem from "../../../../Components/ListItems/ArtistListItem/ArtistListItem";
import { styleGen } from "./ArtistListStyles";
import { IArtistListProps } from "./helpers";

const ArtistList: React.FC<IArtistListProps> = (props) => {
  const { artists, onArtistClick, currentPageNumber, itemsPerPage } = props;
  const { t } = useTranslation("page_artists");
  const { themeInfo }: { themeInfo: ThemeInfo } = useContext(UIContext);
  const { distance, palette } = themeInfo;
  const {
    artistListCradle, artistContentCradle, noArtistsCradle,
    noContentFallbackText, customArtistListCradleStyles, itemSeparatorCradle
  } = styleGen(themeInfo, props);

  const artistCount = (artists) ? artists.length : 0;

  return (
    <div className={css(artistListCradle, customArtistListCradleStyles)}>
      {
        (artistCount > 0) ? (
          <div className={css(artistContentCradle)}>
            <>
              {
                artists.map((artist: Artist, index: number) => {
                  const { id, name, events, imageURL } = artist;
                  const numEvents = (events) ? events.length : 0;

                  return (
                    <div key={id}>
                      <ArtistListItem
                        id={id}
                        itemNumber={(index + 1) + ((currentPageNumber - 1) * itemsPerPage)}
                        name={name}
                        imageURL={imageURL}
                        numEvents={numEvents}
                        onClick={() => onArtistClick(id)}
                      />
                      {
                        (index < (artistCount - 1)) ? (
                          <div className={css(itemSeparatorCradle)}>
                            <LineSegment
                              direction={HORIZONTAL}
                              width={`calc(100% - ${distance.six}px)`}
                              size={1}
                              color={palette.grey1}
                            />
                          </div>
                        ) : null
                      }
                    </div>
                  );
                })
              }
            </>
          </div>
        ) : (
          <div className={css(noArtistsCradle)}>
            <p className={css(noContentFallbackText)}>{t("noArtistsToDisplayMsg")}</p>
          </div>
        )
      }
    </div>
  );
}

export default ArtistList;
